import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query offerDrafts($projectId: Int!) {
    offerDrafts(projectId: $projectId) {
      id
      title
      data
      isTemplate
    }
  }
`;

const useOfferDrafts = projectId => {
  const { error, loading, data, refetch } = useQuery(QUERY, {
    skip: !projectId,
    variables: { projectId: parseInt(projectId) },
  });
  return { error, loading, refetch, data: data?.offerDrafts };
};

export default useOfferDrafts;
