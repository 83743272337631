// A higher-order component which takes an inner component, and provides API
// connectivity to it via its props. This is a re-implmentation of CitrusByte's
// Relay system which is itself inspired by Facebook's Relay.
import React from 'react';
import _ from 'lodash';
import qs from 'qs';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withRouteHelpers from 'common/hoc/withRouteHelpers';

import {
  getResource,
  getSubResource,
  invalidateResource,
  postResource,
  putResource,
  deleteResource,
  replaceResource,
  updateResourceProgress,
} from 'common/store/actions/resources';

// **** HELPERS ****

// The only related resources that are ever used are the router query & params,
// so we just provide these to every info function & ignore the Relay config's
// `related` function.
const getRelated = (match = {}, location = {}) => {
  const { params = {} } = match || {};
  const { search = '' } = location || {};
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  return {
    '/router/query': query,
    '/router/params': params,
  };
};

// Relay config mostly consists of functions which are used to compute further
// information which is then used to get the actual data. This "computed config"
// is derived from two things, the current state of the router and the passed in
// `params` prop.
const computeConfig = (config, params, match, location) => {
  const { info, stale } = config;
  const related = getRelated(match, location);
  const { id, cursor, expires, initial, create, append } = info(
    params,
    related,
  );
  const url = cursor ? `${id}?${qs.stringify(cursor)}` : id;
  const dependentUrls = (stale && stale(params, related)) || [];

  return {
    url,
    dependentUrls,
    initial,
    isCreate: create,
    isAppend: append,
    isCached: !expires,
  };
};

// State to be passed to wrapper component via props.
const mapStateToProps = (state, ownProps) => {
  const { resources } = state;
  return { resources };
};

// Action dispatcher methods to be passed to wrapper component via props.
const mapDispatchToProps = {
  getResource,
  getSubResource,
  invalidateResource,
  postResource,
  putResource,
  deleteResource,
  replaceResource,
  updateResourceProgress,
};

// **** HOC ****

// A component which wraps the passed in component, providing API connectivity
// services to it via its props.
const WrappedComponent = InnerComponent => {
  // Get the query & mutator configuration from the inner component's
  // statics.queries & statics.mutators settings.
  const { queries = {}, mutators = {} } = InnerComponent;
  const queryConfigs = Object.keys(queries).map(key => ({
    ...queries[key],
    name: key,
  }));
  const mutatorConfigs = Object.keys(mutators).map(key => ({
    ...mutators[key],
    name: key,
  }));

  const editBuffers = {};
  mutatorConfigs.forEach(config => (editBuffers[config.name] = {}));

  return class extends React.Component {
    constructor(props) {
      super(props);
      const { params } = props;

      this.state = {
        params,
        editBuffers,
      };
    }

    // After component mounts, do initial fetching.
    componentDidMount() {
      // Running this in a timeout resolves race conditions between multiple
      // components loading the same resource.
      setTimeout(this.requireResources);
    }

    // Keep state.params in sync with changes to props.params &
    // re-require resources every time params or location search changes.
    componentDidUpdate(prevProps, prevState) {
      const { params: newParams, location = {} } = this.props;
      let paramsDidChange;

      this.setState(
        ({ params }) => {
          paramsDidChange = !_.isEqual(params, newParams);
          if (paramsDidChange) return { params: newParams };
        },
        () => {
          const { location: prevLocation = {} } = prevProps;
          const fullLocation = `${location.pathname || ''}${location.search ||
            ''}`;
          const previousFullLocation = `${prevLocation.pathname ||
            ''}${prevLocation.search || ''}`;
          const locationDidChange = fullLocation !== previousFullLocation;
          if (paramsDidChange || locationDidChange) {
            this.requireResources(prevProps, prevState);
            this.setState({ editBuffers });
          }
        },
      );
    }

    // Go through all configured resources and for each one either use the
    // already fetched resource, initialise the new resource or fetch the
    // resource from the API, as appropriate.
    requireResources = (prevProps, prevState) => {
      const { params } = this.state;
      const { resources, getResource, match, location } = this.props;
      const isFirstLoad = !prevProps;

      queryConfigs.concat(mutatorConfigs).forEach(config => {
        const { url, isCreate, initial, isCached } = computeConfig(
          config,
          params,
          match,
          location,
        );

        // If this is a creator resource, seed the edit buffer with the initial
        // data & return.
        if (isCreate) {
          if (isFirstLoad) this.updateEditBuffer(config.name, initial);
          return;
        }

        // If this is not the first load && the url of this resource has not
        // changed, no further action needed.
        if (!isFirstLoad) {
          const { match: prevMatch, location: prevLocation } = prevProps;
          const { params: prevParams } = prevState;
          const { url: prevUrl } = computeConfig(
            config,
            prevParams,
            prevMatch,
            prevLocation,
          );
          if (prevUrl === url) return;
        }

        // If the resource is already loaded or loading in the store & this
        // config allows us to use cached data & the data isn't marked stale,
        // we can use the stored resource. No further action needed.
        const existingResource = resources[url];
        const isCacheHit =
          isCached &&
          existingResource &&
          existingResource.data &&
          !existingResource.stale &&
          existingResource.status !== 'failed';
        if (isCacheHit) return;

        // Fetch the resource from origin.
        getResource({ url });
      });
    };

    // Merge a patch into the edit buffer for a named resource.
    updateEditBuffer = (name, data, callBack) => {
      this.setState(
        ({ editBuffers }) => {
          const existingData = editBuffers[name] || {};
          return {
            editBuffers: {
              ...editBuffers,
              [name]: { ...existingData, ...data },
            },
          };
        },
        () => {
          if (typeof callBack === 'function') callBack();
        },
      );
    };

    // Create the Query API object which will be passed to the inner component via
    // its props.
    createQuery = config => {
      const { params } = this.state;
      const {
        resources,
        getResource,
        getSubResource,
        invalidateResource,
        match,
        location,
        replaceResource,
      } = this.props;
      const { url } = computeConfig(config, params, match, location);
      const { [url]: resource } = resources;
      const { data, stale, status, errors } = resource || {};

      // If data is stale, re-request it
      if (stale && status !== 'loading') getResource({ url });

      return {
        id: url,
        data: data || {},
        // All resources start out loading unless flagged as `create`, in which
        // case there is no loading to do.
        status: status || 'loading',
        errors: errors || {},
        invalidate: () => invalidateResource({ url }),
        reload: (succeed, fail) => getResource({ url, succeed, fail }),
        reloadSubResource: (id, succeed, fail) =>
          getSubResource({ url, id, succeed, fail }),
        replace: data => replaceResource({ url, data }),
      };
    };

    // Create the Mutator API object which will be passed to the inner component via
    // its props.
    createMutator = config => {
      const {
        putResource,
        postResource,
        deleteResource,
        match,
        location,
      } = this.props;
      const { params } = this.state;
      const { url, dependentUrls, isAppend, isCreate } = computeConfig(
        config,
        params,
        match,
        location,
      );

      // Get all currently cached resources that match the dependentUrls
      // array, disregarding query strings.
      const expandDependentUrls = () => {
        const { resources } = this.props;
        return Object.keys(resources).filter(resourceUrl => {
          const resourceUrlNoQuery = resourceUrl.split('?')[0];
          return dependentUrls.includes(resourceUrlNoQuery);
        });
      };

      // Get latest resource data incorporating changes from edit buffer.
      const getResourceWithEdits = () => {
        const { resources } = this.props;
        const {
          editBuffers: { [config.name]: editBuffer },
        } = this.state;
        let { [url]: resource } = resources;
        resource = resource || {};
        let { data } = resource;
        if (isCreate) data = {};
        return {
          ...resource,
          dataBeforeEdits: data,
          data: { ...(data || {}), ...(editBuffer || {}) },
        };
      };

      const save = (action, options, succeed, fail) => {
        // When doing save operations make sure we have the latest data
        const { data } = getResourceWithEdits();
        const payload = {
          url,
          data,
          succeed,
          fail,
          dependentUrls: expandDependentUrls(),
          options: options || {},
        };
        switch (action) {
          case 'delete':
            return deleteResource(payload);
          case 'edit':
            return putResource(payload);
          default:
            return postResource({ ...payload, action, isAppend });
        }
      };

      const updateAndSave = (data, ...saveArgs) => {
        this.updateEditBuffer(config.name, data, () => save(...saveArgs));
      };

      const {
        data,
        dataBeforeEdits,
        stale,
        status,
        errors,
        progress,
      } = getResourceWithEdits();

      // If data is stale, re-request it
      if (stale && status !== 'loading') getResource({ url });

      return {
        data,
        dataBeforeEdits,
        id: url,
        status: status || (isCreate ? 'success' : 'loading'),
        errors: errors || {},
        progress: progress || 0,
        update: key => value =>
          new Promise(resolve =>
            this.updateEditBuffer(config.name, { [key]: value }, resolve),
          ),
        updateWithCallback: key => (value, callback) =>
          this.updateEditBuffer(config.name, { [key]: value }, callback),
        save,
        updateAndSave,
        bulkUpdate: data =>
          new Promise(resolve =>
            this.updateEditBuffer(config.name, data, resolve),
          ),
        bulkUpdateWithCallback: (data, callback) =>
          this.updateEditBuffer(config.name, data, callback),
        revert: field =>
          new Promise(resolve =>
            this.setState(({ editBuffers }) => {
              editBuffers = _.cloneDeep(editBuffers);
              delete editBuffers[config.name][field];
              return { editBuffers };
            }, resolve),
          ),
        reset: () =>
          this.setState(({ editBuffers }) => ({
            editBuffers: { ...editBuffers, [config.name]: {} },
          })),
        updateProgress: progress => updateResourceProgress({ url, progress }),
        invalidate: () => {
          if (isCreate)
            throw new Error('Cannot call invalidate on a create mutator.');
          invalidateResource({ url });
        },
        reload: (succeed, fail) => {
          if (isCreate)
            throw new Error('Cannot call reload on a create mutator.');
          return getResource({ url, succeed, fail });
        },
        reloadSubResource: (id, succeed, fail) => {
          if (isCreate)
            throw new Error(
              'Cannot call reloadSubResource on a create mutator.',
            );
          getSubResource({ url, id, succeed, fail });
        },
        replace: data => {
          if (isCreate)
            throw new Error('Cannot call replace on a create mutator.');
          replaceResource({ url, data });
        },
      };
    };

    // Merge a patch with the passed in `params` prop. This is done on a copy of
    // the prop held in this component's state so as to avoid mutating props.
    // This method is exposed to the inner component.
    setQueryParams = patch => {
      this.setState(({ params }) => ({ params: { ...params, ...patch } }));
    };

    // Invalidate any resource in the store whose URL matches any of the
    // passed-in regex patterns.
    // This method is exposed to the inner component.
    invalidateByPatterns = patterns => {
      const regexes = patterns.map(patt => new RegExp(patt));
      const { resources, invalidateResource } = this.props;
      Object.keys(resources)
        .filter(url => regexes.some(regex => regex.test(url)))
        .forEach(url => invalidateResource({ url }));
    };

    render() {
      const { params } = this.state;

      // Create the query & mutator objects from the current resources state.
      const queryProps = {};
      const mutatorProps = {};
      queryConfigs.forEach(
        config => (queryProps[config.name] = this.createQuery(config)),
      );
      mutatorConfigs.forEach(
        config => (mutatorProps[config.name] = this.createMutator(config)),
      );

      // Don't forward props which were added to the wrapper component by
      // the `connect` HOC.
      const forwardedProps = { ...this.props };
      delete forwardedProps.resources;
      delete forwardedProps.getResource;
      delete forwardedProps.invalidateResource;
      delete forwardedProps.postResource;
      delete forwardedProps.putResource;
      delete forwardedProps.deleteResource;
      delete forwardedProps.replaceResource;
      delete forwardedProps.updateResourceProgress;
      return (
        <InnerComponent
          {...forwardedProps}
          {...queryProps}
          {...mutatorProps}
          queryParams={params}
          setQueryParams={this.setQueryParams}
          invalidateByPatterns={this.invalidateByPatterns}
        />
      );
    }
  };
};

// Connect the component to the store & export it.
const withApi = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouteHelpers,
  WrappedComponent,
);

export default withApi;
