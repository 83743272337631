import React from 'react';
import { withStyles } from '@mui/styles';

const styles = {
  root: { fontWeight: '700', marginBottom: '5px' },
};

const CellTitle = props => {
  const { classes, children, 'data-test-id': dataTestId } = props;
  return (
    <div className={classes.root} data-test-id={dataTestId}>
      {children}
    </div>
  );
};

export default withStyles(styles)(CellTitle);
