import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';

const styles = {
  root: {
    fontSize: '2.30769231em',
    background: '#ffffff',
    color: '#e6e7ea',
    fontWeight: 'bold',
    fontStyle: 'italic',
    marginTop: '2px',
    padding: '50px',
    textAlign: 'center',
  },
  minimal: {
    fontSize: '1.07692308em',
    background: 'none',
    padding: '3px 0',
    textAlign: 'inherit',
  },
  dark: {
    color: '#979ca5',
  },
};

const Blankslate = props => {
  const { children, classes, minimal, theme } = props;
  const rootClasses = classNames(classes.root, {
    [classes.minimal]: minimal,
    [classes.dark]: theme === 'dark',
  });
  return <div className={rootClasses}>{children}</div>;
};

export default withStyles(styles)(Blankslate);
