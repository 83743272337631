import queryParam from 'common/utilities/queryParam';
import snakeCase from 'lodash.snakecase';

const listParams = query => {
  const page = queryParam(query.page, 1);
  const per_page = queryParam(query.perPage, 10);
  const sort = snakeCase(queryParam(query.orderBy, 'name'));
  const direction = queryParam(query.order, 'asc');
  const mapped = queryParam(query.mapped, true);
  const unmapped = queryParam(query.unmapped, true);
  const visible = queryParam(query.visible, true);
  const hidden = queryParam(query.hidden, false);
  const q = queryParam(query.search, '');
  const params = {
    page,
    per_page,
    sort,
    direction,
    mapped,
    unmapped,
    visible,
    hidden,
  };
  if (q) params.q = q;
  return params;
};

export default listParams;
