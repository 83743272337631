import React from 'react';

// HoC
import { withStyles } from '@mui/styles';
import { graphql } from 'react-apollo';
import { compose } from 'redux';
import { connect } from 'react-redux';

// GQL
import { Queries, Mutations } from 'common/apollo';

// MuiComponents
import {
  Button,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';

// Icons
import { Search as SearchIcon } from '@mui/icons-material';

// Components

import Loader from 'common/components/Loader';
import { AdminUserForm, AdminUserList } from 'admin/components/AdminUser';
import Header from 'common/oldJavascripts/components/Base/Header';

// constants
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';

// actions
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';

// utilities
import validateEmail from 'common/utilities/validateEmail';
import isNameValid from 'common/utilities/isNameValid';

const styles = {
  search: {
    paddingRight: 16,
    width: 200,
  },
};

class AdminPage extends React.Component {
  state = {
    filter: [],
    isOpen: false,
    comfirmDelete: null,
    user: {},
    permissionSelection: [],
  };

  handleFilter = event => {
    const {
      target: { value },
    } = event;

    this.setState({
      filter: value
        .replace(/,/gi, '')
        .trim()
        .toLowerCase()
        .split(' '),
    });
  };

  filterCallbak = item => {
    const { filter = [] } = this.state;
    const { email = '', firstName = '', lastName = '' } = item || {};
    if (filter.length === 0) return true;
    return filter.some(
      word =>
        email.toLowerCase().includes(word) ||
        firstName.toLowerCase().includes(word) ||
        lastName.toLowerCase().includes(word),
    );
  };

  handleUserStatusChange = id => event => {
    const {
      target: { checked },
    } = event;
    const {
      AdminQuery,
      SetActiveAdminUser,
      pushNotification,
      popNotification,
    } = this.props;

    // Notifications
    pushNotification({
      message: 'Updating user status.',
      variant: SnackbarVariants.INFO,
    });

    SetActiveAdminUser({
      variables: {
        id: parseInt(id, 10),
        active: checked,
      },
    })
      .then(() => {
        popNotification();
        pushNotification({
          message: 'User status update successfully completed.',
          variant: SnackbarVariants.SUCCESS,
        });
        AdminQuery.refetch();
      })
      .catch(() => {
        // TODO: Update the message when BE message is ready
        pushNotification({
          message:
            'There was an error updating the user status.  Please try again, or contact product support if the issue persists.',
          variant: SnackbarVariants.ERROR,
        });
        AdminQuery.refetch();
      });
  };

  handleEdit = id => event => {
    const { AdminQuery: { adminUsers = [] } = {} } = this.props;
    const theUser = adminUsers.find(u => u.id === id);

    let [{ permissions }] = theUser['userPermissions'];
    permissions = permissions.map(p => p.id);

    if (theUser) {
      this.setState({
        user: { id: theUser.id, ...theUser.profile },
        permissionSelection: [...permissions],
        isOpen: true,
      });
    }
  };

  handleDelete = () => {
    const { confirmDeleteId } = this.state;
    // Close the confirm dialog
    this.setState({ confirmDeleteId: null });

    const {
      AdminQuery,
      DeleteAdminUser,
      pushNotification,
      popNotification,
    } = this.props;

    // Notifications
    pushNotification({
      message: 'Deleting selected user.',
      variant: SnackbarVariants.INFO,
    });

    // Delete User
    DeleteAdminUser({ variables: { id: parseInt(confirmDeleteId, 10) } })
      .then(() => {
        popNotification();
        pushNotification({
          message: 'Successfully deleted the selected user.',
          variant: SnackbarVariants.SUCCESS,
        });
        AdminQuery.refetch();
      })
      .catch(() => {
        // TODO: Update error message while BE is ready
        popNotification();
        pushNotification({
          message:
            'There was an error deleting the user.  Please try again, or contact product support if the issue persists.',
          variant: SnackbarVariants.ERROR,
        });
        AdminQuery.refetch();
      });
  };

  handleCancel = () => {
    this.setState(() => {
      return {
        isOpen: false,
        permissionSelection: [],
        user: {},
        errors: {},
      };
    });
  };

  handleSave = () => {
    const {
      CreateAdminUser,
      UpdateAdminUser,
      AdminQuery,
      pushNotification,
      popNotification,
    } = this.props;
    const {
      user: { id, email, firstName, lastName },
      permissionSelection,
    } = this.state;

    // Validate Inputs
    const errors = this.validateInputs();
    this.setState({ errors });
    // Do not proceed
    if (errors) return;

    // Notifications
    pushNotification({
      message: `Saving user: ${firstName} ${lastName}.`,
      variant: SnackbarVariants.INFO,
    });

    // Prepare data
    const variables = {
      email,
      firstName,
      lastName,
      permissions: permissionSelection.map(p => parseInt(p, 10)),
    };
    if (id) variables.id = parseInt(id, 10);
    const mutate = !!id ? UpdateAdminUser : CreateAdminUser;

    // Create or Update Call
    mutate({ variables })
      .then(() => {
        this.setState({
          isOpen: false,
          permissionSelection: [],
          user: {},
        });
        popNotification();
        pushNotification({
          message: `Successfully saved user: ${firstName} ${lastName}.`,
          variant: SnackbarVariants.SUCCESS,
        });
        AdminQuery.refetch();
      })
      .catch((err, data) => {
        // Format Error Messages
        let { message: errMessage = '' } = err || {};

        if (errMessage.startsWith('GraphQL error:')) {
          errMessage = errMessage.substring(15);
        } else {
          errMessage = `There was an error saving user: ${firstName} ${lastName}.  Please try again, or contact product support if the issue persists.`;
        }

        // Pass down errors
        this.setState({ errors: { root: errMessage } });

        // Notifications
        popNotification();
        pushNotification({
          message: errMessage,
          variant: SnackbarVariants.ERROR,
        });
        AdminQuery.refetch();
      });
  };

  handleUserChange = attr => newValue => {
    if (attr === 'presetId') {
      // ReactSelect sends "newValue"
      // it could be null or undefined or Object

      if (newValue) {
        // The [Preset] ReactSelect sends an object
        const { value } = newValue;
        const { PresetQuery: { permissionPresets = [] } = {} } = this.props;
        const { permissions = [] } =
          permissionPresets.find(p => p.id === value) || {};
        const thePermissions = permissions.map(p => p.id);

        this.setState({
          permissionSelection: [...thePermissions],
          user: { ...this.state.user, presetId: value },
        });
      } else {
        // The [Preset] ReactSelect sends an null
        this.setState({
          permissionSelection: [],
          user: { ...this.state.user, presetId: '' },
        });
      }
    } else {
      // FirstName, LastName, Email: child conponent sends validated value
      this.setState({
        user: { ...this.state.user, [attr]: newValue },
      });
    }
  };

  handlePermissionChange = groupId => newValue => {
    // Expect child [PermissionExpansionPanel] to pass [Array: newValue]
    if (Array.isArray(newValue))
      this.setState(() => {
        const { user } = this.state;
        user.presetId = '';

        return { user, permissionSelection: newValue };
      });
  };

  confirmDelete = id => event => {
    this.setState({ confirmDeleteId: id });
  };

  cancelDelete = () => {
    this.setState({ confirmDeleteId: null });
  };

  validateInputs = () => {
    // This is to validate required fields not empty
    const {
      user: { email = '', firstName = '', lastName = '' },
    } = this.state;
    let error = null;

    if (isNameValid(firstName) === false)
      error = {
        ...error,
        firstName:
          'First Name is not valid, only alphabet characters . - are allowed',
      };

    if (firstName.length === 0)
      error = { ...error, firstName: 'First Name is required' };

    if (isNameValid(lastName) === false)
      error = {
        ...error,
        lastName:
          'Last Name is not valid, only alphabet characters . - are allowed',
      };

    if (lastName.length === 0)
      error = { ...error, lastName: 'Last Name is required' };

    if (validateEmail(email) === false)
      error = { ...error, email: 'Email is not valid' };

    if (email.length === 0) error = { ...error, email: 'Email is required' };

    return error;
  };

  render() {
    const { user, errors, isOpen, confirmDeleteId } = this.state;
    const { classes, AdminQuery = {}, PermissionQuery = {} } = this.props;

    const { permissions: allPermissions = [] } = PermissionQuery;

    let { adminUsers = [] } = AdminQuery;
    adminUsers = adminUsers.map(u => ({
      id: u.id,
      firstName: u.profile.firstName,
      lastName: u.profile.lastName,
      email: u.profile.email,
      active: u.userPermissions[0].active,
      permissions: u.userPermissions[0].permissions,
    }));
    let filteredAdmins = adminUsers.filter(this.filterCallbak);

    return (
      <Paper elevation={0}>
        <Header>
          <Header.Title>
            <span data-test-id="AdminUsers-title">Admin Users</span>
          </Header.Title>
          <Header.Nav>
            <TextField
              variant="standard"
              className={classes.search}
              margin="dense"
              placeholder="Filter Admin Users..."
              onChange={this.handleFilter}
              InputProps={{
                inputProps: { 'data-test-id': 'Admins-search' },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.setState({ isOpen: true });
              }}
              data-test-id="Admins-add"
            >
              Add New Admin
            </Button>
          </Header.Nav>
        </Header>

        {AdminQuery.loading && <Loader size={36} />}

        <AdminUserList
          collection={filteredAdmins}
          onStatusChange={this.handleUserStatusChange}
          onEdit={this.handleEdit}
          onDelete={this.confirmDelete}
        />

        <Dialog open={isOpen} maxWidth="md" scroll="body">
          <DialogContent>
            <AdminUserForm
              user={user}
              errors={errors}
              selections={this.state.permissionSelection}
              permissionGroups={allPermissions}
              onPermissionChange={this.handlePermissionChange}
              onChange={this.handleUserChange}
            />
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleCancel}
              data-test-id="AddAdminUser-Cancel"
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSave}
              data-test-id="AddAdminUser-Save"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={confirmDeleteId != null} maxWidth="sm">
          <DialogContent>
            <Typography variant="h6">
              Are you sure to delete this admin user?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.cancelDelete}
              color="secondary"
              data-test-id="DeleteAdminUser-Cancel"
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleDelete}
              color="secondary"
              data-test-id="DeleteAdminUser-OK"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  pushNotification,
  popNotification,
};

export default compose(
  graphql(Mutations.SetActiveAdminUser, {
    name: 'SetActiveAdminUser',
  }),
  graphql(Mutations.CreateAdminUser, {
    name: 'CreateAdminUser',
  }),
  graphql(Mutations.DeleteAdminUser, {
    name: 'DeleteAdminUser',
  }),
  graphql(Mutations.UpdateAdminUser, {
    name: 'UpdateAdminUser',
  }),
  graphql(Queries.GetPermissions, {
    name: 'PermissionQuery',
    options: props => ({
      variables: {
        admin: true,
      },
    }),
  }),
  graphql(Queries.GetPermissionPresets, {
    name: 'PresetQuery',
  }),
  graphql(Queries.GetAdminUsers, {
    name: 'AdminQuery',
    options: () => ({
      pollInterval: 10000,
    }),
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AdminPage);
