import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Icon from './Icon';
import domClasses from '../../utils/dom/classes.js';
import { withRouter } from 'react-router';
import classNames from 'class-names';
import { withStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DOMPurify from 'dompurify';
import LensIcon from '@mui/icons-material/Lens';
import StepConnector from '@mui/material/StepConnector';

const navStyles = {
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
};

const logoStyles = {
  appVersion: {
    color: '#fff',
    padding: 10,
    position: 'absolute',
    top: '70%',
    left: '3%',
  },
  poweredBy: {
    top: '50%',
    left: '3%',
    color: '#fff',
    paddingTop: '15px',
    position: 'absolute',
    fontSize: '9px',
    fontWeight: 'bold',
  },
  small: {
    color: 'lightgrey',
    fontWeight: 'normal',
  },
};

const progressStyles = {
  root: {
    background: 'transparent',
    padding: 0,
    marginTop: '1rem',
  },
  main: {
    top: '-100%',
    left: '47%',
    width: '60%',
    padding: 0,
    position: 'relative',
  },
  lineRoot: {
    top: '6px',
    left: '-50%',
    right: 0,
    width: '100%',
    zIndex: 1,
    position: 'absolute',
  },
  connectorLine: {
    transition: 'none',
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: '#FFFFFF',
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: '#FFFFFF',
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: '#00DF68',
    },
  },
  iconRoot: {
    color: '#00DF68',
    fontSize: '12px',
    zIndex: 2,
  },
  alternativeLabel: {
    marginTop: '0 !important',
    color: '#FFFFFF',
    fontWeight: 700,
  },
  completedLabel: {
    color: '#00DF68 !important',
    fontWeight: '700 !important',
  },
  activeLabel: {
    color: '#FFFFFF !important',
    fontWeight: '700 !important',
  },
  iconDisabled: {
    color: '#FFFFFF',
    fontSize: '12px',
    zIndex: 2,
  },
};

const connector = classes => {
  const {
    lineRoot,
    connectorActive,
    connectorCompleted,
    connectorDisabled,
    connectorLine,
  } = classes;
  return (
    <StepConnector
      classes={{
        root: lineRoot,
        active: connectorActive,
        completed: connectorCompleted,
        disabled: connectorDisabled,
        line: connectorLine,
      }}
    />
  );
};

var MainHeader = null;

MainHeader = class extends React.Component {
  render() {
    var classes = null;

    classes = domClasses.set({
      mainHeader: true,
      'mainHeader--secondary': this.props.secondary,
    });

    return <header className={classes}>{this.props.children}</header>;
  }
};

class Logo extends React.Component {
  state = {
    clickCount: 0,
  };

  displayAppVersion = () => {
    this.setState(({ clickCount }) => {
      return {
        clickCount: clickCount + 1,
      };
    });
  };

  render() {
    const { clickCount } = this.state;
    const displayVersion = clickCount >= 10;
    const { classes } = this.props;
    return (
      <>
        <img
          data-test-id="MainHeader-StartPlusLogo"
          className="mainHeader-logo"
          src={this.props.src}
          alt={this.props.alt}
          onClick={this.displayAppVersion}
        />
        {displayVersion && (
          <span
            data-test-id="MainHeader-appVersion"
            className={classes.appVersion}
          >
            v{process.env.REACT_APP_VERSION}
          </span>
        )}
      </>
    );
  }
}

MainHeader.Logo = withStyles(logoStyles)(Logo);
MainHeader.Title = class extends React.Component {
  render() {
    return <div className="mainHeader-title">{this.props.children}</div>;
  }
};

class Nav extends React.Component {
  render() {
    const {
      regular,
      secondary,
      children,
      flex = false,
      classes = {},
    } = this.props;
    const className = classNames({
      'mainHeader-nav': true,
      'mainHeader-nav--regular': regular,
      'mainHeader-nav--secondary': secondary,
      [classes.flex]: flex,
    });

    return <nav className={className}>{children}</nav>;
  }
}

MainHeader.Nav = withStyles(navStyles)(Nav);

MainHeader.Item = withRouter(
  class extends React.Component {
    render() {
      const { location = {}, to, isActive, ...rest } = this.props;
      const { pathname } = location || {};
      var classes = null;
      if (to) {
        const linkClass = classNames(
          {
            'mainHeader-item--link': !!to,
            'is-active': pathname.includes(to),
          },
          'mainHeader-item',
        );
        if (typeof isActive === 'undefined') {
          return (
            <RouterLink
              {...rest}
              to={`/${to}`}
              className={linkClass}
              params={this.props.params}
              query={this.props.query}
            >
              {this.props.children}
            </RouterLink>
          );
        } else {
          const linkClass = classNames(
            {
              'mainHeader-item--link': !!to,
              'is-active': isActive,
            },
            'mainHeader-item',
          );

          return (
            <RouterLink
              {...rest}
              to={`/${to}`}
              className={linkClass}
              params={this.props.params}
            >
              {this.props.children}
            </RouterLink>
          );
        }
      } else {
        return <span className={classes}>{this.props.children}</span>;
      }
    }
  },
);

MainHeader.SubNavItem = withRouter(
  class extends React.Component {
    render() {
      const { location = {}, to, isActive, ...rest } = this.props;
      const { pathname } = location || {};
      var classes = null;
      if (to) {
        const linkClass = classNames(
          {
            'subnav-item--link': !!to,
            'subnav-item--is-active': pathname.includes(to),
          },
          'subnav-item',
        );
        if (typeof isActive === 'undefined') {
          return (
            <RouterLink
              {...rest}
              to={`/${to}`}
              className={linkClass}
              params={this.props.params}
              query={this.props.query}
            >
              {this.props.children}
            </RouterLink>
          );
        } else {
          const linkClass = classNames(
            {
              'subnav-item--link': !!to,
              'subnav-item--is-active': isActive,
            },
            'subnav-item',
          );

          return (
            <RouterLink
              {...rest}
              to={`/${to}`}
              className={linkClass}
              params={this.props.params}
            >
              {this.props.children}
            </RouterLink>
          );
        }
      } else {
        return <span className={classes}>{this.props.children}</span>;
      }
    }
  },
);

MainHeader.TitleNav = class extends React.Component {
  render() {
    return <div className="mainHeader-titleNav">{this.props.children}</div>;
  }
};

MainHeader.Icon = class extends React.Component {
  render() {
    return (
      <Icon
        className="mainHeader-icon"
        name={DOMPurify.sanitize(this.props.name)}
      />
    );
  }
};

class OnBoardingProgress extends React.Component {
  render() {
    const { steps, currentStep, classes = {} } = this.props;
    const {
      alternativeLabel,
      completedLabel,
      activeLabel,
      iconRoot,
      iconDisabled,
    } = classes;

    return (
      <nav className={classes.main}>
        <Stepper
          className={classes.root}
          activeStep={currentStep}
          alternativeLabel
          connector={connector(classes)}
        >
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel
                classes={{
                  alternativeLabel,
                  active: activeLabel,
                  completed: completedLabel,
                }}
                icon={
                  <LensIcon
                    classes={{
                      root: index < currentStep ? iconRoot : iconDisabled,
                    }}
                  />
                }
              >
                {step.desc}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </nav>
    );
  }
}

MainHeader.Progress = withStyles(progressStyles)(OnBoardingProgress);

export default MainHeader;
