import React from 'react';
import ReactSelect from 'react-select';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    marginBottom: 20,
  },
});

const reactSelectStyles = {
  control: (styles, state) => ({
    ...styles,
    '&:hover': {
      borderColor: '#0000FF',
    },
    borderColor:
      state.isActive || state.isFocused ? '#0000FF' : styles.borderColor,
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected
      ? '#0000FF'
      : state.isFocused
      ? '#E2F1FF'
      : styles.backgroundColor,
  }),
};

const LabeledSelect = props => {
  const {
    testId,
    label,
    options,
    value,
    onChange,
    disabled,
    clearable,
    loading,
    required,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.root} data-test-id={testId}>
      <label className="field-label">
        {label}
        {required && <span className="field-required">*</span>}
      </label>
      <ReactSelect
        options={options}
        value={options.find(opt => opt.value === value)}
        onChange={sel => onChange(sel?.value, sel?.label)}
        isDisabled={disabled}
        isClearable={clearable}
        isLoading={loading}
        styles={reactSelectStyles}
      />
    </div>
  );
};

export default LabeledSelect;
