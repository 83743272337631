export const REGULAR_STYLES = {
  fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif !important',
  color: '#616161 !important',
};

export const MYFILES_NEXT_BTN = {
  height: '40px !important',
  borderRadius: '2 !important',
  backgroundColor: '#0000FF !important',
  color: 'white !important',
  cursor: 'pointer !important',
  fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif !important',
  fontWeight: '500 !important',
  fontSize: '14px !important',
};

export const MYFILES_CANCEL_BTN = {
  color: '#0000FF',
  padding: '6px 15px',
  border: '1px solid #0000FF',
  cursor: 'pointer',
  marginRight: '1rem',
  fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif !important',
  fontSize: '14px',
  height: '40px',
  fontWeight: 500,
};
