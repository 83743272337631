import React from 'react';
import { Switch, Route } from 'react-router-dom';

// components for Pendo
import Feature from 'common/components/Feature';
import PendoController from 'common/components/PendoController';

// Layout
import ProjectLayout from 'common/oldJavascripts/components/Pages/Layouts/Project';

// Studio Admins
import AddCrewMember from 'studio/components/AddCrewMember';
import CrewList from 'studio/components/ManageCrew';
import ImportCrew from 'studio/oldJavascripts/components/Pages/Project/Crew/Import';
import DownloadCrew from 'studio/components/DownloadCrew';

export default props => {
  return (
    <ProjectLayout>
      <Switch>
        <Route
          exact
          path="/projects/:projectId/crew/new"
          component={AddCrewMember}
        />
        <Route
          exact
          path="/projects/:projectId/crew/import"
          component={ImportCrew}
        />
        <Route
          exact
          path="/projects/:projectId/crew/:fileId"
          component={DownloadCrew}
        />
        <Route component={CrewList} />
      </Switch>
      <Feature name="pendoIntegration">
        <PendoController projectId={props?.match?.params?.projectId} />
      </Feature>
    </ProjectLayout>
  );
};
