import React from 'react';
import withAuthProtection from 'common/hoc/withAuthProtection';
import Routes from './Routes';
import SnackbarController from 'common/containers/SnackbarController';
import DialogController from 'common/containers/DialogController';
import SessionManagementController from 'common/containers/SessionManagementController';
import PendoController from 'common/components/PendoController';
import Feature from 'common/components/Feature';

import './App.css';
import './common/oldLess/styles.css';
import 'react-treeview/react-treeview.css';
import 'mobile/common/styles.css';

const App = () => (
  <div className="container">
    <div className="App">
      <Routes />
      <SnackbarController />
      <DialogController />
      <SessionManagementController />
      <Feature name="pendoIntegration">
        <PendoController init />
      </Feature>
    </div>
  </div>
);

export default withAuthProtection()(App);
