import React from 'react';

// Components
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MaskedInput from 'react-text-mask';

// Constants
import { ratePerHourMask } from '../../helpers/textFieldNumberMasks';

// Utilities
import formatCurrency from '../../helpers/formatCurrency';

const LocationRatePerWeek = ({
  onChange: upstreamOnChange,
  formData: { locationRatePerWeek } = {},
  classes = {},
}) => {
  const onChange = ({ target: { value } = {} } = {}) => {
    /*
    Rate per week does not calculate any overtime.
    - If the user is getting rid of the value by submitting 0 or '' then just update this value
    - If they are giving us a rate then clear overtime since overtime is calculated by rate per hour
      And clear the rate per hour fields
     */
    if (value === '' || value === '0') {
      upstreamOnChange({
        locationRatePerWeek: null,
      });
      return;
    }
    upstreamOnChange({
      locationRatePerWeek: value,
      overtimeRatePerHour: null,
      studioRatePerHour: null,
      locationRatePerHour: null,
    });
    return;
  };

  const onBlur = ({ target: { value } = {} } = {}) => {
    if (value === '' || value === '0') return;

    const formattedValue = formatCurrency(value);

    upstreamOnChange({
      locationRatePerWeek: formattedValue,
      overtimeRatePerHour: null,
      studioRatePerHour: null,
      locationRatePerHour: null,
    });
    return;
  };

  return (
    <TableCell align="center" className={classes.root}>
      <MaskedInput
        mask={ratePerHourMask}
        value={locationRatePerWeek || ''}
        onChange={onChange}
        onBlur={onBlur}
        render={(ref, props) => (
          <TextField
            inputRef={ref}
            {...props}
            variant="standard"
            className={classes.textField}
            InputProps={{
              className: classes.textFieldInputContainer,
              inputProps: {
                className: classes.textFieldInput,
                'data-test-id': 'LocationRatePerWeek-input',
              },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        )}
      />
    </TableCell>
  );
};

export default LocationRatePerWeek;
