import React from 'react';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';

const styles = {
  root: {
    textDecoration: 'none',
    '&:nth-child(odd)': { background: '#fafafa' },
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      background: '#e0e9ef',
    },
  },
  expanded: { borderLeft: '3px solid #0000FF' },
  expandedAndClickable: {
    borderLeft: '0px',
  },
};

const Row = props => {
  const { children, classes, onClick, rowExpanded } = props;
  const rootClass = classNames(classes.root, {
    [classes.clickable]: !!onClick,
    [classes.expanded]: rowExpanded,
    [classes.expandedAndClickable]: !!onClick && rowExpanded,
  });
  return (
    <tr
      className={rootClass}
      onClick={onClick}
      data-test-id={props['data-test-id']}
    >
      {children}
    </tr>
  );
};

export default withStyles(styles)(Row);
