import moment from 'moment-timezone';
import jstz from 'jstz';

const UNCAPITALIZED_WORDS = {
  a: true,
  an: true,
  and: true,
  at: true,
  but: true,
  by: true,
  for: true,
  in: true,
  nor: true,
  of: true,
  on: true,
  or: true,
  so: true,
  the: true,
  to: true,
  up: true,
  yet: true,
};

export const padNumber = number => {
  number = number + '';
  return number.length >= 2 ? number : '0' + number;
};

export const currency = (value, fractionalDigits, omitSymbol) => {
  const match = String(value).match(/^(-)?\$?(\d*)(.\d*)?$/);
  if (!match || !(match[2] || match[3])) {
    return '';
  }
  const parts = [];
  if (match[1] === '-') {
    parts.push('-');
  }
  if (!omitSymbol) {
    parts.push('$');
  }
  parts.push(match[2] ? parseInt(match[2], 10) : '0');
  const fractionAndPoint = match[3] || '.';
  const fraction = fractionAndPoint.substr(1);
  if (typeof fractionalDigits !== 'number') {
    switch (fraction.length) {
      case 0: {
        break;
      }
      case 1:
      case 3: {
        parts.push(`${fractionAndPoint}0`);
        break;
      }
      case 2:
      case 4: {
        parts.push(fractionAndPoint);
        break;
      }
      default: {
        parts.push(fractionAndPoint.substr(0, 5));
      }
    }
  } else if (fractionalDigits > 0) {
    parts.push(fractionAndPoint.substr(0, fractionalDigits + 1));
    for (let i = fraction.length; i < fractionalDigits; ++i) {
      parts.push('0');
    }
  }
  return parts.join('');
};

export const createOfferCurrency = (
  value,
  fractionalDigits = 9,
  omitSymbol,
) => {
  const match = String(value).match(/^(-)?\$?(\d*)(.\d*)?$/);
  if (!match || !(match[2] || match[3])) {
    return '';
  }
  const parts = [];
  if (match[1] === '-') {
    parts.push('-');
  }
  if (!omitSymbol) {
    parts.push('$');
  }
  parts.push(match[2] ? parseInt(match[2], 10) : '0');
  const fractionAndPoint = match[3] || '.';
  const fraction = fractionAndPoint.substr(1);
  if (typeof fractionalDigits !== 'number') {
    switch (fraction.length) {
      case 0: {
        break;
      }
      case 1:
      case 3: {
        parts.push(`${fractionAndPoint}0`);
        break;
      }
      case 2:
      case 4: {
        parts.push(fractionAndPoint);
        break;
      }
      default: {
        parts.push(fractionAndPoint.substr(0, 5));
      }
    }
  } else if (fractionalDigits > 0) {
    parts.push(fractionAndPoint.substr(0, fractionalDigits + 1));
    // If they only put the first digit after the decimal add another one
    // Because $21.1 looks stupid and it should be $21.10
    for (let i = fraction.length; i < 2; ++i) {
      parts.push('0');
    }
  }
  return parts.join('');
};

export const time = timeStr => {
  const timezone = jstz.determine();
  const thisTime = moment(timeStr)
    .tz(timezone.name())
    .format('MM-DD-YYYY h:mm:ss a');

  return thisTime || timeStr;
};

export const localtime = timeStr => {
  const thisTime = moment.utc(timeStr);
  return thisTime.local().format('MM-DD-YYYY h:mm:ss a');
};

export const date = (date, machine) => {
  if (!date) {
    return;
  }

  const timezone = jstz.determine();
  const momentDate = moment(date).tz(timezone.name());

  return machine
    ? momentDate.format('YYYY-MM-DD')
    : momentDate.format('MM-DD-YYYY');
};

export const MILLISECONDS_DAY = 1000 * 60 * 60 * 24;

export const dateDiff = (start_date, end_date) => {
  return end_date.getTime() - start_date.getTime();
};

export const daysAgo = _date => {
  var today = new Date();
  var date = new Date(_date);

  return Math.floor(dateDiff(date, today) / MILLISECONDS_DAY);
};

export const decimal = (number, decimals) => {
  if (number) {
    return global.parseFloat(number).toFixed(decimals);
  }
};

export const fullName = (firstName = '', middleName = '', lastName = '') => {
  return (lastName + ', ' + firstName + ' ' + (middleName[0] || '')).trim();
};

export const signatureName = (firstName, lastName) => {
  return `${firstName || ''} ${lastName || ''}`.trim();
};

export const capitalize = word => {
  word = String(word);
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const fieldName = (name, options) => {
  return String(name)
    .replace(options && options.removeTrailingDigits ? /_\d+$/ : '', '')
    .split('_')
    .filter(Boolean)
    .map((word, index) => (index ? titleCaseWord(word) : capitalize(word)))
    .join(' ');
};

export const titleCaseWord = word => {
  word = String(word);
  if (UNCAPITALIZED_WORDS[word.toLowerCase()]) {
    return word;
  }
  return capitalize(word);
};

export const yesNo = value => {
  return value ? 'Yes' : 'No';
};
export default {
  padNumber,
  currency,
  createOfferCurrency,
  time,
  localtime,
  date,
  MILLISECONDS_DAY,
  dateDiff,
  daysAgo,
  decimal,
  fullName,
  signatureName,
  capitalize,
  fieldName,
  titleCaseWord,
  yesNo,
};
