import React from 'react';
import { withStyles } from '@mui/styles';
import { FormControl, Checkbox, FormControlLabel } from '@mui/material';
import classNames from 'classnames';
import uniqueId from 'common/utilities/uniqueId';
import { pink } from '@mui/material/colors';

const styles = theme => ({
  formControl: {
    flex: 1,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    maxWidth: '100%',
  },
});

const ProjectTemplateAutoAssignmentRuleRequiredCheckbox = props => {
  const {
    classes,
    className,
    'data-test-id': dataTestId,
    isDisabled,
    isLoading,
    onChange,
    value,
  } = props;
  const id = `id-${uniqueId()}`;
  const rootClass = classNames(className, classes.formControl);
  return (
    <FormControl className={rootClass} data-test-id={dataTestId}>
      <FormControlLabel
        disabled={isDisabled || isLoading}
        control={
          <Checkbox
            checked={value || false}
            inputProps={{ id }}
            onChange={({ target: { checked } = {} } = {}) => onChange(checked)}
            MenuProps={{ disableRestoreFocus: true }}
            sx={{
              '&.Mui-checked': {
                color: pink[600],
              },
            }}
          />
        }
        label="Required"
      />
    </FormControl>
  );
};

export default withStyles(styles)(
  ProjectTemplateAutoAssignmentRuleRequiredCheckbox,
);
