import React from 'react';
import Select from 'react-select';
import Typography from '@mui/material/Typography';
import formatOptionLabel from './formatOptionLabel';
import useSeasons from 'studio/hooks/useSeasons';
import withRouteHelpers from 'common/hoc/withRouteHelpers';

const SeasonSelect = props => {
  const {
    onChange,
    formData: { season: selectedSeasonId },
    error = '',
    selectStyles,
    classes,
    disabled: upstreamDisabled,
    projectId,
    isReviewOffer = false,
  } = props;

  const { loading, data: seasons = [] } = useSeasons(projectId);

  if (seasons.length === 0) return null;

  const isSingleSeason = seasons.length === 1;

  // If reviewing an offer or draft, the Season dropdown is disabled/enabled depending on the upstreamDisabled prop.

  // If creating an offer and theres only 1 season (isSingleSeason), the Season dropdown is disabled
  // and the default value is set to the only season available. More than 1 season, the dropdown is fully enabled.
  // if there are no seasons, the Season dropdown does not render.

  const disabled = isReviewOffer ? upstreamDisabled : isSingleSeason;

  const options = seasons.map(({ id, label }) => ({ value: id, label }));

  const selectedOption = options.find(
    ({ value }) => selectedSeasonId === value,
  );

  const updateSelectedSeasonId = selectedSeasonId =>
    onChange({
      season: selectedSeasonId,
      union: null,
      occupation: null,
      workSchedule: null,
    });

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1">
        Season
      </Typography>
      <div data-test-id="SeasonSelect-select">
        <Select
          styles={selectStyles}
          onChange={({ value }) => updateSelectedSeasonId(value)}
          value={selectedOption}
          options={options}
          isLoading={loading}
          className={classes.select}
          formatOptionLabel={formatOptionLabel('SeasonSelect')}
          isDisabled={disabled}
        />
      </div>
      {error && (
        <Typography
          color="error"
          variant="caption"
          className={classes.error}
          data-test-id="SeasonSelect-error"
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

export default withRouteHelpers(SeasonSelect);
