import React from 'react';
import { withStyles } from '@mui/styles';
import DocumentField from './DocumentField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import classNames from 'class-names';

const styles = theme => ({
  root: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: 25,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridAutoRows: 'max-content',
  },
  errorRoot: {
    borderLeft: `2px solid ${theme.palette.error.main}`,
  },
  inputFieldContainer: {
    gridColumn: '1 / -1',
    display: 'grid',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
    // gridTemplateColumns: 'repeat(3, minmax(max-content, 50%))',
    gap: '15px',
  },
  header: {
    gridRow: 1,
    gridColumn: '1 / -2',
  },
  requiredFlag: {
    gridRow: 1,
    gridColumn: 3,
    justifySelf: 'end',
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  completionStatus: {
    gridRow: 1,
    gridColumn: 3,
  },
  divider: {
    gridRow: 2,
    gridColumn: '1 / -1',
    marginBottom: '8px !important',
  },
  inputFieldRoot: {
    width: '100%',
  },
});

const DocumentFieldsGroup = props => {
  const { classes, formErrors, groupName, inputFields, isRequired, onChange } =
    props || {};
  const hasErrors = Object.keys(formErrors).length > 0;
  const rootClass = classNames(classes.root, {
    [classes.errorRoot]: hasErrors,
  });

  const getError = inputFieldId => {
    const { error } =
      formErrors.find(err => err.inputFieldId === inputFieldId) || {};
    return error;
  };

  return (
    <Paper className={rootClass}>
      <Typography variant="subtitle1" className={classes.header}>
        {groupName}
      </Typography>
      <Typography variant="subtitle1" className={classes.requiredFlag}>
        {isRequired ? 'Required' : 'Optional'}
      </Typography>
      <Divider className={classes.divider} />
      <div className={classes.inputFieldContainer}>
        {inputFields.map(({ id, name, type, options, value }) => (
          <div key={id} className={classes.inputFieldRoot}>
            <DocumentField
              onChange={newValue => onChange(id, newValue)}
              value={value}
              error={getError(id)}
              fieldId={id}
              fieldName={name}
              fieldType={type}
              fieldOptions={options}
            />
          </div>
        ))}
      </div>
    </Paper>
  );
};

export default withStyles(styles)(DocumentFieldsGroup);
