import gql from 'graphql-tag';

export default gql`
  mutation mergeAccount(
    $firstEmail: String!
    $secondEmail: String!
    $mergeEmail: String!
    $ssnLastFour: String!
    $birthDate: String!
  ) {
    mergeAccount(
      firstEmail: $firstEmail
      secondEmail: $secondEmail
      mergeEmail: $mergeEmail
      ssnLastFour: $ssnLastFour
      birthDate: $birthDate
    )
  }
`;
