import React from 'react';
import { withStyles } from '@mui/styles';
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

const styles = {
  root: {
    padding: 0,
    maxHeight: 360,
    overflow: 'auto',
    '& .MuiListItemSecondaryAction-root': {
      display: 'none',
    },
    '& .MuiListItem-container:hover .MuiListItemSecondaryAction-root': {
      display: 'block',
    },
  },
  subHeader: {
    fontWeight: 700,
    background: '#fff',
    '&:first-of-type': {
      color: '#525252',
      fontSize: 14,
      borderTop: '1px solid #C6C6C6',
    },
  },
  itemLoading: {
    textAlign: 'center',
    '&:after': {
      content: '"Loading..."',
      marginLeft: 12,
    },
  },
  deleteButton: {
    '&:hover .MuiSvgIcon-root': {
      color: '#f44336',
    },
  },
  noData: {
    color: '#bbb',
  },
  subGroup: {
    '& .MuiListItemText-root': {
      color: '#525252',
    },
  },
};

const groupData = (data, flag) => {
  const templates = [];
  const drafts = [];

  (data || []).forEach(d => {
    if (d.isTemplate) templates.push(d);
    else drafts.push(d);
  });

  const ret = [];
  if (drafts.length > 0) {
    ret.push({ label: 'My Drafts', data: drafts });
  }
  if (templates.length > 0 && flag) {
    ret.push({ label: 'Offer Templates', data: templates });
  }

  return ret;
};

const filterData = (data, keyword) => {
  if ((keyword || '').trim().length === 0) return data;

  return (data || []).filter(
    ({ title }) => title.toLowerCase().indexOf(keyword?.toLowerCase()) >= 0,
  );
};

const Dropdown = props => {
  const flags = useFeatureFlags();
  const isOfferTemplatesFlagOn = flags.includes('OfferTemplates');
  const { data, loading, classes, keyword = '', onDelete, onChange } = props;
  const groups = groupData(filterData(data, keyword), isOfferTemplatesFlagOn);

  return (
    <List className={classes.root}>
      {loading && (
        <ListItem className={classes.itemLoading}>
          <CircularProgress color="secondary" size={16} />
        </ListItem>
      )}
      {!loading &&
        groups.map(({ label, data: options }) => (
          <Box key={`draft-group-${label}`}>
            <ListSubheader
              className={classes.subHeader}
              data-test-id={`OfferDrafts-selectGroup-${label}`}
            >
              {label}
            </ListSubheader>
            <ul className={classes.subGroup}>
              {options.map(draft => (
                <ListItem
                  key={`draft-${draft.id}`}
                  button
                  onClick={() => onChange(draft)}
                  data-test-id={`OfferDrafts-selectOption-${draft.title}`}
                >
                  <ListItemText>{draft.title}</ListItemText>
                  <ListItemSecondaryAction
                    data-test-id={`OfferDrafts-optionActions-${draft.title}`}
                  >
                    <IconButton
                      onClick={() => onDelete(draft)}
                      className={classes.deleteButton}
                      data-test-id={`OfferDrafts-deleteButton-${draft.title}`}
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </ul>
          </Box>
        ))}
      {!loading && !groups.length && (
        <ListItem className={classes.noData}>No offers.</ListItem>
      )}
    </List>
  );
};

export default withStyles(styles)(Dropdown);
