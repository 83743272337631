import React from 'react';
import { Dialog, Typography, Button } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 10,
    margin: 'auto',
  },
  title: {
    marginBottom: 10,
  },
  buttonContainer: {
    marginTop: 20,
    position: 'relative',
    float: 'right',
  },
  btn: {
    backgroundColor: '#0000FF',
  },
});

const MobileSaveDraftDialog = ({ open, close, classes }) => {
  return (
    <Dialog open={open} onClose={() => close()}>
      <div className={classes.paper}>
        <Typography
          variant="h6"
          sx={{ marginBottom: '14px' }}
          className={classes.title}
        >
          Your documents have been saved
        </Typography>
        Please note: Your offer is not complete and will not be submitted for
        approval until you complete and sign all offer documents. You can login
        and resume the process.
        <div className={classes.buttonContainer}>
          <Button
            className={classes.btn}
            variant="contained"
            onClick={() => close()}
          >
            Ok
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(MobileSaveDraftDialog);
