import { createTheme } from '@mui/material/styles';
import palette from './palette';

export const documentSignerTheme = createTheme({
  palette,
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: '1rem',
      },
    },
    MuiButton: {
      root: {
        fontSize: '1rem',
        textTransform: 'capitalize',
      },
    },
  },
});
