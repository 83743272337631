import AllowancesRow from 'common/oldJavascripts/components/Shared/AllowancesRow';
import RateCell from '../Offers/RateCell';

import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import format from 'common/oldJavascripts/utils/format.js';
import OfferStatus from 'common/oldJavascripts/components/Shared/OfferStatus';

import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import ExpandableTableCellTitle from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCellTitle';

const Cell = ExpandableTableCell;
const CellTitle = ExpandableTableCellTitle;
const Row = ExpandableTableRow;

export default Relay.createClass({
  statics: {
    queries: {
      review: {
        info(params, related) {
          const routerParams = related['/router/params'];
          return {
            id: `/projects/${routerParams.projectId}/reviews/${params.id}`,
          };
        },
      },
    },
  },

  render() {
    if (this.props.review.status !== 'success') {
      return null;
    }

    const offer = this.props.review.data;
    return (
      <Row>
        <Cell>
          <CellTitle>{`${offer.first_name} ${offer.last_name}`}</CellTitle>
          {offer.email}
        </Cell>
        <Cell>
          <CellTitle>{offer.department_name}</CellTitle>
        </Cell>
        <Cell>
          {offer.occupation_description}
          <br />
          {offer.account_code}
        </Cell>
        <Cell>
          {offer.union_code}
          {' — '}
          {offer.union_description}
        </Cell>
        <Cell>{format.date(offer.start_date)}</Cell>
        {this._renderEndDateCell(offer)}
        <RateCell expandable={true} offer={offer} />
        <Cell expanded={this.props.allowanceExpanded}>
          <AllowancesRow.Icon offer={offer} onClick={this._toggleAllowance} />
        </Cell>
        <Cell expanded={this.props.statusExpanded} onClick={this._toggleStatus}>
          <OfferStatus
            status={offer.status}
            startDate={offer.start_date}
            sendDate={offer.send_date}
          />
        </Cell>
      </Row>
    );
  },

  _renderEndDateCell(item) {
    if (this.props.endDateAllowed) {
      return <Cell>{format.date(item.end_date)}</Cell>;
    }
  },

  _toggleAllowance() {
    this.props.onAllowanceToggle(this.props.review.data.id);
  },

  _toggleStatus() {
    this.props.onStatusToggle(this.props.review.data.id);
  },
});
