import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import {
  AddCircleOutlineOutlined as AddIcon,
  CloseSharp as CloseIcon,
  DataObjectOutlined as DataObjectIcon,
} from '@mui/icons-material';
import ConditionSelectorModal from './ConditionSelectorModal';

const styles = theme => ({
  container: {
    width: '50%',
    paddingTop: theme.spacing(3.5),
  },
  dialogPaper: {
    padding: 18,
    minHeight: '250px',
    height: 'max-content',
    maxHeight: '600',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  modalText: {
    color: '#868a8f',
  },
  deleteDialogPaper: {
    padding: 18,
    minHeight: '120px',
    height: 'max-content',
    width: 400,
    maxHeight: '200',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  button: {
    padding: '6px 7px !important',
    color: '#004eeb !important',
    borderColor: '#83adff !important',
    fontWeight: '500 !important',
    borderRadius: '8px !important',
    textTransform: 'none !important',
    '&:hover': {
      borderColor: '#1A55FD',
    },
  },
  cancelButton: {
    marginTop: theme.spacing(1) + ' !important',
    marginLeft: theme.spacing(1) + ' !important',
    padding: '5px !important',
    borderRadius: 0,
  },
  saveButton: {
    marginTop: theme.spacing(1) + ' !important',
    marginLeft: theme.spacing(1) + ' !important',
    color: '#FFFFFF !important',
    borderRadius: 0,
    backgroundColor: '#0000FF !important',
    '&:hover': {
      backgroundColor: '#1A55FD !important',
    },
    '&:disabled': {
      backgroundColor: '#9A9FAF !important',
    },
  },
  deleteButton: {
    marginTop: theme.spacing(1) + ' !important',
    marginLeft: theme.spacing(1) + ' !important',
    color: '#fff !important',
    backgroundColor: '#ea5959 !important',
    border: 'none !important',
    '&:hover': {
      backgroundColor: '#ff7f7f !important',
    },
    '&:disabled': {
      backgroundColor: '#9A9FAF !important',
    },
  },
  buttonText: {
    paddingLeft: '2px !important',
    fontWeight: '500 !important',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  select: {
    marginLeft: theme.spacing(1) + ' !important',
    width: '200px',
    fontStyle: 'italic',
    fontSize: '15px !important',
    fontWeight: '300 !important',
  },
  directory: {
    marginLeft: 4,
    color: '#354054',
    fontWeight: 500,
    margin: '3px 0px !important',
  },
  conditionDirectory: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.1rem !important',
    color: '#354054 !important',
  },
  actions: {
    marginLeft: '10px !important',
    cursor: 'pointer !important',
    fontWeight: '500 !important',
    fontSize: '1.1rem !important',
    color: '#155eef !important',
  },
});

const ConditionSelector = props => {
  const {
    classes,
    fieldId,
    templateFields,
    value,
    onChange,
    directoryIndex,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);

  const deleteCondition = () => {
    onChange(directoryIndex, null, null);
    setAlertModalOpen(false);
  };

  const field = templateFields.find(field => field.id === fieldId);
  const fieldName = field ? field?.displayName : '';
  const fieldValue = field
    ? field?.radioGroupItems.find(option => option.name === value)?.value
    : '';

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      sx={{ margin: '6px 26px' }}
    >
      {fieldId && value && (
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Typography
              variant="body1"
              data-test-id={`StudioPlusFolderSelector-condition-text-${directoryIndex}`}
              className={classes.conditionDirectory}
            >
              <DataObjectIcon
                sx={{
                  color: '#667085',
                  marginRight: '6px',
                  fontSize: '1.7rem',
                }}
              />
              Condition:{' '}
              <span className={classes.directory}>
                Document field "{fieldName}" has specific value "{fieldValue}"{' '}
              </span>
            </Typography>
            <Typography
              data-test-id={`StudioPlusFolderSelector-condition-edit-${directoryIndex}`}
              onClick={() => {
                setModalOpen(true);
              }}
              color={'primary'}
              className={classes.actions}
            >
              Edit
            </Typography>
            <Typography
              data-test-id={`StudioPlusFolderSelector-condition-delete-${directoryIndex}`}
              onClick={() => setAlertModalOpen(true)}
              color={'primary'}
              className={classes.actions}
            >
              Remove
            </Typography>
          </Grid>
        </Grid>
      )}
      {!fieldId && !value && (
        <Grid item>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <AddIcon />{' '}
            <Typography className={classes.buttonText}>
              Add condition
            </Typography>
          </Button>
        </Grid>
      )}
      {modalOpen && (
        <ConditionSelectorModal
          {...props}
          onClose={() => setModalOpen(false)}
          modalOpen={modalOpen}
          onChange={onChange}
          templateFields={templateFields}
        />
      )}
      <Dialog
        open={alertModalOpen}
        maxWidth="xs"
        classes={{ paper: classes.deleteDialogPaper }}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="h5"
              data-test-id={`StudioPlusFolderSelector-deleteModal-heading`}
            >
              Delete Condition
            </Typography>
            <CloseIcon
              className={classes.closeIcon}
              onClick={() => setAlertModalOpen(false)}
            />
          </Grid>
          <Typography
            variant="body1"
            data-test-id={`StudioPlusFolderSelector-deleteModal-text`}
          >
            Are you sure you want to delete this condition?
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="outlined"
            className={classes.cancelButton}
            onClick={() => setAlertModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            className={classes.deleteButton}
            onClick={deleteCondition}
            data-test-id={`StudioPlusFolderSelector-deleteModal-deleteBtn`}
          >
            Delete
          </Button>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default withStyles(styles)(ConditionSelector);
