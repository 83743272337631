import React from 'react';

// COMPONENTS
import Allowances from 'studio/components/ManageOffers/Allowances/Allowances';
import RateScaleRow from 'common/oldJavascripts/components/Shared/RateScaleRow';
import AdditionalDealTerms from 'common/oldJavascripts/components/Pages/Project/Offers/AdditionalDealTerms.js';
import OfferDocumentsList from 'common/components/OfferDocumentsList';
import SSNNumber from 'common/components/SSNNumber/SSNNumber';

// MUI
import Tooltip from '@mui/material/Tooltip';
import QUESTIONMARK_ICON from 'common/images/questionmark.svg';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

// MAP to constuct nonUnionTermsOfEmployment and unionTermsOfEmployment for RateScaleRow
import nonUnionTermsOfEmployment from 'studio/components/ManageOffers/utilities/nonUnionTermsOfEmployment/nonUnionTermsOfEmployment';
import unionTermsOfEmployment from 'studio/components/ManageOffers/utilities/unionTermsOfEmployment/unionTermsOfEmployment';

// LAYOUT COMPONENTS
import ExpandableTableRowHeader from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowHeader';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableRowInfo from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowInfo';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import TypographyLink from 'studio/oldJavascripts/components/Pages/Project/TypographyLink.js';

// Helpers
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';
import useOfferDocuments from 'common/components/OfferDocumentsList/hooks/useOfferDocuments';

const TEXT =
  'For California Pay Data Reporting, this employee ' +
  'is entirely remote, teleworking, or home-based, ' +
  'and has no expectation to regularly report in ' +
  'person to a physical establishment to perform, work duties.';

const useStyles = makeStyles(theme => ({
  optOutText: {
    marginLeft: '20px',
  },
  customOccupationDescriptionText: {
    marginLeft: '8px',
  },
  termsOfHireText: {
    marginLeft: '10px',
    marginBottom: '20px',
    display: 'flex',
  },
  termsOfHireSubHeader: {
    fontWeight: 600,
    marginRight: '5px',
  },
  termsOfHireSubHeaderCanada: {
    alignItems: 'center',
  },
  noDeptText: {
    fontStyle: 'italic',
  },
  offerDocuments: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-10px',
  },
  docCount: {
    marginTop: '-7px',
  },
  termsOfHireTable: {
    display: 'Flex',
  },
  linkColor: {
    color: '#0000FF',
    textDecoration: 'underline',
  },
  tooltipIcon: {
    position: 'absolute',
    right: '-8px',
    top: '-10px',
  },
  offsiteContent: {
    position: 'relative',
    marginRight: '14px',
  },
  popper: { maxWidth: '380px !important' },
  termsOfHireTextUsaRightInfo: { width: '50%' },
  termsOfHireTextCanadaRightInfo: { width: '50%' },
  termsOfHireTextUsaLeftInfo: { width: '50%' },
  termsOfHireTextCanadaLeftInfo: { width: '50%' },
}));

const ExpandedDetails = props => {
  const {
    offer,
    openAccountCodeModal,
    togglePacketFlow,
    expandedWorkflow,
    projectData,
    handleStatusChange,
  } = props;

  const classes = useStyles();

  const {
    id,
    customOccupationDescription,
    status,
    notes,
    sentAt,
    additionalNotes,
    projectUser: { profile: { id: profileId } = {} } = {},
    termsOfHire: {
      season,
      hireState: { name } = {},
      workState,
      hireCity,
      workCity,
      hiringStatus,
      employmentClassification,
      union,
      occupation,
      workSchedule: { description } = {},
      fringeContract = {},
      department,
      subDepartment,
      currency,
      fullyOffsiteWorker = false,
      trackOffsiteWorkers = false,
    } = {},
  } = offer;
  // SSN/SIN should only be visible to hirers who have the can_hirer_view_sin_or_ssn privilege
  // and only after the employee has accepted the offer
  const { id: projectId, privileges } = projectData;
  const canViewSSN = privileges?.includes('can_hirer_view_sin_or_ssn');
  const offerStatus = ['submitted', 'approved', 'rejected'];
  const isEmployeeOfferAccepted = offerStatus.includes(status);

  const optedOut = status === 'complete_on_paper';
  const hasNotes = notes.length > 0;
  const { isNonUnion, description: unionName } = union;
  const { description: occupationName } = occupation;
  const { name: departmentName } = department;
  const { name: workStateName } = workState;
  const hireCityName = hireCity?.name;
  const workCityName = workCity?.name;
  const hirerCanSendDocuments =
    privileges && privileges.includes('can_hirer_send_documents');

  // its either loanOut, w2 or null. If its loanOut, format it to be Loan-out
  // otherwise just leave it alone
  const formatEmploymentClassification =
    employmentClassification && employmentClassification === 'loanOut'
      ? 'Loan-out'
      : employmentClassification;

  const formatEmploymentClassificationCAN =
    employmentClassification && employmentClassification === 'loanOut'
      ? 'Loan-out'
      : 'Individual';

  const { data: offerDocument } = useOfferDocuments(parseInt(id));
  const {
    documentDetails: docs = [],
    loanoutCorporation = {},
    externalDocuments = [],
    removedDocuments = [],
  } = offerDocument || {};

  const { w9 } = loanoutCorporation || {};
  const isFullyOffsiteWorker = fullyOffsiteWorker ? 'Yes' : '';
  let documentCount = docs?.length;

  if (externalDocuments?.length && externalDocuments?.length > 0) {
    documentCount += externalDocuments?.length;
  }

  if (w9?.id) {
    documentCount = documentCount + 1;
  }
  if (removedDocuments) {
    documentCount = documentCount + removedDocuments.length;
  }

  // TermsOfEmploymentV1 map for RateScaleRow. RateScaleRow is used across multiple components
  // Rather than mess all of those components up and keep the logic centralized,
  // Created a mapper to feed RateScaleRow the data it needs for NonUnion
  const TermsOfEmploymentMap = isNonUnion
    ? nonUnionTermsOfEmployment(offer)
    : unionTermsOfEmployment(offer);

  return (
    <>
      <ExpandableTableRow key={`custom-free-form-fields-${id}`} rowExpanded>
        <ExpandableTableCell
          colSpan={10}
          expanded
          data-test-id={`Offers-AccountCodeLink-${id}`}
        >
          <TypographyLink
            onClick={() => openAccountCodeModal(id)}
            title={'Set Account Codes'}
          />
        </ExpandableTableCell>
      </ExpandableTableRow>

      <ExpandableTableRow key={`terms-of-hire-${id}`} rowExpanded={true}>
        <ExpandableTableCell colSpan={10} expanded={true}>
          <ExpandableTableRowHeader>Terms of Hire</ExpandableTableRowHeader>
          <UsaOnly>
            <div className={classes.termsOfHireTable}>
              <div className={classes.termsOfHireTextUsaLeftInfo}>
                <ExpandableTableRowInfo>
                  {!(season === null) && (
                    <div className={classes.termsOfHireText}>
                      <p className={classes.termsOfHireSubHeader}>Season:</p>
                      <p>{season === 0 ? 'Pilot' : season}</p>
                    </div>
                  )}
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Hire State:{''}
                    </p>
                    <p>{name}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Hire City:{''}
                    </p>
                    <p>{hireCityName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Work State:{''}
                    </p>
                    <p>{workStateName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Primary Work City:{' '}
                    </p>
                    <p>{workCityName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      ACA Hiring Status:{' '}
                    </p>
                    <p>
                      {hiringStatus === 'full_time'
                        ? 'Full Time'
                        : hiringStatus}
                    </p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Employment Classification:{' '}
                    </p>
                    <p>{formatEmploymentClassification}</p>
                  </div>
                </ExpandableTableRowInfo>
              </div>
              <div className={classes.termsOfHireTextUsaRightInfo}>
                <ExpandableTableRowInfo>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>Union:</p>
                    <p>{unionName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>Occupation:</p>
                    <p>{occupationName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Work Schedule:{' '}
                    </p>
                    <p>{description}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>Department:</p>
                    <p>{departmentName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Associated Hours+ Department:{' '}
                    </p>
                    <p className={`${subDepartment ? '' : classes.noDeptText}`}>
                      {subDepartment ? subDepartment?.name : 'Not Selected'}
                    </p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Job Description:
                    </p>
                    <p>{customOccupationDescription}</p>
                  </div>
                  {trackOffsiteWorkers && (
                    <div className={classes.termsOfHireText}>
                      <div className={classes.offsiteContent}>
                        <p className={classes.termsOfHireSubHeader}>
                          Fully Offsite Worker:
                        </p>
                        <Tooltip
                          interactive
                          classes={{ tooltip: classes.popper }}
                          title={
                            <div>
                              <Typography>
                                {TEXT}
                                <br></br>
                                <br></br> For more information:{' '}
                                <a
                                  href="https://calcivilrights.ca.gov/paydatareporting/faqs/"
                                  className={classes.linkColor}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  California Pay Data Reporting FAQ{' '}
                                </a>
                              </Typography>
                            </div>
                          }
                        >
                          <img
                            className={classes.tooltipIcon}
                            src={QUESTIONMARK_ICON}
                            alt="schedule tooltip"
                          />
                        </Tooltip>
                      </div>
                      <p>{isFullyOffsiteWorker}</p>
                    </div>
                  )}
                </ExpandableTableRowInfo>
              </div>
            </div>
          </UsaOnly>
          <CanadaOnly>
            <div className={classes.termsOfHireTable}>
              <div className={classes.termsOfHireTextCanadaLeftInfo}>
                <ExpandableTableRowInfo>
                  {!(season === null) && (
                    <div className={classes.termsOfHireText}>
                      <p className={classes.termsOfHireSubHeader}>Season:</p>
                      <p>{season === 0 ? 'Pilot' : season}</p>
                    </div>
                  )}
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Hire Province:{' '}
                    </p>
                    <p>{name}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Work Province:{' '}
                    </p>
                    <p>{workStateName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>Currency: </p>
                    <p>{currency}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Employment Classification:{' '}
                    </p>
                    <p>{formatEmploymentClassificationCAN}</p>
                  </div>
                  {canViewSSN && isEmployeeOfferAccepted && (
                    <div
                      className={
                        classes.termsOfHireText +
                        ' ' +
                        classes.termsOfHireSubHeaderCanada
                      }
                    >
                      <p className={classes.termsOfHireSubHeader}>
                        Employee SIN/SSN:{' '}
                      </p>
                      <SSNNumber
                        profileId={profileId}
                        projectId={projectId}
                        hideAfter={10}
                      />
                    </div>
                  )}
                </ExpandableTableRowInfo>
              </div>
              <div className={classes.termsOfHireTextCanadaRightInfo}>
                <ExpandableTableRowInfo>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>Union:</p>
                    <p>{unionName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>Occupation:</p>
                    <p>{occupationName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Work Schedule:{' '}
                    </p>
                    <p>{description}</p>
                  </div>
                  {fringeContract && (
                    <div className={classes.termsOfHireText}>
                      <p className={classes.termsOfHireSubHeader}>
                        Vacation/Fringe{' '}
                      </p>
                      <p>{fringeContract?.description}</p>
                    </div>
                  )}
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>Department:</p>
                    <p>{departmentName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Associated Hours+ Department:{' '}
                    </p>
                    <p className={`${subDepartment ? '' : classes.noDeptText}`}>
                      {subDepartment ? subDepartment?.name : 'Not Selected'}
                    </p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Job Description:
                    </p>
                    <p>{customOccupationDescription}</p>
                  </div>
                </ExpandableTableRowInfo>
              </div>
            </div>
          </CanadaOnly>
        </ExpandableTableCell>
      </ExpandableTableRow>

      <RateScaleRow
        key={`rate-${id}`}
        rowExpanded
        offer={TermsOfEmploymentMap}
        colSpan={10}
      />

      <Allowances
        key={`allowance-${id}`}
        rowExpanded={true}
        colSpan={10}
        offer={offer}
      />

      <ExpandableTableRow key={`packet-documents-${id}`} rowExpanded={true}>
        <ExpandableTableCell colSpan={10} expanded={true}>
          <div className={classes.offerDocuments}>
            <ExpandableTableRowHeader>
              Offer Documents{' '}
            </ExpandableTableRowHeader>
            <span className={classes.docCount}>
              {documentCount} total documents
            </span>
          </div>
          <ExpandableTableRowInfo>
            {optedOut && (
              <Typography
                className={classes.optOutText}
                data-test-id="OfferDocuments-optOutText"
              >
                Employee has chosen to complete the offer on paper and cannot
                complete this offer in Start+.
              </Typography>
            )}
            {!optedOut && (
              <OfferDocumentsList
                offerId={id}
                expandedWorkflow={expandedWorkflow}
                handleStatusChange={handleStatusChange}
                actionClickHandler={togglePacketFlow}
                hirerCanSendDocuments={hirerCanSendDocuments}
              />
            )}
          </ExpandableTableRowInfo>
        </ExpandableTableCell>
      </ExpandableTableRow>

      {hasNotes && (
        <ExpandableTableRow rowExpanded={true}>
          <ExpandableTableCell colSpan={10} expanded={true}>
            <AdditionalDealTerms
              title="Additional Deal Terms"
              notes={notes || ''}
              oldNotes={[...(additionalNotes || [])].reverse()}
              headerStyles={{
                color: '#0000FF',
                marginLeft: '8px',
                textTransform: 'uppercase',
                fontSize: '0.9rem',
              }}
              currentDate={sentAt}
            />
          </ExpandableTableCell>
        </ExpandableTableRow>
      )}
    </>
  );
};

export default ExpandedDetails;
