import * as FieldTypes from '../../utilities/constants/fieldTypes';
import isFilledIn from '../../utilities/isFilledIn';

// ******************** TRIGGER RULES ********************

// Apply the response behaviors of the given trigger rule to the given field.
const applyTriggerBehaviors = (rule, field, isFirstRender, isUserUpdate) => {
  let {
    value,
    externalName,
    _derived: { isDisabled, isRequired, externalNameValue },
  } = field;
  const { responseBehavior } = rule;
  responseBehavior.forEach(({ responseBehaviorType, value: setValue }) => {
    const wasTriggered = isUserUpdate || isFirstRender;
    switch (responseBehaviorType) {
      case 'enable':
        if (isUserUpdate) value = '';
        isDisabled = false;
        break;
      case 'require':
        isRequired = true;
        break;
      case 'set':
        const isTargetEmpty = !isFilledIn(value);
        if ((isUserUpdate && !isFirstRender) || (isTargetEmpty && wasTriggered))
          value = setValue;
        break;
      case 'system':
        const isSystemField = setValue === externalName;
        if (wasTriggered && isSystemField) value = externalNameValue;
        break;
      default:
        throw new Error('Unknown trigger response behavior type.');
    }
  });
  return {
    ...field,
    value,
    _derived: {
      ...field._derived,
      isDisabled,
      isRequired,
    },
  };
};

// Returns the given value coerced to the type required by the given field.
const castValue = (value, field) => {
  if (value == null) return '';
  const {
    _derived: { fieldType },
  } = field;
  switch (fieldType) {
    case FieldTypes.CHK:
      return !!value;
    case FieldTypes.NUMBER:
      return parseFloat(value);
    default:
      return `${value}`.trim();
  }
};

// Returns a bool indicating whether or not a given trigger rule has been fired.
// The condition here is based on the rule's `triggerActionType` object, which
// should look like one of the following:
// `{ triggerActionType: 'present' }`
//    - Trigger if the field contains a non-whitespace value
// `{ triggerActionType: 'empty' }`
//    - Trigger if the field *does not* contain a non-whitespace value
// `{ triggerActionType: 'value', value: 'some value' }`
//    - Trigger if the field contains exactly 'some value' (case-insensitive match)
const getIsTriggerActive = (rule, doc) => {
  if (!rule) return;
  const { triggerFieldId, triggerAction = {} } = rule;

  // Get trigger type & expected value (if there is one).
  let { triggerActionType, value: expectedValue } = triggerAction;

  // Get entered value.
  const { fields } = doc;
  const triggerField = fields.find(f => f.id === triggerFieldId);
  if (!triggerField) return false;
  let { value: enteredValue } = triggerField;

  enteredValue = castValue(enteredValue, triggerField);
  expectedValue = castValue(expectedValue, triggerField);

  switch (triggerActionType) {
    case 'present':
      return isFilledIn(enteredValue);
    case 'empty':
      return !isFilledIn(enteredValue);
    case 'value':
      return enteredValue.toLowerCase() === expectedValue.toLowerCase();
    case 'gt_value':
      return !isNaN(enteredValue) && enteredValue > expectedValue;
    case 'lt_value':
      let { value: realEnteredValue } = triggerField;
      return (
        !!realEnteredValue &&
        !isNaN(enteredValue) &&
        enteredValue < expectedValue
      );
    default:
      throw new Error('Unknown trigger action type.');
  }
};

// Applies the given trigger rule to the given field.
// If the trigger condition for this rule is true, apply the rule behaviors
// against the field, otherwise if the field belongs to the trigger rule that
// has been interacted with by the user and the trigger condition is false, apply
// its default settings. If the field does not belong to the trigger rule, display
// a blank value until the user interacts with the trigger rule.
const applyTriggerRule = (rule, field, doc, changedFieldId) => {
  const { triggerFieldId, fieldGroups } = rule;
  const [{ fieldIds = [] } = {}] = fieldGroups || [];
  const isTriggerActive = getIsTriggerActive(rule, doc);
  const isFirstRender = !changedFieldId;
  const isUserUpdate = changedFieldId && changedFieldId === triggerFieldId;
  const isFieldUpdatedByUser = changedFieldId && changedFieldId === field.id;
  const triggerSourceValue = getTriggerSourceValue(rule, doc);
  let { defaultValue, id, value: fieldValue } = field;
  let value = '';
  if (isTriggerActive) {
    return applyTriggerBehaviors(rule, field, isFirstRender, isUserUpdate);
  } else if (
    (isUserUpdate || (isFirstRender && !!triggerSourceValue)) &&
    fieldIds.includes(id)
  ) {
    // User has updated the trigger rule source, but
    // the trigger is not active OR the document rendered
    // with a "negative" trigger rule value,
    // so we display the default value
    value = defaultValue;
  } else if (isFieldUpdatedByUser) {
    // User has updated a field, but has not updated the trigger
    // so we retain the user input
    value = fieldValue;
  } else {
    if (changedFieldId) {
      // Retain the field value when the user
      // is interacting with the document
      value = fieldValue;
    }
  }
  return {
    ...field,
    value: value || '',
  };
};

// ******************** GROUP RULES ********************

// Find the first rule which contains the given rule, i.e. given rule is in the
// parent rule's conditionalRuleIds array.
const findParentRule = (rule, doc) => {
  const { id: ruleId } = rule;
  const { rules = [] } = doc;
  return rules.find(({ conditionalRuleIds = [] }) =>
    conditionalRuleIds.includes(ruleId),
  );
};

// Given a group rule and one of the group's fields, is the given field required
// or disabled by the given rule?
const isGroupFieldRequired = (rule, field, doc) => {
  // If this rule has a parent rule whose trigger condition is false, this group
  // is disabled.
  const parentRule = findParentRule(rule, doc);
  if (parentRule && !getIsTriggerActive(parentRule, doc)) return false;

  const { value, id: fieldId, defaultValue } = field;

  // If this field has a value, all the fields in this group are required.
  if (value && value !== defaultValue) return true;

  // Find field group
  const { fieldGroups } = rule;
  const thisFieldGroup = fieldGroups.find(({ fieldIds }) =>
    fieldIds.includes(fieldId),
  );

  // If other fieldGroups in this rule are in progress, all the fields in this
  // group are disabled.
  const { fields } = doc;
  const otherFieldGroupsAreInProgress = fieldGroups
    .filter(({ id }) => id !== thisFieldGroup.id)
    .some(({ fieldIds = [] }) =>
      fieldIds.some(fId => {
        const { value: fieldValue, defaultValue: fieldDefaultValue } =
          fields.find(({ id }) => id === fId) || {};
        return fieldValue && fieldValue !== fieldDefaultValue;
      }),
    );
  if (otherFieldGroupsAreInProgress) return false;

  // Otherwise this group is required.
  return true;
};

// Get trigger rule's trigger source (field) value
const getTriggerSourceValue = (triggerRule, doc) => {
  if (!triggerRule) return null;
  const { fields = [] } = doc;
  const { triggerFieldId } = triggerRule;
  const triggerField = fields.find(({ id }) => triggerFieldId === id) || {};
  return triggerField.value;
};

// Return the field value based on the group rule and any parent trigger rules'
// active/inactive state
const getGroupRuleFieldValue = (rule, field, doc, changedFieldId) => {
  const { value: fieldValue = '', defaultValue = '' } = field;
  const parentRule = findParentRule(rule, doc);
  const parentRuleSourceValue = getTriggerSourceValue(parentRule, doc);
  const isParentRuleUpdated =
    (parentRule || {}).triggerFieldId === changedFieldId;
  const isRequired = isGroupFieldRequired(rule, field, doc);
  const isTriggerActive = getIsTriggerActive(parentRule, doc);
  const isFieldBeingUpdated = field.id === changedFieldId;
  const fieldBelongsToRule = ruleContainsField(rule, changedFieldId);
  const isDisabled = !isRequired;
  const isFirstRender = !changedFieldId;
  const isCurrentlyDefaultValue =
    fieldValue != null && fieldValue === defaultValue;
  const isRuleReset = !isDisabled && fieldValue === defaultValue;
  // The group rule has a parent trigger that has been
  // "negatively" triggered and it is the initial doc render
  const renderedWithFalsyTrigger =
    !isTriggerActive && isFirstRender && !!parentRuleSourceValue;
  if (
    isDisabled &&
    (isParentRuleUpdated || renderedWithFalsyTrigger || fieldBelongsToRule)
  )
    return defaultValue || '';
  if (
    (parentRule &&
      isTriggerActive &&
      !isDisabled &&
      !isFieldBeingUpdated &&
      isCurrentlyDefaultValue) ||
    isRuleReset
  )
    return '';
  return fieldValue || '';
};

// Applies the given group rule to the given field.
// If any field in a field group is filled, all fields in that group become
// required, whilst all fields in any other field groups in the same rule become
// disabled and are cleared.
const applyGroupRule = (rule, field, doc, changedFieldId) => {
  const isRequired = isGroupFieldRequired(rule, field, doc);
  const value = getGroupRuleFieldValue(rule, field, doc, changedFieldId);
  const isDisabled = !isRequired;
  return {
    ...field,
    // If field is in a read-only field group, set its value to default value.
    value,
    _derived: {
      ...field._derived,
      isDisabled,
      isRequired,
      // If field is in a read-only field group, show its tooltip.
      readOnlyTooltip: isDisabled ? rule.disabledFieldGroupTooltip : '',
    },
  };
};

// Returns true if field is part of the rule and false otherwise
const ruleContainsField = (rule, fieldId) => {
  const { fieldGroups = [] } = rule;
  return fieldGroups.some(({ fieldIds = [] }) => fieldIds.includes(fieldId));
};

// ******************** COMMON ********************

// Find the first rule applicable to the given field, i.e. given field is in one
// of the rule's field groups.
const findRuleContainingField = (field, doc) => {
  const { id: fieldId } = field;
  const { rules = [] } = doc;
  return rules
    .filter(({ ruleType }) => ruleType !== 'badge' && ruleType !== 'email')
    .find(
      ({ fieldGroups }) =>
        fieldGroups &&
        fieldGroups.some(({ fieldIds }) => fieldIds.includes(fieldId)),
    );
};

// Updates the `isReadyOnly`, `isRequired` & `readOnlyTooltip` attributes of the
// field's `_derived` object accprdomg to whatever rules are applicable.
const applyRules = (field, doc, changedFieldId) => {
  const rule = findRuleContainingField(field, doc);
  if (!rule) return field;
  const { ruleType } = rule;
  return ruleType === 'trigger'
    ? applyTriggerRule(rule, field, doc, changedFieldId)
    : applyGroupRule(rule, field, doc, changedFieldId);
};

export default applyRules;
