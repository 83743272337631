import React from 'react';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import withRouteHelpers from 'common/hoc/withRouteHelpers';
import Loader from 'common/components/Loader';
import BoxBody from 'common/oldJavascripts/components/Base/Box/BoxBody';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

import useRelatedProjects from './hooks/useRelatedProjects';
import Relatives from './Relatives';

const styles = {
  error: {
    color: 'red',
  },
  projectName: {
    color: '#0000FF',
    fontWeight: 'bolder',
    textDecoration: 'none',
  },
};

const RelatedProjects = props => {
  const { classes, projectId } = props;
  const {
    loading,
    error: { message: errorRelated } = {},
    refetch: refetchRelatedProjects,
    data: { relatives = [], masterProject } = {},
  } = useRelatedProjects(projectId);

  if (loading) {
    return <Loader />;
  }

  if (errorRelated) {
    return (
      <BoxBody>
        <BoxItem>
          <Typography className={classes.error}>
            Failed loading related projects: {errorRelated}
          </Typography>
        </BoxItem>
      </BoxBody>
    );
  }

  if (!masterProject) {
    return (
      <Relatives
        projectId={projectId}
        relatives={relatives}
        refetch={refetchRelatedProjects}
      />
    );
  }

  return (
    <BoxBody>
      <BoxItem>
        <Typography variant="h5">
          This project is related to:{' '}
          <Link
            data-test-id="RelatedProjects-MasterLink"
            to={`/projects/${masterProject.id}/related-projects`}
            className={classes.projectName}
          >
            {masterProject.name}
          </Link>
        </Typography>
      </BoxItem>
    </BoxBody>
  );
};

export default compose(withStyles(styles), withRouteHelpers)(RelatedProjects);
