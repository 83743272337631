import React from 'react';

// MATERIAL
import { withStyles } from '@mui/styles';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const styles = () => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingIcon: {
    width: '20px',
    height: '20px',
    marginLeft: '10px',
    color: '#0000FF',
  },
});

const FetchMoreProjects = props => {
  const {
    loadMoreProjects,
    searchQuery,
    projectsPageInfo,
    classes,
    isMobile = false,
    networkStatus,
  } = props;

  const StyledButton = styled(Button)({
    padding: '11px 16px',
    margin: isMobile ? '20px 0' : '0 0 20px 0',
    background: '#FFFFFF',
    border: '1px solid #0000FF',
    color: '#0000FF',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  });

  return (
    <div className={classes.buttonContainer}>
      <StyledButton
        variant="outlined"
        data-test-id={`Projects-load-more-button`}
        onClick={() => {
          loadMoreProjects({
            variables: {
              keyword: searchQuery,
              after: projectsPageInfo.endCursor,
            },
            updateQuery: (prevResults, { fetchMoreResult }) => {
              const { projects: fetchMoreProject = {} } = fetchMoreResult || {};
              const {
                nodes: fetchMoreNodes = [],
                pageInfo: fetchMorePageInfo = {},
              } = fetchMoreProject || {};

              if (!fetchMoreNodes.length) return prevResults;

              return {
                projects: {
                  __typename: prevResults.projects.__typename,
                  nodes: [
                    ...prevResults.projects.nodes,
                    ...fetchMoreNodes.filter(
                      fetchMoreProjectId =>
                        !prevResults.projects.nodes.some(
                          prevResultsProjectId =>
                            prevResultsProjectId === fetchMoreProjectId,
                        ),
                    ),
                  ],
                  pageInfo: { ...fetchMorePageInfo },
                },
              };
            },
          });
        }}
      >
        Load More{' '}
        {networkStatus === 3 && (
          <CircularProgress size="sm" className={classes.loadingIcon} />
        )}
      </StyledButton>
    </div>
  );
};

export default withStyles(styles)(FetchMoreProjects);
