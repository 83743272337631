import React from 'react';
import { withStyles } from '@mui/styles';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import classNames from 'classnames';
import uniqueId from 'common/utilities/uniqueId';

const styles = theme => ({
  formControl: {
    flex: 1,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    maxWidth: '100%',
  },
});

const ProjectTemplateAutoAssignmentRuleSelect = props => {
  const {
    classes,
    className,
    'data-test-id': dataTestId,
    isDisabled,
    isLoading,
    label,
    onChange,
    options,
    value,
    multiple = false,
    onClick,
  } = props;
  const id = `id-${uniqueId()}`;
  const rootClass = classNames(className, classes.formControl);
  return (
    <FormControl className={rootClass} data-test-id={dataTestId}>
      <InputLabel htmlFor={id}>
        {label} {isLoading && <CircularProgress size={12} />}
      </InputLabel>
      <Select
        disabled={isDisabled || isLoading || !options?.length}
        value={value}
        inputProps={{ id }}
        onChange={e => onChange(e.target.value)}
        onClick={onClick}
        multiple={multiple}
        variant="standard"
      >
        {options?.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(ProjectTemplateAutoAssignmentRuleSelect);
