import React from 'react';

import { Button, ListItem, ListItemSecondaryAction } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import FileIcon from '@mui/icons-material/ListAlt';

import ToolTipBox from 'common/oldJavascripts/components/Shared/ToolTipBox';
import Link from 'common/oldJavascripts/components/Base/Link';

const useStyles = makeStyles(_theme => ({
  fileIcon: {
    color: '#9DA1AA',
    marginRight: 4,
  },
  fileButton: {
    color: '#979ca5 !important',
    fontWeight: '700 !important',
    fontSize: '1rem !important',
    textTransform: 'none !important',
    '&:hover': {
      color: '#646464 !important',
      backgroundColor: '#fff !important',
    },
  },
  downloadIcon: {
    fontWeight: 'bold',
  },
}));

const FileItem = props => {
  const { file, onDownload } = props;
  const { id, name, canDownload } = file || {};
  const classes = useStyles();

  let label = 'Download';
  if (!canDownload) {
    label =
      'Preparing final approved document and it will be available shortly for download.';
  }

  return (
    <ListItem>
      <Button
        className={classes.fileButton}
        variant="text"
        disableRipple
        onClick={() => onDownload(file)}
        disabled={!canDownload}
        data-test-id={`OfferDocumentsDetails-${id}-name`}
      >
        <FileIcon className={classes.fileIcon} /> {name}
      </Button>
      <ListItemSecondaryAction
        className={classes.downloadIcon}
        data-test-id={`OfferDocumentsDetails-${id}-download`}
      >
        {canDownload && (
          <Link icon onClick={() => onDownload(file)}>
            <span className="fa-stack">
              <DownloadIcon />
            </span>
          </Link>
        )}
        {!canDownload && (
          <ToolTipBox
            disableInteractive
            align="top"
            tooltipNode={label}
            bold={true}
          >
            <span>Processing...</span>
          </ToolTipBox>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default FileItem;
