import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import {
  Button,
  DialogActions,
  DialogContent,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import { connect } from 'react-redux';
import {
  Visibility as ViewIcon,
  CloudDownload as DownloadIcon,
} from '@mui/icons-material';
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import useSaveStateW4Document from './hooks/useSaveStateW4Document';
import ErrorIndicator from './ErrorIndicator';

const ValidContent = ({
  classes,
  onSubmit,
  offerId,
  workerType,
  name,
  documentId,
  uploadedAt,
  MYCNC_W4S_URL,
  workState,
  downloadUrl,
  downloadError,
  onModalClick,
  pushNotification,
  popNotification,
}) => {
  const [
    saveStateW4Document,
    { loading: saveExtLoading, error: documentsSaveError },
  ] = useSaveStateW4Document();

  const submit = () => {
    const variables = {
      offerId,
      name,
      uploadedAt,
      documentId,
      source: 'mycnc',
      type: 'state_w4',
    };
    saveStateW4Document({ variables })
      .then(({ data }) => {
        const { saveOfferExternalDocument } = data;
        if (saveOfferExternalDocument) {
          onSubmit(true);
        } else {
          onSubmit(false);
        }
      })
      .catch(error => {
        onSubmit(false);
      });
  };

  const downloadDocument = () => {
    pushNotification({
      message:
        'Hold on a moment while we prepare the document for download. Your download should being momentarily.',
      variant: SnackbarVariants.INFO,
    });

    if (downloadError) {
      pushNotification({
        message:
          'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.',
        variant: SnackbarVariants.ERROR,
      });
    }

    setTimeout(() => {
      popNotification();
      if (downloadUrl) window.location.href = downloadUrl;
    }, 2000);
  };

  return (
    <>
      <DialogContent>
        <Typography className={classes.subHead}>
          It looks like you have an existing tax withholding form that you can
          use. Would you like to use it for this Start+ offer?
        </Typography>
        <List>
          <ListItem classes={{ root: classes.listRoot }}>
            <ListItemText
              primary="File Name"
              secondary={name}
              className={classes.itemText}
            />
            <ListItemText
              primary="Date Uploaded"
              secondary={moment(uploadedAt).format('MMMM Do[,] YYYY')}
              className={classes.itemText}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={() => onModalClick(true)}
                className={classes.icon}
              >
                <ViewIcon />
              </IconButton>
              <IconButton edge="end" onClick={() => downloadDocument()}>
                <DownloadIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </DialogContent>
      {documentsSaveError ? <ErrorIndicator /> : null}
      <DialogActions className={classes.actionButtons}>
        <Button
          href={`${MYCNC_W4S_URL}?offerId=${offerId}&w4State=${workState}&workerType=${workerType}`}
          className={classnames(classes.btn, classes.btnOutlined)}
          disabled={saveExtLoading}
        >
          Complete A New Form
        </Button>
        <Button
          data-test-id="Dialog-action-Complete Statew4"
          className={classnames(classes.btn, classes.btnContained)}
          onClick={submit}
          disabled={saveExtLoading}
        >
          {saveExtLoading ? (
            <>
              Saving... <CircularProgress size={20} />
            </>
          ) : (
            'Looks Good, Next'
          )}
        </Button>
      </DialogActions>
    </>
  );
};

const mapDispatchToProps = {
  pushNotification,
  popNotification,
};

export default connect(null, mapDispatchToProps)(ValidContent);
