import React, { useEffect } from 'react';

// Components
import Select from 'react-select';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// HoC
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';

// GQL
import { Queries } from 'common/apollo';

// Utilities
import variableTest from '../helpers/variableTest';
import formatOptionLabel from './formatOptionLabel';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';

const styles = theme => ({
  root: {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'max-content max-content 15px',
  },
  label: {
    gridColumn: 1,
    gridRow: 1,
  },
  payGuideLabel: {
    gridColumn: 2,
    gridRow: 1,
    placeSelf: 'end',
    padding: 5,
    gridAutoFlow: 'column',
    placeItems: 'center',
  },
  select: {
    gridColumn: '1 / -1',
    gridRow: 2,
  },
  icon: {
    height: '14px',
    width: 'auto',
  },
  error: {},
});

const WorkScheduleSelect = props => {
  const {
    onChange: upstreamOnChange,
    formData = {},
    data: {
      workSchedules = [],
      loading,
      variables: graphqlQueryVariables = {},
    } = {},
    error = '',
    selectStyles,
    classes = {},
    disabled,
  } = props;
  const { workSchedule, union } = formData || {};
  const { value: workScheduleValue } = workSchedule || {};
  const { isNonUnion = false } = union || {};

  const options = () => {
    // Ensure that the current data is using the most up to data query variables
    if (!variableTest(graphqlQueryVariables, formData)) return [];
    // Format options for react-select
    return workSchedules.map(({ code, description }) => ({
      value: code,
      label: description,
    }));
  };
  // We only store the code in our formData but we need to supply the formatted option
  // as the selected value
  const value =
    options().find(({ value }) => workScheduleValue === value) || null;

  const onChange = workSchedule =>
    upstreamOnChange({
      workSchedule,
      payAtRollback: null,
    });

  useEffect(() => {
    if (formData?.workSchedule) return;
    let options = workSchedules.map(({ code, description }) => ({
      value: code,
      label: description,
    }));
    // Ensure that the current data is using the most up to data query variables
    if (!variableTest(graphqlQueryVariables, formData)) options = [];
    if (options.length === 1) {
      onChange(options[0]);
    }
  }, [workSchedules, formData]); // eslint-disable-line react-hooks/exhaustive-deps
  // if we include upstreamOnChange in the dependency array, it will cause an infinite loop

  const getPdfLink = countryCode => (
    <Link
      href={`/samples/${countryCode === 'CA' ? 'Canada' : 'Us'}PaySchedule.pdf`}
      color="inherit"
      target="_blank"
      rel="noopener noreferrer"
      className={classes.payGuideLabel}
    >
      {countryCode === 'CA'
        ? 'Work Schedules Guide'
        : 'Non-Union Pay Schedule Guide'}{' '}
      <OpenInNewIcon className={classes.icon} />
    </Link>
  );

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1">
        Work Schedule
      </Typography>
      <UsaOnly>{isNonUnion && getPdfLink('US')}</UsaOnly>
      <CanadaOnly>{getPdfLink('CA')}</CanadaOnly>
      <div data-test-id="WorkScheduleSelect-select" className={classes.select}>
        <Select
          styles={selectStyles}
          onChange={onChange}
          value={value}
          options={loading ? [] : options()}
          isLoading={loading}
          formatOptionLabel={formatOptionLabel('WorkScheduleSelect')}
          isDisabled={disabled}
        />
      </div>
      {error && (
        <Typography
          color="error"
          variant="caption"
          className={classes.error}
          data-test-id="WorkScheduleSelect-error"
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

const getWorkScheduleConfig = {
  skip: ({
    formData: {
      workState,
      hireState,
      occupation,
      union,
      startDate,
      currency,
    } = {},
  }) =>
    (workState || '') === '' ||
    (hireState || '') === '' ||
    (union || '') === '' ||
    (occupation || '') === '' ||
    (currency || '') === '' ||
    (startDate || '') === '',
  options: ({
    formData: {
      startDateObject,
      workState,
      hireState,
      workCity,
      hireCity,
      currency,
      union = {},
      occupation = {},
      season,
    } = {},
    match: { params: { projectId } = {} } = {},
  }) => {
    const { value: unionCode } = union || {};
    const { value: occupationCode } = occupation || {};
    return {
      variables: {
        projectId: parseInt(projectId, 10),
        startDate: startDateObject && startDateObject.format('YYYY-MM-DD'),
        workState,
        hireState,
        workCity,
        hireCity,
        currency,
        union: unionCode,
        occupation: occupationCode,
        season,
      },
      fetchPolicy: 'cache-and-network',
    };
  },
};

export default compose(
  withRouter,
  graphql(Queries.GetWorkSchedules, getWorkScheduleConfig),
  withStyles(styles),
)(WorkScheduleSelect);
