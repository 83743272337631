import React, { useState } from 'react';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import classnames from 'classnames';
import Document from './Document';
import ConfirmButton from './ConfirmButton';
import ErrorIndicator from './ErrorIndicator';

const DocumentsConfirm = ({ onClose, classes, onNext, data }) => {
  const { offers, documents, note } = data;

  const [error, setError] = useState(null);

  const handleError = err => {
    setError(err);
  };

  // back to previous screen - documents
  const hnadleBack = () => {
    onNext('note', { note });
  };

  return (
    <>
      <DialogContent data-test-id="confirm-documents-section">
        <p className={classes.confirmSubHeader}>
          Are you sure you want to send these documents:
        </p>
        {documents &&
          documents.map(doc => {
            return (
              doc.checked && (
                <Document
                  preview={true}
                  key={doc.id}
                  data={doc}
                  classes={classes}
                />
              )
            );
          })}
        <p className={classes.confirmNote}>
          Note:<span data-test-id="preview-note">{note || ''}</span>
        </p>
        <List data-test-id="preview-crew-members-list">
          <div className={classes.confirmUsersList}>
            <p className={classes.confirmSubHeader}>to these crew members?</p>
            {offers.map(({ profile }) => (
              <ListItem
                key={profile.id}
                classes={{ gutters: classes.listItemGutters }}
              >
                <ListItemText
                  data-test-id={`preview-crew-member-${profile.id}`}
                  primary={profile?.fullName + ',' + profile?.email}
                  secondary={null}
                />
              </ListItem>
            ))}
          </div>
        </List>
      </DialogContent>
      {error && (
        <ErrorIndicator message="Oops! Something went wrong. Please try after some time or contact support" />
      )}
      <DialogActions>
        <Button
          onClick={() => hnadleBack()}
          className={classnames(classes.btn, classes.btnOutlined)}
          variant="outlined"
        >
          No, Take Me Back
        </Button>
        <ConfirmButton
          data={data}
          classes={classes}
          onError={handleError}
          onClose={onClose}
        />
      </DialogActions>
    </>
  );
};

export default DocumentsConfirm;
