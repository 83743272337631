import { useQuery } from '@apollo/react-hooks';
import { Queries } from 'common/apollo';
import useStates from 'common/hooks/useStates';
import useCities from 'common/hooks/useCities';

const useUnions = variables => {
  const {
    startDate,
    workState,
    hireState,
    workCity,
    hireCity,
    countryId,
    currency,
  } = variables;
  const isUsa = parseInt(countryId) === 229;
  const { data: states } = useStates(isUsa && countryId);
  const workStateId =
    workState && states && states.find(({ code }) => code === workState).id;
  const hireStateId =
    hireState && states && states.find(({ code }) => code === hireState).id;
  const {
    loading: isWorkStateCitiesLoading,
    data: workStateCities,
  } = useCities({
    countryId,
    stateId: workStateId,
  });
  const {
    loading: isHireStateCitiesLoading,
    data: hireStateCities,
  } = useCities({
    countryId,
    stateId: hireStateId,
  });

  const skip =
    !startDate ||
    !workState ||
    !hireState ||
    !currency ||
    (isUsa &&
      (isWorkStateCitiesLoading ||
        isHireStateCitiesLoading ||
        (!!workStateCities && workStateCities.length > 0 && !workCity) ||
        (!!hireStateCities && hireStateCities.length > 0 && !hireCity)));

  const { loading, data, error } = useQuery(Queries.GetUnions, {
    skip,
    variables,
    notifyOnNetworkStatusChange: true,
  });

  return { loading, data: data && data.unions, error };
};

export default useUnions;
