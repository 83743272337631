import React from 'react';
import { Snackbar, IconButton } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import { Close } from '@mui/icons-material';

const Notification = props => {
  const { open, setOpen, message, severity, autoHide = 3000 } = props;
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHide}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        variant="filled"
        elevation={6}
        severity={severity}
        action={
          <IconButton size="small" onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        }
        data-test-id="OfferDrafts-alert"
      >
        <AlertTitle data-test-id="OfferDrafts-alert-message">
          {message}
        </AlertTitle>
      </Alert>
    </Snackbar>
  );
};

export default Notification;
