import React from 'react';
import { Chip } from '@mui/material';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import DoneIcon from '@mui/icons-material/Done';

const styles = {
  chip: {
    background: '#0000ff !important',
    color: '#ffffff !important',
    fontWeight: 400,
    fontSize: '0.9rem !important',
    letterSpacing: '0.16px',
    lineHeight: '18px',
    marginTop: 6,
    marginRight: '2px !important',
    '&:hover': {
      background: '#0000ff',
    },
    '& .MuiChip-icon': { color: '#ffffff' },
  },
  icon: { color: '#ffffff !important' },
  selectedChip: {
    background: 'linear-gradient(180deg, #1A55FD 0%, #000080 100%) !important',
    border: '1px solid #BECFFE !important',
    '&:hover': {
      background:
        'linear-gradient(180deg, #1A55FD 0%, #000080 100%) !important',
    },
  },
};
const SettingChip = ({ classes, label, value, setValue, disabled }) => {
  return (
    <Chip
      icon={value ? <DoneIcon className={classes.icon} /> : null}
      label={label}
      clickable
      className={classnames(classes.chip, value ? classes.selectedChip : '')}
      onClick={() => setValue(value)}
      disabled={disabled}
      cursor={disabled ? 'not-allowed' : 'pointer'}
    />
  );
};

export default withStyles(styles)(SettingChip);
