import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

// HoC
import { connect } from 'react-redux';
import { compose } from 'redux';

// Actions
import {
  pushNotification,
  popNotification,
  clearNotification,
} from 'common/store/actions/dialogNotifications';

// Pass the pushNotification and popNotification redux actions
// and forward upstream props to the inner component
const WrappedComponent = InnerComponent => {
  const Wrapper = props => {
    return <InnerComponent {...props} />;
  };
  // Copy any static methods and/or props from the
  // InnerComponent to the new wrapped component
  // For example, static query and mutator props
  // need to be hoisted to the new Wrapper component
  // in order for the withApi HoC to work properly
  hoistNonReactStatic(Wrapper, InnerComponent);
  return Wrapper;
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  pushDialogNotification: pushNotification,
  popDialogNotification: popNotification,
  clearDialogNotification: clearNotification,
};

const withDialogNotification = compose(
  connect(mapStateToProps, mapDispatchToProps),
  WrappedComponent,
);

export default withDialogNotification;
