import React from 'react';

import {
  TableCell,
  TableHead as MuiTableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import withRouteHelpers from 'common/hoc/withRouteHelpers';

const TableHead = props => {
  const { amendRouterQuery, getRouterQueryParam: param } = props;
  const order = param('order', 'asc');
  const orderBy = param('orderBy', 'name');

  const updateOrder = newOrderBy => {
    let newOrder = 'asc';
    if (orderBy === newOrderBy) {
      newOrder = order === 'asc' ? 'desc' : 'asc';
    }
    amendRouterQuery({ orderBy: newOrderBy, order: newOrder, page: 1 });
  };

  const renderSortableTableCell = (field, title) => (
    <TableCell sortDirection={orderBy === field ? order : false}>
      <TableSortLabel
        direction={order}
        active={orderBy === field}
        onClick={() => updateOrder(field)}
        data-test-id={`TableHead-sort-${field}`}
      >
        {title}
      </TableSortLabel>
    </TableCell>
  );

  return (
    <MuiTableHead>
      <TableRow>
        {renderSortableTableCell('name', 'Name')}
        {renderSortableTableCell('uploadedBy', 'Uploaded By')}
        {renderSortableTableCell('updatedBy', 'Last Modified By')}
        <TableCell>Tags</TableCell>
        <TableCell>Status</TableCell>
        <TableCell />
      </TableRow>
    </MuiTableHead>
  );
};

export default withRouteHelpers(TableHead);
