export const RULE_TYPES = [
  { id: 4, description: 'All', code: 'all', typeName: 'All' },
  {
    id: 2,
    description: 'Departments',
    code: 'department',
    typeName: 'Department',
  },
  {
    id: 6,
    description: 'Employment Type',
    code: 'employmentType',
    typeName: 'EmploymentType',
  },
  { id: 1, description: 'Unions', code: 'union', typeName: 'Union' },
  { id: 3, description: 'Locations', code: 'location', typeName: 'Location' },
  {
    id: 7,
    description: 'Allowances',
    code: 'allowance',
    typeName: 'Allowances',
  },
  { id: 5, description: 'Combo', code: 'combo', typeName: 'Combo' },
];
