import React, { useState } from 'react';
import { Table, TableCell, TableRow, Select, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { ratePerHourMask } from '../../helpers/textFieldNumberMasks';
import MaskedInput from 'react-text-mask';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import formatCurrency from 'studio/components/OfferPage/helpers/formatCurrency';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 40,
    marginBottom: 40,
  },
  cellTitle: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectItemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  dollar: {
    width: '10%',
    fontSize: 14,
    marginTop: 11,
    color: '#696969',
    paddingLeft: 7,
    fontFamily: 'Roboto, Helvetica, Helvetica Neue, san-serif',
  },
  hoursPerDayCell: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    borderTop: 'none',
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 8,
  },
  tableSpacing: {
    marginTop: 16,
    marginBottom: 20,
    border: '1px solid #0000FF',
  },
  topSpacing: {
    marginTop: 20,
  },
  hoursCopy: {
    marginTop: 10,
    paddingRight: 7,
    color: '#525252',
    fontSize: 16,
    fontWeight: 'normal',
  },
  warningIcon: {
    color: '#FFA800',
    position: 'relative',
    top: 4,
    right: 8,
  },
  warningLabel: {
    color: '#FFA800',
    fontSize: 14,
    textAlign: 'center',
  },
  errorIcon: {
    color: 'red',
    position: 'relative',
    top: 4,
    right: 8,
  },
  errorLabel: {
    color: 'red',
    fontSize: 14,
    textAlign: 'center',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hoursPerDay: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
  },
  label: {
    color: '#262626',
    fontSize: 16,
  },
  rightCellArea: {
    width: '41%',
    backgroundColor: '#FFFFFF',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  input: {
    '& input': {
      textAlign: 'center',
    },
  },
}));

const WeeklyRateTable = (props = {}) => {
  const {
    onChange: upstreamOnChange,
    formData = {},
    formErrors = {},
    isReadOnly = false,
  } = props;
  const classes = useStyles();
  const {
    rate,
    sixthDayPayMultiplication,
    seventhDayPayMultiplication,
  } = formData;

  const intialLoadFormattedRate = rate ? formatCurrency(rate) : null;

  const [weeklyRate, setWeeklyRate] = useState(intialLoadFormattedRate);
  const onChange = (ev = {}) => {
    const { target: { value: rawValue } = {} } = ev;
    let value = rawValue;
    if (rawValue === '' || rawValue === '0') value = '';
    if (value > 0) {
      const formattedValue = formatCurrency(parseFloat(value));
      setWeeklyRate(formattedValue);
    }
  };

  const onBlur = () => {
    upstreamOnChange({
      weeklyRate,
      rate: weeklyRate,
      isTermsOfEmploymentV2: true,
      guaranteedHoursFrequency: 'weekly',
    });
  };

  const onSelectChange = (ev = {}, formKey) => {
    const { target: { value } = {} } = ev;
    upstreamOnChange({
      [formKey]: value,
      isTermsOfEmploymentV2: true,
    });
  };

  return (
    <Table>
      <TableRow>
        <TableCell className={classes.cellTitle}>
          <div className={classes.errorContainer}>
            <Typography
              className={classes.hoursPerDay}
              data-test-id="weeklyRate-title"
            >
              Weekly Rate
            </Typography>
            {formErrors.weeklyRate && (
              <Typography
                color="warning"
                variant="caption"
                className={classes.errorLabel}
              >
                <WarningIcon className={classes.errorIcon} />
                {formErrors.weeklyRate}
              </Typography>
            )}
          </div>
        </TableCell>
        <TableCell className={classes.rightCellArea}>
          <div className={classes.itemContainer}>
            <Typography className={classes.dollar}>$</Typography>
            <MaskedInput
              mask={ratePerHourMask}
              value={weeklyRate || ''}
              onChange={onChange}
              onBlur={onBlur}
              className={classes.input}
              render={(ref, props) => (
                <TextField
                  inputRef={ref}
                  {...props}
                  variant="standard"
                  disabled={isReadOnly}
                  InputProps={{
                    inputProps: {
                      'data-test-id': 'WeeklyRate-input',
                    },
                  }}
                />
              )}
            />
            <Typography className={classes.hoursCopy}>/week</Typography>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.cellTitle}>
          <div className={classes.errorContainer}>
            <Typography className={classes.hoursPerDay}>
              Pay 6th Day at
            </Typography>
          </div>
        </TableCell>
        <TableCell className={classes.rightCellArea}>
          <div className={classes.selectItemContainer}>
            <Select
              value={sixthDayPayMultiplication || 0}
              onChange={ev => onSelectChange(ev, 'sixthDayPayMultiplication')}
              className={classes.selectContainer}
              disabled={isReadOnly}
              inputProps={{
                'data-test-id': 'WeeklyRateSelect-sixthDay',
              }}
            >
              <MenuItem value={0}>No additional pay</MenuItem>
              <MenuItem value={1.0}>1.0x</MenuItem>
              <MenuItem value={1.5}>1.5x</MenuItem>
              <MenuItem value={2.0}>2.0x</MenuItem>
            </Select>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.cellTitle}>
          <div className={classes.errorContainer}>
            <Typography className={classes.hoursPerDay}>
              Pay 7th Day at
            </Typography>
          </div>
        </TableCell>
        <TableCell className={classes.rightCellArea}>
          <div className={classes.selectItemContainer}>
            <Select
              value={seventhDayPayMultiplication || 0}
              onChange={ev => onSelectChange(ev, 'seventhDayPayMultiplication')}
              className={classes.selectContainer}
              inputProps={{
                'data-test-id': 'WeeklyRateSelect-seventhDay',
              }}
              disabled={isReadOnly}
            >
              <MenuItem value={0}>No additional pay</MenuItem>
              <MenuItem value={1.0}>1.0x</MenuItem>
              <MenuItem value={1.5}>1.5x</MenuItem>
              <MenuItem value={2.0}>2.0x</MenuItem>
            </Select>
          </div>
        </TableCell>
      </TableRow>
    </Table>
  );
};

export default WeeklyRateTable;
