import collection from '../collection.js';
import object from '../object.js';
var classes = null;

classes = {
  set: function() {
    var classes = '';

    collection.each(arguments, function(_, arg) {
      var argtype = typeof arg;

      if (arg) {
        if ('string' === argtype || 'number' === argtype) {
          classes += ' ' + arg;
        } else if (collection.isArray(arg)) {
          classes += ' ' + classes.set(arg);
        } else if (object.isObject(arg)) {
          object.each(arg, function(key, val) {
            if (val) {
              classes += ' ' + key;
            }
          });
        }
      }
    });

    return classes.substr(1);
  },

  add: function(el, className) {
    if (el.classList) {
      el.classList.add(className);
    }
  },

  remove: function(el, className) {
    if (el.classList) {
      el.classList.remove(className);
    }
  },

  contains: function(el, className) {
    return el.classList && el.classList.contains(className);
  },
};

export default classes;
