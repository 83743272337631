import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import DocumentFieldsExpansionPanel from './DocumentFieldsExpansionPanel';
import EmptyMessage from 'studio/components/OfferPage/EmptyMessage';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import classNames from 'class-names';
import { ChevronRight } from '@mui/icons-material';
import toggleInList from 'common/utilities/toggleInList';

const styles = theme => ({
  root: {
    display: 'grid',
    height: 'max-content',
    width: '100%',
    gridTemplateColumns: '100%',
    gap: '10px',
  },
  rootWithInputs: {
    gridTemplateRows: 'max-content max-content',
  },
  rootWithoutInputs: {
    gridTemplateRows: 'max-content 150px',
  },
  noInputsMessage: {
    placeSelf: 'center',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 40,
  },
  description: {
    color: '#6D7278',
    fontSize: 12,
  },
  error: {
    color: 'red',
    fontSize: '0.9rem',
    width: 250,
  },
  expandIcon: {
    transform: 'rotate(-90deg)',
  },
  collapseIcon: {
    transform: 'rotate(90deg)',
  },
});

// Makes a copy of the Templates collection, removing any templates which are
// not selected or have no inputs, and adding the current value from formData
// to every field object.
const getStatefulDocumentFieldsTree = (templates = [], formData = {}) => {
  const { documents: selectedDocumentIds = [], documentFields = [] } = formData;

  return templates
    .filter(
      ({ id, inputFieldGroups }) =>
        selectedDocumentIds.includes(id) && inputFieldGroups.length,
    )
    .map(template => ({
      ...template,
      inputFieldGroups: template.inputFieldGroups.map(inputFieldGroup => ({
        ...inputFieldGroup,
        fields: inputFieldGroup.fields.map(field => ({
          ...field,
          value: documentFields.find(({ id }) => id === field.id)?.value,
        })),
      })),
    }));
};

const DocumentFields = props => {
  const {
    formData,
    formData: { documentFields = [] } = {},
    formErrors = [],
    templates,
    onChange: upstreamOnChange,
    classes = {},
  } = props || {};

  const documents = getStatefulDocumentFieldsTree(templates, formData);

  // From hereon down we use only the combined tree of templates and formData.
  const hasInputs = documents.length > 0;
  const { expandIcon, collapseIcon } = classes;
  const rootClass = classNames(classes.root, {
    [classes.rootWithInputs]: hasInputs,
    [classes.rootWithoutInputs]: !hasInputs,
  });

  // Panels default to expanded so store the collapsed ones
  const allPanelIds = documents.map(({ id }) => id);
  const [collapsedPanelIds, setCollapsedPanelIds] = useState([]);
  const arePanelsOpen = collapsedPanelIds.length < allPanelIds.length;
  const btnTitle = arePanelsOpen ? 'Collapse All' : 'Expand All';

  const onChange = (fieldId, newValue) => {
    // Remove field from documentFields
    const newDocumentFields = documentFields.filter(({ id }) => id !== fieldId);

    // If value is present, add field with new value
    if (newValue) newDocumentFields.push({ id: fieldId, value: newValue });

    // Update form state
    upstreamOnChange(newDocumentFields);
  };

  return (
    <div className={rootClass}>
      <div className={classes.row}>
        <Typography variant="h5" className={classes.header}>
          Custom Questions
          <Typography variant="subtitle1" className={classes.description}>
            Questions relating to the selected documents
          </Typography>
          {!!formErrors.length && (
            <Typography
              className={classes.error}
              variant="caption"
              data-test-id="DocumentFields-error"
            >
              {'Please fill out the required document field inputs'}
            </Typography>
          )}
        </Typography>

        {hasInputs && (
          <Button
            data-test-id="DocumentFields-collapseBtn"
            color="primary"
            variant="contained"
            onClick={() =>
              setCollapsedPanelIds(arePanelsOpen ? allPanelIds : [])
            }
          >
            {btnTitle}
            <ChevronRight
              className={classNames({
                [collapseIcon]: !arePanelsOpen,
                [expandIcon]: arePanelsOpen,
              })}
            />
          </Button>
        )}
      </div>
      {!hasInputs && (
        <EmptyMessage
          classes={{ root: classes.noInputsMessage }}
          text="There are no custom questions for the selected documents"
        />
      )}
      {documents.map(({ id, name, inputFieldGroups }) => (
        <DocumentFieldsExpansionPanel
          key={id}
          templateName={name}
          isExpanded={!collapsedPanelIds.includes(id)}
          onChange={onChange}
          onToggleExpansion={() =>
            setCollapsedPanelIds(toggleInList(collapsedPanelIds, id))
          }
          inputFieldGroups={inputFieldGroups}
          formErrors={formErrors.filter(err => err.documentId === id)}
        />
      ))}
    </div>
  );
};

export default compose(withStyles(styles), withRouter)(DocumentFields);
