// React
import React from 'react';

// HoC
import { withStyles } from '@mui/styles';
// Mui Components
import Button from '@mui/material/Button';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const styles = theme => ({
  message: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.contrastText,
  },
});

const Dialog = ({
  actions = [],
  open,
  handleClose,
  message = '',
  title = '',
  disableClose = false,
  classes,
  className,
}) => {
  const isMessageString = typeof message === 'string';

  const renderActions = () =>
    actions.map(({ fn, text, color, ...rest }) => (
      <Button
        onClick={fn}
        color={color}
        variant="contained"
        key={text}
        {...rest}
        data-test-id={`Dialog-action-${text}`}
      >
        {text}
      </Button>
    ));

  return (
    <MuiDialog
      className={className}
      classes={classes}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-test-id={`Dialog-root`}
      disableBackdropClick={disableClose}
      disableEscapeKeyDown={disableClose}
    >
      <DialogTitle id="alert-dialog-title" data-test-id={`Dialog-title`}>
        {title}
      </DialogTitle>
      <DialogContent data-test-id={`Dialog-message`}>
        {isMessageString && (
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        )}
        {!isMessageString && message}
      </DialogContent>
      <DialogActions>{renderActions()}</DialogActions>
    </MuiDialog>
  );
};

export default withStyles(styles)(Dialog);
