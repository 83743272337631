import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Check as CheckIcon } from '@mui/icons-material';

import Loader from 'common/components/Loader';
import useProjectSearch from './hooks/useProjectSearch';
import countryFlag from 'common/utilities/renderCountryFlag.js';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';

const styles = {
  table: {
    width: '100%',
  },
  keyword: {
    fontWeight: 'bolder',
    textDecoration: 'underline',
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  selectedCell: {
    fontWeight: 'bolder',
    color: '#0000FF',
  },
  headRow: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
    background: 'transparent',
    '&:nth-child(1)': {
      width: '10%',
    },
    '&:nth-child(2)': {
      width: '85%',
    },
    '&:nth-child(3)': {
      width: '5%',
      textAlign: 'center',
    },
  },
  chk: {
    opacity: 0,
  },
  chkSelected: {
    opacity: 1,
    color: '#0000FF',
  },
  loaderContainer: {
    height: 100,
  },
  noResultsContainer: {
    textAlign: 'center',
  },
  countryFlagImageSize: {
    width: '24px',
    height: '24px',
    marginLeft: '10px',
  },
};

const ProjectList = props => {
  const {
    classes,
    to,
    keyword = '',
    environment = '',
    onSelect = () => {},
    nonRelated = false,
    showOnlyCA = false,
  } = props;

  const validKeyword = keyword.length > 1;
  const [selectedProject, setSelectedProject] = useState(null);
  const {
    loading,
    error: { message: graphQlError } = {},
    data: projects = [],
  } = useProjectSearch(to, keyword, environment, nonRelated);

  if (!validKeyword) return <div />;
  if (loading) {
    return (
      <div className={classes.loaderContainer}>
        <Loader />
      </div>
    );
  }

  const handleRowClick = project => {
    if (selectedProject === project.id) {
      setSelectedProject(null);
      onSelect(null, environment);
    } else {
      setSelectedProject(project.id);
      onSelect(project, environment);
    }
  };

  const projectRow = project => {
    const { id, cncId, country, name } = project;
    const selected = project.id === selectedProject;
    let cellClass = selected ? classes.selectedCell : classes.row;
    const renderCountryFlag = countryFlag(country?.code);

    return (
      <TableRow
        data-test-id={`ProjectList-project-${id}`}
        className={classes.row}
        key={id}
        onClick={() => {
          handleRowClick(project);
        }}
      >
        <TableCell className={cellClass}>
          <img
            src={renderCountryFlag}
            alt="region flag"
            className={classes.countryFlagImageSize}
          />
        </TableCell>
        <TableCell className={cellClass}>{cncId}</TableCell>
        <TableCell className={cellClass}>{name}</TableCell>
        <TableCell className={cellClass}>
          <CheckIcon className={selected ? classes.chkSelected : classes.chk} />
        </TableCell>
      </TableRow>
    );
  };

  const hasProjects = projects?.length > 0;

  if (graphQlError) {
    return (
      <Typography color="error" variant="caption" className={classes.error}>
        {graphQlError}
      </Typography>
    );
  }

  if (!hasProjects) {
    return (
      <div className={classes.noResultsContainer}>
        No results for <span className={classes.keyword}>{keyword}</span>
      </div>
    );
  }

  const renderProjects = () => {
    // Since this component will probably replace the legacy search at some point
    // just adding a showOnlyCA prop check here just in case this is used elsewhere
    if (hasProjects) {
      if (showOnlyCA) {
        return projects
          .filter(project => project?.country?.code === 'CA')
          .map(projectRow);
      } else {
        return projects.map(projectRow);
      }
    }
  };

  return (
    <Table className={classes.table}>
      <caption>Please select a project to continue.</caption>
      <TableHead>
        <TableRow>
          <TableCell className={classes.headRow}>Payroll Region</TableCell>
          <TableCell className={classes.headRow}>CNC ID</TableCell>
          <TableCell className={classes.headRow}>Name</TableCell>
          <TableCell className={classes.headRow} />
        </TableRow>
      </TableHead>
      <TableBody>{renderProjects()}</TableBody>
    </Table>
  );
};

export default withStyles(styles)(ProjectList);
