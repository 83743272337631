import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import formatCurrency from 'studio/components/OfferPage/helpers/formatCurrency';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '40px',
  },
  title: {
    color: '#646464',
    fontSize: '24px',
    fontWeight: 400,
    marginBottom: 25,
  },
  amount: {
    color: '#0000FF',
    fontSize: 30,
    fontWeight: 400,
    padding: 18,
    textAlign: 'center',
    border: '0.1px solid #d3f0ee',
    wordWrap: 'break-word',
    [theme.breakpoints.up('md')]: {
      padding: 11,
    },
  },
  smallerAmount: {
    color: '#0000FF',
    fontSize: 27,
    fontWeight: 400,
    padding: 18,
    textAlign: 'center',
    border: '0.1px solid #d3f0ee',
    wordWrap: 'break-word',
    [theme.breakpoints.up('md')]: {
      padding: 11,
    },
  },
  greenBox: {
    backgroundColor: '#E2F1FF',
    color: '#5b5b5b',
    fontSize: 14,
    fontWeight: 700,
    borderBottom: '0.1px solid #D9E3FE',
    textAlign: 'center',
    padding: '11px',
  },
  description: {
    color: '#6D7278',
    fontSize: 12,
    textAlign: 'right',
  },
}));

const FringeDetails = props => {
  const {
    offerData: {
      terms_of_employment: {
        total_fringe_percentage: totalFringePercentage,
        fringes_included: fringesIncluded,
        rate_per_hour_studio: hourlyStudioFringeRate,
        rate_per_day_studio: dailyStudioFringeRate,
        rate_per_week_studio: weeklyStudioFringeRate,
      } = {},
    } = {},
  } = props;

  const classes = useStyles();

  const isLargeAmount = amount => Number(amount) >= 1000;

  const fringeRelationshipHeader = fringesIncluded
    ? 'Included in rate'
    : 'In addition to rate';

  // This can be hourlyStudioFringeRate, dailyStudioFringeRate or weeklyStudioFringeRate depending on
  // whats filled in the table
  let rateToUse;

  // Should always default to the hourly studio rate if present and not null
  if (
    hourlyStudioFringeRate &&
    !dailyStudioFringeRate &&
    !weeklyStudioFringeRate
  ) {
    rateToUse = parseFloat(hourlyStudioFringeRate);
  }

  if (
    (hourlyStudioFringeRate && dailyStudioFringeRate) ||
    (hourlyStudioFringeRate && weeklyStudioFringeRate)
  ) {
    rateToUse = parseFloat(hourlyStudioFringeRate);
  }

  // Cases where there is no hourly studio rate, but one of the other two rates are present
  if (dailyStudioFringeRate && !hourlyStudioFringeRate) {
    rateToUse = parseFloat(dailyStudioFringeRate);
  }

  if (weeklyStudioFringeRate && !hourlyStudioFringeRate) {
    rateToUse = parseFloat(weeklyStudioFringeRate);
  }
  const convertedFringePercetange = parseFloat(totalFringePercentage) / 100;

  const inludedFringesInitialCalculation =
    rateToUse * (1 + convertedFringePercetange);
  const excludedFringesInitialCalculation =
    rateToUse / (1 + convertedFringePercetange);

  const calculatedInludedFringe =
    Math.round(formatCurrency(inludedFringesInitialCalculation) * 100) / 100;
  const calculatedExcludedFringe =
    Math.round(formatCurrency(excludedFringesInitialCalculation) * 100) / 100;

  // Set the rendered fringe rate depending if `fringesIncluded` is true or false
  let includedFringeRate, excludedFringeRate;

  if (fringesIncluded) {
    includedFringeRate = rateToUse.toFixed(2);
    excludedFringeRate = calculatedExcludedFringe.toFixed(2);
  } else {
    includedFringeRate = calculatedInludedFringe.toFixed(2);
    excludedFringeRate = rateToUse.toFixed(2);
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Vacation/Fringe</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.greenBox}>
              {fringeRelationshipHeader}
            </TableCell>

            <TableCell className={classes.greenBox}>
              Rate Including Vacation/Fringe
            </TableCell>

            <TableCell className={classes.greenBox}>
              Rate Excluding Vacation/Fringe
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              className={classes.smallerAmount}
              data-test-id="TermsOfEmploymentDesktop-fringe-percentage"
            >
              {totalFringePercentage}%
            </TableCell>
            <TableCell
              className={
                isLargeAmount(includedFringeRate)
                  ? classes.smallerAmount
                  : classes.amount
              }
              data-test-id="TermsOfEmploymentDesktop-rate-include-fringes"
            >
              ${includedFringeRate}
            </TableCell>
            <TableCell
              className={
                isLargeAmount(excludedFringeRate)
                  ? classes.smallerAmount
                  : classes.amount
              }
              data-test-id="TermsOfEmploymentDesktop-rate-exclude-fringes"
            >
              ${excludedFringeRate}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography variant="subtitle1" className={classes.description}>
        Preview above rounds values to 2 decimal places. Actual calculations are
        not rounded.
      </Typography>
    </div>
  );
};

export default FringeDetails;
