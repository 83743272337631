import React from 'react';

import ProjectSearch from 'common/components/ProjectSearch';

const SearchProject = props => {
  const { project, keyword, environments, onSelect } = props;

  return (
    <ProjectSearch
      project={project}
      search={keyword}
      environments={environments}
      onSelect={onSelect}
    />
  );
};

export default SearchProject;
