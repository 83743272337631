import React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import classNames from 'class-names';

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '20px 1fr',
  },
  charCountLabel: {
    justifySelf: 'end',
    gridColumn: '2',
  },
  inputContainer: {
    height: '100%',
    gridColumn: '1 / -1',
    gridRow: 2,
    width: '100%',
  },
  formError: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
    },
  },
  input: {
    height: '100%',
  },
  fieldSetDisabled: {
    background: '#cacaca',
  },
});

const DealNoteEditor = ({
  onChange,
  notes,
  maxLength,
  disabled,
  classes,
  isFormError,
}) => {
  const fieldSetClass = classNames({ [classes.fieldSetDisabled]: disabled });
  const multiLineClasses = classNames(classes.inputContainer, {
    [classes.formError]: isFormError,
  });

  return (
    <div className={classes.root}>
      <Typography
        className={classes.charCountLabel}
        variant="body1"
        color={notes.length === maxLength ? 'error' : 'default'}
      >
        {notes.length} / {maxLength}
      </Typography>
      <TextField
        onChange={onChange}
        value={notes}
        className={multiLineClasses}
        variant="outlined"
        multiline
        rows={5}
        InputProps={{
          className: classes.input,
          classes: {
            multiline: fieldSetClass,
          },
          inputProps: {
            'data-test-id': 'DealNoteEditor-input',
          },
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default withStyles(styles)(DealNoteEditor);
