import uuid from 'uuid';
import * as FieldTypes from 'common/utilities/constants/fieldTypes';
import * as RuleConstants from 'admin/components/RoleMapper/FieldDrawer/RuleAssignment/ruleConstants';

const responseBehaviorTranslation = {
  show: 'shown',
  hide: 'hidden',
  require: 'required',
  disable: 'disabled',
  enable: 'enabled',
  set: 'set',
};

// Returns the rule group id of the field if
// assigned to a rule
export const getFieldRuleGroupId = (rules, fieldId) => {
  const assignedRule = rules.find(({ fieldGroups = [] }) =>
    fieldGroups.some(({ fieldIds = [] }) => fieldIds.includes(fieldId)),
  );
  const { fieldGroups = [] } = assignedRule || {};
  const assignedRuleGroup =
    fieldGroups.find(({ fieldIds }) => fieldIds.includes(fieldId)) || {};
  return assignedRuleGroup.id || null;
};

// Returns the role id that is associated with the given rule id
export const getRuleRoleId = (rules = [], fields = [], ruleId) => {
  let roleId = null;
  const selectedRule = rules.find(({ id }) => id === ruleId);
  const { fieldGroups = [], conditionalRuleIds = [] } = selectedRule || {};
  fields.forEach(field => {
    if (fieldGroups.some(({ fieldIds }) => fieldIds.includes(field.id))) {
      roleId = field.roleId;
      return;
    }
    if (isAssignedToNestedRule(rules, conditionalRuleIds, field.id)) {
      roleId = field.roleId;
    }
  });
  return roleId;
};

// Returns boolean based on if the field is mapped to the rule
export const ruleContainsField = (rule, ruleGroupId, fieldId) => {
  const { fieldGroups = [] } = rule;
  const { fieldIds = [] } = fieldGroups.find(fg => fg.id === ruleGroupId) || {};
  return fieldIds.includes(fieldId);
};

// Removes a field group from a rule
export const removeRuleFieldGroup = (rules, ruleId, fieldGroupId) => {
  return rules.map(rule => {
    if (rule.id === ruleId) {
      const updatedFieldGroups = rule.fieldGroups.filter(
        ({ id }) => id !== fieldGroupId,
      );
      return {
        ...rule,
        fieldGroups: updatedFieldGroups,
      };
    } else {
      return rule;
    }
  });
};

// Add or remove a field id from a rule group
export const handleRuleGroupAssignment = (rule, ruleGroupId, fieldId) => {
  const { fieldGroups = [] } = rule;
  const containsField = ruleContainsField(rule, ruleGroupId, fieldId);
  const updatedFieldGroups = fieldGroups.map(fg => {
    const { id, fieldIds = [] } = fg || {};
    if (id === ruleGroupId) {
      // If the field already contains the rule id of the rule being mapped,
      // remove the field from the rule group
      return {
        ...fg,
        fieldIds: containsField
          ? fieldIds.filter(i => i !== fieldId)
          : [...fieldIds, fieldId],
      };
    } else {
      return fg;
    }
  });
  return {
    ...rule,
    fieldGroups: updatedFieldGroups,
  };
};

// Returns true if the field is mapped to any of the
// conditional rules provided and false otherwise
const isAssignedToNestedRule = (rules, nestedRuleIds, fieldId) => {
  if (!nestedRuleIds.length) return false;
  return nestedRuleIds.some(ruleId => {
    const rule = rules.find(r => r.id === ruleId) || {};
    const { fieldGroups = [] } = rule;
    return fieldGroups.some(({ fieldIds = [] }) => fieldIds.includes(fieldId));
  });
};

export const getRulePanelProps = ({
  rule,
  field,
  assignmentType,
  triggerActions,
  conditionalRules,
  responseBehaviorOptions,
}) => {
  let {
    name,
    triggerAction: { value: triggerValue } = {},
    conditionalRuleIds = [],
  } = rule || {};
  const { radioGroupItems = [], fieldType } = field || {};
  const assignedRadio =
    fieldType === FieldTypes.RDO &&
    triggerValue &&
    (radioGroupItems.find(radio => radio.name === triggerValue) || {});
  // If the trigger rule is assigned to a radio button value as its source
  // then display the radio name to keep consistency when displaying radio
  // buttons in menus, Chips, etc.
  if (assignedRadio.radioValue) triggerValue = assignedRadio.radioValue;
  // Filter for any conditional rules that are attached to the trigger rule
  const associatedConditionalRules = conditionalRules.filter(conditionalRule =>
    conditionalRuleIds.includes(conditionalRule.id),
  );
  const ruleDescription = getRuleDescription({
    triggerActions,
    fieldType,
    responseBehaviorOptions,
    assignmentType,
    triggerValue,
    rule,
  });
  return {
    associatedConditionalRules,
    ruleDescription,
    title: name,
  };
};

const getActionDescription = (action, value, isCheckbox) => {
  if (action === 'value') {
    return `set to "${value}"`;
  }
  if (isCheckbox && action === RuleConstants.PRESENT) {
    return RuleConstants.CHECKED;
  }
  if (action === 'lt_value') {
    return `less than ${value}`;
  }
  if (action === 'gt_value') {
    return `greater than ${value}`;
  }
  return action || '';
};

const insertCommas = text => {
  if (text.length === 2) {
    text.splice(1, 0, 'and');
    return text.join(' ');
  } else if (text.length > 2) {
    text.splice(text.length - 2, 0, 'and');
    return text.join(', ');
  } else {
    return text.join('');
  }
};

const getResponseText = (responseBehavior, options) =>
  responseBehavior.map(
    ({ responseBehaviorType, value: responseValue }, index) => {
      // Find the response behavior object
      const response =
        options.find(option => option.name === responseBehaviorType) || {};
      if (responseBehaviorType === RuleConstants.SET && responseValue) {
        return `set to "${responseValue}"`;
      } else {
        return (
          (response.name && `${responseBehaviorTranslation[response.name]}`) ||
          ''
        );
      }
    },
  );

const getRuleDescription = ({
  triggerActions,
  fieldType,
  responseBehaviorOptions,
  assignmentType,
  triggerValue,
  rule,
}) => {
  const {
    triggerAction: { triggerActionType } = {},
    responseBehavior = [],
    ruleType,
    description,
  } = rule || {};
  const isConfiguringTrigger = assignmentType === RuleConstants.TRIGGER;
  const isConfiguringShare = assignmentType === RuleConstants.SHARE;
  const isEmail = ruleType === RuleConstants.EMAIL;
  const isCheckbox = fieldType === FieldTypes.CHK;
  const triggerFieldAction =
    triggerActions.find(action => action.name === triggerActionType) || {};
  // Format the trigger action text
  const triggerActionDescription = getActionDescription(
    triggerFieldAction.name,
    triggerValue,
    isCheckbox,
  );
  if (ruleType === RuleConstants.WORKFLOW)
    return `This is a conditional workflow rule that needs to be configured on the Project Documents page. The conditional workflow rule will trigger a conditional workflow when this field is ${triggerActionDescription}.`;
  // Formats text when there is one response, two responses, and three or more responses
  const triggerRuleDescription = insertCommas(
    getResponseText(responseBehavior, responseBehaviorOptions),
  );

  if (!triggerRuleDescription && isConfiguringTrigger) {
    return 'The trigger response behavior has not yet been configured for this trigger rule.';
  }
  if (isConfiguringShare) {
    const responseDescription = isEmail
      ? 'the following fields and their corresponding values are emailed.'
      : `the ${description} badge will display on offers that contain this document`;
    return `When this field is ${triggerActionDescription} ${responseDescription}.`;
  }
  return `When this field is ${triggerActionDescription} the following fields are ${triggerRuleDescription}.`;
};

/**********************RULE CREATORS**************************/

export const newTriggerRule = (newId, index) => ({
  id: newId,
  name: `New Trigger Rule - ${index + 1}`,
  fieldGroups: [
    { id: `__new-${uuid()}`, name: 'New Field Group', fieldIds: [] },
  ],
  groupRuleIds: [],
  fieldGroupId: null,
  ruleType: 'trigger',
  responseBehavior: [],
  triggerAction: { triggerActionType: null, value: null },
  triggerFieldId: null,
  conditionalRuleIds: [],
});

export const newConditionalRule = (newId, index) => ({
  id: newId,
  name: `New Required Rule - ${index + 1}`,
  fieldGroups: [
    { id: `__new-${uuid()}`, name: 'New Field Group - 1', fieldIds: [] },
    { id: `__new-${uuid()}`, name: 'New Field Group - 2', fieldIds: [] },
  ],
  fieldGroupId: null,
  triggerRuleId: null,
  ruleType: 'group',
});

export const newWorkflowRule = (newId, index) => ({
  id: newId,
  name: `New Conditional Workflow Rule - ${index + 1}`,
  fieldGroups: [
    { id: `__new-${uuid()}`, name: 'New Field Group', fieldIds: [] },
  ],
  ruleType: 'workflow',
  triggerAction: { triggerActionType: null, value: null },
  triggerFieldId: null,
});

export const newFieldGroup = (newId, index) => ({
  id: newId,
  name: `New Field Group - ${index + 1}`,
  fieldIds: [],
});
