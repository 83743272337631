import React, { Component } from 'react';

import Box from 'common/oldJavascripts/components/Base/Box';
import Button from 'common/oldJavascripts/components/Base/Button';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import LayoutMain from 'common/oldJavascripts/components/Base/Layout/LayoutMain';

import SupportingDocumentForm from 'common/oldJavascripts/components/Shared/SupportingDocumentForm/SupportingDocumentForm';
import history from 'common/constants/config/history';

import Pendocontroller from 'common/components/PendoController';

import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import withApi from 'common/hoc/withApi';

const styles = theme => ({
  nextButtonContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 120px',
    height: '100px',
    padding: '35px',
  },
  nextButton: {
    gridColumn: -1,
  },
  container: {
    minHeight: '60vh',
  },
});

class SupportingDocuments extends Component {
  static mutators = {
    offer: {
      info: function(_, related) {
        var params = related['/router/params'];
        return {
          id: '/v2/offers/' + params.offerId,
        };
      },
    },
  };

  state = {
    nextEnabled: false,
    documents: [],
  };

  updateDocuments = documents => {
    this.setState({
      documents,
    });
  };

  _onAccept = event => {
    event.preventDefault();
    const offerMutator = this.props.offer;
    const { routerParams = {} } = this.props;
    const { offerId } = routerParams || {};
    const { documents } = this.state;
    let offer_status = 'onboarding_documents';
    let next_transition_to = `/onboarding/offers/${offerId}/documents`;
    documents.forEach(doc => {
      if (doc.i9_document && doc.start_plus_status === 'not_signed') {
        offer_status = 'onboarding_i9';
        next_transition_to = `/onboarding/offers/${offerId}/i9`;
      }
    });
    offerMutator.updateAndSave({ status: offer_status }, 'status', {}, () => {
      history.push({
        pathname: next_transition_to,
        params: routerParams,
      });
    });
  };

  enableNext = nextEnabled => {
    this.setState({ nextEnabled });
  };

  render() {
    const {
      params,
      classes = {},
      offer: { data: offerData = {} },
    } = this.props;
    const updatedParams = {
      ...params,
      type: 'crew',
    };
    const { nextEnabled, documents: parentDocuments } = this.state;
    return (
      <LayoutMain>
        <LayoutContent>
          <Box>
            <div className={classes.container}>
              <SupportingDocumentForm
                enableNext={this.enableNext}
                params={updatedParams}
                updateParentState={this.updateDocuments}
                parentDocuments={parentDocuments}
              />
            </div>
            <div className={classes.nextButtonContainer}>
              <Button
                disabled={!nextEnabled}
                onClick={this._onAccept}
                className={classes.nextButton}
              >
                Next
              </Button>
            </div>
          </Box>
        </LayoutContent>
        <Pendocontroller projectId={offerData?.project_id} />
      </LayoutMain>
    );
  }
}

export default compose(withStyles(styles), withApi)(SupportingDocuments);
