import React from 'react';

// HoC
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

// Utilities

// Components
import Dialog from 'common/components/Dialog';

// Actions
import { popNotification } from 'common/store/actions/dialogNotifications';

// Providers
import { ThemeProvider, createTheme } from '@mui/material/styles';

const DialogController = props => {
  const { queue = [], popNotification } = props;

  const getActions = variant => {
    const actionStore = {
      default: [{ text: 'Close', fn: popNotification, color: 'secondary' }],
    };
    return actionStore[variant] || actionStore.default;
  };
  const handleClose = () => {
    popNotification();
  };
  const open = queue.length > 0;
  const [dialogProps = {}] = queue;
  const { variant, customTheme = null } = dialogProps;
  if (!open) return null;
  return (
    <ThemeProvider theme={customTheme || createTheme()}>
      <Dialog
        open={open}
        handleClose={handleClose}
        actions={getActions(variant)}
        {...dialogProps}
      />
    </ThemeProvider>
  );
};

const mapDispatchToProps = {
  popNotification,
};

const mapStateToProps = ({ dialogNotifications = {} }) => ({
  queue: dialogNotifications.queue,
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(DialogController);
