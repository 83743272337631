import systemFieldTypes from 'common/utilities/constants/systemFieldTypes';

// Filter the system fields based on the field type and text type of the field
// and add a default 'None' option
// Returns an array of system fields filtered based on the field type
export const filterSystemFields = (fieldType, textType, systemFields) => {
  const parsedFieldType =
    textType && textType !== 'string' ? textType.toUpperCase() : fieldType;
  return systemFields
    .map(option => {
      const filteredItems = option.items.filter(item => {
        const { [parsedFieldType]: testArr = [] } = systemFieldTypes;
        return testArr.includes(item.type);
      });
      if (filteredItems.length === 0) {
        return null;
      }
      return {
        ...option,
        items: filteredItems,
      };
    })
    .filter(option => option !== null)
    .concat({
      label: 'None',
      key: 'none',
      items: [{ label: 'None', name: '' }],
    });
};

// Returns an array of system fields based on the user input search value
export const searchSystemFields = (searchValue, systemFields) => {
  return systemFields
    .filter(option => {
      // Filter the system fields for values that match the title
      const updatedItems = option.items.filter(item =>
        item.label.toLowerCase().includes(searchValue.toLowerCase()),
      );
      if (
        updatedItems.length === 0 &&
        !option.label.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        return false;
      }
      return true;
    })
    .map(option => {
      // Filter the top level label and corresponding items for values that match the the label or item label
      const { items = [] } = option;
      const updatedItems = items.filter(item =>
        item.label.toLowerCase().includes(searchValue.toLowerCase()),
      );
      if (
        option.label.includes(searchValue.toLowerCase()) ||
        updatedItems.length === 0
      ) {
        return option;
      }
      return {
        ...option,
        items: updatedItems,
      };
    });
};
