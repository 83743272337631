import React from 'react';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import useSeasons from 'studio/hooks/useSeasons';
import formatCurrency from 'studio/components/OfferPage/helpers/formatCurrency';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import isNonUnionWorkScheduleA from 'common/utilities/isNonUnionWorkScheduleA';
import SpecifiedCountryOnly from 'common/components/SpecifiedCountryOnly';

const styles = theme => ({
  root: {
    display: 'grid',
    height: '100%',
    gridTemplateRows: '60px 60px 30px minmax(166px, max-content) 64px',
  },
  headerContainer: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
  },
  subHeader: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lineItemContainer: {
    display: 'grid',
    gridAutoRows: 'auto',
    paddingBottom: 12,
  },
  lineItem: {
    display: 'grid',
    gridTemplateColumns: '40% 60%',
    paddingLeft: '35px',
    paddingRight: '35px',
  },
  label: {
    fontSize: '1.1rem',
  },
  value: {
    fontSize: '1.1rem',
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: 12,
  },
  subText: {
    textAlign: 'center',
  },
  btn: {
    backgroundColor: '#eaeded !important',
    color: '#888888 !important',
  },
});

// formatAmount2Decimal is used to format the amount to 2 decimal places
const formatAmount2Decimal = amount => {
  if (!amount) return '';
  return parseFloat(amount).toFixed(2);
};

const getCrewMemberNames = (crewMembers = {}) => {
  const crewMemberIds = Object.keys(crewMembers).filter(id => crewMembers[id]);
  if (crewMemberIds.length >= 5) return `${crewMemberIds.length} crew members`;
  const crewMemberNames = crewMemberIds
    .map(id => {
      const { profile = {} } = crewMembers[id] || {};
      const { firstName = '', lastName = '' } = profile || {};
      return `${firstName} ${lastName}`;
    })
    .join(', ');
  return crewMemberNames;
};

// TODO: this component needs refactoring
const OfferConfirmationModal = props => {
  const {
    formData = {},
    onSubmit,
    submitInProgress = false,
    classes = {},
    onClose,
    crew = {},
    projectId,
    scaleRates,
    isUnionHourlyDaily,
    isUnionWeeklySchedule,
    isUnionWeeklyOnCall,
  } = props;
  const {
    offerDate = {},
    termsOfEmployment = {},
    allowances = {},
    termsOfHire,
    dealNotes = [],
    termsSettingsType = {},
  } = formData || {};
  const { startDate } = offerDate || {};
  const {
    occupation: { label: occupation },
    union: { label: union, isNonUnion },
    workSchedule: { label: workSchedule, value: workScheduleCode },
    employmentClassification,
    jobDescription,
    season,
    hiringStatus,
    fringeContract,
  } = termsOfHire || {};
  const [dealNote = {}] = dealNotes || [];
  const { notes: dealNoteText = '' } = dealNote || {};
  const {
    idleLocationSeventhDayRatePerHour,
    idleLocationSixthDayRatePerHour,
    locationGuaranteedHours,
    locationRatePerHour,
    locationRatePerWeek,
    overtimeRatePerHour,
    studioGuaranteedHours,
    studioRatePerHour,
    studioRatePerWeek,
    guaranteedHoursFrequency,
    ratePerHour,
    isPrcanRateTable,
    dailyRate,
    weeklyRate,
    weeklyRateDistant,
    rate,
    rateDistant,
    rateOvertime,
    guaranteedHours,
    guaranteedHoursDistant,
    guaranteedHours6thDay,
    guaranteedHours7thDay,
    guaranteedHours6thDayDistant,
    guaranteedHours7thDayDistant,
    payIdleDaysDistant,
    payGoldAt,
    payGoldAtDistant,
    payAtScale,
    payAtScaleDistant,
    idleAtScaleDistant,
    payIdleDaysDistantAt10th,
    payIdleDaysDistantAt12th,
    payIdleDaysDistantAtScale,
    goldAtScale,
    goldAtScaleDistant,
    numberOfDaysPerWorkWeek,
    sixthDayPayMultiplication,
    seventhDayPayMultiplication,
    negotiatedContract,
    fringesIncluded,
    reason,
  } = termsOfEmployment || {};

  const isSAGDaily = termsSettingsType?.unionSagDaily;
  const isSAGWeekly = termsSettingsType?.unionSagWeekly;
  const isSAGContract = termsSettingsType?.unionSagContract;

  const isSAGUnion = isSAGDaily || isSAGWeekly || isSAGContract;

  const {
    boxRentalAllowance: {
      amount: boxRentalAllowance = null,
      per: boxRentalAllowancePer,
    } = {},
    carAllowance: { amount: carAllowance = null, per: carAllowancePer } = {},
    computerRentalAllowance: {
      amount: computerRentalAllowance = null,
      per: computerRentalAllowancePer,
    } = {},
    housingAllowance: {
      amount: housingAllowance = null,
      per: housingAllowancePer,
    } = {},
    mobilePhoneAllowance: {
      amount: mobilePhoneAllowance = null,
      per: mobilePhoneAllowancePer,
    } = {},
    perDiemAllowance: {
      amount: perDiemAllowance = null,
      per: perDiemAllowancePer,
    } = {},
  } = allowances || {};

  const { data: seasons = [] } = useSeasons(projectId);
  const flags = useFeatureFlags();
  const isNonUnionScheduleAFlagActive = flags.includes('nonUnionSchedules');
  const isNonUnionScheduleCFlagActive = flags.includes('nonUnionScheduleC');
  const isNonUnionScheduleBFlagActive = flags.includes('nonUnionScheduleB');
  const isNonUnionScheduleDFlagActive = flags.includes('nonUnionScheduleD');
  const isUnionScheduleAFlagActive = flags.includes('UnionScheduleA');
  const isUnionWeeklyOnCallFlagActive =
    flags.includes('UnionWeeklyOnCall') && isUnionWeeklyOnCall;
  const isCanadianNonUnionFringesOn = flags.includes('CanadianNonUnionFringes');

  const { label: seasonLabel = '' } =
    seasons.find(({ id: seasonId }) => seasonId === season) || {};
  const hiringStatusDescription = {
    full_time: 'Full Time',
    variable: 'Variable',
  };
  const crewHiringStatus = isPrcanRateTable
    ? null
    : hiringStatusDescription[hiringStatus];

  const isUnionScheduleA =
    isUnionHourlyDaily &&
    !isNonUnion &&
    isUnionScheduleAFlagActive &&
    !isPrcanRateTable;

  const nonUnionScheduleALabels =
    isNonUnionScheduleAFlagActive &&
    isNonUnion &&
    isNonUnionWorkScheduleA(workScheduleCode)
      ? [
          {
            label: 'Apply Hours per Day to 6th Day',
            value: guaranteedHours6thDay ? 'Yes' : 'No',
            countryCode: 'US',
          },
          {
            label: 'Apply Hours per Day to 7th Day',
            value: guaranteedHours7thDay ? 'Yes' : 'No',
            countryCode: 'US',
          },
        ]
      : [];

  const isNonUnionScheduleC =
    isNonUnionScheduleCFlagActive &&
    isNonUnion &&
    ['C', 'E', 'CN'].includes(workScheduleCode);
  const isNonUnionScheduleB =
    isNonUnionScheduleBFlagActive && isNonUnion && workScheduleCode === 'B';
  const isNonUnionScheduleD =
    isNonUnionScheduleDFlagActive && isNonUnion && workScheduleCode === 'D';

  const nonUnionScheduleCLabels = isNonUnionScheduleC
    ? [
        {
          label: 'Pay 6th Day at',
          value:
            sixthDayPayMultiplication > 0
              ? `${sixthDayPayMultiplication}x`
              : 'No additional pay',
          countryCode: 'US',
        },
        {
          label: 'Pay 7th Day at',
          value:
            seventhDayPayMultiplication > 0
              ? `${seventhDayPayMultiplication}x`
              : 'No additional pay',
          countryCode: 'US',
        },
      ]
    : [];

  const isPrCanWeekly =
    isPrcanRateTable && guaranteedHoursFrequency === 'weekly';
  const nonUnionHourlyRateLabels =
    isNonUnionScheduleB || isPrCanWeekly
      ? {
          label: 'Hours per Week',
          value: guaranteedHours,
        }
      : { label: 'Hours Per Day', value: guaranteedHours };

  const isFringePercentagePresent =
    parseFloat(fringeContract?.totalFringePercentage) > 0;
  const isPrCanNonUnionFringe =
    isNonUnion &&
    isPrcanRateTable &&
    isCanadianNonUnionFringesOn &&
    isFringePercentagePresent
      ? {
          label: 'Vacation/Fringe',
          value: fringesIncluded ? 'Included in Rate' : 'In Addition to Rate',
        }
      : {};

  let negotiatedContractTermsLabel = null;

  if (negotiatedContract) {
    negotiatedContractTermsLabel = negotiatedContract?.isNegotiated
      ? 'Yes'
      : 'No';
  }

  // let lineItemsToDisplay = [];
  let termsOfEmploymentItemsDefault = [
    { label: 'Studio - Rate Per Hour', value: studioRatePerHour, dollar: true },
    { label: 'Studio - Rate Per Week', value: studioRatePerWeek, dollar: true },
    { label: 'Studio - Guaranteed Hours', value: studioGuaranteedHours },
    {
      label: 'Rate Per Hour',
      value: ratePerHour ? formatCurrency(ratePerHour) : null,
      dollar: true,
    },
    {
      label: 'Overtime Rate Per Hour',
      value: overtimeRatePerHour,
      dollar: true,
    },
    {
      label: 'Overtime Rate Per Hour',
      value: rateOvertime,
      dollar: true,
    },
    !isSAGUnion ? nonUnionHourlyRateLabels : {}, // not applicable for SAG
    {
      label: 'Rate Per Hour',
      value:
        (isNonUnionWorkScheduleA(workScheduleCode) || isNonUnionScheduleB) &&
        !isSAGUnion
          ? rate
          : null,
      dollar: true,
    },
    {
      label: isPrcanRateTable ? 'Rate Per Day' : 'Daily Rate',
      value: isNonUnionScheduleD && !isPrcanRateTable ? rate : dailyRate,
      dollar: true,
    },
    {
      label: isPrcanRateTable ? 'Rate Per Week' : 'Weekly Rate',
      value: isNonUnionScheduleC && !isPrcanRateTable ? rate : weeklyRate,
      dollar: true,
    },
    isPrCanNonUnionFringe,
    ...nonUnionScheduleALabels,
    ...nonUnionScheduleCLabels,
    isNonUnionScheduleC || isNonUnionScheduleD
      ? {}
      : {
          label: 'Number Of Days Per Work Week',
          value: numberOfDaysPerWorkWeek,
        },
    {
      label: 'Distant - Rate Per Hour',
      value: locationRatePerHour,
      dollar: true,
    },
    {
      label: 'Distant - Rate Per Week',
      value: locationRatePerWeek,
      dollar: true,
    },
    { label: 'Distant - Guaranteed Hours', value: locationGuaranteedHours },
    {
      label: 'Distant - Idle Pay on Sixth Day',
      value: idleLocationSixthDayRatePerHour,
      dollar: true,
      countryCode: 'US',
    },
    {
      label: 'Distant - Idle Pay on Seventh Day',
      value: idleLocationSeventhDayRatePerHour,
      dollar: true,
      countryCode: 'US',
    },
  ];
  const sixthSeventhRatetable =
    guaranteedHours6thDay ||
    guaranteedHours7thDay ||
    guaranteedHours6thDayDistant ||
    guaranteedHours7thDayDistant
      ? [
          {
            label: 'Studio - Apply Hours to 6th Day',
            value: guaranteedHours6thDay ? 'Yes' : 'No',
          },
          {
            label: 'Studio - Apply Hours to 7th Day',
            value: guaranteedHours7thDay ? 'Yes' : 'No',
          },
          {
            label: 'Distant - Apply Hours to 6th Day',
            value: guaranteedHours6thDayDistant ? 'Yes' : 'No',
          },
          {
            label: 'Distant - Apply Hours to 7th Day',
            value: guaranteedHours7thDayDistant ? 'Yes' : 'No',
          },
        ]
      : [];
  let termsOfEmploymentScheduleA = [
    {
      label: 'Studio - Rate Per Hour',
      value: rate,
      dollar: true,
      scaleValue: payAtScale ? '(Scale)' : '(Negotiated)',
    },
    {
      label: 'Distant - Rate Per Hour',
      value: rateDistant,
      dollar: true,
      scaleValue: payAtScaleDistant ? '(Scale)' : '(Negotiated)',
    },
    {
      label: 'Studio - Hours Per Day',
      value: guaranteedHours,
    },
    {
      label: 'Distant - Hours Per Day',
      value: guaranteedHoursDistant,
    },
    ...sixthSeventhRatetable,
    {
      label: 'Distant - Pay Idle Days at',
      value: payIdleDaysDistant,
      dollar: true,
      scaleValue: idleAtScaleDistant ? '(Scale)' : '(Negotiated)',
    },
    {
      label: 'Studio - Pay Gold at Base Rate of',
      value: payGoldAt,
      dollar: true,
      scaleValue: goldAtScale ? '(Scale)' : '(Negotiated)',
    },
    {
      label: 'Distant - Pay Gold at Base Rate of',
      value: payGoldAtDistant,
      dollar: true,
      scaleValue: goldAtScaleDistant ? '(Scale)' : '(Negotiated)',
    },
  ];

  const agentFee = {
    label: 'Agent Fee',
    value: reason === 'AGENT10' ? '10%' : 'N/A',
    dollar: false,
  };

  let SAGTerms = [
    {
      label: 'Rate Per Day',
      value: isSAGDaily ? rate : '',
      dollar: true,
    },
    {
      label: 'Total Rate Per Day',
      value:
        isSAGDaily && reason === 'AGENT10'
          ? formatAmount2Decimal(rate * 1.1)
          : isSAGDaily
          ? rate
          : '',
      dollar: true,
    },
    {
      label: 'Rate Per Week',
      value: isSAGWeekly ? rate : '',
      dollar: true,
    },
    {
      label: 'Total Rate Per Week',
      value:
        isSAGWeekly && reason === 'AGENT10'
          ? formatAmount2Decimal(rate * 1.1)
          : isSAGWeekly
          ? rate
          : '',
      dollar: true,
    },
    {
      label: 'Rate Per Contract',
      value: isSAGContract ? rate : '',
      dollar: true,
    },
    {
      label: 'Total Rate Per Contract',
      value:
        isSAGContract && reason === 'AGENT10'
          ? formatAmount2Decimal(rate * 1.1)
          : isSAGContract
          ? rate
          : '',
      dollar: true,
    },
  ];

  const termsOfEmploymentSAG = SAGTerms.filter(term => term.value !== '');
  termsOfEmploymentSAG.splice(1, 0, agentFee); // insert agent fee after rate per day,week,contract

  let termsOfEmploymentWeeklySchedule = [
    {
      label: 'Studio - Weekly Rate',
      value: weeklyRate,
      dollar: true,
      scaleValue: payAtScale ? '(Scale)' : '(Negotiated)',
    },
    {
      label: 'Distant - Weekly Rate',
      value: weeklyRateDistant,
      dollar: true,
      scaleValue: payAtScaleDistant ? '(Scale)' : '(Negotiated)',
    },
    {
      label: 'Studio - Rate Per Hour',
      value: rate || ratePerHour,
      dollar: true,
      scaleValue: payAtScale ? '(Scale)' : '(Negotiated)',
    },
    {
      label: 'Distant - Rate Per Hour',
      value: rateDistant,
      dollar: true,
      scaleValue: payAtScaleDistant ? '(Scale)' : '(Negotiated)',
    },
    {
      label: 'Studio - Hours Per Week',
      value: guaranteedHours,
      dollar: false,
      scaleValue: payAtScale ? '(Scale)' : '(Negotiated)',
    },
    {
      label: 'Distant - Hours Per Week',
      value: guaranteedHoursDistant,
      dollar: false,
      scaleValue: payAtScaleDistant ? '(Scale)' : '(Negotiated)',
    },
    {
      label: 'Distant - Pay Idle Days at',
      value: payIdleDaysDistant,
      dollar: true,
      scaleValue: idleAtScaleDistant ? '(Scale)' : '(Negotiated)',
    },
    {
      label: 'Studio - Pay Gold at Base Rate of',
      value: payGoldAt,
      dollar: true,
      scaleValue: goldAtScale ? '(Scale)' : '(Negotiated)',
    },
    {
      label: 'Distant - Pay Gold at Base Rate of',
      value: payGoldAtDistant,
      dollar: true,
      scaleValue: goldAtScaleDistant ? '(Scale)' : '(Negotiated)',
    },
  ];

  const idleDaysScaleOrNegotiatedValue = payIdleDaysDistantAtScale
    ? 'Scale Rate'
    : 'Negotiated Rate';

  let termsOfEmploymentWeeklyOnCallSchedule = [
    {
      label: 'Studio Rate',
      value: rate,
      dollar: true,
      scaleValue: payAtScale ? '(Scale)' : '(Negotiated)',
    },
    {
      label: 'Distant Rate',
      value: rateDistant,
      dollar: true,
      scaleValue: payAtScaleDistant ? '(Scale)' : '(Negotiated)',
    },
    {
      label: 'Studio - Hours Per Week',
      value: guaranteedHours,
      dollar: false,
      scaleValue: payAtScale ? '(Scale)' : '(Negotiated)',
    },
    {
      label: 'Distant - Hours Per Week',
      value: guaranteedHoursDistant,
      dollar: false,
      scaleValue: payAtScaleDistant ? '(Scale)' : '(Negotiated)',
    },
    {
      label: `Idle Days`,
      value: payIdleDaysDistantAt12th || payIdleDaysDistantAt10th,
      dollar: false,
      scaleValue: payIdleDaysDistantAt12th
        ? `1/12th of ${idleDaysScaleOrNegotiatedValue}`
        : `1/10th of ${idleDaysScaleOrNegotiatedValue}`,
    },
  ];

  let termsOfEmploymentItems;
  if (isUnionScheduleA) {
    termsOfEmploymentItems = termsOfEmploymentScheduleA;
  } else if (isUnionWeeklySchedule) {
    termsOfEmploymentItems = termsOfEmploymentWeeklySchedule;
  } else if (isUnionWeeklyOnCallFlagActive) {
    termsOfEmploymentItems = termsOfEmploymentWeeklyOnCallSchedule;
  } else {
    termsOfEmploymentItems = isSAGUnion
      ? termsOfEmploymentItemsDefault.concat(termsOfEmploymentSAG)
      : termsOfEmploymentItemsDefault;
  }

  const emplClassificationMap = {
    loanout: 'Loan-out',
    w2: 'Individual',
  };
  const emplClassification =
    emplClassificationMap[employmentClassification.toLowerCase()] || '';

  const lineItems = [
    { label: 'Start Date', value: startDate },
    { label: 'Season', value: seasonLabel },
    { label: 'Union', value: union },
    { label: 'Occupation', value: occupation },
    { label: 'Work Schedule', value: workSchedule },
    { label: 'Job Description', value: jobDescription },
    {
      label: 'Employment Classification',
      value: emplClassification === 'Individual' ? 'W2' : emplClassification,
      countryCode: 'US',
    },
    {
      label: 'Employment Classification',
      value: emplClassification,
      countryCode: 'CA',
    },
    { label: 'Hiring Status', value: crewHiringStatus },
    { label: 'Deal Notes', value: dealNoteText },
    {
      label: 'Use Specialty Contract Terms',
      value: negotiatedContractTermsLabel,
    },
    ...termsOfEmploymentItems,
    {
      label: 'Box Rental Allowance',
      value: boxRentalAllowance,
      per: boxRentalAllowancePer,
      dollar: true,
    },
    {
      label: 'Car Allowance',
      value: carAllowance,
      per: carAllowancePer,
      dollar: true,
    },
    {
      label: 'Computer Rental Allowance',
      value: computerRentalAllowance,
      per: computerRentalAllowancePer,
      dollar: true,
    },
    {
      label: 'Housing Allowance',
      value: housingAllowance,
      per: housingAllowancePer,
      dollar: true,
    },
    {
      label: 'Mobile Phone Allowance',
      value: mobilePhoneAllowance,
      per: mobilePhoneAllowancePer,
      dollar: true,
    },
    {
      label: 'Per Diem Allowance',
      value: perDiemAllowance,
      per: perDiemAllowancePer,
      dollar: true,
    },

    { label: 'Crew Members', value: getCrewMemberNames(crew) },
  ];

  const lineItemsToDisplay = lineItems.filter(({ value }) => !!value);

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.header}>
          Confirm Offer
        </Typography>
      </div>
      <Typography variant="h5" className={classes.subHeader}>
        Offer Terms
      </Typography>
      <Typography variant="subtitle1" className={classes.subText}>
        Are you sure you want to create this offer?
      </Typography>
      <div className={classes.lineItemContainer}>
        {lineItemsToDisplay
          .filter(({ value }) => !!value)
          .map(
            ({ label, value, per, dollar, countryCode, scaleValue = null }) => (
              <SpecifiedCountryOnly
                countryCode={countryCode}
                key={`${label}-${value}`}
              >
                <div className={classes.lineItem}>
                  <Typography
                    variant="body1"
                    className={classes.label}
                    data-test-id={`OfferConfirmationModal-label-${label}`}
                  >
                    {label}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.value}
                    data-test-id={`OfferConfirmationModal-value-${label}${value}`}
                  >
                    {dollar ? '$' : ''}
                    {dollar && isSAGUnion
                      ? formatAmount2Decimal(value)
                      : dollar
                      ? formatCurrency(value)
                      : value}
                    {scaleValue ? ` ${scaleValue}` : ''}
                    {per && ` / ${per}`}
                  </Typography>
                </div>
              </SpecifiedCountryOnly>
            ),
          )}
      </div>
      <div className={classes.submitContainer}>
        <Button
          variant="contained"
          onClick={onClose}
          disabled={submitInProgress}
          data-test-id="OfferConfirmationModal-cancelButton"
          className={classes.btn}
        >
          No, Take me back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={e => onSubmit(scaleRates, isUnionWeeklySchedule)}
          disabled={submitInProgress}
          data-test-id="OfferConfirmationModal-button"
        >
          {submitInProgress ? 'Creating Offer...' : 'Yes, Create Offer'}
        </Button>
      </div>
    </div>
  );
};

export default compose(
  withRouteHelpers,
  withStyles(styles),
)(OfferConfirmationModal);
