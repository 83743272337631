import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query projectTemplate($id: Int!) {
    projectTemplate(id: $id) {
      id
      autoAssignmentRules {
        id
        ... on DepartmentAutoAssignmentRule {
          departments {
            ... on DepartmentAutoAssignmentRuleSelection {
              allExcept
              selection {
                id
                name
              }
            }
          }
          required
        }
        ... on EmploymentTypeAutoAssignmentRule {
          employmentType {
            id
            label
          }
          required
        }
        ... on UnionAutoAssignmentRule {
          unions {
            ... on UnionAutoAssignmentRuleSelection {
              allExcept
              selection {
                code
                description
                isNonUnion
              }
            }
          }
          required
        }
        ... on LocationAutoAssignmentRule {
          hireStates {
            ... on LocationAutoAssignmentRuleSelection {
              allExcept
              selection {
                id
                code
                name
              }
            }
          }
          workStates {
            ... on LocationAutoAssignmentRuleSelection {
              allExcept
              selection {
                id
                code
                name
              }
            }
          }
          workCity {
            id
            code
            name
          }
          required
        }
        ... on AllowancesAutoAssignmentRule {
          allowances {
            ... on AllowancesAutoAssignmentRuleSelection {
              allExcept
              selection
            }
          }
          required
        }
        ... on ComboAutoAssignmentRule {
          employmentType {
            id
            label
          }
          departments {
            ... on DepartmentAutoAssignmentRuleSelection {
              allExcept
              selection {
                id
                name
              }
            }
          }
          unions {
            ... on UnionAutoAssignmentRuleSelection {
              allExcept
              selection {
                code
                description
                isNonUnion
              }
            }
          }
          hireStates {
            ... on LocationAutoAssignmentRuleSelection {
              allExcept
              selection {
                id
                code
                name
              }
            }
          }
          workStates {
            ... on LocationAutoAssignmentRuleSelection {
              allExcept
              selection {
                id
                code
                name
              }
            }
          }
          workCity {
            id
            code
            name
          }
          allowances {
            ... on AllowancesAutoAssignmentRuleSelection {
              allExcept
              selection
            }
          }
          required
        }
        ... on AllAutoAssignmentRule {
          required
        }
      }
    }
  }
`;

const useAutoAssignmentRules = ({ projectTemplateId }, options = {}) => {
  const { error, loading, data } = useQuery(QUERY, {
    skip: !projectTemplateId,
    // NOTE ProjectTemplate.id is listed in the schema as an ID! type, but the projectTemplate
    // query takes a projectId of type Int! Obviously, this is wrong.
    variables: { id: parseInt(projectTemplateId) },
    ...options,
  });
  return {
    error,
    loading,
    data: data?.projectTemplate.autoAssignmentRules,
  };
};

export default useAutoAssignmentRules;
