import React from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import * as FieldTypes from 'common/utilities/constants/fieldTypes';
import { Input } from '@mui/material';

const styles = {
  drawerText: {
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    width: 200,
  },
  input: {
    width: 200,
  },
  icon: {
    width: 20,
    height: 20,
  },
  tooltip: {
    fontSize: '0.9rem !important',
  },
};

const numberFormatZeroOptions = [
  { value: 'dash', label: 'Display as dash' },
  { value: 'number', label: 'Display as number' },
];

const dateOptions = [
  {
    value: 'mm/dd/yyyy',
    label: 'MM/DD/YYYY',
  },
  {
    value: 'mm/dd/yy',
    label: 'MM/DD/YY',
  },
  {
    value: 'dd/mm/yyyy',
    label: 'DD/MM/YYYY',
  },
  {
    value: 'dd/mm/yy',
    label: 'DD/MM/YY',
  },
];

const FieldFormat = props => {
  const {
    classes,
    fieldValues,
    updateField,
    toPositiveInteger,
    formatOptions = dateOptions,
  } = props;
  const {
    fieldType,
    textType,
    precision,
    systemField = {},
    max,
    format,
    zeroFormat = '',
  } = fieldValues || {};
  const dateFormatMenuItems = formatOptions.map(option => (
    <MenuItem
      value={option.value}
      key={option.value}
      data-test-id={`FieldDrawer-dateMenuItem-${option.value}`}
    >
      {option.label}
    </MenuItem>
  ));
  const numberFormatZeroMenuItems = numberFormatZeroOptions.map(option => (
    <MenuItem
      value={option.value}
      key={option.value}
      data-test-id={`FieldDrawer-zeroMenuItem-${option.value}`}
    >
      {option.label}
    </MenuItem>
  ));

  const isSystemField = systemField.label && systemField.label !== 'None';

  // Valid values for precision field are empty or any positive integer
  const updatePrecision = ({ target: { value } }) => {
    updateField(toPositiveInteger(value), 'precision');
  };
  // Valid values for length field are empty or any integer > 0
  const updateLength = ({ target: { value } }) => {
    updateField(toPositiveInteger(value) || null, 'max');
  };
  const showFieldLength =
    fieldType === FieldTypes.TXT && (!textType || textType === 'number');
  const numberFormatWhenZeroIsDisabled = !(
    isSystemField && textType === 'number'
  );
  return (
    <React.Fragment>
      <div>
        <Typography className={classes.drawerText} variant="subtitle1">
          Date Format:
        </Typography>
        <Select
          variant="standard"
          value={format || dateOptions[0].value}
          onChange={e => updateField(e.target.value, 'format')}
          className={classes.select}
          data-test-id="FieldDrawer-dateFormatSelect"
          disabled={textType !== 'date' && textType !== 'date_signed'}
          input={<Input data-test-id="FieldDrawer-dateFormatSelectInput" />}
        >
          {dateFormatMenuItems}
        </Select>
      </div>
      <div>
        <Typography className={classes.drawerText} variant="subtitle1">
          Number Format (Decmical Places):
        </Typography>
        <TextField
          variant="standard"
          value={precision == null ? '' : precision}
          onChange={updatePrecision}
          data-test-id="FieldDrawer-precisionTextField"
          disabled={!(isSystemField && textType === 'number')}
          inputProps={{
            'data-test-id': 'FieldDrawer-precisionTextFieldInput',
          }}
          className={classes.input}
        />
      </div>
      <div>
        <Typography className={classes.drawerText} variant="subtitle1">
          Number Format for Pre-populated Zero Values{' '}
          <Tooltip
            disableInteractive
            classes={{ tooltip: classes.tooltip }}
            title="Choose a format for pre-populated number fields that will be printed to the form when the value is zero (regardless of decimal precisions for the field)."
          >
            <InfoIcon
              data-test-id="FieldFormat-zeroNumberFormatIcon"
              className={classes.icon}
            />
          </Tooltip>
          :
        </Typography>
        <Select
          variant="standard"
          value={
            zeroFormat == null || numberFormatWhenZeroIsDisabled
              ? ''
              : zeroFormat
          }
          onChange={e => updateField(e.target.value, 'zeroFormat')}
          className={classes.select}
          data-test-id="FieldDrawer-zeroFormatSelect"
          disabled={numberFormatWhenZeroIsDisabled}
          input={<Input data-test-id="FieldDrawer-zeroFormatSelectInput" />}
        >
          {numberFormatZeroMenuItems}
        </Select>
      </div>
      <div>
        <Typography className={classes.drawerText} variant="subtitle1">
          Maximum Number Length:
        </Typography>
        <TextField
          variant="standard"
          value={max || ''}
          onChange={updateLength}
          data-test-id="FieldDrawer-fieldLengthTextField"
          inputProps={{
            'data-test-id': 'FieldDrawer-fieldLengthTextFieldInput',
          }}
          disabled={!showFieldLength}
          className={classes.input}
        />
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(FieldFormat);
