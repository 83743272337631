import React, { useEffect, useState } from 'react';
import { Table } from '@mui/material';
import { withStyles } from '@mui/styles';
import Rate from './Rate';
import AgentFeeSelect from './AgentFeeSelect';

const styles = {
  tableSpacing: {
    marginBottom: 16,
  },
};

const ContractTermsOfEmployment = ({
  classes,
  onChange: upstreamOnChange,
  formData = {},
  formErrors = {},
  isReadOnly = false,
}) => {
  const {
    guaranteedDays = '',
    guaranteedHours = '',
    per: guaranteedHoursFrequencyPer = '',
    rate: termsRate,
    reason,
    workSchedule = {},
  } = formData;
  const errorMessage = formErrors?.rate;
  const guaranteedHoursFrequency =
    guaranteedHoursFrequencyPer || workSchedule?.per;

  const [rate, setRate] = useState(termsRate);
  const [totalRate, setTotalRate] = useState('');
  const [type, setType] = useState('N/A');

  const handleChange = rate => {
    setRate(rate);
  };

  const handleAgentFee = type => {
    setType(type);
  };

  useEffect(() => {
    // if rate is empty, return
    if (termsRate === '') return;
    setRate(termsRate);
    if (reason === 'AGENT10') {
      setType('10%');
    }
  }, [termsRate, reason]);

  useEffect(() => {
    // if type changes, change total rate
    if (type === 'N/A') {
      setTotalRate(rate);
    } else {
      setTotalRate(rate * 1.1);
    }
  }, [type, rate]);

  useEffect(() => {
    // if rate is empty, return
    if (rate === '') return;

    const termsOfEmployment = {
      guaranteedDays: guaranteedDays || workSchedule?.days,
      guaranteedHours: guaranteedHours || workSchedule?.guaranteedHours,
      guaranteedHoursFrequency:
        guaranteedHoursFrequency === 'D' ? 'daily' : 'weekly',
      reason: type !== 'N/A' ? 'AGENT10' : null,
      override: type !== 'N/A' ? true : false,
      rate: rate,
      isTermsOfEmploymentV2: true,
    };

    upstreamOnChange({ ...termsOfEmployment });
  }, [rate, type]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Table className={classes.tableSpacing}>
      <Rate
        label={'Rate Per Contract'}
        onChange={handleChange}
        value={rate}
        errorMessage={errorMessage || ''}
        disabled={isReadOnly}
      />
      <AgentFeeSelect
        title={'Agent Fee'}
        value={type}
        setValue={val => handleAgentFee(val)}
        disabled={isReadOnly}
      />
      <Rate
        label={'Total Rate Per Contract'}
        onChange={handleChange}
        value={totalRate}
        disabled={true}
      />
    </Table>
  );
};

export default withStyles(styles)(ContractTermsOfEmployment);
