import truncate from 'lodash.truncate';
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { Check as CheckMarkIcon, ChevronLeft } from '@mui/icons-material';
import DocumentSigner from 'mobile/components/DocumentSigner/DocumentSigner';
import MobileSaveSignatureDialog from './MobileSaveSignatureDialog';
import decorateDocuments from 'mobile/components/DocumentSigner/DocumentSigner/decorateDocuments';

const styles = makeStyles(theme => ({
  root: {
    marginTop: '10px',
    backgroundColor: '#f7fcfc',
    minHeight: '100vh',
  },
  crew: {
    color: '#34bfb6',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: 1,
    textTransform: 'uppercase',
    borderRadius: 4,
    backgroundColor: '#9A9FAF',
    padding: '6px',
    textAlign: 'center',
  },
  name: {
    marginLeft: '20px',
    color: '#646464',
    fontSize: '35px',
    fontWeight: 400,
  },
  grayedCheckMark: {
    color: '#cfcfcf',
    width: 30,
    height: 30,
  },
  greenCheckMark: {
    color: '#2ecc71',
    width: 30,
    height: 30,
  },
  formTitle: {
    color: '#646464',
    fontSize: '16px',
    fontWeight: 400,
    marginLeft: '15px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  itemRow: {
    borderBottom: '1px solid #cfcfcf',
    padding: '20px 10px 10px',
  },
  topSection: {
    marginLeft: '20px',
    marginTop: '20px',
    width: '60px',
  },
  expandedIcon: {
    transform: 'rotate(90deg)',
    color: '#ffffff',
    backgroundColor: '#0000FF',
    height: '24px',
    width: '24px',
    borderRadius: 50,
  },
  expandIcon: {
    color: '#0000FF',
    backgroundColor: '#f5f5f5',
    height: '24px',
    width: '24px',
    borderRadius: 50,
    transform: 'rotate(-90deg)',
  },
  icon: {
    position: 'relative',
    bottom: '10px',
    right: '10px',
  },
  bottomSection: {
    width: '100vw',
    boxShadow: '0 -4px 10px #cccccc',
    backgroundColor: '#f7fcfc',
    padding: '10px',
    position: 'fixed',
    left: 0,
    bottom: 0,
  },
  approveBtn: {
    borderRadius: 2,
    backgroundColor: '#0000FF',
  },
  disabledApproveBtn: {
    backgroundColor: '#0000FF',
    borderRadius: 2,
    opacity: 0.4,
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  rejectAll: {
    color: '#0000FF',
    fontSize: 16,
    fontWeight: 400,
  },
  completedStatus: {
    textAlign: 'right',
    color: '#646464',
    fontSize: 12,
    fontWeight: 400,
    marginBottom: '10px',
    marginRight: '5px',
  },
  navigatingForms: {
    width: '100%',
    opacity: 0.85,
  },
  itemOpen: {
    borderLeft: '4px solid #0000FF',
    borderBottom: '1px solid #cfcfcf',
    height: '60px !important',
    minHeight: 'unset',
  },
  paperItemOpen: {
    backgroundColor: '#ffffff',
    marginBottom: '150px',
  },
  paperItemClosed: {
    backgroundColor: '#ffffff',
    marginTop: '30px',
    marginBottom: '150px',
  },
  saveProgress: {
    boxShadow: '0 -2 4 rgba(0, 0, 0, 0.25)',
    border: '1px solid #0000FF',
    backgroundColor: '#f7fcfc',
    color: '#0000FF',
    marginRight: '15px',
    fontSize: '14px',
    fontWeight: 400,
    width: '126px',
    '&:focus': {
      background: 'transparent !important',
    },
  },
  nextButton: {
    marginRight: '10px',
    background: '#0000FF',
    '&:hover': {
      background: '#1A55FD',
    },
    '&:active': {
      background: '#0000B3',
    },
  },
  panelContainer: {
    height: '30px',
  },
  panelDetails: {
    padding: '10px 4px',
  },
  btnContainer: {
    flexBasis: 'auto',
  },
  error: {
    color: 'red',
    margin: '20px',
    textAlign: 'center',
  },
}));

const documentSignerMobileStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: '#f2f5f7',
  },
  busyOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(195, 195, 195, 0.8)',
    zIndex: 100000,
    display: 'grid',
    placeContent: 'center',
  },
  busyContainer: {
    textAlign: 'center',
  },
}));

const documentViewerMobileStyles = makeStyles(theme => ({
  contentContainer: {
    zIndex: 2,
    overflow: 'auto',
    background: '#eef3f6',
    marginBottom: '100px',
  },
  documentImageContainer: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '98vw',
    overflow: 'auto',
    height: '100%',
    width: '100%',
  },
  formComplete: {
    textAlign: 'center',
    color: '#333',
    padding: '15px',
  },
}));

// Returns true if signature is saved.
// Returns false if signature is not saved.
// Returns null if user must be asked whether or not to save signature.
const getIsSignatureSavedFromLocalStorage = token => {
  // If no token then API call has not yet resolved so don't ask user yet.
  if (!token) return false;

  // Get the isSignature saved token/value pair. If not present, ask user.
  const savedTokenAndValue = window.sessionStorage.getItem('isSignatureSaved');
  if (!savedTokenAndValue) return null;

  // Check the token is for the right session. If it is, return saved value.
  const [savedToken, savedValue] = savedTokenAndValue.split('-');
  if (savedToken === token) return savedValue === 'true';

  // Token is for wrong session, return null.
  return null;
};

const saveIsSignatureSavedToLocalStorage = (token, value) => {
  const tokenAndValue = `${token}-${!!value}`;
  window.sessionStorage.setItem('isSignatureSaved', tokenAndValue);
};

const MobileDocumentSigner = props => {
  const {
    actions,
    actor,
    documents: upstreamDocuments = [],
    getDocumentImageUrls,
    me,
    templatesAcknowledgeNotes,
    templatesAcknowledgeTitle,
    throwFatalError,
  } = props;
  const {
    save: { action: save },
    submit: { action: submit },
    cancel: { action: reject },
  } = actions;
  const { data: { signature_session: token } = {} } = me;

  const [currentDocumentId, setCurrentDocumentId] = useState(null);
  const [editedDocuments, setEditedDocuments] = useState(null);
  const documentSignerRef = useRef(null);
  const [isSignatureSaved, setIsSignatureSaved] = useState(false);

  const classes = styles();
  const documentSignerMobileClasses = documentSignerMobileStyles();
  const documentViewerMobileClasses = documentViewerMobileStyles();

  const getIsDocumentComplete = doc => {
    const { _derived: { isComplete = false } = {} } = doc || {};
    return isComplete;
  };

  const documents = editedDocuments || decorateDocuments(upstreamDocuments);
  const currentDocument =
    currentDocumentId && documents.find(({ id }) => id === currentDocumentId);
  const submissionErrors = [];
  const formsCompletedCount = documents.filter(getIsDocumentComplete).length;
  const isOfferComplete = documents.every(getIsDocumentComplete);
  const documentCount = documents.length;
  const expansionPanelDocs = currentDocumentId ? [currentDocument] : documents;

  const goToNext = () => {
    const isCurrentDocumentComplete = getIsDocumentComplete(currentDocument);
    if (!isCurrentDocumentComplete) {
      documentSignerRef.current.goToFirstIncompleteRequiredField();
      return;
    }

    // Now find the first incomplete document, if any
    const firstIncompleteDocument = documents.find(
      doc => !getIsDocumentComplete(doc),
    );

    // If an incomplete document is found open it, otherwise just close all
    setCurrentDocumentId(firstIncompleteDocument && firstIncompleteDocument.id);
  };

  useEffect(() => {
    setIsSignatureSaved(getIsSignatureSavedFromLocalStorage(token));
  }, [token]);

  const saveIsSignatureSaved = value => {
    setIsSignatureSaved(value);
    saveIsSignatureSavedToLocalStorage(token, value);
  };

  return (
    <React.Fragment>
      <div className={!currentDocumentId ? classes.root : ''}>
        {!isOfferComplete && currentDocumentId === null && (
          <div data-test-id="MobileDocumentSigner-getStarted">
            <Typography
              variant="h4"
              gutterBottom
              style={{ paddingLeft: 30, paddingTop: 30 }}
            >
              Here are your Offer Forms
            </Typography>
            <Typography variant="body1" style={{ paddingLeft: 30 }}>
              Let's get started
            </Typography>
          </div>
        )}
        {isOfferComplete && (
          <Typography
            variant="h4"
            gutterBottom
            style={{ paddingLeft: 30, paddingTop: 30 }}
            data-test-id="MobileDocumentSigner-greatJob"
          >
            Great job! Submit for Approval
          </Typography>
        )}
        <Paper
          className={
            currentDocumentId ? classes.paperItemOpen : classes.paperItemClosed
          }
        >
          {/*isLoading && <PreLoader />*/}
          {expansionPanelDocs.length > 0 &&
            expansionPanelDocs.map(doc => (
              <Accordion
                key={doc.id}
                expanded={currentDocumentId === doc.id}
                onChange={(event, expanded) => {
                  setCurrentDocumentId(expanded ? doc.id : false);
                }}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={currentDocumentId ? classes.itemOpen : ''}
                >
                  <Grid container className={classes.panelContainer}>
                    <Grid item xs={1}>
                      <IconButton
                        className={classes.icon}
                        data-test-id={`MobileDocumentSigner-expander-${doc.id}`}
                      >
                        <ChevronLeft
                          className={
                            currentDocumentId
                              ? classes.expandedIcon
                              : classes.expandIcon
                          }
                        />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="h6" className={classes.formTitle}>
                        {truncate(doc.name || doc.templateName, {
                          length: 46,
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <CheckMarkIcon
                        fontSize="large"
                        className={
                          getIsDocumentComplete(doc)
                            ? classes.greenCheckMark
                            : classes.grayedCheckMark
                        }
                      />
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.panelDetails}>
                  {currentDocumentId && (
                    <DocumentSigner
                      documents={documents}
                      selectedDocumentId={currentDocumentId}
                      actor={actor}
                      getDocumentImageUrls={getDocumentImageUrls}
                      throwFatalError={throwFatalError}
                      isMobile
                      mobileDocsSaveHandler={actions.save.action}
                      mobileDocumentSignerStyles={documentSignerMobileClasses}
                      mobileDocumentViewerStyles={documentViewerMobileClasses}
                      me={me}
                      isSaveSignatureEnabled={false}
                      ref={el => {
                        documentSignerRef.current = el;
                      }}
                      templatesAcknowledgeNotes={templatesAcknowledgeNotes}
                      templatesAcknowledgeTitle={templatesAcknowledgeTitle}
                      onChangeDocuments={setEditedDocuments}
                      isDocumentCompleteSnackbarEnabled={false}
                      isMobileSignatureSaved={`${isSignatureSaved}`}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
        </Paper>
        {submissionErrors.length > 0 && (
          <Typography className={classes.error}>
            Sorry an error occurred, please try again
          </Typography>
        )}
        <div className={classes.bottomSection}>
          {!currentDocumentId && (
            <Typography className={classes.completedStatus}>
              {formsCompletedCount} of {documentCount} Forms Completed
            </Typography>
          )}
          <Grid container justify="space-between">
            <Grid item xs={3}>
              <Typography
                className={classes.rejectAll}
                onClick={reject}
                data-test-id="MobileDocumentSigner-reject"
              >
                Reject
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.btnContainer}>
              {!currentDocumentId && (
                <Button
                  disabled={!isOfferComplete}
                  onClick={() => submit(documents)}
                  data-test-id="MobileDocumentSigner-approve"
                  className={
                    isOfferComplete
                      ? classes.approveBtn
                      : classes.disabledApproveBtn
                  }
                >
                  Submit for Approval
                </Button>
              )}
              {currentDocumentId && (
                <div>
                  <Button
                    className={classes.saveProgress}
                    onClick={() => save([currentDocument])}
                  >
                    Save Progress
                  </Button>
                  <Button
                    className={classes.nextButton}
                    onClick={goToNext}
                    data-test-id="MobileDocumentSigner-next"
                  >
                    Next Field
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      <MobileSaveSignatureDialog
        actor={actor}
        onSubmit={saveIsSignatureSaved}
        open={isSignatureSaved === null}
      />
    </React.Fragment>
  );
};

export default MobileDocumentSigner;
