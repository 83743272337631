import classNames from 'classnames';
import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import ProjectTemplateAutoAssignmentRuleTypeSelect from './selects/ProjectTemplateAutoAssignmentRuleTypeSelect';
import ProjectTemplateAutoAssignmentRuleDepartmentSelect from './selects/ProjectTemplateAutoAssignmentRuleDepartmentSelect';
import ProjectTemplateAutoAssignmentRuleUnionSelect from './selects/ProjectTemplateAutoAssignmentRuleUnionSelect';
import ProjectTemplateAutoAssignmentRuleStateSelect from './selects/ProjectTemplateAutoAssignmentRuleStateSelect';
import ProjectTemplateAutoAssignmentRuleCitySelect from './selects/ProjectTemplateAutoAssignmentRuleCitySelect';
import ProjectTemplateAutoAssignmentRuleAllowanceSelect from './selects/ProjectTemplateAutoAssignmentRuleAllowanceSelect';
import ProjectTemplateAutoAssignmentRuleEmploymentTypeSelect from './selects/ProjectTemplateAutoAssignmentRuleEmploymentTypeSelect';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';
import ProjectTemplateAutoAssignmentRuleRequiredCheckbox from './checkboxes/ProjectTemplateAutoAssignmentRuleRequiredCheckbox';
import { FormControlLabel, Switch } from '@mui/material';
import { ALLOWANCE_TYPES } from './constants/allowanceTypes';

const styles = theme => ({
  selectRow: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: `
      "ruleType employmentType ......... .........  ........ ......... ruleRequired"
      "ruleType allowance allowanceAllExcept .........  ........ ......... ruleRequired"
      "ruleType department    departmentAllExcept ........    ........  .........ruleRequired"
      "ruleType union          unionAllExcept  ........ ......... ......... ruleRequired"
      "ruleType hireState     hireStateAllExcept workState  workStateAllExcept workCity  ruleRequired"
    `,
    gridTemplateColumns: '14% 14% 14% 14% 14% 14% 14%',
    gridTemplateRows: 'auto auto auto',
    gridColumnGap: '8px',
    columnGap: '8px',
  },
  multiSelectRow: {
    gridRowGap: '7px',
    rowGap: '7px',
  },
  ruleType: {
    gridArea: 'ruleType',
  },
  department: {
    gridArea: 'department',
  },
  union: {
    gridArea: 'union',
  },
  allowance: {
    gridArea: 'allowance',
  },
  hireState: {
    gridArea: 'hireState',
  },
  workState: {
    gridArea: 'workState',
  },
  workCity: {
    gridArea: 'workCity',
  },
  departmentAllExcept: {
    gridArea: 'departmentAllExcept',
  },
  unionAllExcept: {
    gridArea: 'unionAllExcept',
  },
  hireStateAllExcept: {
    gridArea: 'hireStateAllExcept',
  },
  workStateAllExcept: {
    gridArea: 'workStateAllExcept',
  },
  allowanceAllExcept: {
    gridArea: 'allowanceAllExcept',
  },
  employmentType: {
    gridArea: 'employmentType',
  },
  ruleRequired: {
    gridArea: 'ruleRequired',
    paddingBottom: 8,
  },
});

const ProjectTemplateAutoAssignmentRuleEditor = props => {
  const { classes, rule, onChange, projectCountryId, isEmptyRule } = props;
  const {
    id,
    __typename,
    departments = {},
    unions = {},
    hireStates = {},
    workStates = {},
    allowances = {},
    workCity,
    employmentType,
    required,
  } = rule;
  const [isUnionAllExcept, setIsUnionAllExcept] = useState(unions?.allExcept);
  const [isDepartmentAllExcept, setIsDepartmentAllExcept] = useState(
    departments?.allExcept,
  );
  const [isHireStateAllExcept, setIsHireStateAllExcept] = useState(
    hireStates?.allExcept,
  );
  const [isWorkStateAllExcept, setIsWorkStateAllExcept] = useState(
    workStates?.allExcept,
  );
  const [isAllowanceAllExcept, setIsAllowanceAllExcept] = useState(
    allowances?.allExcept,
  );
  const departmentIds = departments?.selection?.map(({ id }) => id) ?? [];
  const unionCodes = unions?.selection?.map(({ code }) => code) ?? [];
  const hireStateIds = hireStates?.selection?.map(({ id }) => id) ?? [];
  const workStateIds = workStates?.selection?.map(({ id }) => id) ?? [];
  const allowanceId =
    allowances?.selection?.map(allowance => {
      const selectedAllowance = ALLOWANCE_TYPES?.find(
        allowanceType => allowanceType?.code === allowance?.code,
      );
      return selectedAllowance?.id;
    }) ?? [];
  const workCityId = workCity?.id;
  const employmentTypeId = employmentType?.id;
  const ruleType = __typename?.slice(0, -'AutoAssignmentRule'.length);
  const isCombo = ruleType === 'Combo';
  const showDepartment = isCombo || ruleType === 'Department';
  const showUnion = isCombo || ruleType === 'Union';
  const showLocation = isCombo || ruleType === 'Location';
  const showEmploymentType = isCombo || ruleType === 'EmploymentType';
  const showAllowance = isCombo || ruleType === 'Allowances';

  const selectRowClass = classNames(classes.selectRow, {
    [classes.multiSelectRow]: isCombo,
  });
  return (
    <div className={selectRowClass}>
      <ProjectTemplateAutoAssignmentRuleTypeSelect
        ruleType={ruleType}
        onChange={ruleType =>
          onChange({ id, __typename: `${ruleType}AutoAssignmentRule` })
        }
        data-test-id={`ProjectTemplateAutoAssignmentRuleEditor-ruleType`}
        className={classes.ruleType}
      />

      {showDepartment && (
        <>
          <ProjectTemplateAutoAssignmentRuleDepartmentSelect
            departmentIds={departmentIds}
            onChange={departments =>
              onChange({
                ...rule,
                departments: { ...departments, selection: departments },
              })
            }
            data-test-id={`ProjectTemplateAutoAssignmentRuleEditor-department`}
            className={classes.department}
          />

          <FormControlLabel
            className={classes.departmentAllExcept}
            control={
              <Switch
                color="primary"
                checked={isDepartmentAllExcept}
                disabled={departmentIds?.length < 1}
                onChange={() => {
                  setIsDepartmentAllExcept(!isDepartmentAllExcept);
                  onChange({
                    ...rule,
                    departments: {
                      ...departments,
                      allExcept: !isDepartmentAllExcept,
                    },
                  });
                }}
              />
            }
            label="All Except"
          />
        </>
      )}
      {showUnion && (
        <>
          <ProjectTemplateAutoAssignmentRuleUnionSelect
            unionCodes={unionCodes}
            onChange={unions =>
              onChange({
                ...rule,
                unions: { ...unions, selection: unions },
              })
            }
            data-test-id={`ProjectTemplateAutoAssignmentRuleEditor-union`}
            className={classes.union}
          />

          <FormControlLabel
            className={classes.unionAllExcept}
            control={
              <Switch
                color="primary"
                checked={isUnionAllExcept}
                disabled={unionCodes?.length < 1}
                onChange={() => {
                  setIsUnionAllExcept(!isUnionAllExcept);
                  onChange({
                    ...rule,
                    unions: { ...unions, allExcept: !isUnionAllExcept },
                  });
                }}
              />
            }
            label="All Except"
          />
        </>
      )}

      {showLocation && (
        <React.Fragment>
          <ProjectTemplateAutoAssignmentRuleStateSelect
            label={
              <>
                Hire <UsaOnly>States</UsaOnly>
                <CanadaOnly>Provinces</CanadaOnly>
              </>
            }
            stateIds={hireStateIds}
            onChange={hireStates =>
              onChange({
                ...rule,
                hireStates: { ...hireStates, selection: hireStates },
              })
            }
            data-test-id={`ProjectTemplateAutoAssignmentRuleEditor-hireState`}
            className={classes.hireState}
            projectCountryId={projectCountryId}
          />

          <FormControlLabel
            className={classes.hireStateAllExcept}
            control={
              <Switch
                color="primary"
                checked={isHireStateAllExcept}
                disabled={hireStateIds?.length < 1}
                onChange={() => {
                  setIsHireStateAllExcept(!isHireStateAllExcept);
                  onChange({
                    ...rule,
                    hireStates: {
                      ...hireStates,
                      allExcept: !isHireStateAllExcept,
                    },
                  });
                }}
              />
            }
            label="All Except"
          />

          <ProjectTemplateAutoAssignmentRuleStateSelect
            label={
              <>
                Work <UsaOnly>States</UsaOnly>
                <CanadaOnly>Provinces</CanadaOnly>
              </>
            }
            stateIds={workStateIds}
            onChange={workStates =>
              onChange({
                ...rule,
                workStates: { ...workStates, selection: workStates },
              })
            }
            data-test-id={`ProjectTemplateAutoAssignmentRuleEditor-workState`}
            className={classes.workState}
            projectCountryId={projectCountryId}
          />

          <FormControlLabel
            className={classes.workStateAllExcept}
            control={
              <Switch
                color="primary"
                checked={isWorkStateAllExcept}
                disabled={workStateIds?.length < 1}
                onChange={() => {
                  setIsWorkStateAllExcept(!isWorkStateAllExcept);
                  onChange({
                    ...rule,
                    workStates: {
                      ...workStates,
                      allExcept: !isWorkStateAllExcept,
                    },
                  });
                }}
              />
            }
            label="All Except"
          />

          <UsaOnly>
            {!!workStateIds &&
              workStateIds.length === 1 &&
              !isWorkStateAllExcept && (
                <ProjectTemplateAutoAssignmentRuleCitySelect
                  label="Work City"
                  cityId={workCityId}
                  stateId={workStateIds[0]}
                  onChange={workCity => onChange({ ...rule, workCity })}
                  data-test-id={`ProjectTemplateAutoAssignmentRuleEditor-workCity`}
                  className={classes.workCity}
                  projectCountryId={projectCountryId}
                />
              )}
          </UsaOnly>
        </React.Fragment>
      )}

      {showEmploymentType && (
        <ProjectTemplateAutoAssignmentRuleEmploymentTypeSelect
          employmentTypeId={employmentTypeId || ''}
          ruleType={ruleType}
          onChange={employmentType => onChange({ ...rule, employmentType })}
          data-test-id={`ProjectTemplateAutoAssignmentRuleEditor-employmentType`}
          className={classes.employmentType}
        />
      )}

      {showAllowance && (
        <>
          <ProjectTemplateAutoAssignmentRuleAllowanceSelect
            allowanceId={allowanceId}
            onChange={allowances => {
              onChange({
                ...rule,
                allowances: { ...allowances, selection: allowances },
              });
            }}
            label="Allowances"
            data-test-id={`ProjectTemplateAutoAssignmentRuleEditor-allowance`}
            className={classes.allowance}
          />
          <FormControlLabel
            className={classes.allowanceAllExcept}
            control={
              <Switch
                color="primary"
                checked={isAllowanceAllExcept}
                disabled={allowanceId?.length < 1}
                onChange={() => {
                  setIsAllowanceAllExcept(!isAllowanceAllExcept);
                  onChange({
                    ...rule,
                    allowances: {
                      ...allowances,
                      allExcept: !isAllowanceAllExcept,
                    },
                  });
                }}
              />
            }
            label="All Except"
          />
        </>
      )}

      {!isEmptyRule && (
        <ProjectTemplateAutoAssignmentRuleRequiredCheckbox
          className={classes.ruleRequired}
          isDisabled={false}
          value={required}
          onChange={required => onChange({ ...rule, required })}
          data-test-id={`ProjectTemplateAutoAssignmentRuleEditor-ruleRequired`}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(ProjectTemplateAutoAssignmentRuleEditor);
