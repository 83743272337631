import gql from 'graphql-tag';

export default gql`
  mutation saveProjectTemplateWorkflows(
    $projectTemplateId: ID!
    $workflows: [WorkflowInput!]!
  ) {
    saveProjectTemplateWorkflows(
      projectTemplateId: $projectTemplateId
      workflows: $workflows
    )
  }
`;
