import React from 'react';
export default ({ style, ...propsCatch }) => (
  <svg
    width="18"
    height="13"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    {...propsCatch}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#000"
        d="M0 11.5h18v1.25H0zM3.528 7.25l1.555 1.556-.777.777L2.75 8.028 1.194 9.583l-.777-.777L1.972 7.25.417 5.694l.777-.777L2.75 6.472l1.556-1.555.777.777L3.528 7.25z"
      />
      <path
        d="M6.67 7.525c2.714-.736 5.771-.696 8.143-2.207 6.39-4.071-6.287-8.123-4.124 1.363 1.285 5.638 6.934-1.307 5.465 3.93"
        stroke="#000"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);
