import React from 'react';
import { withStyles } from '@mui/styles';
import ConfirmationIcon from '@mui/icons-material/Warning';
import Typography from '@mui/material/Typography';

import Dialog from 'common/components/Dialog';

const styles = {
  paper: {
    width: 600,
    maxHeight: 600,
    padding: 16,
  },
  btnClose: {
    background: '#f4f6f8',
    color: 'black',
    '&:hover': {
      background: '#ccc',
    },
    paddingLeft: 32,
    paddingRight: 32,
  },
  btnPrimary: {
    background: '#45a7dc',
    paddingLeft: 32,
    paddingRight: 32,
    color: 'white',
    '&:hover': {
      background: '#3297ce',
    },
    '&:disabled': {
      background: '#a8d4ee',
      color: 'white',
    },
  },
  dialogContent: {
    textAlign: 'center',
  },
  iconConfirm: {
    width: '100%',
    height: 128,
    color: '#f4b524',
  },
  titleH5: {
    fontWeight: 'bold',
    paddingBottom: 20,
  },
};

const Confirm = props => {
  const {
    classes,
    question,
    title = 'Confirm your action',
    open = false,
    onSubmit = () => {},
    onClose = () => {},
  } = props;

  const body = (
    <div className={classes.dialogContent}>
      <ConfirmationIcon className={classes.iconConfirm} />
      <Typography variant="h5" className={classes.titleH5}>
        {question}
      </Typography>
    </div>
  );

  const actions = [
    { text: 'No', fn: onClose, className: classes.btnClose },
    { text: 'Yes', fn: onSubmit, className: classes.btnPrimary },
  ];

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      title={title}
      message={body}
      actions={actions}
      handleClose={onClose}
    />
  );
};

export default withStyles(styles)(Confirm);
