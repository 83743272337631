import React, { useState } from 'react';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import {
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { ListItemIcon, ListItemText } from '@mui/material';
import {
  FileCopy as ContentCopyIcon,
  Archive as ArchiveIcon,
  Send as SendIcon,
  Edit as EditIcon,
  NotInterestedOutlined as RescindIcon,
  QueryBuilder as QueryBuilderIcon,
  SignalCellularNoSim as RescindDocsIcon,
} from '@mui/icons-material';
import Feature from 'common/components/Feature';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import AlertDialog from '../RescindAdditionalDocuments/RescindAdditionalDocumentsAlertModal';

// Ajax
import ajax from 'common/utilities/ajax.js';
import history from 'common/constants/config/history';

const styles = () => ({
  cancel: {
    padding: '8px 12px',
    boxSizing: 'border-box',
    margin: '0px 14px',
    boxShadow:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px !important',
  },
  offerHistory: { padding: '0px 0px' },
  apply: {
    padding: '8px 20px',
    marginRight: '10px',
    boxSizing: 'border-box',
    boxShadow:
      ' 0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px !important',
    '&:disabled': {
      boxShadow: ' 0px 0px 1px rgba(82, 82, 82, 0.4)',
    },
  },
});

const RowMenu = props => {
  const {
    offer,
    projectId,
    onShowModal,
    anchorEl,
    closeMenu,
    classes,
    onToggleStatus,
    privileges = [],
    alertClose,
  } = props;
  // State
  const [isCopyOfferModalOpen, setIsCopyOfferModalOpen] = useState(false);
  const [openRescindDocsModal, setOpenRescindDocsModal] = useState(false);

  if (!offer) {
    return null;
  }

  const { id, status, badges } = offer;

  // RE-SEND Offer Invite
  const resentOfferInvite = () => {
    ajax
      .post(`/projects/${projectId}/offers/${id}/resend`, { json: true })
      .then(
        () => {
          const { pushSnackbarNotification } = props;
          pushSnackbarNotification({
            message: 'A new invitation has been sent.',
            variant: SnackbarVariants.SUCCESS,
          });
        },
        () => {
          const { pushSnackbarNotification } = props;
          pushSnackbarNotification({
            message: 'Invitation failed to send.',
            variant: SnackbarVariants.ERROR,
          });
        },
      );
  };

  // Edit Offer
  const editOffer = () => {
    history.push(
      `/projects/${projectId}/offers/${id}/edit?ids=${id}&action=version`,
    );
  };

  // Archive Offer
  const archiveOffer = () => {
    onShowModal('archive', id, status);
  };

  // Rescind Offer
  const rescindOffer = () => {
    onShowModal('rescind', id, null);
  };

  // Open Copy Offer Modal
  const openCopyOfferModal = () => setIsCopyOfferModalOpen(true);

  // Close Copy Offer Modal
  const closeCopyOfferModal = () => setIsCopyOfferModalOpen(false);

  const toggleStatus = () => onToggleStatus(id);

  const initializeCopyOffer = () => {
    history.push(`/projects/${projectId}/offers/new?id=${id}&action=copy`);
  };

  const checkPermission = (privileges, permission) => {
    return privileges.some(privilege => {
      return permission === privilege;
    });
  };
  const canCopyOffer = checkPermission(privileges, 'can_create_offers');

  const canRescindDocs = checkPermission(
    privileges,
    'can_hirer_send_documents',
  );

  const handleAlertClose = val => {
    if (val) {
      alertClose(true);
    }
    setOpenRescindDocsModal(false);
  };

  const initialStatus = new RegExp(/(pending_email_delivery|sent)/);
  const archiveOfferRegex = new RegExp(/(onboarding|submitted|reject)/);
  const rejectStatus = new RegExp(/(reject)/);
  const cancelAdditionalDocsRegex = new RegExp(/(onboarding|submitted|sent)/);
  const offerSent = initialStatus.test(status);
  const canArchiveOffer = archiveOfferRegex.test(status);
  const canUpdateOffer = rejectStatus.test(status);
  const canCancelAdditionalDocs = cancelAdditionalDocsRegex.test(status);
  const isAdditionalDocumentsBadge = badges.some(
    badge => badge === 'additional_documents',
  );
  const offerPrevStatus = offer?.previousStatus;
  const canArchiveOfferOption =
    canArchiveOffer ||
    (offerPrevStatus !== 'approved' && isAdditionalDocumentsBadge);
  return (
    <>
      <Menu
        id={`offer-action-row-menu-${id}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        data-test-id={`offer-action-row-menu-${id}`}
        onClick={closeMenu}
      >
        <Feature name="CopyOffer">
          {canCopyOffer && (
            <MenuItem
              onClick={openCopyOfferModal}
              data-test-id={`offer-action-row-menu-copy-offer-${id}`}
            >
              <ListItemIcon>
                <ContentCopyIcon />
              </ListItemIcon>
              <ListItemText primary="Copy Offer" />
            </MenuItem>
          )}
        </Feature>

        {canArchiveOfferOption && (
          <MenuItem
            onClick={archiveOffer}
            data-test-id={`offer-action-row-menu-archive-offer-${id}`}
          >
            <ListItemIcon>
              <ArchiveIcon />
            </ListItemIcon>
            <ListItemText primary="Archive" />
          </MenuItem>
        )}
        {offerSent && (
          <MenuItem
            onClick={resentOfferInvite}
            data-test-id={`offer-action-row-menu-resend-offer-${id}`}
          >
            <ListItemIcon>
              <SendIcon />
            </ListItemIcon>
            <ListItemText primary="Resend Invitation" />
          </MenuItem>
        )}
        {canUpdateOffer && (
          <MenuItem
            onClick={editOffer}
            data-test-id={`offer-action-row-menu-edit-offer-${id}`}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Edit Offer" />
          </MenuItem>
        )}
        {offerSent && !isAdditionalDocumentsBadge && (
          <MenuItem
            onClick={rescindOffer}
            data-test-id={`offer-action-row-menu-rescind-offer-${id}`}
          >
            <ListItemIcon>
              <RescindIcon />
            </ListItemIcon>
            <ListItemText primary="Rescind Offer" />
          </MenuItem>
        )}
        {canRescindDocs &&
          canCancelAdditionalDocs &&
          isAdditionalDocumentsBadge && (
            <MenuItem
              onClick={() => setOpenRescindDocsModal(true)}
              data-test-id={`offer-action-row-menu-rescind-offer-added-documents-${id}`}
            >
              <ListItemIcon>
                <RescindDocsIcon />
              </ListItemIcon>
              <ListItemText primary="Rescind Added Documents" />
            </MenuItem>
          )}
        <ExpandableTableCell className={classes.offerHistory}>
          <MenuItem
            onClick={toggleStatus}
            data-test-id={`offer-action-row-menu-offer-history-${id}`}
          >
            <ListItemIcon>
              <QueryBuilderIcon />
            </ListItemIcon>
            <ListItemText primary="Offer History" />
          </MenuItem>
        </ExpandableTableCell>
      </Menu>
      <Dialog open={isCopyOfferModalOpen}>
        <DialogTitle data-test-id={`copy-offer-modal-title-${id}`}>
          Copy Offer
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to copy this offer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            className={classes.cancel}
            data-test-id={`copy-offer-no-${id}`}
            onClick={closeCopyOfferModal}
          >
            No
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.apply}
            data-test-id={`copy-offer-yes-${id}`}
            autoFocus
            onClick={initializeCopyOffer}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={openRescindDocsModal}
        classes={classes}
        id={id}
        alertClose={val => handleAlertClose(val)}
      />
    </>
  );
};

export default compose(withSnackbarNotification, withStyles(styles))(RowMenu);
