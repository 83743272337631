import React from 'react';

// Components
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MaskedInput from 'react-text-mask';

// Constants
import { ratePerHourMask } from '../../helpers/textFieldNumberMasks';
import OVERTIME_MULTIPLIER from '../OVERTIME_MULTIPLIER';

// Utilities
import formatCurrency from '../../helpers/formatCurrency';
import enforceMaxDigits from '../../helpers/enforceMaxDigits';

const LocationRatePerHour = ({
  onChange: upstreamOnChange,
  formData = {},
  classes = {},
}) => {
  const { studioRatePerHour, locationRatePerHour } = formData || {};

  const onChange = ({ target: { value } = {} } = {}) => {
    if (value === '' || value === '0') {
      upstreamOnChange({
        locationRatePerHour: null,
      });
      return;
    }
    const update = {
      locationRatePerHour: value,
      studioRatePerWeek: null,
      locationRatePerWeek: null,
    };
    if (studioRatePerHour === '' || studioRatePerHour === '0') {
      update.overtimeRatePerHour = enforceMaxDigits(
        value * OVERTIME_MULTIPLIER,
      );
    }

    upstreamOnChange(update);
    return;
  };

  const onBlur = ({ target: { value } = {} } = {}) => {
    if (value === '' || value === '0') return;

    const formattedValue = formatCurrency(value);

    const overtimeRatePerHour =
      studioRatePerHour === '' || studioRatePerHour === '0'
        ? formatCurrency(formattedValue * OVERTIME_MULTIPLIER)
        : formatCurrency(studioRatePerHour * OVERTIME_MULTIPLIER);

    upstreamOnChange({
      locationRatePerHour: formattedValue,
      overtimeRatePerHour,
      studioRatePerWeek: '',
      locationRatePerWeek: '',
    });
    return;
  };

  return (
    <TableCell align="center" className={classes.root}>
      <MaskedInput
        mask={ratePerHourMask}
        value={locationRatePerHour || ''}
        onChange={onChange}
        onBlur={onBlur}
        render={(ref, props) => (
          <TextField
            inputRef={ref}
            {...props}
            variant="standard"
            className={classes.textField}
            InputProps={{
              className: classes.textFieldInputContainer,
              inputProps: {
                className: classes.textFieldInput,
                'data-test-id': 'LocationRatePerHour-input',
              },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        )}
      />
    </TableCell>
  );
};

export default LocationRatePerHour;
