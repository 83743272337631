import React from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'class-names';

const useStyles = makeStyles({
  root: {
    marginBottom: 20,
  },
  errorHelper: {
    color: 'red',
  },
  errorInput: {
    borderColor: 'red',
  },
});

const LabeledTextField = props => {
  const { label, value, onChange, required, testId, error } = props;
  const classes = useStyles();

  const inputClass = classNames('field-input', { [classes.errorInput]: error });

  return (
    <div className={classes.root} data-test-id={testId}>
      <label className="field-label">
        {label}
        {required && <span className="field-required">*</span>}
      </label>
      <input
        className={inputClass}
        type="text"
        value={value}
        onChange={e => onChange(e.target.value)}
      />
      {error && <div className={classes.errorHelper}>{error}</div>}
    </div>
  );
};

export default LabeledTextField;
