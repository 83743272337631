import gql from 'graphql-tag';

export default gql`
  query departments(
    $projectId: Int!
    $union: String
    $occupation: String
    $workState: String
    $currency: String
  ) {
    departments(
      projectId: $projectId
      unionCode: $union
      occupationCode: $occupation
      stateCode: $workState
      currency: $currency
    ) {
      id
      code
      loanOutEnabled
      masterDepartmentId
      preSelected
      name
      loanOutOccupations {
        code
        description
      }
    }
  }
`;
