import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

const isLargeAmount = amount => Number(amount) >= 1000;

const formatAmount2Decimal = amount => {
  if (!amount) return '';
  return parseFloat(amount).toFixed(2);
};

const TermsOfEmploymentSAG = ({ terms, classes }) => {
  const {
    reason = null,
    rate_per_day_studio = '',
    rate_per_week_studio = '',
    type = '',
  } = terms;

  const rateTable = (rate, type) => {
    const typeString =
      type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
    return (
      <Table data-test-id={`Rate-SAG-table`}>
        <TableHead>
          <TableRow>
            <TableCell
              className={classes.greenBox}
              data-test-id={`Rate-title-${typeString}`}
            >
              Rate Per {typeString}
            </TableCell>
            <TableCell
              className={classes.greenBox}
              data-test-id={`Rate-agent-fee-title`}
            >
              Agent Fee
            </TableCell>
            <TableCell
              className={classes.greenBox}
              data-test-id={`Total-rate-title-${typeString}`}
            >
              Total Rate Per {typeString}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              className={
                isLargeAmount(rate) ? classes.smallerAmount : classes.amount
              }
              data-test-id={`Rate-${typeString}`}
            >
              {'$' + formatAmount2Decimal(rate)}
            </TableCell>
            <TableCell
              className={
                isLargeAmount(rate) ? classes.smallerAmount : classes.amount
              }
              data-test-id={`Rate-agent-fee`}
            >
              {reason === 'AGENT10' ? '10%' : 'N/A'}
            </TableCell>
            <TableCell
              className={
                isLargeAmount(rate) ? classes.smallerAmount : classes.amount
              }
              data-test-id={`Total-rate-${typeString}`}
            >
              {reason === 'AGENT10'
                ? '$' + formatAmount2Decimal(rate * 1.1)
                : '$' + formatAmount2Decimal(rate)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  if (type === 'UNION_SAG_DAILY') {
    return rateTable(rate_per_day_studio, 'DAY');
  } else if (type === 'UNION_SAG_WEEKLY') {
    return rateTable(rate_per_week_studio, 'WEEK');
  } else if (type === 'UNION_SAG_CONTRACT') {
    return rateTable(rate_per_week_studio, 'CONTRACT');
  } else {
    return null;
  }
};

export default TermsOfEmploymentSAG;
