export default {
  getAlerts: function() {
    return this.context.alerts.get();
  },

  alertInfo: function(msj) {
    this.context.alerts.set('info', msj);
  },

  alertError: function(msj) {
    this.context.alerts.set('error', msj);
  },

  alertSuccess: function(msj) {
    this.context.alerts.set('success', msj);
  },

  alertWarning: function(msj) {
    this.context.alerts.set('warning', msj);
  },

  removeAlert: function(index) {
    this.context.alerts.remove(index);
  },
};
