import gql from 'graphql-tag';

export default gql`
  query ecsMasterDepartments {
    ecsMasterDepartments {
      id
      name
    }
  }
`;
