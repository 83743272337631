import arrayPrototypeFlat from 'array.prototype.flat';
import promisePrototypeFinally from 'promise.prototype.finally';
import unfetch from 'unfetch';

arrayPrototypeFlat.shim();
promisePrototypeFinally.shim();

// If running in Cypress, monkeypatch fetch calls to redirect to equivalent XHR
// calls *and* append the graphQL operation name (if present) to the url.
if (window.Cypress) {
  const fetchMock = (path, options) => {
    const body = JSON.parse(options.body);
    const { operationName } = body;
    const pathWithOperationName = operationName
      ? `${path}/${operationName}`
      : path;
    return unfetch(pathWithOperationName, options);
  };

  window.fetch = fetchMock;
}
