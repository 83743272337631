import React from 'react';
import Button from '../Base/Button';
import Field from '../Base/Field';
import BaseForm from '../Base/Form';
import Loader from '../Base/Loader';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this._onSubmit = this._onSubmit.bind(this);
  }

  getAction() {
    const props = this.props;
    const id = props.mutator.id;
    if (props.action) {
      return `${id}/${props.action}`;
    }
    return id;
  }

  render() {
    return (
      <BaseForm
        action={this.getAction()}
        method="post"
        multipart={this.props.multipart}
        onSubmit={this._onSubmit}
      >
        {this.props.children}
      </BaseForm>
    );
  }

  _onSubmit() {
    const props = this.props;
    const mutator = props.mutator;
    if (mutator.status !== 'loading') {
      mutator.save(
        this.props.action,
        {
          multipart: props.multipart || false,
          onProgress: props.onProgress || null,
        },
        props.onSuccess,
        props.onError,
      );
    }
  }
}

Form.Field = class extends React.Component {
  render() {
    var name = this.props.name;
    var mutator = this.props.mutator;
    var data = mutator.data || {};
    var errors = mutator.errors || {};

    return (
      <Field
        errors={errors[name]}
        onChange={this._change}
        value={data[name]}
        disabled={mutator.status === 'loading'}
        {...this.props}
      >
        {this.props.children}
      </Field>
    );
  }

  _change = value => {
    var name = this.props.name;
    var mutator = this.props.mutator;

    if (mutator.status !== 'loading') {
      if (this.props.onChange) {
        this.props.onChange(value);
      } else {
        mutator.update(name)(value);
      }
    }
  };
};

Form.Button = class extends React.Component {
  render() {
    var mutator = this.props.mutator;
    var children = this.props.children;
    var isLoading = mutator.status === 'loading';

    if (isLoading) {
      children = <Loader inline={true} />;
    }

    return (
      <Button disabled={isLoading} {...this.props}>
        {children}
      </Button>
    );
  }
};

export default Form;
