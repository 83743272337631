import React, { useState } from 'react';
import { TableCell, TableRow, Tooltip } from '@mui/material';

import Loader from 'common/components/Loader';
import FILE_ICON from 'common/images/file.svg';
import DOWNLOAD_ICON from 'common/images/download.svg';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';

import useOfferW9Document from '../hooks/useOfferW9Document';

const downloadStartNotification = {
  message:
    'Hold on a moment while we prepare the document for download. ' +
    'Your download should being momentarily.',
  variant: SnackbarVariants.INFO,
};

const downloadEndNotification = {
  message: 'Your download has started.',
  variant: SnackbarVariants.SUCCESS,
};

const documentStatusColors = {
  valid: '#008006',
  expired: '#C10004',
  missing: '#C10004',
  pending: '#FFBF00',
};

const LoanoutDocumentRow = props => {
  const {
    classes,
    corporation = {},
    offerId,
    pushSnackbarNotification,
    clearSnackbarNotification,
  } = props;
  const { status, w9: { id, name } = {} } = corporation;
  const [download, setDownload] = useState(false);

  const skip = !download || !id;
  const { data, loading, error } = useOfferW9Document(skip, offerId, id);

  if (!name) return null;

  if (download) {
    pushSnackbarNotification(downloadStartNotification);
  }

  if (error) {
    clearSnackbarNotification();
    pushSnackbarNotification({
      message: error.message,
      variant: SnackbarVariants.ERROR,
    });
  }

  if (data) {
    setDownload(false);
    clearSnackbarNotification();
    const { downloadUrl } = data;
    if (!downloadUrl)
      throw new Error(
        'No path received when requesting document for download.',
      );

    pushSnackbarNotification(downloadEndNotification);
    window.location.href = downloadUrl;
  }

  return (
    <TableRow
      className={classes.tableRow}
      data-test-id="LoanoutDocumentRow-row"
    >
      <TableCell className={classes.documentName}>
        <img src={FILE_ICON} alt="file icon" className={classes.fileIcon} />
        {name}
      </TableCell>
      <TableCell
        style={{ color: documentStatusColors[status] }}
        className={classes.itemStatus}
        data-test-id="LoanoutDocumentRow-status"
      >
        {status}
      </TableCell>
      <TableCell className={classes.downloadCell}>
        {loading && <Loader size={24} />}
        {!loading && (
          <Tooltip title="Download" placement="top">
            <img
              src={DOWNLOAD_ICON}
              alt="Download icon"
              className={classes.downloadIcon}
              onClick={() => setDownload(true)}
              data-test-id="LoanoutDocumentRow-downloadIcon"
            />
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default withSnackbarNotification(LoanoutDocumentRow);
