import React from 'react';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import ProjectTemplateAutoAssignmentRuleSelect from './ProjectTemplateAutoAssignmentRuleSelect';
import { RULE_TYPES } from '../constants/ruleTypes';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0000FF',
    },
  },
});

const ProjectTemplateAutoAssignmentRuleTypeSelect = props => {
  const { className, 'data-test-id': dataTestId, onChange, ruleType } = props;
  const options = RULE_TYPES.map(({ description, typeName }) => ({
    label: description,
    value: typeName,
  }));
  return (
    <ThemeProvider theme={theme}>
      <ProjectTemplateAutoAssignmentRuleSelect
        label="Rule Type"
        options={options}
        value={ruleType}
        onChange={onChange}
        data-test-id={dataTestId}
        className={className}
      />
    </ThemeProvider>
  );
};

export default ProjectTemplateAutoAssignmentRuleTypeSelect;
