import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import status from 'common/oldJavascripts/utils/react/status';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import Loader from 'common/components/Loader';
import BoxBody from 'common/oldJavascripts/components/Base/Box/BoxBody';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Header from 'common/oldJavascripts/components/Base/Header';
import DocumentContainerWrapper from './DocumentContainerWrapper';
import AttachTemplateWrapper from './AttachTemplateWrapper';

const ProjectTemplates = Relay.createClass({
  statics: {
    queries: {
      project: {
        info(_, related) {
          const params = related['/router/params'];
          return {
            id: `/projects/${params.projectId}`,
          };
        },
      },

      departments: {
        info(_, related) {
          const { projectId } = related['/router/params'];
          return {
            id: `/v2/projects/${projectId}/departments`,
          };
        },
      },

      unions: {
        info(_, related) {
          const params = related['/router/params'];
          return {
            id: `/payroll/projects/${params.projectId}/unions`,
          };
        },
      },
    },

    mutators: {
      editTemplate: {
        info(_, related) {
          const params = related['/router/params'];
          const { id } = params;
          return {
            id: `/workflow/templates/${id}`,
            create: true,
          };
        },
      },
    },
  },

  getInitialState() {
    return {
      workCities: {},
      shouldFetch: null,
    };
  },

  _onAttachedTemplates(templateIds) {
    this.setState({ attachedIds: templateIds });
  },

  _onProcessedAttachments() {
    this.setState({ attachedIds: [] });
  },

  _getStatus() {
    const { unions, users } = this.props;
    return status([unions, users]);
  },

  render: function() {
    const { routerParams: params, project } = this.props;
    const { projectId } = params;
    const { items: departments = [] } = this.props.departments.data;
    const { selectedState, shouldFetch, attachedIds } = this.state;

    if (this._getStatus() === 'loading') {
      return <Loader />;
    }

    if (this.props.unions.status === 'failed') {
      return <FailureAlert queryName="Unions" />;
    }

    return (
      <BoxBody>
        <BoxItem>
          <Header>
            <Header.Nav>
              <AttachTemplateWrapper
                {...params}
                attachSuccessHandler={this._onAttachedTemplates}
              />
            </Header.Nav>
          </Header>
        </BoxItem>
        <BoxItem>
          <DocumentContainerWrapper
            params={{ projectId, selectedState }}
            onChange={this._change}
            departments={departments}
            shouldFetch={shouldFetch}
            attachedTemplates={attachedIds}
            onProcessedAttachments={this._onProcessedAttachments}
            projectCountryId={project.data.country_id}
          />
        </BoxItem>
      </BoxBody>
    );
  },
});

export default ProjectTemplates;
