import React, { useEffect } from 'react';
import Select from 'react-select';
import Typography from '@mui/material/Typography';

import formatOptionLabel from './formatOptionLabel';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

const OPT_ROLLBACK = { label: 'Contractual Roll Back Rate', value: 'rollback' };
const OPT_CURRENT = { label: 'Current Scale Rate', value: 'current' };
const FEATURE_FLAG = 'RollbackRates';

const ScaleRatesSelect = props => {
  const {
    formData = {},
    classes = {},
    selectStyles,
    onChange: upstreamOnChange,
    isTermsSettingsLoading: loading = false,
  } = props;

  const { payAtRollback } = formData;
  const flags = useFeatureFlags();

  const options = [OPT_ROLLBACK, OPT_CURRENT];
  const value = !payAtRollback ? OPT_CURRENT : OPT_ROLLBACK;

  const setPayAtRollback = ({ value }) =>
    upstreamOnChange({
      payAtRollback: value === OPT_ROLLBACK.value,
    });

  const onChange = option => setPayAtRollback(option);

  useEffect(() => {
    if (payAtRollback === null) setPayAtRollback(value);
  });

  if (!flags.includes(FEATURE_FLAG)) return null;

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1">
        What rate are you utilizing?
      </Typography>
      <div data-test-id="ScaleRatesSelect-select">
        <Select
          styles={selectStyles}
          onChange={onChange}
          value={value}
          options={options}
          className={classes.select}
          formatOptionLabel={formatOptionLabel('ScaleRatesSelect')}
          isLoading={loading}
        />
      </div>
    </div>
  );
};

export default ScaleRatesSelect;
