import { useMutation } from '@apollo/react-hooks';
import { ProjectSaveSettings } from 'common/apollo/mutations';

const useSaveSettings = () => {
  const [mutate, { loading, error }] = useMutation(ProjectSaveSettings);
  const saveSettings = data => mutate({ variables: { ...data } });

  return [saveSettings, { loading, error }];
};

export default useSaveSettings;
