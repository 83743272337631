import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DealNoteEditor from './DealNoteEditor';
import SavedDealNotes from './SavedDealNotes';
import { withStyles } from '@mui/styles';
import classNames from 'class-names';
import ErrorIcon from '@mui/icons-material/Error';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

const LOAN_OUT_ERROR_MSG =
  'Be sure to enter any applicable vacation/fringe benefits for the non-union loan-out here and indicate if this is in addition to rate or included in rate.';
const INDIVIDUAL_EXEMPT_ERROR_MSG =
  'Be sure to enter any applicable vacation/fringe benefits for the non-union crew member here and indicate if this is in addition to rate or included in rate.';
const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
  },
  rootWithSavedNotes: {
    gridTemplateRows: 'max-content 10px 100px max-content 55px',
    gridTemplateAreas: `
    "header"
    "......"
    "savedNotes"
    "noteEditor"
    "actionButton"
    `,
  },
  rootWithoutSavedNotes: {
    gridTemplateRows: 'max-content 10px max-content 55px',
    gridTemplateAreas: `
    "header"
    "......"
    "noteEditor"
    "actionButton"
    `,
  },
  header: {
    gridArea: 'header',
  },
  savedNotes: {
    gridArea: 'savedNotes',
  },
  noteEditor: {
    gridArea: 'noteEditor',
  },
  actionButton: {
    gridArea: 'actionButton',
    justifySelf: 'end',
    alignSelf: 'end',
  },
  label: {
    gridColumn: 1,
    gridRow: 1,
  },
  charCountLabel: {
    gridColumn: 2,
    gridRow: 1,
    placeSelf: 'end',
    padding: 5,
  },
  inputContainer: {
    height: '38px',
    gridColumn: '1 / -1',
    gridRow: 2,
    width: '100%',
  },
  input: {
    height: '38px',
  },
  error: {
    fontSize: '1rem',
    display: 'block',
  },
  errorIcon: {
    color: 'red',
    marginTop: 10,
    verticalAlign: 'bottom',
  },
  errorContainer: {
    display: 'inline',
  },
  description: {
    color: '#6D7278',
    fontSize: 12,
  },
});

const maxLength = 1000;

const DealNotes = props => {
  const {
    dealNotesFormData,
    formData: dealNotes = [],
    classes = {},
    isEditingDealNotes,
    updateEditingDealNoteStatus,
    formError,
    onChange: upstreamOnChange,
  } = props;
  const {
    termsOfHire: { employmentClassification, union } = {},
    termsOfEmployment: { isPrcanRateTable } = {},
  } = dealNotesFormData;
  const isNonUnion = union?.isNonUnion;
  const [editableDealNote, setDealNote] = useState('');
  const [editingEnabled, setEditingEnabled] = useState(
    dealNotes.find(({ current }) => current) === undefined,
  );
  if (editableDealNote.length && !isEditingDealNotes)
    updateEditingDealNoteStatus(true);
  if (!editingEnabled && isEditingDealNotes) updateEditingDealNoteStatus(false);

  const onChange = ({ target: { value } = {} }) => {
    if (value.length > maxLength) return;
    setDealNote(value);
  };
  const flags = useFeatureFlags();
  const isCanadianNonUnionFringesOn = flags.includes('CanadianNonUnionFringes');

  const isEmptyDealNote = !editableDealNote && dealNotes.length === 0;
  const isLoanOutWarning =
    !isCanadianNonUnionFringesOn &&
    isNonUnion &&
    employmentClassification === 'loanOut' &&
    isPrcanRateTable &&
    isEmptyDealNote;

  const isW2Error =
    !isCanadianNonUnionFringesOn &&
    isNonUnion &&
    employmentClassification === 'w2' &&
    isPrcanRateTable &&
    isEmptyDealNote;

  const loanOutWarningMessage = isLoanOutWarning ? LOAN_OUT_ERROR_MSG : null;
  const w2ErrorMessage = isW2Error ? INDIVIDUAL_EXEMPT_ERROR_MSG : null;

  // Disable editing if there are saved notes
  useEffect(() => {
    if (dealNotes.length > 0) setEditingEnabled(false);
    else setEditingEnabled(true);
  }, [dealNotes, setEditingEnabled]);

  const saveDealNote = () => {
    // Remove current note from list
    const newDealNotes = dealNotes.filter(({ current }) => !current);

    // If current note is entered, add to start of list
    if (editableDealNote.trim()) {
      const newDealNote = { notes: editableDealNote, current: true };
      newDealNotes.unshift(newDealNote);
    }

    // Push change upstream and reset editor state
    upstreamOnChange(newDealNotes);
    setDealNote('');
    setEditingEnabled(false);
  };

  const editDealNote = () => {
    const { notes = '' } = dealNotes.find(({ current }) => current) || {};
    setDealNote(notes);
    setEditingEnabled(true);
  };

  const rootClass = classNames(classes.root, {
    [classes.rootWithSavedNotes]: dealNotes.length > 0,
    [classes.rootWithoutSavedNotes]: dealNotes.length === 0,
  });

  let dealNotesErrors = w2ErrorMessage || loanOutWarningMessage || formError;

  return (
    <div className={rootClass}>
      <Typography className={classes.header} variant="h5">
        Additional Deal Notes
        <Typography variant="subtitle1" className={classes.description}>
          Deal notes are visible to all users with access to the offer,
          including crew and C&C
        </Typography>
        {dealNotesErrors && (
          <Typography
            color="error"
            variant="caption"
            className={classes.error}
            data-test-id="DealNotes-error"
          >
            <ErrorIcon className={classes.errorIcon} /> {dealNotesErrors}
          </Typography>
        )}
      </Typography>
      <SavedDealNotes
        dealNotes={dealNotes}
        classes={{ root: classes.savedNotes }}
      />
      <DealNoteEditor
        onChange={onChange}
        notes={editableDealNote}
        maxLength={maxLength}
        classes={{ root: classes.noteEditor }}
        disabled={!editingEnabled}
        isFormError={dealNotesErrors}
      />
      {editingEnabled && (
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={saveDealNote}
          className={classes.actionButton}
          data-test-id="DealNotes-saveButton"
        >
          Save Deal Note
        </Button>
      )}
      {!editingEnabled && (
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={editDealNote}
          className={classes.actionButton}
          data-test-id="DealNotes-editButton"
        >
          Edit Deal Note
        </Button>
      )}
    </div>
  );
};

export default withStyles(styles)(DealNotes);
