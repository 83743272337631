import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const QUERY = gql`
  query projectWorkflowCategories($projectId: Int!) {
    project(id: $projectId) {
      id
      workflowCategories {
        id
        name
      }
    }
  }
`;

const useWorkflowCategories = projectId => {
  const { data, error, loading } = useQuery(QUERY, {
    variables: { projectId },
  });

  return { loading, error, data: data?.project?.workflowCategories };
};

export default useWorkflowCategories;
