import React from 'react';
import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { RULE_TYPES } from './constants/ruleTypes';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';
import ProjectTemplateAutoAssignmentRuleRequiredCheckbox from './checkboxes/ProjectTemplateAutoAssignmentRuleRequiredCheckbox';
import { FormControlLabel, Switch } from '@mui/material';
import { ALLOWANCE_TYPES } from './constants/allowanceTypes';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: `
      "ruleType employmentType ......... ......... .........  ........ ruleRequired"
      "ruleType allowance allowanceAllExcept ......... .........  ........ ruleRequired"
      "ruleType department  departmentAllExcept .........  .........  ........ ruleRequired"
      "ruleType union        unionAllExcept ......... .........  ........ ruleRequired"
      "ruleType hireState     hireStateAllExcept workState  workStateAllExcept  workCity ruleRequired"
    `,
    gridTemplateColumns: '14% 14% 14% 14% 14% 14% 14%',
    gridTemplateRows: 'auto auto auto',
    gridColumnGap: '8px',
    columnGap: '8px',
    fontSize: '1.2em',
    background: '#f7fcfc',
    paddingTop: 8,
    paddingLeft: 8,
  },
  ruleType: {
    gridArea: 'ruleType',
    paddingBottom: 8,
  },
  department: {
    gridArea: 'department',
    paddingBottom: 8,
  },
  union: {
    gridArea: 'union',
    paddingBottom: 8,
  },
  allowance: {
    gridArea: 'allowance',
    paddingBottom: 8,
  },
  hireState: {
    gridArea: 'hireState',
    paddingBottom: 8,
  },
  workState: {
    gridArea: 'workState',
    paddingBottom: 8,
  },
  workCity: {
    gridArea: 'workCity',
    paddingBottom: 8,
  },
  departmentAllExcept: {
    gridArea: 'departmentAllExcept',
  },
  unionAllExcept: {
    gridArea: 'unionAllExcept',
  },
  hireStateAllExcept: {
    gridArea: 'hireStateAllExcept',
  },
  workStateAllExcept: {
    gridArea: 'workStateAllExcept',
  },
  allowanceAllExcept: {
    gridArea: 'allowanceAllExcept',
  },
  employmentType: {
    gridArea: 'employmentType',
    paddingBottom: 8,
  },
  ruleRequired: {
    gridArea: 'ruleRequired',
    paddingBottom: 8,
  },
});

const ProjectTemplateAutoAssignmentRuleDisplay = props => {
  const { classes, rule, isEmptyRule } = props;
  const options = RULE_TYPES.map(({ typeName, description }) => ({
    label: description,
    value: typeName,
  }));
  const {
    id,
    __typename,
    departments = {},
    unions = {},
    hireStates = {},
    workStates = {},
    allowances = {},
    workCity,
    employmentType,
    required,
  } = rule;

  const departmentNames = departments?.selection
    ?.map(({ name }) => name)
    .sort() ?? ['--'];
  const unionNames = unions?.selection
    ?.map(({ description }) => description)
    .sort() ?? ['--'];
  const hireStateNames = hireStates?.selection
    ?.map(({ name }) => name)
    .sort() ?? ['--'];
  const workStateNames = workStates?.selection
    ?.map(({ name }) => name)
    .sort() ?? ['--'];
  const allowanceNames = allowances?.selection
    ?.map(allowance => {
      const selectedAllowance = ALLOWANCE_TYPES?.find(
        allowanceType => allowanceType?.code === allowance?.code,
      );
      return selectedAllowance?.name;
    })
    .sort() ?? ['--'];
  const workCityName =
    !workStates?.allExcept && workCity?.name ? workCity?.name : '--';
  const employmentTypeName = employmentType?.label ?? '--';
  const ruleType = __typename?.slice(0, -'AutoAssignmentRule'.length);
  const ruleTypeOption = options.find(({ value }) => value === ruleType) || {};
  const { label: ruleDescription = '' } = ruleTypeOption;
  const isCombo = ruleType === 'Combo';
  const showEmploymentType = isCombo || ruleType === 'EmploymentType';
  const showDepartment = isCombo || ruleType === 'Department';
  const showUnion = isCombo || ruleType === 'Union';
  const showAllowance = isCombo || ruleType === 'Allowances';
  const showLocation = isCombo || ruleType === 'Location';

  return (
    <div className={classes.root}>
      <div
        className={classes.ruleType}
        data-test-id={`ProjectTemplateAutoAssignmentRuleDisplay-ruleType-${id}`}
      >
        <Typography variant="caption">Rule Type</Typography>
        <div>{ruleDescription}</div>
      </div>
      {showEmploymentType && (
        <div
          className={classes.employmentType}
          data-test-id={`ProjectTemplateAutoAssignmentRuleDisplay-employmentType-${id}`}
        >
          <Typography variant="caption">Employment Type</Typography>
          <div>
            <UsaOnly>{employmentTypeName}</UsaOnly>
            <CanadaOnly>
              {employmentTypeName === 'W-2' || employmentTypeName === 'W2'
                ? 'Individual'
                : employmentTypeName}
            </CanadaOnly>
          </div>
        </div>
      )}
      {showDepartment && (
        <>
          <div
            className={classes.department}
            data-test-id={`ProjectTemplateAutoAssignmentRuleDisplay-department-${id}`}
          >
            <Typography variant="caption">Departments</Typography>
            <div>
              {!!departmentNames &&
                departmentNames.map(departmentName => (
                  <div>{departmentName}</div>
                ))}
            </div>
          </div>
          <FormControlLabel
            className={classes.departmentAllExcept}
            control={
              <Switch
                color="primary"
                checked={departments?.allExcept}
                disabled={true}
              />
            }
            label="All Except"
          />
        </>
      )}
      {showUnion && (
        <>
          <div
            className={classes.union}
            data-test-id={`ProjectTemplateAutoAssignmentRuleDisplay-union-${id}`}
          >
            <Typography variant="caption">Unions</Typography>
            <div>
              {!!unionNames &&
                unionNames.map(unionName => <div>{unionName}</div>)}
            </div>
          </div>
          <FormControlLabel
            className={classes.unionAllExcept}
            control={
              <Switch
                color="primary"
                checked={unions?.allExcept}
                disabled={true}
              />
            }
            label="All Except"
          />
        </>
      )}
      {showLocation && (
        <React.Fragment>
          <div
            className={classes.hireState}
            data-test-id={`ProjectTemplateAutoAssignmentRuleDisplay-hireState-${id}`}
          >
            <Typography variant="caption">
              Hire <UsaOnly>States</UsaOnly>
              <CanadaOnly>Provinces</CanadaOnly>
            </Typography>
            <div>
              {!!hireStateNames &&
                hireStateNames.map(hireStateName => <div>{hireStateName}</div>)}
            </div>
          </div>
          <FormControlLabel
            className={classes.hireStateAllExcept}
            control={
              <Switch
                color="primary"
                checked={hireStates?.allExcept}
                disabled={true}
              />
            }
            label="All Except"
          />
          <div
            className={classes.workState}
            data-test-id={`ProjectTemplateAutoAssignmentRuleDisplay-workState-${id}`}
          >
            <Typography variant="caption">
              Work <UsaOnly>States</UsaOnly>
              <CanadaOnly>Provinces</CanadaOnly>
            </Typography>
            <div>
              {!!workStateNames &&
                workStateNames.map(workStateName => <div>{workStateName}</div>)}
            </div>
          </div>
          <FormControlLabel
            className={classes.workStateAllExcept}
            control={
              <Switch
                color="primary"
                checked={workStates?.allExcept}
                disabled={true}
              />
            }
            label="All Except"
          />
          <UsaOnly>
            <div
              className={classes.workCity}
              data-test-id={`ProjectTemplateAutoAssignmentRuleDisplay-workCity-${id}`}
            >
              <Typography variant="caption">Work City</Typography>
              <div>{workCityName}</div>
            </div>
          </UsaOnly>
        </React.Fragment>
      )}
      {showAllowance && (
        <>
          <div
            className={classes.allowance}
            data-test-id={`ProjectTemplateAutoAssignmentRuleDisplay-allowance-${id}`}
          >
            <Typography variant="caption">Allowances</Typography>
            <div>
              {!!allowanceNames &&
                allowanceNames.map(allowanceName => <div>{allowanceName}</div>)}
            </div>
          </div>
          <FormControlLabel
            className={classes.allowanceAllExcept}
            control={
              <Switch
                color="primary"
                checked={allowances?.allExcept}
                disabled={true}
              />
            }
            label="All Except"
          />
        </>
      )}

      {!isEmptyRule && (
        <div className={classes.ruleRequired}>
          <ProjectTemplateAutoAssignmentRuleRequiredCheckbox
            data-test-id={`ProjectTemplateAutoAssignmentRuleDisplay-ruleRequired-${id}`}
            isDisabled={true}
            value={required}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ProjectTemplateAutoAssignmentRuleDisplay);
