import React, { useState } from 'react';

// HoC
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import { withRouter } from 'react-router';

// Components
import AdminPage from './admins.js';
import PresetPage from './presets.js';
import GuildPage from './guild.js';
import history from 'common/constants/config/history';
import GlobalSecondaryNavBar from 'common/oldJavascripts/components/Pages/Layouts/GlobalNavbar/SecondaryNavbar';
import NavItemsContainer from 'common/oldJavascripts/components/Pages/Layouts/GlobalNavbar/NavItemsContainer';
import SubNavItem from 'common/oldJavascripts/components/Pages/Layouts/GlobalNavbar/SubNavItem';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import LayoutHeader from 'common/oldJavascripts/components/Base/Layout/LayoutHeader';
import Box from 'common/oldJavascripts/components/Base/Box';

// MuiComponents
import { Paper, Tabs, Tab } from '@mui/material';

// Featureflags
import useFeatureFlags from 'common/hooks/useFeatureFlags';

const styles = {
  topMargin: {
    marginTop: 16,
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#0000FF',
  },
  container: {
    padding: 16,
  },
  globalNavContainer: {
    padding: 16,
    margin: 25,
  },
  tabs: {
    marginLeft: 15,
  },
};

const GlobalPermissions = props => {
  const { classes, tab } = props;

  const [activeTab, setActiveTab] = useState(tab || 0);

  const handleTabChange = (_event, value) => {
    setActiveTab(value);
  };
  const featureFlags = useFeatureFlags();
  const guildManagementFlagOn = featureFlags.includes('GuildManagement');
  const globalNavFlag = featureFlags.includes('GlobalNavbar');
  if (!guildManagementFlagOn && activeTab === 2) {
    setActiveTab(0);
    history.push('/global-permissions');
  }
  const tabs = [AdminPage, PresetPage, GuildPage];
  const ComponentToRender = tabs[activeTab];

  if (globalNavFlag) {
    return (
      <>
        <LayoutHeader globalNav={globalNavFlag}>
          <GlobalSecondaryNavBar>
            <NavItemsContainer>
              <SubNavItem
                to="global-permissions"
                onClick={() => setActiveTab(0)}
                isActive={activeTab === 0}
                data-test-id="GlobalPermissions-adminTab"
              >
                Admin Users
              </SubNavItem>
              <SubNavItem
                to="global-permissions/permissions"
                isActive={activeTab === 1}
                onClick={() => setActiveTab(1)}
                data-test-id="GlobalPermissions-presetTab"
              >
                Permission Presets
              </SubNavItem>
              {guildManagementFlagOn && (
                <SubNavItem
                  to="global-permissions/guild"
                  isActive={activeTab === 2}
                  onClick={() => setActiveTab(2)}
                  data-test-id="GlobalPermissions-guildTab"
                >
                  Guild Management
                </SubNavItem>
              )}
            </NavItemsContainer>
          </GlobalSecondaryNavBar>
        </LayoutHeader>
        <Paper elevation={0} className={classes.globalNavContainer}>
          <ComponentToRender />
        </Paper>
      </>
    );
  }

  return (
    <LayoutContent>
      <Box>
        <Paper elevation={0}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            value={activeTab}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Admin Users"
              data-test-id="GlobalPermissions-adminTab"
            />
            <Tab
              label="Permission Presets"
              data-test-id="GlobalPermissions-presetTab"
            />
            {guildManagementFlagOn && (
              <Tab
                label="Guild Management"
                data-test-id="GlobalPermissions-guildTab"
              />
            )}
          </Tabs>
        </Paper>

        <Paper elevation={0} className={classes.container}>
          {activeTab === 0 && <AdminPage />}
          {activeTab === 1 && <PresetPage />}
          {activeTab === 2 && <GuildPage />}
        </Paper>
      </Box>
    </LayoutContent>
  );
};

export default compose(withRouter, withStyles(styles))(GlobalPermissions);
