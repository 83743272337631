import React from 'react';
import domClasses from '../../utils/dom/classes.js';
import T from '../../utils/i18n';
import Icon from '../Base/Icon';
import CircleIcon from '../Base/CircleIcon';

export default class extends React.Component {
  render() {
    var classes = null;
    var iconName = null;
    var tooltip = null;
    var icon = null;

    classes = { statusFlag: true };

    if (this.props.size) {
      classes['statusFlag--' + this.props.size] = true;
    }

    switch (this.props.status) {
      case 'warning':
        classes['statusFlag--warning'] = true;
        iconName = 'flag';
        break;
      case 'approval-success':
        classes['statusFlag--approval-success'] = true;
        iconName = 'circleCheck';
        break;
      case 'approval-pending':
        classes['statusFlag--approval-pending'] = true;
        iconName = 'circleCheckEmpty';
        break;
      case 'require-signature':
        classes['statusFlag--require-signature'] = true;
        icon = (
          <CircleIcon
            iconSize="small"
            iconType="sign"
            iconText="S"
            marginTop={true}
          />
        );
        break;
      case 'require-review':
        classes['statusFlag--require-review'] = true;
        icon = (
          <CircleIcon
            iconSize="small"
            iconType="review"
            iconText="R"
            marginTop={true}
          />
        );
        break;
      default:
        break;
    }

    tooltip =
      this.props.text || T('status.' + this.props.status.replace(/-/g, '.'));

    if (!icon) {
      icon = <Icon name={iconName} label={tooltip} />;
    }

    return <div className={domClasses.set(classes)}>{icon}</div>;
  }
}
