export const TRIGGER = 'trigger';
export const WORKFLOW = 'workflow';
export const PRESENT = 'present';
export const SYSTEM = 'system';
export const VALUE = 'value';
export const CHECKED = 'checked';
export const GROUP = 'group';
export const BADGE = 'badge';
export const EMAIL = 'email';
export const SET = 'set';
export const SHARE = 'share';
export const GT_VALUE = 'gt_value';
export const LT_VALUE = 'lt_value';
