import React from 'react';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import { withStyles, ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import {
  Card,
  CardContent,
  Checkbox,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import withApi from 'common/hoc/withApi';
import * as Queries from 'common/apollo/queries';
import ChipInputWithAutosuggest from '../ChipInputWithAutosuggest';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0000FF',
    },
  },
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#FFA800',
        },
      },
    },
  },
});

const styles = {
  card: {
    marginTop: 20,
    overflow: 'visible',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 100px',
  },
  input: {
    width: '100%',
  },
  button: {
    display: 'relative',
    top: 20,
    marginLeft: 65,
  },
};

// Converts user objects from gql to simple { id, name } objects
const simplifyUsers = users =>
  users.map(({ id, profile: { firstName, middleName, lastName, email } }) => ({
    id,
    email,
    name: [firstName, middleName, lastName, `<${email}>`]
      .filter(s => s)
      .join(' '),
  }));

const doesTextMatchUser = (text, suggestedUser) => {
  const textMatches = s =>
    (s || '').toLowerCase().slice(0, text.length) === text.toLowerCase();
  return textMatches(suggestedUser.name) || textMatches(suggestedUser.email);
};

const DocumentNotificationDialogDistributionList = props => {
  const {
    classes,
    distributionList,
    projectDepartments,
    onChange,
    onDelete,
    projectUsers,
    index,
  } = props;
  const { departments, recipients, isEnabled } = distributionList;

  const updateRecipients = recipients => {
    recipients = recipients.map(({ id }) =>
      projectUsers.find(({ id: uId }) => uId === id),
    );
    onChange({ ...distributionList, recipients });
  };

  const updateDepartments = departments =>
    onChange({ ...distributionList, departments });

  const toggleIsEnabled = () =>
    onChange({ ...distributionList, isEnabled: !isEnabled });

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.grid}>
            <ChipInputWithAutosuggest
              className={classes.input}
              label="Departments"
              suggestionSource={projectDepartments}
              value={departments}
              onChange={updateDepartments}
              data-test-id={`DocumentNotificationDialogDistributionList-departments-${index}`}
            />
            <div>
              <Tooltip
                disableInteractive
                title={`This distribution list is ${
                  isEnabled ? 'enabled' : 'disabled'
                }`}
              >
                <Checkbox
                  className={classes.button}
                  checked={isEnabled}
                  onClick={toggleIsEnabled}
                  data-test-id={`DocumentNotificationDialogDistributionList-isEnabled-${index}`}
                />
              </Tooltip>
            </div>
            <ChipInputWithAutosuggest
              className={classes.input}
              label="Recipients"
              suggestionMatcher={doesTextMatchUser}
              suggestionSource={simplifyUsers(projectUsers)}
              value={simplifyUsers(recipients)}
              onChange={updateRecipients}
              data-test-id={`DocumentNotificationDialogDistributionList-recipients-${index}`}
            />
            <div>
              {onDelete && (
                <IconButton
                  className={classes.button}
                  onClick={onDelete}
                  data-test-id="DocumentNotificationDialogDistributionList-delete"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
};

const withProjectUsers = graphql(Queries.projectUsers, {
  options: ({ projectId }) => ({
    variables: { id: projectId, excludeEmployees: true },
  }),
  props: ({ data: { project: { users: projectUsers = [] } = {} } }) => ({
    projectUsers,
  }),
});

const withProjectDepartments = graphql(Queries.projectDepartments, {
  options: ({ projectId }) => ({
    variables: { projectId },
  }),
  props: ({ data: { departments: projectDepartments = [] } }) => ({
    projectDepartments,
  }),
});

export default compose(
  withStyles(styles),
  withApi,
  withProjectUsers,
  withProjectDepartments,
)(DocumentNotificationDialogDistributionList);
