import React, { Fragment, useState, useRef } from 'react';
import Signature from 'mobile/components/Projects/Signature';
import { useCookies } from 'react-cookie';
import classnames from 'classnames';
import {
  REMEMBER_SIGNATURE_COOKIE,
  VIEWED_TUTORIAL_OVERLAY,
} from 'mobile/constants';
import BackButton from 'mobile/components/BackButton';
import history from 'common/constants/config/history';
import {
  Button,
  Paper,
  Typography,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { Check as CheckMarkIcon, ChevronLeft } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import FormTutorialOverlay from 'mobile/components/Projects/FormTutorialOverlay';
import DocumentInteractionController from 'mobile/common/DocumentInteractionController';
import { MOBILE_ROUTES } from 'mobile/constants';
import withApi from 'common/hoc/withApi';
import PreLoader from 'mobile/components/PreLoader';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import truncate from 'lodash.truncate';
import ajax from 'common/utilities/ajax.js';
import processDocumentRequests from 'common/utilities/processDocumentRequests';
import decorateDocuments from 'mobile/components/DocumentSigner/DocumentSigner/decorateDocuments';
import RejectOffer from './RejectOffer';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import RemoveDocumentModal from 'common/components/OfferDocumentsList/RemoveDocumentModal';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import GrowlNotification from 'mobile/components/DocumentSigner/DocumentSigner/GrowlNotification';

const approvalStyles = makeStyles(theme => ({
  root: {
    marginTop: '10px',
    backgroundColor: '#f7fcfc',
    minHeight: '100vh',
  },
  globalNav: {
    marginTop: '130px',
  },
  crew: {
    color: '#2E1EFF',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: 1,
    textTransform: 'uppercase',
    borderRadius: 4,
    backgroundColor: '#BECFFE',
    padding: '6px',
    textAlign: 'center',
  },
  name: {
    marginLeft: '20px !important',
    color: '#646464',
    fontSize: '35px !important',
    fontWeight: '400 important',
  },
  grayedCheckMark: {
    color: '#cfcfcf',
    width: 30,
    height: 30,
  },
  greenCheckMark: {
    color: '#2ecc71',
    width: 30,
    height: 30,
  },
  formTitle: {
    color: '#646464',
    fontSize: '16px',
    fontWeight: 400,
    marginLeft: '15px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  itemRow: {
    borderBottom: '1px solid #cfcfcf',
    padding: '20px 10px 10px',
  },
  topSection: {
    marginLeft: '20px',
    marginTop: '14px',
    width: '60px',
  },
  expandedIcon: {
    transform: 'rotate(90deg)',
    color: '#ffffff',
    backgroundColor: '#0000FF',
    height: '24px',
    width: '24px',
    borderRadius: 50,
  },
  expandIcon: {
    color: '#0000FF',
    backgroundColor: '#f5f5f5',
    height: '24px',
    width: '24px',
    borderRadius: 50,
    transform: 'rotate(-90deg)',
  },
  icon: {
    position: 'relative',
    bottom: '10px',
    right: '10px',
  },
  bottomSection: {
    width: '100vw',
    boxShadow: '0 -4px 10px #cccccc',
    backgroundColor: '#f7fcfc',
    padding: '10px',
    position: 'fixed',
    left: 0,
    bottom: 0,
  },
  approveBtn: {
    fontSize: '14px !important',
    '&:focus': {
      backgroundColor: '#0000B3',
    },
    '&:active': {
      backgroundColor: '#000080',
    },
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  disabledApproveBtn: {
    backgroundColor: '#0000FF',
    borderRadius: 2,
    opacity: 0.4,
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  rejectAll: {
    color: '#0000FF',
    fontSize: '16px !important',
  },
  completedStatus: {
    textAlign: 'right',
    color: '#646464',
    fontSize: 12,
    fontWeight: 400,
    marginBottom: '10px',
    marginRight: '5px',
  },
  navigatingForms: {
    width: '100%',
    opacity: 0.85,
  },
  itemOpen: {
    borderLeft: '4px solid #0000FF',
    borderBottom: '1px solid #cfcfcf',
    height: '60px !important',
    minHeight: 'unset',
  },
  paperItemOpen: {
    backgroundColor: '#ffffff',
    marginBottom: '150px',
  },
  paperItemClosed: {
    backgroundColor: '#ffffff',
    marginTop: '30px',
    marginBottom: '150px',
  },
  saveProgress: {
    marginRight: '15px !important',
    padding: '10px !important',
    '&:hover': {
      backgroundColor: '#f7fcfc',
    },
    '&:focus': {
      background: 'transparent !important',
    },
  },
  nextButton: {
    marginRight: '10px !important',
    padding: '10px 0px !important',
    '&:active': {
      background: '#0000B3',
    },
  },
  panelContainer: {
    height: '30px',
  },
  panelDetails: {
    padding: '10px 4px',
  },
  btnContainer: {
    flexBasis: 'auto !important',
  },
  error: {
    color: 'red',
    margin: '20px',
    textAlign: 'center',
  },
  rejectOfferButton: {
    color: '#FFFFFF !important',
    backgroundColor: '#f3ac21 !important',
    maxWidth: '100px',
    padding: '7px 0px',
    height: '42px',
    '&:active': {
      backgroundColor: '#f3ac21 !important',
    },
  },
  removeDocButton: {
    backgroundColor: '#f3ac21 !important',
    color: '#FFFFFF !important',
    maxWidth: '28px',
    height: '42px',
    marginRight: '2px',
    padding: '7px 0px',
    minWidth: '28px !important',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      height: '80%',
      width: '1px',
      backgroundColor: '#fff', // White line on the left side
    },
  },
}));

const Approval = props => {
  const {
    documents: documentsQuery,
    profile: { data: profileData },
    invalidateByPatterns,
  } = props;
  const {
    data: documentsQueryData,
    status: documentsQueryStatus,
  } = documentsQuery;
  const { location: { state: { projectId, offer = {} } = {} } = {} } =
    history || {};
  const { id: offerId } = offer;
  const { first_name, last_name, middle_name } = profileData;
  const name = [first_name, middle_name, last_name].filter(s => s).join(' ');
  const initials = [first_name, middle_name, last_name]
    .filter(s => s)
    .map(s => String(s)[0])
    .join('');
  const actor = { name, initials };
  const { items: upstreamDocuments = [] } = documentsQueryData;
  const [isProcessingApprovals, setIsProcessingApprovals] = useState(false);
  const [editedDocuments, setEditedDocuments] = useState(null);
  const [currentDocumentId, setCurrentDocumentId] = useState(null);
  const [documentsSuccessfullySubmitted] = useState([]);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [openRemoveDocumentDialog, setOpenRemoveDocumentDialog] = useState(
    false,
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const documentSignerRef = useRef(null);
  const [submissionErrors, setSubmissionErrors] = useState([]);
  const [growlOpen, setGrowlOpen] = useState(false);
  const [growlType, setGrowlType] = useState(null);
  const isGrowlEnabled = growlOpen;
  const isLoading = documentsQueryStatus === 'loading' || isProcessingApprovals;
  const documents = editedDocuments || decorateDocuments(upstreamDocuments);
  const currentDocument =
    currentDocumentId && documents.find(({ id }) => id === currentDocumentId);
  const featureFlags = useFeatureFlags();
  const isRemoveDocFlagOn = featureFlags.includes('RemoveDocument');
  const isGlobalFlagOn = featureFlags.includes('GlobalNavbar');
  const completedDoc = currentDocument?.status === 'complete';
  const isDocOne = documents.length === 1;
  const canRemoveDocument =
    isRemoveDocFlagOn && !isDocOne && !currentDocument?.unremovable;

  const handleRejectOfferClose = () => {
    setOpenRejectDialog(false);
  };

  const handleOpen = () => {
    setOpenRejectDialog(true);
  };

  const handleRemoveDocument = () => {
    setOpenRemoveDocumentDialog(true);
  };

  const handleRemoveDocumentDialogClose = value => {
    setOpenRemoveDocumentDialog(false);
    setCurrentDocumentId(null);
    setAnchorEl(null);
    setGrowlOpen(true);
    setGrowlType('removeDoc');
    const pendingDocs = documents.filter(doc => doc.status !== 'complete');
    if (pendingDocs.length === 1) {
      const offersRoute = `/projects/${projectId}/offers`;
      invalidateByPatterns([
        offersRoute,
        `/v2/projects/${projectId}/approvals`,
      ]);
      history.push({
        pathname: offersRoute,
        state: { showNextOffer: true },
      });
    }
    if (value) documentsQuery.reload();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hideNotification = (event, reason) => {
    if (reason === 'clickaway') return;
    setGrowlOpen(false);
  };

  const cleanUpNotification = () => {
    setGrowlType(null);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const [cookies, setCookie] = useCookies([
    REMEMBER_SIGNATURE_COOKIE,
    VIEWED_TUTORIAL_OVERLAY,
  ]);
  const classes = approvalStyles();
  const {
    [REMEMBER_SIGNATURE_COOKIE]: rememberSignature,
    [VIEWED_TUTORIAL_OVERLAY]: viewedTutorialOverlay,
  } = cookies;

  if (!offerId) {
    const { PROJECTS } = MOBILE_ROUTES;
    history.push({ pathname: PROJECTS });
  }

  const handleRememberSignature = ({
    rememberSignature,
    approverInfo = {},
  }) => {
    setCookie(REMEMBER_SIGNATURE_COOKIE, rememberSignature, { path: '/' });
  };

  const handleOverlayTutorial = () => {
    setCookie(VIEWED_TUTORIAL_OVERLAY, 'true', { path: '/' });
  };

  if (!rememberSignature) {
    return (
      <Signature
        actor={actor}
        handleRememberSignatureInSession={handleRememberSignature}
        projectId={projectId}
      />
    );
  }

  const handleOfferAccept = () => {
    setIsProcessingApprovals(true);
    const newDocs = documents.filter(doc => doc.ready && !doc.signed);
    const documentsCount = newDocs.length;
    newDocs.map(({ id: documentId, fields: fieldsData = [] }, index) => {
      const fields = fieldsData.map(({ id, value }) => ({
        id,
        value,
      }));
      const postUrl = `/v2/offers/${offerId}/documents/${documentId}`;
      return ajax
        .post(postUrl, { fields }, { json: true })
        .then(response => {
          const indexPosition = index + 1;
          const hasProcessedAllDocs =
            indexPosition === documentsCount && submissionErrors.length === 0;
          if (hasProcessedAllDocs) {
            // no errors
            const offersRoute = `/projects/${projectId}/offers`;
            invalidateByPatterns([
              offersRoute,
              `/v2/projects/${projectId}/approvals`,
            ]);
            history.push({
              pathname: offersRoute,
              state: { showNextOffer: true },
            });
          }
        })
        .catch(error => {
          const { resource } = error || {};
          const { errors } = resource || {};
          if (errors) {
            setSubmissionErrors([...errors]);
            console.warn(errors);
          }
        })
        .finally(() => setIsProcessingApprovals(false));
    });
  };

  const handleSave = (docs, isAutosave = false) => {
    if (!docs) return;
    // Sometimes autosave is triggered after the offer is closed in which case
    // we should just ignore it.
    if (isAutosave && !offerId) return;

    const documentRequests = Object.keys(docs)
      .filter(d => docs[d].ready && !documentsSuccessfullySubmitted.includes(d))
      .map(d => {
        const fields = Object.keys(docs[d].fields).map(fieldId => ({
          id: docs[d].fields[fieldId].id,
          value: docs[d].fields[fieldId].value,
        }));
        const { crew_acknowledged, id } = docs[d];
        const postUrl = `/v2/offers/${offerId}/documents/${id}/save-draft`;
        const promise = ajax.post(
          postUrl,
          { fields, crew_acknowledged },
          { json: true },
        );
        return { promise, documentId: id };
      });
    // If this is an autosave don't reject errors - we don't want to pop up
    // mysterious error messages while the user is typing.
    return processDocumentRequests(documentRequests, !isAutosave).then(() =>
      documentsQuery.reload(),
    );
  };

  const getIsDocumentComplete = doc => {
    const { _derived: { isComplete = false } = {} } = doc || {};
    return isComplete;
  };

  const formsCompletedCount = documents.filter(getIsDocumentComplete).length;
  const isOfferComplete = documents.every(getIsDocumentComplete);
  const documentCount = documents.length;
  const expansionPanelDocs = currentDocumentId ? [currentDocument] : documents;

  const goToNext = () => {
    const isCurrentDocumentComplete = getIsDocumentComplete(currentDocument);
    if (!isCurrentDocumentComplete) {
      documentSignerRef.current.goToFirstIncompleteRequiredField();
      return;
    }

    // Now find the first incomplete document, if any
    const firstIncompleteDocument = documents.find(
      doc => !getIsDocumentComplete(doc),
    );

    // If an incomplete document is found open it, otherwise just close all
    setCurrentDocumentId(firstIncompleteDocument && firstIncompleteDocument.id);
  };

  const rootClass = classnames(classes.root, {
    [classes.globalNav]: isGlobalFlagOn,
  });

  return (
    <Fragment>
      <div className={rootClass}>
        {!currentDocumentId && (
          <Fragment>
            {!isGlobalFlagOn && (
              <BackButton
                title="Back to Offers"
                url={`/projects/${projectId}/offers`}
              />
            )}
            <div className={classes.topSection}>
              <Typography className={classes.crew}>CREW</Typography>
            </div>
            <Typography className={classes.name}>
              {`${offer?.projectUser?.profile?.firstName}  ${offer.projectUser?.profile?.lastName}`}
            </Typography>
          </Fragment>
        )}
        <Paper
          className={
            currentDocumentId ? classes.paperItemOpen : classes.paperItemClosed
          }
          data-test-id="Approval-Paper-ExpansionContainer"
        >
          {isLoading && <PreLoader />}
          {expansionPanelDocs.length > 0 &&
            expansionPanelDocs.map(doc => (
              <Accordion
                key={doc.id}
                expanded={currentDocumentId === doc.id}
                data-test-id={`Approval-ExpansionPanel-${doc.id}`}
                onChange={(event, expanded) => {
                  setCurrentDocumentId(expanded ? doc.id : null);
                }}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={currentDocumentId ? classes.itemOpen : ''}
                  data-test-id={`Approval-ExpansionPanelSummary-${doc.id}`}
                >
                  <Grid container className={classes.panelContainer}>
                    <Grid item xs={1}>
                      <IconButton className={classes.icon}>
                        <ChevronLeft
                          className={
                            currentDocumentId
                              ? classes.expandedIcon
                              : classes.expandIcon
                          }
                        />{' '}
                      </IconButton>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography
                        variant="h6"
                        className={classes.formTitle}
                        data-test-id={`Approval-DocumentName-${doc.id}`}
                      >
                        {truncate(doc.name || doc.templateName, {
                          length: 46,
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <CheckMarkIcon
                        fontSize="large"
                        className={
                          getIsDocumentComplete(doc)
                            ? classes.greenCheckMark
                            : classes.grayedCheckMark
                        }
                      />
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.panelDetails}>
                  <DocumentInteractionController
                    offerId={offerId}
                    projectId={projectId}
                    selectedDocumentId={doc.id}
                    documents={documents}
                    mobileDocsSaveHandler={handleSave}
                    documentSignerRef={el => {
                      documentSignerRef.current = currentDocumentId ? el : null;
                    }}
                    onChangeDocuments={setEditedDocuments}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
        </Paper>
        {isGrowlEnabled && (
          <GrowlNotification
            isOpen={growlOpen}
            onClose={hideNotification}
            onExited={cleanUpNotification}
            type={growlType}
          />
        )}
        {submissionErrors.length > 0 && (
          <Typography className={classes.error}>
            Sorry an error occurred, please try again
          </Typography>
        )}
        <div className={classes.bottomSection}>
          {!currentDocumentId && (
            <Typography className={classes.completedStatus}>
              {formsCompletedCount} of {documentCount} Forms Completed
            </Typography>
          )}
          <Grid container justifyContent={'space-between'}>
            <Grid item xs={5}>
              {!currentDocumentId && (
                <Typography
                  fontWeight={400}
                  className={classes.rejectAll}
                  onClick={handleOpen}
                >
                  Reject All
                </Typography>
              )}
              {currentDocumentId && !completedDoc && (
                <div>
                  <Button className={classes.rejectOfferButton}>
                    Reject Offer
                  </Button>
                  {canRemoveDocument && (
                    <Button
                      className={classes.removeDocButton}
                      onClick={handleMenu}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                    >
                      <ArrowDropUpIcon />
                    </Button>
                  )}
                </div>
              )}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleOpen}>Reject Offer</MenuItem>
                <MenuItem onClick={handleRemoveDocument}>
                  Remove Document
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item xs={7} className={classes.btnContainer}>
              {!currentDocumentId && (
                <Button
                  disabled={!isOfferComplete || isProcessingApprovals}
                  onClick={handleOfferAccept}
                  data-test-id="Approval-submitApprovalBtn"
                  color="primary"
                  variant="contained"
                  className={
                    isOfferComplete
                      ? classes.approveBtn
                      : classes.disabledApproveBtn
                  }
                >
                  {isProcessingApprovals
                    ? 'processing...'
                    : 'Submit for Approval'}
                </Button>
              )}
              {currentDocumentId && (
                <div>
                  <Button
                    className={classes.saveProgress}
                    onClick={() => handleSave([currentDocument])}
                    color="primary"
                    variant="outlined"
                  >
                    Save Progress
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.nextButton}
                    onClick={goToNext}
                    data-test-id="Approval-next"
                    color="primary"
                  >
                    Next
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      {!viewedTutorialOverlay && (
        <FormTutorialOverlay overlayTutorialHandler={handleOverlayTutorial} />
      )}
      {openRejectDialog && (
        <RejectOffer
          open={openRejectDialog}
          handleClose={handleRejectOfferClose}
          projectId={projectId}
          selectedOfferId={offerId}
        />
      )}
      {openRemoveDocumentDialog && (
        <RemoveDocumentModal
          onClose={value => handleRemoveDocumentDialogClose(value)}
          documentId={currentDocumentId}
        />
      )}
    </Fragment>
  );
};

Approval.queries = {
  documents: {
    info: () => {
      const { location: { state: { projectId, offer = {} } = {} } = {} } =
        history || {};
      const { id: offerId } = offer;

      return {
        id: `/v2/projects/${projectId}/offers/${offerId}/document-details`,
      };
    },
  },
  profile: {
    info: () => {
      return {
        id: `/account/profile`,
      };
    },
  },
};

export default withApi(Approval);
