import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query projectCountry($id: Int!) {
    project(id: $id) {
      id
      country {
        id
        code
      }
    }
  }
`;

const useProjectCountry = ({ projectId }) => {
  const { loading, data } = useQuery(QUERY, {
    skip: !projectId,
    variables: { id: parseInt(projectId) },
  });
  return { loading, data: data?.project.country };
};

export default useProjectCountry;
