import React from 'react';
import { withStyles } from '@mui/styles';

const styles = {
  root: {
    backgroundColor: '#ffffff',
    padding: '10px 10px 0px',
    height: '32px',
    color: '#0000FF',
    fontWeight: '700',
    textTransform: 'uppercase',
    marginBottom: '10px',
  },
};

const RowHeader = props => {
  const { children, classes } = props;
  return <div className={classes.root}>{children}</div>;
};

export default withStyles(styles)(RowHeader);
