import React from 'react';
import ProjectTemplateAutoAssignmentRuleSelect from './ProjectTemplateAutoAssignmentRuleSelect';
import { ALLOWANCE_TYPES } from '../constants/allowanceTypes';

const ProjectTemplateAutoAssignmentRuleAllowanceSelect = props => {
  const {
    allowanceId,
    className,
    'data-test-id': dataTestId,
    label,
    onChange,
  } = props;
  const allowanceOptions = ALLOWANCE_TYPES?.map(({ id, name, code }) => ({
    label: name,
    value: id,
    id,
    name,
    code: code,
  }));

  return (
    <ProjectTemplateAutoAssignmentRuleSelect
      label={label}
      options={allowanceOptions}
      value={allowanceId}
      onChange={allowanceId =>
        onChange(allowanceOptions.filter(({ id }) => allowanceId.includes(id)))
      }
      data-test-id={dataTestId}
      className={className}
      multiple={true}
    />
  );
};

export default ProjectTemplateAutoAssignmentRuleAllowanceSelect;
