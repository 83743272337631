import mime from 'mime';

const humanizeFileTypes = (mimeTypeCsl = '') => {
  const extensions = mimeTypeCsl
    .split(',')
    .map(mimeType => mime.getExtension(mimeType).toUpperCase());
  const [head, ...tail] = extensions;
  return tail.length ? `${tail.join(', ')} or ${head}` : head;
};

export default humanizeFileTypes;
