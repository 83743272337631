import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    marginBottom: 20,
  },
});

const LabeledTextArea = props => {
  const { label, value, onChange, rows } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {label && <label className="field-label">{label}</label>}
      <textarea
        className="field-input"
        value={value}
        onChange={e => onChange(e.target.value)}
        rows={rows}
      />
    </div>
  );
};

export default LabeledTextArea;
