import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';

// MaterialUI Components
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

// Custom Components
import PanelConfiguration from './PanelConfiguration';
import RuleAssignmentInput from './RuleAssignmentInput';

// Utilities
import { getRuleRoleId } from 'admin/components/RoleMapper/documentSetupHelpers/rules';
import { newBadge } from 'admin/components/RoleMapper/documentSetupHelpers/notifications';
import * as RuleConstants from './ruleConstants';

const styles = {
  label: {
    fontWeight: 600,
    display: 'flex',
  },
  input: {
    width: '80%',
  },
  divider: {
    marginTop: '10px',
    marginBottom: '5px',
  },
  drawerText: {
    fontWeight: 600,
    display: 'flex',
  },
};

const RuleAssignmentConfiguration = props => {
  const {
    triggerActions,
    rule,
    rules,
    field,
    fields,
    assignRule,
    assignBadge,
    cancelAssignment,
    classes,
    editingId,
    assignmentType,
    roles = [],
    badgeTypes = [],
    isTrigger,
  } = props;
  const {
    triggerAction: {
      triggerActionType: upstreamActionType = '',
      value: upstreamValue = '',
    } = {},
    id: ruleId = '',
  } = rule || {};
  const {
    id: fieldId,
    options = [],
    radioGroupItems = [],
    fieldType,
    roleId,
    textType,
  } = field || {};
  const { name: roleName = '' } = roles.find(r => r.id === roleId);

  // State
  const [actionType, setActionType] = useState(upstreamActionType);
  const [actionValue, setActionValue] = useState(upstreamValue);
  const [assignedRuleId, setAssignedRuleId] = useState(ruleId);

  const configuringTrigger = assignmentType === RuleConstants.TRIGGER;
  const configuringWorkflow = assignmentType === RuleConstants.WORKFLOW;
  const hasEmptyTriggerValue =
    actionType === RuleConstants.VALUE && !actionValue;

  let filteredRules =
    configuringTrigger || configuringWorkflow
      ? rules
          .filter(({ ruleType }) => {
            if (configuringTrigger) return ruleType === RuleConstants.TRIGGER;
            return ruleType === RuleConstants.WORKFLOW;
          })
          .filter(
            r =>
              !r.triggerFieldId &&
              (roleId === getRuleRoleId(rules, fields, r.id) ||
                editingId === r.id ||
                r.ruleType === RuleConstants.WORKFLOW),
          )
      : rules.filter(
          ({ ruleType, id, triggerFieldId }) =>
            ruleType === RuleConstants.EMAIL ||
            (ruleType === RuleConstants.BADGE &&
              (!triggerFieldId || id === editingId)),
        );
  if (!isTrigger) {
    // Add badge types to the rule array
    // This prevents displaying duplicates of the same badge
    // Remove a badge if the field has the badge assigned
    const concatBadges = badgeTypes
      .filter(({ name }) => {
        const selectedRule = rules.find(
          ({ ruleType, badgeType, triggerFieldId, id }) =>
            ruleType === RuleConstants.BADGE &&
            triggerFieldId === fieldId &&
            name === badgeType,
        );
        return !selectedRule;
      })
      .map(badge => newBadge(badge));
    filteredRules = filteredRules.concat(concatBadges);
  }

  const [ruleType, setRuleType] = useState(
    (filteredRules.find(({ id }) => id === ruleId) || {}).ruleType || '',
  );

  useEffect(() => {
    setActionType(upstreamActionType);
    setActionValue(upstreamValue);
    setAssignedRuleId(ruleId);
  }, [fieldId, ruleId, upstreamActionType, upstreamValue]);

  const updateActionType = value => {
    setActionType(value);
    setActionValue('');
  };

  const handleSave = () => {
    const value =
      typeof actionValue === 'string' ? actionValue.trim() : actionValue;
    const upstreamSave =
      ruleType === RuleConstants.BADGE ? assignBadge : assignRule;
    upstreamSave({
      triggerRuleId: assignedRuleId,
      originalTriggerRuleId: ruleId,
      triggerActionType: actionType,
      triggerValue: value,
      ruleType,
    });
  };

  const ruleMenuItems = filteredRules
    .filter(r => (editingId ? true : r.triggerFieldId !== fieldId))
    .map(r => (
      <MenuItem
        value={r.id}
        key={r.id}
        data-test-id={`RuleAssignmentConfiguration-associatedRuleMenuItem-${r.id}`}
      >
        {r.name}
      </MenuItem>
    ));

  const actionMenuItems = triggerActions.map(action => (
    <MenuItem
      value={action.name}
      key={action.name}
      data-test-id={`RuleAssignmentConfiguration-triggerActionMenuItem-${action.name}`}
    >
      {action.description}
    </MenuItem>
  ));

  const ruleLabel = (() => {
    if (configuringTrigger) return 'Trigger';
    if (configuringWorkflow) return 'Conditional Workflow';
    return 'Share';
  })();

  const ruleInputLabel = (() => {
    if (configuringTrigger) return 'Trigger';
    if (configuringWorkflow) return 'Conditional Workflow';
    return 'Share Notification';
  })();

  const assignmentSelectTooltip = (() => {
    if (configuringTrigger)
      return `Only trigger rules that contain fields in the ${roleName} role can be attached to this field.`;
    if (configuringWorkflow)
      return 'Conditional workflow rules that are not assigned to a source field can be assigned using this select dropdown.';
    return '';
  })();

  const assignmentProps = {
    actionTitle: ruleLabel,
    actionSelect: {
      onChange: e => updateActionType(e.target.value),
      value: actionType,
      dataTestId: `RuleAssignmentConfiguration-actionTypeSelect-${actionType}`,
      inputProps: {
        'data-test-id': `RuleAssignmentConfiguration-actionTypeSelectInput-${actionType}`,
      },
      menuItems: actionMenuItems,
    },
    assignmentTitle: `Associated ${ruleInputLabel}`,
    assignmentSelect: {
      onChange: e => {
        setAssignedRuleId(e.target.value);
        setRuleType(
          (filteredRules.find(({ id }) => id === e.target.value) || {})
            .ruleType || '',
        );
      },
      value: assignedRuleId,
      dataTestId: `RuleAssignmentConfiguration-associatedRuleSelect-${assignedRuleId}`,
      inputProps: {
        'data-test-id': `RuleAssignmentConfiguration-associatedRuleSelectInput-${assignedRuleId}`,
      },
      menuItems: ruleMenuItems,
      infoTooltip: assignmentSelectTooltip,
    },
    saveButton: {
      disabled: hasEmptyTriggerValue || !actionType || !assignedRuleId,
      onClick: handleSave,
      dataTestId: 'RuleAssignmentConfiguration-saveButton',
      title: 'Done',
      tooltipMessage: 'Please complete all fields',
      tooltipDataTestId: 'RuleAssignmentConfiguration-tooltip',
    },
    cancelButton: {
      dataTestId: 'RuleAssignmentConfiguration-cancelButton',
      title: 'Cancel',
      onClick: cancelAssignment,
    },
  };

  const assignmentInputProps = {
    fieldType,
    options,
    radioGroupItems,
    updateValue: e => setActionValue(e.target.value),
    value: actionValue,
    textType,
  };

  return (
    <PanelConfiguration {...assignmentProps}>
      {(actionType === RuleConstants.VALUE ||
        actionType === RuleConstants.SYSTEM ||
        actionType === RuleConstants.GT_VALUE ||
        actionType === RuleConstants.LT_VALUE) && (
        <React.Fragment>
          <Typography variant="subtitle1" className={classes.drawerText}>
            {ruleLabel} Value
          </Typography>
          <RuleAssignmentInput {...assignmentInputProps} />
          <Divider className={classes.divider} />
        </React.Fragment>
      )}
    </PanelConfiguration>
  );
};

export default withStyles(styles)(RuleAssignmentConfiguration);
