import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query offerLoanoutCorporation($id: Int!) {
    offer(id: $id) {
      loanoutCorporation {
        status
        name
        taxId
        type
        state
        city
        zip
        address
        gstNumber
        w9 {
          id
          date
          name
        }
      }
    }
  }
`;

const useOfferLoanoutCorporation = (id, skip) => {
  const { error, loading, data } = useQuery(QUERY, {
    skip: skip || !parseInt(id) > 0,
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: 'no-cache',
  });

  const corp = data?.offer?.loanoutCorporation;
  return { error, loading, data: corp };
};

export default useOfferLoanoutCorporation;
