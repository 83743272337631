/// Mapper for legacy account codes modal

const accountCodesMap = (offers, showAccountCodeModalId) => {
  const findCorrectOffer = offers.find(
    offer => offer.id === showAccountCodeModalId,
  );
  const {
    termsOfEmployment: terms = {},
    termsOfEmploymentV2: termsV2 = {},
    status,
    version,
    termsOfHire: {
      union: { isNonUnion },
      workSchedule: { code },
    },
    allowances: {
      perDiemAllowance,
      boxRentalAllowance,
      computerRentalAllowance,
      mobilePhoneAllowance,
      carAllowance,
      housingAllowance,
    },
  } = findCorrectOffer;

  // Checking to see what type of nonUnion terms of employment it is
  const { __typename } = termsV2;

  const isNonUnionWeekly = __typename?.includes(
    'TermsOfEmploymentNonUnionWeekly',
  );
  const isNonUnionDaily = __typename?.includes(
    'TermsOfEmploymentNonUnionDaily',
  );
  const isNonUnionHourly = __typename?.includes(
    'TermsOfEmploymentNonUnionHourly',
  );

  const isSAGUnion = __typename?.includes(
    'TermsOfEmploymentUnionSag',
    'UNION_SAG_WEEKLY',
    'UNION_SAG_CONTRACT',
    'UNION_SAG_DAILY',
  );

  const offer = {
    id: showAccountCodeModalId,
    terms_of_employment: {
      rate_per_week_studio:
        isNonUnionWeekly || isSAGUnion
          ? termsV2?.rate
          : terms?.studioRatePerWeek || terms?.weeklyRate,
      rate_per_day_studio:
        isNonUnionDaily || isSAGUnion ? termsV2?.rate : terms?.dailyRate,
      rate_per_hour_location: terms?.locationRatePerHour,
      rate_per_hour_studio: isNonUnionHourly
        ? termsV2?.rate
        : terms?.studioRatePerHour || terms?.ratePerHour,
      rate_per_week_location: terms?.locationRatePerWeek,
      per_diem_allowance_amount: perDiemAllowance?.amount,
      box_rental_amount: boxRentalAllowance?.amount,
      computer_rental_amount: computerRentalAllowance?.amount,
      mobile_phone_amount: mobilePhoneAllowance?.amount,
      car_allowance_amount: carAllowance?.amount,
      housing_allowance_amount: housingAllowance?.amount,
      type: __typename, // This is the union type - terms of employment
    },
    status,
    version,
    union_is_non_union: isNonUnion,
    work_schedule_code: code || null,
  };

  return offer;
};

export default accountCodesMap;
