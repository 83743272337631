import { useQuery } from '@apollo/react-hooks';
import { Queries } from 'common/apollo';

const useProjectCloneSettings = projectId => {
  const { loading, data } = useQuery(Queries.projectCloneSettings, {
    skip: !projectId,
    variables: { projectId: parseInt(projectId, 10) },
  });
  return { loading, data: data && data.projectCloneSettings };
};

export default useProjectCloneSettings;
