import gql from 'graphql-tag';

export default gql`
  query projectTemplateNotifications($id: Int!) {
    projectTemplate(id: $id) {
      id
      notifications {
        id
        name
        explainer
        distributionLists {
          departments {
            id
            name
          }
          recipients {
            id
            profile {
              firstName
              middleName
              lastName
              email
            }
          }
          isEnabled
        }
      }
    }
  }
`;
