import React from 'react';
import { ThemeProvider } from '@mui/styles';
import theme from './theme';
import CncSnackbarProvider from './CncSnackbarProvider';

const CncUiProvider = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CncSnackbarProvider>{children}</CncSnackbarProvider>
  </ThemeProvider>
);

export default CncUiProvider;
