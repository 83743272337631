import React, { useState, useEffect } from 'react';

import { List, DialogContent, InputBase, IconButton } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import CrewField from './CrewField';
import ActionButtons from './ActionButtons';
import Checkbox from '@mui/material/Checkbox';

// templates
const CrewFields = ({
  onClose,
  fields,
  classes,
  onNext,
  data,
  loading: fieldsLoading,
}) => {
  const [crewFields, setCrewFields] = useState([]);
  const [search, setSearch] = useState('');
  const [checkedAll, setCheckedAll] = useState(false);

  useEffect(() => {
    if (data?.fields && data?.fields.length > 0) {
      setCrewFields(data?.fields);
      return;
    }
    setCrewFields(fields);
  }, [data, fields]);

  useEffect(() => {
    if (crewFields.length > 0) {
      const allChecked = crewFields.every(field => field.checked);
      setCheckedAll(allChecked);
    }
  }, [crewFields]);

  const handleCheckBoxClick = field => {
    const index = crewFields.findIndex(fld => fld.id === field.id);
    if (index > -1) {
      const newcrewFields = [...crewFields];
      newcrewFields[index].checked = !newcrewFields[index].checked;
      setCrewFields(newcrewFields);
    }
  };

  // next screen - note
  const handleNext = () => {
    const selectedFields = fields.filter(({ checked }) => checked); // no checked fields - no next step
    if (!selectedFields) return;
    let step = 'note';
    onNext(step, { fields: fields });
  };

  const handleChange = e => {
    const searchVal = e.target.value || '';
    setSearch(searchVal);
    const newCrewFields = fields.filter(field =>
      field.name.toLowerCase().includes(searchVal.toLowerCase()),
    );
    if (newCrewFields) setCrewFields(newCrewFields);
    else setCrewFields(crewFields);
  };

  const handleAllSelect = () => {
    const newCrewFields = [...crewFields];
    newCrewFields.forEach(field => {
      field.checked = !checkedAll;
    });
    setCrewFields(newCrewFields);
    setCheckedAll(!checkedAll);
  };

  return (
    <>
      <p className={classes.subHeader}>
        Select the fields that the crew member needs to correct
      </p>
      {!fieldsLoading && (
        <div className={classes.inputWrapper}>
          <div className={classes.inputContainer}>
            <IconButton className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder="Search"
              onChange={e => handleChange(e)}
              value={search}
            />
          </div>
          <div className={classes.allText}>
            <Checkbox
              edge="start"
              onClick={() => handleAllSelect()}
              checked={checkedAll}
              data-test-id={'crew-field-list-all-checkbox'}
              classes={{ root: classes.checkboxRoot }}
            />
            All
          </div>
        </div>
      )}
      <DialogContent
        data-test-id="crew-fields-section"
        className={classes.fieldsWrapper}
      >
        <List
          classes={{ root: classes.listRoot }}
          data-test-id="crew-fields-list"
        >
          {crewFields &&
            crewFields.map(node => (
              <CrewField
                key={node.id}
                data={node}
                classes={classes}
                onCheckBoxClick={handleCheckBoxClick}
              />
            ))}
        </List>
      </DialogContent>
      <ActionButtons
        btnText1="Cancel"
        disabled={!crewFields.find(fld => fld.checked)}
        onBack={() => onClose()}
        onNext={handleNext}
        classes={classes}
      />
    </>
  );
};

export default CrewFields;
