import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import DepartmentItem from './Department/Item';
import List from 'common/oldJavascripts/components/Base/List';
import NewDepartment from './New';
import Loader from 'common/components/Loader';

export default Relay.createClass({
  statics: {
    queries: {
      departments: {
        info(params, related) {
          const routerParams = related['/router/params'];

          return {
            id: `/v2/projects/${routerParams.projectId}/departments`,
            expires: true,
          };
        },
      },
    },
  },

  render() {
    const departmentsQuery = this.props.departments;
    const { detailSubDataType, enforceMask, accountMask } = this.props;

    if (departmentsQuery.status === 'loading') {
      return <Loader />;
    }

    return (
      <List>
        {this._renderDepartments()}
        <NewDepartment
          detailSubDataType={detailSubDataType}
          enforceMask={enforceMask}
          accountMask={accountMask}
        />
      </List>
    );
  },

  _renderDepartments() {
    const {
      detailSubDataType,
      departments: { data = {} } = {},
      enforceMask,
      accountMask,
    } = this.props;
    const { items: departmentItems = [] } = data || {};
    return departmentItems.map(department => (
      <DepartmentItem
        params={{ id: department.id }}
        key={department.id}
        detailSubDataType={detailSubDataType}
        enforceMask={enforceMask}
        accountMask={accountMask}
      />
    ));
  },
});
