import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

const styles = {
  content: {
    display: 'grid',
    gridTemplateColumns: '49% 49%',
    gridTemplateRows: 'auto auto minmax(150px, auto) auto minmax(100px, auto)',
    gridTemplateAreas: `
      "name                 ........."
      "subject              ........."
      "emailBody            emailBody"
      "attachDoc            ........."
      "projectExplainer     projectExplainer"
    `,
    gridColumnGap: '2%',
    gap: '8px',
    gridGap: '8px',
    minHeight: 550,
  },
  name: {
    gridArea: 'name',
  },
  subject: {
    gridArea: 'subject',
  },
  emailBody: {
    gridArea: 'emailBody',
    marginTop: 10,
  },
  attachDoc: {
    gridArea: 'attachDoc',
  },
  projectExplainer: {
    gridArea: 'projectExplainer',
  },
};

const useEmail = upstreamEmail => {
  const [email, setEmail] = useState(upstreamEmail);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    setEmail(upstreamEmail);
    setErrors({});
  }, [upstreamEmail]);
  return { email, setEmail, errors, setErrors };
};

const EmailConfigurationDialog = props => {
  const {
    email: upstreamEmail,
    emails = [],
    isOpen,
    onClose,
    classes,
    save,
  } = props;
  const { email, setEmail, errors, setErrors } = useEmail(upstreamEmail);
  const {
    name = '',
    emailSubject = '',
    emailContent = '',
    emailExplainer = '',
    emailAttached = false,
    id,
  } = email || {};

  const updateEmail = key => ({ target: { value } }) => {
    setEmail({
      ...email,
      [key]: value,
    });
    setErrors({
      ...errors,
      [key]: '',
    });
  };

  const toggleEmailAttached = ({ target: { checked } }) =>
    setEmail({
      ...email,
      emailAttached: checked,
    });

  const onFocus = () => {
    if (name.toLowerCase().includes('new email notification')) {
      setEmail({
        ...email,
        name: '',
      });
    }
  };

  const validateAndSave = () => {
    let errors = {};
    if (!name.length) {
      errors = {
        ...errors,
        name: 'Email name is required.',
      };
    }
    if (!emailSubject.length) {
      errors = {
        ...errors,
        emailSubject: 'Email subject is required.',
      };
    }
    if (!emailContent.length) {
      errors = {
        ...errors,
        emailContent: 'Email body is required.',
      };
    }
    if (
      emails.some(
        e => e.name.toLowerCase() === name.toLowerCase() && id !== e.id,
      )
    ) {
      errors = {
        ...errors,
        name: 'Duplicate email names are not allowed.',
      };
    }
    if (Object.keys(errors).length) {
      return setErrors(errors);
    }
    save(email, 'emailDialogOpen');
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>Configure Email Notification</DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <TextField
          variant="standard"
          label="Name"
          className={classes.name}
          value={name}
          onChange={updateEmail('name')}
          onFocus={onFocus}
          FormHelperTextProps={{
            'data-test-id': 'EmailConfigurationDialog-nameHelperText',
          }}
          inputProps={{
            'data-test-id': `EmailConfigurationDialog-emailNameInput`,
          }}
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          variant="standard"
          label="Subject"
          className={classes.subject}
          value={emailSubject}
          onChange={updateEmail('emailSubject')}
          FormHelperTextProps={{
            'data-test-id': 'EmailConfigurationDialog-subjectHelperText',
          }}
          inputProps={{
            'data-test-id': `EmailConfigurationDialog-emailSubjectInput`,
          }}
          error={!!errors.emailSubject}
          helperText={errors.emailSubject}
        />
        <TextField
          label="Email Body"
          className={classes.emailBody}
          value={emailContent}
          onChange={updateEmail('emailContent')}
          multiline
          variant="outlined"
          rows={10}
          InputLabelProps={{
            shrink: true,
          }}
          FormHelperTextProps={{
            'data-test-id': 'EmailConfigurationDialog-bodyHelperText',
          }}
          inputProps={{
            'data-test-id': `EmailConfigurationDialog-emailContentInput`,
          }}
          error={!!errors.emailContent}
          helperText={errors.emailContent}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={emailAttached}
              onChange={toggleEmailAttached}
              data-test-id="EmailConfigurationDialog-attachEmailCheckbox"
              inputProps={{
                'data-test-id':
                  'EmailConfigurationDialog-attachEmailCheckboxInput',
              }}
            />
          }
          className={classes.attachDoc}
          label="Attach this document"
        />
        <TextField
          label="Project Details"
          className={classes.projectExplainer}
          value={emailExplainer}
          onChange={updateEmail('emailExplainer')}
          multiline
          variant="outlined"
          rows={5}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            'data-test-id': `EmailConfigurationDialog-emailProjectExplainerInput`,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
          data-test-id={`EmailConfigurationDialog-cancelButton`}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={validateAndSave}
          data-test-id="EmailConfigurationDialog-saveButton"
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(EmailConfigurationDialog);
