import React from 'react';
import { withStyles } from '@mui/styles';
import { Checkbox as MUICheckbox } from '@mui/material';
import { CheckBox as IconCheckBox } from '@mui/icons-material';

const styles = {
  checkbox: {
    color: '#0000FF',
  },
};

const Checkbox = props => {
  const { classes } = props;

  const checkIcon = <IconCheckBox className={classes.checkbox} />;

  return <MUICheckbox {...props} checkedIcon={checkIcon} />;
};

export default withStyles(styles)(Checkbox);
