import React, { Component } from 'react';

import AddressSection from './AddressSection';

import HomeIcon from 'common/icons/Home';
import MailBoxIcon from 'common/icons/MailBox';
import W2Icon from 'common/icons/W2';

import { withStyles } from '@mui/styles';

const styles = () => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto auto auto',
    gridTemplateColumns: '100%',
    gridGap: '35px',
    gap: '35px',
    margin: '30px 0',
  },
});

class Addresses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primaryAddress: null,
    };
  }

  render() {
    const { onChange, params, profileMutator, states, classes } = this.props;
    return (
      <div className={classes.root}>
        <AddressSection
          onChange={address => onChange(address, 'primary_address')}
          states={states}
          onSave={primaryAddress => this.setState({ primaryAddress })}
          params={params}
          profileMutator={profileMutator}
          required={true}
          subTitle="Where do you legally reside?"
          title="Primary Address"
          type="primary_address"
          icon={HomeIcon}
        />
        <AddressSection
          onChange={address => onChange(address, 'w2_address')}
          states={states}
          params={params}
          primaryAddress={this.state.primaryAddress}
          profileMutator={profileMutator}
          required={true}
          subTitle="Where would you like your W-2 to be mailed?"
          title="W-2 Address"
          type="w2_address"
          icon={W2Icon}
        />
        <AddressSection
          onChange={address => onChange(address, 'mailing_address')}
          states={states}
          params={params}
          primaryAddress={this.state.primaryAddress}
          profileMutator={profileMutator}
          subTitle="Where would you like us to send your mail?"
          title="Mailing Address"
          type="mailing_address"
          icon={MailBoxIcon}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Addresses);
