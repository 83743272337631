import React from 'react';
import moment from 'moment';
import { Button, Chip, Grid, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import getSSOUrl from 'common/constants/config/ssoUrl';

const LoanoutCorporation = props => {
  const { classes, corporation = {}, offerId, isCanada, offer } = props;
  const {
    status,
    name,
    taxId,
    type,
    state,
    city,
    zip,
    address,
    gstNumber,
    w9: { date: w9DateRaw } = {},
  } = corporation;

  const isOfferStatusSent = offer?.status === 'sent';

  const offerStatus = offer?.previous_status;
  const statusRegex = new RegExp(/^(approved|submitted)$/);
  const isApprovedOrSubmitted = statusRegex.test(offerStatus);

  const isMissing = status === 'missing';
  const isExpired = status === 'expired';
  const isValid = status === 'valid';
  const isPending = status === 'pending';
  const workerType = offer?.worker_type;

  let loanoutUrl = `${getSSOUrl()}/offer/loanout?offerId=${offerId}`;
  if (workerType) loanoutUrl += `&workerType=${workerType}`;
  const w9Date = w9DateRaw ? moment(w9DateRaw).format('MMM. D, YYYY') : null;

  const subTitleClasses = [classes.subTitle];
  if (isMissing || isExpired) subTitleClasses.push(classes.subTitleError);

  const itemValueClasses = [classes.itemValue];
  if (isExpired) itemValueClasses.push(classes.itemValueExpired);

  const statusChipClasses = [classes.statusChip];
  if (isExpired) statusChipClasses.push(classes.statusChipExpired);
  if (isValid) statusChipClasses.push(classes.statusChipValid);
  if (isPending) statusChipClasses.push(classes.statusChipPending);

  const items = {
    'FEIN': taxId, // prettier-ignore
    'Entity Name': name,
    'Entity Type': type,
    'Entity Address': (
      <div>
        {address && (
          <>
            {address}
            <br />
          </>
        )}
        {city && (
          <>
            {city}
            {state && <span>, {state}</span>}
            <br />
          </>
        )}
        {zip}
      </div>
    ),
  };
  if (isCanada) {
    items['GST/HST Number'] = gstNumber;
    items['Entity Type'] = '';
  } else {
    items['W-9'] = w9Date ? `last submitted ${w9Date}` : null;
  }

  return (
    <div>
      <Typography className={subTitleClasses}>
        {(isMissing || isExpired) && (
          <WarningIcon
            data-test-id="HiringProfile-subtitle-warning-icon"
            size="small"
            className={classes.subWarningIcon}
          />
        )}
        Loan-out Profile
      </Typography>
      {isMissing && (
        <>
          <Typography className={classes.topCopy}>
            You must register your Loan-out corporation before you can accept
            this offer as a Loan-out crew member. Otherwise, reject this offer
            and request to be paid as {isCanada ? 'an Individual' : 'a W-2'}{' '}
            crew member.
          </Typography>
          <Button
            data-test-id="HiringProfile-add-loanout-corp-btn"
            className={classes.corpAddButton}
            href={loanoutUrl}
            variant="contained"
            size="large"
            disableElevation
          >
            Add Loan-Out
          </Button>
        </>
      )}
      {!isMissing && (
        <>
          <Typography className={classes.topCopy}>
            Ensure your Loan-out profile is up-to date and has &quot;Valid&quot;
            {isCanada && <> or &quot;Pending&quot;</>} as its status.
          </Typography>
          {(isOfferStatusSent || !isApprovedOrSubmitted) && (
            <Typography className={classes.editLink}>
              <a href={loanoutUrl} className={classes.editLink}>
                Edit Loan-out
              </a>
            </Typography>
          )}
          <Grid container className={classes.item}>
            {Object.keys(items).map(label => (
              <Grid container key={`loanout-item-${label}`}>
                <Grid item xs={4} className={classes.itemName}>
                  {label}
                </Grid>
                <Grid
                  data-test-id={`HiringProfile-CorpData-${label}`}
                  item
                  xs={8}
                  className={itemValueClasses}
                >
                  {items[label]}
                </Grid>
              </Grid>
            ))}
            <Grid item xs={4} className={classes.itemName}>
              Status
            </Grid>
            <Grid item xs={8} className={classes.itemValue}>
              <Chip
                data-test-id="HiringProfile-loanout-corp-status"
                size="small"
                className={statusChipClasses}
                label={status}
              />
              {isExpired && (
                <Button
                  data-test-id="HiringProfile-re-verify-loanout-corp"
                  href={loanoutUrl}
                  className={classes.corpReverifyButton}
                  variant="outlined"
                  size="small"
                >
                  Re-verify Loan-out
                </Button>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default LoanoutCorporation;
