import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import updateFieldLayout from './updateFieldLayout';
import classNames from 'classnames';

const styles = () => ({
  root: {
    margin: 'auto',
    position: 'relative',
  },
});

class FieldsOverlay extends Component {
  getFilteredFields = () => {
    // Get the list of fields for the current document and page
    const { currentDocument, page } = this.props;
    const { fields = [] } = currentDocument;
    // Filtered Field which are found in new page or
    // radio group fields from different page have group items in new page.
    return fields.filter(
      ({ page: fieldPage, radioGroupPages: radioPages }) =>
        fieldPage === page || (!!radioPages && radioPages.includes(page)),
    );
  };

  renderFields = () => {
    const { currentDocument, zoom, renderField, page } = this.props;
    const { height, width } = currentDocument;
    const filteredFields = this.getFilteredFields();

    return filteredFields.map(field => {
      // Set field page to current page. This will help to find radioGroupItems
      // when they are split between multiple pages even though main field
      // is present in first page.
      field = { ...field, page };
      const pageDimensions = {
        originalDimensions: { height, width },
        updatedDimensions: {
          height: height * zoom,
          width: width * zoom,
        },
      };
      const fieldParams = {
        ...field,
        ...updateFieldLayout(pageDimensions, field),
      };

      return renderField(fieldParams);
    });
  };

  render() {
    const { classes, zoom, currentDocument, className } = this.props;
    const { height, width } = currentDocument;

    return (
      <div
        className={classNames(classes.root, className)}
        style={{
          height: height * zoom,
          width: width * zoom,
          marginTop: -(height * zoom),
        }}
      >
        {this.renderFields()}
      </div>
    );
  }
}

export default withStyles(styles)(FieldsOverlay);
