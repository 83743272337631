// This component has two modes depending on what type of children it is passed.
// If it is given a React tree, the tree will be rendered if & only if the
// current project is a US project.
// If it is given a function, it will call that function with a single boolean
// argument indicating whether the current project is a US project.
import React from 'react';
import SpecifiedCountryOnly from './SpecifiedCountryOnly';

const UsaOnly = ({ children }) => (
  <SpecifiedCountryOnly countryCode="US">{children}</SpecifiedCountryOnly>
);

export default UsaOnly;
