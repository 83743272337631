import React from 'react';
import Typography from '@mui/material/Typography';
import UnionTable from './UnionTermsOfEmploymentTable';
import USUnionTable from './USTermsOfEmployment/RateTable';
import USUnionWeekTable from './USUnionWeeklyTermsOfEmployment/WeeklyRateTable';
import USWeeklyOnCall from './USWeeklyOnCall/WeeklyRateTable';
import ExemptTable from './ExemptTermsOfEmploymentTable';
import DefaultTable from './DefaultTermsOfEmploymentTable';
import EmptyMessage from '../EmptyMessage';
import Loader from 'common/components/Loader';
import { withStyles } from '@mui/styles';
import WarningIcon from '@mui/icons-material/Warning';
import TableChartIcon from '@mui/icons-material/TableChartOutlined';
import classNames from 'class-names';
import NonUnionRateTable from './NonUnionRateTable';
import CanadaRateTable from './CanadaTermsOfEmployment/RateTable';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import NegotiatedContractTerms from './NegotiatedContractTerms';
import isNonUnionWorkScheduleA from 'common/utilities/isNonUnionWorkScheduleA';
import Feature from 'common/components/Feature';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';
import SAGTable from './SAGTermsOfEmployment';

const styles = theme => ({
  root: {
    gridTemplateColumns: '100%',
    gridTemplateRows: 'max-content 30px 1fr',
  },
  rootWithMessage: {
    minHeight: '300px',
  },
  header: {
    gridColumn: 1,
    gridRow: 1,
  },
  tableRoot: {
    gridColumn: 1,
    gridRow: 3,
  },
  loader: {
    gridColumn: 1,
    gridRow: 3,
  },
  disabledCell: {
    background: 'hsl(0,0%,95%)',
    color: 'rgb(153, 153, 153)',
  },
  headerCell: {
    fontSize: '1.1125rem',
    padding: '0px 24px',
  },
  labelCell: {
    fontSize: '1.0125rem',
  },
  border: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  errorCell: {
    borderLeft: `2px solid ${theme.palette.error.main}`,
  },
  errorLabel: {
    paddingLeft: 8,
    fontSize: '1rem',
  },
  cellWithTextField: {
    padding: '2px 24px',
  },
  textField: {
    width: '100%',
  },
  idlePayLabel: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto 1fr',
    gap: '10px',
    placeItems: 'center start',
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 'normal',
  },
  warningIcon: {
    color: '#ff0000',
    marginRight: '5px',
  },
  description: {
    color: '#6D7278',
    fontSize: 12,
  },
});

// Scale rate warning messages
const NO_SCALE_RATE_MESSAGE =
  "There's no minimum scale rate for this job title.";
const MISSING_SCALE_RATE_MESSAGE =
  "Sorry, we're not finding the scale rate. " +
  'Please check with your C&C Payroll Coordinator.';
const MISSING_SCALE_RATE_MESSAGE_WEEKLY =
  'No scale rate found for this occupation. ' +
  'Please enter the negotiated rate and hours to continue';
const SAG_NO_SCALE_RATE_MESSAGE = 'No scale rate found.';

const getScaleRatesWarning = (
  formData,
  scaleRates,
  isUnionHourlyGuaranteedWeekly,
  isCanada,
  isNonUnion,
  isSAGUnion,
) => {
  const onlyRates = {
    ...scaleRates,
    __typename: null,
  };

  // Just need rates not the hours that come back from scale rates api
  const onlyRatesNoHours = (({ rate, rateDistant }) => ({
    rate,
    rateDistant,
  }))(onlyRates);

  // Checks if every key in the onlyRatesNoHours object are 0
  const areScaleRatesZero = Object.keys(onlyRatesNoHours).every(k => {
    return parseInt(onlyRatesNoHours[k], 10) === 0;
  });

  // return no scale rate message is rates are 0
  if (areScaleRatesZero) return NO_SCALE_RATE_MESSAGE;

  let isScaleRate = Object.keys(onlyRates).some(k => {
    return parseInt(onlyRates[k], 10) > 0;
  });
  if (isScaleRate) return;
  const pickedFormData = (({ rate, studioRatePerHour }) => ({
    rate,
    studioRatePerHour,
  }))(formData);

  const isFormRatesPresent = Object.keys(pickedFormData).some(k => {
    return parseInt(pickedFormData[k], 10) > 0;
  });
  if (isFormRatesPresent) return;

  // No scale rates (rates are zero)
  const isNoScaleRates = isScaleRatesZero(scaleRates);
  if (isNoScaleRates) return NO_SCALE_RATE_MESSAGE;

  // No scale rates for SAG
  if (isSAGUnion) return SAG_NO_SCALE_RATE_MESSAGE;

  if (isCanada && isNonUnion) return;
  // Missing scale rates (rates are null)
  return isUnionHourlyGuaranteedWeekly
    ? MISSING_SCALE_RATE_MESSAGE_WEEKLY
    : MISSING_SCALE_RATE_MESSAGE;
};

const isScaleRatesZero = scaleRates => {
  const onlyRates = {
    ...scaleRates,
    __typename: null,
  };
  return Object.keys(onlyRates).every(k => {
    return parseInt(onlyRates[k], 10) === 0;
  });
};

const TermsOfEmployment = props => {
  const {
    onChange: upstreamOnChange,
    isScaleRatesLoading,
    scaleRates,
    termsOfEmploymentSettings = {},
    formData,
    formErrors,
    classes,
    isReviewOffer,
    isContractsLoading,
    isTermsSettingsLoading,
    contracts = [],
    isCanada,
    canSelectScaleRates,
    isGuaranteedHours,
    isGuaranteedDistantHours,
    handleSixthSeventhRatetableDisplay,
  } = props;

  const {
    union,
    occupation,
    workSchedule,
    startDate,
    workState,
    hireState,
    negotiatedContract,
    payAtRollback,
  } = formData || {};

  const { value: unionCode = null, isNonUnion = false } = union || {};
  const { value: workScheduleCode = null } = workSchedule || {};
  const { value: occupationCode = null } = occupation || {};
  const isUnion = !isNonUnion;
  const isExempt = workScheduleCode === 'C' || workScheduleCode === 'D';
  const isTermsOfHireFilledIn = ![
    startDate,
    workState,
    hireState,
    unionCode,
    occupationCode,
    workScheduleCode,
    canSelectScaleRates ? payAtRollback : true,
  ].includes(null);
  const flags = useFeatureFlags();
  const isNonUnionScheduleFlagActiveA = flags.includes('nonUnionSchedules');
  const isNonUnionScheduleFlagActiveC = flags.includes('nonUnionScheduleC');
  const isNonUnionScheduleFlagActiveB = flags.includes('nonUnionScheduleB');
  const isUnionWeeklyOnCallFlagActive = flags.includes('UnionWeeklyOnCall');

  const {
    unionHourlyDaily: isUnionGuaranteedDailyHours,
    unionHourlyWeekly: isUnionHourlyGuaranteedWeekly,
    unionDaily: isUnionDaily,
    unionWeekly: isUnionWeekly,
    unionSagDaily,
    unionSagWeekly,
    unionSagContract,
  } = termsOfEmploymentSettings;

  const isEmptyPayrollCategories =
    !isUnionGuaranteedDailyHours &&
    !isUnionHourlyGuaranteedWeekly &&
    !isUnionDaily &&
    !isUnionWeekly;

  const showNonUnionTableA =
    isNonUnion &&
    isNonUnionWorkScheduleA(workScheduleCode) &&
    isNonUnionScheduleFlagActiveA;
  const showNonUnionTableC =
    isNonUnion &&
    ['C', 'E', 'N'].includes(workScheduleCode) &&
    isNonUnionScheduleFlagActiveC;
  const showNonUnionTableB =
    isNonUnion &&
    ['B'].includes(workScheduleCode) &&
    isNonUnionScheduleFlagActiveB;
  const showNonUnionTableD =
    isNonUnion &&
    ['D'].includes(workScheduleCode) &&
    isNonUnionScheduleFlagActiveB;

  const isNonUnionActive =
    showNonUnionTableA ||
    showNonUnionTableC ||
    showNonUnionTableB ||
    showNonUnionTableD;

  const tableClasses = {
    root: classes.tableRoot,
    disabledCell: classes.disabledCell,
    headerCell: classes.headerCell,
    labelCell: classes.labelCell,
    errorCell: classes.errorCell,
    errorLabel: classes.errorLabel,
    border: classes.border,
    cellWithTextField: classes.cellWithTextField,
    textField: classes.textField,
    textFieldInput: classes.textFieldInput,
    idlePayLabel: classes.idlePayLabel,
    cellWithTextFieldClass: classNames(
      classes.border,
      classes.cellWithTextField,
    ),
    labelCellClass: classNames(classes.border, classes.labelCell),
  };

  const rootClass = classNames(classes.root, {
    [classes.rootWithMessage]: !isTermsOfHireFilledIn,
  });

  const isSAGUnion = unionSagDaily || unionSagWeekly || unionSagContract;

  const isScaleRatesWarning = getScaleRatesWarning(
    formData,
    scaleRates,
    isUnionHourlyGuaranteedWeekly,
    isCanada,
    isNonUnion,
    isSAGUnion,
  );

  const showLoader =
    isScaleRatesLoading || isContractsLoading || isTermsSettingsLoading;
  const showEmptyMessage = !showLoader && !isTermsOfHireFilledIn;
  const scaleRatesWarning = !showEmptyMessage && isScaleRatesWarning;
  const showTable = !showEmptyMessage && !showLoader;
  const showScaleRatesWarning = !!scaleRatesWarning && showTable;
  const isDefaultUnionTable =
    isUnionDaily ||
    (isEmptyPayrollCategories && isUnion) ||
    (isUnionWeekly && isUnion && !isUnionWeeklyOnCallFlagActive);

  const isUnionWeeklyOnCall =
    isUnionWeekly && isUnion && isUnionWeeklyOnCallFlagActive;

  const TableComponent =
    (isUnionGuaranteedDailyHours && USUnionTable) ||
    (isUnionHourlyGuaranteedWeekly && USUnionWeekTable) ||
    (isNonUnionActive && NonUnionRateTable) ||
    (isDefaultUnionTable && UnionTable) ||
    (isUnionWeeklyOnCall && USWeeklyOnCall) ||
    (isExempt && ExemptTable) ||
    DefaultTable;

  return (
    <div className={rootClass}>
      <Typography className={classes.header} variant="h5">
        Terms of Employment
        <Typography variant="subtitle1" className={classes.description}>
          {isReviewOffer
            ? 'Review the rates for your crew member'
            : 'Set the rates for your crew members'}
        </Typography>
      </Typography>
      {Array.isArray(contracts) && (
        <NegotiatedContractTerms
          onChange={upstreamOnChange}
          negotiatedContract={negotiatedContract}
          contracts={contracts}
          formErrors={formErrors}
        />
      )}
      {showLoader && <Loader className={classes.loader} />}
      {showEmptyMessage && (
        <EmptyMessage
          classes={tableClasses}
          icon={TableChartIcon}
          text={
            'Note that this table populates ' +
            'after you complete the Terms of Hire'
          }
        />
      )}
      {showScaleRatesWarning && (
        <Typography
          className={classes.errorMessage}
          variant="subtitle1"
          data-test-id="TermsOfEmployment-scaleRatesWarning"
        >
          <WarningIcon className={classes.warningIcon} />
          {scaleRatesWarning}
        </Typography>
      )}
      <UsaOnly>
        {showTable && !isSAGUnion && (
          <TableComponent
            classes={tableClasses}
            formData={formData}
            formErrors={formErrors}
            onChange={upstreamOnChange}
            workScheduleCode={workScheduleCode}
            scaleRates={scaleRates}
            contracts={contracts}
            isContractsLoading={isContractsLoading}
            unionCode={unionCode}
            isGuaranteedHours={isGuaranteedHours}
            isGuaranteedDistantHours={isGuaranteedDistantHours}
            handleSixthSeventhRatetableDisplay={
              handleSixthSeventhRatetableDisplay
            }
          />
        )}
      </UsaOnly>
      {showTable && !isSAGUnion && (
        <Feature name="prcanRateTable">
          <CanadaOnly>
            <CanadaRateTable
              classes={tableClasses}
              formData={formData}
              formErrors={formErrors}
              onChange={upstreamOnChange}
              workScheduleCode={workScheduleCode}
              isReviewOffer={isReviewOffer}
            />
          </CanadaOnly>
        </Feature>
      )}
      {showTable && isSAGUnion && (
        <SAGTable
          formData={formData}
          termsOfEmploymentSettings={termsOfEmploymentSettings}
          formErrors={formErrors}
          onChange={upstreamOnChange}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(TermsOfEmployment);
