export default {
  each: function(collection = [], iterator) {
    var i = 0;
    var len = collection.length;
    var res = null;

    for (; i < len; i += 1) {
      res = iterator.call(null, i, collection[i]);
      if (res === false) break;
    }
  },

  map: function(collection, fn, context) {
    var newCollection = [];

    context = context || null;

    this.each(collection, function(i, item) {
      newCollection.push(fn.call(context, item, i));
    });

    return newCollection;
  },

  select: function(collection, fn) {
    var newCollection = [];

    this.each(collection, function(i, item) {
      if (fn.call(null, item, i)) {
        newCollection.push(item);
      }
    });

    return newCollection;
  },

  find: function(collection, fn) {
    var matched = null;

    this.each(collection, function(_, item) {
      if (fn.call(null, item)) {
        matched = item;
        return false;
      }
    });

    return matched;
  },

  reduce: function(collection, acc, fn) {
    this.each(collection, function(index, item) {
      acc = fn.call(null, acc, item, index);
    });

    return acc;
  },

  eachInRange: function(start, end, fn) {
    var i = start;
    var inc = start < end ? 1 : -1;

    for (; i !== end; i += inc) {
      fn.call(null, i);
    }
  },

  any: function(collection, fn) {
    var any = false;

    this.each(collection, function(_, item) {
      if (fn.call(null, item)) {
        any = true;
        return false;
      }
    });

    return any;
  },

  all: function(collection, fn) {
    var all = true;

    this.each(collection, function(_, item) {
      if (!fn.call(null, item)) {
        all = false;
        return false;
      }
    });

    return all;
  },

  isArray: function(obj) {
    return Array.isArray(obj);
  },

  clone: function(collection) {
    return collection.slice();
  },

  deleteAt: function(collection, index) {
    var newCollection = this.clone(collection);

    newCollection.splice(index, 1);

    return newCollection;
  },

  replace: function(oldCollection, newCollection) {
    var args = this.clone(newCollection);
    args.unshift(0, oldCollection.length);
    oldCollection.splice.apply(oldCollection, args);
    return oldCollection;
  },

  sortBy: function(collection, fn) {
    return this.clone(collection).sort(fn);
  },

  indexOf: function(collection, fn) {
    var elementIndex = -1;

    this.each(collection, function(index, item) {
      if (fn.call(null, item)) {
        elementIndex = index;
        return false;
      }
    });

    return elementIndex;
  },

  subtract: function(minuend, subtrahend) {
    return this.select(minuend, function(_, index, item) {
      return subtrahend.indexOf(item) === -1;
    });
  },
};
