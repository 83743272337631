import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const QUERY = gql`
  mutation sendAdditionalDocoument(
    $offerIds: [Int!]!
    $documentsIds: [Int!]!
    $documentFields: [OfferDocumentFieldInput!]!
    $note: String!
  ) {
    sendAdditionalDocuments(
      offerIds: $offerIds
      documentsIds: $documentsIds
      documentFields: $documentFields
      note: $note
    )
  }
`;

const useSendAdditionalDocuments = () => {
  const [sendAdditionalDocuments, params] = useMutation(QUERY);
  return [sendAdditionalDocuments, params];
};

export default useSendAdditionalDocuments;
