import React from 'react';

// HoC
import { withStyles } from '@mui/styles';
// Component
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';

const styles = theme => ({
  root: {},
  error: {
    color: '#f44336',
  },
  inputLabel: {
    position: 'relative !important',
  },
});

const CheckboxInput = props => {
  const { onChange, value, classes = {}, fieldName, error } = props || {};

  return (
    <>
      <FormGroup row classes={{ root: classes.root }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              onChange={({ target: { checked } = {} } = {}) =>
                onChange(checked)
              }
              data-test-id={`CheckboxInput-${fieldName}`}
            />
          }
          label={fieldName}
        />
      </FormGroup>
      {error && (
        <FormHelperText className={classes.error}>{error}</FormHelperText>
      )}
    </>
  );
};

export default withStyles(styles)(CheckboxInput);
