const CONSTANT_TERM = 0.003569895;

const adjustmentFunctions = {
  x: {
    TXT: pageWidth =>
      2.40704902 * 10 ** -9 * pageWidth ** 2 +
      1.159799175 * 10 ** -3 * pageWidth +
      CONSTANT_TERM -
      1.5,
    NUMBER: pageWidth =>
      2.40704902 * 10 ** -9 * pageWidth ** 2 +
      1.159799175 * 10 ** -3 * pageWidth +
      CONSTANT_TERM -
      1.5,
    CHK: pageWidth =>
      2.40704902 * 10 ** -9 * pageWidth ** 2 +
      1.159799175 * 10 ** -3 * pageWidth +
      CONSTANT_TERM,
    RDO: pageWidth =>
      2.40704902 * 10 ** -9 * pageWidth ** 2 +
      1.159799175 * 10 ** -3 * pageWidth +
      CONSTANT_TERM,
    BTN: pageWidth =>
      2.40704902 * 10 ** -9 * pageWidth ** 2 +
      1.159799175 * 10 ** -3 * pageWidth +
      CONSTANT_TERM,
    CMB: pageWidth =>
      2.40704902 * 10 ** -9 * pageWidth ** 2 +
      1.159799175 * 10 ** -3 * pageWidth +
      CONSTANT_TERM,
    DATE: pageWidth =>
      2.40704902 * 10 ** -9 * pageWidth ** 2 +
      1.159799175 * 10 ** -3 * pageWidth +
      CONSTANT_TERM -
      1.5,
    SIGNATURE: pageWidth =>
      2.40704902 * 10 ** -9 * pageWidth ** 2 +
      1.159799175 * 10 ** -3 * pageWidth +
      CONSTANT_TERM -
      1.5,
    INITIAL_SIGNATURE: pageWidth =>
      2.40704902 * 10 ** -9 * pageWidth ** 2 +
      1.159799175 * 10 ** -3 * pageWidth +
      CONSTANT_TERM -
      1.5,
    UNVALIDATED_SIGNATURE: pageWidth =>
      2.40704902 * 10 ** -9 * pageWidth ** 2 +
      1.159799175 * 10 ** -3 * pageWidth +
      CONSTANT_TERM -
      1.5,
    UNVALIDATED_INITIAL_SIGNATURE: pageWidth =>
      2.40704902 * 10 ** -9 * pageWidth ** 2 +
      1.159799175 * 10 ** -3 * pageWidth +
      CONSTANT_TERM -
      1.5,
  },
  y: {
    TXT: (fieldWidth, fieldHeight, pageHeight) => {
      if (fieldWidth / fieldHeight < 3) {
        return -fieldHeight * (pageHeight * 0.00142142408);
      }
      return -fieldHeight * (pageHeight * 0.00131989379);
    },
    NUMBER: (fieldWidth, fieldHeight, pageHeight) => {
      if (fieldWidth / fieldHeight < 3) {
        return -fieldHeight * (pageHeight * 0.00142142408);
      }
      return -fieldHeight * (pageHeight * 0.00131989379);
    },
    CHK: (fieldWidth, fieldHeight, pageHeight) =>
      -fieldHeight * (pageHeight * 0.00137065894),
    RDO: (fieldWidth, fieldHeight, pageHeight) =>
      -fieldHeight * (pageHeight * 0.00203060583),
    BTN: (fieldWidth, fieldHeight, pageHeight) => -(pageHeight * 0.02842848172),
    CMB: (fieldWidth, fieldHeight, pageHeight) =>
      -fieldHeight * (pageHeight * 0.00131989379),
    DATE: (fieldWidth, fieldHeight, pageHeight) => {
      if (fieldWidth / fieldHeight < 3) {
        return -fieldHeight * (pageHeight * 0.00142142408);
      }
      return -fieldHeight * (pageHeight * 0.00131989379);
    },
    SIGNATURE: (fieldWidth, fieldHeight, pageHeight) =>
      -fieldHeight * (pageHeight * 0.00131989379),
    INITIAL_SIGNATURE: (fieldWidth, fieldHeight, pageHeight) =>
      -fieldHeight * (pageHeight * 0.00131989379),
    UNVALIDATED_SIGNATURE: (fieldWidth, fieldHeight, pageHeight) =>
      -fieldHeight * (pageHeight * 0.00131989379),
    UNVALIDATED_INITIAL_SIGNATURE: (fieldWidth, fieldHeight, pageHeight) =>
      -fieldHeight * (pageHeight * 0.00131989379),
  },
};

export const getXModifier = (pageWidth, fieldType, fieldWidth, fieldHeight) =>
  adjustmentFunctions.x[fieldType](pageWidth);

export const getYModifier = (pageHeight, fieldType, fieldWidth, fieldHeight) =>
  adjustmentFunctions.y[fieldType](fieldWidth, fieldHeight, pageHeight);

const adjustFieldPositioning = (pageDimensions, field) => {
  const { width, height } = pageDimensions;
  const { fieldType, x, y, width: fieldWidth, height: fieldHeight } = field;
  return {
    x: x + getXModifier(width, fieldType, fieldWidth, fieldHeight),
    y: y + getYModifier(height, fieldType, fieldWidth, fieldHeight),
  };
};

export default adjustFieldPositioning;
