// common functions for SendAdditionalDocuments component

const questionsCheck = documents => {
  const checkedDocuments = documents.filter(({ checked }) => checked);
  return checkedDocuments.some(
    ({ inputFieldGroups }) => inputFieldGroups.length > 0,
  );
};

const checkedDocumentsIds = documents =>
  documents.filter(doc => doc.checked).map(({ id }) => id);

export default {
  questionsCheck,
  checkedDocumentsIds,
};
