import React, { Component } from 'react';
import { withStyles } from '@mui/styles';

// Utitlities
import getSSOUrl from 'common/constants/config/ssoUrl';

// HoC
import { compose } from 'redux';
import { connect } from 'react-redux';
import withApi from 'common/hoc/withApi';

// Actions
import { logout } from 'common/store/actions/authenticate';

// Components
import Dropdown from '../../../Base/Dropdown';

const styles = theme => ({
  profileLink: {
    textDecoration: 'none',
  },
});

class DropdownSession extends Component {
  static queries = {
    me: {
      info() {
        return {
          id: '/me',
        };
      },
    },
    profile: {
      info: function() {
        return {
          id: '/account/profile',
        };
      },
    },
  };

  goToMyCnC = () => {
    window.location.href = getSSOUrl();
  };

  openUniversity = () => {
    window.open('https://edge.castandcrew.com/learning/cc ', '_blank');
  };

  openTraining = () => {
    window.open('https://support.castandcrew.com/', '_blank');
  };

  requestLogout = () => {
    const { logout } = this.props;
    logout();
  };

  render() {
    const { classes, me: meQuery = {}, profile } = this.props;
    const { data: { is_employee: isEmployee } = {} } = profile || {};
    const { data: me = {}, status: meStatus } = meQuery || {};
    const {
      is_update_password_required: isUpdatePasswordRequired,
      is_password_expired: isPasswordExpired,
      first_name: firstName = '',
      last_name: lastName = '',
      email,
    } = me || {};
    if (meStatus === 'loading') return null;
    const dropDownItems = () => {
      const basicItemConfig = [
        {
          label: 'MyCast&Crew',
          onClick: this.goToMyCnC,
        },
        {
          label: 'Help Center',
          onClick: this.openTraining,
        },
        {
          label: 'C&C Edge Learning',
          onClick: this.openUniversity,
        },
        {
          label: 'Sign Out',
          onClick: this.requestLogout,
        },
      ];
      const profileUrl = isEmployee ? `${getSSOUrl()}/profile` : '/profile';

      const originalItems = [
        <Dropdown.Item key="profile-container" container={true}>
          <Dropdown.Title secondary={true}>{email}</Dropdown.Title>
        </Dropdown.Item>,
      ];

      const dynamicItems = () => {
        const items = [];
        if (!isUpdatePasswordRequired && !isPasswordExpired) {
          items.push(
            <Dropdown.Item key="profile">
              <a href={profileUrl} className={classes.profileLink}>
                Profile
              </a>
            </Dropdown.Item>,
          );
        }
        if (isEmployee) {
          items.push(
            <Dropdown.Item key="notifications">
              <a href="/notifications" className={classes.profileLink}>
                Notifications
              </a>
            </Dropdown.Item>,
          );
        }
        return items;
      };

      const basicItems = basicItemConfig.map(({ label, onClick }) => (
        <Dropdown.Item key={label} onClick={onClick}>
          {label}
        </Dropdown.Item>
      ));

      return [...originalItems, ...dynamicItems(), ...basicItems];
    };
    return (
      <Dropdown
        title={[firstName, lastName].join(' ')}
        avatarText={[firstName, lastName].join('')}
        header={true}
        data-test-id="DropdownSession-root"
      >
        {dropDownItems()}
      </Dropdown>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  logout,
};

export default compose(
  withApi,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(DropdownSession);
