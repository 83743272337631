import Flexbox from 'flexbox-react';

import React from 'react';
import { createOfferCurrency as currency } from '../../../utils/format';

import ExpandableTable from 'common/oldJavascripts/components/Base/ExpandableTable';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

const Row = ExpandableTableRow;
const Cell = ExpandableTableCell;

const TABLE_HEADER = ['', 'Rate per Hour', 'Rate per Week', 'Guaranteed Hours']
  .map((th, index) => (
    <Cell header={true} key={`union-header-${index}`}>
      {th}
    </Cell>
  ))
  .concat([
    <Cell colSpan={2} header={true} key="union-header-no-idle">
      If Rate does not Include Idle Day (Hours)
    </Cell>,
    <Cell header={true} key="union-header-blank" />,
  ]);

class UnionTable extends React.Component {
  _getTableData() {
    const terms = this.props.offer.terms_of_employment || this.props.offer;
    return [
      [
        'Studio Rate',
        currency(terms.rate_per_hour_studio),
        currency(terms.rate_per_week_studio),
        terms.guaranteed_hours_studio,
        'Pay 6th Day At',
        'Pay 7th Day At',
        'Overtime Rate',
      ],
      [
        'Distant Rate',
        currency(terms.rate_per_hour_location),
        currency(terms.rate_per_week_location),
        terms.guaranteed_hours_location,
        currency(terms.pay_6th_day_rate),
        currency(terms.pay_7th_day_rate),
        currency(terms.overtime_rate),
      ],
    ];
  }

  render() {
    return (
      <Flexbox width="100%">
        <ExpandableTable containerClassName="rate-scale-table">
          <Row className="rate-scale-table-row">{TABLE_HEADER}</Row>
          {this._renderTableContent()}
        </ExpandableTable>
      </Flexbox>
    );
  }

  _renderTableContent() {
    return this._getTableData().map((td, index) => (
      <Row
        className={`rate-scale-table-row ${index % 2 ? 'evenrow' : 'oddrow'}`}
        key={`row-${index}`}
      >
        {this._renderTableContentRow(td, index)}
      </Row>
    ));
  }

  _renderTableContentRow(row, rowIndex) {
    return row.map((cell, cellIndex) => (
      <Cell key={`cell-${rowIndex}-${cellIndex}`}>{cell || '—'}</Cell>
    ));
  }
}

export default UnionTable;
