import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query termsSettings(
    $projectId: Int!
    $startDate: String!
    $workState: String!
    $hireState: String!
    $workCity: String
    $hireCity: String
    $union: String!
    $nonUnion: Boolean!
    $occupation: String!
    $workSchedule: String!
    $season: Int
    $currency: String!
    $offerId: Int
  ) {
    termsOfEmploymentSettings(
      projectId: $projectId
      startDate: $startDate
      workState: $workState
      hireState: $hireState
      workCity: $workCity
      hireCity: $hireCity
      union: $union
      nonUnion: $nonUnion
      occupation: $occupation
      workSchedule: $workSchedule
      season: $season
      currency: $currency
      offerId: $offerId
    ) {
      type
      types {
        nonUnionHourlyDaily
        nonUnionHourlyWeekly
        nonUnionDaily
        nonUnionWeekly
        unionHourlyDaily
        unionHourlyWeekly
        unionDaily
        unionWeekly
        unionSagDaily
        unionSagWeekly
        unionSagContract
      }
      scaleRatesSelect
    }
  }
`;

const useTermsOfEmploymentSettings = variables => {
  const {
    projectId,
    union,
    occupation,
    workSchedule,
    startDate,
    workState,
    hireState,
    currency,
  } = variables;

  const skip =
    !projectId ||
    !startDate ||
    !workState ||
    !hireState ||
    !currency ||
    !union ||
    !occupation ||
    !workSchedule;
  const { loading, data } = useQuery(QUERY, {
    skip,
    variables,
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    data: !skip && data && data?.termsOfEmploymentSettings,
  };
};

export default useTermsOfEmploymentSettings;
