import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query accountCodeConfigurations($projectId: Int!) {
    project(id: $projectId) {
      id
      accountCodeConfigurations {
        code
        dataType
        defaultValue
        id
        label
        mask
        required
      }
    }
  }
`;

const useAccountCodeConfigurations = projectId => {
  const { error, loading, data } = useQuery(QUERY, {
    skip: !projectId,
    // NOTE Project.id is listed in the schema as an ID! type, but the project
    // query takes a projectId of type Int! Obviously, this is wrong.
    variables: { projectId: parseInt(projectId) },
  });
  return { error, loading, data: data?.project.accountCodeConfigurations };
};

export default useAccountCodeConfigurations;
