import React from 'react';
import domClasses from '../../utils/dom/classes.js';
import DOMPurify from 'dompurify';

export default class extends React.Component {
  static defaultProps = {
    bold: false,
  };

  render() {
    var classes = null;

    classes = domClasses.set(this.props.className, {
      text: true,
      'text--blue': DOMPurify.sanitize(this.props.blue),
      'text--small': DOMPurify.sanitize(this.props.small),
      'text--faded': DOMPurify.sanitize(this.props.faded),
      'text--inline': DOMPurify.sanitize(this.props.inline),
      'text--alert': DOMPurify.sanitize(this.props.alert),
      'text--nowrap': DOMPurify.sanitize(this.props.nowrap),
      'text--amount': DOMPurify.sanitize(this.props.amount),
      'text--paragraph': DOMPurify.sanitize(this.props.paragraph),
      'text--comment': DOMPurify.sanitize(this.props.comment),
      'text--help': DOMPurify.sanitize(this.props.help),
      'text--bold': DOMPurify.sanitize(this.props.bold),
      'text--italic': DOMPurify.sanitize(this.props.italic),
      'text--big': DOMPurify.sanitize(this.props.big),
      'text--flex-vertical': DOMPurify.sanitize(this.props.flexVertical),
      'text--transform-uppercase': DOMPurify.sanitize(
        this.props.transform === 'uppercase',
      ),
      'text--ultra': DOMPurify.sanitize(this.props.ultra),
    });

    if (this.props.paragraph) {
      return (
        <p
          className={classes}
          title={DOMPurify.sanitize(this.props.title)}
          style={this.props.style}
          data-test-id={this.props['data-test-id']}
        >
          {this.props.children}
        </p>
      );
    } else {
      return (
        <span
          className={classes}
          title={DOMPurify.sanitize(this.props.title)}
          style={this.props.style}
          data-test-id={this.props['data-test-id']}
        >
          {this.props.children}
        </span>
      );
    }
  }
}
