import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import {
  Dialog,
  Grid,
  Button,
  Typography,
  ThemeProvider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import {
  CloseSharp as CloseIcon,
  FolderOpenOutlined as FolderIcon,
} from '@mui/icons-material';
import StudioPlusFolderSelectorModal from './StudioPlusFolderSelectorModal';
import ConditionSelector from './ConditionSelector';
import { documentSignerTheme } from 'common/shared/oldDocumentSignerUI/theme';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

const styles = theme => ({
  listStyle: {
    paddingLeft: 10,
    '& li': {
      marginBottom: 10,
    },
  },
  deleteDialogPaper: {
    padding: 18,
    minHeight: '120px',
    height: 'max-content',
    width: 400,
    maxHeight: '200',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  cancelButton: {
    marginTop: theme.spacing(1) + ' !important',
    marginLeft: theme.spacing(1) + ' !important',
    padding: '5px !important',
  },
  deleteButton: {
    marginTop: theme.spacing(1) + ' !important',
    marginLeft: theme.spacing(1) + ' !important',
    color: '#fff !important',
    backgroundColor: '#ea5959 !important',
    border: 'none !important',
    '&:hover': {
      backgroundColor: '#ff7f7f',
    },
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  directory: {
    marginLeft: 4,
    color: '#354054',
    fontWeight: 500,
  },
  actions: {
    marginLeft: '10px !important',
    cursor: 'pointer !important',
    fontWeight: '500 !important',
    fontSize: '1.1rem !important',
    color: '#155eef !important',
  },
});

const StudioPlusFolderList = props => {
  const {
    directories,
    selectedDirectories,
    onChange,
    setIsEnabled,
    projectTemplateId,
    templateFields,
    projectId,
    classes,
    sendProvisionalDealMemo,
  } = props;
  const [currentModalDirecoryId, setCurrentModalDirecoryId] = useState(null);
  const [selectedDirectoryIndex, setSelectedDirectoryIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);

  const flags = useFeatureFlags();
  const isSendProvisionalFlagOn = flags?.includes(
    'SendProvisionalDocumentsToStudioPlus',
  );

  // Given array of directories & a directoryId, returns the path to the directory
  // or null if the directory is not found.
  const getDirectoryPath = (directories, directoryId, path = '') => {
    // This is a bit clunky
    let p = null;
    directories.find(({ id, children, name }) => {
      const thisPath = `${path}/${name}`;
      if (id === directoryId) {
        p = thisPath;
        return true;
      }
      if (!(children && children.length)) return false;
      p = getDirectoryPath(children, directoryId, thisPath);
      return !!p;
    });
    // remove leading slash
    if (p && p.startsWith('/')) p = p.slice(1);
    return p;
  };

  const editDirectory = (directoryId, index) => {
    setCurrentModalDirecoryId(directoryId);
    setSelectedDirectoryIndex(index);
  };

  const deleteDirectory = () => {
    const updatedDirectories = [...selectedDirectories];
    updatedDirectories.splice(selectedDirectoryIndex, 1);
    if (updatedDirectories?.length === 0) {
      setIsEnabled(false);
    }
    onChange(updatedDirectories);
    setAlertModalOpen(false);
  };

  const changeSelectedDirectory = directory => {
    const updatedDirectories = [...selectedDirectories];
    updatedDirectories[selectedDirectoryIndex].directoryId = directory;
    onChange(updatedDirectories);
  };

  const onChangeCondition = (index, fieldId, value) => {
    const updatedDirectories = [...selectedDirectories];
    updatedDirectories[index].fieldId = fieldId;
    updatedDirectories[index].value = value;
    onChange(updatedDirectories);
  };

  const handleChange = (event, index) => {
    const updatedDirectories = [...selectedDirectories];
    updatedDirectories[index].sendWithProvisional = event.target.checked;
    onChange(updatedDirectories);
  };

  return (
    <ThemeProvider theme={documentSignerTheme}>
      <ul className={classes.listStyle}>
        {selectedDirectories.map(
          ({ directoryId, fieldId, value, sendWithProvisional }, index) => (
            <li key={index}>
              <Grid container alignItems="center">
                <Grid item>
                  <Grid container direction="row" alignItems="center">
                    <FolderIcon
                      sx={{
                        color: '#667085',
                        marginRight: '6px',
                        fontSize: '1.7rem',
                      }}
                    />
                    <Typography
                      data-test-id={`StudioPlusFolderSelector-path-${index}`}
                      sx={{ fontSize: '1.1rem', color: '#354054' }}
                    >
                      Destination folder:
                      <span className={classes.directory}>
                        {' '}
                        {getDirectoryPath(directories, directoryId)}
                      </span>
                    </Typography>
                    <Typography
                      data-test-id={`StudioPlusFolderSelector-edit-${index}`}
                      onClick={() => {
                        editDirectory(directoryId, index);
                        setModalOpen(true);
                      }}
                      color={'primary'}
                      className={classes.actions}
                    >
                      Edit
                    </Typography>
                    <Typography
                      data-test-id={`StudioPlusFolderSelector-delete-${index}`}
                      onClick={() => {
                        setSelectedDirectoryIndex(index);
                        setCurrentModalDirecoryId(directoryId);
                        setAlertModalOpen(true);
                      }}
                      color={'primary'}
                      className={classes.actions}
                    >
                      Remove
                    </Typography>
                  </Grid>
                  <ConditionSelector
                    projectId={projectId}
                    directoryId={directoryId}
                    templateId={projectTemplateId}
                    fieldId={fieldId}
                    value={value}
                    templateFields={templateFields}
                    directoryIndex={index}
                    onChange={onChangeCondition}
                  />
                  {isSendProvisionalFlagOn && sendProvisionalDealMemo && (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={sendWithProvisional}
                          onChange={e => handleChange(e, index)}
                          color="primary"
                          sx={{
                            color: '#d0d5de',
                            '&.Mui-checked': { color: '#1a54fd' },
                            '&:hover': { bgcolor: 'transparent' },
                          }}
                          data-test-id={`StudioPlusFolderProvisionalDeal-${index}`}
                        />
                      }
                      label="Send with provisional deal memo"
                      sx={{ marginLeft: '16px' }}
                    />
                  )}
                </Grid>
              </Grid>
            </li>
          ),
        )}
      </ul>
      <StudioPlusFolderSelectorModal
        directories={directories}
        directoryId={currentModalDirecoryId}
        open={modalOpen}
        onSelect={changeSelectedDirectory}
        onClose={() => {
          setModalOpen(false);
          setCurrentModalDirecoryId(null);
          setSelectedDirectoryIndex(null);
        }}
      />
      <Dialog
        open={alertModalOpen}
        maxWidth="xs"
        classes={{ paper: classes.deleteDialogPaper }}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">Delete Studio+ Destination</Typography>
            <CloseIcon
              className={classes.closeIcon}
              onClick={() => setAlertModalOpen(false)}
            />
          </Grid>
          <Typography
            variant="body1"
            data-test-id={'StudioPlusDestination-Deletion-Confirmation'}
          >
            Are you sure you want to delete the Studio+ destination?
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="outlined"
            className={classes.cancelButton}
            onClick={() => setAlertModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            className={classes.deleteButton}
            onClick={deleteDirectory}
            data-test-id={'StudioPlusDestination-Delete-button'}
          >
            Delete
          </Button>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
};

export default withStyles(styles)(StudioPlusFolderList);
