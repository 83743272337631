import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import OffsiteWorkersModal from './OffsiteWorkersModal';
import QUESTIONMARK_ICON from 'common/images/questionmark.svg';
import { makeStyles } from '@mui/styles';

const TEXT =
  'Check this box for an employee who is entirely remote, ' +
  'teleworking, or home-based, and has no expectation to ' +
  'regularly report in person to a physical establishment to ' +
  'perform work duties. For more information, ' +
  'see the California Pay Data Reporting FAQ.';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'start',
  },
  offsiteCheckbox: {
    checked: {
      backgroundColor: '#0000ff',
      color: '#000000',
    },
  },
  textSpace: {
    margin: '8px 0 0 -6px',
  },
  iconSpace: {
    marginLeft: '10px',
    marginBottom: '20px',
  },
  linkColor: {
    color: '#0000FF',
    textDecoration: 'underline',
  },
  infoIcon: {
    marginTop: '7px',
  },
}));

const OffsiteWorkersSelect = props => {
  const classes = useStyles();
  const {
    formData: { fullyOffsiteWorker = false } = {},
    onChange: upstreamOnChange,
  } = props;

  const [isOffsiteWorkersModalOpen, setIsOffsiteWorkersModalOpen] = useState(
    false,
  );

  const onChange = e => {
    if (e.target.checked) {
      upstreamOnChange({ fullyOffsiteWorker: true });
      setIsOffsiteWorkersModalOpen(true);
    } else {
      upstreamOnChange({ fullyOffsiteWorker: false });
    }
  };

  const handleClose = state => {
    if (state === 'N') {
      upstreamOnChange({ fullyOffsiteWorker: false });
    }
    setIsOffsiteWorkersModalOpen(false);
  };

  return (
    <div className={classes.root}>
      <Checkbox
        checked={fullyOffsiteWorker}
        onChange={onChange}
        className={classes.offsiteCheckbox}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <Typography className={classes.textSpace}>
        Please check this box if this employee is a fully offsite worker.
        <br />
        (Will be used for California pay data reporting purpose only.)
      </Typography>
      <Tooltip
        interactive
        title={
          <div>
            <Typography>{TEXT}</Typography>
            <a
              href="https://calcivilrights.ca.gov/paydatareporting/faqs/"
              className={classes.linkColor}
              target="_blank"
              rel="noopener noreferrer"
            >
              California Pay Data Reporting FAQ{' '}
            </a>
          </div>
        }
      >
        <img
          className={classes.infoIcon}
          src={QUESTIONMARK_ICON}
          alt="schedule tooltip"
        />
      </Tooltip>
      {isOffsiteWorkersModalOpen && (
        <OffsiteWorkersModal handleClose={handleClose} />
      )}
    </div>
  );
};

export default OffsiteWorkersSelect;
