import { Link } from 'react-router-dom';

import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from '@mui/icons-material';
import qs from 'qs';
import classNames from 'classnames';

const styles = {
  root: {
    lineHeight: '1.23076923',
    border: '1px solid #d8d8d8',
    padding: '16px 20px',
    verticalAlign: 'middle',
    position: 'relative',
    overflow: 'hidden',
    width: 'auto',
    textOverflow: 'ellipsis',
    fontSize: 12,
  },
  alignCenter: { textAlign: 'center' },
  alignJustify: { textAlign: 'justify' },
  alignRight: { textAlign: 'right' },
  compact: { padding: '0 !important' },
  expanded: { background: '#f2f2f3' },
  header: {
    fontSize: '0.76923077em',
    background: '#f2f2f3',
    border: 'none',
    color: '#979ca5',
    fontWeight: '700',
    padding: '10px 20px',
    textTransform: 'uppercase',
  },
  noBorder: { border: '0px !important' },
  noWrap: { whiteSpace: 'nowrap' },
  rowHeader: { fontWeight: '700', padding: '15px 20px' },
  secondaryHeader: {
    textTransform: 'uppercase',
    fontWeight: '400',
  },
  separator: { background: '#5fc1cd' },
  sort: { textDecoration: 'none' },
  textTruncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  sortIcon: {
    height: 15,
    width: 15,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  noPadding: {
    padding: 0,
  },
  leftBorder: {
    borderLeft: '1px solid #d8d8d8',
  },
  borderBottom: {
    borderTop: 'none !important',
    borderLeft: 'none !important',
    borderBottom: '1px solid #d8d8d8 !important',
  },
  borderRight: {
    borderRight: '1px solid #d8d8d8 !important',
  },
  doubleBorderBottom: {
    borderTop: 'none !important',
    borderLeft: 'none !important',
    borderRight: 'none !important',
    borderBottom: '2px solid #d8d8d8 !important',
  },
  allowOverflow: {
    overflow: 'initial',
  },
};

class Cell extends Component {
  render() {
    return this.props.sort
      ? this._renderSortableLink()
      : this._renderCellContent();
  }

  _getClasses = () => {
    const {
      classes,
      align,
      compact,
      expanded,
      header,
      noBorder,
      leftBorder,
      noWrap,
      rowHeader,
      secondaryHeader,
      separator,
      sort,
      textTruncate,
      noPadding,
      className,
      allowOverflow,
      borderBottom,
      borderRight,
      doubleBorderBottom,
    } = this.props;
    const rootClass = classNames(classes.root, className, {
      [classes.alignCenter]: align === 'center',
      [classes.alignJustify]: align === 'justify',
      [classes.alignRight]: align === 'right',
      [classes.compact]: compact,
      [classes.expanded]: expanded,
      [classes.header]: header,
      [classes.noBorder]: noBorder,
      [classes.noWrap]: noWrap,
      [classes.rowHeader]: rowHeader,
      [classes.secondaryHeader]: secondaryHeader,
      [classes.separator]: separator,
      [classes.sort]: !!sort,
      [classes.textTruncate]: textTruncate,
      [classes.noPadding]: noPadding,
      [classes.leftBorder]: leftBorder,
      [classes.allowOverflow]: allowOverflow,
      [classes.borderBottom]: borderBottom,
      [classes.borderRight]: borderRight,
      [classes.doubleBorderBottom]: doubleBorderBottom,
    });
    return rootClass;
  };

  _setStyles = () => {
    const styles = {};
    if (this.props.textTruncateWidth) {
      styles.maxWidth = this.props.textTruncateWidth;
    }
    return styles;
  };

  _renderCellContent = () => {
    const { 'data-test-id': dataTestId, width = 'auto' } = this.props;
    return (
      <td
        className={this._getClasses()}
        style={{ width }}
        data-test-id={dataTestId}
        colSpan={this.props.colSpan}
        onClick={this.props.onClick}
        onFocus={this.props.onClick}
      >
        <div
          style={this._setStyles()}
          tabIndex={this.props.onClick && 0}
          title={this.props.title}
        >
          {this.props.children}
        </div>
      </td>
    );
  };

  _renderSortableLink = () => {
    const {
      'data-test-id': dataTestId,
      classes,
      location = {},
      width = 'auto',
    } = this.props;
    const { search, pathname } = location || {};
    const query = { ...qs.parse(search, { ignoreQueryPrefix: true }) };

    if (query.sort === this.props.sort) {
      query.direction = query.direction === 'asc' ? 'desc' : 'asc';
    } else {
      query.sort = this.props.sort;
      query.direction = this.props.defaultSort || 'asc';
    }

    return (
      <td
        className={this._getClasses()}
        style={{ width }}
        colSpan={this.props.colSpan}
        data-test-id={dataTestId}
      >
        <Link
          to={{
            pathname,
            search: qs.stringify(query, { addQueryPrefix: true }),
          }}
          className={classes.link}
        >
          {this.props.children}
          {this._renderSortIcon()}
        </Link>
      </td>
    );
  };

  _renderSortIcon = () => {
    const { location = {}, sort, classes } = this.props;
    if (!sort) return;
    const { search } = location || {};
    const query = { ...qs.parse(search, { ignoreQueryPrefix: true }) };
    const { direction, sort: querySort } = query;
    if (!direction || querySort !== sort) return null;
    const sortIcon =
      direction === 'asc' ? (
        <ArrowUpwardIcon className={classes.sortIcon} />
      ) : (
        <ArrowDownwardIcon className={classes.sortIcon} />
      );
    return sortIcon;
  };
}

export default compose(withRouter, withStyles(styles))(Cell);
