import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query projectDetails($projectId: Int!) {
    project(id: $projectId) {
      id
      allowHirersToSendOffers
      country {
        id
        name
      }
      customEmailText
      employeeType
      employerOrgAddress
      employerOrgCity
      employerOrgName
      employerOrgState {
        id
        name
      }
      employerOrgZipCode
      hasHiringStatus
      sendFinalDocsToStudioPlus
      sendMycncW4ToStudioPlus
      sendMycncW9ToStudioPlus
      sendDataReportToStudioPlus
      sendProvisionalDealMemo
      sendDealMemoAfter
      status
      trackOffsiteWorkers
      studioPlusDirectoryId
      studioPlusMycncW4DirectoryId
      studioPlusW9DirectoryId
      studioPlusDataReportDirectoryId
      templatesAcknowledgeTitle
      templatesAcknowledgeNotes
      offerDefaults {
        allowEndDate
        backdateLimit
        hiringStatus
        sendDateConfiguration
        hireState {
          id
          name
        }
        hireCity {
          id
          name
        }
        workState {
          id
          name
        }
        workCity {
          id
          name
        }
      }
      reimbursements {
        boxRentalAllowance {
          amount
          cap
          per
          duration
        }
        carAllowance {
          amount
          cap
          per
          duration
        }
        computerRentalAllowance {
          amount
          cap
          per
          duration
        }
        housingAllowance {
          amount
          cap
          per
          duration
        }
        mobilePhoneAllowance {
          amount
          cap
          per
          duration
        }
        perDiemAllowance {
          amount
          cap
          per
          duration
        }
      }
    }
  }
`;

const useProjectDetails = projectId => {
  const { loading, data, error, refetch } = useQuery(QUERY, {
    skip: !projectId,
    variables: { projectId: parseInt(projectId) },
  });

  return { loading, error, refetch, data: data?.project };
};

export default useProjectDetails;
