// replace all non-digit values
const getDigits = (value = '') => {
  try {
    return value.replace(/[^0-9]/g, '') || '';
  } catch (e) {
    return '';
  }
};

// add 0's as padding to the left of the digits if value is < $1
const padDigits = digits => {
  // min length 3 to format values that are cents only, eg. $0.02
  const minimumLength = 3;
  const digitsLength = digits.length;
  if (digitsLength >= minimumLength) {
    return digits;
  }
  const requiredPadding = minimumLength - digitsLength;
  const padding = '0'.repeat(requiredPadding);
  return `${padding}${digits}`;
};

// remove any leading zeroes from the number
const removeLeadingZeroes = number => number.replace(/^0+([0-9]+)/, '$1');

// format the number as US currency
const addDecimalToNumber = number => {
  const centsStartingPosition = number.length - 2;
  const dollars = removeLeadingZeroes(
    number.substring(0, centsStartingPosition),
  );
  const cents = number.substring(centsStartingPosition);
  return `${dollars}.${cents}`;
};

export const formatCurrency = value => {
  const digits = getDigits(value);
  const digitsWithPadding = padDigits(digits);
  const digitsWithDecimal = addDecimalToNumber(digitsWithPadding);
  return digitsWithDecimal;
};

export default formatCurrency;
