import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query offerCurrency($id: Int!) {
    offer(id: $id) {
      id
      termsOfHire {
        currency
      }
    }
  }
`;

const useOfferCurrency = ({ offerId }) => {
  const { loading, data } = useQuery(QUERY, {
    skip: !offerId,
    variables: { id: parseInt(offerId) },
  });
  return { loading, data: data?.offer.termsOfHire.currency };
};

export default useOfferCurrency;
