import React, { Component } from 'react';
import AvatarDetails from 'common/oldJavascripts/components/Base/AvatarDetails';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Header from 'common/oldJavascripts/components/Base/Header';
import format from 'common/oldJavascripts/utils/format.js';

class EditHeader extends Component {
  // storing user's name is state because the user object being passed down is the user mutator
  // which updates the user's name in the header as they type
  // TODO: Fix upstream props that are passed down
  state = {
    firstName: this.props.user.first_name || '',
    middleName: this.props.user.middle_name || '',
    lastName: this.props.user.last_name || '',
  };

  render() {
    return (
      <BoxItem>
        <AvatarDetails>
          <Header>
            <Header.Title primary={true}>{this._formatFullName()}</Header.Title>
          </Header>
        </AvatarDetails>
      </BoxItem>
    );
  }

  _formatFullName() {
    const { firstName, middleName, lastName } = this.state;
    return format.fullName(firstName, middleName, lastName);
  }
}

export default EditHeader;
