import React from 'react';
import ProjectTemplateAutoAssignmentRuleSelect from './ProjectTemplateAutoAssignmentRuleSelect';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';

const ProjectTemplateAutoAssignmentRuleEmploymentTypeSelect = props => {
  const {
    className,
    'data-test-id': dataTestId,
    employmentTypeId,
    onChange,
  } = props;

  // NOTE This is quite a tortuous way of showing "Individual" on Canadian
  // projects, but I don't want to expose useProjectCountry unless I absolutely
  // have to.

  const getProps = countryCode => {
    const options = [
      { label: countryCode === 'CA' ? 'Individual' : 'W-2', value: 1, id: 1 },
      { label: 'Loan-out', value: 2, id: 2 },
    ];

    return {
      className,
      label: 'Employment Type',
      options,
      value: employmentTypeId,
      onChange: employmentTypeId => {
        onChange(options.find(({ value }) => value === employmentTypeId));
      },
      'data-test-id': dataTestId,
    };
  };

  return (
    <>
      <UsaOnly>
        <ProjectTemplateAutoAssignmentRuleSelect {...getProps('US')} />
      </UsaOnly>
      <CanadaOnly>
        <ProjectTemplateAutoAssignmentRuleSelect {...getProps('CA')} />
      </CanadaOnly>
    </>
  );
};

export default ProjectTemplateAutoAssignmentRuleEmploymentTypeSelect;
