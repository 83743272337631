import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const QUERY = gql`
  mutation templateClone(
    $templateId: String!
    $templateName: String!
    $tags: [InputTag!]
    $expireOn: String
  ) {
    templateClone(
      templateId: $templateId
      templateName: $templateName
      tags: $tags
      expireOn: $expireOn
    )
  }
`;

const useTemplateClone = () => {
  const [templateClone, params] = useMutation(QUERY);
  return [templateClone, params];
};

export default useTemplateClone;
