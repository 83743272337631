import React from 'react';

// MaterialUI Components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/system';

// Custom Components
import ProgressSpinner from 'common/components/ProgressSpinner';

const StyledButton = styled(Button)({
  backgroundColor: '#e0e0e0',
  marginRight: 16,
  color: 'black',
  '&:hover': {
    backgroundColor: '#d0d0d0',
  },
});

const DiscardChangesDialog = props => {
  const { onClose, isOpen, discardChanges, isDiscardingChanges } = props;
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>Are you sure you want to discard changes?</DialogTitle>
      <DialogContent>{`Any unsaved changes will be discarded and the document will be reverted to the previously saved configuration.`}</DialogContent>
      <DialogActions>
        <StyledButton
          onClick={onClose}
          variant="contained"
          data-test-id={'DiscardChangesDialog-cancelButton'}
        >
          Cancel
        </StyledButton>
        <Button
          onClick={discardChanges}
          variant="contained"
          disabled={isDiscardingChanges}
          data-test-id={'DiscardChangesDialog-discardButton'}
        >
          Discard Changes
          {isDiscardingChanges && (
            <ProgressSpinner size={24} isDiscardingChanges />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscardChangesDialog;
