import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query offers($projectId: Int!, $offerIds: [Int!]!) {
    offers(projectId: $projectId, offerIds: $offerIds) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        totalCount
      }
      nodes {
        id
        status
        isTermsOfEmploymentV2
        reimbursementsUpdated
        keepI9
        i9DocumentTemplateId
        i9Document
        projectUser {
          id
          profile {
            id
            firstName
            lastName
            email
            phone
          }
        }
        startDate
        sendDate
        endDate
        sentAt
        termsOfHire {
          subDepartment {
            id
            name
          }
          season
          hireState {
            code
            id
            name
          }
          hireCity {
            code
            id
            name
          }
          workState {
            code
            id
            name
          }
          workCity {
            code
            id
            name
          }
          union {
            code
            description
            isNonUnion
          }
          occupation {
            code
            description
          }
          workSchedule {
            code
            description
          }
          fringeContract {
            code
            description
          }
          department {
            code
            id
            loanOutEnabled
            masterDepartmentId
            name
            preSelected
          }
          jobDescription
          employmentClassification
          hiringStatus
          currency
          payAtRollback
          workerType
          fullyOffsiteWorker
          trackOffsiteWorkers
        }
        termsOfEmployment {
          studioRatePerHour
          locationRatePerHour
          studioRatePerWeek
          guaranteedHoursFrequency
          locationRatePerWeek
          studioGuaranteedHours
          idleLocationSixthDayRatePerHour
          idleLocationSeventhDayRatePerHour
          overtimeRatePerHour
          locationGuaranteedHours
          guaranteedHours
          ratePerHour
          dailyRate
          weeklyRate
          numberOfDaysPerWorkWeek
          negotiatedContract {
            contractId
            isNegotiated
          }
        }
        termsOfEmploymentV2 {
          rate
          negotiatedContract {
            contractId
            isNegotiated
          }
          ... on TermsOfEmploymentNonUnionHourly {
            guaranteedHours
            guaranteedHours6thDay
            guaranteedHours7thDay
            guaranteedHoursFrequency
            guaranteedHoursLimit
            rate
            rateMinimumWage
            rateOvertime
          }
          ... on TermsOfEmploymentUnionHourly {
            rateDistant
            weeklyRate
            weeklyRateDistant
            guaranteedHours
            guaranteedHoursFrequency
            guaranteedHoursDistant
            guaranteedHours6thDay
            guaranteedHours7thDay
            guaranteedHours6thDayDistant
            guaranteedHours7thDayDistant
            payIdleDaysDistant
            payGoldAt
            payGoldAtDistant
            payAtScale
            payAtScaleDistant
            idleAtScaleDistant
            goldAtScale
            goldAtScaleDistant
          }
          ... on TermsOfEmploymentNonUnionWeekly {
            rate
            sixthDayPayMultiplication
            seventhDayPayMultiplication
          }
          ... on TermsOfEmploymentCA {
            ratePerHour
            guaranteedHours
            dailyRate
            weeklyRate
            overtimeRatePerHour
            guaranteedHoursFrequency
            totalFringePercentage
            fringesIncluded
          }
          ... on TermsOfEmploymentUnionWeekly {
            rateDistant
            guaranteedHours
            guaranteedHoursDistant
            payAtScale
            payAtScaleDistant
            payIdleDaysDistantAt10th
            payIdleDaysDistantAt12th
            payIdleDaysDistantAtNegotiated
            payIdleDaysDistantAtScale
          }
          ... on TermsOfEmploymentUnionSag {
            rate
            override
            reason
            per
            guaranteedHours
            guaranteedDays
          }
        }
        allowances {
          boxRentalAllowance {
            amount
            cap
            per
            duration
          }
          computerRentalAllowance {
            amount
            cap
            per
            duration
          }
          carAllowance {
            amount
            cap
            per
            duration
          }
          mobilePhoneAllowance {
            amount
            cap
            per
            duration
          }
          perDiemAllowance {
            amount
            cap
            per
            duration
          }
          housingAllowance {
            amount
            cap
            duration
            per
          }
        }
        accountCodes {
          id
          accountCodeId
          lineItemKey
          value
        }
        documents
        documentFields {
          id
          value
        }
        notes
        previousStatus
        additionalNotes {
          notes
          notesUpdatedAt
          notesUpdatedBy
        }
      }
    }
  }
`;

const useOffers = (projectId, offerIds) => {
  const { error, loading, data } = useQuery(QUERY, {
    // NOTE Project.id is listed in the schema as an ID! type, but the project
    // query takes a projectId of type Int! Obviously, this is wrong.
    // NOTE Same goes for Offer.id
    skip:
      !projectId || !offerIds || (Array.isArray(offerIds) && !offerIds.length),
    variables: {
      projectId: parseInt(projectId),
      offerIds: offerIds.map(id => parseInt(id)),
    },
    fetchPolicy: 'no-cache',
  });
  return { error, loading, data: data?.offers };
};

export default useOffers;
