import React from 'react';
import { withStyles } from '@mui/styles';
import { IconButton, Typography } from '@mui/material';
import {
  Delete as DeleteIcon,
  Done as DoneIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import ProjectTemplateAutoAssignmentRuleDisplay from './ProjectTemplateAutoAssignmentRuleDisplay';
import ProjectTemplateAutoAssignmentRuleEditor from './ProjectTemplateAutoAssignmentRuleEditor';

const styles = theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(0.5),
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const ProjectTemplateAutoAssignmentRule = props => {
  const {
    classes,
    onToggleEdit,
    isBeingEdited,
    isDuplicateRule = false,
    isComboNotEligible = false,
    rule,
    rule: { id },
    onDelete,
    onChange,
    projectCountryId,
  } = props;

  // Shouldn't allow empty rules to be created (no label)
  const isEmptyRule = !rule?.__typename;

  return (
    <div
      className={classes.root}
      data-test-id={`ProjectTemplateAutoAssignmentRule-root-${id}`}
    >
      {isBeingEdited ? (
        <ProjectTemplateAutoAssignmentRuleEditor
          rule={rule}
          onChange={onChange}
          projectCountryId={projectCountryId}
          isEmptyRule={isEmptyRule}
        />
      ) : (
        <ProjectTemplateAutoAssignmentRuleDisplay
          rule={rule}
          isEmptyRule={isEmptyRule}
        />
      )}
      <div className={classes.buttonRow}>
        <div>
          {isBeingEdited && isEmptyRule && (
            <Typography color="error" variant="caption">
              * Please select a rule type before continuing. Empty rules will
              not be saved.
            </Typography>
          )}
        </div>
        <div>
          {isBeingEdited && isDuplicateRule && (
            <Typography color="error" variant="caption">
              Duplicate Rule.
            </Typography>
          )}
          {isBeingEdited && isComboNotEligible && (
            <Typography color="error" variant="caption">
              Select at least 2 rule types for combo rule.
            </Typography>
          )}
          <IconButton
            onClick={onToggleEdit}
            data-test-id={`ProjectTemplateAutoAssignmentRule-edit-${id}`}
            disabled={
              isBeingEdited &&
              (isEmptyRule || isDuplicateRule || isComboNotEligible)
            }
          >
            {isBeingEdited ? <DoneIcon /> : <EditIcon />}
          </IconButton>
          <IconButton
            onClick={onDelete}
            data-test-id={`ProjectTemplateAutoAssignmentRule-delete-${id}`}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ProjectTemplateAutoAssignmentRule);
