import React from 'react';

import { withStyles } from '@mui/styles';
import classNames from 'class-names';

const styles = {
  root: {
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    cursor: 'default',
    display: 'inline-block',
    marginBottom: '8px',
  },
  inline: { display: 'inline' },
  required: {
    color: '#ea5a5a',
    display: 'inline-block',
    fontWeight: '700',
    marginLeft: '1px',
  },
};

class FormLabel extends React.Component {
  render() {
    const { classes, className, inline, text, htmlFor } = this.props;
    const rootClass = classNames(classes.root, className, {
      [classes.inline]: !!inline,
    });
    return (
      <label className={rootClass} htmlFor={htmlFor}>
        {text}
        {this._renderRequired()}
      </label>
    );
  }

  _renderRequired() {
    const { required, classes } = this.props;
    if (required) {
      return <span className={classes.required}>*</span>;
    }
  }
}

FormLabel.defaultProps = {
  inline: false,
  required: false,
  text: '',
};

export default withStyles(styles)(FormLabel);
