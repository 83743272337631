import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Close as CloseIcon } from '@mui/icons-material';
import classNames from 'class-names';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import useDepartments from 'common/hooks/useDepartments';

const styles = {
  warning: {
    textAlign: 'center',
    marginTop: 16,
  },
  borderRight: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  borderTop: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  tableDescription: {
    padding: 8,
  },
  closeIcon: {
    fontSize: '20px',
    color: 'grey',
    marginRight: 20,
    cursor: 'pointer',
  },
  iconContainer: {
    textAlign: 'right',
    padding: 10,
  },
};

const LoanOutRestrictionTable = props => {
  const { classes, handleClose, projectId } = props;
  const { data: departments = [] } = useDepartments({ projectId });

  const tableBodyRows = departments
    .filter(({ loanOutOccupations = [] }) => loanOutOccupations.length)
    .map(({ id, name, code, loanOutOccupations = [] }) => (
      <React.Fragment key={id}>
        <TableRow>
          <TableCell
            rowSpan={loanOutOccupations.length}
            className={classNames(classes.borderRight, classes.labelCell)}
          >
            <Tooltip
              disableInteractive
              title={`Department Code: ${code}`}
              enterDelay={500}
            >
              <Typography>{name}</Typography>
            </Tooltip>
          </TableCell>
          <TableCell className={classNames(classes.border, classes.labelCell)}>
            <Tooltip
              disableInteractive
              title={`Occupation Code: ${loanOutOccupations[0].code}`}
              enterDelay={500}
            >
              <Typography>{`${loanOutOccupations[0].code} - ${loanOutOccupations[0].description}`}</Typography>
            </Tooltip>
          </TableCell>
        </TableRow>
        {[...loanOutOccupations].slice(1).map(({ description, code }) => (
          <TableRow>
            <TableCell
              className={classNames(classes.border, classes.labelCell)}
            >
              <Tooltip
                disableInteractive
                title={`Occupation Code: ${code}`}
                enterDelay={500}
              >
                <Typography>{`${code} - ${description}`}</Typography>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </React.Fragment>
    ));

  return (
    <div>
      <div className={classes.iconContainer}>
        <CloseIcon onClick={handleClose} className={classes.closeIcon} />
      </div>
      <Typography variant="h6" className={classes.tableDescription}>
        Production management for your show restricts what jobs can be
        loan-outs. ONLY the following Departments/Occupations are authorized:
      </Typography>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              className={classNames(classes.borderTop, classes.borderRight)}
            >
              <Typography variant="h6">Department</Typography>
            </TableCell>
            <TableCell align="center" className={classes.borderTop}>
              <Typography variant="h6">Occupation</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableBodyRows}</TableBody>
      </Table>
      {!tableBodyRows.length && (
        <Typography variant="h6" className={classes.warning}>
          There are not any restricted loan-out occupations configured for this
          project.
        </Typography>
      )}
    </div>
  );
};

export default compose(
  withRouteHelpers,
  withStyles(styles),
)(LoanOutRestrictionTable);
