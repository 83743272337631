import { useState } from 'react';

// Approach suggested by https://reactjs.org/docs/hooks-faq.html#how-do-i-implement-getderivedstatefromprops
const useSyncedState = (upstreamValue, changeFlag) => {
  const [value, setValue] = useState(upstreamValue);
  const [prevChangeFlag, setPrevChangeFlag] = useState(changeFlag);

  if (changeFlag !== prevChangeFlag) {
    setPrevChangeFlag(changeFlag);
    setValue(upstreamValue);
  }

  return [value, setValue];
};

export default useSyncedState;
