import gql from 'graphql-tag';

export default gql`
  query CreateOfferCrewMembers(
    $id: Int!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $search: String
    $sort: String
    $sortField: String
    $withOffers: Boolean
    $withoutOffers: Boolean
  ) {
    project(id: $id) {
      id
      crew(
        first: $first
        after: $after
        last: $last
        before: $before
        search: $search
        sort: $sort
        sortField: $sortField
        withOffers: $withOffers
        withoutOffers: $withoutOffers
      ) {
        edges {
          cursor
          node {
            id
            hasUserAccount
            privileges
            offerCount
            profile {
              id
              firstName
              middleName
              lastName
              fullName
              email
            }
            offers {
              id
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
          totalCount
        }
      }
    }
  }
`;
