import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay';
import T from 'common/oldJavascripts/utils/i18n';
import ProjectLayout from 'common/oldJavascripts/components/Pages/Layouts/Project';
import Box from 'common/oldJavascripts/components/Base/Box';
import Header from 'common/oldJavascripts/components/Base/Header';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Tabs from 'common/oldJavascripts/components/Base/Tabs';
import Feature from 'common/components/Feature';
import CanadaOnly from 'common/components/CanadaOnly';
import { withRouter } from 'react-router';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

const ProjectSetup = Relay.createClass({
  statics: {
    queries: {
      packetsManagement: {
        info(_, related) {
          const params = related['/router/params'];
          return {
            id: `/v2/projects/${params.projectId}/packets-management`,
          };
        },
      },
    },
  },

  render: function() {
    const { routerParams: params = {} } = this.props;
    const { projectId } = params;
    return (
      <ProjectLayout>
        <LayoutContent>
          <Box>
            <BoxItem>
              <Header>
                <Header.Title>
                  <span data-test-id="ProjectSetup-title">
                    {T('project.header.setup.title')}
                  </span>
                </Header.Title>
              </Header>
              <Tabs>
                <Tabs.Item
                  to={`/projects/${projectId}/imported-settings`}
                  params={{ projectId }}
                  data-test-id="ProjectSetup-importedSettings"
                >
                  {T('project.header.setup.settings')}
                </Tabs.Item>
                <CanadaOnly>
                  <Feature name="CanadianProjectSupport">
                    <Tabs.Item
                      to={`/projects/${projectId}/related-projects`}
                      params={{ projectId }}
                      data-test-id="ProjectSetup-relatedProjects"
                    >
                      {T('project.header.setup.related_projects')}
                    </Tabs.Item>
                  </Feature>
                </CanadaOnly>
                <Tabs.Item
                  to={`/projects/${projectId}/project-settings`}
                  params={{ projectId }}
                  data-test-id="ProjectSetup-projectSettings"
                >
                  {T('project.header.setup.documents')}
                </Tabs.Item>
                <Tabs.Item
                  to={`/projects/${projectId}/project-templates`}
                  params={{ projectId }}
                >
                  Documents
                </Tabs.Item>
                <Tabs.Item
                  to={`/projects/${projectId}/audit-logs`}
                  params={{ projectId }}
                >
                  {T('project.header.setup.audit_log')}
                </Tabs.Item>
              </Tabs>
            </BoxItem>
            {this.props.children}
          </Box>
        </LayoutContent>
      </ProjectLayout>
    );
  },
});

export default withRouter(ProjectSetup);
