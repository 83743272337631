const HIRING_STATUS_OPTS = [
  { value: 'full_time', label: 'Full Time' },
  { value: 'variable', label: 'Variable' },
];

const EMPLOYEE_HIRING_STATUS_OPTS = HIRING_STATUS_OPTS.concat([
  { value: 'opt_out_var', label: 'Opt-out Variable' },
  { value: 'part_time', label: 'Part Time' },
  { value: 'seasonal', label: 'Seasonal' },
  { value: 'variable_ec', label: 'Variable (exclude Corps)' },
]);

const hiringStatusOptions = employeeType =>
  !!employeeType ? EMPLOYEE_HIRING_STATUS_OPTS : HIRING_STATUS_OPTS;

export default hiringStatusOptions;
