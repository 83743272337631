import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const QUERY = gql`
  mutation addProjectTemplate($projectId: Int!, $templateIds: [String!]!) {
    addProjectTemplate(projectId: $projectId, templateIds: $templateIds)
  }
`;

const useAddTemplates = () => {
  const [addTemplates, params] = useMutation(QUERY);
  return [addTemplates, params];
};

export default useAddTemplates;
