import React, { Component } from 'react';
import { Button, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import moment from 'moment';

const lightGrey = '#a2a2a2';
const text = 'white';
const blue = '#0000FF';
const yellow = '#f3ac21';
const secondary = blue;
const tertiary = yellow;

const styles = theme => ({
  addButton: {
    backgroundColor: secondary,
    color: text,
    '&:hover': {
      backgroundColor: secondary,
    },
    gridColumn: '11 / -1',
  },
  container: {
    gridColumn: '4 span',
    wordWrap: 'break-word',
  },
  currentNotes: {
    minHeight: 80,
    maxHeight: 108,
    width: '100%',
    overflow: 'auto',
    resize: 'none',
    border: 'none',
  },
  disabled: {
    borderLeft: `2px solid ${tertiary}`,
  },
  editTextArea: {
    gridColumn: '1 / -1',
    maxWidth: '100%',
    minWidth: '20%',
    minHeight: 100,
    maxHeight: 150,
  },
  error: {
    color: '#ea5a5a',
    gridColumn: '8 / 11',
    textAlign: 'right',
  },
  enabled: {
    borderLeft: `2px solid ${blue}`,
  },
  header: {
    fontStyle: 'italic',
  },
  headerEnabled: {
    color: blue,
  },
  headerDisabled: {
    color: tertiary,
  },
  previousNotes: {
    color: lightGrey,
  },
  termContainer: {
    paddingLeft: theme.spacing(1),
    height: '100%',
    width: '100%',
  },
  textAreaDisabled: {
    background: '#efefef',
    cursor: 'not-allowed',
    border: 'none',
  },
  title: {
    gridColumn: '1 / -1',
    marginBottom: theme.spacing(1),
    fontWeight: 700,
    fontSize: '1rem',
  },
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    columnGap: `${theme.spacing(2)}px`,
    rowGap: `${theme.spacing(1.5)}px`,
  },
  warning: {
    gridColumn: '1 / -1',
    color: '#ea5a5a',
  },
});

class AdditionalDealTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      rejected: props.rejected,
      noteUpdated: false,
      previousNoteToDisplay: props.notes || '',
      buffer: '',
    };
    this.addDealNote = this.addDealNote.bind(this);
    this.editDealNote = this.editDealNote.bind(this);
    this.renderNoteField = this.renderNoteField.bind(this);
  }

  addDealNote() {
    const { updateNotes } = this.props;
    const {
      rejected,
      previousNoteToDisplay,
      buffer: textAreaValue,
    } = this.state;
    updateNotes(textAreaValue);
    const noteUpdatedStatus = !!(rejected && previousNoteToDisplay);
    this.setState({
      isEditing: false,
      noteUpdated: noteUpdatedStatus,
      buffer: '',
    });
  }

  editDealNote() {
    const { editNotes, textAreaValue } = this.props;
    editNotes();
    this.setState({ isEditing: true, buffer: textAreaValue });
  }

  renderNoteField(note, current = false) {
    const { classes, currentDate } = this.props;
    const { notes, notesUpdatedAt } = note;
    const containerClasses = classnames([classes.container], {
      [classes.disabled]: !current,
      [classes.enabled]: current,
    });
    const noteClasses = classnames([classes.currentNotes], {
      [classes.previousNotes]: !current,
    });
    const headerClasses = classnames([classes.header], {
      [classes.headerDisabled]: !current,
      [classes.headerEnabled]: current,
    });
    return (
      <div className={containerClasses}>
        <div className={classes.termContainer}>
          <Typography className={headerClasses}>
            {current
              ? `Current - ${moment(currentDate || new Date()).format(
                  'M/D/YYYY h:mm a',
                )}`
              : `Previous - ${moment(notesUpdatedAt).format(
                  'M/D/YYYY h:mm a',
                )}`}
          </Typography>
          <textarea className={noteClasses} value={notes} disabled />
        </div>
      </div>
    );
  }

  renderButtonText() {
    const { isEditing } = this.state;
    const { notes = '' } = this.props;
    if (isEditing) return 'Save';
    if (notes.length > 0) return 'Edit';
    return 'Add';
  }

  updateTextArea = ev => {
    const {
      target: { value },
    } = ev;
    this.setState({ buffer: value.slice(0, 1000) });
  };

  syncTextAreaWithOffer = () => {
    const { updateTextArea } = this.props;
    const { buffer } = this.state;
    updateTextArea({ target: { value: buffer } });
  };

  render() {
    const {
      classes,
      error = {},
      title,
      canEdit = false,
      headerStyles = {},
      oldNotes,
      notes = '',
    } = this.props;
    const {
      isEditing,
      noteUpdated,
      previousNoteToDisplay = '',
      buffer: textAreaValue = '',
    } = this.state;
    const disabled = !isEditing && notes.length > 0;
    const textAreaClass = classnames([classes.editTextArea], {
      [classes.textAreaDisabled]: disabled,
    });

    return (
      <div className={classes.root}>
        <Typography className={classes.title} style={headerStyles}>
          {title}
        </Typography>
        {notes && notes.length > 0
          ? this.renderNoteField({ notes }, true)
          : null}
        {previousNoteToDisplay.length > 0 && noteUpdated
          ? this.renderNoteField({ notes: previousNoteToDisplay })
          : null}
        {oldNotes.map(note => this.renderNoteField(note))}
        {canEdit === true ? (
          <textarea
            rows={4}
            className={textAreaClass}
            value={textAreaValue}
            onChange={this.updateTextArea}
            disabled={disabled}
            onBlur={this.syncTextAreaWithOffer}
          />
        ) : null}
        {textAreaValue.length >= 1000 ? (
          <Typography className={classes.warning}>
            Cannot exceed maximum character limit (1,000 characters).
          </Typography>
        ) : null}
        {error.unsaved_additional_deal_notes && textAreaValue.length > 0 ? (
          <Typography className={classes.error}>
            Please save your additional deal notes.
          </Typography>
        ) : null}
        {canEdit === true ? (
          <Button
            onClick={disabled ? this.editDealNote : this.addDealNote}
            className={classes.addButton}
            data-test-id={`AdditionalDealTerms-button`}
          >
            {`${this.renderButtonText()} Deal Note`}
          </Button>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(AdditionalDealTerms);
