import isNonUnionWorkScheduleA from 'common/utilities/isNonUnionWorkScheduleA';

const isNonUnionV2Active = (flags, workScheduleCode) => {
  const isNonUnionScheduleA =
    isNonUnionWorkScheduleA(workScheduleCode) &&
    flags.includes('nonUnionSchedules');
  const isNonUnionScheduleB =
    workScheduleCode === 'B' && flags.includes('nonUnionScheduleB');
  const isNonUnionScheduleC =
    ['C', 'E', 'CN'].includes(workScheduleCode) &&
    flags.includes('nonUnionScheduleC');
  const isNonUnionScheduleD =
    workScheduleCode === 'D' && flags.includes('nonUnionScheduleD');
  return (
    isNonUnionScheduleA ||
    isNonUnionScheduleB ||
    isNonUnionScheduleC ||
    isNonUnionScheduleD
  );
};

export default isNonUnionV2Active;
