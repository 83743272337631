import React, { useEffect, useState } from 'react';

import TextareaAutosize from '@mui/material/TextareaAutosize';
import DialogContent from '@mui/material/DialogContent';

import ActionButtons from './ActionButtons';

const AddNote = ({ classes, onNext, data }) => {
  const [note, setNote] = useState('');

  useEffect(() => {
    if (data?.note) {
      setNote(data.note);
    }
  }, [data]);

  // next screen - confirm fields
  const handleNext = () => {
    onNext('confirm', { note });
  };

  // back to previous screen - crew fields list
  const handleBack = () => {
    onNext('fields', { note });
  };

  const handleNote = value => {
    if (value !== ' ' && value.length <= 500) {
      setNote(value);
    }
  };

  return (
    <>
      <DialogContent data-test-id="note-section">
        <TextareaAutosize
          className={classes.noteTextArea}
          minRows={8}
          aria-label="maximum height"
          placeholder="Please add a short explanation for rejecting the I-9 Form back to the crew member for correction(s)."
          value={note}
          onChange={e => handleNote(e.target.value)}
          data-test-id="crew-fields-note"
        />
        <p className={classes.noteLength}>{note.length}/500</p>
      </DialogContent>
      <ActionButtons
        onBack={handleBack}
        onNext={handleNext}
        classes={classes}
        disabled={note.length < 1}
      />
    </>
  );
};

export default AddNote;
