import React from 'react';
import { withStyles } from '@mui/styles';
import ReactSelect, { components } from 'react-select';
import classNames from 'classnames';

const styles = {
  invalid: {
    borderColor: '#ea5a5a',
  },
};

const reactSelectStyles = {
  control: (styles, state) => ({
    ...styles,
    '&:hover': {
      borderColor: '#0000FF',
    },
    borderColor:
      state.isActive || state.isFocused ? '#0000FF' : styles.borderColor,
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected
      ? '#0000FF'
      : state.isFocused
      ? '#E2F1FF'
      : styles.backgroundColor,
  }),
  multiValue: styles => ({
    ...styles,
    backgroundColor: '#E2F1FF',
    border: 'solid 1px #0000FF',
    borderRadius: 3,
  }),
  multiValueLabel: styles => ({
    ...styles,
    color: '#0000FF',
    padding: '1px 3px 1px 6px',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: '#0000FF',
    borderLeft: 'solid 1px #0000FF',
  }),
};

const DropdownIndicator = props => {
  return (
    <span data-test-id="Select-opener">
      <components.DropdownIndicator {...props} />
    </span>
  );
};

const formatOptionLabel = ({ label }, { context }) =>
  context === 'value' ? (
    <span data-test-id="Select-value">{label}</span>
  ) : (
    <span data-test-id={`Select-option-${label}`}>{label}</span>
  );

class Select extends React.Component {
  constructor(props) {
    super(props);
    this._onChange = this._onChange.bind(this);
  }

  render() {
    const {
      classes,
      className,
      options,
      value,
      isLoading,
      isInvalid,
      searchable,
      clearable,
      disabled,
      multiple,
      id,
      placeholder,
      noResultsText,
      name,
      closeMenuOnSelect = true,
    } = this.props;
    const rootClass = classNames(className, {
      [classes.invalid]: isInvalid,
      'select-loading': isLoading,
    });

    let valueProp;
    if (multiple) {
      valueProp = options.filter(opt => (value || []).includes(opt.value));
    } else {
      valueProp = options.filter(opt => opt.value === value);
    }

    return (
      <ReactSelect
        options={options}
        value={valueProp}
        onChange={this._onChange}
        isLoading={isLoading}
        isSearchable={searchable}
        isClearable={clearable}
        isDisabled={disabled}
        isMulti={multiple}
        id={id}
        className={rootClass}
        placeholder={placeholder}
        noResultsText={noResultsText}
        name={name}
        formatOptionLabel={formatOptionLabel}
        components={{ DropdownIndicator }}
        closeMenuOnSelect={!!closeMenuOnSelect}
        styles={reactSelectStyles}
      />
    );
  }

  _onChange(selectedOption) {
    if (this.props.multiple) {
      const newValues = selectedOption.map(so => so.value);
      this.props.onChange(newValues);
    } else {
      this.props.onChange(selectedOption && selectedOption.value);
    }
  }
}

export default withStyles(styles)(Select);
