export const WHITE = '#FFFFFF';
export const WHITE_OFF = '#F7FCFC';
export const BLACK = '#000000';
export const TEAL = '#2EABA4';
export const TEAL_DARK = '#2E9A9C';
export const TEAL_LIGHT = '#96D5D1';
export const TEAL_VERY_LIGHT = '#BFEEEC';
export const GOLD = '#FFA800';
export const GOLD_DARK = '#FF9500';
export const GOLD_LIGHT = '#FFDA8A';
export const RED = '#D92B2E';
export const RED_LIGHT = '#EA5A5A';
export const GRAY = '#AAAAAA';
export const GRAY_DARK = '#999999';
export const GRAY_VERY_DARK = '#646464';
export const GRAY_LIGHT = '#CFCFCF';
export const GREEN = '#2ECC71';
export const GREEN_LIGHT = '#99CC48';
export const ORANGE = '#FF7800';
export const ORANGE_LIGHT = '#FF8100';
export const BLUE = '#3C9DD7';
export const BLUE_LIGHT = '#0091FF';
export const MAIN = '#0000FF';
export const DARK = '#0000B3';
export const LIGHT = '#4D7BFD';
export const LIGHT_CONTRAST_TEXT = '#BFEEEC';
