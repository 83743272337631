import React, { useState } from 'react';
import { DialogActions } from '@mui/material';
import * as palette from 'common/shared/oldDocumentSignerUI/palette';
import { Button, Dialog, Typography, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import DocumentMetaDataWrapper from 'admin/oldJavascripts/components/Shared/DocumentMetaDataWrapper';
import useTemplateClone from 'admin/hooks/useTemplateClone';

const styles = theme => ({
  dialogContent: {
    width: '600px',
    padding: '12px',
  },
  dialogtitle: {
    color: '#525252',
    fontSize: 'px',
  },
  dialogActions: {
    paddingBottom: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginTop: '35px',
    marginRight: '10px',
  },
  error: {
    padding: '0px 50px',
    minHeight: '1.71429em',
  },
  saveButtonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: palette.success,
    '&:hover': {
      backgroundColor: palette.successHover,
    },
  },
  expirationDateLabel: {
    marginTop: 16,
    marginBottom: 10,
  },
  closeIcon: {
    position: 'absolute !important',
    top: '10px',
    right: '10px',
  },
});

const DraggableDialog = ({
  classes,
  handleCancel,
  handleReload,
  handleError,
  id,
}) => {
  const [templateClone, { loading }] = useTemplateClone();
  const [isClone, setIsClone] = useState(false);
  const [error, setError] = useState(null);

  const handleTextModalOpen = () => {
    setIsClone(true);
  };
  const handleClose = () => {
    handleCancel();
  };

  const handleSubmit = variables => {
    templateClone({ variables })
      .then(({ data }) => {
        const { templateClone } = data;
        if (templateClone) {
          handleCancel();
          handleReload(templateClone);
        }
      })
      .catch(errors => {
        const { graphQLErrors } = errors;
        const errMessage = graphQLErrors[0].message;
        setError(errMessage);
        handleError(errMessage);
      });
  };
  const handleClone = data => {
    const variables = {
      templateId: data?.id,
      templateName: data?.name,
      tags: data?.tags,
      expireOn: data?.expireOn,
    };
    handleSubmit(variables);
  };

  return (
    <div>
      <Dialog open={!isClone}>
        <div className={classes.dialogContent}>
          {!isClone && (
            <div>
              <div>
                <Typography variant="h4" gutterBottom>
                  Are you sure?
                </Typography>
                <IconButton
                  onClick={handleClose}
                  aria-label="close"
                  className={classes.closeIcon}
                >
                  <CloseIcon />
                </IconButton>
                <p className={classes.dialogtitle}>
                  {' '}
                  Are you sure you want to clone this document template?
                </p>
              </div>
              <DialogActions className={classes.dialogActions}>
                <Button
                  onClick={() => handleClose()}
                  className={classes.button}
                  color="secondary"
                  autoCapitalize="none"
                  variant="contained"
                >
                  Cancel
                </Button>
                <div className={classes.saveButtonContainer}>
                  <Button
                    onClick={id => handleTextModalOpen(id)}
                    color="primary"
                    variant="contained"
                    data-test-id="DocumentMetaData-saveButton"
                  >
                    Yes, I'm Sure
                  </Button>
                </div>
              </DialogActions>
            </div>
          )}
        </div>
      </Dialog>
      {isClone && (
        <DocumentMetaDataWrapper
          isOpen={isClone}
          isClone={isClone}
          id={id}
          handleCancel={() => handleCancel()}
          handleClone={data => handleClone(data)}
          error={error}
          isLoading={loading}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(DraggableDialog);
