import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import classnames from 'classnames';
const ValidDialogContent = ({
  classes,
  offerId,
  workState,
  workerType,
  MYCNC_W4S_URL,
}) => {
  return (
    <>
      <DialogContent>
        <Typography className={classes.subHead}>
          Sorry, but it looks like you don't have valid tax withholding form.
          Please go to MyCast&Crew to complete the form(s) before you accept
          your offer.
        </Typography>
        <Typography className={classes.contentText}>
          If you need help, contact support:
        </Typography>
        <Typography className={classes.contact}>
          <a href="mailto:plus.support@castandcrew.com">
            plus.support@castandcrew.com
          </a>{' '}
          <a href="tel:818.860.7770">818.860.7770 (United States)</a>
          <a href="tel:416.324.9748">416.324.9748 (Canada)</a>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          className={classnames(classes.btn, classes.btnContained)}
          href={`${MYCNC_W4S_URL}?offerId=${offerId}&w4State=${workState}&workerType=${workerType}`}
        >
          Complete Form
        </Button>
      </DialogActions>
    </>
  );
};

export default ValidDialogContent;
