import React from 'react';
import Loader from 'common/components/Loader';
import { Consumer } from 'common/contextProviders/Authentication';

const withAuthProtection = options => Component => {
  const { fullScreen = true } = options || {};
  const C = props => (
    <Consumer>
      {value => {
        const { accessToken, sessionActive } = value;
        // User is authenticated
        if (accessToken && sessionActive) return <Component {...props} />;
        // Authentication is processing
        return <Loader fullScreen={fullScreen} />;
      }}
    </Consumer>
  );

  C.displayName = `withAuthProtection(${Component.displayName ||
    Component.name})`;
  return C;
};

export default withAuthProtection;
