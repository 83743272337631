import { REQUEST, SUCCESS, FAILURE } from './actionTypes';

export const AUTHENTICATION_STATUS = 'AUTHENTICATION_STATUS';
export const LOGOUT = 'LOGOUT';
export const SESSION_STATUS = 'SESSION_STATUS';
export const REDIRECT_SSO = 'REDIRECT_SSO';

export const checkAuthentication = () => ({
  type: REQUEST(AUTHENTICATION_STATUS),
});

export const getSessionCheckSuccess = () => ({
  type: SUCCESS(SESSION_STATUS),
});

export const authenticationCheckSuccess = () => ({
  type: SUCCESS(AUTHENTICATION_STATUS),
});

export const authenticationCheckFailure = error => ({
  type: FAILURE(AUTHENTICATION_STATUS),
  error,
});

export const logout = ({ withFromURI, fromURI = 'origin' } = {}) => ({
  type: REQUEST(LOGOUT),
  withFromURI,
  fromURI,
});

export const redirectToSSO = () => ({
  type: REQUEST(REDIRECT_SSO),
});
