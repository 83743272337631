import React from 'react';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import ProjectTemplateAutoAssignmentRuleSelect from './ProjectTemplateAutoAssignmentRuleSelect';
import useDepartments from 'common/hooks/useDepartments';

const ProjectTemplateAutoAssignmentRuleDepartmentSelect = props => {
  const {
    className,
    'data-test-id': dataTestId,
    departmentIds,
    onChange,
    projectId,
  } = props;
  const { data: departments, loading } = useDepartments({ projectId });
  const departmentOptions = departments?.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
  return (
    <ProjectTemplateAutoAssignmentRuleSelect
      label="Departments"
      isLoading={loading}
      options={departmentOptions}
      value={departmentIds}
      onChange={departmentIds =>
        onChange(departments.filter(({ id }) => departmentIds.includes(id)))
      }
      data-test-id={dataTestId}
      className={className}
      multiple={true}
    />
  );
};

export default withRouteHelpers(
  ProjectTemplateAutoAssignmentRuleDepartmentSelect,
);
