import gql from 'graphql-tag';

export default gql`
  mutation downloadUserAccessReport(
    $producerId: Int!
    $employeeRole: String!
    $userStatus: String!
  ) {
    downloadUserAccessReport(
      producerId: $producerId
      employeeRole: $employeeRole
      userStatus: $userStatus
    )
  }
`;
