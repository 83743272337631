import uuid from 'uuid';

export const newEmailNotification = (newId, index) => ({
  id: newId,
  ruleType: 'email',
  triggerFieldId: null,
  description: '',
  triggerAction: { triggerActionType: null, value: null },
  fieldGroups: [
    { id: `__new-${uuid()}`, name: 'New Field Group', fieldIds: [] },
  ],
  name: `New Email Notification - ${index + 1}`,
  emailSubject: '',
  emailContent:
    'You received this alert because of a possible conflict of interest regarding a crew member. We have supplied the information below so you can make the appropriate adjustments.',
  emailProjectExplainer: '',
  emailAttached: false,
});

export const newBadge = ({ name, description }) => ({
  id: name,
  ruleType: 'badge',
  triggerFieldId: null,
  triggerAction: { triggerActionType: null, value: null },
  fieldGroups: [
    { id: `__new-${uuid()}`, name: 'New Field Group', fieldIds: [] },
  ],
  badgeType: name,
  name: description,
  description,
});

export const getEmailId = (emails, fieldId) => {
  let emailIds = [];
  emails.forEach(({ fieldGroups = [], id }) =>
    fieldGroups.forEach(({ fieldIds }) => {
      if (fieldIds.includes(fieldId)) {
        emailIds.push(id);
      }
    }),
  );
  return emailIds;
};
