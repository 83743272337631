import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const MUTATION = gql`
  mutation exportCrew($projectId: Int!) {
    exportCrew(projectId: $projectId)
  }
`;

const useExportCrew = () => useMutation(MUTATION);

export default useExportCrew;
