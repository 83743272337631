import { useQuery } from '@apollo/react-hooks';
import { Queries } from 'common/apollo';

const useCities = variables => {
  const { countryId, stateId } = variables;
  const skip = !countryId || !stateId;
  const { loading, data } = useQuery(Queries.GetCities, {
    skip,
    variables,
    notifyOnNetworkStatusChange: true,
  });
  return { loading, data: data && data.cities };
};

export default useCities;
