import { REQUEST, SUCCESS, FAILURE } from './actionTypes';

export const SESSION_STATUS = 'SESSION_STATUS';

export const getSessionStatus = () => ({
  type: REQUEST(SESSION_STATUS),
});

export const sessionStatusSuccess = () => ({
  type: SUCCESS(SESSION_STATUS),
});

export const sessionStatusFailure = error => ({
  type: FAILURE(SESSION_STATUS),
  error,
});
