import React from 'react';
import domClasses from '../../utils/dom/classes.js';
var NavBar = null;

NavBar = class extends React.Component {
  render() {
    var classes = null;

    classes = domClasses.set({
      navBar: true,
      'navBar--important': this.props.important,
    });

    return <div className={classes}>{this.props.children}</div>;
  }
};

NavBar.Item = class extends React.Component {
  render() {
    const { className } = this.props;
    const classes = domClasses.set({
      'navBar-item': true,
      'navBar-item--secondary': this.props.secondary,
      'navBar-item--tertiary': this.props.tertiary,
      [className]: true,
    });

    return <div className={classes}>{this.props.children}</div>;
  }
};

export default NavBar;
