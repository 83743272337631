import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const QUERY = gql`
  query stateW4DownloadUrl(
    $offerId: Int!
    $source: OfferExternalDocumentSource!
    $type: OfferExternalDocumentType!
  ) {
    offerExternalDocuments(offerId: $offerId, source: $source, type: $type) {
      downloadUrl
    }
  }
`;

const useStateW4Download = (offerId, source, type) => {
  const { error, loading, data } = useQuery(QUERY, {
    skip: !offerId,
    fetchPolicy: 'no-cache',
    variables: { offerId, source, type },
  });
  return { error, loading, data };
};

export default useStateW4Download;
