import React, { useCallback, useEffect, useState } from 'react';
import merge from 'lodash.merge';

import DialogContent from '@mui/material/DialogContent';

import DocumentFieldGroupWrapper from './DocumentFieldGroupWrapper';
import offerValidationChecks from '../../OfferPage/helpers/offerValidationChecks';
import ScrollDestination from 'common/components/ScrollDestination';
import ActionButtons from './ActionButtons';

import helperFunctions from './helpers';

const CustomQuestions = ({ classes, onNext, data }) => {
  const { documentFields: propsDocumentFields = [] } = data;
  const [documentFields, setDocumentFields] = useState(
    data?.documentFields && data?.documentFields.length
      ? data?.documentFields
      : [],
  );
  const [formErrors, setFormErrors] = useState([]);
  const [scrollDestinationKey, setScrollDestinationKey] = useState();

  const { documents } = data;
  const checkedDocumentsIds = helperFunctions.checkedDocumentsIds(documents);

  useEffect(() => {
    if (propsDocumentFields.length > 0) {
      setDocumentFields(propsDocumentFields);
      return;
    }
  }, [propsDocumentFields]);

  // next screen - add note
  const handleNext = () => {
    if (validationErrors()) return;
    onNext('note', {
      documentFields,
      documents: mergeDocumentsAndModifiedDocuments(),
    });
  };

  // back to previous screen - documents
  const handleBack = () => {
    onNext('documents', {
      documentFields: [],
      documents: mergeDocumentsAndModifiedDocuments(),
    });
  };

  const validationErrors = useCallback(() => {
    const documentData = {
      documents: checkedDocumentsIds,
      documentFields,
    };

    let results = offerValidationChecks.map(test =>
      test(documentData, [], {
        templates: documents,
      }),
    );

    const newFormErrors = merge(
      {},
      ...results.filter(result => result !== true),
    );

    setFormErrors(newFormErrors);
    setScrollDestinationKey('documentFields');
    if (
      newFormErrors?.documentFields &&
      newFormErrors?.documentFields.length > 0
    )
      return true;
    else return false;
  }, [documentFields, checkedDocumentsIds, documents]);

  useEffect(() => {
    validationErrors();
  }, [documentFields]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (fieldId, newValue, id) => {
    // Remove field from documentFields
    const newDocumentFields = documentFields.filter(({ id }) => id !== fieldId);
    // If value is present, add field with new value

    newDocumentFields.push({
      id: fieldId,
      value: newValue,
      documentId: id,
    });

    // Update form state
    setDocumentFields(newDocumentFields);
  };

  const getStatefulDocumentFieldsTree = (templates = [], formData = {}) => {
    const {
      documents: selectedDocumentIds = [],
      documentFields = [],
    } = formData;

    return templates
      .filter(
        ({ id, inputFieldGroups }) =>
          selectedDocumentIds.includes(id) && inputFieldGroups.length,
      )
      .map(template => ({
        ...template,
        inputFieldGroups: template.inputFieldGroups.map(inputFieldGroup => ({
          ...inputFieldGroup,
          fields: inputFieldGroup.fields.map(field => ({
            ...field,
            value:
              documentFields.find(({ id }) => id === field.id)?.value || '',
          })),
        })),
      }));
  };

  const modifiedDocuments = getStatefulDocumentFieldsTree(documents, {
    documentFields,
    documents: checkedDocumentsIds,
  });

  const mergeDocumentsAndModifiedDocuments = () => {
    return documents.map(document => {
      const modifiedDocument = modifiedDocuments.find(
        ({ id }) => id === document.id,
      );
      if (modifiedDocument) {
        return {
          ...document,
          ...modifiedDocument,
        };
      }
      return document;
    });
  };

  const documentFieldsFormErrors = formErrors?.documentFields || [];

  return (
    <>
      <DialogContent data-test-id="custom-questions-section">
        <ScrollDestination
          isActive={scrollDestinationKey === 'documentFields'}
          behavior="smooth"
          block="start"
        >
          {modifiedDocuments.map(({ id, name, inputFieldGroups, checked }) => {
            return (
              checked && (
                <DocumentFieldGroupWrapper
                  key={id}
                  templateName={name}
                  onChange={(fieldId, val) => onChange(fieldId, val, id)}
                  inputFieldGroups={inputFieldGroups}
                  formErrors={[]}
                />
              )
            );
          })}
          {modifiedDocuments.length === 0 && (
            <p className={classes.noQuestionsText}>
              No custom questions for the selected documents
            </p>
          )}
        </ScrollDestination>
      </DialogContent>
      <ActionButtons
        onBack={handleBack}
        onNext={handleNext}
        classes={classes}
        disabled={documentFieldsFormErrors.length > 0}
      />
    </>
  );
};

export default CustomQuestions;
