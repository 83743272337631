import React from 'react';
import { withStyles } from '@mui/styles';

// MaterialUI Components
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// Custom Components
import SystemFieldSelect from 'admin/components/RoleMapper/SystemFieldSelect';
// Utilities
import isDocCollectSystemField from 'common/utilities/isDocCollectSystemField';
import * as FieldTypes from 'common/utilities/constants/fieldTypes';

const styles = {
  drawerText: {
    fontWeight: 600,
  },
};

const PrepopulateSection = props => {
  const {
    classes = {},
    tags = [],
    fieldValues = {},
    selectSystemField,
    handleOpenMenu,
    updateField,
    disabled: upstreamDisabled = false,
    multiLevelMenuProps = {},
  } = props;

  const { textType, customProperties = {}, systemField = {} } = fieldValues;
  const { collectSystemField = false } = customProperties || {};
  const isW4OrW9 = tags.some(tag => isDocCollectSystemField(tag.name));
  const isSystemField = systemField.label && systemField.label !== 'None';
  const isDisabled =
    textType === FieldTypes.SIGNATURE.toLowerCase() ||
    textType === FieldTypes.INITIAL_SIGNATURE.toLowerCase();
  const menuIcon = systemField.name ? <CloseIcon /> : <SearchIcon />;
  const iconAction = systemField.name
    ? () => selectSystemField({ label: 'None', name: '' })
    : handleOpenMenu;
  return (
    <React.Fragment>
      <SystemFieldSelect
        onClick={iconAction}
        disabled={isDisabled || upstreamDisabled}
        menuIcon={menuIcon}
        multiLevelMenuProps={multiLevelMenuProps}
        systemField={systemField}
      />
      <div>
        <Typography className={classes.drawerText}>
          Collect System Field Data from User Input:
        </Typography>
        <FormControlLabel
          key="collect-system-field-checkbox"
          control={
            <Checkbox
              checked={collectSystemField}
              onChange={() =>
                updateField(
                  !collectSystemField,
                  'customProperties',
                  'collectSystemField',
                )
              }
              disabled={isW4OrW9 || !isSystemField || upstreamDisabled}
              data-test-id="FieldDrawer-collectCheckbox"
              color="secondary"
            />
          }
          label="Collect from user"
        />
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(PrepopulateSection);
