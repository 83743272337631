import React from 'react';

// Components
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const AccountCodeTableHead = props => {
  const { accountCodeConfigurations = [], classes = {} } = props || {};

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.headerCell} />
        {accountCodeConfigurations.map(({ label, required } = {}, index) => (
          <TableCell
            align="center"
            className={classes.headerCell}
            key={`${label}-${index}`}
          >
            <div className={classes.headerCellContainer}>
              {label}
              {required && (
                <Typography className={classes.requiredFlag} color="error">
                  *
                </Typography>
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default AccountCodeTableHead;
