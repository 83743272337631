import React from 'react';
import { Link } from 'react-router-dom';
import {
  Badge,
  Divider,
  AccordionDetails,
  Button,
  Typography,
} from '@mui/material';
import uniqBy from 'common/utilities/uniqBy';
import truncate from 'common/utilities/truncate';
import DocumentNotifications from './DocumentNotifications';
import DocumentDestination from './DocumentDestination';
import ProjectTemplateAutoAssignmentRules from './ProjectTemplateAutoAssignmentRules';
import SupportDocument from './SupportingDocumentSetup';
import AcknowledgmentSetup from './AcknowledgmentSetup';
// import ProjectTemplateWorkflows from './ProjectTemplateWorkflows';

const MAX_ACKNOWLEDGMENT_DESCRIPTION_LENGTH = 200;
const DEFAULT_ACKNOWLEDGEMENT_TITLE = 'Acknowledgement of Receipt';
const DEFAULT_ACKNOWLEDGEMENT_DESCRIPTION =
  'I acknowledge receipt of, and agree to read and adhere to, all guidelines and policies and procedures provided in my offer documents.';

const ProjectTemplateBody = props => {
  const {
    acknowledgeNotes,
    acknowledgeOptions,
    acknowledgeTitle,
    acknowledgeType,
    id,
    isExpanded,
    onSetGqlCallback,
    supportDocument,
    updateDoc,
    users,
    workflow,
    workflowsCount,
    projectCountryId,
    classes,
  } = props;
  const { levels = [] } = workflow;
  const workflowDisabled = !!acknowledgeType;

  const acknowledgmentDisabled = !!(
    workflow &&
    workflow.levels &&
    workflow.levels.length
  );

  const levelNames = levels
    .filter(level => !level.requireAll)
    .map(level => level.levelName);

  const rawApprovers = levels
    .filter(level => level.requireAll)
    .map(level =>
      level.approvers.map(({ id }) => {
        const approver = users.find(user => user.id === id) || {};
        return {
          id,
          fullName: approver.fullName,
        };
      }),
    )
    .flat();
  const approvers = uniqBy(rawApprovers, 'id');

  const updateRules = value => {
    const key = 'auto_assign_rules';
    updateDoc({ id, key, value });
  };

  const updateWorkflow = value => {
    const key = 'workflow';
    const subKey = 'levels';
    updateDoc({ id, value, key, subKey });
    if (value && value.length) updateAcknowledgment('acknowledgeType', '');
  };

  const updateAcknowledgment = (key, value) => {
    switch (key) {
      case 'acknowledgeType':
        updateDoc({ id, key: 'acknowledge_type', value });
        const isCt = value === 'C';
        updateDoc({
          id,
          key: 'acknowledge_title',
          value: isCt ? DEFAULT_ACKNOWLEDGEMENT_TITLE : null,
        });
        updateDoc({
          id,
          key: 'acknowledge_notes',
          value: isCt ? DEFAULT_ACKNOWLEDGEMENT_DESCRIPTION : null,
        });
        if (value) updateWorkflow([]);
        return;
      case 'acknowledgeNotes':
        value = truncate(value, MAX_ACKNOWLEDGMENT_DESCRIPTION_LENGTH);
        updateDoc({ id, key: 'acknowledge_notes', value });
        return;
      case 'acknowledgeTitle':
        updateDoc({ id, key: 'acknowledge_title', value });
        return;
      default:
        throw new Error();
    }
  };

  const setSupportDocument = supportDocument => {
    updateDoc({ id, key: 'supportDocument', value: supportDocument });
  };

  const addSupportDocument = () => {
    setSupportDocument({
      name: '',
      isRequired: false,
      primaryResponsibilityHolder: '',
      isIncluded: false,
    });
  };

  const updateSupportDocument = patch => {
    if (patch && patch.isRequired === false) {
      patch = { ...patch, primaryResponsibilityHolder: '' };
    }
    setSupportDocument({ ...supportDocument, ...patch });
  };

  const deleteSupportDocument = () => {
    setSupportDocument(null);
  };

  return (
    <React.Fragment>
      <AccordionDetails>
        {isExpanded && <DocumentNotifications projectTemplateId={id} />}
      </AccordionDetails>

      <Divider />

      <AccordionDetails>
        {isExpanded && (
          <DocumentDestination
            projectTemplateId={id}
            updateDoc={updateDoc}
            onSetGqlCallback={onSetGqlCallback}
          />
        )}
      </AccordionDetails>

      <Divider />

      <AccordionDetails>
        <div style={{ width: '100%' }}>
          {isExpanded && (
            <ProjectTemplateAutoAssignmentRules
              projectTemplateId={id}
              onChange={updateRules}
              projectCountryId={projectCountryId}
            />
          )}
        </div>
      </AccordionDetails>

      <Divider />

      <AccordionDetails>
        <div style={{ width: '100%' }}>
          <Typography variant="h6" gutterBottom>
            Workflows
          </Typography>
          {workflowDisabled ? (
            <Typography
              variant="body1"
              data-test-id="ProjectTemplateBody-workflowsDisabled"
            >
              You cannot add workflows because acknowledgements are configured.
            </Typography>
          ) : (
            <Badge
              badgeContent={workflowsCount}
              color="secondary"
              classes={{ badge: classes.badge }}
              data-test-id="ProjectTemplateBody-workflowsCount"
            >
              <Link
                to={`project-templates/${id}/workflows`}
                data-test-id="ProjectTemplateBody-workflowsLink"
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="contained"
                  data-test-id="ProjectTemplateBody-workflowsBtn"
                  className={classes.containedButton}
                >
                  {workflowsCount ? 'View' : 'Add'} Workflows
                </Button>
              </Link>
            </Badge>
          )}
        </div>
      </AccordionDetails>

      <Divider />

      <AccordionDetails>
        {workflowsCount ? (
          <div data-test-id="ProjectTemplateBody-acknowledgmentDisabled">
            <Typography variant="h6" gutterBottom>
              Acknowledgment of Receipt
            </Typography>
            <Typography variant="body1">
              You cannot require acknowledgment because workflows are
              configured.
            </Typography>
          </div>
        ) : (
          <AcknowledgmentSetup
            acknowledgeNotes={acknowledgeNotes}
            acknowledgeOptions={acknowledgeOptions}
            acknowledgeTitle={acknowledgeTitle}
            acknowledgeType={acknowledgeType}
            updateHandler={updateAcknowledgment}
            disabled={acknowledgmentDisabled}
          />
        )}
      </AccordionDetails>

      <Divider />

      <AccordionDetails>
        <SupportDocument
          supportDocument={supportDocument}
          addHandler={addSupportDocument}
          updateHandler={updateSupportDocument}
          deleteHandler={deleteSupportDocument}
          approvers={approvers}
          levelNames={levelNames}
        />
      </AccordionDetails>
    </React.Fragment>
  );
};

export default ProjectTemplateBody;
