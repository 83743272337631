import React, { useState } from 'react';
import { withStyles, ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import ProjectTemplateAutoAssignmentRule from './ProjectTemplateAutoAssignmentRule';
import useAutoAssignmentRules from './useAutoAssignmentRules';
import Loader from 'common/components/Loader';
import useSyncedState from 'common/hooks/useSyncedState';
import uniqueId from 'common/utilities/uniqueId';
import { RULE_TYPES } from './constants/ruleTypes';
import ProjectTemplateAutoAssignmentRuleAllRequiredCheckbox from './checkboxes/ProjectTemplateAutoAssignmentRuleAllRequiredCheckbox';
import { stableSort, getSorting } from 'common/utilities/stableSort';
import { ALLOWANCE_TYPES } from './constants/allowanceTypes';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0000FF',
    },
  },
});

const styles = theme => ({
  rulesList: {
    marginTop: theme.spacing(1),
    flexGrow: 1,
  },
  headingText: {
    padding: `${theme.spacing(1)}px 0px`,
  },
  rulesRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  button: {
    marginLeft: theme.spacing(1),
    color: 'white',
    height: 40,
    borderRadius: '2px',
    backgroundColor: '#0000FF',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#1A55FD',
    },
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  allRequired: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const convertRulesToLegacyRestApiRepresentation = rules => {
  const finalRules = rules
    .map(rule => {
      if (!rule.__typename) return null;
      const ruleType = rule.__typename.slice(0, -'AutoAssignmentRule'.length);
      const definedRuleType = RULE_TYPES.find(
        ({ typeName }) => typeName === ruleType,
      );
      const ruleTypeId = definedRuleType?.id;
      const ruleName = definedRuleType?.typeName;
      const legacyRule = {
        ruleType: { id: ruleTypeId, description: ruleType },
      };
      const {
        departments = {},
        unions = {},
        hireStates = {},
        workStates = {},
        allowances = {},
      } = rule;
      const departmentOptions = departments?.selection;
      const unionOptions = unions?.selection;
      const hireStatesOptions = hireStates?.selection;
      const workStatesOptions = workStates?.selection;
      const allowancesOptions = allowances?.selection;
      legacyRule.departments = {};
      legacyRule.unions = {};
      legacyRule.hireStates = {};
      legacyRule.workStates = {};
      legacyRule.allowances = {};
      const isCombo = ruleName === 'Combo';
      if ((isCombo || ruleName === 'Department') && !!departmentOptions) {
        legacyRule.departments.departments = stableSort(
          departmentOptions,
          getSorting('asc', 'id'),
        ).map(({ id }) => ({ id }));
        legacyRule.departments.all_except = departments?.allExcept || false;
      }
      if ((isCombo || ruleName === 'Allowances') && !!allowancesOptions) {
        legacyRule.allowances.allowances = stableSort(
          allowancesOptions,
          getSorting('asc', 'id'),
        ).map(({ code }) => code);
        legacyRule.allowances.all_except = allowances?.allExcept || false;
      }
      if ((isCombo || ruleName === 'Union') && !!unionOptions) {
        legacyRule.unions.unions = stableSort(
          unionOptions,
          getSorting('asc', 'code'),
        ).map(({ code, description, isNonUnion }) => ({
          code,
          description,
          is_non_union: isNonUnion,
        }));
        legacyRule.unions.all_except = unions?.allExcept || false;
      }
      if (isCombo || ruleName === 'Location') {
        if (!!hireStatesOptions) {
          legacyRule.hireStates.hire_states = stableSort(
            hireStatesOptions,
            getSorting('asc', 'id'),
          ).map(({ id, code, name }) => ({ id, code, name }));
          legacyRule.hireStates.all_except = hireStates?.allExcept || false;
        }
        if (!!workStatesOptions) {
          legacyRule.workStates.work_states = stableSort(
            workStatesOptions,
            getSorting('asc', 'id'),
          ).map(({ id, code, name }) => ({ id, code, name }));
          legacyRule.workStates.all_except = workStates?.allExcept || false;
        }
        legacyRule.workCity = workStates?.allExcept
          ? {}
          : {
              ...rule.workCity,
            };
      }
      if (isCombo || ruleName === 'EmploymentType') {
        legacyRule.employmentType = { id: rule.employmentType?.id };
      }
      legacyRule.required = rule.required;
      return legacyRule;
    })
    .filter(x => x);
  finalRules.errors = rules?.errors;
  return finalRules;
};

const ProjectTemplateAutoAssignmentRules = props => {
  const {
    classes,
    isTemplateRequired,
    onChange: onChangeUpstream,
    projectTemplateId,
    projectCountryId,
  } = props;

  // TODO: Once project templates has been fully converted to gql, all the state
  // should be lifted & centralized into the project template root component.
  const { data: upstreamRules } = useAutoAssignmentRules(
    { projectTemplateId },
    {
      // Always get the data from the server. This is necessary because we do
      // not yet have a graphql mutation for updating auto assignment rules, so
      // we can't intelligently re-fetch the queries.
      fetchPolicy: 'network-only',
    },
  );
  const updatedRules = upstreamRules?.map(rule => {
    const ruleType = rule?.__typename?.slice(0, -'AutoAssignmentRule'.length);
    if (
      (ruleType === 'Allowances' || ruleType === 'Combo') &&
      rule?.allowances?.selection?.length > 0 &&
      typeof rule?.allowances?.selection[0] === 'string'
    ) {
      const updatedAllowances = rule?.allowances?.selection?.map(
        allowanceCode =>
          ALLOWANCE_TYPES.find(({ code }) => allowanceCode === code),
      );
      rule.allowances.selection = [...updatedAllowances];
    }
    return rule;
  });
  const [initialRulesLoad, setInitialRulesLoad] = useState(false);
  const [rules, setRulesLocal] = useSyncedState(updatedRules, !!updatedRules);
  const [ruleIdBeingEdited, setRuleIdBeingEdited] = useState(null);
  const [isDuplicateRule, setIsDuplicateRule] = useState(false);
  const [isComboNotEligible, setComboNotEligible] = useState(false);

  if (!rules) return <Loader />;

  const isAllChecked = rules.every(rule => rule.required);
  const isEmptyRule = rules.some(rule => !rule?.__typename);
  const isAll = rules.some(rule => rule.__typename === 'AllAutoAssignmentRule');
  const showRules = !isTemplateRequired;

  const setRules = newRules => {
    setRulesLocal(newRules);
    // Translate new GQL representation to old REST representation & push upstream.
    // TODO: Remove this once whole page is GQL
    const rulesForRestApi = convertRulesToLegacyRestApiRepresentation(newRules);
    onChangeUpstream(rulesForRestApi);
  };

  // Add auto_assignment_rules key in Legacy data.
  if (!initialRulesLoad) {
    setRules(rules);
    setInitialRulesLoad(true);
  }

  const deleteRule = id => setRules(rules.filter(rule => rule.id !== id));

  const checkDuplicateRule = editedRule => {
    const {
      id: editedRuleId,
      __typename: editedRuleType,
      departments: editedDepartments = [],
      unions: editedUnions = [],
      hireStates: editedHireStates = [],
      workStates: editedWorkStates = [],
      allowances: editedAllowances = [],
      workCity: editedWorkCity = null,
      employmentType: editedEmploymentType = null,
    } = editedRule;

    const otherRules = rules.filter(
      ({ id, __typename: ruleType }) =>
        id !== editedRuleId && ruleType === editedRuleType,
    );
    return otherRules.some(rule => {
      const {
        __typename,
        departments = [],
        unions = [],
        hireStates = [],
        workStates = [],
        allowances = [],
        workCity = null,
        employmentType = null,
      } = rule;
      const ruleType = __typename.slice(0, -'AutoAssignmentRule'.length);
      const definedRuleType = RULE_TYPES.find(
        ({ typeName }) => typeName === ruleType,
      );
      const ruleName = definedRuleType?.typeName;
      const isCombo = ruleName === 'Combo';
      const flags = [];
      if (isCombo || ruleName === 'EmploymentType') {
        if (!!employmentType && !!editedEmploymentType) {
          flags.push(
            parseInt(employmentType?.id) === parseInt(editedEmploymentType?.id),
          );
        } else if (!!employmentType || !!editedEmploymentType)
          flags.push(false);
      }
      if (isCombo || ruleName === 'Department') {
        if (
          !!departments?.selection &&
          !!editedDepartments?.selection &&
          editedDepartments?.selection.length > 0
        ) {
          const departmentIds = departments?.selection.map(({ id }) => id);
          const editedDepartmentIds = editedDepartments?.selection.map(
            ({ id }) => id,
          );
          flags.push(
            editedDepartmentIds.every(id => departmentIds.includes(id)),
          );
        } else if (
          (Array.isArray(departments?.selection) &&
            departments?.selection.length > 0) ||
          (Array.isArray(editedDepartments?.selection) &&
            editedDepartments?.selection.length > 0)
        )
          flags.push(false);
      }
      if (isCombo || ruleName === 'Union') {
        if (
          !!unions?.selection &&
          !!editedUnions?.selection &&
          editedUnions?.selection.length > 0
        ) {
          const editedUnionCodes = editedUnions?.selection.map(
            ({ code }) => code,
          );
          const unionCodes = unions?.selection.map(({ code }) => code);
          flags.push(editedUnionCodes.every(id => unionCodes.includes(id)));
        } else if (
          (Array.isArray(unions?.selection) && unions?.selection?.length > 0) ||
          (Array.isArray(editedUnions?.selection) &&
            editedUnions?.selection.length > 0)
        )
          flags.push(false);
      }
      if (isCombo || ruleName === 'Allowances') {
        if (
          !!allowances?.selection &&
          !!editedAllowances?.selection &&
          editedAllowances.selection.length > 0
        ) {
          const editedAllowanceCodes = editedAllowances?.selection.map(
            ({ code }) => code,
          );
          const allowanceCodes = allowances?.selection.map(({ code }) => code);
          flags.push(
            editedAllowanceCodes.every(id => allowanceCodes.includes(id)),
          );
        } else if (
          (Array.isArray(allowances?.selection) && allowances.length > 0) ||
          (Array.isArray(editedAllowances?.selection) &&
            editedAllowances?.selection.length > 0)
        )
          flags.push(false);
      }
      if (isCombo || ruleName === 'Location') {
        if (
          !!hireStates?.selection &&
          !!editedHireStates?.selection &&
          editedHireStates?.selection.length > 0
        ) {
          const editedHireStateIds = editedHireStates?.selection?.map(
            ({ id }) => id,
          );
          const hireStateIds = hireStates?.selection.map(({ id }) => id) || [];
          flags.push(editedHireStateIds.every(id => hireStateIds.includes(id)));
        } else if (
          (Array.isArray(hireStates?.selection) &&
            hireStates?.selection.length > 0) ||
          (Array.isArray(editedHireStates?.selection) &&
            editedHireStates?.selection.length > 0)
        )
          flags.push(false);

        if (
          !!workStates?.selection &&
          !!editedWorkStates?.selection &&
          editedWorkStates?.selection.length > 0
        ) {
          const editedWorkStateIds = editedWorkStates?.selection.map(
            ({ id }) => id,
          );
          const workStateIds = workStates?.selection.map(({ id }) => id) || [];
          flags.push(editedWorkStateIds.every(id => workStateIds.includes(id)));
        } else if (
          (Array.isArray(workStates?.selection) &&
            workStates?.selection?.length > 0) ||
          (Array.isArray(editedWorkStates?.selection) &&
            editedWorkStates?.selection.length > 0)
        )
          flags.push(false);

        if (!!workCity && !!editedWorkCity) {
          flags.push(parseInt(workCity?.id) === parseInt(editedWorkCity?.id));
        } else if (!!workCity || !!editedWorkCity) flags.push(false);
      }
      return flags.every(Boolean);
    });
  };

  const checkComboEligible = editedRule => {
    const {
      departments = {},
      unions = {},
      hireStates = {},
      workStates = {},
      allowances = {},
      employmentType = null,
    } = editedRule;
    let ruleTypesCount = 0;
    if (employmentType) {
      ruleTypesCount = ruleTypesCount + 1;
    }
    if (unions?.selection?.length > 0) {
      ruleTypesCount = ruleTypesCount + 1;
    }
    if (departments?.selection?.length > 0) {
      ruleTypesCount = ruleTypesCount + 1;
    }
    if (allowances?.selection?.length > 0) {
      ruleTypesCount = ruleTypesCount + 1;
    }
    if (hireStates?.selection?.length > 0 || workStates.selection?.length > 0) {
      ruleTypesCount = ruleTypesCount + 1;
    }
    return ruleTypesCount >= 2 ? false : true;
  };

  const updateRule = updatedRule => {
    setIsDuplicateRule(checkDuplicateRule(updatedRule));
    const typeOfRule = updatedRule?.__typename.slice(
      0,
      -'AutoAssignmentRule'.length,
    );
    const isCombo = typeOfRule === 'Combo';
    if (isCombo) {
      setComboNotEligible(checkComboEligible(updatedRule));
    } else {
      setComboNotEligible(false);
    }
    const newRules = rules.map(rule =>
      rule.id === updatedRule.id ? updatedRule : rule,
    );
    newRules.errors = {
      duplicate: checkDuplicateRule(updatedRule),
      comboNotEligible: isCombo ? checkComboEligible(updatedRule) : false,
    };
    setRules(newRules);
  };

  const addRule = () => {
    const id = `new-rule-${uniqueId()}`;
    setRules(rules.concat({ id }));
    setIsDuplicateRule(false);
    setRuleIdBeingEdited(id);
  };

  const toggleRule = id =>
    setRuleIdBeingEdited(id === ruleIdBeingEdited ? null : id);

  const checkAll = checked => {
    const newRules = rules.map(rule => {
      return {
        ...rule,
        required: checked,
      };
    });

    setRules(newRules);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.rulesList}>
        <div className={classes.headingText}>
          <Typography variant="h6" gutterBottom>
            Auto Assignments
          </Typography>

          <div className={classes.allRequired}>
            <Typography variant="body1">
              Offers that match the rules below will have this document enabled
              by default.
            </Typography>
            {rules.length > 0 && (
              <ProjectTemplateAutoAssignmentRuleAllRequiredCheckbox
                onCheckboxChange={checkAll}
                value={isAllChecked}
              />
            )}
          </div>
        </div>
        <div className={classes.rulesRow}>
          {rules.map(rule => (
            <ProjectTemplateAutoAssignmentRule
              key={`rule-${rule.id}`}
              rule={rule}
              onChange={updateRule}
              onDelete={() => deleteRule(rule.id)}
              onToggleEdit={() => toggleRule(rule.id)}
              isBeingEdited={ruleIdBeingEdited === rule.id}
              isDuplicateRule={isDuplicateRule}
              isComboNotEligible={isComboNotEligible}
              projectCountryId={projectCountryId}
            />
          ))}
        </div>
        <div className={classes.buttonRow}>
          <Button
            className={classes.button}
            onClick={addRule}
            disabled={!showRules || isAll || isEmptyRule}
            variant="contained"
            size="small"
            data-test-id={`RuleList-add-${projectTemplateId}`}
          >
            <AddIcon />
            Add New Rule
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default withStyles(styles)(ProjectTemplateAutoAssignmentRules);
