import { REQUEST, SUCCESS, FAILURE } from 'common/store/actions/actionTypes';
import { ACCESS_TOKEN } from 'common/store/actions/tokens';

export const initialState = {
  accessToken: '',
  expiresAt: null,
  accessTokenRequestInProgress: false,
  accessTokenFailureCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACCESS_TOKEN):
      return {
        ...state,
        accessTokenRequestInProgress: true,
      };
    case SUCCESS(ACCESS_TOKEN):
      return {
        ...initialState,
        accessToken: action.accessToken,
        expiresAt: action.expiresAt,
      };
    case FAILURE(ACCESS_TOKEN):
      return {
        ...state,
        accessTokenFailureCount: state.accessTokenFailureCount + 1,
      };
    default:
      return state;
  }
};
