import React, { useState } from 'react';
import { TableRow, TableCell, Tooltip } from '@mui/material';

import useOfferExternalDocuments from './hooks/useOfferExternalDocuments';

import Loader from 'common/components/Loader';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';

import FILE_ICON from 'common/images/file.svg';
import DOWNLOAD_ICON from 'common/images/download.svg';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import useDownloadOfferExternalDocument from './hooks/useDownloadOfferExternalDocument';

const downloadStartNotification = {
  message:
    'Hold on a moment while we prepare the document for download. ' +
    'Your download should being momentarily.',
  variant: SnackbarVariants.INFO,
};

const downloadEndNotification = {
  message: 'Your download has started.',
  variant: SnackbarVariants.SUCCESS,
};

const downloadErrorNotification = {
  message: 'Download failed.',
  variant: SnackbarVariants.ERROR,
};

const OfferExternalDocuments = props => {
  const {
    offerId,
    classes = {},
    pushSnackbarNotification,
    clearSnackbarNotification,
  } = props;
  const [download, setDownload] = useState(null);

  const {
    data: externalDocuments = [],
    loading: loadingDocuments,
    error: errorDocuments,
  } = useOfferExternalDocuments(offerId);
  const {
    data: downloadDoc,
    loading: loadingDownload,
    error: errorDownload,
  } = useDownloadOfferExternalDocument(offerId, download);

  if (loadingDocuments) return <Loader size={24} />;
  if (errorDocuments) return <FailureAlert />;

  if (download) {
    clearSnackbarNotification();

    if (errorDownload) {
      setDownload(null);
      pushSnackbarNotification(downloadStartNotification);
    } else if (downloadDoc) {
      setDownload(null);
      const { downloadUrl } = downloadDoc;
      if (downloadUrl) {
        pushSnackbarNotification(downloadEndNotification);
        window.location.href = downloadUrl;
      } else {
        pushSnackbarNotification(downloadErrorNotification);
      }
    } else {
      pushSnackbarNotification(downloadStartNotification);
    }
  }

  const isDocDownloading = documentId =>
    loadingDownload && download === documentId;

  return (externalDocuments || []).map(({ id, documentId, name }) => (
    <TableRow
      className={classes.tableRow}
      key={`external-document-${id}`}
      data-test-id={`ExternalDocumentRow-${id}`}
    >
      <TableCell className={classes.documentName}>
        <img src={FILE_ICON} alt="file icon" className={classes.fileIcon} />
        {name}
      </TableCell>
      <TableCell
        style={{ color: '#008006' }}
        className={classes.itemStatus}
        data-test-id="LoanoutDocumentRow-status"
      >
        Approved
      </TableCell>
      <TableCell className={classes.downloadCell}>
        {isDocDownloading(documentId) && <Loader size={24} />}
        {!isDocDownloading(documentId) && (
          <Tooltip title="Download" placement="top">
            <img
              src={DOWNLOAD_ICON}
              alt="Download icon"
              className={classes.downloadIcon}
              onClick={() => setDownload(id)}
              data-test-id="LoanoutDocumentRow-downloadIcon"
            />
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  ));
};

export default withSnackbarNotification(OfferExternalDocuments);
