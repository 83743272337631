import React from 'react';

// Component
import CheckboxInput from './CheckboxInput';
import DropdownInput from './DropdownInput';
import RadioInput from './RadioInput';
import TextInput from './TextInput';

const componentMap = {
  CHK: CheckboxInput,
  CMB: DropdownInput,
  RDO: RadioInput,
  TXT: TextInput,
};

const DocumentField = props => {
  const { fieldType = 'TXT' } = props;
  const { [fieldType]: Component } = componentMap;
  return <Component {...props} />;
};

export default DocumentField;
