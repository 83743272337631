import React, { useEffect } from 'react';
import Select from 'react-select';
import Typography from '@mui/material/Typography';
import { withRouter } from 'react-router';
import formatOptionLabel from './formatOptionLabel';
import useUnions from './hooks/useUnions';

const UnionSelect = props => {
  const {
    onChange: upstreamOnChange,
    formData = {},
    error = '',
    selectStyles,
    classes = {},
    disabled,
    match: { params: { projectId } = {} },
    countryId,
  } = props;
  const {
    startDateObject,
    workState,
    hireState,
    workCity,
    hireCity,
    currency,
    season,
    union,
  } = formData;
  const { value: unionValue } = union || {};

  const {
    loading,
    data: unions = [],
    error: { message: graphQlError = '' } = {},
  } = useUnions({
    projectId: parseInt(projectId, 10),
    startDate: (startDateObject && startDateObject.format('YYYY-MM-DD')) || '',
    workState,
    hireState,
    workCity,
    hireCity,
    currency,
    season,
    countryId,
  });

  // Format options for react-select
  const options = unions.map(u => {
    const { code, description } = u;
    return {
      ...u,
      value: code,
      label: description,
    };
  });

  // We only store the code in our formData but we need to supply the formatted option
  // as the selected value
  const value = options.find(({ value }) => unionValue === value) || null;

  const onChange = union => {
    upstreamOnChange({
      union,
      occupation: null,
      workSchedule: null,
      payAtRollback: null,
    });
  };

  useEffect(() => {
    if (formData?.union) return;
    let options = unions.map(u => {
      const { code, description } = u;
      return {
        ...u,
        value: code,
        label: description,
      };
    });
    if (options.length === 1) {
      onChange(options[0]);
    }
  }, [unions, formData]); // eslint-disable-line react-hooks/exhaustive-deps
  // if we include upstreamOnChange in the dependency array, it will cause an infinite loop

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1">
        Union
      </Typography>
      <div data-test-id="UnionSelect-select">
        <Select
          styles={selectStyles}
          onChange={onChange}
          value={value}
          options={options}
          className={classes.select}
          isLoading={loading}
          error={error || graphQlError}
          formatOptionLabel={formatOptionLabel('UnionSelect')}
          isDisabled={disabled}
        />
      </div>
      {(error || graphQlError) && (
        <Typography
          color="error"
          variant="caption"
          className={classes.error}
          data-test-id="UnionSelect-error"
        >
          {error || graphQlError}
        </Typography>
      )}
    </div>
  );
};

export default withRouter(UnionSelect);
