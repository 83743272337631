import React, { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import DialogTitle from '@mui/material/DialogTitle';

import Loader from 'common/components/Loader/Loader';
import Documents from './Documents';
import CustomQuestions from './CustomQuestions';
import AddNote from './AddNote';
import DocumentsConfirm from './DocumentsConfirm';
import useTemplates from './hooks/useTemplates';
import ErrorIndicator from './ErrorIndicator';

const styles = {
  dialogPaper: {
    maxHeight: '600px !important',
    minHeight: 400,
    minWidth: 500,
    padding: 10,
  },
  closeButton: {
    height: '35px !important',
    borderRadius: '2px !important',
    position: 'absolute !important',
    right: 8,
    top: 17,
    color: '#262626 !important',
  },
  btn: {
    height: 35,
    borderRadius: '2px',
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: 14,
  },
  btnContained: {
    backgroundColor: '#0000FF',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#0000FF',
    },
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  btnOutlined: {
    border: '1px solid #0000FF',
    color: '#0000FF',
    backgroundColor: '#fff',
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  listItemCheckbox: {
    minWidth: '10px !important',
  },
  listText: {
    color: '#0000FF !important',
  },
  listItemGutters: {
    paddingLeft: '0px !important',
  },
  dialogRoot: {
    zIndex: '1000 !important',
  },
  loaderContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  required: { color: '#FF0000' },
  noteLength: { color: '#8D8D8D' },
  noteTextArea: {
    minWidth: 400,
    background: '#F4F4F4',
    border: 'none',
    padding: 10,
  },
  confirmSubHeader: {
    fontWeight: 700,
    marginBottom: 16,
  },
  multiList: {
    listStyle: 'none',
  },
  confirmUsersList: {
    marginTop: 40,
  },
  questionsSubHeader: {
    fontSize: 12,
  },
  confirmNote: {
    marginTop: 40,
    fontWeight: 700,
    '& span': {
      fontWeight: 300,
      display: 'block',
      paddingTop: 2,
    },
  },
  noQuestionsText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center',
    color: '#525252',
  },
  listRoot: {
    marginRight: 24,
  },
  itemTextRoot: {
    color: '#525252',
    fontSize: 14,
  },
  checkboxRoot: {
    color: '#6f6f6f',
  },
  dialgActionsRoot: {
    padding: '12px 8px',
  },
  dialogTitleRoot: {
    '& h2': {
      color: '#262626',
      fontSize: 18,
    },
  },
};

const SendAdditionalDocumentsModal = ({
  onClose,
  projectId,
  classes,
  offers,
}) => {
  const [open, setOpen] = useState(true);
  const [next, setNext] = useState('');
  const [finalData, setFinalData] = useState({
    documentFields: [],
    documents: [],
    note: '',
  });

  const offerIds = offers.map(offer => parseInt(offer.id));
  const { data: templates, error, loading } = useTemplates(projectId, offerIds);

  useEffect(() => {
    setFinalData(finalData => ({
      ...finalData,
      offers,
    }));
  }, [offers]);

  const handleClose = (value, reason) => {
    if (reason === 'backdropClick') return;
    setOpen(false);
    onClose(value);
  };

  const handleNext = (
    step,
    { documentFields = [], note = '', documents = [] },
  ) => {
    setNext(step);
    setFinalData(finalData => ({
      ...finalData,
      documentFields:
        documentFields && documentFields.length > 0
          ? documentFields
          : finalData.documentFields,
      note: note ? note : finalData.note,
      documents:
        documents && documents.length > 0 ? documents : finalData.documents,
    }));
  };

  const renderComponent = () => {
    switch (next) {
      case 'documents':
        return (
          <Documents
            onClose={handleClose}
            projectId={projectId}
            classes={classes}
            onNext={handleNext}
            data={finalData}
            templates={templates}
          />
        );
      case 'questions':
        return (
          <CustomQuestions
            classes={classes}
            onClose={handleClose}
            onNext={handleNext}
            data={finalData}
          />
        );
      case 'note':
        return (
          <AddNote
            classes={classes}
            onClose={handleClose}
            onNext={handleNext}
            data={finalData}
          />
        );
      case 'confirm':
        return (
          <DocumentsConfirm
            classes={classes}
            onClose={handleClose}
            onNext={handleNext}
            data={finalData}
          />
        );
      default:
        return (
          <Documents
            onClose={handleClose}
            projectId={projectId}
            classes={classes}
            onNext={handleNext}
            data={finalData}
            templates={templates}
          />
        );
    }
  };

  const renderTitle = () => {
    switch (next) {
      case 'questions':
        return 'Custom Questions';
      case 'note':
        return 'Add a Note To Crew';
      case 'confirm':
        return 'Confirm Documents';
      default:
        return 'Select Documents to Send';
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      classes={{ paper: classes.dialogPaper }}
      disableEscapeKeyDown={true}
      className={classes.dialogRoot}
      fullWidth={false}
      maxWidth="xs"
      data-test-id="offer-send-documents-dialog"
    >
      <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
        {renderTitle()}
        <p>
          {next === 'questions' && (
            <p className={classes.questionsSubHeader}>
              Questions relating to the selected documents
            </p>
          )}
        </p>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {error && (
        <ErrorIndicator
          message={
            'Oops! Something went wrong. Please try after some time or contact support'
          }
        />
      )}
      {loading && (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      )}
      {renderComponent()}
    </Dialog>
  );
};

export default withStyles(styles)(SendAdditionalDocumentsModal);
