import internalReduceParams from './reduceParams';

export const reduceParams = (params, initial, callback) =>
  internalReduceParams(params, '', initial, callback);

export const generateQuery = params =>
  internalReduceParams(params, '', [], function(key, val, acc) {
    if (typeof val !== 'undefined') {
      acc.push(key + '=' + val);
    }

    return acc;
  })
    .sort()
    .join('&');

export const generateURL = (url, params) => {
  var query = generateQuery(params);

  if (query.length > 0) {
    return url + '?' + query;
  } else {
    return url;
  }
};

export const encodeURI = value => encodeURIComponent(value);

export const parseURI = url => new global.URL(url);

export default {
  reduceParams,
  generateQuery,
  generateURL,
  encodeURI,
  parseURI,
};
