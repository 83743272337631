import * as FieldTypes from './fieldTypes';

export default {
  [FieldTypes.DATE]: ['date'],
  [FieldTypes.NUMBER]: ['number'],
  [FieldTypes.CHK]: ['boolean'],
  [FieldTypes.RDO]: ['radiogroup'],
  [FieldTypes.CMB]: ['combo', 'string'],
  [FieldTypes.TXT]: ['string'],
};
