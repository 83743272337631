import React, { Component } from 'react';

// Components
import ContactDetails from 'common/oldJavascripts/components/Base/ContactDetails';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import HelpIcon from '@mui/icons-material/Help';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Paper from '@mui/material/Paper';
import Loader from 'common/components/Loader';
import classnames from 'classnames';

import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import LayoutHeader from 'common/oldJavascripts/components/Base/Layout/LayoutHeader';
import Separator from 'common/oldJavascripts/components/Base/Separator';
import MainHeader from 'common/oldJavascripts/components/Base/MainHeader';
import Typography from '@mui/material/Typography';

// Context
import { FeatureFlagsContext } from 'common/context';

// HoC
import { withStyles } from '@mui/styles';
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';

// Utilities
import classNames from 'classnames';
import T from 'common/oldJavascripts/utils/i18n';
import { styled } from '@mui/system';

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: 'auto auto',
    gridGap: '25px',
    gap: '25px',
    placeItems: 'center',
  },
  globalNav: {
    marginTop: '120px',
  },
  header: {
    height: '50px',
    fontWeight: '700 !important',
    fontSize: '24px !important',
    color: 'rgb(67, 79, 89)',
  },
  paper: {
    minHeight: 172,
    width: '360px',
  },
  contactGroupContainer: {
    padding: '8px 15px',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: 'auto auto',
    gap: '5px',
    gridGap: '5px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  contactDetailContainer: {
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '30px auto',
    alignItems: 'center',
    fontSize: '0.92307692em',
    lineHeight: '1.07692308',
    fontWeight: '400',
  },
  icons: {
    height: '18px',
    width: 'auto',
  },
  helpIcon: {
    marginBottom: '-5px',
  },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  minHeight: 172,
  width: '500px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

class Help extends Component {
  static contextType = FeatureFlagsContext;

  static queries = {
    offer: {
      info: function(_, related) {
        var params = related['/router/params'];
        return {
          id: '/v2/offers/' + params.offerId,
        };
      },
    },

    hirer: {
      info: function(_, related) {
        var params = related['/router/params'];
        return {
          id: '/v2/offers/' + params.offerId + '/hirer',
        };
      },
    },
  };

  render() {
    const { offer: offerQuery = {}, hirer: hirerQuery = {} } = this.props;
    const { data: offer = {} } = offerQuery || {};
    const { data: hirer = {} } = hirerQuery || {};

    const { title = '', first_name = '', last_name = '', phone } = hirer || {};

    const { classes } = this.props;
    const flags = this.context || [];
    const globalNavFlag = flags.includes('GlobalNavbar');

    if (offerQuery.status === 'loading' || hirerQuery.status === 'loading') {
      return <Loader />;
    }
    const rootClass = classnames(classes.root, {
      [classes.globalNav]: globalNavFlag,
    });

    return (
      <>
        {!globalNavFlag && (
          <LayoutHeader>
            <MainHeader secondary={true}>
              <MainHeader.Title>
                <HelpIcon className={classes.helpIcon} /> {T('shared.help')}
              </MainHeader.Title>
            </MainHeader>
          </LayoutHeader>
        )}

        <LayoutContent>
          <div className={rootClass}>
            <StyledPaper className={classNames(classes.crew, classes.paper)}>
              <BoxItem>
                <Typography className={classes.header}>
                  Hiring Managers For This Project
                </Typography>
              </BoxItem>

              <Separator />
              <BoxItem>
                <ContactDetails>
                  <ContactDetails.Title>
                    {first_name + ' ' + last_name}
                  </ContactDetails.Title>
                  <ContactDetails.Data secondary={true}>
                    <ContactDetails.DataValue>
                      {offer.project_name}
                      {title ? ` - ${title}` : ''}
                    </ContactDetails.DataValue>
                  </ContactDetails.Data>
                </ContactDetails>
                <div className={classes.contactGroupContainer}>
                  <div className={classes.contactDetailContainer}>
                    <EmailIcon className={classes.icons} />
                    <div>{hirer.email}</div>
                  </div>
                  {phone && (
                    <div className={classes.contactDetailContainer}>
                      <PhoneIcon className={classes.icons} />
                      <div>{phone}</div>
                    </div>
                  )}
                </div>
              </BoxItem>
            </StyledPaper>

            <StyledPaper className={classNames(classes.crew, classes.paper)}>
              <BoxItem>
                <Typography className={classes.header}>
                  {T('help.contact_information_title')}
                </Typography>
              </BoxItem>

              <Separator />
              <BoxItem>
                <ContactDetails>
                  <ContactDetails.Title>Customer Service</ContactDetails.Title>
                  <ContactDetails.Data secondary={true}>
                    <ContactDetails.DataValue>
                      Cast&Crew
                    </ContactDetails.DataValue>
                  </ContactDetails.Data>
                </ContactDetails>
                <div className={classes.contactGroupContainer}>
                  <div className={classes.contactDetailContainer}>
                    <EmailIcon className={classes.icons} />
                    <div>{T('help.support_email')}</div>
                  </div>
                  <div className={classes.contactDetailContainer}>
                    <PhoneIcon className={classes.icons} />
                    <div>{T('help.support_phone')}</div>
                  </div>
                </div>
              </BoxItem>
            </StyledPaper>
          </div>
        </LayoutContent>
      </>
    );
  }
}

export default compose(withApi, withStyles(styles))(Help);
