import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query unions($projectId: Int!) {
    unions(projectId: $projectId) {
      code
      description
      isNonUnion
    }
  }
`;

const useUnions = ({ projectId }) => {
  const { loading, data, error } = useQuery(QUERY, {
    variables: { projectId },
  });
  return { loading, data: data?.unions, error };
};

export default useUnions;
