import React from 'react';

import { withStyles } from '@mui/styles';
import { Radio, RadioGroup as MuiRadioGroup, Input } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/material/internal/svg-icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/material/internal/svg-icons/RadioButtonChecked';
import classNames from 'classnames';
import sharedCss from '../../shared/oldDocumentSignerUI/sharedCss';
import fieldStatusClass from './fieldStatusClass';

const styles = {
  ...sharedCss.fields,
  radioGroup: {},
  input: {
    borderRadius: '50%',
  },
};

const RadioGroup = props => {
  const {
    id,
    name,
    handleChange,
    classes,
    radioGroupItems,
    value,
    setFieldRef,
    page,
    _derived: { isDisabled },
  } = props;
  const inputClass = classNames(classes.input, fieldStatusClass(props));

  const radioOptions = radioGroupItems
    .filter(option => option.page === page)
    .map(option => {
      const { x, y: preAdjustmentY, height, width, name: buttonValue } = option;
      const y = preAdjustmentY + height * 0.7;
      const fieldDynamicStyle = {
        position: 'absolute',
        top: y,
        left: x,
        height,
        width,
      };
      return (
        <Radio
          icon={
            <RadioButtonUncheckedIcon
              style={{ height: height * 1.15, width: width * 1.15 }}
              className={inputClass}
            />
          }
          checkedIcon={
            <RadioButtonCheckedIcon
              style={{ height: height * 1.15, width: width * 1.15 }}
              className={inputClass}
            />
          }
          style={fieldDynamicStyle}
          value={buttonValue}
          key={`${name}-${buttonValue}`}
          data-test-id={`RadioGroup-radio-${name}/${buttonValue}`}
          disabled={isDisabled}
        />
      );
    });
  const adjustedY = radioGroupItems[0].y + radioGroupItems[0].height * 0.7;
  return (
    <div data-test-id={`RadioGroup-root-${id}`}>
      <Input inputRef={e => setFieldRef(e, adjustedY)} type="hidden" />
      <MuiRadioGroup
        name={name}
        className={classes.radioGroup}
        onChange={e => handleChange(e.target.value)}
        value={value}
      >
        {radioOptions}
      </MuiRadioGroup>
    </div>
  );
};

export default withStyles(styles)(RadioGroup);
