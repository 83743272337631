import object from './object.js';
import { encodeURI } from './serializer';

const reduceParams = (params, namespace, acc, callback) => {
  object.each(params, function(key, val) {
    var queryKey = encodeURI(key);

    if (namespace) {
      queryKey = namespace + '[' + queryKey + ']';
    }

    if (val === null || typeof val === 'undefined') {
      return;
    }

    if (val.constructor === Object || val.constructor === Array) {
      acc = reduceParams(val, queryKey, acc, callback);
    } else if (val.constructor !== global.File) {
      acc = callback(queryKey, encodeURI(val), acc, val);
    } else {
      acc = callback(queryKey, val, acc, val);
    }
  });

  return acc;
};

export default reduceParams;
