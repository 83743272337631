import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import { withStyles } from '@mui/styles';
import classNames from 'class-names';

const styles = {
  root: {
    textDecoration: 'none',
  },
  icon: {
    color: '#00DF68',
    fontSize: '21px',
    verticalAlign: 'sub',
    marginLeft: '-5px',
    marginRight: '5px',
  },
};

const ContactLink = props => {
  const { classes, className, phone } = props;
  return (
    <a href={'tel:' + phone} className={classNames(classes.root, className)}>
      <PhoneIcon className={classes.icon} />
      {phone}
    </a>
  );
};

export default withStyles(styles)(ContactLink);
