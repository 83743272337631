import React from 'react';
import { withStyles } from '@mui/styles';
import { Avatar, Chip, MenuItem, Select, Typography } from '@mui/material';
import colorFromInt from 'common/utilities/colorFromInt';
import sortByCoords from 'common/utilities/sortByCoords';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  radioButtonTitle: {
    fontWeight: 600,
  },
});

const RadioButtonAssignments = props => {
  const {
    classes,
    radios,
    onAssign,
    options = [],
    mapping = {},
    title,
  } = props;

  const tableRows = sortByCoords(radios, null, true).map(
    ({ radioValue, name }, index) => (
      <div key={name} className={classes.row}>
        <div>
          <Chip
            avatar={
              <Avatar
                style={{ background: colorFromInt(index) }}
                data-test-id={`RadioButtonAssignments-chip-${index}-${radioValue}`}
              >
                {index + 1}
              </Avatar>
            }
            label={radioValue || name}
            data-test-id={`RadioButtonAssignments-chip-${radioValue}`}
          />
        </div>
        <div>
          <Select
            displayEmpty
            value={mapping[name] || ''}
            onChange={e => onAssign(name, e.target.value)}
          >
            <MenuItem value="">- no mapping -</MenuItem>
            {Object.keys(options).map(key => (
              <MenuItem value={key} key={key}>
                {options[key]}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    ),
  );

  return (
    <div className={classes.container}>
      <Typography className={classes.radioButtonTitle} variant="subtitle1">
        {title}
      </Typography>
      {tableRows}
    </div>
  );
};

export default withStyles(styles)(RadioButtonAssignments);
