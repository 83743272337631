import React from 'react';
import { withStyles } from '@mui/styles';
import { Tooltip, IconButton } from '@mui/material';
import HighlightIcon from '@mui/icons-material/Highlight';
import classNames from 'classnames';

const styles = () => ({
  tooltip: {
    fontSize: '0.9rem !important',
  },
  highlightedIcon: {
    color: '#fae047',
  },
  highlightedButton: {
    backgroundColor: '#8b8b8b',
  },
  highlightButton: {
    padding: 0,
    marginRight: '8px',
    height: '20px',
    width: '20px',
  },
  icon: {
    height: '20px',
  },
});
const HighLightTriggerSourceButton = props => {
  const {
    classes,
    triggerFieldId,
    highlightTriggerSource,
    triggerSourceIdToHighlight,
    enabledTooltipText,
    disabledTooltipText,
    listItemComponentName,
    index,
  } = props;
  const isHighlighted =
    triggerFieldId && triggerFieldId === triggerSourceIdToHighlight;
  return (
    <Tooltip
      disableInteractive
      title={!triggerFieldId ? enabledTooltipText : disabledTooltipText}
      classes={{ tooltip: classes.tooltip }}
    >
      <div
        data-test-id={`${listItemComponentName}-highlightContainer-${index}`}
      >
        <IconButton
          className={classNames(classes.highlightButton, {
            [classes.highlightedButton]: isHighlighted,
          })}
          data-test-id={`${listItemComponentName}-highlightButton-${index}`}
          onClick={() => highlightTriggerSource(triggerFieldId)}
          disabled={!triggerFieldId}
        >
          <HighlightIcon
            className={classNames(classes.icon, {
              [classes.highlightedIcon]: isHighlighted,
            })}
          />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default withStyles(styles)(HighLightTriggerSourceButton);
