import React from 'react';
import { ThemeProvider, withStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0000FF',
    },
  },
});

const styles = theme => ({
  container: {
    width: '100%',
    paddingTop: theme.spacing(3.5),
    overflowX: 'auto',
  },
  formContainer: {
    width: '100%',
  },
  row: {
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  selectContainer: {
    flex: 0.3,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  acknowledgmentTitle: {
    flex: 0.7,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  acknowledgmentDescription: {
    flex: 1,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
});

const AcknowledgmentSetup = props => {
  const {
    classes,
    updateHandler,
    disabled,
    acknowledgeNotes,
    acknowledgeOptions,
    acknowledgeTitle,
    acknowledgeType,
  } = props;
  const showDetails = acknowledgeType === 'C';

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <Typography variant="h6" gutterBottom>
          Acknowledgment of Receipt
        </Typography>
        <Typography variant="body1">
          You can require that a user acknowledges receipt of a document in this
          section.
        </Typography>
        <div className={classes.formContainer}>
          <div className={classes.row}>
            <FormControl className={classes.selectContainer}>
              <InputLabel htmlFor="acknowledgment-select" shrink>
                Require Acknowledgement of Receipt
              </InputLabel>
              <Select
                variant="standard"
                displayEmpty
                inputProps={{ id: 'acknowledgment-select' }}
                value={acknowledgeType || ''}
                onChange={e => updateHandler('acknowledgeType', e.target.value)}
                disabled={disabled}
                data-test-id="AcknowledgmentSetup-type"
              >
                <MenuItem value="">None</MenuItem>
                {acknowledgeOptions.includes('C') && (
                  <MenuItem value="C">Click-through</MenuItem>
                )}
                {acknowledgeOptions.includes('G') && (
                  <MenuItem value="G">Grouped</MenuItem>
                )}
              </Select>
            </FormControl>
            {showDetails && (
              <TextField
                variant="standard"
                className={classes.acknowledgmentTitle}
                value={acknowledgeTitle}
                onChange={e =>
                  updateHandler('acknowledgeTitle', e.target.value)
                }
                label="Title"
                inputProps={{ 'data-test-id': 'AcknowledgmentSetup-title' }}
              />
            )}
          </div>
          {showDetails && (
            <div className={classes.row}>
              <TextField
                variant="standard"
                className={classes.acknowledgmentDescription}
                value={acknowledgeNotes}
                onChange={e =>
                  updateHandler('acknowledgeNotes', e.target.value)
                }
                label="Description"
                inputProps={{
                  'data-test-id': 'AcknowledgmentSetup-description',
                }}
              />
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default withStyles(styles)(AcknowledgmentSetup);
