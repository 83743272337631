import gql from 'graphql-tag';

export default gql`
  mutation updateAdminUser(
    $id: Int!
    $email: String!
    $firstName: String!
    $lastName: String!
    $middleName: String
    $permissions: [Int!]!
  ) {
    updateAdminUser(
      id: $id
      email: $email
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      permissions: $permissions
    ) {
      id
    }
  }
`;
