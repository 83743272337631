import React, { useState } from 'react';

// HoC
import { withStyles } from '@mui/styles';

// Components
import Dialog from '@mui/material/Dialog';
import OfferConfirmationModal from './OfferConfirmationModal';
import ConfirmHoursModal from 'studio/components/OfferPage/ConfirmHoursModal';
const styles = theme => ({
  root: {
    minHeight: '350px',
    height: 'max-content',
    maxHeight: '700px',
    width: '600px',
    overflowY: 'auto',
  },
});

const OfferConfirmation = ({
  open,
  formData,
  scaleRates,
  isUnionWeeklySchedule,
  isUnionWeeklyOnCall,
  isUnionHourlyDaily,
  onClose,
  onSubmit,
  submitInProgress = false,
  classes,
  scrollDestinationHandler,
  crew,
  isCanada,
} = {}) => {
  const isGuaranteedHours = formData?.termsOfEmployment?.guaranteedHours;
  const isGuaranteedHoursDistant =
    formData?.termsOfEmployment?.guaranteedHoursDistant;
  const isHoursEmpty =
    !isCanada &&
    (!isGuaranteedHours || !isGuaranteedHoursDistant) &&
    (isUnionWeeklyOnCall || isUnionHourlyDaily || isUnionWeeklySchedule);
  const [isConfirmOfferModalVisible, setIsConfirmOfferModalVisible] = useState(
    !isHoursEmpty,
  );
  const [isHoursModalVisible, setIsHoursModalVisible] = useState(isHoursEmpty);

  const takeMeBack = () => {
    setIsConfirmOfferModalVisible(false);
    setIsHoursModalVisible(false);
    scrollDestinationHandler('termsOfEmployment');
    onClose();
  };

  const onContinue = () => {
    setIsHoursModalVisible(false);
    setIsConfirmOfferModalVisible(true);
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.root }}>
      {isHoursModalVisible && (
        <ConfirmHoursModal
          takeMeBackHandler={takeMeBack}
          onContinue={onContinue}
        />
      )}
      {isConfirmOfferModalVisible && (
        <OfferConfirmationModal
          formData={formData}
          onSubmit={onSubmit}
          scaleRates={scaleRates}
          isUnionWeeklySchedule={isUnionWeeklySchedule}
          isUnionWeeklyOnCall={isUnionWeeklyOnCall}
          isUnionHourlyDaily={isUnionHourlyDaily}
          submitInProgress={submitInProgress}
          onClose={onClose}
          crew={crew}
        />
      )}
    </Dialog>
  );
};
export default withStyles(styles)(OfferConfirmation);
