import React from 'react';
import domClasses from '../../utils/dom/classes.js';

const DEFAULT_SCALE = NaN;

const checkZero = (value, allowZeros) =>
  !allowZeros && value === 0 ? null : value;
const empty = x => !x || !x.length;
const fix = (value, digits) => {
  return value && !isNaN(digits) ? toNumber(value).toFixed(digits) : value;
};
const toNumber = value => {
  value = (value || '').replace(/[^\d.]/g, '');
  return value ? Number(value) : value;
};

class NumericInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      didExceedLimit: false,
    };
    this._onBlur = this._onBlur.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  _getValue(value) {
    const validNumber = checkZero(toNumber(value), this.props.allowZeros);
    if (isNaN(this.props.scale) && (validNumber || validNumber === 0)) {
      return value.toString();
    }
    return validNumber === null ? null : validNumber.toString();
  }

  _onBlur(event) {
    const value = fix(this._getValue(event.target.value), this.props.scale);
    event.target.value = value;
    if (this.props.onBlur) {
      this.props.onBlur(value);
    }
  }

  _onChange(event) {
    const value = this._getValue(event.target.value);
    if (value > Number.MAX_SAFE_INTEGER) {
      return;
    }
    const max = Number(this.props.max);
    const min = Number(this.props.min);
    this.setState({
      didExceedLimit: value < min || value > max,
    });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  render() {
    const classes = domClasses.set({
      'field-input': true,
      'field-input--alignCenter': this.props.align === 'center',
      'field-input--alignLeft': this.props.align === 'left',
      'field-input--inline': this.props.inline,
      'field-input--noBorder': this.props.border === 'none',
      'field-input--warning': this.state.didExceedLimit,
      'field--disabled': this.props.disabled,
      'is-invalid': !empty(this.props.errors),
    });
    return (
      <input
        autoFocus={this.props.autoFocus}
        className={classes}
        disabled={this.props.disabled}
        id={this.props.id}
        max={this.props.max}
        min={this.props.min}
        name={this.props.name}
        onBlur={this._onBlur}
        onChange={this._onChange}
        placeholder={this.props.placeholder}
        readOnly={this.props.readOnly}
        type="number"
        value={this.props.value}
      />
    );
  }
}

NumericInput.defaultProps = {
  align: 'center',
  allowZeros: true,
  border: 'none',
  scale: DEFAULT_SCALE,
};

export default NumericInput;
