import { useQuery } from 'react-apollo';
import { projectDepartments as QUERY } from 'common/apollo/queries';

const useProjectDepartments = projectId => {
  const { data, error, loading } = useQuery(QUERY, {
    variables: { projectId },
  });

  return { loading, error, data: data?.departments };
};

export default useProjectDepartments;
