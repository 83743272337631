import { withStyles } from '@mui/styles';
import classNames from 'classnames';

import React from 'react';
import Badge from '../Base/Badge';
import moment from 'moment';

const styles = {
  badge: {
    width: 73,
    height: 28,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

class OfferStatus extends React.Component {
  _displayStatus = (status, sendDate, startDate) => {
    const FORMAT = 'MM-DD-YYYY';
    const FORMAT2 = 'YYYY-MM-DD';
    const TIME = 'HH:mm:ss UTC';
    let message = 'START DATE';
    if (!!sendDate && !!startDate) {
      const formatStartDate = moment(startDate, FORMAT2).format(FORMAT);
      const formatSendDate = moment(sendDate, `${FORMAT2} ${TIME}`)
        .utc()
        .format(FORMAT);
      if (formatStartDate !== formatSendDate) message = formatSendDate;
    }
    const display_map = {
      draft: 'DRAFT',
      draft_review: 'PENDING REVIEWER',
      pending_email_delivery: `DELIVER ON ${message}`,
      sent: 'PENDING EMPLOYEE',
      submitted: 'PENDING APPROVAL',
      approved: 'APPROVED',
      rejected: 'REJECTED',
      rejected_employee: 'REJECTED BY EMPLOYEE',
      archived: 'Archived',
      rescinded: 'Rescinded',
      inactive: 'Inactive',
      complete_on_paper: 'COMPLETE ON PAPER',
    };
    if (status.startsWith('onboarding')) status = 'sent';

    return display_map[status];
  };

  render() {
    const { classes, status, startDate, sendDate, className } = this.props;
    const badgeProps = (() => {
      if (status === 'draft_review') {
        return { warning: false };
      } else if (status === 'pending_email_delivery') {
        return { info: true };
      } else if (status === 'approved' || status === 'complete_on_paper') {
        return { success: true };
      } else if (status.startsWith('rejected') || status === 'inactive') {
        return { error: true };
      } else {
        return { warning: true };
      }
    })();
    const badgeClass = classNames(classes.badge, className);

    return (
      <Badge {...badgeProps} className={badgeClass}>
        {this._displayStatus(status, sendDate, startDate)}
      </Badge>
    );
  }
}

export default withStyles(styles)(OfferStatus);
