import * as palette from 'common/shared/oldDocumentSignerUI/palette';
import { getFieldRuleGroupId, getRuleRoleId } from './rules';
import { getEmailId } from './notifications';
const { crosshatch } = palette;

/******************FIELD PROPS********************/

// Check if field is disabled based on document state
export const isFieldDisabled = ({
  roles = [],
  rules = [],
  fields = [],
  activeRoleId,
  activeRuleGroupId,
  activeRuleId,
  roleId,
  id,
  activeEmailId,
}) => {
  const assignedRuleGroupId = getFieldRuleGroupId(
    rules.filter(({ ruleType }) => ruleType !== 'email'),
    id,
  );
  // The field should be disabled if the role being mapped is different to the role assigned to the field
  if (activeRoleId) {
    const isFieldRoleActive = activeRoleId !== roleId && roleId;
    if (isFieldRoleActive) {
      return true;
    } else {
      return false;
    }
  }
  if (!activeRuleGroupId || activeEmailId) return false;
  /*
    Field should be disabled based on rule props when:
    - A rule is actively being mapped AND
      - The rule being mapped is not the same as the rule assigned to the field OR
      - the rule has a role different from the role assigned to the field OR
      - the rule isn't assigned to a role
      - the field is assigned as a trigger rule source
  */
  const activeRuleRoleId = getRuleRoleId(rules, fields, activeRuleId);

  const fieldBelongsToDifferentRuleGroup =
    assignedRuleGroupId && activeRuleGroupId !== assignedRuleGroupId;
  const fieldBelongsToDifferentRole =
    activeRuleRoleId && activeRuleRoleId !== roleId;
  const isFieldTriggerSource =
    activeRuleGroupId && rules.some(rule => rule.triggerFieldId === id);
  const isFieldRoleUnassigned = roleId == null;
  if (
    activeRuleGroupId &&
    (fieldBelongsToDifferentRuleGroup ||
      fieldBelongsToDifferentRole ||
      isFieldRoleUnassigned ||
      isFieldTriggerSource)
  ) {
    return true;
  }
  return false;
};

// Returns the button background color based on state of document
export const getButtonColor = ({
  isDisabled,
  activeRoleId,
  roleId,
  highlightUnmappedFields,
  activeRuleGroupId,
  rules,
  triggerRuleIdToHighlight,
  fieldId,
  triggerSourceIdToHighlight,
  activeEmailId,
  emails,
  fieldDrawerOpened,
}) => {
  const assignedRuleGroupId = getFieldRuleGroupId(
    rules.filter(({ ruleType }) => ruleType !== 'email'),
    fieldId,
  );
  const assignedEmailIds = getEmailId(emails, fieldId);
  // Find the active highlighted trigger rule
  const triggerRule = rules.find(r => r.id === triggerRuleIdToHighlight) || {};
  const { conditionalRuleIds = [] } = triggerRule || {};
  // Find the conditional rule the field is assigned to if any
  const assignedRule =
    rules.find(({ fieldGroups = [] }) =>
      fieldGroups.some(({ fieldIds = [] }) => fieldIds.includes(fieldId)),
    ) || {};
  const isAssignedToEmail = assignedEmailIds.includes(activeEmailId);

  /*
    Conditions for yellow highlight:
      - Field is assigned to a trigger rule and the user highlights the fields assigned to the trigger response group
      - Field is assigned as a trigger source and the user highlights the trigger rule source
  */
  const isFieldAssignedToActiveTrigger =
    triggerRuleIdToHighlight &&
    (assignedRule.id === triggerRuleIdToHighlight ||
      assignedEmailIds.includes(triggerRuleIdToHighlight));
  const isConditionalAssignedToTrigger = conditionalRuleIds.some(
    id => id === assignedRule.id,
  );
  const isTriggerSourceHighlighted = fieldId === triggerSourceIdToHighlight;
  if (
    isFieldAssignedToActiveTrigger ||
    isConditionalAssignedToTrigger ||
    isTriggerSourceHighlighted
  ) {
    return { backgroundColor: palette.yellowHighlight };
  }
  /*
    Conditions for orange highlight:
      - Field is not assigned to a role and highlight unmapped fields is active
      - The role being mapped is different from the role assigned to the field
      - The rule field group being mapped is different from the rule field group assigned to the field
      - the field is assigned as a trigger rule source
      */
  const isFieldAssignedToRole =
    roleId && activeRoleId && roleId !== activeRoleId;
  const fieldBelongsToDifferentRole =
    highlightUnmappedFields && !roleId && !activeRoleId;
  const fieldBelongsToDifferentRuleGroup =
    assignedRuleGroupId &&
    activeRuleGroupId &&
    activeRuleGroupId !== assignedRuleGroupId;
  const isFieldTriggerSource =
    activeRuleGroupId && rules.some(rule => rule.triggerFieldId === fieldId);
  if (
    !activeEmailId &&
    (isFieldAssignedToRole ||
      fieldBelongsToDifferentRole ||
      fieldBelongsToDifferentRuleGroup ||
      isFieldTriggerSource)
  ) {
    return { backgroundColor: palette.orange };
  } else if (fieldDrawerOpened) {
    return { backgroundColor: palette.secondary };
  }
  /*
    Conditions for green highlight:
      - The role being mapped is the same as the role assigned to the field
      - The rule field group being mapped is the same as the rule field group assigned to the field
      - The document is in its default view and the field is assigned to a role
  */
  const fieldBelongsToActiveRole =
    roleId && !activeEmailId && roleId === activeRoleId;
  const fieldBelongsToRuleGroup =
    activeRuleGroupId &&
    !activeEmailId &&
    assignedRuleGroupId === activeRuleGroupId;
  const isFieldMappedToRole = roleId && !activeEmailId && !activeRuleGroupId;
  if (
    fieldBelongsToActiveRole ||
    fieldBelongsToRuleGroup ||
    isFieldMappedToRole ||
    isAssignedToEmail
  ) {
    return { backgroundColor: palette.green };
  }
  /*
    Conditions for crosshatch:
      - The user is mapping a rule field group and the role assigned to the rule is different from the role assigned to the field
  */
  if (isDisabled) {
    return {
      backgroundColor: palette.roleMapperHighlight,
      backgroundImage: crosshatch,
      backgroundRepeat: 'repeat',
    };
  }
  // Grey transparent background for default
  return {
    backgroundColor: palette.roleMapperHighlight,
  };
};

// Adds system field object to field if externalName is present
export const addSystemField = (field, systemFields) => {
  const { externalName } = field;
  // If the field has a system field assigned, add the system field object to the field
  if (externalName) {
    const { customProperties = {} } = field;
    const { systemFieldId = null } = customProperties || {};
    let updatedField = { ...field };
    systemFields.forEach(field => {
      const { items = [] } = field;
      items.forEach(item => {
        if (item.id === systemFieldId) {
          updatedField = {
            ...updatedField,
            systemField: item,
          };
        }
      });
    });
    return updatedField;
  } else {
    return { ...field, systemField: {} };
  }
};

// Sets the system field (external name) and field type
export const formatField = field => {
  const { systemField = {} } = field;
  const isTextType = field.textType === '' || field.textType === 'TXT';
  const formattedField = {
    ...field,
    externalName: systemField.name || '',
    textType: isTextType ? null : field.textType,
  };
  return formattedField;
};
