import BaseLayout from 'common/oldJavascripts/components/Pages/Layouts/Base';
import LayoutHeader from 'common/oldJavascripts/components/Base/Layout/LayoutHeader';
import MainHeader from 'common/oldJavascripts/components/Base/MainHeader';
import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';

/* eslint react/prop-types: "off" */

export default Relay.createClass({
  statics: {
    queries: {
      me: {
        info: function() {
          return {
            id: '/me',
          };
        },
      },
    },
  },

  render() {
    const me = this.props.me.data;
    const navItems = [];

    // Hide Secondary Nav links excluding Password.
    if (!me.is_update_password_required && !me.is_password_expired) {
      navItems.push(
        <MainHeader.Item to="account/profile">Profile</MainHeader.Item>,
      );
    }
    navItems.push(
      <MainHeader.Item to="account/password">Password</MainHeader.Item>,
    );
    if (!me.is_update_password_required && !me.is_password_expired) {
      navItems.push(
        <MainHeader.Item to="account/notifications">
          Notifications
        </MainHeader.Item>,
      );
    }

    return (
      <BaseLayout hasSidebar={this.props.hasSidebar}>
        <LayoutHeader>
          <MainHeader secondary={true}>
            <MainHeader.Title>{this._getUserName()}</MainHeader.Title>
          </MainHeader>
        </LayoutHeader>
        {this.props.children}
      </BaseLayout>
    );
  },

  _getUserName() {
    const me = this.props.me.data;
    return `${me.first_name} ${me.last_name}`;
  },
});
