import React from 'react';
import { styled } from '@mui/system';

const StyledHeader = styled('header')({
  height: '66px',
  width: '100%',
  overflow: 'visible',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '#FFFFFF',
  zIndex: 100,
  borderBottom: '1px solid #EAECF0',
});

const SecondaryNavBar = ({ children }) => {
  return <StyledHeader>{children}</StyledHeader>;
};

export default SecondaryNavBar;
