import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';

const styles = {
  root: {
    WebkitBorderRadius: '3px',
    borderRadius: '3px',
    fontSize: '0.76923077em',
    background: '#646464',
    color: '#ffffff',
    display: 'inline-block',
    fontWeight: '700',
    padding: '3px',
    textTransform: 'uppercase',
  },
  warning: {
    backgroundColor: '#FFBF00',
    color: '#FFFFFF',
  },
  error: {
    backgroundColor: '#ED1C23',
    color: '#FFFFFF',
  },
  success: {
    backgroundColor: '#00DF68',
    color: '#FFFFFF',
  },
  info: {
    backgroundColor: '#0000FF',
    color: '#FFFFFF',
  },
  clear: {
    backgroundColor: 'transparent',
    color: '#979ca5',
    border: '1px solid #e6e7ea',
    textTransform: 'none !important',
  },
};

class Badge extends React.Component {
  render() {
    const {
      children,
      classes,
      className,
      success,
      warning,
      error,
      info,
      clear,
    } = this.props;

    const rootClasses = classNames(className, classes.root, {
      [classes.success]: success,
      [classes.warning]: warning,
      [classes.error]: error,
      [classes.info]: info,
      [classes.clear]: clear,
    });

    return <span className={rootClasses}>{children}</span>;
  }
}

export default withStyles(styles)(Badge);
