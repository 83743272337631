import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

const CrewField = ({ data, classes, onCheckBoxClick, preview = false }) => {
  const { name, displayName, id, checked } = data;

  const handleCheckBoxClick = () => {
    if (preview) return;
    onCheckBoxClick(data);
  };

  return (
    <ListItem
      key={id}
      button={!preview}
      onClick={() => handleCheckBoxClick()}
      classes={{
        gutters: classes.listItemGutters,
        container: classes.multiList,
      }}
      data-test-id={`crew-field-list-item-${id}`}
    >
      {!preview && (
        <ListItemIcon
          classes={{
            root: classes.listItemCheckbox,
          }}
        >
          <Checkbox
            edge="start"
            checked={checked}
            data-test-id={`crew-field-list-checkbox-${id}`}
            classes={{ root: classes.checkboxRoot }}
          />
        </ListItemIcon>
      )}
      <ListItemText
        className={checked && !preview ? classes.listText : ''}
        data-test-id={`crew-field-list-item-text-${id}`}
        primary={displayName || name}
        classes={{ root: classes.itemTextRoot }}
      />
    </ListItem>
  );
};

export default CrewField;
