import React from 'react';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import qs from 'qs';
import {
  ExpandMore as DownArrowIcon,
  ExpandLess as UpArrowIcon,
  UnfoldMore as SortIcon,
} from '@mui/icons-material';

const styles = {
  root: {
    lineHeight: '1.23076923',
    borderBottom: '1px solid #e4e4e4',
    padding: '16px 20px',
    verticalAlign: 'middle',
    position: 'relative',
  },
  date: { minWidth: '10em' },
  header: {
    background: '#e6e7ea',
    border: 'none',
    color: '#434f59',
    fontWeight: '700',
    textTransform: 'uppercase',
    verticalAlign: 'middle',
  },
  bodyCell: {
    overflow: 'hidden',
  },
  expanded: {
    background: '#ffffff',
    paddingLeft: '41px',
    borderBottom: '0px',
    borderTop: '0px',
  },
  clickable: { cursor: 'pointer' },
  alignCenter: { textAlign: 'center' },
  sortableLink: {
    textDecoration: 'none',
    whiteSpace: 'nowrap',
  },
  noPadding: { padding: 0 },
  sortIcon: {
    display: 'inline-flex',
    height: 16,
  },
};

class Cell extends React.Component {
  constructor(props) {
    super(props);
    this._click = this._click.bind(this);
    this._renderCellContent = this._renderCellContent.bind(this);
    this._renderSortableLink = this._renderSortableLink.bind(this);
    this._renderSortIcon = this._renderSortIcon.bind(this);
    this._setClasses = this._setClasses.bind(this);
  }

  render() {
    return this.props.sort
      ? this._renderSortableLink()
      : this._renderCellContent();
  }

  _setClasses() {
    const {
      classes,
      date,
      header,
      expanded,
      onClick,
      align,
      noPadding,
      className,
    } = this.props;
    const rootClass = classNames(
      classes.root,
      {
        [classes.date]: date,
        [classes.header]: header,
        [classes.expanded]: expanded,
        [classes.onClick]: !!onClick,
        [classes.alignCenter]: align === 'center',
        [classes.noPadding]: noPadding,
        [classes.bodyCell]: !header,
      },
      className,
    );
    return rootClass;
  }

  _renderCellContent() {
    const {
      classes,
      date,
      header,
      expanded,
      onClick,
      align,
      noPadding,
      className,
      width,
      colSpan,
      children,
      ...rest
    } = this.props;
    return (
      <td
        {...rest}
        className={this._setClasses()}
        width={width}
        onClick={this._click}
        colSpan={colSpan}
      >
        {children}
      </td>
    );
  }

  _renderSortableLink() {
    const {
      location = {},
      classes,
      sort,
      defaultSort,
      colSpan,
      width,
      children,
    } = this.props;
    const { search, pathname } = location || {};
    const query = { ...qs.parse(search, { ignoreQueryPrefix: true }) };

    if (query.sort === sort) {
      query.direction = query.direction === 'asc' ? 'desc' : 'asc';
    } else {
      query.sort = sort;
      query.direction = defaultSort || 'asc';
    }
    // This query direction logic is very strange
    return (
      <td
        className={this._setClasses()}
        colSpan={colSpan}
        width={width}
        data-sort={query.direction === 'asc' ? 'desc' : 'asc'}
      >
        <Link
          to={{
            pathname,
            search: qs.stringify(query, { addQueryPrefix: true }),
          }}
          className={classes.sortableLink}
        >
          {children} {this._renderSortIcon()}
        </Link>
      </td>
    );
  }

  _click(event) {
    const { onClick } = this.props;
    if (onClick) {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    }
  }

  _renderSortIcon() {
    const { location = {}, sort, classes } = this.props;
    if (!sort) return;
    const { search } = location || {};
    const query = { ...qs.parse(search, { ignoreQueryPrefix: true }) };

    if (query.sort === sort && query.direction === 'asc') {
      return <UpArrowIcon className={classes.sortIcon} />;
    }

    if (query.sort === sort && query.direction === 'desc') {
      return <DownArrowIcon className={classes.sortIcon} />;
    }

    return <SortIcon className={classes.sortIcon} />;
  }
}

export default compose(withRouter, withStyles(styles))(Cell);
