import React, { Component } from 'react';

import classnames from 'classnames';
import moment from 'moment';
import { withStyles } from '@mui/styles';
import {
  Button,
  Dialog,
  TextField,
  Typography,
  ThemeProvider,
  FormLabel,
} from '@mui/material';
import { DialogActions } from '@mui/material';
import DocumentTags from 'common/components/UploadedDocument/UploadedDocumentTags';
import ProgressSpinner from 'common/components/ProgressSpinner';
import * as palette from 'common/shared/oldDocumentSignerUI/palette';
import { documentSignerTheme } from 'common/shared/oldDocumentSignerUI/theme';
import DatePicker from 'common/oldJavascripts/components/Base/DatePickerField';
import DOMPurify from 'dompurify';

const styles = theme => ({
  dialogContent: {
    width: 400,
    maxHeight: 400,
    overflow: 'scroll',
    padding: theme.spacing(3),
    paddingBottom: 0,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  dialogActions: {
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  textField: {
    width: 350,
    marginTop: theme.spacing(2) + ' !important',
    marginBottom: theme.spacing(5) + ' !important',
  },
  tagsWidth: {
    maxWidth: '100%',
  },
  errorText: {
    textTransform: 'capitalize',
  },
  error: {
    padding: '0px 50px',
    minHeight: '1.71429em',
  },
  saveButtonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: palette.success,
    '&:hover': {
      backgroundColor: palette.successHover,
    },
  },
  expirationDateLabel: {
    marginTop: 16,
    marginBottom: 10,
  },
});

const getValidMoment = value => {
  if (!value) return null;
  const m = moment(value);
  if (!m.isValid()) return null;
  return m;
};

class DocumentMetaData extends Component {
  static defaultProps = {
    isOpen: false,
    isClone: false,
  };

  state = {
    name: this.props.name,
    expireOn: this.props.expireOn
      ? moment(DOMPurify.sanitize(this.props.expireOn))
      : null,
    tags: this.props.tags,
  };

  handleSubmit = () => {
    const { name, expireOn, tags } = this.state;
    const { handleSubmit: handleUpstreamSubmit, id } = this.props;
    handleUpstreamSubmit(id, name, expireOn, tags);
  };

  handleCancel = (e, reason) => {
    if (reason === 'backdropClick') return;
    const { handleCancel: handleUpstreamCancel } = this.props;
    handleUpstreamCancel();
  };

  handleDateChange = v => {
    const m = getValidMoment(v);
    this.setState({ expireOn: m });
  };

  handleInputChange = value => {
    this.setState({
      name: value,
    });
  };

  handleAddTag = value => {
    this.setState(({ tags }) => {
      const newTag = { name: value };
      if (
        tags.filter(tag => tag.name.toLowerCase() === value.toLowerCase())
          .length > 0
      ) {
        return null;
      }
      return { tags: [...tags, newTag] };
    });
  };

  handleDeleteTag = tag => {
    this.setState(({ tags }) => {
      const newTags = [...tags];
      newTags.splice(tags.indexOf(tag), 1);
      return {
        tags: newTags,
      };
    });
  };

  isValid = () => this.isNameValid() && this.isTagsValid();

  isNameValid = (name = this.state.name) => {
    const trimmedName = name?.trim();
    return trimmedName?.length >= 3 && trimmedName?.length <= 75;
  };

  isTagsValid = (tags = this.state.tags) =>
    tags.every(tag => {
      const t = tag.name.trim();
      return t?.length >= 2 && t?.length <= 50;
    });

  handleClone = () => {
    const { name, tags, expireOn } = this.state;
    const { handleClone, id } = this.props;
    handleClone({ id, name, tags, expireOn });
  };

  render() {
    const { name, tags, expireOn } = this.state;
    const {
      error = [],
      isSaving = false,
      saveSuccess = false,
      isOpen,
      isClone,
      classes,
    } = this.props;
    const isValid = this.isValid();
    const title = isClone ? 'Clone Document Template' : 'Edit Document';

    return (
      <ThemeProvider theme={documentSignerTheme}>
        <Dialog onClose={this.handleCancel} open={isOpen}>
          <div className={classes.dialogContent}>
            <Typography variant="h4" gutterBottom>
              {title}
            </Typography>
            <TextField
              variant="standard"
              value={name}
              error={!this.isNameValid()}
              onChange={e => this.handleInputChange(e.target.value)}
              label={
                this.isNameValid()
                  ? 'Document Name'
                  : 'Document name must be between 3 and 75 characters'
              }
              className={classes.textField}
              inputProps={{
                'data-test-id': 'DocumentMetaData-nameInput',
              }}
            />
            <DocumentTags
              tags={tags}
              addHandler={this.handleAddTag}
              deleteHandler={this.handleDeleteTag}
              classes={{ root: classes.tagsWidth }}
              editable
              form
            />

            <FormLabel
              component="label"
              className={classes.expirationDateLabel}
            >
              Expiration Date
            </FormLabel>

            <div>
              <DatePicker
                name="DocumentMetaData-expirationDate"
                id="DocumentMetaData-expirationDate"
                selected={expireOn}
                minDate={moment()}
                showYearDropdown={true}
                isClearable={true}
                onChange={this.handleDateChange}
                popperClassName="DocumentMetaData-popper-root"
                popperModifiers={{
                  preventOverflow: {
                    boundariesElement: 'scrollParent',
                  },
                }}
              />
            </div>
          </div>
          <DialogActions className={classes.dialogActions}>
            <div className={classes.error}>
              <Typography
                variant="body1"
                color="error"
                className={classes.errorText}
              >
                {error.join('. ')}
              </Typography>
            </div>
            <Button
              onClick={this.handleCancel}
              className={classes.button}
              color="secondary"
              variant="contained"
            >
              Cancel
            </Button>
            <div className={classes.saveButtonContainer}>
              <Button
                className={classnames(
                  {
                    [classes.buttonSuccess]: saveSuccess,
                  },
                  classes.button,
                )}
                onClick={isClone ? this.handleClone : this.handleSubmit}
                disabled={isSaving || !isValid}
                color="primary"
                variant="contained"
                data-test-id="DocumentMetaData-saveButton"
              >
                Save
              </Button>
              {isSaving && <ProgressSpinner size={24} top={6} left={20} />}
            </div>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(DocumentMetaData);
