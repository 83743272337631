import { useMutation } from '@apollo/react-hooks';
import * as Mutations from 'common/apollo/mutations';

const useRemoveRelatedProject = () => {
  const mutation = Mutations.RemoveRelatedProject;
  const [mutate, { loading, error }] = useMutation(mutation);
  const removeRelatedProject = data => mutate({ variables: { ...data } });

  return [removeRelatedProject, { loading, error }];
};

export default useRemoveRelatedProject;
