import React from 'react';
import { withStyles } from '@mui/styles';

const styles = {
  list: {
    marginLeft: '10px',
    listStyle: 'inside',
  },
  requiredRuleTitle: {
    fontWeight: 700,
  },
};

const PanelContent = props => {
  const {
    classes,
    fieldGroups = [],
    fields = [],
    associatedConditionalRules = [],
  } = props;
  return (
    <ul className={classes.list}>
      {fieldGroups.map(({ fieldIds = [] }) =>
        fieldIds.map(fieldId => {
          const selectedField =
            fields.find(field => field.id === fieldId) || {};
          return (
            <li
              data-test-id={`PanelContent-field-${selectedField.displayName}`}
              key={fieldId}
            >
              {selectedField.displayName}
            </li>
          );
        }),
      )}
      {associatedConditionalRules.map(({ name, fieldGroups }) => (
        <React.Fragment key={name}>
          <li data-test-id={`PanelContent-ruleName-${name}`}>
            <span className={classes.requiredRuleTitle}>Required Rule</span>:{' '}
            {name}
            <ul className={classes.list}>
              {fieldGroups.map(({ fieldIds }) =>
                fieldIds.map(fieldId => {
                  const selectedField =
                    fields.find(field => field.id === fieldId) || {};
                  return (
                    <li
                      data-test-id={`PanelContent-field-${selectedField.displayName}`}
                      key={fieldId}
                    >
                      {selectedField.displayName}
                    </li>
                  );
                }),
              )}
            </ul>
          </li>
        </React.Fragment>
      ))}
    </ul>
  );
};

export default withStyles(styles)(PanelContent);
