import React from 'react';

// Components
import TableCell from '@mui/material/TableCell';
import AccountCodeMaskedInput from 'common/components/AccountCodeMaskedInput';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  maskInput: {
    margin: 0,
    width: '100%',
    height: '40px',
    padding: '10px',
    lineHeight: '14px',
  },
}));

const AccountCodeTableCell = props => {
  const textInputClasses = useStyles();
  const {
    id,
    code,
    lineItemKey,
    mask,
    value,
    onChange,
    classes = {},
    canEditAccountCodes,
  } = props || {};

  const isDetailSub = code === 'detail/sub';

  // Check if code is for the insurance or ins
  // since we have to limit this fields character count to 2
  const isInsurance = code === 'insurance' || code === 'ins';

  const maxLength = isInsurance ? 2 : 4;

  const handleInputChange = ev => {
    const { value: inputDefault = '' } = ev.target;

    // Per the requirements: alpha-numeric only, no symbols/spaces
    const isValid = new RegExp('^[a-zA-Z0-9]*$').test(inputDefault);

    return isValid ? onChange(ev) : false;
  };
  return (
    <TableCell key={id} className={classes.root}>
      {isDetailSub ? (
        <AccountCodeMaskedInput
          id={id}
          value={value}
          code={code}
          lineItemKey={lineItemKey}
          accountMask={mask}
          onChange={onChange}
          canEditAccountCodes={canEditAccountCodes}
          hasContainerClass={false}
          customMaskInputClass
          isLabelVisible={false}
        />
      ) : (
        <input
          value={value}
          disabled={!canEditAccountCodes}
          className={textInputClasses.maskInput}
          maxLength={maxLength}
          onChange={handleInputChange}
          data-test-id={`AccountCodeTableCell-input-${id}/${lineItemKey}`}
        />
      )}
    </TableCell>
  );
};

export default AccountCodeTableCell;
