import React from 'react';
import { Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { logout } from 'common/store/actions/authenticate';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { MOBILE_ROUTES } from 'mobile/constants';
import { Link } from 'react-router-dom';
import withApi from 'common/hoc/withApi';
import BEACH from 'mobile/images/beach.svg';
import PALMTREE from 'mobile/images/palmtree.svg';

const { PROJECTS } = MOBILE_ROUTES;

const youRockStyles = makeStyles(theme => ({
  container: {
    paddingTop: '70px',
    marginBottom: '100px',
    backgroundColor: '#f7fcfc',
    height: '100vh',
  },
  card: {
    minWidth: '295px',
    minHeight: '262px',
    width: '60vw',
    height: '30vh',
    borderRadius: 2,
    backgroundColor: '#ffffff',
    margin: '0 auto',
    display: 'block',
    padding: '20px',
    boxShadow: '-2px -1px 11px 6px rgba(209,209,209,1)',
  },
  title: {
    color: '#0000FF',
    fontSize: '25px',
    fontWeight: 400,
    textAlign: 'center',
  },
  message: {
    color: '#646464',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    marginBottom: '50px',
    textAlign: 'center',
    marginTop: '20px',
  },
  viewAllBtn: {
    width: 231,
    height: 40,
    borderRadius: 2,
    backgroundColor: '#0000FF',
    fontSize: 14,
    color: '#FFFFFF',
    marginTop: '10px',
    marginBottom: '10px',
    '&:hover': {
      backgroundColor: '#1A55FD',
    },
    '&:focus': {
      backgroundColor: '#0000B3',
    },
    '&:active': {
      backgroundColor: '#000080',
    },
  },
  noUnderline: {
    textDecoration: 'none !important',
  },
  logoutBtn: {
    width: '232px',
    height: '40px',
    borderRadius: 2,
    background: 'transparent',
    border: '1px solid #0000FF',
    fontSize: 14,
    color: '#0000FF',
    marginTop: '10px',
    marginBottom: '10px',
    '&:hover': {
      background: 'transparent !important',
    },
    '&:focus': {
      background: 'transparent !important',
    },
  },
  palmtree: {
    marginRight: '15px',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '30px auto',
    alignItems: 'center',
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

const YouRock = ({ logout }) => {
  const classes = youRockStyles();

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <Typography className={classes.title}>You Rock!</Typography>
        <Typography className={classes.message}>
          There are no more offers for you to approve.
        </Typography>
        <div className={classes.imgContainer}>
          <img
            src={PALMTREE}
            alt="palm tree icon"
            className={classes.palmtree}
          />
          <img src={BEACH} alt="beach icon" />
        </div>
      </div>
      <div className={classes.btnContainer}>
        <Link to={PROJECTS} className={classes.noUnderline}>
          <Button className={classes.viewAllBtn}>View All Projects</Button>
        </Link>
        <Button className={classes.logoutBtn} onClick={() => logout()}>
          Logout
        </Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  logout,
};
export default compose(withApi, connect(null, mapDispatchToProps))(YouRock);
