import React from 'react';
import { Table, TableCell, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import formatCurrency from 'studio/components/OfferPage/helpers/formatCurrency';

const useStyles = makeStyles(theme => ({
  tableSpacing: {
    marginTop: '10px',
  },
  noBorderCell: {
    border: 0,
  },
  cellTitle: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 600,
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  cellTitleModifiedBorder: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 600,
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
    borderRight: 0,
  },
  cellContentModifiedBorder: {
    backgroundColor: '#FFFFFF',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
    borderRight: 0,
    borderTop: 0,
  },
  cellContentNoBorderTop: {
    backgroundColor: '#FFFFFF',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
    borderTop: 0,
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  dollar: {
    width: '10%',
    fontSize: 14,
    marginTop: 7,
    color: '#696969',
    paddingLeft: 14,
    fontFamily: 'Roboto, Helvetica, Helvetica Neue, san-serif',
  },
  amount: {
    fontSize: 14,
    marginTop: 8,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: '#525252',
    paddingRight: 24,
    fontFamily: 'Roboto, Helvetica, Helvetica Neue, san-serif',
  },
  radio: {
    '&$checked': {
      color: '#0000FF',
    },
  },
  checked: {
    color: '#0000FF',
    '&, & + $label': {
      color: '#0000FF !important',
      fontSize: 16,
    },
  },
  label: {
    color: '#262626',
    fontSize: 16,
  },
  errorLabelIncluded: {
    color: '#262626',
    fontSize: 16,
    border: '1px solid red',
    borderBottom: 0,
  },
  errorLabelExcluded: {
    color: '#262626',
    fontSize: 16,
    border: '1px solid red',
    borderTop: 0,
  },
  questionText: {
    fontSize: 16,
    marginTop: '6px',
  },
  errorLabel: {
    color: 'red',
    fontSize: 12,
    textAlign: 'center',
  },
  fringeCellContainer: {
    padding: 0,
    border: 0,
    width: '25%',
  },
  fringeQuestionContainer: {
    border: 0,
    padding: 0,
    verticalAlign: 'baseline',
  },
  noPaddingOrBorderContainer: {
    border: 0,
    padding: 0,
  },
  helperText: {
    fontSize: '12px',
    color: '#6D7278',
  },
  fringeHeaderText: {
    fontWeight: 600,
  },
  description: {
    color: '#6D7278',
    fontSize: 12,
  },
  alignCopy: {
    marginTop: 5,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const FringeInputFields = props => {
  const {
    fringeType,
    handleFringeType,
    totalFringePercentage,
    isReadOnly,
    formErrors = {},
    formData,
  } = props;

  const { ratePerHour, dailyRate, weeklyRate } = formData || {};

  let parsedRate = 0;

  if (ratePerHour) {
    parsedRate = ratePerHour ? parseFloat(ratePerHour) : 0;
  }

  if ((ratePerHour && dailyRate) || (ratePerHour && weeklyRate)) {
    parsedRate = ratePerHour ? parseFloat(ratePerHour) : 0;
  }

  if (weeklyRate && !ratePerHour) {
    parsedRate = weeklyRate ? parseFloat(weeklyRate) : 0;
  }

  if (dailyRate && !ratePerHour) {
    parsedRate = dailyRate ? parseFloat(dailyRate) : 0;
  }

  const calculatedFringePercentage = totalFringePercentage / 100;

  const fringesIncludedRate = parsedRate * (1 + calculatedFringePercentage);
  const fringesExcludedRate = parsedRate / (1 + calculatedFringePercentage);

  const formattedFringeIncludedRate =
    Math.round(formatCurrency(fringesIncludedRate) * 100) / 100;
  const formattedFringeExcludedRate =
    Math.round(formatCurrency(fringesExcludedRate) * 100) / 100;

  const errorMessage = formErrors['fringesIncluded'];

  let fringeRelationshipValue = null;
  let renderedIncludedFringeRate;
  let renderedExcludedFringeRate;

  if (fringeType) {
    fringeRelationshipValue = 'yes';
  }

  if (fringeType === false || fringeType === 'no') {
    fringeRelationshipValue = 'no';
  }

  if (!parsedRate) {
    fringeRelationshipValue = null;
  }

  // Calculate Different Rates Depending on fringeType being yes or no

  if (fringeType === 'yes' || fringeRelationshipValue === 'yes') {
    renderedIncludedFringeRate = parsedRate.toFixed(2);
    renderedExcludedFringeRate = formattedFringeExcludedRate.toFixed(2);
  }

  if (fringeType === 'no' || fringeRelationshipValue === 'no') {
    renderedIncludedFringeRate = formattedFringeIncludedRate.toFixed(2);
    renderedExcludedFringeRate = parsedRate.toFixed(2);
  }

  const classes = useStyles();

  const CustomRadio = (
    <Radio
      classes={{
        root: classes.radio,
        checked: classes.checked,
      }}
    />
  );

  return (
    <>
      <Table
        className={classes.tableSpacing}
        data-test-id="FringeInputFields-Table"
      >
        <TableRow>
          <TableCell className={classes.fringeQuestionContainer}>
            <Typography className={classes.questionText}>
              How should vacation/fringe be calculated?
            </Typography>
          </TableCell>
          <TableCell className={classes.noPaddingOrBorderContainer}>
            <RadioGroup
              value={fringeRelationshipValue}
              onChange={ev => handleFringeType(ev)}
            >
              <FormControlLabel
                classes={{
                  root: errorMessage && classes.errorLabelIncluded,
                  label: classes.label,
                }}
                disabled={isReadOnly || !parsedRate}
                data-test-id="fringeradiorow-radio-yes"
                value="yes"
                control={CustomRadio}
                label="Include in rate"
              />
              <FormControlLabel
                classes={{
                  root: errorMessage && classes.errorLabelExcluded,
                  label: classes.label,
                }}
                disabled={isReadOnly || !parsedRate}
                data-test-id="fringeradiorow-radio-no"
                value="no"
                control={CustomRadio}
                label="In addition to rate"
              />
            </RadioGroup>
          </TableCell>
          <TableCell className={classes.fringeCellContainer}>
            <div className={classes.cellTitleModifiedBorder}>
              <Typography className={classes.fringeHeaderText}>
                Rate Including Vacation/Fringe
              </Typography>
            </div>
            <div className={classes.cellContentModifiedBorder}>
              <div className={classes.itemContainer}>
                <Typography className={classes.dollar}>$</Typography>
                <Typography className={classes.amount}>
                  {fringesIncludedRate > 0 &&
                    fringeRelationshipValue &&
                    renderedIncludedFringeRate}
                </Typography>
              </div>
            </div>
          </TableCell>
          <TableCell className={classes.fringeCellContainer}>
            <div className={classes.cellTitle}>
              <Typography className={classes.fringeHeaderText}>
                Rate Excluding Vacation/Fringe
              </Typography>
            </div>
            <div className={classes.cellContentNoBorderTop}>
              <div className={classes.itemContainer}>
                <Typography className={classes.dollar}>$</Typography>
                <Typography className={classes.amount}>
                  {fringesExcludedRate > 0 &&
                    fringeRelationshipValue &&
                    renderedExcludedFringeRate}
                </Typography>
              </div>
            </div>
          </TableCell>
        </TableRow>
      </Table>
      <div className={classes.alignCopy}>
        <div>
          {errorMessage && (
            <Typography
              color="warning"
              variant="caption"
              className={classes.errorLabel}
            >
              {errorMessage}
            </Typography>
          )}
        </div>

        <Typography variant="subtitle1" className={classes.description}>
          Preview above rounds values to 2 decimal places. Actual calculations
          are not rounded.
        </Typography>
      </div>
    </>
  );
};

export default FringeInputFields;
