import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

import { fullName } from 'common/oldJavascripts/utils/format.js';
import ajax from 'common/utilities/ajax';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';

import Cell from 'common/oldJavascripts/components/Base/Table/Cell';
import BaseRow from 'common/oldJavascripts/components/Base/Table/Row';
import RowMenu from './RowMenu';

const Row = props => {
  const { offer, projectId } = props;
  const {
    id: offerId,
    accountCode,
    startDate,
    approvedAt,
    projectUser: { profile: { firstName, middleName, lastName } = {} } = {},
    termsOfHire: {
      department: { name: departmentName } = {},
      occupation: { description: occupation } = {},
    } = {},
  } = offer || {};

  const name = fullName(firstName, middleName, lastName);
  const [contextualMenuOffer, setContextualMenuOffer] = useState(null);
  const [contextMenuAnchor, setContextMenuAnchor] = useState({ x: 0, y: 0 });

  const closeActionMenu = () => {
    setContextMenuAnchor(null);
  };

  const openMenu = ev => {
    ev.stopPropagation();
    setContextualMenuOffer(offer);
    setContextMenuAnchor({ x: ev.clientX, y: ev.clientY });
  };

  const handleRightClick = ev => {
    ev.preventDefault();
    setContextualMenuOffer(null);
    if (ev.target.closest('tr')) {
      setContextualMenuOffer(offer);
      openMenu(ev);
    }
  };
  const downloadOfferFiles = () => {
    const { pushNotification, popNotification } = props;
    // An email is sent to the user when the document is ready for download
    pushNotification({
      message: 'Hold on a moment while we prepare the document for download.',
      variant: SnackbarVariants.INFO,
    });
    ajax
      .post(`/projects/${projectId}/offers-documents/${offerId}/files/download`)
      .then(() => {
        popNotification();
        pushNotification({
          message:
            "Your file is being created. You'll receive an email when it's done.",
          variant: SnackbarVariants.SUCCESS,
        });
      })
      .catch(err => {
        popNotification();
        pushNotification({
          message:
            'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.',
          variant: SnackbarVariants.ERROR,
        });
      });
  };
  const renderAction = () => {
    return (
      <IconButton
        onClick={e => {
          openMenu(e);
        }}
      >
        <MoreVertIcon />
      </IconButton>
    );
  };

  return (
    <BaseRow
      onContextMenu={e => {
        handleRightClick(e);
      }}
    >
      <Cell doubleBorderBottom rowHeader>
        {name}
      </Cell>
      <Cell doubleBorderBottom>{departmentName}</Cell>
      <Cell doubleBorderBottom>{accountCode}</Cell>
      <Cell doubleBorderBottom>{occupation}</Cell>
      <Cell doubleBorderBottom>
        {moment(startDate, 'YYYY-MM-DD').format('MM-DD-YYYY')}
      </Cell>
      <Cell doubleBorderBottom>
        {moment(approvedAt, 'YYYY-MM-DD HH:mm:ss').format('MM-DD-YYYY')}
      </Cell>
      <Cell doubleBorderBottom>{renderAction()}</Cell>
      <RowMenu
        projectId={projectId}
        offer={contextualMenuOffer}
        anchorEl={contextMenuAnchor}
        closeMenu={closeActionMenu}
        downloadOfferFiles={downloadOfferFiles}
        contextMenuAnchor={contextMenuAnchor}
      />
    </BaseRow>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  pushNotification,
  popNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(Row);
