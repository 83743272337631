import gql from 'graphql-tag';

export default gql`
  mutation projectSaveSettings(
    $id: Int!
    $allowHirersToSendOffers: Boolean
    $customEmailText: String
    $employeeType: String
    $employerOrgAddress: String
    $employerOrgCity: String
    $employerOrgName: String
    $employerOrgState: StateInput
    $employerOrgZipCode: String
    $sendFinalDocsToStudioPlus: Boolean
    $sendMycncW4ToStudioPlus: Boolean
    $sendMycncW9ToStudioPlus: Boolean
    $sendDataReportToStudioPlus: Boolean
    $sendProvisionalDealMemo: Boolean
    $sendDealMemoAfter: String
    $trackOffsiteWorkers: Boolean
    $studioPlusDirectoryId: String
    $studioPlusMycncW4DirectoryId: String
    $studioPlusW9DirectoryId: String
    $studioPlusDataReportDirectoryId: String
    $templatesAcknowledgeTitle: String
    $templatesAcknowledgeNotes: String
    $offerDefaults: OfferDefaultsInput!
    $reimbursements: AllowancesInput
  ) {
    projectSaveSettings(
      id: $id
      allowHirersToSendOffers: $allowHirersToSendOffers
      customEmailText: $customEmailText
      employeeType: $employeeType
      employerOrgAddress: $employerOrgAddress
      employerOrgCity: $employerOrgCity
      employerOrgName: $employerOrgName
      employerOrgState: $employerOrgState
      employerOrgZipCode: $employerOrgZipCode
      sendFinalDocsToStudioPlus: $sendFinalDocsToStudioPlus
      sendMycncW4ToStudioPlus: $sendMycncW4ToStudioPlus
      sendMycncW9ToStudioPlus: $sendMycncW9ToStudioPlus
      sendDataReportToStudioPlus: $sendDataReportToStudioPlus
      sendProvisionalDealMemo: $sendProvisionalDealMemo
      sendDealMemoAfter: $sendDealMemoAfter
      trackOffsiteWorkers: $trackOffsiteWorkers
      studioPlusDirectoryId: $studioPlusDirectoryId
      studioPlusMycncW4DirectoryId: $studioPlusMycncW4DirectoryId
      studioPlusW9DirectoryId: $studioPlusW9DirectoryId
      studioPlusDataReportDirectoryId: $studioPlusDataReportDirectoryId
      templatesAcknowledgeTitle: $templatesAcknowledgeTitle
      templatesAcknowledgeNotes: $templatesAcknowledgeNotes
      offerDefaults: $offerDefaults
      reimbursements: $reimbursements
    )
  }
`;
