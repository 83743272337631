import React, { Component } from 'react';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import Field from 'common/oldJavascripts/components/Base/Field';
import Flexbox from 'flexbox-react';
import Header from 'common/oldJavascripts/components/Base/Header';
import Loader from 'common/components/Loader';
import { Link as RouterLink } from 'react-router-dom';
import history from 'common/constants/config/history';
import Separator from 'common/oldJavascripts/components/Base/Separator';
import Text from 'common/oldJavascripts/components/Base/Text';
import I9Sample from './I9/shared/I9Sample';
import I9LogoIcon from 'common/icons/i9Logo';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import withApi from 'common/hoc/withApi';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import LayoutMain from 'common/oldJavascripts/components/Base/Layout/LayoutMain';
import PendoController from 'common/components/PendoController';

const styles = theme => ({
  root: {},
  headerSection: {
    display: 'grid',
    gridTemplateColumns: '120px auto',
    gridTemplateRows: '100%',
  },
  loader: {
    marginTop: 120,
  },
  iconContainer: {
    display: 'grid',
    placeItems: 'center',
    alignItems: 'start',
  },
  headerTextContainer: {
    display: 'grid',
  },
});

class I9 extends Component {
  static mutators = {
    offer: {
      info(_, related) {
        const { offerId } = related['/router/params'];
        return {
          id: `/v2/offers/${offerId}`,
        };
      },
    },
  };
  static queries = {
    documents: {
      info: function(_, related) {
        const { offerId } = related['/router/params'];
        return {
          id: `/v2/offers/${offerId}/documents-details`,
        };
      },
    },
  };

  state = {
    statementAgreed: false,
  };

  render() {
    return (
      <Flexbox margin="20px auto" maxWidth="800px">
        {this._renderContent()}
      </Flexbox>
    );
  }

  _renderContent = () => {
    const {
      documents = {},
      offer: { data: offerData = {} } = {},
      routerParams,
    } = this.props;
    const { offerId } = routerParams;
    const { status } = documents;
    if (offerData?.keep_i9) {
      history.push(`/onboarding/offers/${offerId}/documents`);
    }

    if (status === 'loading') return this._renderLoading();
    if (status === 'failed') return this._renderFailed();
    if (status === 'success') return this._renderSuccess();
  };

  _renderContinueButton = () => {
    const { routerParams = {} } = this.props;
    const { statementAgreed } = this.state;
    const { offerId } = routerParams;
    let nextTransitionRoute = `/onboarding/offers/${offerId}/documents`;
    if (statementAgreed) {
      return (
        <Flexbox>
          <Flexbox alignSelf="center" padding="20px">
            {'By clicking "Continue" I agree with the statement above ' +
              'and have reviewed and understood all of the instructions.'}
          </Flexbox>
          <Flexbox alignSelf="center">
            <RouterLink
              className="button"
              to={nextTransitionRoute}
              data-test-id="I9-continue"
            >
              Continue
            </RouterLink>
          </Flexbox>
        </Flexbox>
      );
    }
  };

  _renderFailed = () => {
    return <FailureAlert />;
  };

  _renderLoading = () => {
    const { classes } = this.props;
    return <Loader className={classes.loader} />;
  };

  findI9DocId = () => {
    const { documents: documentQuery = {} } = this.props;
    const { data = {} } = documentQuery;
    const { items: documents = [] } = data;
    const i9Doc = documents.find(doc => {
      const { categories = [] } = doc;
      const catNames = categories.map(cat => cat.name);
      return (
        catNames.includes('i9') ||
        catNames.includes('I9') ||
        catNames.includes('I-9') ||
        catNames.includes('i-9')
      );
    });
    return i9Doc.id;
  };

  _renderSuccess = () => {
    const { classes, offer: { data: offerData = {} } = {} } = this.props;
    return (
      <LayoutMain>
        <LayoutContent>
          <Flexbox margin="20px auto" maxWidth="800px">
            <Flexbox className="box-item" flex="1">
              <Flexbox flexDirection="column">
                <div className={classes.headerSection}>
                  <div className={classes.iconContainer}>
                    <I9LogoIcon height="100px" />
                  </div>
                  <div className={classes.headerTextContainer}>
                    <Header.Title data-test-id="I9-title">
                      Employment Eligibility Verification I-9 Form
                    </Header.Title>
                    <Flexbox flexDirection="column">
                      <Text paragraph={true}>
                        {'Thanks for filling in your personal information! Now ' +
                          "let's fill in your I-9. Don't worry. The information " +
                          'you provide will be available on the following screens.'}
                      </Text>
                      <Text paragraph={true} bold={true}>
                        {'After reading the statement below, indicate your ' +
                          'agreement by checking the "I agree" box.'}
                      </Text>
                    </Flexbox>
                  </div>
                </div>
                <Separator />
                <Flexbox padding="20px 20px 0 20px" flexDirection="column">
                  {'In completing the Form I-9, I agree to read all of the ' +
                    'instructions provided. I agree to read the attestation ' +
                    'statements on the Form I-9, and I agree to electronically ' +
                    'sign the document. I understand that I am free to present ' +
                    'any acceptable document or combination of documents to my ' +
                    'employer for Form I-9 purposes, and I understand that ' +
                    'I must present the original documentation as evidence ' +
                    'of my identity and employment authorization to my employer ' +
                    'on or before the end of the third business day after ' +
                    'my first day of work for pay (or, if I will be ' +
                    'employed for fewer than 3 days, on the first ' +
                    'day of work). I agree to keep my login credentials and ' +
                    'password confidential and not to provide them to any other ' +
                    'individual. I attest that I am the individual listed on ' +
                    'the Form I-9 and that I will not allow any other ' +
                    'individual to sign the form on my behalf.'}
                  <Flexbox margin="20px 0 0 0">
                    <Field
                      label="I agree with this statement."
                      onChange={this._updateStatementAgreed}
                      type="checkbox"
                      value={this.state.statementAgreed}
                    />
                  </Flexbox>
                  <I9Sample />
                </Flexbox>
                {this._renderContinueButton()}
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </LayoutContent>
        <PendoController projectId={offerData?.project_id} />
      </LayoutMain>
    );
  };

  _updateStatementAgreed = value => {
    this.setState({ statementAgreed: Boolean(value) });
  };
}

export default compose(withApi, withStyles(styles))(I9);
