import React from 'react';

// Components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

// Custom Components
import AmountField from './AmountField';
import PerField from './PerField';
import CapField from './CapField';
import DurationField from './DurationField';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

// HoC
import { withStyles } from '@mui/styles';

// Utilities
import classNames from 'class-names';
import ClearAllowance from './ClearAllowance';

const styles = theme => ({
  root: {
    display: 'grid',
    height: 'max-content',
    width: '100%',
    gridTemplateColumns: '100%',
    gridTemplateRows: 'max-content 10px max-content',
  },
  header: {
    gridRow: 1,
    gridColumn: 1,
  },
  table: {
    gridRow: 3,
    gridColumn: 1,
    padding: 25,
    tableLayout: 'fixed',
  },
  durationTable: {
    gridRow: 3,
    gridColumn: 1,
    padding: 25,
  },
  icon: {
    height: '14px',
    width: 'auto',
  },

  disabledCell: {
    background: 'hsl(0,0%,95%)',
    color: 'rgb(153, 153, 153)',
  },
  headerCell: {
    fontSize: '1.1125rem',
    padding: '0px 24px',
  },
  labelCell: {
    fontSize: '1.0125rem',
  },
  errorLabelCell: {
    borderLeft: '2px solid #f44336 !important',
  },
  error: {
    fontSize: '0.9rem',
    placeSelf: 'center',
    display: 'block',
  },
  border: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  cellWithTextField: {
    padding: '2px 24px',
  },
  textField: {
    width: '100%',
  },
  textFieldInputContainer: {
    '&:before': {
      border: 'none !important',
    },
    '&:after': {
      borderBottomColor: '#0000FF',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `2px solid #0000FF !important`,
    },
  },
  textFieldInput: {
    textAlign: 'center',
  },
  selectContainer: {
    width: '100%',
    padding: '5px 15px',
  },
  durationSelectContainer: {
    width: '28%',
    padding: '5px 15px',
  },
  description: {
    color: '#6D7278',
    fontSize: 12,
  },
  clearCell: {
    borderBottom: '1px solid transparent !important',
    padding: 12,
    display: 'inline-block',
    cursor: 'pointer',
    textAlign: 'left !important',
  },
});

const Allowances = ({
  classes = {},
  onChange: upstreamOnChange,
  formData = {},
  formErrors = {},
} = {}) => {
  const flags = useFeatureFlags();
  const isDurationFieldEnabled = flags?.includes('AllowanceDurationField');
  const fieldProps = (formKey, subKey) => {
    const { [formKey]: sectionData = {} } = formData || {};
    const { [subKey]: value } = sectionData || {};

    return {
      id: formKey,
      onChange: upstreamOnChange(formKey),
      value: value,
      updateKey: subKey,
      allowance: sectionData,
      classes: {
        root: classNames(classes.border, classes.cellWithTextField),
        textField: classes.textField,
        textFieldInputContainer: classes.textFieldInputContainer,
        textFieldInput: classes.textFieldInput,
        selectContainer: !isDurationFieldEnabled
          ? classes.selectContainer
          : classes.durationSelectContainer,
        clearCell: classes.clearCell,
      },
    };
  };

  const rowConfig = [
    { label: 'Box Rental', key: 'boxRentalAllowance' },
    { label: 'Computer Rental', key: 'computerRentalAllowance' },
    { label: 'Car Allowance', key: 'carAllowance' },
    { label: 'Mobile Phone Allowance', key: 'mobilePhoneAllowance' },
    { label: 'Per Diem', key: 'perDiemAllowance' },
    { label: 'Housing Allowance', key: 'housingAllowance' },
  ];

  const labelCellClass = key =>
    classNames(
      {
        [classes.errorLabelCell]: formErrors[key],
      },
      classes.labelCell,
      classes.border,
    );
  const tableBodyRows = rowConfig.map(({ label, key }) => (
    <TableRow key={`${label}-${key}`}>
      <TableCell className={labelCellClass(key)}>
        {label}
        {formErrors[key] && (
          <Typography
            data-test-id={`Allowances-errorMessage-${key}`}
            color="error"
            variant="caption"
            className={classes.error}
          >
            {formErrors[key]}
          </Typography>
        )}
        {formData?.[key]?.amount === '0._0' && (
          <Typography color="error" variant="caption" className={classes.error}>
            Please Enter Valid Amount
          </Typography>
        )}
      </TableCell>
      <AmountField {...fieldProps(key, 'amount')} />
      <PerField {...fieldProps(key, 'per')} />
      <CapField {...fieldProps(key, 'cap')} />
      {!isDurationFieldEnabled && (
        <DurationField {...fieldProps(key, 'duration')} />
      )}
      <ClearAllowance {...fieldProps(key, '')} />
    </TableRow>
  ));

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.header}>
        Allowances
        <Typography variant="subtitle1" className={classes.description}>
          Reimbursable expenses allowed over and beyond a crew member's daily
          rate
        </Typography>
      </Typography>
      <Table
        className={
          !isDurationFieldEnabled ? classes.table : classes.durationTable
        }
      >
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center" className={classes.headerCell}>
              Amount
            </TableCell>
            <TableCell align="center" className={classes.headerCell}>
              Per
            </TableCell>
            <TableCell align="center" className={classes.headerCell}>
              Cap
            </TableCell>
            {!isDurationFieldEnabled && (
              <TableCell align="center" className={classes.headerCell}>
                Duration
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{tableBodyRows}</TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(Allowances);
