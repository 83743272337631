import React, { useState } from 'react';
import { styled } from '@mui/system';

const LogoImg = styled('img')({
  height: '20px',
  float: 'right',
  marginLeft: 'auto',
});
const Logo = props => {
  const [clickCount, setClickCount] = useState(0);

  const displayVersion = clickCount >= 10;
  const { classes } = props;
  return (
    <>
      <LogoImg
        data-test-id="MainHeader-StartPlusLogo"
        src={props.src}
        alt={props.alt}
        onClick={() => setClickCount(clickCount + 1)}
      />
      {displayVersion && (
        <span
          data-test-id="MainHeader-appVersion"
          className={classes.appVersion}
        >
          v{process.env.REACT_APP_VERSION}
        </span>
      )}
    </>
  );
};

export default Logo;
