import React from 'react';
import format from '../../utils/format.js';
import T from '../../utils/i18n';

import Button from '../Base/Button';
import AllowancesIcon from './Icons/AllowancesIcon';
import Link from '../Base/Link';
import Text from '../Base/Text';

import {
  Build as BoxRentalIcon,
  Computer as ComputerRentalIcon,
  DirectionsCar as CarIcon,
  Smartphone as PhoneIcon,
  LocalDining as PerDiemIcon,
  LocalHotel as HousingIcon,
} from '@mui/icons-material';
import { withStyles } from '@mui/styles';

import ExpandableTableRowHeader from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowHeader';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableRowInfoItem from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowInfoItem';
import ExpandableTableRowInfoItemData from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowInfoItemData';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

var Row = ExpandableTableRow;
var Cell = ExpandableTableCell;
var RowHeader = ExpandableTableRowHeader;
var RowInfoItem = ExpandableTableRowInfoItem;
var RowInfoItemData = ExpandableTableRowInfoItemData;
var AllowancesRow = null;
var ALLOWANCES = null;
var ICONS = null;

ALLOWANCES = [
  'box_rental',
  'computer_rental',
  'car_allowance',
  'mobile_phone',
  'per_diem_allowance',
  'housing_allowance',
];

ICONS = {
  box_rental: <BoxRentalIcon />,
  computer_rental: <ComputerRentalIcon />,
  car_allowance: <CarIcon />,
  mobile_phone: <PhoneIcon />,
  per_diem_allowance: <PerDiemIcon />,
  housing_allowance: <HousingIcon />,
};

const shouldShowAllowances = offer => {
  const termsOfEmployment = offer.terms_of_employment || offer;
  return (
    parseFloat(termsOfEmployment.box_rental_amount) ||
    parseFloat(termsOfEmployment.mobile_phone_amount) ||
    parseFloat(termsOfEmployment.car_allowance_amount) ||
    parseFloat(termsOfEmployment.per_diem_allowance_amount) ||
    parseFloat(termsOfEmployment.housing_allowance_amount) ||
    parseFloat(termsOfEmployment.computer_rental_amount)
  );
};

const rowStyles = {
  container: {
    border: '2px solid #e6e7ea',
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
};

AllowancesRow = withStyles(rowStyles)(
  class extends React.Component {
    render() {
      var emptyText = T('N/A');
      var runOfShow = T('Run of Show');
      var offer = this.props.offer;
      var terms = offer.terms_of_employment || offer;
      var allowances = [];
      var hasAllowance = false;
      const { classes } = this.props;

      ALLOWANCES.forEach(allowance => {
        var amount = terms[allowance + '_amount'];
        var per_output = null;
        var cap_output = null;
        var per_duration = null;
        var duration = null;
        var duration_output = null;

        amount = amount && amount > 0.0 ? format.currency(amount) : null;

        if (amount) {
          if (!Number(terms[allowance + '_per'])) {
            per_duration = terms[allowance + '_per'];
            if (per_duration) {
              per_output = '/' + per_duration;
            }
          }

          cap_output = (
            <RowInfoItemData>
              {T('Cap')}{' '}
              {format.currency(terms[allowance + '_cap']) || emptyText}
            </RowInfoItemData>
          );

          if (Number(terms[allowance + '_duration'])) {
            if (per_duration) {
              per_duration = T('shared.' + per_duration, {
                count: terms[allowance + '_duration'],
              });
            }

            duration =
              terms[allowance + '_duration'] + ' ' + (per_duration || '');
          }

          duration_output = (
            <RowInfoItemData>
              {T('Duration')}
              {': '}
              {duration || runOfShow}
            </RowInfoItemData>
          );
          hasAllowance = true;
        }

        allowances.push(
          <RowInfoItem key={'allowance-item-' + allowance}>
            {ICONS[allowance]}
            <span className="expandableTable-rowInfo-grouped">
              <RowInfoItemData important={true}>
                {amount || emptyText}
                {per_output}
              </RowInfoItemData>
              {cap_output}
              {duration_output}
            </span>
          </RowInfoItem>,
        );
      });

      if (hasAllowance) {
        return (
          <Row rowExpanded={true}>
            <Cell colSpan={this.props.colSpan} expanded={true}>
              <RowHeader>Allowances / Reimbursements</RowHeader>
              <div className={classes.container}>{allowances}</div>
            </Cell>
          </Row>
        );
      } else {
        return <Row />;
      }
    }
  },
);

AllowancesRow.Button = class extends React.Component {
  render() {
    const { offer = {} } = this.props;
    if (shouldShowAllowances(offer)) {
      return (
        <Button
          tertiary={true}
          inline={true}
          onClick={this.props.onClick}
          data-test-id={`AllowancesRow-hasAllowances-${offer.id}`}
        >
          Allowances / Reimbursements
        </Button>
      );
    }
    return (
      <Text data-test-id={`AllowancesRow-noAllowances-${offer.id}`}>
        {T('-')}
      </Text>
    );
  }
};

AllowancesRow.Icon = class extends React.Component {
  render() {
    const { offer = {}, onClick } = this.props;
    if (shouldShowAllowances(offer)) {
      if (onClick) {
        return (
          <Link
            onClick={this._onClick}
            plain={true}
            data-test-id={`AllowancesRow-hasAllowances-${offer.id}`}
          >
            <AllowancesIcon />
          </Link>
        );
      }

      return (
        <span data-test-id={`AllowancesRow-hasAllowances-${offer.id}`}>
          <AllowancesIcon />
        </span>
      );
    }
    return (
      <Text data-test-id={`AllowancesRow-noAllowances-${offer.id}`}>
        {T('-')}
      </Text>
    );
  }

  _onClick = event => {
    event.stopPropagation();
    this.props.onClick();
  };
};

export default AllowancesRow;
