const UNION_WEEKLY_ONCALL_SCHEDULE = [
  'C',
  'C1',
  'C2',
  'C3',
  'C4',
  'C-1',
  'C-2',
  'C-3',
  'C-4',
  'C-5',
];

export default workScheduleCode =>
  UNION_WEEKLY_ONCALL_SCHEDULE.includes(workScheduleCode);
