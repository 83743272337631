import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query offers(
    $projectId: Int!
    $after: String
    $keyword: String
    $offerIds: [Int!]!
    $filter: OffersFilterInput
    $sort: String
    $direction: String
    $status: String
  ) {
    offers(
      projectId: $projectId
      keyword: $keyword
      first: 20
      status: $status
      after: $after
      offerIds: $offerIds
      filter: $filter
      sort: $sort
      direction: $direction
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        totalCount
      }
      nodes {
        id
        status
        isTermsOfEmploymentV2
        badges
        reimbursementsUpdated
        jobTitle
        projectUser {
          id
          profile {
            id
            firstName
            lastName
            email
            phone
            fullName
          }
        }
        startDate
        sendDate
        sentAt
        endDate
        scaleRate
        keepI9
        locationScaleRate
        termsOfHire {
          subDepartment {
            id
            name
          }
          season
          hireState {
            code
            id
            name
          }
          hireCity {
            code
            id
            name
          }
          workState {
            code
            id
            name
          }
          workCity {
            code
            id
            name
          }
          union {
            code
            description
            isNonUnion
          }
          occupation {
            code
            description
          }
          workSchedule {
            code
            description
          }
          fringeContract {
            code
            description
          }
          department {
            code
            id
            loanOutEnabled
            masterDepartmentId
            name
            preSelected
          }
          jobDescription
          employmentClassification
          hiringStatus
          currency
          fullyOffsiteWorker
          trackOffsiteWorkers
        }
        termsOfEmployment {
          dailyRate
          guaranteedHours
          guaranteedHoursFrequency
          idleLocationSeventhDayRatePerHour
          idleLocationSixthDayRatePerHour
          locationGuaranteedHours
          locationRatePerHour
          locationRatePerWeek
          numberOfDaysPerWorkWeek
          overtimeRatePerHour
          ratePerHour
          studioGuaranteedHours
          studioRatePerHour
          studioRatePerWeek
          weeklyRate
        }
        termsOfEmploymentV2 {
          rate
          ... on TermsOfEmploymentNonUnionHourly {
            guaranteedHours
            guaranteedHours6thDay
            guaranteedHours7thDay
            guaranteedHoursFrequency
            guaranteedHoursLimit
            rate
            rateMinimumWage
            rateOvertime
          }
          ... on TermsOfEmploymentUnionHourly {
            rateDistant
            guaranteedHours
            guaranteedHoursDistant
            guaranteedHours6thDay
            guaranteedHours7thDay
            guaranteedHours6thDayDistant
            guaranteedHours7thDayDistant
            payIdleDaysDistant
            payGoldAt
            payGoldAtDistant
            payAtScale
            payAtScaleDistant
            idleAtScaleDistant
            goldAtScale
            goldAtScaleDistant
          }
          ... on TermsOfEmploymentNonUnionWeekly {
            rate
            sixthDayPayMultiplication
            seventhDayPayMultiplication
          }
          ... on TermsOfEmploymentCA {
            ratePerHour
            guaranteedHours
            dailyRate
            weeklyRate
            overtimeRatePerHour
            guaranteedHoursFrequency
          }
          ... on TermsOfEmploymentUnionSag {
            rate
            override
            reason
          }
        }
        allowances {
          boxRentalAllowance {
            amount
            cap
            per
            duration
          }
          computerRentalAllowance {
            amount
            cap
            per
            duration
          }
          carAllowance {
            amount
            cap
            per
            duration
          }
          mobilePhoneAllowance {
            amount
            cap
            per
            duration
          }
          perDiemAllowance {
            amount
            cap
            per
            duration
          }
          housingAllowance {
            amount
            cap
            per
            duration
          }
        }
        accountCodes {
          id
          accountCodeId
          lineItemKey
          value
        }
        accountCode
        customOccupationDescription
        hasPackets
        i9Document
        i9Verified
        i9CreatedDate
        documents
        rejectedAt
        version
        rejectionComments
        rescindedOrArchivedAt
        rescindedOrArchivedComments
        notes
        previousStatus
        additionalNotes {
          notes
          notesUpdatedAt
          notesUpdatedBy
        }
      }
    }
  }
`;

const useManageOffers = variables => {
  const { keyword } = variables;
  const { error, loading, data, refetch, networkStatus, fetchMore } = useQuery(
    QUERY,
    {
      variables: {
        ...variables,
        keyword: (keyword || '').length > 0 ? keyword : null,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );
  return {
    error,
    loading,
    refetch,
    networkStatus,
    fetchMore,
    data: data?.offers,
  };
};

export default useManageOffers;
