import React, { Component } from 'react';

// Components
import LayoutHeader from 'common/oldJavascripts/components/Base/Layout/LayoutHeader';
import MainHeader from '../../Base/MainHeader';
import GlobalSecondaryNavBar from 'common/oldJavascripts/components/Pages/Layouts/GlobalNavbar/SecondaryNavbar';
import GlobalNavItemsContainer from 'common/oldJavascripts/components/Pages/Layouts/GlobalNavbar/NavItemsContainer';
import SubNavItem from './GlobalNavbar/SubNavItem';
import BaseLayout from './Base';
import Typography from '@mui/material/Typography';
import SpecifiedCountryOnly from 'common/components/SpecifiedCountryOnly';
import TitleTooltip from 'common/components/TitleTooltip';

// Icons
import LensIcon from '@mui/icons-material/Lens';

// Context
import { FeatureFlagsContext } from 'common/context';

// HoC
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import withApi from 'common/hoc/withApi';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/system';
// Graphql
import * as Queries from 'common/apollo/queries';
// Utilities
import classNames from 'class-names';
import truncateStringToFitLength from 'common/utilities/truncateStringToFitLength.js';
// Constants
import * as palette from 'common/shared/oldDocumentSignerUI/palette';

const styles = theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    overflow: 'visible',
    flexShrink: 1,
  },
  projectTitle: {
    marginLeft: '0',
    paddingLeft: '20px',
    fontSize: '1.53846154em !important',
    lineHeight: '3 !important',
    color: '#ffffff',
    fontWeight: '700 !important',
  },
  projectStatus: {
    alignSelf: 'center',
    fontSize: '1.33846154em',
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  projectStatusLive: {
    color: '#ffffff',
  },
  projectStatusTest: {
    color: '#ffffff57',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px',
    marginTop: '15px',
  },
  statusIcon: {
    height: '12px',
    width: '12px',
  },
  liveIcon: {
    color: palette.green,
  },
  testIcon: {
    color: '#3434348f',
  },
});

const Rectangle = styled('div')({
  width: '10px',
  height: '35px',
  backgroundColor: '#D0D5DD',
  display: 'inline-block',
  marginLeft: '20px',
  borderRadius: '6px',
});

class ProjectLayout extends Component {
  static contextType = FeatureFlagsContext;

  static queries = {
    project: {
      info: function(_, related) {
        var params = related['/router/params'];

        return {
          id: '/projects/' + params.projectId,

          cursor: {
            with_privileges: true,
          },
        };
      },
    },

    me: {
      info: function() {
        return { id: '/me' };
      },
    },
  };

  renderHeaderItems = () => {
    const {
      location = {},
      me: meQuery = {},
      project: projectQuery = {},
    } = this.props;
    const { data: me = {}, status: meStatus } = meQuery || {};
    const { data: project = {}, status: projectStatus } = projectQuery || {};
    const { privileges = [], id: projectId } = project || {};
    const { pathname } = location || {};

    const isLoading = status => status === 'loading';
    const statuses = [meStatus, projectStatus];
    if (statuses.some(isLoading)) return null;

    const headerConfig = [
      {
        to: `imported-settings`,
        label: 'Project Setup',
        permission: () => me.is_admin,
      },
      {
        to: `users`,
        label: 'Users',
        permission: () => me.is_admin,
      },
      {
        to: `invitations`,
        label: 'Invitations',
        permission: () => me.is_admin,
      },
      {
        to: `offers`,
        label: 'Manage Offers',
        permission: () =>
          privileges.includes('can_create_offers') ||
          privileges.includes('can_review_offers') ||
          me.is_support_admin,
      },
      {
        to: `crew`,
        label: 'Manage Crew',
        permission: () =>
          privileges.includes('can_create_offers') || me.is_support_admin,
      },
      {
        to: `offers/new`,
        label: 'Create Offer',
        permission: () =>
          privileges.includes('can_create_offers') && !me.is_support_admin,
      },
      {
        to: `reviews`,
        label: 'Reviews',
        permission: () => privileges.includes('can_review_offers'),
      },
      {
        to: `approvals`,
        label: 'Approvals',
        permission: () =>
          privileges.includes('can_approve_offers') || me.is_support_admin,
      },
      {
        to: `i9-verifications`,
        label: 'I-9 Reviews',
        permission: () =>
          privileges.includes('can_be_i9_verifier') || me.is_support_admin,
        countryCode: 'US',
      },
      {
        to: `archives`,
        label: 'Archives',
        permission: () => privileges.includes('can_access_archives'),
      },
      {
        to: `offer-documents`,
        label: 'Offers Documents',
        permission: () => privileges.includes('can_view_documents'),
      },
    ];
    const featureFlags = this.context || [];
    const globalNavFlag = featureFlags.includes('GlobalNavbar');
    const NavItem = globalNavFlag ? SubNavItem : MainHeader.Item;

    return headerConfig
      .filter(({ permission }) => permission())
      .map(({ to, label, countryCode }) => (
        <SpecifiedCountryOnly countryCode={countryCode} key={to}>
          <NavItem
            to={`projects/${project.id}/${to}`}
            params={{ projectId: projectId }}
            isActive={pathname === `/projects/${projectId}/${to}`}
            data-test-id={`Project-headerItem-${to}`}
          >
            {label}
          </NavItem>
        </SpecifiedCountryOnly>
      ));
  };

  render() {
    const {
      hasSidebar = false,
      children,
      data: { project = {}, stopPolling } = {},
      classes = {},
    } = this.props;

    const { status, name } = project || {};

    const featureFlags = this.context || [];
    const globalNavFlag = featureFlags.includes('GlobalNavbar');
    const HeaderComponent = globalNavFlag ? GlobalSecondaryNavBar : MainHeader;
    const NavigationComponent = globalNavFlag
      ? GlobalNavItemsContainer
      : MainHeader.Nav;

    // If the status is live then there wont be any future changes
    if (status === 'live') stopPolling();

    const statusClass = classNames(classes.projectStatus, {
      [classes.projectStatusLive]: status === 'live',
      [classes.projectStatusTest]: status === 'test',
    });

    const iconClass = classNames(classes.statusIcon, {
      [classes.liveIcon]: status === 'live',
      [classes.testIcon]: status === 'test',
    });

    const statusLoaded = name && status;

    const ProjectTitleTypography = styled(Typography)({
      marginLeft: '0',
      paddingLeft: '20px',
      fontSize: '1.53846154em',
      lineHeight: '3',
      color: '#000000',
      fontWeight: '700',
    });

    const StyledStatus = styled(Typography)({
      color: '#000000',
      textTransform: 'capitalize',
      marginLeft: '5px',
    });

    const renderProjectName = () => {
      if (globalNavFlag) {
        return (
          <div className={classes.titleContainer}>
            <Rectangle />
            <TitleTooltip title={name}>
              <ProjectTitleTypography>
                {truncateStringToFitLength(22, name)}
              </ProjectTitleTypography>
            </TitleTooltip>
            <div className={classes.statusContainer}>
              <LensIcon className={iconClass} />
              <StyledStatus>{status}</StyledStatus>
            </div>
          </div>
        );
      }

      return (
        <div className={classes.titleContainer}>
          <Typography className={classes.projectTitle}>{name}</Typography>{' '}
          <div className={classes.statusContainer}>
            <LensIcon className={iconClass} />
            <Typography className={statusClass}>{status}</Typography>
          </div>
        </div>
      );
    };

    const header = (
      <LayoutHeader globalNav={globalNavFlag}>
        <HeaderComponent secondary={true}>
          {statusLoaded && renderProjectName()}
          <NavigationComponent secondary={true}>
            {this.renderHeaderItems()}
          </NavigationComponent>
        </HeaderComponent>
      </LayoutHeader>
    );

    if (globalNavFlag) {
      return (
        <BaseLayout
          hasSidebar={hasSidebar}
          nonEmployee={true}
          secondaryNav={true}
          header={header}
        >
          {children}
        </BaseLayout>
      );
    }

    return (
      <BaseLayout hasSidebar={hasSidebar}>
        {header}
        {children}
      </BaseLayout>
    );
  }
}

const projectStatusConfig = {
  options: ({ routerParams: { projectId } = {} }) => ({
    variables: { id: parseInt(projectId, 10) },
  }),
};

export default compose(
  withStyles(styles),
  withApi,
  graphql(Queries.GetProjectNameStatus, projectStatusConfig),
)(ProjectLayout);
