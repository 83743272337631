import * as palette from './palette';

export default {
  fields: {
    required: {
      backgroundColor: palette.transparentYellow + ' !important',
    },
    optional: {
      backgroundColor: palette.transparentBlue + ' !important',
    },
    success: {
      backgroundColor: palette.transparentGreen + ' !important',
    },
    error: {
      backgroundColor: palette.transparentRed + ' !important',
    },
    readOnly: {
      color: palette.black + ' !important',
    },
  },
};
