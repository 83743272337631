import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)({
  backgroundColor: '#e0e0e0',
  marginRight: 16,
  color: 'black',
  '&:hover': {
    backgroundColor: '#d0d0d0',
  },
});

const DeleteConfirmationDialog = props => {
  const { isOpen, onClose, deleteMessage, handleDeleteConfirmation } = props;
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      data-test-id="DeleteConfirmationDialog-root"
    >
      <DialogTitle data-test-id="DeleteConfirmationDialog-title">
        Delete Confirmation
      </DialogTitle>
      <DialogContent data-test-id="DeleteConfirmationDialog-content">
        <DialogContentText data-test-id="DeleteConfirmationDialog-message">
          {deleteMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <StyledButton
          onClick={onClose}
          variant="contained"
          data-test-id="DeleteConfirmationDialog-cancelButton"
        >
          Cancel
        </StyledButton>
        <Button
          onClick={() => handleDeleteConfirmation()}
          color="primary"
          variant="contained"
          data-test-id="DeleteConfirmationDialog-deleteButton"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
