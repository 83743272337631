import { PUSH, POP, CLEAR } from './actionTypes';

export const SNACKBAR_NOTIFICATION = 'SNACKBAR_NOTIFICATION';

export const pushNotification = snackObject => ({
  type: PUSH(SNACKBAR_NOTIFICATION),
  snackObject,
});

export const popNotification = ({ message, popAllMatching, popAll } = {}) => ({
  type: POP(SNACKBAR_NOTIFICATION),
  message,
  popAllMatching,
  popAll,
});

export const clearNotification = () => ({
  type: CLEAR(SNACKBAR_NOTIFICATION),
});
