import React, { useEffect, useState } from 'react';
import withApi from 'common/hoc/withApi';
import withPermissionProtection from 'common/hoc/withPermissionProtection';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import qs from 'qs';
import classnames from 'classnames';
import debounce from 'lodash.debounce';

import {
  Button,
  Typography,
  Paper,
  InputBase,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Divider,
  Tabs,
  Tab,
} from '@mui/material';
import styled from '@mui/material/styles/styled';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import { Search as SearchIcon } from '@mui/icons-material';

import RejectOffer from './RejectOffer';
import { MOBILE_ROUTES } from 'mobile/constants';
import history from 'common/constants/config/history';
import OfferSlides from 'mobile/components/Projects/OfferSlides';

import YouRock from 'mobile/components/Projects/YouRock';
import { logout } from 'common/store/actions/authenticate';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import AdditionalDocumentsRejectionModal from 'studio/components/Approvals/AdditionalDocumentsRejectionModal';
import useApprovals from 'studio/components/Approvals/hooks/useApprovals';
import Box from 'common/oldJavascripts/components/Base/Box';
import FetchMoreApprovals from 'studio/components/Approvals/FetchMoreApprovals';
import Loader from 'common/components/Loader';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import OfferStatus from 'common/oldJavascripts/components/Shared/OfferStatus';

const { PROJECTS } = MOBILE_ROUTES;
const offerListStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#f7fcfc',
    paddingTop: '20px',
    paddingBottom: '120px',
  },
  globalNav: {
    marginTop: '130px',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '40px',
  },
  offerBox: {
    minWidth: '300px',
    minHeight: '350px',
    boxShadow: '0 -1 1 rgba(0, 0, 0, 0.05), 0 2 4 rgba(0, 0, 0, 0.16)',
    borderRadius: 2,
    backgroundColor: '#ffffff',
    margin: '15px',
    padding: '15px',
  },
  rejectBtn: {
    fontSize: '14px !important',
    textTransform: 'none !important',
    borderRadius: '2px !important',
  },
  approveBtn: {
    marginLeft: '20px !important',
    borderRadius: '2px !important',
    fontSize: '14px !important',
    '&:focus': {
      backgroundColor: '#0000B3',
    },
    '&:active': {
      backgroundColor: '#000080',
    },
  },
  nicework: {
    color: '#0000FF',
    fontSize: '24px !important',
    fontWeight: '400 !important',
    marginLeft: '30px !important',
  },
  niceworkMessage: {
    color: '#646464',
    fontSize: '15px !important',
    marginLeft: '30px !important',
  },
  bottomSection: {
    width: '100vw',
    height: '60px',
    boxShadow: '0 -4 10 rgba(0, 0, 0, 0.06)',
    backgroundColor: '#ffffff',
    paddingBottom: '30px',
    paddingLeft: '10px',
    paddingRight: '10px',
    position: 'fixed',
    left: 0,
    bottom: 0,
  },
  viewAllProjects: {
    color: '#646464',
    fontSize: '16px',
    fontWeight: 400,
    textDecoration: 'none',
  },
  logout: {
    color: '#0000FF',
    fontSize: '16px',
    fontWeight: 400,
  },
  bottomBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '20px',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    zIndex: 3,
    marginTop: '20px',
    padding: '0px 16px',
    justifyContent: 'space-between',
  },
  searchForm: {
    border: '1px solid #d4d4d4',
    boxShadow: 'none !important',
    width: '70%',
    paddingTop: '10px',
    paddingLeft: '5px',
  },
  searchIcon: {
    width: '30px',
    height: '30px',
    color: '#b0bec5',
  },
  sortIcon: {
    color: '#0000FF !important',
  },
  sortFormControl: {
    marginLeft: '6px !important',
    backgroundColor: '#ffffff',
    width: '100px',
  },
  searchIconBtn: {
    top: '0px',
    padding: 0,
  },
  searchinput: {
    marginLeft: '5px',
    '&::placeholder': {
      bottom: '20px',
      fontSize: '18px',
    },
  },
  niceWorkContainer: {
    marginTop: '60px',
  },
  tabs: {
    padding: '10px 0px',
    position: 'fixed',
    top: '168px',
    width: '100%',
    backgroundColor: '#ffffff',
    zIndex: 2,
  },
  indicator: {
    height: '1px !important',
  },
  recordsText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.16px',
    color: '#8D8D8D',
    padding: '10px',
  },
  status: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0px',
    '& > span': {
      padding: '10px',
      fontSize: '14px',
      fontWeight: '600',
      width: 'auto',
    },
  },
  contentWrapper: {
    marginTop: '130px',
  },
  youRockContainer: {
    marginTop: '130px',
  },
}));

const EMPTY_CONTENT = <Blankslate>No offers</Blankslate>;
const StyledTab = styled(Tab)({
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: '600',
});

const OfferList = props => {
  const classes = offerListStyles();
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState(false);
  const [sortText, setSortText] = useState();
  const [hasI9Document, setHasI9Document] = useState(false);
  const [offerPrvStatus, setOfferPrvStatus] = useState();
  const [tabValue, setTabValue] = useState('pending');

  const featureFlags = useFeatureFlags();
  const isGlobalFlagOn = featureFlags.includes('GlobalNavbar');

  const { invalidateByPatterns, logout, routerParams, routerQuery } = props;
  const { projectId } = routerParams;

  const handleClose = () => {
    setIsRejectDialogOpen(false);
  };

  const handleRejectClick = offer => {
    setOfferPrvStatus(offer?.previousStatus);
    history.push({ state: { projectId, offer } });
    setSelectedOfferId(offer.id);
    setHasI9Document(offer?.i9Document);
    setIsRejectDialogOpen(true);
  };

  const handleApprovalClick = offer => {
    history.push({
      pathname: `/projects/${projectId}/approvals`,
      state: { projectId, offer },
    });
  };

  const handleSearch = debounce(event => {
    const { value } = event.target;
    const { location: { pathname } = {} } = history;
    const { routerQuery } = props;
    const { status } = routerQuery;
    history.push({
      pathname,
      search: qs.stringify({
        q: value,
        status,
      }),
    });
  }, 300);

  const handleOfferSort = event => {
    const { value } = event.target;
    setSortText(value);
    let direction;
    let sort;
    if (value === 'oldest') {
      direction = 'asc';
      sort = 'start_date';
    } else if (value === 'newest') {
      direction = 'desc';
      sort = 'start_date';
    } else if (value === 'A-Z') {
      direction = 'asc';
      sort = 'last_name,first_name,middle_name';
    } else if (value === 'Z-A') {
      direction = 'desc';
      sort = 'last_name,first_name,middle_name';
    }
    const { location: { pathname } = {} } = history;
    const { routerQuery } = props;
    const { status } = routerQuery;
    history.push({
      pathname,
      search: qs.stringify({
        sort,
        direction,
        status,
      }),
    });
  };

  const rootClass = classnames(classes.root, {
    [classes.globalNav]: isGlobalFlagOn,
  });

  const contentWrapperClass = classnames({
    [classes.contentWrapper]: isGlobalFlagOn,
  });

  const approvalsVariables = {
    id: parseInt(projectId),
    keyword: routerQuery?.q,
    sort: routerQuery?.sort,
    processed: routerQuery?.status === 'processed',
    direction: routerQuery?.direction,
    after: null,
    offerIds: [],
  };

  const {
    data: {
      approvals: { nodes: approvals = [] } = {},
      approvals: { pageInfo } = [],
      country: { code: projectCountry = '' } = {},
      status: { approvals: { status: approvalsStatus } = {} } = {},
    } = {},
    loading: offersLoading,
    networkStatus,
    fetchMore,
  } = useApprovals(approvalsVariables);

  const isLoading = offersLoading && networkStatus !== 3;
  const readyToSignOffers = approvals.filter(
    item => item.readyToSign !== false,
  );
  const totalOffers = readyToSignOffers.length;
  const { location: { state: { showNextOffer = false } = {} } = {} } = history;
  const isNextOffer = !isLoading && showNextOffer;
  const isNiceWork = !isLoading && isNextOffer && totalOffers > 0;
  const isYouRock = !isLoading && isNextOffer && totalOffers === 0;

  const nextOffer = () => {
    let arr = [];
    if (totalOffers > 0) {
      let [nextOffer] = readyToSignOffers;
      arr.push(nextOffer);
    }
    return arr;
  };

  const offers = isNiceWork ? nextOffer() : readyToSignOffers;

  const getNextOfferProjectName = () => {
    if (offers) {
      let [firstOffer] = offers;
      return firstOffer ? firstOffer.project_name : '';
    }
  };

  useEffect(() => {
    const { status } = routerQuery;
    if (status) {
      setTabValue(status);
      return;
    }
  }, [routerQuery]);

  const _selectTab = (e, newValue) => {
    setTabValue(newValue);
    const { location } = props;
    const { pathname } = location || {};
    history.push({
      pathname,
      search: qs.stringify({
        status: newValue,
      }),
    });
  };

  if (isYouRock) {
    return (
      <div className={classes.youRockContainer}>
        <YouRock />
      </div>
    );
  }

  const _renderContentFailed = () => {
    return (
      <Box full={true}>
        <FailureAlert />
      </Box>
    );
  };

  const _approvalRow = offer => {
    return (
      <div>
        <Paper
          className={classes.offerBox}
          data-test-id={`OffersListCard-${offer.id}`}
          key={offer.id}
        >
          <OfferSlides offer={offer} countryCode={projectCountry} />
          {!isProcessed ? (
            <div className={classes.btnContainer}>
              <Button
                className={classes.rejectBtn}
                variant="outlined"
                color="primary"
                onClick={() => handleRejectClick(offer)}
                data-test-id={`OffersListCard-rejectButton-${offer.id}`}
              >
                Reject
              </Button>
              <Button
                className={classes.approveBtn}
                color="primary"
                variant="contained"
                data-test-id={`OffersListCard-approveButton-${offer.id}`}
                onClick={() => handleApprovalClick(offer)}
              >
                Approve
              </Button>
            </div>
          ) : (
            <div className={classes.status}>
              <OfferStatus
                status={offer?.status}
                startDate={offer?.startDate}
                sendDate={offer?.sendDate}
              />
            </div>
          )}
        </Paper>
      </div>
    );
  };

  const _renderListProcessed = () => {
    if (isLoading) return <Loader className={classes.loader} />;
    if (approvals.length === 0) {
      return EMPTY_CONTENT;
    }
    return (
      <Box full={true} noShadow={true}>
        {approvals.map(_approvalRow)}
      </Box>
    );
  };

  const _renderListPending = () => {
    if (isLoading) return <Loader className={classes.loader} />;
    if (approvals.length === 0) {
      return EMPTY_CONTENT;
    }
    return (
      <Box full={true} noShadow={true}>
        {approvals.map(_approvalRow)}
      </Box>
    );
  };

  const _isProcessed = () => {
    const { routerQuery: query = {} } = props;
    const { status } = query || {};
    return status === 'processed';
  };

  const isProcessed = _isProcessed();

  const _renderContent = () => {
    if (approvalsStatus === 'failed') {
      return _renderContentFailed();
    }
    return isProcessed ? _renderListProcessed() : _renderListPending();
  };

  const _renderMoreOffers = () => {
    if (pageInfo?.hasNextPage && !isLoading) {
      return (
        <FetchMoreApprovals
          loadMoreOffers={fetchMore}
          searchQuery={routerQuery?.q || ''}
          offersPageInfo={pageInfo}
          networkStatus={networkStatus}
        />
      );
    }
  };

  return (
    <>
      <div className={rootClass}>
        <div className={classes.tabs}>
          <Tabs
            variant="fullWidth"
            centered
            value={tabValue}
            onChange={_selectTab}
            classes={{ indicator: classes.indicator }}
          >
            <StyledTab value="pending" label="Ready for Approval" />
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderWidth: '1px' }}
            />
            <StyledTab value="processed" label="My Approval History" />
          </Tabs>
          <Divider />
          {!isNiceWork && (
            <div className={classes.searchContainer}>
              <Paper className={classes.searchForm} borderRadius={2}>
                <IconButton
                  type="submit"
                  aria-label="search"
                  className={classes.searchIconBtn}
                >
                  <SearchIcon className={classes.searchIcon} />
                </IconButton>
                <InputBase
                  className={classes.input}
                  placeholder="Search"
                  inputProps={{
                    'aria-label': 'Search',
                    className: classes.searchinput,
                  }}
                  onChange={e => handleSearch(e)}
                  onBlur={e => handleSearch(e)}
                />
              </Paper>
              <FormControl className={classes.sortFormControl}>
                <InputLabel disableAnimation={false} htmlFor="searchCriteria">
                  Sort
                </InputLabel>
                <Select
                  id="offerlist-select"
                  value={sortText}
                  variant="standard"
                  onChange={e => handleOfferSort(e)}
                  classes={{ icon: classes.sortIcon }}
                >
                  <MenuItem value="newest">Newest</MenuItem>
                  <MenuItem value="oldest">Oldest</MenuItem>
                  <MenuItem value="A-Z">A-Z</MenuItem>
                  <MenuItem value="Z-A">Z-A</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
        </div>
        <div className={contentWrapperClass}>{_renderContent()}</div>
        {!isLoading && (
          <span className={classes.recordsText}>
            Showing {approvals.length || 0} of {pageInfo?.totalCount} offers
          </span>
        )}
        {_renderMoreOffers()}

        {totalOffers > 0 && isNiceWork && (
          <React.Fragment>
            <Typography className={classes.nicework}>Nice Work!</Typography>
            <Typography className={classes.niceworkMessage}>
              {' '}
              Here is your next offer for {getNextOfferProjectName()}.
            </Typography>
          </React.Fragment>
        )}
        {isRejectDialogOpen &&
          (offerPrvStatus === 'approved' ? (
            <AdditionalDocumentsRejectionModal
              onClose={handleClose}
              open={isRejectDialogOpen}
            />
          ) : (
            <RejectOffer
              open={isRejectDialogOpen}
              handleClose={handleClose}
              projectId={projectId}
              i9Document={hasI9Document}
              selectedOfferId={selectedOfferId}
              invalidateByPatterns={invalidateByPatterns}
            />
          ))}
        {pageInfo?.totalCount > 0 && isNiceWork && (
          <div className={classes.niceWorkContainer}>
            <div className={classes.bottomSection}>
              <div className={classes.bottomBtnContainer}>
                <Typography className={classes.logout} onClick={logout}>
                  Logout
                </Typography>
                <Link to={PROJECTS} className={classes.viewAllProjects}>
                  View All Projects
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  logout,
};

export default compose(
  withPermissionProtection('can_approve_offers'),
  withApi,
  connect(null, mapDispatchToProps),
)(OfferList);
