// V1 UNION TERMS OF EMPLOYMENT MAP FOR GRAPHQL OFFERS QUERY

const unionTermsOfEmployment = offers => {
  const { id, termsOfEmployment: termsV1 = {} } = offers;

  let terms_of_employment = {
    rate_per_hour_studio: termsV1?.studioRatePerHour,
    rate_per_week_studio: termsV1?.studioRatePerWeek,
    guaranteed_hours_studio: termsV1?.studioGuaranteedHours,
    rate_per_hour_location: termsV1?.locationRatePerHour,
    rate_per_week_location: termsV1?.locationRatePerWeek,
    guaranteed_hours_location: termsV1?.locationGuaranteedHours,
    pay_6th_day_rate: termsV1?.idleLocationSixthDayRatePerHour,
    pay_7th_day_rate: termsV1?.idleLocationSeventhDayRatePerHour,
    overtime_rate: termsV1?.overtimeRatePerHour,
  };

  return {
    id,
    terms_of_employment,
  };
};

export default unionTermsOfEmployment;
