import React from 'react';

import ExpandableTable from 'common/oldJavascripts/components/Base/ExpandableTable';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

const Row = ExpandableTableRow;
const Cell = ExpandableTableCell;

class NonUnionTable extends React.Component {
  render() {
    return (
      <div>
        <ExpandableTable containerClassName="rate-scale-table">
          <Row className="rate-scale-table-row">
            <Cell key="nonunion-header-rate-labels" header={true} />
            {this._renderTableHeader()}
          </Row>
          <Row className="rate-scale-table-row oddrow">
            {this._renderTableData()}
          </Row>
        </ExpandableTable>
      </div>
    );
  }

  _renderTableData() {
    const { tableData } = this.props;
    return tableData.map((td, index) => (
      <Cell key={`nonunion-rate-${index}`}>{td || '—'}</Cell>
    ));
  }

  _renderTableHeader() {
    return this.props.tableHeader.map((th, index) => (
      <Cell header={true} key={`nonunion-header-${index}`}>
        {th || ''}
      </Cell>
    ));
  }
}

export default NonUnionTable;
