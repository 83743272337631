import React, { useState, useEffect } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Object from 'common/utilities/object';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import history from 'common/constants/config/history';

import useProjectDetails from './hooks/useProjectDetails';
import useStates from 'common/hooks/useStates';
import useSaveSettings from './hooks/useSaveSettings';

import Loader from 'common/components/Loader';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import NavBar from 'common/oldJavascripts/components/Base/NavBar';

import General from './Details/General';
import I9Details from './Details/I9';
import Reimbursements from './Details/Reimbursements';
import cloneDeep from 'lodash.clonedeep';
import { styled } from '@mui/system';

const useStyles = makeStyles({
  loadingThrobber: {
    '& svg': {
      color: '#CCC',
    },
  },
  stickyContainer: {
    padding: '18px 4px',
    bottom: '-21px',
    position: 'sticky',
    background: '#FFFFFF',
  },
});

const StyledButton = styled(Button)({
  cursor: 'pointer',
  padding: '8px 16px',
  minWidth: 120,
  transition: 'background 0.3s ease-in-out 0s',
  fontWeight: 'bolder',
  borderRadius: 3,
});

const Details = props => {
  const {
    projectId,
    popSnackbarNotification,
    pushSnackbarNotification,
  } = props;

  const classes = useStyles();

  const {
    loading: detailsLoading,
    error: detailsError,
    data: details,
    refetch,
  } = useProjectDetails(projectId);
  const { country: { id: countryId } = {} } = details || {};
  const {
    data: states,
    loading: statesLoading,
    error: statesError,
  } = useStates(countryId);
  const [saveSettings, mutationData] = useSaveSettings();
  const { loading: saveLoading } = mutationData || {};

  const [data, setData] = useState({});
  const [saveErrors, setSaveErrors] = useState({});
  const [isSaveValid, setSaveValid] = useState(true);

  useEffect(() => {
    const clonedDetails = cloneDeep(details || {});
    setData({
      ...clonedDetails,
      id: parseInt(projectId),
    });
  }, [details, projectId]);

  // check if any required fields are empty
  useEffect(() => {
    // required fields for employer org
    const employRequiredFields = [
      'employerOrgCity',
      'employerOrgZip',
      'employerOrgAddress',
      'employerOrgName',
      'employerOrgState',
    ];
    Object.keys(data).forEach(key => {
      if (
        employRequiredFields.includes(key) &&
        (data[key] === '' || data[key] === null)
      ) {
        return setSaveValid(true);
      }
    });
  }, [data]);

  // component unmount cleanup for data state variable
  // cancel button will reset data to original state
  useEffect(() => {
    return () => setData(details);
  }, [details]);

  if (detailsLoading || statesLoading) return <Loader />;
  if (detailsError) return <FailureAlert queryName="projectDetails" />;
  if (statesError) return <FailureAlert queryName="states" />;

  const onChange = (key, newData) => {
    setSaveValid(false);
    setData(data => ({
      ...data,
      [key]: newData,
    }));
  };

  const cleanTypename = object => {
    delete object.__typename;
    Object.keys(object).forEach(key => {
      if (object[key] && Object.isObject(object[key])) {
        object[key] = cleanTypename(object[key]);
      }
    });

    return object;
  };

  const saveDetails = () => {
    popSnackbarNotification();
    pushSnackbarNotification({
      message: 'Saving project settings. Please wait...',
      variant: SnackbarVariants.INFO,
    });
    saveSettings(cleanTypename(data))
      .then(() => {
        popSnackbarNotification();
        pushSnackbarNotification({
          message: 'Project settings have been updated.',
          variant: SnackbarVariants.SUCCESS,
        });
        refetch();
        setSaveErrors({});
      })
      .catch(err => {
        const errorData = (err.graphQLErrors || [])[0]?.extensions?.data;
        const message = ((errorData || {}).reimbursements || [])[0];
        setSaveErrors(errorData || { error: err.message });
        popSnackbarNotification();
        pushSnackbarNotification({
          message: message || 'There was an error saving project settings.',
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  const cancel = () => history.push(`/projects/${projectId}/settings`);

  return (
    <>
      <General
        project={data}
        states={states}
        countryId={countryId}
        onChange={onChange}
        errors={saveErrors}
      />
      <I9Details
        project={data}
        states={states}
        onChange={onChange}
        errors={saveErrors}
      />
      <Reimbursements data={data} onChange={onChange} errors={saveErrors} />
      <div className={classes.stickyContainer}>
        <NavBar>
          <NavBar.Item>
            <StyledButton
              color="primary"
              variant="contained"
              data-test-id="Details-saveButton"
              onClick={saveDetails}
              disabled={saveLoading || isSaveValid}
              startIcon={
                saveLoading && (
                  <CircularProgress
                    size={20}
                    className={classes.loadingThrobber}
                  />
                )
              }
            >
              Save
            </StyledButton>
          </NavBar.Item>
          <NavBar.Item>
            <StyledButton
              color="primary"
              variant="outlined"
              onClick={() => cancel()}
            >
              Cancel
            </StyledButton>
          </NavBar.Item>
        </NavBar>
      </div>
    </>
  );
};

export default withSnackbarNotification(Details);
