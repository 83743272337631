import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const QUERY = gql`
  query offerW9Document($offerId: Int!) {
    offer(id: $offerId) {
      loanoutCorporation {
        w9 {
          id
          downloadUrl
        }
      }
    }
  }
`;

const useOfferW9Document = (skip, offerId, id) => {
  const { error, loading, data } = useQuery(QUERY, {
    skip: skip,
    variables: { id, offerId },
    fetchPolicy: 'no-cache',
  });

  const doc = data?.offer?.loanoutCorporation?.w9;
  return { error, loading, data: doc };
};

export default useOfferW9Document;
