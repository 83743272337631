import React from 'react';

export default props => (
  <svg
    width={54}
    height={72}
    viewBox="0 0 54 72"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        d="M2 0h36.172a2 2 0 0 1 1.414.586l13.828 13.828A2 2 0 0 1 54 15.828V70a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
        id="a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <use fill="#FFF" xlinkHref="#a" />
        <path
          stroke="#888"
          strokeWidth={3}
          d="M2 1.5a.5.5 0 0 0-.5.5v68a.5.5 0 0 0 .5.5h50a.5.5 0 0 0 .5-.5V15.828a.5.5 0 0 0-.146-.353L38.525 1.646a.5.5 0 0 0-.353-.146H2z"
        />
      </g>
      <path
        d="M10.5 27h33a1.5 1.5 0 0 1 0 3h-33a1.5 1.5 0 0 1 0-3zm0 27h21a1.5 1.5 0 0 1 0 3h-21a1.5 1.5 0 0 1 0-3zm0-18h15a1.5 1.5 0 0 1 0 3h-15a1.5 1.5 0 0 1 0-3zm0 9h27a1.5 1.5 0 0 1 0 3h-27a1.5 1.5 0 0 1 0-3zM36.854.854l16.292 16.292a.5.5 0 0 1-.353.854H38a2 2 0 0 1-2-2V1.207a.5.5 0 0 1 .854-.353z"
        fill="#888"
      />
    </g>
  </svg>
);
