import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';

const StyledLink = styled(Link)(({ active }) => ({
  fontSize: '0.92307692em',
  lineHeight: '5.16666667',
  color: '#ddf2f3',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  fontWeight: 700,
  height: '40px',
  textDecoration: 'none',
  textTransform: 'uppercase',
  margin: '5px 10px',
  borderRadius: '6px',
  padding: '5px 10px',
  background: active ? '#155eef' : 'none',
  '&:hover': {
    background: '#155eef',
  },
}));

const Item = props => {
  const { location = {}, to, isActive, ...rest } = props;
  const { pathname } = location || {};
  var classes = null;
  const handleClick = event => {
    if (pathname === `/${to}`) {
      event.preventDefault();
      // Force a page reload
      window.location.reload();
    }
  };
  if (to) {
    if (typeof isActive === 'undefined') {
      return (
        <StyledLink
          {...rest}
          to={`/${to}`}
          onClick={handleClick}
          active={pathname.includes(to)}
          params={props.params}
          query={props.query}
        >
          {props.children}
        </StyledLink>
      );
    } else {
      return (
        <StyledLink
          {...rest}
          to={`/${to}`}
          active={isActive}
          onClick={handleClick}
          params={props.params}
        >
          {props.children}
        </StyledLink>
      );
    }
  } else {
    return <span className={classes}>{props.children}</span>;
  }
};

export default withRouter(Item);
