import React, { Component } from 'react';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Box from '../../Base/Box';
import Link from '../../Base/Link';
import Header from '../../Base/Header';
import Form from '../../Base/Form';
import NavBar from '../../Base/NavBar';
import Separator from '../../Base/Separator';
import Preloader from '../../Shared/Preloader';
import Addresses from '../UserProfile/Addresses/Index';
import General from '../UserProfile/General';
import Emergency from '../UserProfile/Emergency';
import T from '../../../utils/i18n';
import FailureAlert from '../../Shared/FailureAlert';
import Button from '../../Base/Button';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
// HoC
import withPermissionProtection from 'common/hoc/withPermissionProtection';
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';

class Profile extends Component {
  static queries = {
    me: {
      info: function() {
        return {
          id: '/me',
        };
      },
    },
    states: {
      info: () => ({
        id: '/states',
      }),
    },
  };

  static mutators = {
    profile: {
      info: () => ({ id: '/account/profile' }),
      stale: () => ['/account/profile', '/me'],
    },
  };

  componentDidUpdate(prevProps, prevState) {
    const { profile = {} } = this.props;
    this.cleanUpAddressData(profile);
  }

  cleanUpAddressData = profileMutator => {
    // Check if the secondary addresses are missing
    // If they are then make them the default secondary address object
    const { status, data = {}, update } = profileMutator || {};
    if (status !== 'success' || typeof update !== 'function') return;

    const { mailing_address = {}, w2_address = {} } = data || {};

    const addresses = {
      mailing_address,
      w2_address,
    };
    Object.entries(addresses).forEach(([addressType, address]) => {
      if (!address || Object.keys(address).length === 0) {
        update(addressType)({ use_primary: true });
      }
    });
  };

  updateAddress = (address, type) => {
    const { profile: profileMutator = {} } = this.props;
    return profileMutator.update(type)(address);
  };

  renderAddressSection = () => {
    const {
      states: statesQuery = {},
      profile: profileMutator = {},
    } = this.props;

    const { status: statesStatus, data: statesData = {} } = statesQuery;
    const { items: states = [] } = statesData;
    switch (statesStatus) {
      case 'loading':
        return <Preloader />;
      case 'success':
        return (
          <Addresses
            states={states}
            profileMutator={profileMutator}
            onChange={this.updateAddress}
          />
        );
      default:
        return <FailureAlert queryName="States API Data" />;
    }
  };

  render() {
    const { profile: profileMutator = {} } = this.props;
    const { me: meQuery = {} } = this.props;
    const { data: me = {} } = meQuery || {};
    var cancelAction = '/offers';
    const profileIsLoading = profileMutator.status === 'loading';

    if (profileIsLoading || meQuery.status === 'loading') {
      return <Preloader />;
    }

    if (me.is_admin || me.can_access_projects) {
      cancelAction = 'projects';
    }

    return (
      <LayoutContent>
        <Box>
          <BoxItem>
            <Header>
              <Header.Title>{T('account.profile.title')}</Header.Title>
            </Header>
          </BoxItem>

          <Separator />

          <Form
            action="/account/profile"
            method="post"
            onSubmit={this._submitForm}
          >
            <BoxItem>
              {T(
                'To change your Social Security Number or Date of Birth contact ' +
                  'plus.support@castandcrew.com or call 818.860.7770 for support.',
              )}
              <br />
              {T(
                'Note: Changes you make to your profile will only be seen ' +
                  'on new offers.',
              )}
              <General profileMutator={profileMutator} />

              <Separator />

              {this.renderAddressSection()}

              <Separator />

              <Emergency profileMutator={profileMutator} />
            </BoxItem>

            <Separator />

            <BoxItem>
              <NavBar important>
                <NavBar.Item>
                  <Button data-test-id="Profile-saveButton">
                    Save Changes
                  </Button>
                </NavBar.Item>{' '}
                <NavBar.Item>
                  {T('form.actions.or')}{' '}
                  <Link to={cancelAction} data-test-id="Profile-cancelButton">
                    {T('form.actions.cancel')}
                  </Link>
                </NavBar.Item>
              </NavBar>
            </BoxItem>
          </Form>
        </Box>
      </LayoutContent>
    );
  }

  _submitForm = () => this.props.profile.save('edit', {}, function() {});
}

export default compose(
  withPermissionProtection('can_access_crew_profile'),
  withApi,
)(Profile);
