import React, { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import DialogTitle from '@mui/material/DialogTitle';

import Loader from 'common/components/Loader/Loader';
import CrewFields from './CrewFields';
import AddNote from './AddNote';
import FieldsConfirm from './FieldsConfirm';
import useCrewGroupFileds from './hooks/useCrewGroupFileds';
import ErrorIndicator from './ErrorIndicator';
import { styles } from './styles';

const RejectI9 = ({ onClose, documentId, classes, employee }) => {
  const [open, setOpen] = useState(true);
  const [next, setNext] = useState('');
  const [crewFields, setCrewFields] = useState([]);

  const [finalData, setFinalData] = useState({
    fields: [],
    note: '',
  });

  const { data: documentData, error, loading } = useCrewGroupFileds(documentId);

  useEffect(() => {
    if (documentData) {
      const crewFields = documentData || [];
      const modifiedFields = crewFields.map(obj => ({
        checked: false,
        ...obj,
      }));
      setCrewFields(modifiedFields);
    }
  }, [documentData]);

  const handleClose = (value, reason) => {
    if (reason === 'backdropClick') return;
    setOpen(false);
    onClose(value);
  };

  const handleNext = (step, { note = '', fields = [] }) => {
    setNext(step);
    setFinalData({
      note: note ? note : finalData.note,
      fields: fields && fields.length > 0 ? fields : finalData.fields,
    });
  };

  const renderComponent = () => {
    switch (next) {
      case 'fields':
        return (
          <CrewFields
            onClose={handleClose}
            classes={classes}
            onNext={handleNext}
            data={finalData}
            fields={crewFields}
            loading={loading}
          />
        );
      case 'note':
        return (
          <AddNote
            classes={classes}
            onClose={handleClose}
            onNext={handleNext}
            data={finalData}
          />
        );
      case 'confirm':
        return (
          <FieldsConfirm
            classes={classes}
            onClose={handleClose}
            onNext={handleNext}
            data={finalData}
            user={employee}
            documentId={documentId}
          />
        );
      default:
        return (
          <CrewFields
            onClose={handleClose}
            classes={classes}
            onNext={handleNext}
            data={finalData}
            fields={crewFields}
            loading={loading}
          />
        );
    }
  };

  const renderTitle = () => {
    switch (next) {
      case 'note':
        return 'Add a Note To Crew';
      case 'confirm':
        return 'Confirm I-9 Correction Request';
      default:
        return 'I-9 Correction Request';
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      classes={{ paper: classes.dialogPaper }}
      disableEscapeKeyDown={true}
      className={classes.dialogRoot}
      fullWidth={false}
      maxWidth="xs"
      data-test-id="rejecti9-fields-dialog"
    >
      <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
        {renderTitle()}
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {error && (
        <ErrorIndicator
          message={
            'Oops! Something went wrong. Please try after some time or contact support'
          }
        />
      )}
      {loading && (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      )}
      {renderComponent()}
    </Dialog>
  );
};

export default withStyles(styles)(RejectI9);
