import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query templates($projectId: Int!, $search: String, $page: Int) {
    templatesList(projectId: $projectId, search: $search, page: $page) {
      items {
        id
        name
      }
      perPage
      totalPages
      totalItems
      page
    }
  }
`;

const useTemplates = (projectId, search, page) => {
  const { error, loading, data } = useQuery(QUERY, {
    skip: !projectId,
    variables: { projectId: parseInt(projectId), search, page },
    fetchPolicy: 'no-cache',
  });
  return { error, loading, data };
};

export default useTemplates;
