import React from 'react';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';

const styles = {
  root: {
    zoom: '1',
    background: '#ffffff',
    padding: '22px 30px',
    position: 'relative',
    '&:after': { content: '" "', display: 'table', clear: 'both' },
    '&:before': { content: '" "', display: 'table' },
  },
  compact: { marginTop: '0' },
  secondary: {
    background: 'linear-gradient(to right, #0000FF, #0000B3)',
    padding: '37px 0',
  },
  tertiary: { background: '#646464', color: '#ffffff' },
  container: { background: 'none' },
  boxed: { border: '1px solid #e6e7ea', borderTop: '0px' },
  divider: { borderBottom: '1px solid #e6e7ea' },
  first: { borderTop: '1px solid #e6e7ea' },
  quaternary: { padding: '22px 0' },
  noPadding: { padding: '0px' },
  minimal: { padding: '0px 30px' },
  inverse: { margin: '22px 30px 0 30px', padding: '0 0 22px 0' },
  signer: { padding: '0', height: 'calc(100vh - 190px)' },
  flex: { display: 'flex' },
};

const BoxItem = props => {
  const {
    classes,
    children,
    compact,
    secondary,
    tertiary,
    container,
    boxed,
    divider,
    first,
    quaternary,
    nopadding,
    minimal,
    inverse,
    signer,
    flex,
  } = props;
  const classFirst = !!(boxed && first);
  const rootClass = classNames(classes.root, {
    [classes.compact]: compact,
    [classes.secondary]: secondary,
    [classes.tertiary]: tertiary,
    [classes.container]: container,
    [classes.boxed]: boxed,
    [classes.divider]: divider,
    [classes.first]: classFirst,
    [classes.quaternary]: quaternary,
    [classes.noPadding]: nopadding,
    [classes.minimal]: minimal,
    [classes.inverse]: inverse,
    [classes.signer]: signer,
    [classes.flex]: flex,
  });
  return <div className={rootClass}>{children}</div>;
};

export default withStyles(styles)(BoxItem);
