import { useQuery } from 'react-apollo';
import { gql } from 'graphql-tag';

const QUERY = gql(`
  query downloadExternalDocument(
    $offerId: Int!
    $documentId: Int!
  ) {
    offer(
      id: $offerId
    ) {
      externalDocuments(id: $documentId) {
        downloadUrl
      }
    }
  }
`);

const useDownloadExternalDocument = (offerId, documentId) => {
  const { loading, data, error } = useQuery(QUERY, {
    skip: !parseInt(offerId, 10) || !parseInt(documentId, 10),
    variables: {
      offerId: parseInt(offerId, 10),
      documentId: parseInt(documentId, 10),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  return { loading, error, data: (data?.offer?.externalDocuments || [])[0] };
};

export default useDownloadExternalDocument;
