import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import PacketDocument from './Packets/PacketDocument';
import StatusFlag from 'common/oldJavascripts/components/Shared/StatusFlag';
import Text from 'common/oldJavascripts/components/Base/Text';
import { Folder as FolderIcon } from '@mui/icons-material';
import classNames from 'classnames';

const styles = {
  documentsAlert: {
    flex: '1 1 auto',
    marginBottom: 5,
    padding: '0px 10px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  packet: {
    display: 'flex',
    alignItems: 'flex-end',
    lineHeight: '15px',
  },
  packetContent: {
    marginRight: '5px',
  },
};

class Packet extends Component {
  render() {
    const { classes = {} } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.packet}>
          <FolderIcon
            className={classNames(
              classes.packetContent,
              this._getFolderIconClass(),
            )}
          />
          <Text
            bold={true}
            className={classes.packetContent}
            title={this.props.packet.name}
          >
            {this.props.packet.name}
          </Text>
        </div>
        {this._renderStatusFlag()}
        {this._renderDocuments()}
      </div>
    );
  }

  _getFolderIconClass() {
    return this.props.colorStyle === 'grey' ? 'icon-grey' : 'icon-blue';
  }

  _renderDocuments() {
    const { classes } = this.props;
    const props = this.props;
    const packet = props.packet;
    const documents = packet.documents;

    if (documents.length === 0) {
      return (
        <div className={classes.documentsAlert}>
          <Blankslate minimal={true} theme="dark">
            No Documents Defined
          </Blankslate>
        </div>
      );
    }

    return documents.map(doc => {
      const params = Object.assign({ documentId: doc.id }, props.params);
      return (
        <PacketDocument
          allowPreview={props.allowPreview}
          document={doc}
          isApproval={props.isApproval}
          key={`packet-document-${doc.id}`}
          onDocumentView={props.onDocumentView}
          params={params}
          user={props.user}
        />
      );
    });
  }

  _renderStatusFlag() {
    if (this.props.packet.is_draft) {
      return (
        <StatusFlag
          status="warning"
          text="This packet contains documents still in draft state"
        />
      );
    }
  }
}

Packet.defaultProps = {
  isApproval: false,
};

export default withStyles(styles)(Packet);
