import React from 'react';
import moment from 'moment';
import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';

import useOfferHistory from '../hooks/useOfferHistory';
import config from 'common/oldJavascripts/config';

import Loader from 'common/components/Loader';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';

const useStyles = makeStyles({
  eventDate: {
    alignSelf: 'start',
    padding: '7px',
    fontWeight: 'bold',
    '&:after': {
      fontWeight: 'normal',
      content: '" -"',
    },
  },
  listText: {
    overflowWrap: 'break-word',
  },
});

const FullHistory = ({ offer = {} }) => {
  const { id: offerId } = offer;
  const { data: history = [], loading, error } = useOfferHistory(offerId);
  const classes = useStyles();

  if (loading) return <Loader />;
  if (error) return <FailureAlert queryName="offerHistory" />;

  return (
    <List data-test-id="OfferHistory-list">
      {history.map(({ datetime, description }, index) => (
        <ListItem key={`offerHistory-item-${index}`}>
          <ListItemAvatar className={classes.eventDate}>
            {moment
              .utc(datetime, config.DATE_FORMAT.DB)
              .local()
              .format('MM/DD/YYYY, h:mm A')}
          </ListItemAvatar>
          <ListItemText primary={description} className={classes.listText} />
        </ListItem>
      ))}
    </List>
  );
};

export default FullHistory;
