import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Alert } from '@mui/material';
import { withStyles } from '@mui/styles';

import useDeleteOfferDraft from '../hooks/useDeleteOfferDraft';

const styles = {
  error: {
    background: '#d32f2f !important',
    color: '#fff',
    '&:hover': {
      background: '#ef5350',
      color: '#fff',
    },
  },
};

const contentStrings = {
  template:
    'Are you sure you want to delete this offer template? This cannot be undone and the offer template will be unavailable for all other Hiring Managers on the Project',
  draft: 'Are you sure you want to delete this offer from your drafts?',
};

const DeleteModal = props => {
  const { draft, open, onClose, onDelete, classes, deleteError = null } = props;
  const { id: draftId } = draft || {};
  const [deleteDraft, { loading }] = useDeleteOfferDraft();
  const [showError, setShowError] = useState(false);

  const [modalData, setModalData] = useState({
    title: '',
    content: '',
  });

  const isTemplate = draft?.isTemplate;

  useEffect(() => {
    if (!open) return;
    setModalData({
      title: isTemplate ? 'Delete Template' : 'Delete Draft',
      content: isTemplate ? contentStrings.template : contentStrings.draft,
    });
  }, [isTemplate, open]);

  useEffect(() => {
    setShowError(Boolean(deleteError));
  }, [setShowError, deleteError]);

  const draftDelete = () => {
    setShowError(false);
    deleteDraft({ variables: { id: draftId } })
      .then(() => onDelete({ success: true, isTemplate, draft }))
      .catch(err => onDelete({ success: false, error: err }));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{modalData.title}</DialogTitle>
      <DialogContent>
        <Typography>{modalData.content}</Typography>
        {showError && (
          <Alert severity="error" data-test-id="OfferDrafts-delete-error">
            Delete failed.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={onClose}
          data-test-id="OfferDrafts-deleteDialog-cancel"
        >
          Cancel
        </Button>
        <Button
          className={classes.error}
          variant="contained"
          onClick={draftDelete}
          disabled={loading}
          startIcon={
            loading && <CircularProgress size={16} color="secondary" />
          }
          data-test-id="OfferDrafts-deleteDialog-submit"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DeleteModal);
