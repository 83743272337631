import React from 'react';

// HoC
import { withStyles } from '@mui/styles';

import Button from '@mui/material/Button';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    display: 'inline-block',
    width: 'auto',
  },
});

const SubmitOffer = ({ onClick, classes = {} }) => (
  <div className={classes.root}>
    <Button
      variant="contained"
      size="large"
      color="primary"
      className={classes.button}
      onClick={onClick}
      data-test-id="SubmitOffer-button"
    >
      Verify
    </Button>
  </div>
);

export default withStyles(styles)(SubmitOffer);
