import gql from 'graphql-tag';

export default gql`
  query userAccessReport(
    $producerId: Int!
    $employeeRole: String!
    $userStatus: String!
  ) {
    userAccessReport(
      producerId: $producerId
      employeeRole: $employeeRole
      userStatus: $userStatus
    ) {
      employeeId
      producerName
      productionCompanyName
      projectName
      projectStatus
      employeeName
      employeeRole
      employeeEmail
      activeStatusForUserInProject
      inviteDate
      deletedDate
      userLastActivity
      lastStartUserLogInDate
      userPermissions
    }
  }
`;
