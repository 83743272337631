import gql from 'graphql-tag';

export default gql`
  query CreateOfferCrewMembers(
    $id: Int!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $search: String
  ) {
    project(id: $id) {
      id
      crew(
        first: $first
        after: $after
        last: $last
        before: $before
        search: $search
      ) {
        edges {
          cursor
          node {
            id
            profile {
              firstName
              lastName
              email
            }
            offers {
              id
            }
          }
        }
      }
    }
  }
`;
