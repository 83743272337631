const isProd = () => {
  const env = process.env.REACT_APP_ENVIRONMENT;
  return env === 'prod' || env === 'prod-2';
};

export const getClientIdMyCnC = () =>
  isProd() ? '0oa1b383d8XvfKYp0356' : '0oail3tmV0Phe7Ktr355';

export const getBaseUrl = () =>
  isProd()
    ? 'https://login.castandcrew.com'
    : 'https://login.dev.aws.castandcrew.com';

export const getIssuerMyCnC = () =>
  isProd()
    ? 'https://login.castandcrew.com/oauth2/aus77kwrwSbSunX5s356'
    : 'https://login.dev.aws.castandcrew.com/oauth2/aus67ubcqBSG33G9h356';

export default {
  issuer: getIssuerMyCnC(),
  clientId: getClientIdMyCnC(),
  url: {
    myCnC: `${process.env.REACT_APP_SSO_LOGOUT_URL}/app-links`,
    oktaUserSettings: `${getBaseUrl()}/enduser/settings`,
  },
};
