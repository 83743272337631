import React from 'react';

// Components
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import MaskedInput from 'react-text-mask';

// Constants
import { allowancesDurationMask } from '../helpers/textFieldNumberMasks';

const DurationField = ({
  id,
  onChange: upstreamOnChange,
  value = null,
  updateKey,
  classes = {},
}) => {
  const onChange = ({ target: { value: rawValue } = {} } = {}) => {
    let value = rawValue;
    if (rawValue === '' || rawValue === '0') value = '';

    upstreamOnChange({
      [updateKey]: value,
    });
  };

  const onBlur = ({ target: { value } = {} } = {}) => {
    if (value === null || value === 0 || value === '') return;

    upstreamOnChange({
      [updateKey]: parseInt(value) || '',
    });
  };

  return (
    <TableCell align="center" className={classes.root}>
      <MaskedInput
        mask={allowancesDurationMask}
        value={value < 1 ? null : value}
        onChange={onChange}
        onBlur={onBlur}
        render={(ref, props) => (
          <TextField
            inputRef={ref}
            {...props}
            className={classes.textField}
            InputProps={{
              className: classes.textFieldInputContainer,
              inputProps: {
                className: classes.textFieldInput,
                'data-test-id': `DurationField-input-${id}`,
              },
            }}
          />
        )}
      />
    </TableCell>
  );
};

export default DurationField;
