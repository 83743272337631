import React from 'react';
import DatePicker from 'react-datepicker';
import Typography from '@mui/material/Typography';
import moment from 'moment';

const StartDate = props => {
  const {
    backdateLimit,
    classes,
    onChange: upstreamOnChange,
    formData: { startDateObject, endDateObject, isLinkActive } = {},
    formErrors = {},
    sendDateConfiguration,
  } = props || {};
  const {
    startDate: startDateError,
    backdateLimit: backdateLimitError,
  } = formErrors;
  const isSendOnStartAlways = sendDateConfiguration === 'always_start_date';

  const onChange = date => {
    const patch = {
      startDate: (date && date.format('LL')) || null,
      startDateObject: date,
    };
    const today = moment();

    // If link is switched on & start date is in past, switch link off
    const isStartDateInPast = date && date.isBefore(moment(), 'day');
    if (isLinkActive && isStartDateInPast) {
      if (isLinkActive) {
        patch.sendDate = today.format('MM/DD/YYYY');
        patch.sendDateObject = today;
      }
    }

    // If the send date is linked and the new date is not in the past,
    // update the send date to the start date time
    if (isSendOnStartAlways || (isLinkActive && !isStartDateInPast)) {
      patch.sendDate = patch.startDate;
      patch.sendDateObject = patch.startDateObject;
      // Set send date today when startDate is past date.
      if (isStartDateInPast) {
        patch.sendDate = today.format('MM/DD/YYYY');
        patch.sendDateObject = today;
      }
    }

    // If we changed the start date and now the anticipated end date occurs
    // before the start date, clear the end date
    const isEndDateBeforeStartDate =
      endDateObject && endDateObject.isBefore(date, 'day');
    if (isEndDateBeforeStartDate) {
      patch.endDate = null;
      patch.endDateObject = null;
    }

    upstreamOnChange(patch);
  };

  const minDate = moment().subtract(backdateLimit, 'day');

  const error = startDateError || backdateLimitError;

  return (
    <div className={classes.root} data-test-id="StartDate-root">
      <Typography className={classes.label} variant="body1">
        Start Date
      </Typography>
      <DatePicker
        todayButton={'Today'}
        selected={startDateObject}
        onChange={onChange}
        className={classes.field}
        minDate={minDate}
      />
      {error && (
        <Typography
          className={classes.error}
          variant="caption"
          data-test-id="StartDate-error"
        >
          {error}
        </Typography>
      )}
    </div>
  );
};
export default StartDate;
