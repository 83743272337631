import React from 'react';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

import classnames from 'classnames';

import useRejectI9Fields from './hooks/useRejectI9Fields';

const ConfirmButton = ({ classes, data, onError, onClose, documentId }) => {
  const { note, fields } = data;

  const [rejectI9, { loading }] = useRejectI9Fields();

  const excludedFields = () =>
    fields.filter(field => field.checked).map(field => field.fieldId);

  const documentFields = () =>
    fields.map(({ checked, __typename, ...field }) => field);

  const variables = {
    documentId,
    excludedFields: excludedFields() || [],
    documentFields: documentFields() || [],
    rejectionNotes: note,
  };

  const handleSubmit = () => {
    rejectI9({ variables })
      .then(({ data }) => {
        const { rejectI9 } = data;
        if (rejectI9) {
          onClose('sent');
        }
      })
      .catch(err => {
        onError(err.message);
      });
  };

  return (
    <Button
      disabled={loading}
      onClick={() => handleSubmit()}
      className={classnames(classes.btn, classes.btnContained)}
      data-test-id="submit-rejecti9"
      variant="contained"
      color="primary"
    >
      {loading ? (
        <>
          Processing... <CircularProgress size={20} />
        </>
      ) : (
        'Yes, Reject I-9'
      )}
    </Button>
  );
};

export default ConfirmButton;
