import React from 'react';
import Select from 'react-select';
import Typography from '@mui/material/Typography';
import { withRouter } from 'react-router';
import formatOptionLabel from './formatOptionLabel';

const Fringeselect = props => {
  const {
    onChange: upstreamOnChange,
    contracts = [],
    formData: { fringeContract },
    error = '',
    selectStyles,
    classes = {},
    disabled,
    fringeLoading,
  } = props;

  const pensionContract = contracts?.find(
    contract => contract.type === 'pension',
  );

  const pensionArr = pensionContract?.contracts || [];
  const options = pensionArr?.map(contract => {
    const { code, description } = contract;
    return {
      ...contract,
      value: code,
      label: description,
    };
  });

  const { code: fringeCode } = fringeContract || {};
  const value = options?.find(({ code }) => fringeCode === code) || null;

  const onChange = ({
    code: contractCode,
    description: contractDescription,
    totalFringePercentage: totalContractFringePercentage,
  }) =>
    upstreamOnChange({
      fringeContract: {
        code: contractCode,
        description: contractDescription,
        totalFringePercentage: totalContractFringePercentage,
      },
    });

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1">
        Vacation/Fringe
      </Typography>
      <div data-test-id="FringeSelect-select">
        <Select
          styles={selectStyles}
          value={value}
          onChange={onChange}
          options={options}
          className={classes.select}
          isLoading={fringeLoading}
          error={error}
          formatOptionLabel={formatOptionLabel('Fringeselect')}
          isDisabled={disabled}
        />
      </div>
    </div>
  );
};

export default withRouter(Fringeselect);
