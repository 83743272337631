import gql from 'graphql-tag';

export default gql`
  query projectCloneSettings($projectId: Int!) {
    projectCloneSettings(projectId: $projectId) {
      cloned
      environments {
        id
        label
      }
    }
  }
`;
