import React, { Component } from 'react';

import { withStyles } from '@mui/styles';

// MaterialUI Components
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  ThemeProvider,
} from '@mui/material';

// Utilities
import isEqual from 'lodash.isequal';
import { documentSignerTheme } from 'common/shared/oldDocumentSignerUI/theme';
import uuid from 'uuid';
import {
  newTriggerRule,
  newConditionalRule,
  newFieldGroup,
  newWorkflowRule,
  handleRuleGroupAssignment,
  removeRuleFieldGroup,
  getRuleRoleId,
} from 'admin/components/RoleMapper/documentSetupHelpers/rules';
import {
  newRole,
  addFieldsMapped,
} from 'admin/components/RoleMapper/documentSetupHelpers/roles';
import {
  newCustomInput,
  newInputField,
} from 'admin/components/RoleMapper/documentSetupHelpers/customInputs';
import {
  getDocumentDetails,
  formatReplacementDialogText,
  formatDocumentData,
  filterTextFieldOptions,
} from 'admin/components/RoleMapper/documentSetupHelpers/documentHelpers';
import { addSystemField } from 'admin/components/RoleMapper/documentSetupHelpers/fields';
import { newEmailNotification } from 'admin/components/RoleMapper/documentSetupHelpers/notifications';
import ajax from 'common/utilities/ajax';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';

// hoc
import withDialogNotification from 'common/hoc/withDialogNotification';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import { compose } from 'redux';

// Custom Components
import { DocumentViewer } from '@castandcrew/document-signer-react';
import GroupContainer from 'admin/components/RoleMapper/GroupContainer';
import DeleteConfirmationDialog from 'admin/components/RoleMapper/DeleteConfirmationDialog';
import RuleConfigurationDialog from 'admin/components/RoleMapper/RuleConfigurationDialog';
import CustomInputDialog from 'admin/components/RoleMapper/CustomInputDialog';
import FieldIconButton from 'admin/components/RoleMapper/FieldIconButton';
import ToolbarActionButtons from 'admin/components/RoleMapper/ToolbarActionButtons';
import EmailConfigurationDialog from 'admin/components/RoleMapper/EmailConfigurationDialog';
import DiscardChangesDialog from 'admin/components/RoleMapper/DiscardChangesDialog';

const styles = theme => ({
  root: {
    boxSizing: 'border-box',
    display: 'grid',
    height: '100%',
    overflow: 'hidden',
    gridTemplateColumns: '100%',
    gridTemplateRows: '100%',
    gridTemplateAreas: `
      "document"
    `,
  },
  sidebar: {
    gridArea: 'sidebar',
  },
  document: {
    gridArea: 'document',
  },
  newFields: {
    fontWeight: 'bold',
  },
  actionBar: {
    background: 'white',
    color: '#6e6e6e',
    borderBottom: `1px solid #d7d7d7`,
  },
});

class RoleMapper extends Component {
  static defaultProps = {
    roles: [],
    isSaving: false,
    saveSuccess: false,
    systemFieldOptions: [],
    textFieldOptions: [],
    tags: [],
  };

  constructor(props) {
    super(props);
    const {
      roles = [],
      isReplacement = false,
      doc = {},
      systemFieldOptions = [],
      rules = [],
      inputs = [],
    } = props;
    const { fields = [] } = doc || {};
    // Calculate the number of new fields per page when an existing document is replaced
    const newFieldsPerPage = {};
    fields
      .filter(field => field.fieldGroupId === null)
      .forEach(field => {
        if (newFieldsPerPage[field.page]) {
          newFieldsPerPage[field.page] += 1;
          return;
        }
        newFieldsPerPage[field.page] = 1;
      });
    this.state = {
      roles,
      newFieldsPerPage,
      rules,
      systemFieldOptions,
      inputs,
      fields,
      newFieldsModalIsOpen: isReplacement,
      activeRoleId: null,
      deleteConfirmationModalIsOpen: false,
      configureDialogIsOpen: false,
      mapModeActive: false,
      highlightUnmappedFields: false,
      selectedField: '',
      drawerOpen: false,
      activeRuleId: null,
      activeRuleGroupId: null,
      deleteMessage: null,
      deleteCallback: null,
      ruleToConfigure: {},
      activeRuleRoleId: null,
      inputDialogIsOpen: false,
      inputToConfigure: null,
      triggerRuleIdToHighlight: null,
      triggerSourceIdToHighlight: null,
      emailDialogOpen: false,
      isEditingRoleName: null,
      discardChangesDialogIsOpen: false,
      isDiscardingChanges: false,
      drawerValues: {
        name: '',
        fieldType: 'TXT',
        textType: null,
        isRequired: false,
        systemField: {},
        systemFieldMapping: {},
        id: null,
      },
    };
  }

  componentDidMount() {
    const { fields = [] } = this.props.doc || {};
    this.formatFields(fields);
  }

  static getDerivedStateFromProps(props, state) {
    const { systemFieldOptions: upstreamSystemFieldOptions = [] } = props;
    const { systemFieldOptions } = state;
    // Update the system fields as updated props are received
    return {
      systemFieldOptions: upstreamSystemFieldOptions.length
        ? upstreamSystemFieldOptions
        : systemFieldOptions,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { systemFieldOptions = [] } = prevState;
    const {
      inputs: previousInputs = [],
      rules: previousRules = [],
      doc: { fields: prevFields = [] } = {},
    } = prevProps;
    const {
      systemFieldOptions: upstreamSystemFieldOptions,
      inputs = [],
      rules = [],
      doc = {},
    } = this.props;
    const { fields: currentFields = [] } = doc || {};
    if (!isEqual(prevFields, currentFields)) {
      this.formatFields(currentFields);
    }
    // Format the fields if the system fields were previously empty
    if (
      systemFieldOptions.length === 0 &&
      upstreamSystemFieldOptions.length > 0
    ) {
      this.formatFields(currentFields);
    }
    // Update the component state if updated upstream inputs are received from saving
    if (!isEqual(previousInputs, inputs)) {
      this.setState({ inputs });
    }
    // Update component state when rules are saved
    if (!isEqual(previousRules, rules)) {
      this.setState({ rules, isEditingRoleName: null }, () =>
        this.formatFields(this.props.doc.fields),
      );
    }
  }

  // Update system field with the assigned system field object
  // Add rule name, rule group id, and rule group name to the field if applicable
  // in order to track button status, color, and role assignment eligibility during rule mapping
  formatFields = fields => {
    const { systemFieldOptions } = this.props;
    const { roles } = this.state;
    const formattedFields = fields.map(field => {
      let updatedField = { ...field };
      const { fieldGroupId } = field;
      updatedField = addSystemField(updatedField, systemFieldOptions);
      return { ...updatedField, roleId: fieldGroupId || null };
    });
    const updatedRoles = addFieldsMapped(formattedFields, roles);
    this.setState({ fields: formattedFields, roles: updatedRoles });
  };

  // Set the active role id for mapping or to null if toggling off role mapping
  setActiveRole = roleId => {
    this.setState(({ activeRoleId }) => {
      if (activeRoleId === roleId) {
        return {
          activeRoleId: null,
          highlightUnmappedFields: false,
          triggerSourceIdToHighlight: null,
        };
      } else {
        return {
          activeRoleId: roleId,
          highlightUnmappedFields: false,
          triggerSourceIdToHighlight: null,
        };
      }
    });
  };

  toggleEditRoleName = roleId =>
    this.setState(({ isEditingRoleName }) => ({
      isEditingRoleName: isEditingRoleName === roleId ? null : roleId,
    }));

  toggleHighlightUnmappedFields = () => {
    this.setState(({ highlightUnmappedFields }) => ({
      highlightUnmappedFields: !highlightUnmappedFields,
      triggerSourceIdToHighlight: null,
    }));
  };

  toggleTriggerRuleHighlight = ruleId => {
    this.setState(({ triggerRuleIdToHighlight }) => ({
      triggerRuleIdToHighlight:
        triggerRuleIdToHighlight === ruleId ? null : ruleId,
      triggerSourceIdToHighlight: null,
      highlightUnmappedFields: false,
    }));
  };

  toggleRuleMapping = (ruleId, ruleGroupId) => {
    this.setState(({ rules, fields, activeRuleGroupId }) => {
      // Turn off rule mapping
      if (activeRuleGroupId === ruleGroupId) {
        return {
          activeRuleId: null,
          activeRuleGroupId: null,
          activeRuleRoleId: null,
          triggerSourceIdToHighlight: null,
        };
      }
      // Turn on rule mapping
      const selectedRule = rules.find(rule => rule.id === ruleId) || {};
      const roleId = getRuleRoleId(rules, fields, ruleId);
      return {
        activeRuleId: selectedRule.id,
        activeRuleGroupId: ruleGroupId,
        activeRuleRoleId: roleId,
        triggerSourceIdToHighlight: null,
      };
    });
  };

  highlightTriggerSource = newId =>
    this.setState(({ triggerSourceIdToHighlight }) => ({
      triggerSourceIdToHighlight:
        newId === triggerSourceIdToHighlight ? null : newId,
    }));

  handleAddNewRole = () => {
    const newId = `__new-${uuid()}`;
    this.setState(
      ({ roles }) => ({
        roles: [...roles, newRole(newId, roles.length)],
      }),
      () => this.scrollToElement(`RoleListItem-${newId}`),
    );
  };

  // Delete an existing role and unassign all fields that were assigned to the role
  handleDeleteRole = roleId =>
    this.setState(({ roles, fields }) => ({
      roles: roles.filter(group => group.id !== roleId),
      fields: fields.map(field =>
        field.roleId === roleId ? { ...field, roleId: null } : field,
      ),
    }));

  handleRoleFieldAssignment = fieldId => {
    this.setState(({ activeRoleId, fields, rules, roles }) => {
      const previousField = fields.find(({ id }) => id === fieldId) || {};
      const { roleId } = previousField;
      let updatedRules = [...rules];
      const updatedField = {
        ...previousField,
        roleId: roleId === activeRoleId ? null : activeRoleId,
        fieldGroupId: roleId === activeRoleId ? null : activeRoleId,
      };
      const updatedFields = fields.map(field =>
        field.id === updatedField.id ? updatedField : field,
      );
      const updatedRoles = addFieldsMapped(updatedFields, roles);
      // Remove the field from any rules it is assigned to
      if (!updatedField.roleId) {
        updatedRules = rules.map(rule => ({
          ...rule,
          fieldGroups: rule.fieldGroups.map(fg => ({
            ...fg,
            fieldIds: fg.fieldIds.filter(id => id !== fieldId),
          })),
        }));
      }
      return {
        fields: updatedFields,
        roles: updatedRoles,
        rules: updatedRules,
      };
    });
  };

  updateRoleName = (id, updatedName) =>
    this.setState(({ roles }) => ({
      roles: roles.map(role =>
        role.id === id ? { ...role, name: updatedName } : role,
      ),
    }));

  updateRuleName = (id, updatedName) =>
    this.setState(({ rules }) => ({
      rules: rules.map(rule =>
        rule.id === id ? { ...rule, name: updatedName } : rule,
      ),
    }));

  // Create new trigger rule
  handleCreateTriggerRule = () => {
    const newId = `__new-${uuid()}`;
    this.setState(
      ({ rules }) => ({
        rules: [
          ...rules,
          newTriggerRule(
            newId,
            rules.filter(({ ruleType }) => ruleType === 'trigger').length,
          ),
        ],
      }),
      () =>
        // Use setTimeout to allow the Collapse component
        // to render fully before trying to scroll to the element
        setTimeout(
          () => this.scrollToElement(`TriggerRuleListItem-${newId}`),
          200,
        ),
    );
  };

  // Create new conditional rule
  handleCreateConditionalRule = () => {
    const newId = `__new-${uuid()}`;
    this.setState(
      ({ rules }) => ({
        rules: [
          ...rules,
          newConditionalRule(
            newId,
            rules.filter(({ ruleType }) => ruleType === 'group').length,
          ),
        ],
      }),
      () => this.scrollToElement(`ConditionalRuleListItem-${newId}`),
    );
  };

  // Map or unmap the field from a rule field group
  handleFieldRuleGroupChange = fieldId => {
    this.setState(({ fields, activeRuleGroupId, activeRuleId, rules }) => {
      const selectedRule = rules.find(rule => rule.id === activeRuleId) || {};
      const updatedRule = handleRuleGroupAssignment(
        selectedRule,
        activeRuleGroupId,
        fieldId,
      );
      const updatedRules = rules.map(r =>
        r.id === updatedRule.id ? updatedRule : r,
      );
      const ruleRoleId = getRuleRoleId(updatedRules, fields, selectedRule.id);
      return {
        rules: updatedRules,
        activeRuleRoleId: ruleRoleId,
      };
    });
  };

  // Add new field group to a conditional rule
  handleAddFieldGroupToConditionalRule = ruleId => {
    const newFieldGroupId = `__new-${uuid()}`;
    this.setState(
      ({ rules }) => ({
        rules: rules.map(rule =>
          rule.id === ruleId
            ? {
                ...rule,
                fieldGroups: [
                  ...rule.fieldGroups,
                  newFieldGroup(newFieldGroupId, rule.fieldGroups.length),
                ],
              }
            : rule,
        ),
      }),
      () => this.scrollToElement(`ConditionalRuleListItem-${newFieldGroupId}`),
    );
  };

  handleSaveRuleConfig = (rule, dialogKey) =>
    this.setState(
      ({ rules }) => ({
        rules: rules.map(r => (r.id === rule.id ? rule : r)),
      }),
      () => {
        const { ruleType } = rule;
        if (ruleType !== 'email') {
          this.saveDraft().then(() =>
            this.setState({ ruleToConfigure: {}, [dialogKey]: false }),
          );
          return;
        }
        this.setState({ ruleToConfigure: {}, [dialogKey]: false });
      },
    );

  // Update role mapper state with trigger rules as they're assigned/unassigned in the field drawer
  updateRules = (updatedRules, removedBadgeIds = []) =>
    this.setState(({ rules: prevRules }) => {
      // Update existing rules
      let rules = prevRules.map(rule => {
        const selectedRule = updatedRules.find(r => r.id === rule.id);
        return selectedRule ? selectedRule : rule;
      });
      // Remove the old badges
      rules = rules.filter(({ id, triggerFieldId, ruleType }) => {
        if (
          ruleType === 'badge' &&
          (removedBadgeIds.includes(id) || !triggerFieldId)
        )
          return false;
        return true;
      });
      // Add new badges
      const newBadges = updatedRules.filter(
        ({ ruleType, id }) =>
          ruleType === 'badge' &&
          !prevRules.find(({ id: ruleId }) => ruleId === id),
      );
      rules = rules.concat(newBadges);
      return {
        rules,
      };
    });

  // Delete conditional or trigger rule
  deleteRule = ruleId =>
    this.setState(({ rules, fields }) => {
      const updatedRules = rules
        .filter(({ id }) => id !== ruleId)
        // Remove the conditional rule id from any trigger
        // rules it's nested to
        .map(rule =>
          rule.ruleType === 'trigger'
            ? {
                ...rule,
                conditionalRuleIds: (rule.conditionalRuleIds || []).filter(
                  id => id !== ruleId,
                ),
              }
            : rule,
        );
      return {
        rules: updatedRules,
      };
    });

  // Delete field group from a conditional rule
  deleteGroupFromRule = (ruleId, ruleFieldGroupId) =>
    this.setState(({ rules, fields, activeRuleRoleId }) => {
      const updatedRules = removeRuleFieldGroup(
        rules,
        ruleId,
        ruleFieldGroupId,
      );
      const roleId = getRuleRoleId(rules, fields, ruleId);
      return {
        rules: updatedRules,
        fields,
        activeRuleRoleId: roleId,
        activeRuleGroupId: null,
      };
    });

  // Create new custom input
  handleCreateNewInput = () => {
    const newId = `__new-${uuid()}`;
    this.setState(
      ({ inputs }) => {
        const updatedInputs = [...inputs, newCustomInput(newId, inputs.length)];
        return {
          inputs: updatedInputs,
        };
      },
      () => this.scrollToElement(`CustomInput-${newId}`),
    );
  };

  // Add new input field to a custom input
  addNewInputField = inputId => {
    const newId = `__new-${uuid()}`;
    this.setState(
      ({ inputs }) => ({
        inputs: inputs.map(input =>
          input.id === inputId
            ? {
                ...input,
                inputFields: [...input.inputFields, newInputField(newId)],
              }
            : input,
        ),
      }),
      () =>
        // Use setTimeout to allow the Collapse component
        // to render fully before trying to scroll to the element
        setTimeout(() => {
          this.scrollToElement(`InputFieldListItem-${newId}`);
        }, 300),
    );
  };

  handleSaveInput = input => {
    this.handleCloseInputDialog();
    // Update the inputs array with the new input
    this.setState(
      ({ inputs }) => ({
        inputs: inputs.map(i => (i.id === input.id ? input : i)),
      }),
      () => {
        const { saveCustomInputs } = this.props;
        const { inputs } = this.state;
        saveCustomInputs(inputs);
      },
    );
  };

  // Delete custom input and submit delete request to server,
  // which is followed by a request for updated system fields
  // because deleted custom inputs should not appear in the system field list
  handleDeleteInput = inputId => {
    this.setState(
      ({ inputs }) => ({
        inputs: inputs.map(input =>
          input.id === inputId
            ? {
                ...input,
                active: false,
              }
            : input,
        ),
      }),
      () => {
        const { deleteCustomInput } = this.props;
        deleteCustomInput(inputId);
      },
    );
  };

  // Delete custom input field and submit save request for custom inputs
  handleDeleteInputField = (inputId, inputFieldName) =>
    this.setState(
      ({ inputs }) => ({
        inputs: inputs.map(input => {
          if (input.id === inputId) {
            const updatedInputFields = input.inputFields.map(field =>
              field.name === inputFieldName
                ? { ...field, active: false }
                : field,
            );
            return {
              ...input,
              inputFields: updatedInputFields,
            };
          } else {
            return input;
          }
        }),
      }),
      () => {
        const { saveCustomInputs } = this.props;
        const { inputs } = this.state;
        saveCustomInputs(inputs);
      },
    );

  // Update custom input and submit save request for custom inputs
  handleUpdateInput = updatedInput =>
    this.setState(
      ({ inputs }) => ({
        inputs: inputs.map(input =>
          input.id === updatedInput.id ? updatedInput : input,
        ),
      }),
      () => {
        const { saveCustomInputs } = this.props;
        const { inputs } = this.state;
        saveCustomInputs(inputs);
      },
    );

  handleOpenDrawer = id => {
    this.setState(({ fields }) => {
      const selectedField = fields.find(field => field.id === id) || {};
      // Transform selected field options to an array
      const options = Object.keys(selectedField.options || {});
      const drawerValues = {
        ...selectedField,
        options,
      };
      return { drawerOpen: true, drawerValues, selectedField: id };
    });
  };

  handleCloseDrawer = () =>
    this.setState({
      drawerOpen: false,
      triggerRuleIdToHighlight: null,
    });

  handleSaveField = fieldValues =>
    this.setState(
      ({ fields, selectedField: id }) => ({
        fields: fields.map(field => (field.id === id ? fieldValues : field)),
      }),
      this.handleCloseDrawer,
    );

  handleCloseNewFieldModal = () =>
    this.setState({ newFieldsModalIsOpen: false });

  handleOpenDeleteConfirmationDialog = (deleteMessage, deleteCallback) => {
    this.setState({
      deleteConfirmationModalIsOpen: true,
      deleteMessage,
      deleteCallback,
    });
  };

  handleCloseDeleteConfirmationDialog = () =>
    this.setState({
      deleteConfirmationModalIsOpen: false,
      deleteMessage: null,
      deleteCallback: null,
    });

  handleDeleteConfirmation = () => {
    const { deleteCallback } = this.state;
    deleteCallback();
    this.handleCloseDeleteConfirmationDialog();
  };

  handleOpenRuleConfigureDialog = ruleId =>
    this.setState(({ rules }) => ({
      configureDialogIsOpen: true,
      ruleToConfigure: rules.find(rule => rule.id === ruleId),
    }));

  handleCloseRuleConfigureDialog = () =>
    this.setState({ configureDialogIsOpen: false, ruleToConfigure: {} });

  handleOpenInputDialog = inputId =>
    this.setState(({ inputs }) => ({
      inputDialogIsOpen: true,
      inputToConfigure: inputs.find(input => input.id === inputId),
    }));

  handleCloseInputDialog = () => {
    this.setState({ inputDialogIsOpen: false, inputToConfigure: null });
  };

  createNewEmailNotification = () => {
    const newId = `__new-${uuid()}`;
    this.setState(
      ({ rules }) => ({
        rules: [
          ...rules,
          newEmailNotification(
            newId,
            rules.filter(({ ruleType }) => ruleType === 'email').length,
          ),
        ],
      }),
      () => this.scrollToElement(`EmailNotification-${newId}`),
    );
  };

  createNewWorkflowRule = () => {
    const newId = `__new-${uuid()}`;
    this.setState(
      ({ rules }) => ({
        rules: [
          ...rules,
          newWorkflowRule(
            newId,
            rules.filter(({ ruleType }) => ruleType === 'workflow').length,
          ),
        ],
      }),
      () => this.scrollToElement(`WorkflowRuleListItem-${newId}`),
    );
  };

  openEmailDialog = emailId => this.setState({ emailDialogOpen: emailId });

  closeEmailDialog = () => this.setState({ emailDialogOpen: false });

  saveDraft = () => {
    const { saveDraft } = this.props;
    const { fields, roles, rules } = this.state;
    const { fieldGroups, openFields } = formatDocumentData({
      fields,
      roles,
    });
    return saveDraft({
      fieldGroups,
      openFields,
      rules,
    });
  };

  handleSaveMappings = () => {
    const {
      submitGroupMappings,
      pushDialogNotification,
      popDialogNotification,
    } = this.props;
    const {
      fields: stateFields,
      roles: stateRoles,
      rules,
      inputs,
    } = this.state;
    const { fieldGroups, openFields } = formatDocumentData({
      fields: stateFields,
      roles: stateRoles,
    });
    const submit = () =>
      submitGroupMappings({
        fieldGroups,
        openFields,
        rules,
        inputs,
      });
    if (openFields.length) {
      const dialogProps = {
        title: 'Save Document',
        message: [
          `Are your sure? There ${openFields.length > 1 ? 'are' : 'is'} ${
            openFields.length
          } unmapped fields on the document and saving could impact any projects already using it.`,
        ],
        actions: [
          {
            text: 'Cancel',
            fn: popDialogNotification,
            variant: 'contained',
          },
          {
            text: 'Continue',
            fn: () => {
              submit();
              popDialogNotification();
            },
            color: 'primary',
            variant: 'contained',
          },
        ],
        customTheme: documentSignerTheme,
      };
      pushDialogNotification(dialogProps);
    } else {
      submit();
    }
  };

  renderRoleMappingField = field => {
    const { id: fieldId } = field;
    const {
      activeRoleId,
      activeRuleId,
      fields,
      roles,
      rules,
      highlightUnmappedFields,
      activeRuleGroupId,
      activeRuleRoleId,
      triggerRuleIdToHighlight,
      triggerSourceIdToHighlight,
      drawerValues: { id: drawerFieldId = '' } = {},
      drawerOpen,
    } = this.state;
    const fieldToRender = fields.find(field => field.id === fieldId) || {};
    const {
      fieldType = 'TXT',
      textType = '',
      isRequired = false,
      displayName = '',
    } = fieldToRender || {};
    const updatedField = {
      ...fieldToRender,
      ...field,
      fieldType,
      isRequired,
      displayName,
      textType,
      activeRoleId,
      activeRuleGroupId,
      roles,
      rules,
      highlightUnmappedFields,
      activeRuleRoleId,
      handleOpenDrawer: this.handleOpenDrawer,
      handleRoleAssignment: this.handleRoleFieldAssignment,
      handleFieldRuleGroupChange: this.handleFieldRuleGroupChange,
      systemField: field.systemField || {},
      triggerRuleIdToHighlight,
      triggerSourceIdToHighlight,
      drawerFieldId,
      drawerOpen,
      activeRuleId,
      fields,
    };
    return <FieldIconButton {...updatedField} key={fieldId} />;
  };

  discardChanges = () => {
    const { doc: { id: docId } = {} } = this.props;
    this.setState({ isDiscardingChanges: true }, () =>
      ajax
        .post(`/workflow/templates/${docId}/field_groups/discard-drafts`)
        .then(({ fields = [], rules = [] }) => {
          this.setState({ fields, rules, isDiscardingChanges: false }, () => {
            const { pushSnackbarNotification } = this.props;
            this.formatFields(this.state.fields);
            this.closeDiscardChangesDialog();
            pushSnackbarNotification({
              message: 'The document changes have been discarded successfully.',
              variant: SnackbarVariants.SUCCESS,
            });
          });
        })
        .catch(() => {
          const { pushSnackbarNotification } = this.props;
          pushSnackbarNotification({
            message:
              'There was an error while discarding changes. Please try again.',
            variant: SnackbarVariants.ERROR,
          });
          this.setState({
            isDiscardingChanges: false,
            discardChangesDialogIsOpen: false,
          });
        }),
    );
  };

  openDiscardChangesDialog = () =>
    this.setState({ discardChangesDialogIsOpen: true });

  closeDiscardChangesDialog = () =>
    this.setState({ discardChangesDialogIsOpen: false });

  scrollToElement = elId => {
    const element = document.getElementById(elId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        inline: 'end',
      });
    }
  };

  render() {
    const {
      classes,
      doc = {},
      isSaving,
      saveSuccess,
      textFieldOptions,
      getDocumentImageUrls,
      systemFieldOptions,
      responseBehaviorOptions = [],
      triggerActions = [],
      saveDraftIsLoading,
      badgeTypes,
    } = this.props;
    const {
      activeRoleId,
      fields,
      roles,
      mapModeActive,
      newFieldsModalIsOpen,
      drawerOpen,
      drawerValues,
      rules,
      activeRuleGroupId,
      deleteConfirmationModalIsOpen,
      deleteMessage,
      configureDialogIsOpen,
      ruleToConfigure,
      inputs,
      inputDialogIsOpen,
      inputToConfigure,
      highlightUnmappedFields,
      triggerRuleIdToHighlight,
      triggerSourceIdToHighlight,
      newFieldsPerPage,
      emailDialogOpen,
      activeRuleId,
      isEditingRoleName,
      discardChangesDialogIsOpen,
      isDiscardingChanges,
    } = this.state;
    const { categories: tags = [], id: docId } = doc || {};
    const {
      totalFields,
      mappedFields,
      numberOfNewFields,
      totalFieldsMappedTooltipTitle,
      dialogReplacementMessage,
    } = getDocumentDetails(fields, newFieldsPerPage);
    const dialogReplacementContent =
      numberOfNewFields === 0 ? (
        'There are no new unmapped fields.'
      ) : (
        <Typography>
          There are{' '}
          <span className={classes.newFields}>{numberOfNewFields}</span> new
          unmapped fields on {formatReplacementDialogText(newFieldsPerPage)}
        </Typography>
      );
    const groupContainerProps = {
      activeRoleId,
      activeRuleGroupId,
      activeRuleId,
      addFieldGroupToConditionalRule: this.handleAddFieldGroupToConditionalRule,
      addNewInputField: this.addNewInputField,
      addNewRole: this.handleAddNewRole,
      className: classes.sidebar,
      createConditionalRule: this.handleCreateConditionalRule,
      createTriggerRule: this.handleCreateTriggerRule,
      createNewInput: this.handleCreateNewInput,
      deleteRole: this.handleDeleteRole,
      deleteInput: this.handleDeleteInput,
      deleteRule: this.deleteRule,
      deleteGroupFromRule: this.deleteGroupFromRule,
      deleteInputField: this.handleDeleteInputField,
      docId,
      filterGroups: this.handleFilterGroups,
      inputs,
      roles,
      rules,
      setActiveRole: this.setActiveRole,
      toggleRuleMapping: this.toggleRuleMapping,
      openDeleteDialog: this.handleOpenDeleteConfirmationDialog,
      openConfigureDialog: this.handleOpenRuleConfigureDialog,
      openInputDialog: this.handleOpenInputDialog,
      updateRoleName: this.updateRoleName,
      updateInput: this.handleUpdateInput,
      highlightTriggerSource: this.highlightTriggerSource,
      toggleEmailMapping: this.toggleRuleMapping,
      createNewEmailNotification: this.createNewEmailNotification,
      createNewWorkflowRule: this.createNewWorkflowRule,
      updateRuleName: this.updateRuleName,
      openEmailDialog: this.openEmailDialog,
      toggleEditRoleName: this.toggleEditRoleName,
      isEditingRoleName,
      triggerSourceIdToHighlight,
    };
    const fieldDrawerProps = {
      drawerValues,
      tags,
      systemFieldOptions,
      saveField: this.handleSaveField,
      updateRules: this.updateRules,
      textFieldOptions: textFieldOptions.filter(filterTextFieldOptions),
      handleUpdate: this.handleDropdownOptionUpdate,
      badgeTypes,
      drawerOpen,
      handleCloseDrawer: this.handleCloseDrawer,
      triggerActions,
      responseBehaviorOptions,
      fields,
      rules,
      roles,
      triggerRuleIdToHighlight,
      toggleTriggerRuleHighlight: this.toggleTriggerRuleHighlight,
      onClose: this.handleCloseDrawer,
    };
    const sideBarProps = {
      open: drawerOpen,
    };
    const toolbarActionButtonsProps = {
      totalFieldsMappedTooltipTitle,
      saveSuccess,
      mappedFields,
      totalFields,
      isSaving,
      mapModeActive,
      toggleHighlightUnmappedFields: this.toggleHighlightUnmappedFields,
      saveMappings: this.handleSaveMappings,
      openDiscardChangesDialog: this.openDiscardChangesDialog,
      highlightUnmappedFields,
    };
    /*
      The DocumentViewer is responsible for rendering the sideBar prop
      and actionButtons inside of the actionBarProps, which in this
      case are the GroupContainer and ToolbarActionButtons respectively.
    */
    const documentViewerProps = {
      documentMappingMode: true,
      getDocumentImageUrls,
      activeRoleId,
      activeRuleGroupId,
      fieldDrawerProps,
      currentDocument: doc,
      withSidebar: true,
      throwFatalError: () => {},
      renderField: this.renderRoleMappingField,
      className: classes.document,
      sideBar: (
        <GroupContainer
          {...groupContainerProps}
          fieldDrawerProps={fieldDrawerProps}
          sideBarProps={sideBarProps}
        />
      ),
      actionBarProps: {
        className: classes.actionBar,
        documentTitle: doc.name,
        actionButtons: <ToolbarActionButtons {...toolbarActionButtonsProps} />,
      },
    };

    return (
      <ThemeProvider theme={documentSignerTheme}>
        <React.Fragment>
          <div className={classes.root}>
            <DocumentViewer {...documentViewerProps} />
          </div>
          <DeleteConfirmationDialog
            isOpen={deleteConfirmationModalIsOpen}
            onClose={this.handleCloseDeleteConfirmationDialog}
            deleteMessage={deleteMessage}
            handleDeleteConfirmation={this.handleDeleteConfirmation}
          />
          <RuleConfigurationDialog
            isOpen={configureDialogIsOpen}
            rule={ruleToConfigure}
            onClose={this.handleCloseRuleConfigureDialog}
            saveRule={this.handleSaveRuleConfig}
            rules={rules}
            roles={roles}
            fields={fields}
            responseBehaviorOptions={responseBehaviorOptions}
            isLoading={saveDraftIsLoading}
            systemFieldOptions={systemFieldOptions}
          />
          <CustomInputDialog
            isOpen={inputDialogIsOpen}
            input={inputToConfigure}
            onClose={this.handleCloseInputDialog}
            saveInput={this.handleSaveInput}
            inputs={inputs}
          />
          <EmailConfigurationDialog
            isOpen={!!emailDialogOpen}
            onClose={this.closeEmailDialog}
            save={this.handleSaveRuleConfig}
            email={rules.find(email => email.id === emailDialogOpen) || {}}
            emails={rules.filter(({ ruleType }) => ruleType === 'email')}
          />
          <Dialog
            onClose={this.handleCloseNewFieldModal}
            open={newFieldsModalIsOpen}
          >
            <DialogTitle>{dialogReplacementMessage}</DialogTitle>
            <DialogContent>{dialogReplacementContent}</DialogContent>
          </Dialog>
          <DiscardChangesDialog
            isOpen={discardChangesDialogIsOpen}
            onClose={this.closeDiscardChangesDialog}
            discardChanges={this.discardChanges}
            isDiscardingChanges={isDiscardingChanges}
          />
        </React.Fragment>
      </ThemeProvider>
    );
  }
}

export default compose(
  withDialogNotification,
  withSnackbarNotification,
  withStyles(styles),
)(RoleMapper);
