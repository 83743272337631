import React from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Email, Phone } from '@mui/icons-material';

import { CUSTOMER_SERVICE } from 'common/constants/config/config';

const { PHONE: phone, EMAIL: email } = CUSTOMER_SERVICE;

const styles = {
  root: {
    margin: 2,
  },
};

const ContactCustomerService = ({ classes }) => (
  <Grid container spacing="2" className={classes.root}>
    <Grid item xs={1}>
      <Email />
    </Grid>
    <Grid item xs={11}>
      {email}
    </Grid>
    <Grid item xs={1}>
      <Phone />
    </Grid>
    <Grid item xs={11}>
      {phone}
    </Grid>
  </Grid>
);

export default withStyles(styles)(ContactCustomerService);
