import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'redux';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  Visibility as HideIcon,
  VisibilityOff as ShowIcon,
  Error as ErrorIcon,
} from '@mui/icons-material';

import withRouteHelpers from 'common/hoc/withRouteHelpers';

import ssnDecrypt from './ssnDecrypt';
import useSSN from './hooks/useSSN';

const DEFAULT_HIDE_TIMEOUT = 5; // seconds

const styles = {
  root: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 4,
    justifyContent: 'center',

    '& >*': {
      display: 'flex',
    },
  },
  eyeIcon: {
    fontSize: 16,
  },
  eyeIconDisabled: {
    opacity: 0.5,
  },
  loader: {
    color: '#0000FF',
    padding: 3,
  },
  number: {
    padding: '0 6px',
  },
  countdown: {
    width: 24,
    fontWeight: 800,
    color: '#0000FF',
  },
  error: {
    display: 'flex',
    color: 'red',
  },
  errorTooltip: {
    tooltip: {
      backgroundColor: 'rgba(255, 0, 0, 0.5)',
      color: 'white',
    },
  },
};

const RedTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
}))(Tooltip);

const SSNNumber = props => {
  const {
    projectId,
    profileId,
    classes,
    hideAfter = DEFAULT_HIDE_TIMEOUT,
  } = props;

  const [show, setShow] = useState(false);
  const [hideCount, setHideCount] = useState(null);
  const [number, setNumber] = useState(null);

  const iteration = show ? (number ? 2 : 1) : null;
  const { data, error, loading } = useSSN(projectId, profileId, iteration);
  const { number: ssnEncrypted, canView, key = null } = data || {};

  let countTimeout = useRef(null);

  const toggleShow = () => {
    if (show && countTimeout) clearTimeout(countTimeout.current);
    if (!show) setHideCount(parseInt(hideAfter) || DEFAULT_HIDE_TIMEOUT);
    setShow(!show);
  };

  const disabled = loading || error;
  const iconClass = disabled ? classes.eyeIconDisabled : classes.eyeIcon;
  const isCountdown = !loading && !error && show;

  useEffect(() => {
    if (isCountdown) {
      if (hideCount > 0) {
        countTimeout.current = setTimeout(
          () => setHideCount(hideCount - 1),
          1000,
        );
      } else {
        setShow(false);
      }
    }
  }, [isCountdown, setShow, setHideCount, hideCount]);

  useEffect(() => {
    if (!show) setHideCount(null);
  }, [show]);

  useEffect(() => {
    if (ssnEncrypted) {
      setNumber(ssnEncrypted);
    }
  }, [ssnEncrypted, setNumber]);

  return (
    <Box className={classes.root}>
      {loading && <CircularProgress size={22} className={classes.loader} />}
      {!loading && error && (
        <RedTooltip title={error.message} leaveDelay={3} interactive>
          <Box className={classes.error}>
            <ErrorIcon size={16} />
          </Box>
        </RedTooltip>
      )}
      {!loading && !error && show && (
        <Box className={classes.number}>{ssnDecrypt(number, key)}</Box>
      )}
      {!loading && !error && canView === true && (
        <>
          <IconButton
            onClick={toggleShow}
            size="small"
            disabled={disabled}
            className={classes.eyeIcon}
          >
            {show && <ShowIcon className={iconClass} />}
            {!show && <HideIcon className={iconClass} />}
          </IconButton>
          <Box sx={styles.countdown}>
            {hideCount > 0 && isCountdown && hideCount}
          </Box>
        </>
      )}
    </Box>
  );
};

export default compose(withRouteHelpers, withStyles(styles))(SSNNumber);
