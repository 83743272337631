import React from 'react';
import Select from 'react-select';
import Typography from '@mui/material/Typography';
import formatOptionLabel from './formatOptionLabel';

const CurrencySelect = props => {
  const {
    classes = {},
    currencies,
    disabled,
    error = '',
    formData: { currency } = {},
    onChange,
    selectStyles,
  } = props;

  const change = ({ value }) =>
    onChange({
      currency: value,
      union: null,
      occupation: null,
      workSchedule: null,
    });

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1">
        Currency
      </Typography>
      <div data-test-id="CurrencySelect-select">
        <Select
          className={classes.select}
          formatOptionLabel={formatOptionLabel('CurrencySelect')}
          isDisabled={disabled}
          styles={selectStyles}
          onChange={change}
          options={currencies.map(c => ({ value: c, label: c }))}
          value={currency ? { value: currency, label: currency } : null}
        />
      </div>
      {error && (
        <Typography color="error" variant="caption" className={classes.error}>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default CurrencySelect;
