// Utilties
import oktaAuth from 'common/utilities/oktaAuth';
import oktaAuthMyCnC from 'common/utilities/oktaAuthMyCnC';

// Constants
import getSsoUrl from 'common/constants/config/ssoUrl';
import getLogoutUrl from 'common/constants/config/logoutUrl';
import DOMPurify from 'dompurify';

export default class Auth {
  oktaAuthClient = oktaAuth();
  oktaAuthClientCnC = oktaAuthMyCnC();

  checkSession = () => this.oktaAuthClient.session.exists();

  oktaLogout = () => this.oktaAuthClient.signOut();
  oktaLogoutMyCnC = () => this.oktaAuthClientCnC.signOut();

  cncLogout = actionPayload => {
    const { withFromURI = true, fromURI: fromURIParam = 'origin' } =
      actionPayload || {};

    const pathParam = () => {
      if (!withFromURI === '') return '';
      const { [fromURIParam]: fromURI } = window.location;
      return `?fromURI=${getSsoUrl()}/login?fromURI=${fromURI}`;
    };
    DOMPurify.sanitize(window.location);
    window.location = `${getLogoutUrl()}/logout${pathParam()}`;
  };

  redirectToSSO = () => (window.location = getSsoUrl());
}
