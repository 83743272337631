import React from 'react';
import EmployeeInfo from 'mobile/components/Projects/EmployeeInfo';
import DocumentList from 'mobile/components/Projects/DocumentList';
import Allowances from 'mobile/components/Projects/Allowances';
import { ALLOWANCES } from 'mobile/constants';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { makeStyles } from '@mui/styles';

const offerSlidesStyles = makeStyles(theme => ({
  slider: {
    marginBottom: '30px',
  },
  dots: {
    '& li button:before': {
      fontSize: '12px',
    },
  },
}));

const OfferSlides = ({ offer, countryCode }) => {
  const classes = offerSlidesStyles();
  const { allowances: terms = [] } = offer || [];

  const settings = {
    dots: true,
    dotsClass: `slick-dots  ${classes.dots}`,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: `ondemand`,
    arrows: false,
  };

  const showSecondAllowanceCard = () => {
    const { perDiemAllowance, housingAllowance } = terms;
    return perDiemAllowance?.amount || housingAllowance?.amount;
  };

  return (
    <div className={classes.slider}>
      <Slider {...settings}>
        <div>
          <EmployeeInfo offer={offer} countryCode={countryCode} />
        </div>
        <div>
          <Allowances terms={terms} allowances={ALLOWANCES.slice(0, 4)} />
        </div>
        {showSecondAllowanceCard() && (
          <div>
            <Allowances terms={terms} allowances={ALLOWANCES.slice(4, 6)} />
          </div>
        )}
        <div>
          <DocumentList offer={offer} />
        </div>
      </Slider>
    </div>
  );
};

export default OfferSlides;
