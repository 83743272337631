import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query guildTerms {
    guildTerms {
      unionCode
      scheduleCode
      scheduleDescription
      occupationCode
      occupationDescription
      guaranteedHoursFrequency
      guaranteedHours
      days
      per
      workerType
    }
    guildTermsUpdatedBy {
      fullName
      timestamp
    }
  }
`;

const useGuildTerms = () => {
  const { error, loading, data, refetch } = useQuery(QUERY, {
    fetchPolicy: 'no-cache',
  });
  return {
    error,
    loading,
    data: data?.guildTerms,
    updatedBy: data?.guildTermsUpdatedBy,
    refetch,
  };
};

export default useGuildTerms;
