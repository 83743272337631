import React from 'react';
import T from 'common/oldJavascripts/utils/i18n';
import { withRouter } from 'react-router';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import Layout from 'common/oldJavascripts/components/Base/Layout';
import LayoutHeader from 'common/oldJavascripts/components/Base/Layout/LayoutHeader';
import MainHeader from 'common/oldJavascripts/components/Base/MainHeader';
import Link from 'common/oldJavascripts/components/Base/Link';
import MainNav from 'common/oldJavascripts/components/Pages/Layouts/Nav/MainNav';
import DropdownSession from 'common/oldJavascripts/components/Pages/Layouts/Nav/DropdownSession';
import HeaderOfferOnboarding from './Nav/HeaderOfferOnboarding';
import BaseLayout from 'common/oldJavascripts/components/Pages/Layouts/Base';
import HelpIcon from '@mui/icons-material/Help';
import Typography from '@mui/material/Typography';

// Images
import STARTPLUS_LOGO from 'common/images/start-white-logo-01.svg';

const styles = () => ({
  root: {},
  secondaryNavGroup: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridTemplateRows: '100%',
    float: 'right',
    placeItems: 'center',
    gridGap: '15px',
    gap: '15px',
  },
  helpLink: {
    height: '25px',
  },
  help: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '20px 50px',
    gridTemplateRows: '100%',
    alignItems: 'center',
    gridGap: '5px',
    gap: '5px',
  },
  helpIcon: {
    width: '14px',
    height: 'auto',
    justifySelf: 'center',
    color: '#ddf2f3',
  },
  helpText: {
    justifySelf: 'start',
    color: '#ddf2f3',
    fontSize: '12px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
});

const OfferLayout = props => {
  const {
    match: { params = {} } = {},
    hasSidebar,
    compactNoLanding,
    children,
    isOnboarding,
    classes = {},
  } = props;
  const { offerId } = params;
  const flags = useFeatureFlags();
  const globalNavFlag =
    flags.includes('GlobalNavbar') ||
    process.env.REACT_APP_ENABLE_GLOBAL_NAV_BY_DEFAULT;

  const helpLinkContent = () => (
    <Link
      icon={true}
      to={`/onboarding/offers/${offerId}/help`}
      className={classes.helpLink}
    >
      <div className={classes.help}>
        <HelpIcon className={classes.helpIcon} />
        <Typography className={classes.helpText}>{T('shared.help')}</Typography>
      </div>
    </Link>
  );

  if (globalNavFlag) {
    const subNav = isOnboarding ? <HeaderOfferOnboarding /> : null;
    return (
      <BaseLayout
        hasSidebar={hasSidebar}
        secondaryNav={isOnboarding}
        header={subNav}
        employee={true}
        offerId={offerId}
      >
        {children}
      </BaseLayout>
    );
  }

  return (
    <Layout hasSidebar={hasSidebar} compactNoLanding={compactNoLanding}>
      <LayoutHeader>
        <MainHeader>
          <MainHeader.Logo src={STARTPLUS_LOGO} alt={T('images.logo')} />
          <MainNav />
          <div className={classes.secondaryNavGroup}>
            {offerId && helpLinkContent()}
            <DropdownSession />
          </div>
        </MainHeader>
      </LayoutHeader>
      {isOnboarding && <HeaderOfferOnboarding />}
      {children}
    </Layout>
  );
};

export default compose(withStyles(styles), withRouter)(OfferLayout);
