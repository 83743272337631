import React, { Component } from 'react';

import { withStyles, ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { CircularProgress } from '@mui/material';
import { Input, InputLabel } from '@mui/material';
import { FormControl } from '@mui/material';
import { Done as ConfirmIcon } from '@mui/icons-material';
import isEqual from 'lodash.isequal';
import DOMPurify from 'dompurify';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0000FF',
    },
  },
});

const styles = theme => ({
  root: {
    width: 130,
    marginRight: 50,
  },
  text: {
    paddingTop: 12,
  },
  input: { width: 100 },
  submit: { verticalAlign: 'bottom' },
  circularProgress: {
    marginLeft: theme.spacing(1),
  },
});

class DocumentRank extends Component {
  state = {
    rank: DOMPurify.sanitize(this.props.rank),
  };

  componentWillReceiveProps(newProps) {
    if (this.props.editMode || isEqual(this.props, newProps)) return;
    this.setState({ rank: newProps.rank });
  }

  handleChange = (name, value) => {
    let updatedValue = value;
    if (name === 'rank') {
      if (value === '0') {
        updatedValue = 1;
      }
      if (value === '') {
        updatedValue = '';
      }
    }
    this.setState({
      [name]: updatedValue,
    });
  };

  handleKeyDown = event => {
    const { value } = event.target;
    if (
      value === '' &&
      (event.key === 'e' || event.key === '-' || event.key === '0')
    ) {
      event.stopPropagation();
      event.preventDefault();
      this.handleChange('rank', value);
    }
  };

  handleKeyPress = ({ key }) => {
    const { rank } = this.state;
    if (key === 'Enter' && rank !== '') {
      this.handleSubmit();
    }
  };

  handleSubmit = (event = {}) => {
    if (event.stopPropagation) event.stopPropagation();
    if (event.preventDefault) event.preventDefault();
    const { rank } = this.state;
    const { handleSubmit: upstreamHandleSubmit } = this.props;
    upstreamHandleSubmit(rank);
  };

  render() {
    const { editMode, onRankClick, classes, id, isUpdatingRank } = this.props;
    const { rank } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <div
          className={classes.root}
          onClick={onRankClick}
          data-test-id={`DocumentRank-root-${id}`}
        >
          {!editMode && (
            <Typography className={classes.text}>Rank {rank}</Typography>
          )}
          {editMode && (
            <div>
              <FormControl>
                <InputLabel htmlFor="name-simple">Rank</InputLabel>
                <Input
                  className={classes.input}
                  value={rank}
                  type="number"
                  onChange={e => this.handleChange('rank', e.target.value)}
                  onKeyDown={e => this.handleKeyDown(e)}
                  onKeyPress={e => this.handleKeyPress(e)}
                />
              </FormControl>
              <IconButton
                disabled={
                  Boolean(rank === '') || `${isUpdatingRank}` === `${id}`
                }
                onClick={this.handleSubmit}
                size="small"
                className={classes.submit}
              >
                <ConfirmIcon />
              </IconButton>
            </div>
          )}
          {`${isUpdatingRank}` === `${id}` && (
            <CircularProgress size={24} className={classes.circularProgress} />
          )}
        </div>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(DocumentRank);
