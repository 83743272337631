import React from 'react';
import domClasses from '../../utils/dom/classes.js';
var Data = null;

Data = class extends React.Component {
  render() {
    return <div className="data">{this.props.children}</div>;
  }
};

Data.Header = class extends React.Component {
  render() {
    return <div className="data-header">{this.props.children}</div>;
  }
};

Data.Item = class extends React.Component {
  render() {
    return <div className="data-item">{this.props.children}</div>;
  }
};

Data.Label = class extends React.Component {
  render() {
    return <div className="data-label">{this.props.children}</div>;
  }
};

Data.Value = class extends React.Component {
  render() {
    var classes = null;

    classes = domClasses.set({
      'data-value': true,
      'data-value--right': this.props.right,
    });

    return <div className={classes}>{this.props.children}</div>;
  }
};

export default Data;
