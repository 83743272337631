import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import Box from 'common/oldJavascripts/components/Base/Box';
import Button from 'common/oldJavascripts/components/Base/Button';
import Field from 'common/oldJavascripts/components/Base/Field';
import Header from 'common/oldJavascripts/components/Base/Header';
import T from 'common/oldJavascripts/utils/i18n';
import Link from 'common/oldJavascripts/components/Base/Link';
import Modal from 'common/oldJavascripts/components/Base/Modal';
import Text from 'common/oldJavascripts/components/Base/Text';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

import BoxParagraphItem from 'common/oldJavascripts/components/Base/Box/BoxParagraphItem';

export default Relay.createClass({
  getInitialState: function() {
    return { disableSubmit: true };
  },

  statics: {
    mutators: {
      offer: {
        info: function(params) {
          return {
            id: '/v2/offers/' + params.offerId + '/archive',
            create: true,
          };
        },
      },
    },
  },

  render: function() {
    return (
      <Modal minimal={true}>
        <Box minimal={true} noShadow={true}>
          <BoxItem>
            <Header>
              <Header.Title compact={true} center={true}>
                {T('employee.offers.archive.modal.title')}
              </Header.Title>
            </Header>
          </BoxItem>
          <BoxItem minimal={true}>
            <Text paragraph={true}>
              {T('employee.offers.archive.modal.note')}
            </Text>
          </BoxItem>
          <BoxItem>
            <Field
              type="textarea"
              numRows={6}
              label={T('employee.offers.modal.note_label')}
              onChange={this._onNoteChange}
            />
          </BoxItem>
          <BoxParagraphItem align="center" minimal={true}>
            <Link onClick={this._handleClose}>
              {T('employee.offers.modal.close')}
            </Link>
            <Button
              align="right"
              marginLeft={true}
              disabled={this.state.disableSubmit}
              onClick={this._modalSave}
            >
              {T('employee.offers.archive.modal.submit')}
            </Button>
          </BoxParagraphItem>
        </Box>
      </Modal>
    );
  },

  _onNoteChange: function(value) {
    var offer = this.props.offer;

    offer.update('comments')(value);

    this.setState({
      disableSubmit: value.length === 0,
    });
  },

  _modalSave: function() {
    var offer = this.props.offer;

    if (this.state.disableSubmit) {
      return false;
    }

    offer.save('', {}, () => {
      this.props.onClose({ refresh: true });
    });
  },

  _handleClose: function() {
    this.props.onClose();
  },
});
