import React from 'react';

// MaterialUI Components
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';

// Utilities
import colorFromInt from 'common/utilities/colorFromInt';

const RadioItem = props => {
  const { index, name } = props;
  return (
    <Chip
      avatar={
        <Avatar style={{ background: colorFromInt(index) }}>{index + 1}</Avatar>
      }
      label={name}
    />
  );
};

export default RadioItem;
