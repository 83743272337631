import * as FieldTypes from 'common/utilities/constants/fieldTypes';

export const newCustomInput = (newId, index) => ({
  id: newId,
  displayName: `New Custom Input - ${index + 1}`,
  description: '',
  isRequired: false,
  inputFields: [],
  active: true,
});

export const newInputField = newId => ({
  name: newId,
  displayName: 'Default Label',
  fieldType: FieldTypes.TXT,
  properties: {
    options: {},
  },
  active: true,
});
