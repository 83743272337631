import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';

import Button from 'common/oldJavascripts/components/Base/Button';
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';

import useExportCew from './hooks/useExportCrew';

const NOTIFICATION = {
  loading: {
    message: 'Requesting the crew list for download.',
    variant: SnackbarVariants.INFO,
  },
  success: {
    message:
      'Your file is being created. You\'ll receive an email when it\'s done.',
    variant: SnackbarVariants.SUCCESS,
  },
  error: {
    message:
      'There was an issue requesting the crew list download. ' +
      'Please try again or contact customer support ' +
      'if the issue persists.',
    variant: SnackbarVariants.ERROR,
  },
};

const styles = {
  root: {
    margin: 5,
  },
};

const ExportCrewButton = props => {
  const { projectId, pushNotification, popNotification, classes } = props;
  const [exportCrew, { loading }] = useExportCew();

  const handleExportCrew = () => {
    pushNotification(NOTIFICATION.loading);
    exportCrew({ variables: { projectId: parseInt(projectId, 10) } })
      .then(() => {
        popNotification();
        pushNotification(NOTIFICATION.success);
      })
      .catch(() => {
        popNotification();
        pushNotification(NOTIFICATION.error);
      });
  };

  return (
    <Button
      className={classes.root}
      onClick={handleExportCrew}
      disabled={loading}
    >
      Export Crew List
    </Button>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = { pushNotification, popNotification };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ExportCrewButton);
