import React, { useState } from 'react';
import { Table, TableCell, TableRow } from '@mui/material';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MaskedInput from 'react-text-mask';
import {
  ratePerHourMask,
  guaranteedHoursMask,
} from 'studio/components/OfferPage/helpers/textFieldNumberMasks';
import formatCurrency from 'studio/components/OfferPage/helpers/formatCurrency';
import OVERTIME_MULTIPLIER from '../OVERTIME_MULTIPLIER';
import WarningIcon from '@mui/icons-material/Warning';
import Typography from '@mui/material/Typography';
import isNonUnionWorkScheduleA from 'common/utilities/isNonUnionWorkScheduleA';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 40,
    marginBottom: 40,
  },
  terms: {
    marginTop: '40px',
  },
  textFieldInputContainerWarning: {
    borderBottom: `2px solid #FFBF00`,
    '&:before': {
      border: 'none !important',
    },
    '&:after': {
      border: 'none !important',
    },
  },
  cellTitle: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectItemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  dollar: {
    width: '10%',
    fontSize: 14,
    marginTop: 7,
    color: '#696969',
    paddingLeft: 14,
    fontFamily: 'Roboto, Helvetica, Helvetica Neue, san-serif',
  },
  hoursPerDayCell: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    borderTop: 'none',
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 8,
  },
  tableSpacing: {
    marginTop: 16,
    marginBottom: 20,
    border: '1px solid #0000FF',
  },
  topSpacing: {
    marginTop: 20,
  },
  hoursCopy: {
    marginTop: 7,
    paddingRight: 12,
    color: '#525252',
    fontSize: 16,
    fontWeight: 'normal',
  },
  sixthDayCell: {
    borderTop: 'none',
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  radioGroupCell: {
    borderTop: 'none',
    backgroundColor: '#FFFFFF',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  warningIcon: {
    color: '#FFA800',
    position: 'relative',
    top: 4,
    right: 8,
  },
  warningLabel: {
    color: '#FFA800',
    fontSize: 14,
    textAlign: 'center',
  },
  errorIcon: {
    color: 'red',
    position: 'relative',
    top: 4,
    right: 8,
  },
  errorLabel: {
    color: 'red',
    fontSize: 14,
    textAlign: 'center',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hoursPerDay: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
  },
  radioBtns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginLeft: 30,
  },
  radio: {
    '&$checked': {
      color: '#0000FF',
    },
  },
  checked: {
    color: '#0000FF',
    '&, & + $label': {
      color: '#0000FF !important',
      fontSize: 16,
    },
  },
  label: {
    color: '#262626',
    fontSize: 16,
  },
  rightCellArea: {
    width: '41%',
    backgroundColor: '#FFFFFF',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  fieldArea: {
    width: '43%',
    backgroundColor: '#FFFFFF!important',
  },
  input: {
    '& input': {
      textAlign: 'center',
    },
  },
}));

const HourlyRateTable = (props = {}) => {
  const {
    onChange: upstreamOnChange,
    formData = {},
    formErrors = {},
    isReadOnly = false,
    workScheduleCode,
  } = props;

  const classes = useStyles();
  const isScheduleA = isNonUnionWorkScheduleA(workScheduleCode);
  const scheduleHourlyLimit = isScheduleA ? 16 : 80;

  const {
    rateOvertime,
    rate,
    guaranteedHours,
    guaranteedHours6thDay,
    guaranteedHours7thDay,
  } = formData;

  const initialFormattedHourlyRate = rate ? formatCurrency(rate) : null;
  const initialFormattedOvertimeRate = rateOvertime
    ? formatCurrency(rateOvertime)
    : null;

  const [isHoursPerDay, setHoursPerDay] = useState(guaranteedHours);
  const [typedGuaranteedHours, setGuaranteedHours] = useState(guaranteedHours);
  const [hourlyRate, setHourlyRate] = useState(initialFormattedHourlyRate);
  const [isRatePerHourError, setRateError] = useState(false);
  const [isHoursPerDayWarning, setHoursPerDayWarning] = useState(false);
  const [overtimeRate, setOvertimeRate] = useState(
    initialFormattedOvertimeRate,
  );

  const getTypedValue = (ev = {}) => {
    const { target: { value: rawValue } = {} } = ev;
    let value = rawValue;
    if (rawValue === '' || rawValue === '0') value = '';
    return parseFloat(value);
  };

  const onChange = (ev = {}) => {
    const typedValue = getTypedValue(ev);
    setHourlyRate(formatCurrency(typedValue));
    setOvertimeRate(formatCurrency(typedValue * OVERTIME_MULTIPLIER));
  };

  const onChangeGuaranteedHours = ev => {
    const typedValue = getTypedValue(ev);
    setGuaranteedHours(typedValue);
    if (typedValue > 0) {
      setHoursPerDay(true);
      setHoursPerDayWarning(typedValue > scheduleHourlyLimit);
    } else if (isNaN(typedValue)) {
      setHoursPerDay(false);
      upstreamOnChange({
        guaranteedHours6thDay: false,
        guaranteedHours7thDay: false,
      });
    }
  };

  const onBlurGuaranteedHours = () => {
    upstreamOnChange({
      guaranteedHours: typedGuaranteedHours,
    });
  };

  const onBlur = () => {
    if (hourlyRate > 0) {
      setRateError(hourlyRate < 5.5);
      upstreamOnChange({
        rate: hourlyRate,
        rateOvertime: overtimeRate,
        isTermsOfEmploymentV2: true,
        guaranteedHoursFrequency: isScheduleA ? 'daily' : 'weekly',
      });
    }
  };

  const onChangeOvertime = ev => {
    const typedValue = getTypedValue(ev);
    setOvertimeRate(formatCurrency(typedValue));
  };

  const onBlurOvertime = ev => {
    const typedValue = getTypedValue(ev);
    upstreamOnChange({
      rateOvertime: formatCurrency(typedValue),
      isTermsOfEmploymentV2: true,
    });
  };

  const handleDayChange = (ev = {}, radioKey) => {
    const { target: { value: rawValue } = {} } = ev;
    upstreamOnChange({
      [radioKey]: rawValue === 'yes',
    });
  };

  const showApplyHoursPerDay = isHoursPerDay && isScheduleA;
  const hourlyTitle = isScheduleA ? 'Hours per Day' : 'Hours per Week';

  const CustomRadio = (
    <Radio
      classes={{
        root: classes.radio,
        checked: classes.checked,
      }}
    />
  );

  return (
    <>
      <Table>
        <TableRow>
          <TableCell className={classes.cellTitle}>
            <div className={classes.errorContainer}>
              <Typography className={classes.hoursPerDay}>
                Hourly Rate
              </Typography>
              {(isRatePerHourError || formErrors.rate) && (
                <Typography
                  color="warning"
                  variant="caption"
                  className={classes.errorLabel}
                >
                  <WarningIcon className={classes.errorIcon} />
                  Rate is below minimum wage
                </Typography>
              )}
            </div>
          </TableCell>
          <TableCell className={classes.rightCellArea}>
            <div className={classes.itemContainer}>
              <Typography className={classes.dollar}>$</Typography>
              <MaskedInput
                mask={ratePerHourMask}
                value={hourlyRate || ''}
                onChange={onChange}
                onBlur={onBlur}
                className={classes.input}
                render={(ref, props) => (
                  <TextField
                    inputRef={ref}
                    {...props}
                    variant="standard"
                    error={isRatePerHourError}
                    disabled={isReadOnly}
                    InputProps={{
                      inputProps: {
                        'data-test-id': 'RatePerHour-input',
                      },
                    }}
                  />
                )}
              />
              <Typography className={classes.hoursCopy}>/hour</Typography>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.cellTitle}>Overtime Rate</TableCell>
          <TableCell className={classes.rightCellArea}>
            <div className={classes.itemContainer}>
              <Typography className={classes.dollar}>$</Typography>
              <MaskedInput
                mask={ratePerHourMask}
                value={overtimeRate || ''}
                onChange={onChangeOvertime}
                onBlur={onBlurOvertime}
                className={classes.input}
                render={(ref, props) => (
                  <TextField
                    inputRef={ref}
                    {...props}
                    variant="standard"
                    disabled
                    InputProps={{
                      inputProps: {
                        'data-test-id': 'OvertimeRatePerHour-input',
                      },
                    }}
                  />
                )}
              />
              <Typography className={classes.hoursCopy}>/hour</Typography>
            </div>
          </TableCell>
        </TableRow>
      </Table>
      <Table className={classes.topSpacing}>
        <TableRow>
          <TableCell className={classes.cellTitle} colSpan={2}>
            <div className={classes.errorContainer}>
              <Typography
                className={classes.hoursPerDay}
                data-test-id="HourlyRateTable-RatePerHour-title"
              >
                {hourlyTitle}
              </Typography>
              {isHoursPerDayWarning && (
                <Typography
                  color="warning"
                  variant="caption"
                  className={classes.warningLabel}
                  data-test-id="HourlyRateTable-RatePerHour-warning"
                >
                  <WarningIcon className={classes.warningIcon} /> You entered
                  more than {scheduleHourlyLimit} hours
                </Typography>
              )}
            </div>
          </TableCell>
          <TableCell className={classes.rightCellArea}>
            <div className={classes.itemContainer}>
              <div className={classes.dollar} />
              <MaskedInput
                mask={guaranteedHoursMask}
                value={typedGuaranteedHours || ''}
                onChange={onChangeGuaranteedHours}
                onBlur={onBlurGuaranteedHours}
                className={classes.input}
                render={(ref, props) => (
                  <TextField
                    inputRef={ref}
                    {...props}
                    variant="standard"
                    disabled={isReadOnly}
                    InputProps={{
                      className: isHoursPerDayWarning
                        ? classes.textFieldInputContainerWarning
                        : '',
                      inputProps: {
                        'data-test-id': 'NonUnion-guaranteedHours',
                      },
                    }}
                  />
                )}
              />
              <Typography className={classes.hoursCopy}>hours</Typography>
            </div>
          </TableCell>
        </TableRow>
        {showApplyHoursPerDay && (
          <>
            <TableRow>
              <TableCell className={classes.hoursPerDayCell} rowSpan={2}>
                Apply Hours per Day to
              </TableCell>
              <TableCell className={classes.sixthDayCell} colSpan={1}>
                6th Day
              </TableCell>
              <TableCell className={classes.radioGroupCell}>
                <RadioGroup
                  disabled={isReadOnly}
                  value={guaranteedHours6thDay ? 'yes' : 'no'}
                  onChange={ev => handleDayChange(ev, 'guaranteedHours6thDay')}
                  className={classes.radioBtns}
                  row
                >
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="no"
                    control={CustomRadio}
                    label="No"
                    data-test-id="NonUnion-guaranteedHours6thDay-radio-no"
                  />
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="yes"
                    control={CustomRadio}
                    label="Yes"
                    data-test-id="NonUnion-guaranteedHours6thDay-radio-yes"
                  />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle} colSpan={1}>
                7th Day
              </TableCell>
              <TableCell className={classes.radioGroupCell}>
                <RadioGroup
                  disabled={isReadOnly}
                  value={guaranteedHours7thDay ? 'yes' : 'no'}
                  onChange={ev => handleDayChange(ev, 'guaranteedHours7thDay')}
                  className={classes.radioBtns}
                  row
                >
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="no"
                    control={CustomRadio}
                    label="No"
                    data-test-id="NonUnion-guaranteedHours7thDay-radio-no"
                  />
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="yes"
                    control={CustomRadio}
                    label="Yes"
                    data-test-id="NonUnion-guaranteedHours7thDay-radio-yes"
                  />
                </RadioGroup>
              </TableCell>
            </TableRow>
          </>
        )}
      </Table>
    </>
  );
};

export default HourlyRateTable;
