import getClientId from './clientId';

const isProd = () => {
  const env = process.env.REACT_APP_ENVIRONMENT;
  return env === 'prod' || env === 'prod-2';
};

export const getClientIdMyCnC = () =>
  isProd() ? '0oa1b383d8XvfKYp0356' : '0oail3tmV0Phe7Ktr355';

export const getBaseUrl = () =>
  isProd()
    ? 'https://login.castandcrew.com'
    : 'https://login.dev.aws.castandcrew.com';

export const getIssuer = () =>
  isProd()
    ? 'https://login.castandcrew.com/oauth2/ausetjg5xy8y30npD356' // Prod
    : 'https://login.dev.aws.castandcrew.com/oauth2/aus6v5xnfcC2x2Nj1356'; // Lower Envs

export const getIssuerMyCnC = () =>
  isProd()
    ? 'https://login.castandcrew.com/oauth2/aus77kwrwSbSunX5s356'
    : 'https://login.dev.aws.castandcrew.com/oauth2/aus67ubcqBSG33G9h356';

export const getRedirectUri = () => `${window.location.origin}`;
export const scope =
  'openid profile email application_access terms_and_conditions';

export default {
  oidc: {
    url: getBaseUrl(),
    clientId: getClientId(),
    baseUrl: getBaseUrl(),
    issuer: getIssuer(),
    redirectUri: getRedirectUri(),
    scope,
  },
  oidcMyCnC: {
    url: getBaseUrl(),
    clientId: getClientIdMyCnC(),
    baseUrl: getBaseUrl(),
    issuer: getIssuerMyCnC(),
    redirectUri: getRedirectUri(),
    scope,
  },
};
