import { REQUEST, SUCCESS, FAILURE } from './actionTypes';

export const ACCESS_TOKEN = 'ACCESS_TOKEN';

export const requestAccessToken = () => ({
  type: REQUEST(ACCESS_TOKEN),
});

export const accessTokenSuccess = (accessToken, expiresAt) => ({
  type: SUCCESS(ACCESS_TOKEN),
  accessToken,
  expiresAt,
});

export const accessTokenFailure = () => ({
  type: FAILURE(ACCESS_TOKEN),
});
