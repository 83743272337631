import React from 'react';
import TOOLTIP_ICON from 'common/images/tooltip.svg';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createOfferCurrency as currency } from 'common/oldJavascripts/utils/format';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';
import UnionWeeklyEmployeeTermsDesktop from './UnionWeeklyEmployeeTermsDesktop';
import UnionWeeklyOnCallTermsDesktop from './UnionWeeklyOnCallTermsDesktop';
import TermsOfEmploymentSAG from './TermsOfEmploymentSAG';

const formatAmount = amount => {
  return !Number.isInteger(amount) ? currency(amount) : `$${amount}`;
};

const useStyles = makeStyles(theme => ({
  terms: {
    marginTop: '40px',
  },
  title: {
    color: '#646464 !important',
    fontSize: '24px !important',
    fontWeight: 400,
    marginBottom: '25px !important',
  },
  termsTableFooter: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
  },
  rowTitle: {
    color: '#5b5b5b !important',
    fontSize: '16px !important',
    fontWeight: 700,
    textTableRowansform: 'uppercase',
    backgroundColor: '#E2F1FF',
    padding: '10px',
  },
  panel: {
    backgroundColor: '#E2F1FF',
    padding: '10px',
  },
  amount: {
    color: '#0000FF !important',
    fontSize: '30px !important',
    fontWeight: 400,
    padding: 18,
    textAlign: 'center !important',
    border: '0.1px solid #BECFFE',
    wordWrap: 'break-word',
    [theme.breakpoints.up('md')]: {
      padding: 11,
    },
  },
  smallerAmount: {
    color: '#0000FF !important',
    fontSize: '27px !important',
    fontWeight: 400,
    padding: 18,
    textAlign: 'center',
    border: '0.1px solid #BECFFE',
    wordWrap: 'break-word',
    [theme.breakpoints.up('md')]: {
      padding: 11,
    },
  },
  grey: {
    color: '#939393 !important',
  },
  tooltip: {
    marginLeft: '2px',
    position: 'absolute',
  },
  idlePayRate: {
    color: '#939393 !important',
    fontSize: '16px !important',
    fontWeight: 400,
    textAlign: 'center',
  },
  greenBox: {
    backgroundColor: '#E2F1FF !important',
    color: '#5b5b5b !important',
    fontSize: '14px !important',
    fontWeight: '700 !important',
    borderBottom: '0.1px solid #BECFFE !important',
    textAlign: 'center !important',
    padding: '11px',
  },
  greenBoxNoContent: {
    borderBottom: 'none !important',
  },
  regularBox: {
    minHeight: 50,
    minWidth: 155,
    color: '#5b5b5b !important',
    fontSize: '14px !important',
    fontWeight: 700,
    textAlign: 'center',
    borderBottom: '0 solid #BECFFE',
  },
  distant: {
    color: '#5b5b5b !important',
    fontSize: '16px !important',
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
    border: '0 solid #BECFFE',
  },
  tableContainer: {
    marginTop: 50,
    marginBottom: 20,
  },
}));

const isLargeAmount = amount => Number(amount) >= 1000;

const TermsOfEmploymentDesktop = ({
  isNonUnion,
  offerData,
  terms,
  isNonUnionScheduleA,
  isNonUnionScheduleC,
  isNonUnionScheduleD,
  isUnionScheduleA,
  isUnionWeeklySchedule,
  isUnionWeeklyOnCall,
  isSAGUnion,
}) => {
  const classes = useStyles();
  const {
    idlePayRate,
    locationHours,
    sixthDayRate,
    seventhDayRate,
    sixthDayLocationRate,
    seventhDayLocationRate,
    hoursDaysTitle,
    studioDaysHours,
    hourlyStudioRate,
    dailyStudioRate,
    weeklyStudioRate,
    overtimeRate,
    hourlyLocationRate,
    weeklyLocationRate,
    daysPerWeekTitle,
    daysPerWeek,
    payGoldAtStudio,
    payGoldAtDistant,
  } = offerData;

  if (isSAGUnion) {
    return <TermsOfEmploymentSAG terms={terms} classes={classes} />;
  }

  if (isUnionWeeklySchedule) {
    return <UnionWeeklyEmployeeTermsDesktop terms={terms} />;
  }

  if (isUnionWeeklyOnCall) {
    return <UnionWeeklyOnCallTermsDesktop terms={terms} />;
  }
  const isDistant = hourlyLocationRate || weeklyLocationRate || locationHours;
  const isStudio =
    studioDaysHours || hourlyStudioRate || weeklyStudioRate || dailyStudioRate;

  const studioOvertimeRate = formatAmount(overtimeRate);
  const offerSeventhDayRate = formatAmount(seventhDayRate);

  const payDayTitle = day => {
    if (isUnionScheduleA) {
      return `Hours Apply to ${day}th`;
    }
    if (isNonUnionScheduleA) {
      return (
        <>
          Apply Hours per Day <br /> to {day}
          th Day
        </>
      );
    }
    if (isNonUnionScheduleC) {
      return `Pay ${day}th day at`;
    }
    return `${day}th day pay`;
  };

  const weekendPayRate = payRate => {
    if (isNonUnionScheduleA || isUnionScheduleA)
      return payRate > 0 ? 'Yes' : 'No';
    return formatAmount(payRate);
  };

  const showStudioDaysHours =
    !isNonUnionScheduleC && !isNonUnionScheduleD && !!studioDaysHours;

  return (
    <div className={classes.terms}>
      <Typography
        className={classes.title}
        data-test-id="TermsOfEmploymentDesktop-title"
      >
        Terms of Employment
      </Typography>
      {isStudio && (
        <Table>
          <TableHead>
            <TableRow>
              <UsaOnly>
                {' '}
                {!isNonUnion && (
                  <TableCell
                    className={[
                      classes.greenBoxNoContent,
                      classes.greenBox,
                    ].join(' ')}
                  />
                )}
              </UsaOnly>
              {hourlyStudioRate && (
                <TableCell
                  data-test-id="TermsOfEmploymentDesktop-hourlyStudioRate-title"
                  className={classes.greenBox}
                >
                  <UsaOnly> Hourly Rate</UsaOnly>
                  <CanadaOnly>Rate Per Hour</CanadaOnly>
                </TableCell>
              )}
              {dailyStudioRate && (
                <TableCell
                  data-test-id="TermsOfEmploymentDesktop-dailyStudioRate-title"
                  className={classes.greenBox}
                >
                  Daily Rate
                </TableCell>
              )}
              {weeklyStudioRate && (
                <TableCell
                  className={classes.greenBox}
                  data-test-id="TermsOfEmploymentDesktop-weeklyStudioRate-title"
                >
                  Weekly Rate
                </TableCell>
              )}
              {showStudioDaysHours && (
                <TableCell
                  className={classes.greenBox}
                  data-test-id="TermsOfEmploymentDesktop-studioDaysHours-title"
                >
                  <UsaOnly>
                    {' '}
                    {isNonUnionScheduleA ? 'Hours Per Day' : hoursDaysTitle}
                  </UsaOnly>
                  <CanadaOnly>{hoursDaysTitle}</CanadaOnly>
                </TableCell>
              )}
              {daysPerWeek && (
                <CanadaOnly>
                  <TableCell
                    className={classes.greenBox}
                    data-test-id="TermsOfEmploymentDesktop-canada-daysPerWeek-title"
                  >
                    {daysPerWeekTitle}
                  </TableCell>
                </CanadaOnly>
              )}
              {studioOvertimeRate && (
                <TableCell
                  className={classes.greenBox}
                  data-test-id="TermsOfEmploymentDesktop-overtime-title"
                >
                  Overtime Rate
                </TableCell>
              )}
              <UsaOnly>
                {(sixthDayRate || offerSeventhDayRate) && isUnionScheduleA && (
                  <TableCell
                    className={classes.greenBox}
                    data-test-id="TermsOfEmploymentDesktop-6thPayDay-title"
                  >
                    {payDayTitle('6')}
                  </TableCell>
                )}
                {(sixthDayRate || offerSeventhDayRate) && isUnionScheduleA && (
                  <TableCell
                    className={classes.greenBox}
                    data-test-id="TermsOfEmploymentDesktop-7thPayDay-title"
                  >
                    {payDayTitle('7')}
                  </TableCell>
                )}
                {(payGoldAtStudio || isUnionScheduleA) && (
                  <TableCell className={classes.greenBox}>
                    Pay Gold at Base Rate of
                  </TableCell>
                )}
              </UsaOnly>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <UsaOnly>
                {' '}
                {!isNonUnion && (
                  <TableCell
                    data-test-id="TermsOfEmploymentDesktop-studio-title"
                    className={[classes.greenBox, classes.distant].join(' ')}
                  >
                    STUDIO{' '}
                    <Tooltip
                      disableInteractive
                      title="Studio refers to a local distance within the studio zone."
                      placement="top-start"
                      className={classes.tooltip}
                    >
                      <img src={TOOLTIP_ICON} alt="schedule  tooltip" />
                    </Tooltip>
                  </TableCell>
                )}
              </UsaOnly>
              {hourlyStudioRate && (
                <TableCell
                  data-test-id="TermsOfEmploymentDesktop-hourlyStudioRate-amount"
                  className={
                    isLargeAmount(hourlyStudioRate)
                      ? classes.smallerAmount
                      : classes.amount
                  }
                >
                  {formatAmount(hourlyStudioRate)}
                </TableCell>
              )}
              {dailyStudioRate && (
                <TableCell
                  data-test-id="TermsOfEmploymentDesktop-dailyStudioRate-amount"
                  className={
                    isLargeAmount(dailyStudioRate)
                      ? classes.smallerAmount
                      : classes.amount
                  }
                >
                  {formatAmount(dailyStudioRate)}
                </TableCell>
              )}
              {weeklyStudioRate && (
                <TableCell
                  data-test-id="TermsOfEmploymentDesktop-weeklyStudioRate-amount"
                  className={
                    isLargeAmount(weeklyStudioRate)
                      ? classes.smallerAmount
                      : classes.amount
                  }
                >
                  {formatAmount(weeklyStudioRate)}
                </TableCell>
              )}
              {showStudioDaysHours && (
                <TableCell
                  data-test-id="TermsOfEmploymentDesktop-studioDaysHours-amount"
                  className={classes.amount}
                >
                  {studioDaysHours}
                </TableCell>
              )}
              {daysPerWeek && (
                <CanadaOnly>
                  <TableCell
                    data-test-id="TermsOfEmploymentDesktop-canada-daysPerWeek"
                    className={classes.amount}
                  >
                    {daysPerWeek}
                  </TableCell>
                </CanadaOnly>
              )}
              {studioOvertimeRate && (
                <TableCell
                  data-test-id="TermsOfEmploymentDesktop-overtime-amount"
                  className={
                    isLargeAmount(studioOvertimeRate)
                      ? classes.smallerAmount
                      : classes.amount
                  }
                >
                  {studioOvertimeRate}
                </TableCell>
              )}
              <UsaOnly>
                {isUnionScheduleA && (sixthDayRate || seventhDayRate) && (
                  <TableCell
                    className={[classes.amount, classes.grey].join(' ')}
                    data-test-id="TermsOfEmploymentDesktop-6thDayRate"
                  >
                    {weekendPayRate(sixthDayRate)}
                  </TableCell>
                )}
                {isUnionScheduleA && (sixthDayRate || seventhDayRate) && (
                  <TableCell
                    className={[classes.amount, classes.grey].join(' ')}
                    data-test-id="TermsOfEmploymentDesktop-7thDayRate"
                  >
                    {weekendPayRate(seventhDayRate)}
                  </TableCell>
                )}
                {payGoldAtStudio && (
                  <TableCell className={classes.amount}>
                    {formatAmount(payGoldAtStudio)}
                  </TableCell>
                )}
              </UsaOnly>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {isDistant && (
        <UsaOnly>
          <Table className={classes.tableContainer}>
            <TableHead>
              <TableRow>
                <TableCell
                  className={[classes.greenBoxNoContent, classes.greenBox].join(
                    ' ',
                  )}
                />
                {hourlyLocationRate && (
                  <TableCell
                    className={classes.greenBox}
                    data-test-id="TermsOfEmploymentDesktop-hourlyDistantRate-title"
                  >
                    Hourly Rate
                  </TableCell>
                )}
                {weeklyLocationRate && (
                  <TableCell className={classes.greenBox}>
                    Weekly Rate
                  </TableCell>
                )}
                {!!locationHours && (
                  <TableCell className={classes.greenBox}>
                    {hoursDaysTitle}
                  </TableCell>
                )}
                <UsaOnly>
                  {isUnionScheduleA &&
                    (sixthDayRate || offerSeventhDayRate) && (
                      <TableCell className={classes.greenBox}>
                        {isUnionScheduleA
                          ? 'Hours Apply to 6th'
                          : '6th day pay'}
                      </TableCell>
                    )}
                  {isUnionScheduleA &&
                    (sixthDayRate || offerSeventhDayRate) && (
                      <TableCell className={classes.greenBox}>
                        {isUnionScheduleA
                          ? 'Hours Apply to 7th'
                          : '7th day pay'}
                      </TableCell>
                    )}
                </UsaOnly>
                {idlePayRate && (
                  <TableCell className={classes.greenBox}>
                    Idle Days pay
                  </TableCell>
                )}
                {(payGoldAtDistant || isUnionScheduleA) && (
                  <TableCell className={classes.greenBox}>
                    Pay Gold at Base Rate of
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  className={[classes.greenBox, classes.distant].join(' ')}
                >
                  DISTANT{' '}
                  <Tooltip
                    disableInteractive
                    title="Distant refers to the location outside the studio zone."
                    placement="top-start"
                    className={classes.tooltip}
                  >
                    <img src={TOOLTIP_ICON} alt="schedule  tooltip" />
                  </Tooltip>
                </TableCell>
                {hourlyLocationRate && (
                  <TableCell
                    data-test-id="TermsOfEmploymentDesktop-hourlyDistantRate-amount"
                    className={
                      isLargeAmount(hourlyLocationRate)
                        ? classes.smallerAmount
                        : classes.amount
                    }
                  >
                    {formatAmount(hourlyLocationRate)}
                  </TableCell>
                )}
                {weeklyLocationRate && (
                  <TableCell
                    className={
                      isLargeAmount(weeklyLocationRate)
                        ? classes.smallerAmount
                        : classes.amount
                    }
                  >
                    {formatAmount(weeklyLocationRate)}
                  </TableCell>
                )}
                {!!locationHours && (
                  <TableCell className={classes.amount}>
                    {locationHours}
                  </TableCell>
                )}
                <UsaOnly>
                  {isUnionScheduleA && (sixthDayRate || offerSeventhDayRate) && (
                    <TableCell
                      className={[classes.amount, classes.grey].join(' ')}
                      data-test-id="TermsOfEmploymentDesktop-distant6thDayRate"
                    >
                      {isUnionScheduleA
                        ? weekendPayRate(sixthDayLocationRate)
                        : formatAmount(sixthDayRate)}
                    </TableCell>
                  )}
                  {isUnionScheduleA && (sixthDayRate || offerSeventhDayRate) && (
                    <TableCell
                      className={[classes.amount, classes.grey].join(' ')}
                      data-test-id="TermsOfEmploymentDesktop-distant7thDayRate"
                    >
                      {isUnionScheduleA
                        ? weekendPayRate(seventhDayLocationRate)
                        : offerSeventhDayRate}
                    </TableCell>
                  )}
                  {idlePayRate && (
                    <TableCell className={classes.amount}>
                      {formatAmount(idlePayRate)}
                    </TableCell>
                  )}
                  {payGoldAtDistant && (
                    <TableCell className={classes.amount}>
                      {formatAmount(payGoldAtDistant)}
                    </TableCell>
                  )}
                </UsaOnly>
              </TableRow>
            </TableBody>
          </Table>
        </UsaOnly>
      )}
    </div>
  );
};

export default TermsOfEmploymentDesktop;
