import React, { useState } from 'react';
import CrewMembers from './CrewMembers';
import OfferForm from './OfferForm';
import Loader from 'common/components/Loader';
import { withStyles } from '@mui/styles';
import { graphql } from 'react-apollo';
import { compose } from 'redux';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import withApi from 'common/hoc/withApi';
import moment from 'moment';
import getNormalizedMutationPayload from './helpers/getNormalizedMutationPayload';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import history from 'common/constants/config/history';
import { Mutations } from 'common/apollo';
import useOfferDefaults from './hooks/useOfferDefaults';
import useAccountCodeConfigurations from './hooks/useAccountCodeConfigurations';
import useSeasons from 'studio/hooks/useSeasons';
import useProjectCurrencies from './hooks/useProjectCurrencies';
import useOffers from './hooks/useOffers';
import formatOfferData from './helpers/formatOfferData';
import useCrewMembers from './hooks/useCrewMembers';

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'minmax(300px, 400px) 1fr',
    gridTemplateRows: '100%',
    gridTemplateAreas: `
        "crewMembers content"`,
    height: 'calc(100% - 180px)',
    overflowY: 'auto',
  },
  crewMembers: {
    gridArea: 'crewMembers',
    margin: '20px',
    padding: 10,
    marginRight: 0,
    boxSizing: 'content-box',
    position: 'sticky',
    top: 20,
  },
});

const CreateOffer = props => {
  const {
    classes,
    invalidateByPatterns,
    projectId,
    mutate,
    popSnackbarNotification,
    pushSnackbarNotification,
    routerQuery: { action: routerQueryAction, id: copyOfferId },
  } = props;

  const [configurationLoaded, setConfigurationLoaded] = useState(false);
  const [hasOfferBeenValidated, setHasOfferBeenValidated] = useState(false);
  const [formData, setFormData] = useState({});
  const [crew, setCrew] = useState({});
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const { termsOfHire = {} } = formData;
  const { workSchedule } = termsOfHire || {};
  const { value: workScheduleValue } = workSchedule || {};
  const { data: offerDefaults } = useOfferDefaults(projectId);
  const isCopyOfferAction = routerQueryAction === 'copy';
  const copyOfferIds =
    isCopyOfferAction && !isNaN(copyOfferId) && !!copyOfferId
      ? [copyOfferId]
      : [];
  const { data: copyOfferData = [], loading } = useOffers(
    projectId,
    copyOfferIds,
  );
  const { nodes: copyOffers = [] } = copyOfferData;
  const copyOffer = copyOffers.find(({ id }) => id === copyOfferId) || {};

  const { data: accountCodeConfigurations } = useAccountCodeConfigurations(
    projectId,
  );
  const { data: seasons } = useSeasons(projectId);
  const { data: currencies } = useProjectCurrencies({ projectId });

  const [crewMemberFilters, setCrewMemberFilters] = useState({});
  const crewMembersData = useCrewMembers({
    ...crewMemberFilters,
    id: projectId,
  });
  const { data: { crew: { edges: allCrewMembers = [] } = {} } = {} } =
    crewMembersData || {};
  const onFilter = newFilters =>
    setCrewMemberFilters(oldFilters => ({
      ...oldFilters,
      ...(newFilters || {}),
    }));

  if (
    !offerDefaults ||
    !accountCodeConfigurations ||
    !seasons ||
    !currencies ||
    loading
  )
    return <Loader />;

  const getPrePopulatedAccountCodes = () => {
    const accountCodeLineItemKeys = [
      'studio',
      'location',
      'hourly',
      'daily',
      'weekly',
      'perDiem',
      'box',
      'car',
      'computer',
      'mobile',
      'housing',
    ];
    let accountCodes = [];
    accountCodeConfigurations.forEach(({ id, defaultValue, code }) => {
      if (!defaultValue && code !== 'detail/sub') return;
      accountCodeLineItemKeys.forEach(lineItemKey => {
        accountCodes = [
          ...accountCodes,
          {
            accountCodeId: id,
            lineItemKey,
            value: defaultValue || '',
          },
        ];
      });
    });
    return accountCodes;
  };

  const onChangeCrewMember = node =>
    setCrew(crew => ({
      ...crew,
      [node.id]: crew[node.id] === undefined ? node : undefined,
    }));

  const confirmOffer = (scaleRates, isUnionWeeklySchedule) => {
    setSubmitInProgress(true);

    const loadingMessage = 'Submitting Offer Now...';

    let modifiedScaleRates = isValidScaleRates(scaleRates) ? scaleRates : {};
    delete modifiedScaleRates?.__typename;
    delete modifiedScaleRates?.scaleRateHash;
    let toe = formData?.termsOfEmployment;
    let toh = formData?.termsOfHire;

    delete toe?.tableErrors;
    delete toe?.scaleRates;
    delete toe?.guaranteedHoursDaily;
    delete toe?.guaranteedHoursWeekly;
    delete toh?.fringeContract?.totalFringePercentage;

    let modiFiedtermsOfHire = toh?.fringeContract
      ? {
          ...toh,
          fringeContract: {
            code: toh?.fringeContract?.code,
            description: toh?.fringeContract?.description,
          },
        }
      : { ...toh };

    let modifiedFormData = {
      ...formData,
      modiFiedtermsOfHire,
      termsOfEmployment: {
        ...toe,
        scaleRates: modifiedScaleRates,
        isTermsOfEmploymentV2:
          isUnionWeeklySchedule ||
          formData?.termsOfEmployment?.isTermsOfEmploymentV2,
      },
    };

    const variables = getNormalizedMutationPayload(modifiedFormData);
    // Additional processing of mutation payload
    variables.projectId = projectId;

    // Remove undefined values in hash.
    Object.keys(crew).forEach(
      key => crew[key] === undefined && delete crew[key],
    );
    variables.crew = Object.values(crew)
      .filter(({ id }) => id)
      .map(({ id }) => parseInt(id, 10));

    popSnackbarNotification();
    pushSnackbarNotification({
      message: loadingMessage,
      variant: SnackbarVariants.INFO,
    });
    mutate({
      variables,
    })
      .then(({ data, message = '' }) => {
        setSubmitInProgress(false);
        popSnackbarNotification({
          message: loadingMessage,
          popAllMatching: true,
        });
        if (message.length) {
          pushSnackbarNotification({
            message: `There was an error submitting the offer${
              !message ? '.' : `: ${message}.`
            }`,
            variant: SnackbarVariants.ERROR,
          });
          return;
        }
        pushSnackbarNotification({
          message: 'Offer successfully submitted',
          variant: SnackbarVariants.SUCCESS,
        });
        invalidateByPatterns([
          `projects/${projectId}/offers`,
          `projects/${projectId}/reviews`,
        ]);
        history.push(`/projects/${projectId}/offers`);
      })
      .catch(err => {
        setSubmitInProgress(false);
        const { graphQLErrors, message } = err;
        graphQLErrors.forEach(console.warn);
        popSnackbarNotification({
          message: loadingMessage,
          popAllMatching: true,
        });
        pushSnackbarNotification({
          message: `There was an error submitting the offer${
            !message ? '.' : `: ${message}.`
          }`,
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  const isValidScaleRates = (scaleRates = {}) => {
    return Object.keys(scaleRates).some(k => {
      return parseInt(scaleRates[k]) > 0;
    });
  };

  const checkCopyOfferObject = () => {
    if (isCopyOfferAction && Object.entries(copyOffer).length === 0) {
      pushSnackbarNotification({
        message: 'Copied Offer Details Not Found! Continue with Defaults.',
        variant: SnackbarVariants.WARNING,
        duration: 6000,
      });
    }
  };

  const copyOfferForm = () => {
    const isSingleSeason = seasons.length === 1;
    const formattedOfferData = formatOfferData(copyOffer, offerDefaults, true);

    // If its the project has a single season and the offer being copied has no season
    // set the season to the only one available
    if (isSingleSeason && !formattedOfferData.termsOfHire.season) {
      formattedOfferData.termsOfHire.season = seasons[0]?.id;
    }
    formattedOfferData.offerDate.startDateObject = moment();
    formattedOfferData.offerDate.startDate = moment().format('LL');
    setFormData(formattedOfferData);
  };

  const initializeOfferDefaults = () => {
    const {
      boxRentalAllowance,
      carAllowance,
      computerRentalAllowance,
      hireState = {},
      housingAllowance,
      mobilePhoneAllowance,
      perDiemAllowance,
      sendDateConfiguration,
      workState = {},
      hiringStatus,
      hireCity = {},
      workCity = {},
    } = offerDefaults;

    setFormData({
      offerDate: {
        startDateObject: moment(),
        startDate: moment().format('LL'),
        sendDateObject: moment(),
        sendDate: moment().format('LL'),
        isLinkActive: sendDateConfiguration === 'start_date',
      },
      termsOfHire: {
        hireState: hireState && hireState.code,
        hireStateId: hireState && hireState.id,
        workState: workState && workState.code,
        workStateId: workState && workState.id,
        hireCity: hireCity && hireCity.code,
        hireCityId: hireCity && hireCity.id,
        workCity: workCity && workCity.code,
        workCityId: workCity && workCity.id,
        employmentClassification: 'w2',
        season: seasons.length === 1 ? seasons[0].id : null,
        hiringStatus: hiringStatus,
        currency: currencies.length === 1 ? currencies[0] : null,
        payAtRollback: true,
      },
      allowances: {
        boxRentalAllowance,
        carAllowance,
        computerRentalAllowance,
        housingAllowance,
        mobilePhoneAllowance,
        perDiemAllowance,
      },
      accountCodes: getPrePopulatedAccountCodes(),
    });
  };

  if (!configurationLoaded) {
    setConfigurationLoaded(true);
    checkCopyOfferObject();
    Object.entries(copyOffer).length === 0
      ? initializeOfferDefaults()
      : copyOfferForm();
  }

  return (
    <div className={classes.root}>
      <CrewMembers
        projectId={projectId}
        onChange={onChangeCrewMember}
        formData={crew}
        hasOfferBeenValidated={hasOfferBeenValidated}
        classes={{ root: classes.crewMembers }}
        data={crewMembersData}
        onFilter={onFilter}
      />
      <OfferForm
        crew={crew}
        onLoadCrew={ids => {
          const newCrew = {};
          allCrewMembers.forEach(({ node }) => {
            if (ids.includes(node.id)) {
              newCrew[node.id] = node;
            } else {
              newCrew[node.id] = undefined;
            }
          });
          setCrew({ ...crew, ...newCrew });
        }}
        onSubmit={confirmOffer}
        submitInProgress={submitInProgress}
        performAdditionalValidation={() => {
          // Flag that the offer has been validated so we should show errors.
          setHasOfferBeenValidated(true);
          // Additional validation passes if crew members have been selected.
          const isCrewEmpty = Object.values(crew).every(
            val => val === undefined,
          );
          if (isCrewEmpty) return false;
          return Object.keys(crew).length > 0;
        }}
        formData={formData}
        workScheduleCode={workScheduleValue}
        setFormData={setFormData}
        isCopyOffer={isCopyOfferAction}
        showOfferDrafts={!isCopyOfferAction}
      />
    </div>
  );
};

export default compose(
  withSnackbarNotification,
  withApi,
  graphql(Mutations.SubmitOffer),
  withStyles(styles),
)(CreateOffer);
