import React from 'react';
import { Button } from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import classnames from 'classnames';

const SendDocumentsButton = ({ offers, limit, classes, onOpen, onClear }) => {
  const limitCheck = offers.length >= limit;

  if (offers.length === 0) return null;

  return (
    <>
      <div className={classes.actionsContainer}>
        <Button
          className={classes.sendDocumentsButton}
          variant="contained"
          onClick={() => onOpen()}
          startIcon={<PostAddIcon />}
          data-test-id="send-documetns-button"
        >
          Send Documents
        </Button>
        {limitCheck && (
          <span className={classnames(classes.count, classes.maxCount)}>
            {limit} is the maximum allowed selection
          </span>
        )}
      </div>
      <div className={classes.countContainer}>
        <span className={classes.count}>{offers.length} Offer(s) selected</span>
        <Button
          variant="outlined"
          className={classes.btnOutlined}
          startIcon={<HighlightOffIcon />}
          onClick={() => onClear()}
        >
          Clear
        </Button>
      </div>
    </>
  );
};

export default SendDocumentsButton;
