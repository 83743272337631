import React, { useState } from 'react';
import InputField from './InputField';
import InputRadioRow from './InputRadioRow';
import RadioRow from './RadioRow';
import { Table, TableCell, TableRow } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';

const useStyles = makeStyles(theme => ({
  tableRoot: {
    marginTop: 10,
    width: '100%',
  },
  tableTitle: {
    marginTop: 30,
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 16,
    color: '#6d7278',
  },
  tableSpacing: {
    marginBottom: 16,
  },
  warningLabel: {
    color: '#FFA800',
    fontSize: 14,
    textAlign: 'center',
    maxWidth: '65%',
    marginBottom: 10,
  },
  warningIcon: {
    color: '#FFA800',
    position: 'relative',
    top: 4,
    right: 8,
  },
}));

const StyledTableCell = styled(TableCell)({
  width: '30%',
  backgroundColor: '#f9fdff',
  color: '#0000FF',
  fontSize: 14,
  fontWeight: 'bold',
  border: '2px solid #BECFFE',
  paddingTop: 15,
  paddingBottom: 15,
  paddingLeft: 8,
  textAlign: 'center',
});

const theme = createTheme({
  overrides: {
    MuiInput: {
      input: {
        fontSize: 16,
        fontWeight: 'normal',
        textAlign: 'center',
        width: 196,
        top: 3,
        color: '#525252',
      },
      underline: {
        width: 196,
        color: '#696969',
        '&:before': {
          borderBottom: `1px solid #8D8D8D`,
        },
        '&$focused:not($disabled):before': {
          borderBottom: `2px solid #0000FF`,
        },
        '&:after': {
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          borderBottom: `2px solid #0000FF`,
        },
        '&$error:not($disabled):before': {
          borderBottom: `1px solid #FFBF00`,
        },
      },
      disabled: {
        '&:before': {
          borderBottom: `1px solid #C6C6C6`,
        },
      },
    },
    MuiFormGroup: {
      root: {
        flexWrap: 'no-wrap',
        flexDirection: 'row',
        justifyContent: 'center',
      },
    },
  },
});

const RateTable = props => {
  const classes = useStyles();
  const {
    formData = {},
    onChange: upstreamOnChange,
    scaleRates,
    contracts = [],
    isContractsLoading = false,
    isReadOnly = false,
    handleSixthSeventhRatetableDisplay,
    manageOffers,
  } = props;
  const {
    rate,
    rateDistant,
    guaranteedHours,
    guaranteedHoursDistant,
    guaranteedHours6thDay,
    guaranteedHours7thDay,
    guaranteedHours6thDayDistant,
    guaranteedHours7thDayDistant,
    payIdleDaysDistant,
    payGoldAt,
    payGoldAtDistant,
    payAtScale,
    payAtScaleDistant,
    idleAtScaleDistant,
    goldAtScale,
    goldAtScaleDistant,
  } = formData;

  const [isOpenSixthSeventhDay, setOpenSixthSeventhDay] = useState(false);
  const onHandleSixthSeventhDay = value => {
    setOpenSixthSeventhDay(value);
  };
  handleSixthSeventhRatetableDisplay &&
    handleSixthSeventhRatetableDisplay(isOpenSixthSeventhDay);
  let isCNCSignatory,
    signatoryID = null;
  if (Array.isArray(contracts)) {
    const pensionContract = contracts.find(
      contract => contract.type === 'pension',
    );
    const pensionArr = pensionContract?.contracts || [];
    if (pensionArr.length > 0) {
      const contract = pensionArr.find(contract => contract.signatory);
      signatoryID = contract?.signatory?.id;
      isCNCSignatory = signatoryID === 'C';
    }
  }

  let isSignatoryAbsentMessage;
  if (!signatoryID && !isReadOnly && !isContractsLoading) {
    isSignatoryAbsentMessage =
      'Sorry, we’re not finding the scale rate. Please check with your C&C Coordinator.';
  }

  const onHandleIdleGoldUpdatePayRate = (rowKey, value, type, isScale) => {
    const isStudio = type === 'studio';
    const objKeyGold = isStudio ? 'payGoldAt' : 'payGoldAtDistant';
    const isDistant = type === 'distant';
    const isHourly = rowKey === 'payHourly';

    if (isHourly) {
      upstreamOnChange({
        [objKeyGold]: value,
      });
    }

    if (isHourly && isDistant) {
      upstreamOnChange({
        payIdleDaysDistant: value,
        guaranteedHoursFrequency: 'daily',
      });
    }

    if (rowKey === 'payGold' && isStudio) {
      upstreamOnChange({
        [objKeyGold]: isScale ? value : rate,
      });
    }
    if (rowKey === 'payGold' && isDistant) {
      upstreamOnChange({
        [objKeyGold]: isScale ? value : rateDistant,
      });
    }
    if (rowKey === 'payIdle' && isDistant) {
      upstreamOnChange({
        payIdleDaysDistant: isScale ? value : rateDistant,
      });
    }
  };

  const payHourlyKeys = {
    rowTitle: 'Rate Per Hour',
    rowKey: 'payHourly',
    rowKeyStudio: 'rate',
    rowKeyDistant: 'rateDistant',
    rateStudio: rate,
    rateDistant,
    scaleKeyStudio: 'payAtScale',
    scaleKeyDistant: 'payAtScaleDistant',
    scaleStudio: payAtScale,
    scaleDistant: payAtScaleDistant,
    payrollScaleValStudio: scaleRates?.rate,
    payrollScaleValDistant: scaleRates?.rateDistant,
  };

  const payIdleKeys = {
    rowTitle: 'Pay Idle Days at',
    rowKey: 'payIdle',
    rowKeyDistant: 'payIdleDaysDistant',
    rateDistant: payIdleDaysDistant || rateDistant,
    isStudioDisabled: true,
    scaleKeyDistant: 'idleAtScaleDistant',
    scaleDistant: idleAtScaleDistant,
    payrollScaleValStudio: scaleRates?.rate,
    payrollScaleValDistant: scaleRates?.rateDistant,
  };

  const payGoldKeys = {
    rowTitle: 'Pay Gold at Base Rate of',
    rowKey: 'payGold',
    rowKeyStudio: 'payGoldAt',
    rowKeyDistant: 'payGoldAtDistant',
    rateStudio: payGoldAt || rate,
    rateDistant: payGoldAtDistant || rateDistant,
    scaleKeyStudio: 'goldAtScale',
    scaleKeyDistant: 'goldAtScaleDistant',
    scaleStudio: goldAtScale,
    scaleDistant: goldAtScaleDistant,
    payrollScaleValStudio: scaleRates?.rate,
    payrollScaleValDistant: scaleRates?.rateDistant,
  };

  const sixthDayKeys = {
    rowTitle: 'Apply Hours to 6th day?',
    scaleKeyStudio: 'guaranteedHours6thDay',
    scaleKeyDistant: 'guaranteedHours6thDayDistant',
    applySixthSeventhDayStudio: guaranteedHours6thDay,
    applySixthSeventhDayDistant: guaranteedHours6thDayDistant,
  };

  const seventhDayKeys = {
    rowTitle: 'Apply Hours to 7th day?',
    scaleKeyStudio: 'guaranteedHours7thDay',
    scaleKeyDistant: 'guaranteedHours7thDayDistant',
    applySixthSeventhDayStudio: guaranteedHours7thDay,
    applySixthSeventhDayDistant: guaranteedHours7thDayDistant,
  };

  return (
    <div className={classes.tableRoot}>
      <ThemeProvider theme={theme}>
        {isSignatoryAbsentMessage && (
          <Typography
            color="warning"
            variant="caption"
            className={classes.warningLabel}
            data-test-id="union-signatory-warning-message"
          >
            <WarningIcon className={classes.warningIcon} />
            {isSignatoryAbsentMessage}
          </Typography>
        )}
        <Table className={classes.tableSpacing}>
          <TableRow>
            <TableCell />
            <StyledTableCell className={classes.headerTitle}>
              Studio
            </StyledTableCell>
            <StyledTableCell className={classes.headerTitle}>
              Distant
            </StyledTableCell>
          </TableRow>

          <InputRadioRow
            {...props}
            {...payHourlyKeys}
            handleIdleGoldUpdatePayRate={onHandleIdleGoldUpdatePayRate}
            isCNCSignatory={isCNCSignatory}
          />
          <InputField
            guaranteedHours={guaranteedHours}
            guaranteedHoursDistant={guaranteedHoursDistant}
            guaranteedHoursScaleStudio={scaleRates?.guaranteedHours}
            guaranteedHoursScaleDistant={scaleRates?.guaranteedHoursDistant}
            guaranteedHours6thStudio={guaranteedHours6thDay}
            guaranteedHours6thDistant={guaranteedHours6thDayDistant}
            guaranteedHours7thStudio={guaranteedHours7thDay}
            guaranteedHours7thDistant={guaranteedHours7thDayDistant}
            manageOffers={manageOffers}
            handleSixthSeventhDay={onHandleSixthSeventhDay}
            isCNCSignatory={isCNCSignatory}
            {...props}
          />
        </Table>
        {isOpenSixthSeventhDay && (
          <Table className={classes.tableSpacing}>
            <RadioRow {...sixthDayKeys} {...props} />
            <RadioRow {...seventhDayKeys} {...props} />
          </Table>
        )}
        <Table>
          <InputRadioRow
            {...payIdleKeys}
            {...props}
            handleIdleGoldUpdatePayRate={onHandleIdleGoldUpdatePayRate}
            isCNCSignatory={isCNCSignatory}
          />
          <InputRadioRow
            {...payGoldKeys}
            {...props}
            handleIdleGoldUpdatePayRate={onHandleIdleGoldUpdatePayRate}
            isCNCSignatory={isCNCSignatory}
          />
        </Table>
      </ThemeProvider>
    </div>
  );
};
export default RateTable;
