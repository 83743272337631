import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Containers
import ProjectSetup from 'admin/containers/Projects/ProjectSetup';
import Project from './Project';
import ProjectManagement from './ProjectManagement';
import OfferCreation from 'studio/containers/OfferCreation';
import ManageCrew from 'studio/containers/ManageCrew';
import OfferArchives from 'studio/containers/OfferArchives';

// Components
import ProjectsList from 'studio/components/ProjectsList';

export default props => {
  return (
    <Switch>
      <Route path="/projects/:projectId/archives" component={OfferArchives} />
      <Route path="/projects/:projectId/crew" component={ManageCrew} />
      <Route
        path={[
          // Implementation Admin
          '/projects/:projectId/imported-settings',
          '/projects/:projectId/project-settings',
          '/projects/:projectId/related-projects',
          '/projects/:projectId/project-templates',
          '/projects/:projectId/audit-logs',
          // Legacy URLs
          '/projects/:projectId/settings',
          '/projects/:projectId/documents',
          '/projects/:projectId/workflow',
        ]}
        component={ProjectSetup}
      />

      <Route
        path={[
          // Implementation Admin
          '/projects/:projectId/invitations',
          '/projects/:projectId/users',
          // Studio Admin
          '/projects/:projectId/crew',
          '/projects/:projectId/approvals',
          '/projects/:projectId/i9-verifications',
          '/projects/:projectId/archives',
          '/projects/:projectId/offer-documents',
          '/projects/:projectId/reviews',
          '/projects/:projectId/files/:fileId',
        ]}
        component={Project}
      />
      <Route
        exact
        path={
          // Change App requires base layout
          '/projects/:projectId/change-app'
        }
        component={ProjectManagement}
      />
      <Route
        exact
        path={
          // Offers List Should be handled by Project
          '/projects/:projectId/offers'
        }
        component={Project}
      />
      <Route
        path={
          // New offer And Edit Offer Should be handled by Offer Creation
          '/projects/:projectId/offers'
        }
        component={OfferCreation}
      />
      <Route component={ProjectsList} />
    </Switch>
  );
};
