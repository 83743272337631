import React from 'react';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const typographyProps = { variant: 'h6' };

const RuleList = props => {
  const { onClick, id, title, isOpen, children } = props;
  return (
    <React.Fragment>
      <ListItem
        button
        onClick={onClick}
        data-test-id={`GroupContainer-${id}List`}
      >
        <ListItemText
          primary={title}
          primaryTypographyProps={typographyProps}
        />
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Divider />
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </React.Fragment>
  );
};

export default RuleList;
