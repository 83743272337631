import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query CliendAndProducer($projectId: Int!) {
    project(id: $projectId) {
      id
      country {
        id
        name
      }
      client {
        id
        name
        cncId
      }
      productionCompany {
        id
        producer {
          id
          name
          cncId
        }
      }
    }
  }
`;

const useCliendAndProducer = projectId => {
  const { loading, data } = useQuery(QUERY, {
    skip: !projectId,
    variables: { projectId: parseInt(projectId) },
  });
  return { loading, data };
};

export default useCliendAndProducer;
