import { PUSH, POP, CLEAR } from 'common/store/actions/actionTypes';
import { SNACKBAR_NOTIFICATION } from 'common/store/actions/snackbarNotifications';

export const initialState = {
  queue: [],
};

export default (state = initialState, action) => {
  const { type, message, snackObject, popAllMatching = false, popAll = false } =
    action || {};

  switch (type) {
    case PUSH(SNACKBAR_NOTIFICATION):
      return {
        ...state,
        queue: [...state.queue, snackObject],
      };
    case POP(SNACKBAR_NOTIFICATION):
      if (popAll) return { ...initialState };
      if (message && popAllMatching) {
        return {
          ...state,
          queue: [...state.queue].filter(({ message: m }) => m !== message),
        };
      }
      return {
        ...state,
        queue: [...state.queue].slice(1),
      };
    case CLEAR(SNACKBAR_NOTIFICATION):
      return {
        ...state,
        queue: [],
      };
    default:
      return state;
  }
};
