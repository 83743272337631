import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  Paper,
} from '@mui/material';
import {
  Close as CloseIcon,
  HelpOutlineOutlined,
  Email as EmailIcon,
  Phone as PhoneIcon,
} from '@mui/icons-material';
import { compose } from 'redux';
import { styled } from '@mui/system';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router';
import Loader from 'common/components/Loader';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Separator from 'common/oldJavascripts/components/Base/Separator';
import T from 'common/oldJavascripts/utils/i18n';
import ContactDetails from 'common/oldJavascripts/components/Base/ContactDetails';
import useContactDetails from './hooks/useContactDetails';

const styles = theme => ({
  dialogPaper: {
    maxHeight: '600px !important',
    minHeight: 400,
    minWidth: 500,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  header: {
    fontWeight: '700 !important',
    fontSize: '16px !important',
    lineHeight: 0,
  },
  DialogTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contactGroupContainer: {
    padding: '8px 15px',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: 'auto auto',
    gap: '5px',
    gridGap: '5px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  contactDetailContainer: {
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '30px auto',
    alignItems: 'center',
    fontSize: '0.92307692em',
    lineHeight: '1.07692308',
    fontWeight: '400',
  },
  icons: {
    height: '18px',
    width: 'auto',
  },
});

const StyledDialog = styled(Dialog)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '50%',
  minWidth: '40%',
  margin: 'auto',
  padding: '20px',
});

const StyledDialogTitle = styled(DialogTitle)({
  '& h2': {
    color: '#262626',
    fontSize: 18,
  },
});

const StyledIconButton = styled(IconButton)({
  height: '35px',
  borderRadius: '2px',
  position: 'absolute',
  right: 8,
  top: 17,
  color: '#262626',
});

const StyledPaper = styled(Paper)({
  minHeight: 172,
  width: '500px',
  margin: '10px',
});

const ContactsModal = props => {
  const { open, onClose, offerId, classes } = props;
  const [hirerData, setHirerData] = useState({
    fullName: '',
    email: '',
    phone: '',
  });
  const { loading, data } = useContactDetails(offerId);
  const { hirer = {}, project = {} } = data || {};
  const { name: projectName } = project;

  useEffect(() => {
    if (hirer.fullName) {
      const { fullName, email, phone } = hirer;
      setHirerData({ fullName, email, phone });
    }
  }, [hirer]);
  const { fullName, email, phone } = hirerData;

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.dialogPaper }}
    >
      {loading ? (
        <Loader />
      ) : (
        <StyledDialogTitle>
          <div className={classes.DialogTitleContainer}>
            <Typography variant="h2" marginRight={'5px'}>
              Help
            </Typography>
            <HelpOutlineOutlined />
          </div>
          <StyledIconButton onClick={onClose}>
            <CloseIcon />
          </StyledIconButton>
        </StyledDialogTitle>
      )}
      {!loading && (
        <>
          <StyledPaper>
            <BoxItem
              style={{
                padding: '15px 20px',
              }}
            >
              <Typography className={classes.header}>
                Hiring Managers For This Project
              </Typography>
            </BoxItem>

            <Separator />
            <BoxItem>
              <ContactDetails>
                <ContactDetails.Title>{fullName}</ContactDetails.Title>
                <ContactDetails.Data secondary={true}>
                  <ContactDetails.DataValue>
                    {projectName}
                  </ContactDetails.DataValue>
                </ContactDetails.Data>
              </ContactDetails>
              <div className={classes.contactGroupContainer}>
                <div className={classes.contactDetailContainer}>
                  <EmailIcon className={classes.icons} />
                  <div>{email}</div>
                </div>
                {phone && (
                  <div className={classes.contactDetailContainer}>
                    <PhoneIcon className={classes.icons} />
                    <div>{phone}</div>
                  </div>
                )}
              </div>
            </BoxItem>
          </StyledPaper>

          <StyledPaper>
            <BoxItem
              style={{
                padding: '15px 20px',
              }}
            >
              <Typography className={classes.header}>Cast & Crew</Typography>
            </BoxItem>

            <Separator />
            <BoxItem>
              <ContactDetails>
                <ContactDetails.Title>Customer Service</ContactDetails.Title>
                <ContactDetails.Data secondary={true}>
                  <ContactDetails.DataValue>Cast&Crew</ContactDetails.DataValue>
                </ContactDetails.Data>
              </ContactDetails>
              <div className={classes.contactGroupContainer}>
                <div className={classes.contactDetailContainer}>
                  <EmailIcon className={classes.icons} />
                  <div>{T('help.support_email')}</div>
                </div>
                <div className={classes.contactDetailContainer}>
                  <PhoneIcon className={classes.icons} />
                  <div>{T('help.support_phone')}</div>
                </div>
              </div>
            </BoxItem>
          </StyledPaper>
        </>
      )}
    </StyledDialog>
  );
};

export default compose(withStyles(styles), withRouter)(ContactsModal);
