import React from 'react';

const CopyFromIcon = () => {
  return (
    <div>
      <svg width="12" height="14" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.842 0H1.263C.568 0 0 .573 0 1.273v8.909h1.263v-8.91h7.58V0zm1.895 2.545H3.789c-.694 0-1.263.573-1.263 1.273v8.91c0 .7.569 1.272 1.263 1.272h6.948c.695 0 1.263-.573 1.263-1.273V3.818c0-.7-.568-1.273-1.263-1.273zm0 10.182H3.789V3.818h6.948v8.91z"
          fill="#000"
          fillRule="nonzero"
        />
      </svg>
    </div>
  );
};
export default CopyFromIcon;
