import { format, parse } from 'date-fns';
import classNames from 'classnames';
import React from 'react';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { GOLD } from 'common/components/CnCUi/theme/colors';

const styles = theme => ({
  root: {
    width: '300px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '20px 1fr',
    padding: '0px 10px',
  },
  current: {
    borderLeft: `2px solid #0000FF`,
    color: '#0000FF',
  },
  previous: {
    borderLeft: `2px solid ${GOLD}`,
    color: GOLD,
  },
  status: {
    gridColumn: 1,
    gridRow: 1,
    fontStyle: 'italic',
  },
  timeStamp: {
    gridColumn: 2,
    gridRow: 1,
    fontStyle: 'italic',
  },
  content: {
    gridColumn: '1 / -1',
    gridRow: 2,
    whiteSpace: 'pre-line',
    color: 'rgb(84, 84, 84)',
  },
});

const getDisplayDate = givenDate => {
  if (!givenDate) return '';

  // API gives us a UTC time string like "2021-03-30 21:56:33 UTC" so we need to
  // convert this to local timezone & then format as per user's locale settings.
  const localDate = parse(
    givenDate.replace('UTC', '+00'),
    'yyyy-MM-dd HH:mm:ss x',
    new Date(),
  );
  return format(localDate, 'P p');
};

const SavedDealNote = ({
  current = false,
  notesUpdatedAt,
  notes = '',
  classes,
  index,
}) => (
  <div
    className={classNames(
      classes.root,
      current ? classes.current : classes.previous,
    )}
  >
    {current ? (
      <Typography className={classes.status} variant="body1">
        Current
      </Typography>
    ) : (
      <React.Fragment>
        <Typography className={classes.status} variant="body1">
          Previous
        </Typography>
        <Typography className={classes.timeStamp} variant="body1">
          {getDisplayDate(notesUpdatedAt)}
        </Typography>
      </React.Fragment>
    )}
    <Typography
      className={classes.content}
      variant="body1"
      data-test-id={`SavedDealNote-notes-${index}`}
    >
      {notes}
    </Typography>
  </div>
);

export default withStyles(styles)(SavedDealNote);
