import React from 'react';
import { makeStyles } from '@mui/styles';

import CloseIcon from '@mui/icons-material/Close';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableRowHeader from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowHeader';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

import OfferFullHistory from './FullHistory';

const styles = makeStyles({
  close: {
    float: 'right',
    color: 'black',
  },
});

const ExpandedStatus = props => {
  const { offer, onToggleStatus } = props;
  const classes = styles();
  const { id } = offer || {};

  return (
    <ExpandableTableRow>
      <ExpandableTableCell colSpan={10} expanded>
        <CloseIcon
          className={classes.close}
          onClick={() => onToggleStatus(id)}
        />
        <ExpandableTableRowHeader>
          <span data-test-id="OfferHistory-header">Offer Full History</span>
        </ExpandableTableRowHeader>
        <OfferFullHistory offer={offer} />
      </ExpandableTableCell>
    </ExpandableTableRow>
  );
};

export default ExpandedStatus;
