import {
  REQUEST,
  SUCCESS,
  FAILURE,
  PUSH,
  PULL,
  GET,
  POST,
  PUT,
  DELETE,
  INVALIDATE,
  REPLACE,
  UPDATE_PROGRESS,
} from './actionTypes';

export const RESOURCE = 'RESOURCE';
export const SUB_RESOURCE = 'SUB_RESOURCE';
export const RESOURCE_ITEM = 'RESOURCE_ITEM';

export const getResource = payload => ({
  type: REQUEST(GET(RESOURCE)),
  payload,
});
export const getSubResource = payload => ({
  type: REQUEST(GET(SUB_RESOURCE)),
  payload,
});
export const getResourceSuccess = payload => ({
  type: SUCCESS(GET(RESOURCE)),
  payload,
});
export const getSubResourceSuccess = payload => ({
  type: SUCCESS(GET(SUB_RESOURCE)),
  payload,
});
export const postResource = payload => ({
  type: REQUEST(POST(RESOURCE)),
  payload,
});
export const postResourceSuccess = payload => ({
  type: SUCCESS(POST(RESOURCE)),
  payload,
});
export const putResource = payload => ({
  type: REQUEST(PUT(RESOURCE)),
  payload,
});
export const putResourceSuccess = payload => ({
  type: SUCCESS(PUT(RESOURCE)),
  payload,
});
export const deleteResource = payload => ({
  type: REQUEST(DELETE(RESOURCE)),
  payload,
});
export const deleteResourceSuccess = payload => ({
  type: SUCCESS(DELETE(RESOURCE)),
  payload,
});
export const failResource = payload => ({
  type: FAILURE(RESOURCE),
  payload,
});

export const invalidateResource = payload => ({
  type: INVALIDATE(RESOURCE),
  payload,
});

export const replaceResource = payload => ({
  type: REPLACE(RESOURCE),
  payload,
});

export const updateResourceProgress = payload => ({
  type: UPDATE_PROGRESS(RESOURCE),
  payload,
});

export const pullResourceItem = payload => ({
  type: PULL(RESOURCE_ITEM),
  payload,
});
export const pushResourceItem = payload => ({
  type: PUSH(RESOURCE_ITEM),
  payload,
});
