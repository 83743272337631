import React from 'react';
import { styled } from '@mui/system';

const StyledHeader = styled('nav')({
  maxHeight: '61px',
  display: 'flex',
  right: 0,
  marginTop: '17px',
});

const NavItemsContainer = ({ children }) => {
  return <StyledHeader>{children}</StyledHeader>;
};

export default NavItemsContainer;
