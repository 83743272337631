import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = {
  button: {
    backgroundColor: '#0000FF',
    color: '#ffffff',
    fontWeight: '700',
    '&:hover': {
      backgroundColor: '#1A55FD',
    },
    '&:active': {
      backgroundColor: '#0000B3',
    },
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  disabledButton: {
    backgroundColor: '#0000FF !important',
    color: '#ffffff !important',
    opacity: '0.25',
    cursor: 'default',
  },
};

const NextButton = props => {
  const { disabled, onClick, classes, title } = props;

  const button = (
    <Button
      classes={{ root: classes.button, disabled: classes.disabledButton }}
      variant="contained"
      disabled={disabled}
      onClick={onClick}
    >
      Next
    </Button>
  );

  if (!disabled) return button;

  return (
    <Tooltip disableInteractive id="tooltip" title={<ol>{title}</ol>}>
      <div>{button}</div>
    </Tooltip>
  );
};

export default withStyles(styles)(NextButton);
