import React from 'react';
import { makeStyles } from '@mui/styles';
import BaseTable, {
  Header,
  Cell,
} from 'common/oldJavascripts/components/Base/Table';
import Row from './Row';

const useStyles = makeStyles({
  root: {
    background: '#ffffff',
    paddingBottom: '20px',
    width: '100%',
  },
  header: {
    position: 'sticky',
    top: 298,
    zIndex: 1,
  },
});

const Table = ({ offers, projectId }) => {
  const classes = useStyles();

  return (
    <BaseTable className={classes.root}>
      <Header className={classes.header}>
        <Cell header sort="name">
          Name
        </Cell>
        <Cell header sort="department">
          Department
        </Cell>
        <Cell header sort="account_code">
          Account code
        </Cell>
        <Cell header sort="occupation">
          Occupation
        </Cell>
        <Cell header sort="start_at">
          Start date
        </Cell>
        <Cell header sort="approved_at">
          Approved on
        </Cell>
        <Cell header>Actions</Cell>
      </Header>
      {offers?.map(offer => (
        <Row key={offer.id} {...{ offer, projectId }} />
      ))}
    </BaseTable>
  );
};

export default Table;
