// NON UNION FOR V2
import nonUnionDaily from './nonUnionDaily';
import nonUnionWeekly from './nonUnionWeekly';
import nonUnionHourly from './nonUnionHourly';

// NON UNION FOR v1
import nonUnionV1 from './nonUnionV1';

/// This whole file is meant to give the correct data
/// to the RateScaleRow component

/// RateScaleRow is used throughout the application (create offer, edit offer, etc) so it must be
/// left as is to not affect the rest of the application

const nonUnionTermsOfEmployment = offers => {
  const {
    id,
    termsOfEmployment: termsV1 = {},
    termsOfEmploymentV2 = {},
    termsOfHire: {
      workSchedule: { code } = {},
      union: { isNonUnion } = {},
    } = {},
  } = offers;

  // LEGACY DEFAULT MAP FOR RATE SCALE ROW TO LOAD PROPER DATA
  let terms_of_employment = {};

  // Potential TermsOfEmployment types
  // I dont think termsOfEmploymentV2 will ever come back empty
  // but just in case we do optional chaining here for __typename
  const nonUnionHourlyToe =
    termsOfEmploymentV2?.__typename === 'TermsOfEmploymentNonUnionHourly';
  const nonUnionWeeklyToe =
    termsOfEmploymentV2?.__typename === 'TermsOfEmploymentNonUnionWeekly';
  const nonUnionDailyToe =
    termsOfEmploymentV2?.__typename === 'TermsOfEmploymentNonUnionDaily';

  const nonUnionDefaultToe =
    !nonUnionDailyToe && !nonUnionHourlyToe && !nonUnionWeeklyToe;

  if (nonUnionHourlyToe) {
    terms_of_employment = { ...nonUnionHourly(termsOfEmploymentV2) };
  }

  if (nonUnionWeeklyToe) {
    terms_of_employment = { ...nonUnionWeekly(termsOfEmploymentV2) };
  }
  if (nonUnionDailyToe) {
    terms_of_employment = { ...nonUnionDaily(termsOfEmploymentV2) };
  }

  if (nonUnionDefaultToe) {
    // LEGACY DEFAULT MAP VALUES
    terms_of_employment = { ...nonUnionV1(termsV1) };
  }

  return {
    id,
    terms_of_employment,
    union_is_non_union: isNonUnion,
    work_schedule_code: code,
  };
};

export default nonUnionTermsOfEmployment;
