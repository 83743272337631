import sortList from './sortList';
import sortListWithPage from './sortListWithPage';

// Sorts an array of objects in 2-dimensional space using their x and y coordinates.
// Allows for variation from the fixed axis by establishing the direction of the
// points (vertical or horizontal) and then sorting only along that axis.
const sortByCoords = (arr, direction, withPage = false) => {
  const xArr = arr.map(item => item.x);
  const yArr = arr.map(item => item.y);
  const width = Math.max(...xArr) - Math.min(...xArr);
  const height = Math.max(...yArr) - Math.min(...yArr);
  const orderBy = height > width ? 'y' : 'x';
  const sortDirection = direction || (orderBy === 'y' ? 'desc' : 'asc');
  return !!withPage
    ? sortListWithPage(arr, orderBy, sortDirection)
    : sortList(arr, orderBy, sortDirection);
};

export default sortByCoords;
