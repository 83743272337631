import React from 'react';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';

const styles = {
  root: {
    WebkitBoxFlex: '1',
    MozBoxFlex: '1',
    msBoxFlex: '1',
    boxFlex: '1',
    WebkitFlex: '1',
    MozFlex: '1',
    msFlex: '1',
    flex: '1',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: '2.15384615em',
    lineHeight: '1',
    color: '#0000FF',
    verticalAlign: 'top',
    marginTop: '10px',
  },
  small: {
    WebkitFlexBasis: '16.5%',
    msFlexPreferredSize: '16.5%',
    flexBasis: '16.5%',
  },
};

const RowInfoItem = props => {
  const { classes, children, small } = props;
  const rootClass = classNames(classes.root, {
    [classes.small]: small,
  });
  return <div className={rootClass}>{children}</div>;
};

export default withStyles(styles)(RowInfoItem);
