import upperFirst from 'lodash.upperfirst';

const processDocumentSubmissionError = (documentId, error = {}) => {
  const { status_code, resource = {} } = error || {};

  // Handle correctly-formed validation errors.
  if (documentId && status_code === 422) {
    const validationErrors =
      resource.errors && resource.errors[0].validateFields;
    if (validationErrors) {
      const formattedValidationErrors = validationErrors.map(
        validationError => {
          const fieldId = Object.keys(validationError)[0];
          const message = upperFirst(validationError[fieldId]);
          return { documentId, fieldId, message };
        },
      );
      return formattedValidationErrors;
    }
  }

  // Handle all other errors.
  return [{ response: error.response || error }];
};

// Takes an array of { documentId, promise }. If all the promises resolve,
// returns a single promise which resolves. If any promises reject, converts
// rejections into standardised error objects, & if
// rejectErrors is true, return a single promise which rejects with the array
// of error objects.
const processDocumentRequests = (documentRequests, rejectErrors = true) => {
  const promises = documentRequests.map(({ documentId, promise }) =>
    promise
      .then(() => 'ok')
      .catch(error => processDocumentSubmissionError(documentId, error)),
  );
  return Promise.all(promises).then(results => {
    const failures = results.filter(result => result !== 'ok').flat();
    // make sure we have clear errors once we're done
    if (!failures.some(failure => failure?.documentId)) {
      Promise.reject([]);
    }
    if (!failures.length) return;
    if (!rejectErrors) return;
    return Promise.reject(failures);
  });
};

export default processDocumentRequests;
