import React, { Component } from 'react';
import withApi from 'common/hoc/withApi';
import MainHeader from '../../../Base/MainHeader';
import DOMPurify from 'dompurify';

class MainNav extends Component {
  static queries = {
    me: {
      info() {
        return {
          id: '/me',
        };
      },
    },
  };

  render() {
    const sanitizeMe = this.props.me.data || {};
    const me = sanitizeMe;
    DOMPurify.sanitize(sanitizeMe);
    DOMPurify.sanitize(me);
    const {
      is_admin: isAdmin,
      is_update_password_required: isUpdatePasswordRequired,
      is_password_expired: isPasswordExpired,
      is_prodco_admin,
      is_super_admin: isSuperAdmin,
      can_access_projects: canAccessProjects,
      can_access_offers: canAccessOffers,
      producer_id: producerId,
      producer_name: producerName,
    } = me;
    const isProdcoAdmin = is_prodco_admin && isAdmin;
    const libraryLinkTo = `library${isProdcoAdmin ? '-fields' : ''}`;

    if (isProdcoAdmin) {
      localStorage.setItem('producerId', producerId);
      localStorage.setItem('producerName', producerName);
    }

    if (isUpdatePasswordRequired || isPasswordExpired) {
      return <MainHeader.Nav />;
    }

    return (
      <MainHeader.Nav>
        {canAccessProjects && (
          <MainHeader.Item
            key="projects"
            to="projects"
            data-test-id="MainNav-projects"
          >
            Projects
          </MainHeader.Item>
        )}
        {isAdmin && (
          <MainHeader.Item
            key="library"
            to={libraryLinkTo}
            data-test-id="MainNav-library"
          >
            Library
          </MainHeader.Item>
        )}
        {isSuperAdmin && (
          <MainHeader.Item
            key="global-permissions"
            to="global-permissions"
            data-test-id="MainNav-adminUsers"
          >
            Admin Users
          </MainHeader.Item>
        )}
        {canAccessOffers && (
          <MainHeader.Item
            key="offers"
            to="offers"
            data-test-id="MainNav-myOffers"
          >
            My Offers
          </MainHeader.Item>
        )}
        {(isSuperAdmin || isAdmin) && (
          <MainHeader.Item key="tools" to="tools" data-test-id="MainNav-tools">
            Tools
          </MainHeader.Item>
        )}
      </MainHeader.Nav>
    );
  }
}

export default withApi(MainNav);
