import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import { Phone as PhoneIcon, Email as EmailIcon } from '@mui/icons-material';
// **** DEFAULT COMPONENT ****

const styles = {
  root: {
    padding: '8px 15px',
    width: 'auto',
  },
  right: {
    textAlign: 'right',
  },
  topRight: {
    position: 'absolute',
    top: '14px',
    right: '15px',
  },
};

const ContactDetails = withStyles(styles)(props => {
  const { children, classes, right, topRight } = props;
  const rootClasses = classNames(classes.root, {
    [classes.right]: right,
    [classes.topRight]: topRight,
  });
  return <div className={rootClasses}>{children}</div>;
});

// **** TITLE COMPONENT ****

const titleStyles = {
  root: {
    fontSize: '1.53846154em',
    lineHeight: '1.53846154',
    fontWeight: '700',
    color: '#f25e5b',
  },
  secondary: {
    fontSize: '1em',
    lineHeight: '1.07692308',
    fontWeight: '400',
  },
};

const Title = withStyles(titleStyles)(props => {
  const { children, classes, secondary } = props;
  const rootClasses = classNames(classes.root, {
    [classes.secondary]: secondary,
  });
  return (
    <div className={rootClasses} data-test-id="ContactDetails-title">
      {children}
    </div>
  );
});

// **** CONTENT COMPONENT ****

const contentStyles = {
  root: {
    marginTop: '10px',
  },
  compact: {
    display: 'inline-block',
    verticalAlign: 'top',
    width: '48%',
  },
};

const Content = withStyles(contentStyles)(props => {
  const { children, classes, compact } = props;
  const rootClasses = classNames(classes.root, {
    [classes.compact]: compact,
  });
  return <div className={rootClasses}>{children}</div>;
});

// **** CONTENT SECTION COMPONENT ****

const contentSectionStyles = {
  compact: {
    textAlign: 'right',
  },
};

const ContentSection = withStyles(contentSectionStyles)(props => {
  const { children, classes, compact } = props;
  const rootClass = classNames({ [classes.compact]: compact });
  return <div className={rootClass}>{children}</div>;
});

// **** DATA COMPONENT ****

const dataStyles = {
  root: {
    fontSize: '0.92307692em',
    lineHeight: '1.07692308',
    padding: '7px 0',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
  },
  secondary: {
    padding: '0',
  },
};

const Data = withStyles(dataStyles)(props => {
  const { children, classes, secondary } = props;
  const rootClasses = classNames(classes.root, {
    [classes.secondary]: secondary,
  });
  return <div className={rootClasses}>{children}</div>;
});

// **** DATA LABEL COMPONENT ****

const dataLabelStyles = {
  root: {
    display: 'inline-block',
    marginRight: '5px',
    width: '23px',
  },
};

const DataLabel = withStyles(dataLabelStyles)(props => {
  const { children, classes } = props;
  return <div className={classes.root}>{children}</div>;
});

// **** DATA VALUE COMPONENT ****

const dataValueStyles = {
  root: {
    display: 'inline-block',
  },
  highlight: {
    fontWeight: '700',
  },
};

const DataValue = withStyles(dataValueStyles)(props => {
  const { children, classes, highlight } = props;
  const rootClasses = classNames(classes.root, {
    [classes.highlight]: highlight,
  });
  return (
    <div className={rootClasses} data-test-id="ContactDetails-dataValue">
      {children}
    </div>
  );
});

// **** PREFAB COMPONENT ****

const Prefab = props => {
  const { compact, detailName, detailTitle, detailEmail, detailPhone } = props;
  const contentTitle = [];
  const contentDetails = [];

  contentTitle.push(<Title key="name">{detailName}</Title>);

  if (detailTitle) {
    contentTitle.push(
      <Title key="title" secondary={true}>
        {detailTitle}
      </Title>,
    );
  }

  if (detailEmail) {
    contentDetails.push(
      <Data key="email">
        <DataLabel>
          <EmailIcon />
        </DataLabel>
        <DataValue>{detailEmail}</DataValue>
      </Data>,
    );
  }

  if (detailPhone) {
    contentDetails.push(
      <Data key="phone">
        <DataLabel>
          <PhoneIcon />
        </DataLabel>
        <DataValue>{detailPhone}</DataValue>
      </Data>,
    );
  }

  return (
    <ContactDetails>
      <Content>{contentTitle}</Content>
      <Content compact={compact}>
        <ContentSection compact={compact}>{contentDetails}</ContentSection>
      </Content>
    </ContactDetails>
  );
};

// **** EXPORTS ****

ContactDetails.Title = Title;
ContactDetails.Content = Content;
ContactDetails.ContentSection = ContentSection;
ContactDetails.Data = Data;
ContactDetails.DataLabel = DataLabel;
ContactDetails.DataValue = DataValue;
ContactDetails.Prefab = Prefab;

export { Title, Content, ContentSection, Data, DataLabel, DataValue, Prefab };
export default ContactDetails;
