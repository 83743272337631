export const ALLOWANCE_TYPES = [
  { id: 1, name: 'Box Rental', code: 'box_rental', typeName: 'allowance' },
  {
    id: 2,
    name: 'Computer Rental',
    code: 'computer_rental',
    typeName: 'allowance',
  },
  {
    id: 3,
    name: 'Car Allowance',
    code: 'car_allowance',
    typeName: 'allowance',
  },
  {
    id: 4,
    name: 'Mobile Phone Allowance',
    code: 'mobile_phone',
    typeName: 'allowance',
  },
  {
    id: 5,
    name: 'Per Diem',
    code: 'per_diem',
    typeName: 'allowance',
  },
  {
    id: 6,
    name: 'Housing Allowance',
    code: 'housing_allowance',
    typeName: 'allowance',
  },
];
