import { HttpLink } from 'apollo-link-http';
import getAPIServer from 'common/constants/config/apiServer';

// Note that inside Cypress, fetch calls are automatically intercepted &
// redirected to XHR (see shims file)
const link = new HttpLink({
  uri: `${getAPIServer()}/graphql`,
  credentials: 'include',
});

export default link;
