import React from 'react';

// HoC
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';

// Actions
import { logout } from 'common/store/actions/authenticate';

// Utitities
import history from 'common/constants/config/history';
import getSSOUrl from 'common/constants/config/ssoUrl';

// Components
import { Paper, Button, Typography, Link } from '@mui/material';
import UsaOnly from 'common/components/UsaOnly';

// Icons
import IdentificationDocumentsIcon from 'common/icons/IdentificationDocuments';

const ACCEPTABLE_DOCS_URL =
  'https://www.uscis.gov/i-9-central/acceptable-documents/list-documents/form-i-9-acceptable-documents';

const MYCNC_W4S_URL = `${getSSOUrl()}/w4s`;
const ENROLL_DIRECT_DEPOSIT_URL = `${getSSOUrl()}/profile`;

const styles = {
  root: {
    height: 'fit-content',
    width: 500,
    textAlign: 'center',
    margin: 'auto',
    padding: '20px 20px 5px 20px',
  },
  headerText: {
    color: '#5a6062 !important',
  },
  image: {
    height: 'auto',
    width: 200,
  },
  footerText: {
    color: '#5a6062',
  },
  myCnCButton: {
    '&:hover': {
      textDecoration: 'none',
    },
  },

  myDirectDepositButton: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  directDepositButton: {
    borderRadius: '30px !important',
    marginTop: '5px !important',
  },

  i9Link: {
    color: '#0000FF',
    fontWeight: 'bold',
  },
  buttons: {
    marginTop: 20,
    '& > :not(:last-child)': {
      marginRight: 24,
    },
  },
  offerButtons: {
    backgroundColor: '#E0E0E0 !important',
    color: '#2F2F2F !important',
  },
};

const OnboardingComplete = props => {
  const { match = {}, logout, classes } = props;
  const { params = {} } = match || {};
  const { offerId } = params;

  const goToSubmittedOffer = () =>
    history.push(`/onboarding/offers/${offerId}/submitted`);
  return (
    <Paper className={classes.root}>
      <Typography variant="h6" align="center" className={classes.headerText}>
        Your Start Packet has been Submitted for Approval!
      </Typography>
      <IdentificationDocumentsIcon className={classes.image} />
      <UsaOnly>
        <Typography variant="h6" align="center" className={classes.footerText}>
          Don’t forget your I-9 needs to be verified, so bring{' '}
          <Link
            href={ACCEPTABLE_DOCS_URL}
            target="_blank"
            rel="noopener"
            className={classes.i9Link}
            data-test-id="Complete-i9AcceptableDocsLink"
          >
            acceptable documents
          </Link>{' '}
          within 3 days (or by your first day of work, if hired to work for less
          than 3 days) of when you report to work. Go to MyCast&Crew to fill out
          your Federal and State W-4s.
        </Typography>
      </UsaOnly>
      <div className={classes.buttons}>
        <Button
          variant="contained"
          className={classes.offerButtons}
          onClick={goToSubmittedOffer}
        >
          Offer Status
        </Button>
        <UsaOnly>
          <Link href={MYCNC_W4S_URL} className={classes.myCnCButton}>
            <Button variant="contained" className={classes.offerButtons}>
              Go to My W-4s
            </Button>
          </Link>
        </UsaOnly>
        <Button
          variant="contained"
          onClick={logout}
          className={classes.offerButtons}
        >
          Logout
        </Button>
      </div>
      {/*
        This is a Material UI Link, not a React Router Link
        This uses href rather than to
      */}
      <Link
        href={ENROLL_DIRECT_DEPOSIT_URL}
        className={classes.myDirectDepositButton}
      >
        <Button
          color="primary"
          variant="contained"
          className={classes.directDepositButton}
        >
          Enroll in Direct Deposit
        </Button>
      </Link>
    </Paper>
  );
};
const mapStateToProps = () => {};
const mapDispatchToProps = {
  logout,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
)(OnboardingComplete);
