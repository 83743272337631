import React from 'react';
import { Grid, Typography } from '@mui/material';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';

import LabeledTextField from './LabeledTextField';
import LabeledSelect from './LabeledSelect';

const I9 = ({ project, states, onChange, errors }) => {
  const {
    employerOrgName,
    employerOrgAddress,
    employerOrgCity,
    employerOrgState,
    employerOrgZipCode,
  } = project || {};

  const { id: employerOrgStateId } = employerOrgState || {};

  const stateOptions = (states || []).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const {
    employer_org_name: errName,
    employer_org_address: errAddress,
    employer_org_city: errCity,
    employer_org_state: errState,
    employer_org_zip_code: errZipCode,
  } = errors || {};

  return (
    <BoxItem divider>
      <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
        <span data-test-id="I9-title">Employer Information</span>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <LabeledTextField
            label="Business or Organization Name"
            value={employerOrgName}
            onChange={val => onChange('employerOrgName', val)}
            testId="I9-employerOrgName"
            error={errName ? 'Value is required' : null}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <LabeledTextField
            label="Business or Organization Address(Street Number and Name)"
            value={employerOrgAddress}
            onChange={val => onChange('employerOrgAddress', val)}
            testId="I9-employerOrgAddress"
            error={errAddress ? 'Value is required' : null}
            required
          />
        </Grid>
        <Grid item xs={2}>
          <LabeledTextField
            label="City or Town"
            value={employerOrgCity}
            onChange={val => onChange('employerOrgCity', val)}
            testId="I9-employerOrgCity"
            error={errCity ? 'Value is required' : null}
            required
          />
        </Grid>
        <Grid item xs={2}>
          <UsaOnly>
            <LabeledSelect
              label="State"
              testId="I9-employerOrgStateId"
              options={stateOptions}
              value={employerOrgStateId}
              onChange={(value, label) => {
                const val = value ? { id: value, name: label } : null;
                onChange('employerOrgState', val);
              }}
              error={errState ? 'Value is required' : null}
              required
            />
          </UsaOnly>
          <CanadaOnly>
            <LabeledSelect
              label="Province"
              testId="I9-employerOrgStateId"
              options={stateOptions}
              value={employerOrgStateId}
              onChange={(value, label) => {
                const val = value ? { id: value, name: label } : null;
                onChange('employerOrgState', val);
              }}
              error={errState ? 'Value is required' : null}
              required
            />
          </CanadaOnly>
        </Grid>
        <Grid item xs={1}>
          <UsaOnly>
            <LabeledTextField
              label="Zip Code"
              value={employerOrgZipCode}
              onChange={val => onChange('employerOrgZipCode', val)}
              testId="I9-employerOrgZipCode"
              error={errZipCode ? 'Value is required' : null}
              required
            />
          </UsaOnly>
          <CanadaOnly>
            <LabeledTextField
              label="Zip Code"
              value={employerOrgZipCode}
              onChange={val => onChange('employerOrgZipCode', val)}
              testId="I9-employerOrgZipCode"
              error={errZipCode ? 'Value is required' : null}
              required
            />
          </CanadaOnly>
        </Grid>
      </Grid>
    </BoxItem>
  );
};

export default I9;
