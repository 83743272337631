import React from 'react';

// HoC
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

// Utilities

// Components
import Snackbar from 'common/components/Snackbar';

// Actions
import { popNotification } from 'common/store/actions/snackbarNotifications';

const SnackbarController = props => {
  const { queue = [], popNotification } = props;
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    popNotification();
  };
  const open = queue.length > 0;
  const [{ message, variant, duration } = {}] = queue;
  if (!open) return null;
  return (
    <Snackbar
      open={open}
      message={message}
      onExited={popNotification}
      handleClose={handleClose}
      variant={variant}
      duration={duration}
    />
  );
};

const mapDispatchToProps = {
  popNotification,
};

const mapStateToProps = ({ snackbarNotifications }) => ({
  queue: snackbarNotifications.queue,
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SnackbarController);
