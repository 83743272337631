import React from 'react';
import Flexbox from 'flexbox-react';
import Link from 'common/oldJavascripts/components/Base/Link';

const I9Sample = () => (
  <Flexbox alignItems="center" flexDirection="row">
    <Link
      to={'/samples/i-9instr.pdf'}
      external={true}
      newWindow={true}
      large={true}
    >
      I-9 Instructions
    </Link>
  </Flexbox>
);

export default I9Sample;
