import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import history from 'common/constants/config/history';

// Components
import { Typography, Tabs, Tab, Paper } from '@mui/material';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import { pushNotification } from 'common/store/actions/snackbarNotifications';
import Notification from 'common/oldJavascripts/components/Pages/Account/Notification';
import NonCrewProfile from 'common/components/NonCrewProfile';
import BaseLayout from 'common/oldJavascripts/components/Pages/Layouts/Base';

// HoC
import withPermissionProtection from 'common/hoc/withPermissionProtection';
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';

// Context
import { FeatureFlagsContext } from 'common/context';

const tabLabels = ['Profile', 'Notifications'];

const profileTabPaths = {
  0: '/profile',
  1: '/profile/notifications',
};

const styles = {
  paperRoot: {
    height: '80vh',
    paddingTop: 30,
    display: 'grid',
    gridTemplateAreas: `
      ".     header     ."
      ". .............. ."
      ".      tabs      ."
      ". .............. ."
      ".     content    ."
    `,
    gridTemplateColumns: '3% 94% 3%',
    gridTemplateRows: '5% 10px 6% 20px auto',
    overflow: 'auto',
  },
  notificationContent: {
    gridArea: 'content',
  },
  header: {
    gridArea: 'header',
  },
  tabs: {
    gridArea: 'tabs',
    borderBottom: '1px solid #d2d2d2',
  },
};

class NonCrewProfileContainer extends Component {
  static contextType = FeatureFlagsContext;

  static queries = {
    me: {
      info() {
        return {
          id: '/me',
        };
      },
    },
  };
  static mutators = {
    profile: {
      info() {
        return {
          id: '/account/profile',
        };
      },
    },
    password: {
      info() {
        return {
          id: '/account/password',
          create: true,
        };
      },
    },
  };

  constructor(props) {
    super(props);
    const { profile = {}, location = {} } = props;
    const {
      data: {
        first_name: firstName = '',
        middle_name: middleName = '',
        last_name: lastName = '',
        email = '',
      } = {},
    } = profile || {};
    const { pathname = '' } = location || {};
    const tabValue = pathname.includes('notifications') ? 1 : 0;
    this.state = {
      firstName,
      middleName,
      lastName,
      email,
      tabValue,
      isEditing_Name: false,
      profileIsLoading: true,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { profile: profileMutator = {}, location = {} } = props;
    const {
      firstName,
      middleName,
      lastName,
      email,
      profileIsLoading,
      tabValue,
    } = state;
    const {
      data: {
        first_name: upstreamFirstName = '',
        middle_name: upstreamMiddleName = '',
        last_name: upstreamLastName = '',
        email: upstreamEmail = '',
      } = {},
    } = profileMutator || {};
    const { pathname = '' } = location || {};
    // Update the component's state with the user's information
    // after receiving updated upstreamProps, which have been previously loading
    if (
      (firstName !== upstreamFirstName ||
        middleName !== upstreamMiddleName ||
        lastName !== upstreamLastName ||
        email !== upstreamEmail) &&
      profileIsLoading
    ) {
      return {
        firstName: upstreamFirstName,
        middleName: upstreamMiddleName,
        lastName: upstreamLastName,
        email: upstreamEmail,
        profileIsLoading: false,
      };
    }
    // Update the active tab when the path has changed
    if (profileTabPaths[tabValue] !== location) {
      return {
        tabValue: pathname.includes('notifications') ? 1 : 0,
      };
    }
    // Update profileIsLoading state if the profileMutator is loading
    if (profileMutator.status === 'loading' && !profileIsLoading) {
      return { profileIsLoading: true };
    }
    if (
      profileMutator.status === 'success' ||
      (profileMutator.status === 'failed' && profileIsLoading)
    ) {
      return { profileIsLoading: false };
    }
    return null;
  }

  // Toggle edit for inputs specified by the key argument
  toggleEdit = key =>
    this.setState(({ [`isEditing_${key}`]: editStatus }) => ({
      [`isEditing_${key}`]: !editStatus,
    }));

  // Update user's name input values
  updateInput = key => ({ target: { value = '' } }) =>
    this.setState({ [key]: value });

  // Handles tab changes in user settings and updates the url
  handleTabChange = (_, tabValue) =>
    this.setState({ tabValue }, () => {
      history.push(profileTabPaths[tabValue]);
    });

  // Submit request to save profile and set user name editing to false on success
  // Snackbar notifications are triggered on success and error
  saveProfile = () => {
    const { profile: profileMutator = {}, pushNotification } = this.props;
    const {
      firstName: first_name,
      middleName: middle_name,
      lastName: last_name,
    } = this.state;
    const profileData = { first_name, middle_name, last_name };
    const saveProfileSuccessCallback = () => {
      pushNotification({
        message: 'Your profile has been successfully updated.',
        variant: SnackbarVariants.SUCCESS,
      });
      this.setState({ isEditing_Name: false });
    };
    const saveProfileErrorCallback = () => {
      pushNotification({
        message:
          'There was an error while saving your profile. Please try again.',
        variant: SnackbarVariants.ERROR,
      });
    };
    profileMutator.updateAndSave(
      profileData,
      'edit',
      {},
      saveProfileSuccessCallback,
      saveProfileErrorCallback,
    );
  };

  // Request to reset password via email
  // Snackbar notifications are triggered on success and error
  resetPassword = () => {
    const { password: passwordMutator, pushNotification } = this.props;
    const resetPasswordSuccessCallback = () => {
      pushNotification({
        message: "We've sent you an email with a link to reset your password.",
        variant: SnackbarVariants.SUCCESS,
      });
    };
    const resetPasswordErrorCallback = () => {
      pushNotification({
        message:
          'There was an error resetting your password. Please try again.',
        variant: SnackbarVariants.ERROR,
      });
    };
    passwordMutator.save(
      'edit',
      { json: true },
      resetPasswordSuccessCallback,
      resetPasswordErrorCallback,
    );
  };

  // Navigate to the profile page from the notifications tab
  navigateToProfile = () => {
    this.handleTabChange('', 0);
  };

  render() {
    const {
      isEditing_Name,
      tabValue,
      firstName,
      middleName,
      lastName,
      email,
    } = this.state;
    const {
      classes = {},
      profile: profileMutator = {},
      password: passwordMutator = {},
    } = this.props;
    const { data: profileData = {} } = profileMutator || {};
    const {
      first_name: upstreamFirstName = '',
      last_name: upstreamLastName = '',
    } = profileData || {};
    const profileIsLoading = profileMutator.status === 'loading';
    const passwordIsLoading = passwordMutator.status === 'loading';
    const nonCrewProfileProps = {
      firstName,
      middleName,
      lastName,
      email,
      isEditing_Name,
      profileIsLoading,
      passwordIsLoading,
      upstreamFirstName,
      upstreamLastName,
      saveProfile: this.saveProfile,
      resetPassword: this.resetPassword,
      updateInput: this.updateInput,
      toggleEdit: this.toggleEdit,
      handleTabChange: this.handleTabChange,
    };
    const featureFlags = this.context || [];
    const globalNavFlag = featureFlags.includes('GlobalNavbar');
    if (globalNavFlag) {
      return (
        <BaseLayout nonEmployee={true}>
          <LayoutContent>
            <Paper classes={{ root: classes.paperRoot }}>
              <div className={classes.notificationContent}>
                <Notification navigateToProfile={this.navigateToProfile} />
              </div>
            </Paper>
          </LayoutContent>
        </BaseLayout>
      );
    }

    return (
      <BaseLayout>
        <LayoutContent>
          <Paper classes={{ root: classes.paperRoot }}>
            <Typography variant="h4" classes={{ root: classes.header }}>
              User Settings
            </Typography>
            <Tabs
              classes={{ root: classes.tabs }}
              value={tabValue}
              onChange={this.handleTabChange}
            >
              {tabLabels.map(label => (
                <Tab label={label} />
              ))}
            </Tabs>
            <Switch>
              <Route
                path={'/profile/notifications'}
                render={() => (
                  <div className={classes.notificationContent}>
                    <Notification navigateToProfile={this.navigateToProfile} />
                  </div>
                )}
              />
              <Route
                render={() => <NonCrewProfile {...nonCrewProfileProps} />}
              />
            </Switch>
          </Paper>
        </LayoutContent>
      </BaseLayout>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  pushNotification,
};

export default compose(
  withPermissionProtection('can_access_non_crew_profile'),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withApi,
)(NonCrewProfileContainer);
