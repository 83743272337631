import React from 'react';
import { Button, Typography, Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'class-names';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    width: '483px',
    padding: '13px 17px',
  },
  header: {
    color: '#000000',
    fontSize: '20px !important',
    fontWeight: '700 !important',
    marginTop: '14px !important',
    textAlign: 'left',
  },
  description: {
    color: '#525252 !important',
    fontSize: '14px !important',
    marginBottom: '14px !important',
    marginTop: '8px !important',
    '& span': {
      fontWeight: 700,
    },
  },
  remoteDescription: {
    color: '#646464',
    fontSize: 14,
    marginBottom: '14px',
    marginTop: '8px',
    textAlign: 'left',
  },
  closeBtn: {
    background: 'transparent !important',
    color: '#0000FF',
    border: '1px solid #0000FF',
    borderRadius: 2,
    marginRight: '20px !important',
  },
  btn: {
    height: 40,
    borderRadius: '2px',
    fontWeight: '700 !important',
    fontSize: '14px !important',
    textTransform: 'none !important',
  },
  confirmBtn: {
    borderRadius: '2px',
    fontWeight: 400,
    fontSize: 14,
    backgroundColor: '#0000FF',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1A55FD',
    },
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },

  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    marginTop: '12px',
    marginBottom: '12px',
  },
  headerContainer: {
    textAlign: 'left',
  },
  linkColor: {
    color: '#0000FF',
    textDecoration: 'underline',
  },
}));

const OffsiteWorkersModal = ({ handleClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={true}>
      <div className={classes.dialogRoot}>
        <div className={classes.headerContainer}>
          <Typography variant="h2" className={classes.header}>
            Offsite Worker for California Pay Data Reporting
          </Typography>
          <Typography className={classes.description}>
            <span>
              This employee will be tracked as an offsite worker for the
              California Pay Data report (referred to as a "remote worker"),
            </span>{' '}
            which includes anyone who is entirely remote, teleworking, or
            home-based, and has no expectation to regularly report in person to
            a physical establishment to perform work duties.
            <br />
            <br />
            For more information:{' '}
            <a
              href="https://calcivilrights.ca.gov/paydatareporting/faqs/"
              className={classes.linkColor}
              target="_blank"
              rel="noopener noreferrer"
            >
              California Pay Data Reporting FAQ{' '}
            </a>
          </Typography>
        </div>
        <div className={classes.btnContainer}>
          <Button
            variant="outlined"
            className={classnames(classes.closeBtn, classes.btn)}
            onClick={() => handleClose('N')}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classnames(classes.confirmBtn, classes.btn)}
            onClick={() => handleClose('Y')}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default OffsiteWorkersModal;
