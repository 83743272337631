import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const QUERY = gql`
  query guildTerms($unionCode: String!) {
    guildTerms(unionCode: $unionCode) {
      id
      unionCode
      scheduleCode
      scheduleDescription
      occupationCode
      occupationDescription
      guaranteedHours
      guaranteedHoursFrequency
      days
      per
      workerType
    }
  }
`;

const useSAGGuilds = variables => {
  const { unionCode } = variables;

  const { loading, data, error } = useQuery(QUERY, {
    skip: unionCode !== 'SAG',
    variables,
  });

  return { loading, data: data && data.guildTerms, error };
};

export default useSAGGuilds;
