import React, { useCallback, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import Alert from '@mui/material/Alert';
import { Link as ReactLink } from 'react-router-dom';

import withApi from 'common/hoc/withApi';
import ajax from 'common/utilities/ajax';

import useOfferDocumentsDetails from './hooks/useOfferDocumentsDetails';
import useDownloadW9Document from './hooks/useDownloadW9Document';
import useDownloadExternalDocument from './hooks/useDownloadExternalDocument';

import Link from 'common/oldJavascripts/components/Base/Link';
import Loader from 'common/components/Loader';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Box from 'common/oldJavascripts/components/Base/Box';
import BoxHeader from 'common/oldJavascripts/components/Base/Box/BoxHeader';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Header from 'common/oldJavascripts/components/Base/Header';

import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import FileItem from './FileItem';

const useStyles = makeStyles(_theme => ({
  fileList: {
    border: '1px solid #E6E7EA',
    borderBottom: 'none',
    padding: 0,
    '& li': {
      borderBottom: '1px solid #E6E7EA',
    },
  },
  finalDocContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  finalDocLink: {
    display: 'flex',
    alignItems: 'center',
  },
  rowRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  finalDocIcon: {
    marginRight: '5px',
  },
  backLink: {
    textAlign: 'right',
  },
}));

const DOWNLOAD_STARTED =
  'Hold on a moment while we prepare the document for download. Your download should being momentarily.';
const DOWNLOAD_ERROR =
  'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.';

const Details = props => {
  const { routerParams = {}, pushNotification, popNotification } = props;
  const { offerId, projectId } = routerParams;
  const classes = useStyles();
  const [downloadW9, setDownloadW9] = useState(false);
  const [downloadExtId, setDownloadExtId] = useState(false);

  const { data: offer, loading, error } = useOfferDocumentsDetails(offerId);
  const {
    data: w9,
    loading: w9loading,
    error: w9error,
  } = useDownloadW9Document(offerId, !downloadW9);
  const {
    data: extDoc,
    loading: extDocLoading,
    error: extDocError,
  } = useDownloadExternalDocument(offerId, downloadExtId);

  const {
    projectUser: { profile: { fullName: employeeFullName } = {} } = {},
    documentDetails: offerFiles = [],
    loanoutCorporation = {},
    externalDocuments = [],
  } = offer || {};

  const { w9: w9doc } = loanoutCorporation || {};
  const { id: w9id, name: w9name } = w9doc || {};

  const handleDownloadNotification = useCallback(
    (error, url) => {
      popNotification();
      if (error || !url) {
        pushNotification({
          message: DOWNLOAD_ERROR,
          variant: SnackbarVariants.ERROR,
        });
      } else {
        pushNotification({
          message: 'Your download has started.',
          variant: SnackbarVariants.SUCCESS,
        });
        window.location.href = url;
      }
    },
    [pushNotification, popNotification],
  );

  useEffect(() => {
    if (downloadW9 && !w9loading) {
      handleDownloadNotification(w9error, w9.downloadUrl);
      setDownloadW9(false);
    }
  }, [downloadW9, w9loading, w9, w9error, handleDownloadNotification]);

  useEffect(() => {
    if (downloadExtId && !extDocLoading) {
      handleDownloadNotification(extDocError, extDoc.downloadUrl);
      setDownloadExtId(null);
    }
  }, [
    downloadExtId,
    extDocLoading,
    extDoc,
    extDocError,
    handleDownloadNotification,
  ]);

  if (loading) return <Loader />;
  if (error) return <Alert severity="error">{error.message}</Alert>;

  let canDownloadFinalDocument = offerFiles?.every(file => file.canDownload);

  const downloadFile = file => {
    const { canDownload, documentId: id } = file;

    if (!canDownload) {
      return false;
    }

    const url = `/v2/projects/${projectId}/offers/${offerId}/files/${id}/download`;
    pushNotification({
      message:
        'Hold on a moment while we prepare the document for download. Your download should being momentarily.',
      variant: SnackbarVariants.INFO,
    });
    ajax
      .get(url)
      .then(response => {
        const { path = '' } = response;
        if (!path)
          throw new Error(
            'No path received when requesting document for download.',
          );
        popNotification();
        pushNotification({
          message: 'Your download has started.',
          variant: SnackbarVariants.SUCCESS,
        });
        window.location.href = path;
      })
      .catch(err => {
        popNotification();
        pushNotification({
          message:
            'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.',
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  const downloadFinalDocument = () => {
    pushNotification({
      message:
        'Hold on a moment while we prepare the document for download. Your download should being momentarily.',
      variant: SnackbarVariants.INFO,
    });
    ajax
      .get(`/v2/offers/${offerId}/full-final-document`)
      .then(response => {
        const { path = '' } = response;
        if (!path)
          throw new Error(
            'No path received when requesting document for download.',
          );
        popNotification();
        pushNotification({
          message: 'Your download has started.',
          variant: SnackbarVariants.SUCCESS,
        });
        window.location.href = path;
      })
      .catch(err => {
        popNotification();
        pushNotification({
          message:
            'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.',
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  const downloadW9file = () => {
    pushNotification({
      message: DOWNLOAD_STARTED,
      variant: SnackbarVariants.INFO,
    });
    setDownloadW9(true);
  };

  const downloadExternal = ({ id }) => {
    pushNotification({
      message: DOWNLOAD_STARTED,
      variant: SnackbarVariants.INFO,
    });
    setDownloadExtId(id);
  };

  return (
    <LayoutContent>
      <Box>
        <BoxHeader>
          <Header>
            <Header.Title>{employeeFullName}</Header.Title>
          </Header>
        </BoxHeader>
        <BoxItem>
          <BoxItem minimal={true}>
            <Header>
              <Header.Title>Offer documents</Header.Title>
            </Header>
            {canDownloadFinalDocument && (
              <div className={classes.finalDocContainer}>
                <Link
                  bold={true}
                  onClick={downloadFinalDocument}
                  className={classes.finalDocLink}
                >
                  <DownloadIcon
                    name="download"
                    className={classes.finalDocIcon}
                  />
                  {' Final E-Signature Document '}
                </Link>
              </div>
            )}
            <List className={classes.fileList}>
              {offerFiles?.map(file => (
                <FileItem key={file.id} file={file} onDownload={downloadFile} />
              ))}
              {w9id && (
                <FileItem
                  file={{ id: w9id, name: w9name, canDownload: true }}
                  onDownload={downloadW9file}
                />
              )}
              {externalDocuments.map(file => (
                <FileItem
                  key={`external-${file.id}`}
                  file={{ ...file, canDownload: true }}
                  onDownload={downloadExternal}
                />
              ))}
            </List>
          </BoxItem>
          <BoxItem>
            <div className={classes.backLink}>
              <ReactLink to={`/projects/${projectId}/offer-documents`}>
                Back
              </ReactLink>
            </div>
          </BoxItem>
        </BoxItem>
      </Box>
    </LayoutContent>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  pushNotification,
  popNotification,
};

export default compose(
  withApi,
  connect(mapStateToProps, mapDispatchToProps),
)(Details);
