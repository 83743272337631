import moment from 'moment';
import formatCurrency from './formatCurrency';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss Z';

const formatOfferData = (offer, defaults, isCopyOffer) => {
  const { sendDateConfiguration, countryCode } = defaults;
  const isCanada = countryCode === 'CA';
  const {
    isTermsOfEmploymentV2,
    termsOfHire = {},
    allowances = {},
    termsOfEmployment = {},
    termsOfEmploymentV2 = {},
    documentFields = [],
    accountCodes = [],
    startDate,
    endDate,
    sendDate,
    keepI9,
    i9DocumentTemplateId,
    notes,
    additionalNotes = [],
  } = offer;
  let { documents = [] } = offer;
  if (keepI9 & !isCopyOffer) {
    const i9Index = documents.indexOf(i9DocumentTemplateId);
    if (i9Index > -1) {
      documents.splice(i9Index, 1);
    }
  }

  const {
    union,
    occupation,
    workSchedule,
    workState,
    hireState,
    workCity,
    hireCity,
    department,
    hiringStatus,
    fringeContract,
    ...restTermsOfHire
  } = termsOfHire || {};

  const { code: occupationCode, description: occupationDescription } =
    occupation || {};
  const { code: workStateCode, id: workStateId } = workState || {};
  const { code: hireStateCode, id: hireStateId } = hireState || {};
  const { code: workCityCode, id: workCityId } = workCity || {};
  const { code: hireCityCode, id: hireCityId } = hireCity || {};
  const { id: departmentId } = department || {};
  const {
    code: unionCode,
    description: unionDescription,
    isNonUnion: unionIsNonUnion,
  } = union || {};
  const { code: workScheduleCode, description: workScheduleDescription } =
    workSchedule || {};
  const { code: fringeContractCode, description: fringeContractDescription } =
    fringeContract || {};
  delete restTermsOfHire.__typename;

  let sendDateObject = (sendDate && moment(sendDate, DATE_FORMAT)) || null;
  const sendDateIsInPast =
    sendDateObject && sendDateObject.isBefore(moment(), 'day');
  if (sendDate && sendDateIsInPast) sendDateObject = moment();
  const isLinkActive =
    sendDateConfiguration === 'start_date' &&
    sendDateObject.isSame(moment(startDate, DATE_FORMAT));

  const formattedOfferDate = {
    startDate: moment(startDate, DATE_FORMAT).format('LL'),
    startDateObject: moment(startDate, DATE_FORMAT),
    sendDateObject: sendDateObject || sendDate,
    sendDate: (sendDate && moment(sendDate, DATE_FORMAT).format('LL')) || null,
    endDateObject: (endDate && moment(endDate, DATE_FORMAT)) || endDate,
    endDate: (endDate && moment(endDate, DATE_FORMAT).format('LL')) || null,
    isLinkActive,
  };

  const updatedAllowances = Object.entries(allowances).reduce(
    (allowancesObject, [allowanceKey, allowance]) => {
      if (allowanceKey === '__typename') return allowancesObject;
      allowancesObject[allowanceKey] = {
        ...allowance,
        amount: allowance.amount ? formatCurrency(allowance.amount) : null,
      };
      return allowancesObject;
    },
    {},
  );

  const formattedTermsOfHire = {
    ...restTermsOfHire,
    hireStateId: hireStateId,
    hireState: hireStateCode,
    workStateId: workStateId,
    workState: workStateCode,
    hireCityId: hireCityId,
    hireCity: hireCityCode,
    workCityId: workCityId,
    workCity: workCityCode,
    department: departmentId,
    hiringStatus: hiringStatus,
    occupation: {
      value: occupationCode,
      label: occupationDescription,
    },
    union: {
      value: unionCode,
      label: unionDescription,
      isNonUnion: unionIsNonUnion,
    },
    workSchedule: {
      value: workScheduleCode,
      label: workScheduleDescription,
    },
  };

  const formattedFringeTermsOfHire = {
    ...restTermsOfHire,
    hireStateId: hireStateId,
    hireState: hireStateCode,
    workStateId: workStateId,
    workState: workStateCode,
    hireCityId: hireCityId,
    hireCity: hireCityCode,
    workCityId: workCityId,
    workCity: workCityCode,
    department: departmentId,
    hiringStatus: hiringStatus,
    occupation: {
      value: occupationCode,
      label: occupationDescription,
    },
    union: {
      value: unionCode,
      label: unionDescription,
      isNonUnion: unionIsNonUnion,
    },
    workSchedule: {
      value: workScheduleCode,
      label: workScheduleDescription,
    },
    fringeContract: {
      code: fringeContractCode,
      description: fringeContractDescription,
    },
  };

  let mergedTermsOfEmployment;
  if (isTermsOfEmploymentV2 && !isCanada) {
    mergedTermsOfEmployment = {
      ...termsOfEmploymentV2,
      isTermsOfEmploymentV2: true,
    };
  } else if (isCanada) {
    mergedTermsOfEmployment = {
      ...termsOfEmployment,
      ...termsOfEmploymentV2,
    };
  } else {
    mergedTermsOfEmployment = termsOfEmployment;
  }

  const formattedTermsOfEmployment = Object.fromEntries(
    Object.entries(mergedTermsOfEmployment).map(([key, value]) => [
      key,
      key.toLowerCase().includes('rate') && value
        ? formatCurrency(value)
        : value,
    ]),
  );

  const dealNotes = [...additionalNotes];
  if (notes) dealNotes.unshift({ notes, current: true });

  return {
    offerDate: formattedOfferDate,
    termsOfHire: fringeContract
      ? formattedFringeTermsOfHire
      : formattedTermsOfHire,
    termsOfEmployment: formattedTermsOfEmployment,
    allowances: updatedAllowances,
    documents,
    documentFields,
    accountCodes,
    dealNotes,
    keepI9,
    i9DocumentTemplateId,
  };
};

export default formatOfferData;
