import DocumentModal from 'common/oldJavascripts/components/Shared/DocumentModal';

import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';

export default Relay.createClass({
  statics: {
    queries: {
      documentImages: {
        info(params) {
          const { templateId } = params;
          return {
            id: `/workflow/templates/${templateId}`,
          };
        },
      },
    },
  },

  render() {
    const { images = {} } = this.props.documentImages.data;
    const { onClose } = this.props;
    return <DocumentModal onClose={onClose} pages={images.items || []} />;
  },
});
