import React from 'react';

import { withStyles } from '@mui/styles';
import classNames from 'class-names';

const styles = {
  root: {
    position: 'fixed',
    top: '20px',
    left: '20px',
    zIndex: '300',
  },
  full: {
    top: '0',
    left: '0',
    right: '0',
  },
};

const LayoutBanner = props => {
  const { full, children, classes } = props || {};
  const rootClass = classNames(
    {
      [classes.full]: full,
    },
    classes.root,
  );
  return <div className={rootClass}>{children}</div>;
};

export default withStyles(styles)(LayoutBanner);
