import gql from 'graphql-tag';

export default gql`
  query projectTemplate($id: Int!) {
    projectTemplate(id: $id) {
      id
      name
      template {
        specialTags
        roles {
          id
          name
        }
        rules(type: "workflow") {
          id
          name
        }
      }
      workflows {
        id
        type
        rule {
          id
          name
        }
        departments {
          id
          name
        }
        overscaleConditionTriggered
        levels {
          name
          role {
            id
            name
          }
          users {
            id
            profile {
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  }
`;
