import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Button, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';

const styles = theme => ({
  spinner: {
    color: theme.palette.primary.dark,
    position: 'absolute',
    zIndex: 1,
  },
});

const DEFAULT_ERROR_MESSAGE = 'An error occurred';

const FeedbackButton = ({
  children,
  classes,
  disabled,
  onClick: upstreamOnClick,
  successMessage,
  errorMessage = DEFAULT_ERROR_MESSAGE,
  ...restOfProps
}) => {
  const [isBusy, setIsBusy] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const getSnackbarSuccessMessage = result =>
    typeof successMessage === 'function'
      ? successMessage(result)
      : successMessage;

  const showSuccessNotification = result =>
    enqueueSnackbar(getSnackbarSuccessMessage(result), {
      variant: 'success',
    });

  const getSnackbarErrorMessage = err =>
    typeof errorMessage === 'function' ? errorMessage(err) : errorMessage;

  const showErrorNotification = err =>
    enqueueSnackbar(getSnackbarErrorMessage(err), {
      variant: 'error',
    });

  const onClick = async (...args) => {
    try {
      const retVal = upstreamOnClick?.(...args);

      if (retVal?.finally) {
        setIsBusy(true);
        retVal.finally(() => setIsBusy(false));
      }

      if (successMessage) {
        if (retVal?.then) {
          retVal.then(showSuccessNotification);
        } else {
          showSuccessNotification(retVal);
        }
      }

      if (errorMessage && retVal?.catch) {
        retVal.catch(showErrorNotification);
      }

      return retVal;
    } catch (err) {
      if (errorMessage) showErrorNotification(err);
    }
  };

  return (
    <Button
      {...restOfProps}
      onClick={upstreamOnClick && onClick}
      disabled={disabled || isBusy}
    >
      {children}
      {isBusy && <CircularProgress size={24} className={classes.spinner} />}
    </Button>
  );
};

export default withStyles(styles)(FeedbackButton);
export { DEFAULT_ERROR_MESSAGE };
