import { AsYouType } from 'libphonenumber-js';

const phoneNumberInputOnChange = (
  number,
  target = {},
  key = 'phone',
  mutator = {},
) => {
  const caretStart = target.selectionStart;
  const caretEnd = target.selectionEnd;
  if (!/(.?\d){4,}/.test(number)) return mutator.update(key)(number);
  const asYouType = new AsYouType('US');
  const formattedNumber = asYouType.input(number);
  return mutator
    .update(key)(formattedNumber)
    .then(() => {
      // If editing the input from any position that is not the end
      // preserve the cursor position
      if (caretStart < number.length) {
        target.selectionStart = caretStart;
        target.selectionEnd = caretEnd;
      }
    });
};

export default phoneNumberInputOnChange;
