import React from 'react';
import STARTPLUS_LOGO from 'common/images/start-white-logo-01.svg';
import Layout from 'common/oldJavascripts/components/Base/Layout';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import { AppBar, Toolbar } from '@mui/material';
import { MyCnCHeader } from '@castandcrew/global-navbar';
import MyCnCHeaderConfig from 'common/constants/config/MyCnCHeaderConfig';
import LayoutBanner from 'common/oldJavascripts/components/Base/Layout/LayoutBanner';
import LayoutHeader from 'common/oldJavascripts/components/Base/Layout/LayoutHeader';
import MainHeader from '../../Base/MainHeader';
import GlobalMainNav from './GlobalNavbar/MainNavbar';
import MainNav from './Nav/MainNav';
import ActionsAndLogo from './GlobalNavbar/ActionsAndLogo';
import DropdownSession from './Nav/DropdownSession';
import { styled } from '@mui/system';

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  background: 'linear-gradient(180deg, #0000b3 0%, #00004c 100%)',
  paddingLeft: '0 !important',
});

const StyledAppBar = styled(AppBar)({
  zIndex: 1000,
  position: 'sticky',
});

const Base = ({
  hasSidebar,
  children,
  nonEmployee,
  employee,
  offerId,
  secondaryNav = false,
  header,
}) => {
  const flags = useFeatureFlags();
  const globalNavEnableByDefault =
    process.env.REACT_APP_ENABLE_GLOBAL_NAV_BY_DEFAULT === 'true';
  const globalNavFlag =
    flags.includes('GlobalNavbar') || globalNavEnableByDefault;

  if (globalNavFlag) {
    return (
      <Layout hasSidebar={hasSidebar}>
        <StyledAppBar>
          <MyCnCHeader configs={MyCnCHeaderConfig} />
          <StyledToolbar>
            <GlobalMainNav />
            <ActionsAndLogo
              employee={employee}
              nonEmployee={nonEmployee}
              offerId={offerId}
            />
          </StyledToolbar>
          {secondaryNav && header}
        </StyledAppBar>
        {children}
      </Layout>
    );
  }
  return (
    <Layout hasSidebar={hasSidebar}>
      <LayoutBanner />
      <LayoutHeader globalNav={globalNavFlag}>
        <MainHeader>
          <MainHeader.Logo alt="Start+ logo" src={STARTPLUS_LOGO} />
          <MainNav />
          <MainHeader.Nav secondary={true}>
            <DropdownSession />
          </MainHeader.Nav>
        </MainHeader>
      </LayoutHeader>

      {children}
    </Layout>
  );
};

export default Base;
