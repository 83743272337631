import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query downloadCrew($projectId: Int!, $token: String!) {
    project(id: $projectId) {
      id
      crewDownloadUrl(token: $token)
    }
  }
`;

const useDownloadCrew = (projectId, token) => {
  const { data, ...rest } = useQuery(QUERY, {
    variables: { projectId: parseInt(projectId, 10), token },
    skip: !token || !parseInt(projectId, 10),
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  return { data: data?.project?.crewDownloadUrl, ...rest };
};

export default useDownloadCrew;
