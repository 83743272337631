import React from 'react';
import DocumentSigner from 'mobile/components/DocumentSigner/DocumentSigner';
import withApi from 'common/hoc/withApi';
import ajax from 'common/utilities/ajax.js';
import { makeStyles } from '@mui/styles';
import { useCookies } from 'react-cookie';
import { REMEMBER_SIGNATURE_COOKIE } from 'mobile/constants';

const documentSignerMobileStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: '#f2f5f7',
  },
  busyOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(195, 195, 195, 0.8)',
    zIndex: 100000,
    display: 'grid',
    placeContent: 'center',
  },
  busyContainer: {
    textAlign: 'center',
  },
}));

const documentViewerMobileStyles = makeStyles(theme => ({
  contentContainer: {
    zIndex: 2,
    overflow: 'auto',
    background: '#eef3f6',
    marginBottom: '100px',
  },
  documentImageContainer: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '98vw',
    overflow: 'auto',
    height: '100%',
    width: '100%',
  },
  formComplete: {
    textAlign: 'center',
    color: '#333',
    padding: '15px',
  },
}));

const DocumentInteractionController = props => {
  const [cookies] = useCookies([REMEMBER_SIGNATURE_COOKIE]);
  const { [REMEMBER_SIGNATURE_COOKIE]: rememberSignature } = cookies;

  const documentSignerMobileClasses = documentSignerMobileStyles();
  const documentViewerMobileClasses = documentViewerMobileStyles();

  const {
    documents,
    documentSignerRef,
    selectedDocumentId,
    offerId,
    mobileDocsSaveHandler,
    me,
    profile: { data: profileData },
    onChangeDocuments,
  } = props;

  const { first_name, last_name, middle_name } = profileData;
  const name = [first_name, middle_name, last_name].filter(s => s).join(' ');
  const initials = [first_name, middle_name, last_name]
    .filter(s => s)
    .map(s => String(s)[0])
    .join('');
  const actor = { name, initials };

  let filteredDocs = [...documents];

  const getDocumentImageUrls = ({ documentId, page }) => {
    const ajaxQuery = page ? `?page=${page}` : '';
    const ajaxUrl = `/v2/offers/${offerId}/documents/${documentId}/images${ajaxQuery}`;
    return ajax
      .get(ajaxUrl)
      .then(({ items }) => {
        return items.map(({ url }) => url);
      })
      .catch(throwFatalError);
  };

  const throwFatalError = () => {
    // TODO: add proper error message
    console.warn('Unable to fetch document image');
  };

  // We have to update the document data because of discrepencies
  // between what the documentation outlined and what the backend sends
  const updatedDocs = filteredDocs.map(doc => ({
    ...doc,
    fieldMappings: doc.fields,
    name: doc.templateName,
    rank: 1,
  }));

  if (filteredDocs.length === 0) return <div />;

  return (
    <div data-test-id="DocumentInterationController-root">
      <DocumentSigner
        documents={updatedDocs}
        selectedDocumentId={selectedDocumentId}
        actor={actor}
        getDocumentImageUrls={getDocumentImageUrls}
        throwFatalError={throwFatalError}
        isMobile
        isMobileSignatureSaved={rememberSignature}
        mobileDocsSaveHandler={mobileDocsSaveHandler}
        mobileDocumentSignerStyles={documentSignerMobileClasses}
        mobileDocumentViewerStyles={documentViewerMobileClasses}
        me={me}
        isSaveSignatureEnabled={false}
        ref={documentSignerRef}
        onChangeDocuments={onChangeDocuments}
      />
    </div>
  );
};

DocumentInteractionController.queries = {
  profile: {
    info: () => {
      return {
        id: `/account/profile`,
      };
    },
  },
  me: {
    info() {
      return { id: '/me' };
    },
  },
};

export default withApi(DocumentInteractionController);
