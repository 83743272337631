// Utilities
import { all } from 'redux-saga/effects';

// Saga Data
import Auth from './providers/Auth';
import TokenProvider from './providers/Token';
import history from 'common/constants/config/history';

// Sagas - sorted alphabetically
import authenticate from './authentication';
import accessToken from './accessToken';
import resources from './resources';

const auth = new Auth();
const token = new TokenProvider();

export default function* root() {
  yield all([
    authenticate({ auth, history }),
    resources(),
    accessToken({ token }),
  ]);
}
