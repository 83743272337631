import React from 'react';
import { withStyles } from '@mui/styles';
// MaterialUI Components
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';

const styles = {
  summary: {
    paddingLeft: 5,
  },
  summaryContent: {
    alignItems: 'center',
    marginTop: '5px !important',
    marginBottom: '5px !important',
    marginLeft: '5px !important',
  },
  actionButtons: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  content: {
    flexDirection: 'column',
    paddingBottom: 10,
  },
  title: {
    marginLeft: 4,
  },
};

const RuleAssignmentPanel = props => {
  const {
    onClick,
    classes,
    dataTestIds,
    title,
    secondaryAction,
    isOpen,
    description,
    collapseContent,
    buttons,
  } = props;

  return (
    <Accordion
      data-test-id={dataTestIds.root}
      className={classes.root}
      expanded={isOpen}
    >
      <AccordionSummary
        onClick={onClick}
        expandIcon={<ExpandMoreIcon data-test-id={dataTestIds.listIcon} />}
        className={classes.summary}
        classes={{
          content: classes.summaryContent,
        }}
      >
        {secondaryAction}
        <Typography
          data-test-id={dataTestIds.title}
          variant="h6"
          className={classes.title}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails className={classes.content}>
        <Typography variant="subtitle1" data-test-id={dataTestIds.description}>
          {description}
        </Typography>
        {collapseContent}
      </AccordionDetails>
      <Divider />
      <AccordionActions className={classes.actionButtons}>
        {buttons.map(({ title, onClick, dataTestId, className }) => (
          <Button
            onClick={onClick}
            data-test-id={dataTestId}
            key={title}
            className={className}
          >
            {title}
          </Button>
        ))}
      </AccordionActions>
    </Accordion>
  );
};

export default withStyles(styles)(RuleAssignmentPanel);
