import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay';
import DocumentUploader from 'common/components/DocumentUploader';
import Worker from './DocumentWorker';
import { parseErrorMessages } from 'common/oldJavascripts/utils/errorMessageParser';

// We should stop the requests after 36 attempts,
// as it has been 3 minutes since upload (36 * 5 sec each = 3min)
const MAX_RETRIES = 36;

export default Relay.createClass({
  getInitialState() {
    return {
      updateTracker: {},
      updateErrors: {},
      error: null,
    };
  },

  errorHandler(response) {
    // the way the errors come down are highly varied
    const message = parseErrorMessages(response);
    return Promise.reject({ message });
  },

  // This is the code to update the template when the API finishes processing a template
  // Based on the one in DocumentReplacementWrapper
  fetchStatus(id) {
    const { updater: listUpdater } = this.props;
    const worker = Worker.createWorker();
    worker
      .get(id)
      .then(response => {
        this.setState(({ updateTracker, updateErrors }) => {
          const { status, name } = response;
          const tracker = { ...updateTracker[id] };
          tracker.attempts = tracker.attempts + 1;
          const errors = { ...updateErrors };
          // On this cases we stop the tracking
          if (
            (status !== 'processing' || tracker.attempts >= MAX_RETRIES) &&
            updateTracker[id]
          ) {
            clearInterval(updateTracker[id].intervalId);
            listUpdater(response);
            // delete the record on state
            delete updateTracker[id];
            // If its not this case, then something bad happened, we update the errors stored
            // Ideally, this should be shown in a pop-up as it is not a critical error that
            // warrants a Big Red Error Box...
            if (status !== 'processed') {
              // This error is temporal while we find a better way to handle it
              const error =
                tracker.attempts >= MAX_RETRIES
                  ? ' made too many attempts to get its status'
                  : `status: ${status}`;
              // As the WF API reports different status like secure_file,
              // opted to take all not 'processing' or 'processed' as errors
              // and store them for future use.
              errors[id] = `Template '${name} - ${error}`;
            }
            return { updateTracker, updateErrors: errors };
          }
          // Else, we just keep updating the number of attempts
          const newTracker = { ...updateTracker, [id]: tracker };
          return { updateTracker: newTracker };
        });
      })
      .catch(err => {
        this.setState({ error: err });
      });
  },

  uploadSuccessHandler(response) {
    const { updater: listUpdater } = this.props;
    const { updateTracker } = this.state;
    const templateData = response.data[0];
    listUpdater(templateData);
    const { id: templateId } = templateData;
    // Here we start a process to start requesting the data,
    // to do a req every x sec while status === 'processing'
    // all others will stop the interval.
    const intervalId = setInterval(() => {
      this.fetchStatus(templateId);
    }, 5000);
    const tracker = { status: 'requesting', attempts: 1, intervalId };
    const newTracker = { ...updateTracker, [templateId]: tracker };
    this.setState({ updateTracker: newTracker });
    return Promise.resolve(response);
  },

  editSuccessHandler(response) {
    const { updater: listUpdater } = this.props;
    listUpdater(response);
    return Promise.resolve(response);
  },

  uploadHandler(formdata) {
    const worker = Worker.createWorker();
    return worker.upload(
      formdata.get('file'),
      { multipart: true },
      this.errorHandler,
      this.uploadSuccessHandler,
    );
  },

  editHandler(data) {
    const worker = Worker.createWorker();
    const update = {
      templateId: data.fileId,
      file: {
        categories: data.tags,
        name: data.name,
      },
    };
    return worker.edit(
      update,
      { json: true },
      this.errorHandler,
      this.editSuccessHandler,
    );
  },

  render() {
    return (
      <DocumentUploader
        uploadHandler={this.uploadHandler}
        editHandler={this.editHandler}
      />
    );
  },
});
