import React from 'react';

import Table from 'common/oldJavascripts/components/Base/Table';
import Select from 'common/oldJavascripts/components/Base/Select';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from '@mui/styles';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
const LABELS = {
  box_rental: 'Box rental',
  computer_rental: 'Computer rental',
  car_allowance: 'Car allowance',
  mobile_phone: 'Mobile phone',
  per_diem_allowance: 'Per Diem',
  housing_allowance: 'Housing allowance',
};

const PER_OPTIONS = [
  { value: 'day', label: 'Day' },
  { value: 'week', label: 'Week' },
];

const ReimbursementRow = ({ type, data, onChange, errors = {} }) => {
  const { amount, per, cap, duration } = data || {};
  const flags = useFeatureFlags();
  const isDurationFieldEnabled = flags?.includes('AllowanceDurationField');
  const handleChange = (key, value) => onChange(type, key, value);

  const parseFloatValue = val => (parseFloat(val) > 0 ? parseFloat(val) : null);
  const parseIntValue = val => (parseInt(val) > 0 ? parseInt(val) : null);

  const handleClear = () => {
    for (const key in data) {
      handleChange(key, null);
    }
  };

  return (
    <Table.Row>
      <Table.Cell data-test-id={`ReimbursementsRow-title-${type}`}>
        {LABELS[type]}
      </Table.Cell>
      <Table.EditableCell
        value={amount}
        onChange={val => handleChange('amount', parseFloatValue(val))}
        ignoreZero={true}
        format="currency"
        align="right"
        minValueWarning={0}
        maxValueWarning={999999}
        maxLength={7}
        isCurrency={true}
        label=""
        errors={errors?.amount}
        data-test-id={`ReimbursementsRow-amount-${type}`}
      />
      <Table.Cell allowOverflow data-test-id={`ReimbursementsRow-per-${type}`}>
        <Select
          options={PER_OPTIONS}
          placeholder={'Choose'}
          value={per}
          onChange={val => handleChange('per', val)}
          isInvalid={(errors?.per || []).length > 0}
          searchable={false}
        />
      </Table.Cell>
      <Table.EditableCell
        value={cap}
        onChange={val => handleChange('cap', parseFloatValue(val))}
        ignoreZero={true}
        format="currency"
        align="right"
        minValueWarning={Number(amount)}
        maxValueWarning={9999999}
        label=""
        maxLength={9}
        isCurrency={true}
        errors={errors?.cap}
        data-test-id={`ReimbursementsRow-cap-${type}`}
      />
      {!isDurationFieldEnabled && (
        <Table.EditableCell
          value={duration}
          onChange={val => handleChange('duration', parseIntValue(val))}
          ignoreZero={true}
          format="numeric"
          fractionalDigits={0}
          maxValueWarning={999}
          align="center"
          label=""
          maxLength={3}
          errors={errors?.duration}
          data-test-id={`ReimbursementsRow-duration-${type}`}
        />
      )}
      <ClearRow allowance={data} onClear={handleClear} />
    </Table.Row>
  );
};

export default ReimbursementRow;

const styles = makeStyles({
  clearCell: {
    padding: '10px 20px',
    verticalAlign: 'middle',
  },
  icon: {
    cursor: 'pointer',
  },
});

const ClearRow = ({ allowance, onClear }) => {
  const classes = styles();
  const showButton = allowance
    ? delete allowance.__typename &&
      Object.values(allowance).some(value => value)
    : false;

  return (
    <td className={classes.clearCell}>
      {showButton ? (
        <ClearIcon className={classes.icon} onClick={() => onClear()} />
      ) : null}
    </td>
  );
};
