import React, { Component } from 'react';
import Field from '../Base/Field';
import qs from 'qs';
import { withRouter } from 'react-router';
import T from '../../utils/i18n';
import history from 'common/constants/config/history';

class Search extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    const { search } = location;
    const { q: query = '' } = qs.parse(search, { ignoreQueryPrefix: true });

    this.state = {
      searchText: props.searchText || query,
      lastSubmittedValue: null,
    };
  }

  render() {
    return (
      <span data-test-id="Search-root">
        <Field
          label={T('form.search_label')}
          placeholder={T('form.search_placeholder')}
          type="search"
          inline={true}
          hideLabel={true}
          value={this.state.searchText}
          onChange={this._updateSearchText}
          onSubmitSearch={this._submitSearch}
        />
      </span>
    );
  }

  _updateSearchText = text => {
    this.setState({ searchText: text });
  };

  _submitSearch = () => {
    const { location } = this.props;
    const { searchText, lastSubmittedValue } = this.state;
    const { pathname, search } = location || {};
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    // Avoid potential double submit by clicking enter and triggering
    // blur event when field loses focus
    if (searchText === lastSubmittedValue) {
      return;
    }

    this.setState({ lastSubmittedValue: searchText });

    if (this.props.onSubmitSearch) {
      this.props.onSubmitSearch(query);
      return;
    }
    history.push({
      pathname,
      search: qs.stringify({
        ...query,
        q: searchText,
        page: 1,
      }),
    });
  };
}

export default withRouter(Search);
