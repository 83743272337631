import React, { useState } from 'react';
import { Autocomplete, Chip, TextField } from '@mui/material';

const ChipInputWithAutosuggest = props => {
  const { suggestionSource, value = [], onChange } = props;
  const [textFieldInput, setTextFieldInput] = useState('');

  const removeChip = chipIndex => {
    onChange(value.filter((_, index) => index !== chipIndex));
  };

  return (
    <Autocomplete
      {...props}
      multiple
      options={suggestionSource}
      renderOption={(props, option) => (
        <li
          {...props}
          data-test-id={`ChipInputWithAutosuggest-option-${option.name}`}
        >
          {option.name}
        </li>
      )}
      getOptionLabel={option => option.name}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      inputValue={textFieldInput}
      onInputChange={(event, newInputValue) => setTextFieldInput(newInputValue)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            label={option.name}
            onDelete={() => removeChip(index)}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={params => <TextField {...params} variant="standard" />}
    />
  );
};

export default ChipInputWithAutosuggest;
