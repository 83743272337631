import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import WarningIcon from '@mui/icons-material/Warning';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    top: '70px !important',
  },
  alertRoot: {
    color: '#000 !important',
    backgroundColor: '#FFF8E1 !important',
    minWidth: '50%',
    padding: '10px 20px 10px 10px !important',
    position: 'relative',
  },
  icon: {
    color: '#FFBF00 !important',
    marginLeft: 4,
  },
  action: {
    color: '#000000',
    alignItems: 'baseline !important',
    paddingTop: 7,
  },
  message: {
    fontSize: 14,
    overflowWrap: 'anywhere',
    '& b': {
      fontWeight: 700,
    },
  },
  bar: {
    width: 7,
    height: '100%',
    background: '#FFBF00',
    position: 'absolute',
    left: 0,
    top: 0,
    borderRadius: 2,
  },
});

const RejectI9Alert = ({ message = '' }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      classes={{ root: classes.root }}
    >
      <MuiAlert
        elevation={6}
        icon={<WarningIcon color="warning" />}
        onClose={handleClose}
        severity="warning"
        classes={{
          root: classes.alertRoot,
          icon: classes.icon,
          action: classes.action,
          message: classes.message,
        }}
      >
        <span className={classes.bar} />
        <b>Note from I9 Verifier: </b>
        <span>{message}</span>
      </MuiAlert>
    </Snackbar>
  );
};

export default RejectI9Alert;
