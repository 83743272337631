export const newRole = (newId, index) => ({
  name: `New Group - ${index + 1}`,
  id: `${newId}`,
  fieldsMapped: 0,
});

// Add number of fields mapped to each role
export const addFieldsMapped = (fields, roles) =>
  roles.map(role => ({
    ...role,
    fieldsMapped: fields.filter(({ fieldGroupId }) => fieldGroupId === role.id)
      .length,
  }));
