import React, { useEffect, useState } from 'react';

import TextareaAutosize from '@mui/material/TextareaAutosize';
import DialogContent from '@mui/material/DialogContent';

import ActionButtons from './ActionButtons';

import helperFunctions from './helpers';

const AddNote = ({ classes, onNext, data }) => {
  const [note, setNote] = useState('');

  useEffect(() => {
    if (data?.note) {
      setNote(data.note);
    }
  }, [data]);

  // next screen - confirm documents
  const handleNext = () => {
    onNext('confirm', { note });
  };

  // back to previous screen - custom questions
  const handleBack = () => {
    let step = 'questions';
    const questions = helperFunctions.questionsCheck(data?.documents);
    if (!questions) step = 'documents';
    onNext(step, { note });
  };

  const handleNote = value => {
    if (value.length <= 500) {
      setNote(value);
    }
  };

  return (
    <>
      <DialogContent data-test-id="note-section">
        <TextareaAutosize
          className={classes.noteTextArea}
          minRows={8}
          aria-label="maximum height"
          placeholder="You may add a short note explaining why additional documents are being sent to them."
          value={note}
          onChange={e => handleNote(e.target.value)}
          data-test-id="send-documents-note"
        />
        <p className={classes.noteLength}>{note.length}/500</p>
      </DialogContent>
      <ActionButtons
        onBack={handleBack}
        onNext={handleNext}
        classes={classes}
      />
    </>
  );
};

export default AddNote;
