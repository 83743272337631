import React from 'react';
import { withStyles } from '@mui/styles';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import CheckIcon from 'common/images/CheckIcon.svg';
import CurrentStepIcon from 'common/images/CurrentStepIcon.svg';
import PendingIcon from 'common/images/PendingIcon.svg';
import { styled } from '@mui/system';
import { Stepper, Step, StepLabel, Typography } from '@mui/material';
import withOnboardingStatus from 'onboarding/hoc/withOnboardingStatus';

const styles = {
  SubNavbar: {
    background: '#ffffff',
    padding: '10px 0',
    position: 'relative',
    zIndex: 1,
  },
  stepTitle: {
    color: '#182230',
    textAlign: 'center',
  },
  root: {
    background: 'transparent',
    padding: 0,
    marginTop: 4,
  },
  lineRoot: {
    top: '6px',
    left: '-50%',
    right: 0,
    width: '100%',
    zIndex: 1,
    position: 'absolute',
  },
  connectorLine: {
    transition: 'none',
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: '#79c2d5',
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: '#79c2d5',
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: 'white',
    },
  },
  iconRoot: {
    color: 'white',
    fontSize: '12px',
    zIndex: 2,
  },
  alternativeLabel: {
    marginTop: '0 !important',
    color: '#79c2d5',
    fontWeight: 700,
  },
  completedLabel: {
    color: 'white !important',
    fontWeight: '700 !important',
  },
  activeLabel: {
    color: '#79c2d5 !important',
    fontWeight: '700 !important',
  },
  iconDisabled: {
    color: '#79c2d5',
    fontSize: '12px',
    zIndex: 2,
  },
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#155EEF',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#155EEF',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#155EEF',
  }),
}));

const QontoStepIcon = props => {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <img src={CheckIcon} alt="check icon" />
      ) : active ? (
        <img src={CurrentStepIcon} alt="in progress" />
      ) : (
        <img src={PendingIcon} alt="pending" />
      )}
    </QontoStepIconRoot>
  );
};

const OnboardingStepper = ({
  classes,
  currentOnboardingStepIndex,
  onboardingSteps,
}) => {
  if (onboardingSteps.length === 0 || currentOnboardingStepIndex === -1) {
    return null;
  }
  return (
    <div data-test-id="OnboardingStepper-root" className={classes.SubNavbar}>
      <Typography variant="subtitle2" className={classes.stepTitle}>
        {onboardingSteps[currentOnboardingStepIndex].label}
      </Typography>
      <Stepper
        className={classes.root}
        activeStep={currentOnboardingStepIndex + 1}
        alternativeLabel
        connector={<QontoConnector />}
      >
        {onboardingSteps.map((step, index) => (
          <Step key={step.name}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              classes={{
                alternativeLabel: classes.alternativeLabel,
                active: classes.activeLabel,
                completed: classes.completedLabel,
              }}
            />
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default withStyles(styles)(withOnboardingStatus(OnboardingStepper));
