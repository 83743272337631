import React from 'react';

// COMPONENTS
import AllowancesIcon from 'common/oldJavascripts/components/Shared/Icons/AllowancesIcon';
import Link from 'common/oldJavascripts/components/Base/Link';
import Text from 'common/oldJavascripts/components/Base/Text';

// HELPERS
import format from 'common/oldJavascripts/utils/format.js';
import T from 'common/oldJavascripts/utils/i18n';

// MUI
import {
  Build as BoxRentalIcon,
  Computer as ComputerRentalIcon,
  DirectionsCar as CarIcon,
  Smartphone as PhoneIcon,
  LocalDining as PerDiemIcon,
  LocalHotel as HousingIcon,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

// TABLE COMPONENTS
import ExpandableTableRowHeader from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowHeader';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableRowInfoItem from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowInfoItem';
import ExpandableTableRowInfoItemData from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowInfoItemData';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

const Row = ExpandableTableRow;
const Cell = ExpandableTableCell;
const RowHeader = ExpandableTableRowHeader;
const RowInfoItem = ExpandableTableRowInfoItem;
const RowInfoItemData = ExpandableTableRowInfoItemData;

const ICONS = {
  boxRentalAllowance: <BoxRentalIcon />,
  computerRentalAllowance: <ComputerRentalIcon />,
  carAllowance: <CarIcon />,
  mobilePhoneAllowance: <PhoneIcon />,
  perPiemAllowance: <PerDiemIcon />,
  housingAllowance: <HousingIcon />,
};

const useStyles = makeStyles(theme => ({
  container: {
    border: '2px solid #e6e7ea',
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
}));

const Allowances = props => {
  const classes = useStyles();
  const { offer, colSpan, renderIconComponent = false, onActionClick } = props;
  const { allowances = {} } = offer;
  const emptyText = T('N/A');
  const runOfShow = T('Run of Show');
  const flags = useFeatureFlags();
  const isDurationFieldEnabled = flags?.includes('AllowanceDurationField');

  const shouldShowAllowances = () => {
    const offerAllowance = allowances || {};
    return (
      parseFloat(offerAllowance?.boxRentalAllowance.amount) ||
      parseFloat(offerAllowance?.mobilePhoneAllowance.amount) ||
      parseFloat(offerAllowance?.carAllowance.amount) ||
      parseFloat(offerAllowance?.perDiemAllowance.amount) ||
      parseFloat(offerAllowance?.housingAllowance.amount) ||
      parseFloat(offerAllowance?.computerRentalAllowance.amount)
    );
  };

  const renderIcon = () => {
    if (shouldShowAllowances()) {
      if (onActionClick) {
        return (
          <Link
            onClick={onActionClick}
            plain={true}
            data-test-id={`AllowancesRow-hasAllowances-${offer.id}`}
          >
            <AllowancesIcon />
          </Link>
        );
      }

      return (
        <span data-test-id={`AllowancesRow-hasAllowances-${offer.id}`}>
          <AllowancesIcon />
        </span>
      );
    }

    return (
      <Text data-test-id={`AllowancesRow-noAllowances-${offer.id}`}>
        {T('-')}
      </Text>
    );
  };

  const renderRow = () => {
    let hasAllowance = false;
    const mappedAllowances = Object.entries(allowances)
      .filter(([key]) => key !== '__typename')
      .map(([key, value]) => {
        let amount = value?.amount;
        let perOutput = null;
        let capOutput = null;
        let perDuration = null;
        let duration = null;
        let durationOutput = null;

        amount = amount && amount > 0.0 ? format.currency(amount) : null;

        if (amount) {
          if (!Number(value?.per)) {
            perDuration = value?.per;
            if (perDuration) {
              perOutput = '/' + perDuration;
            }
          }

          capOutput = (
            <RowInfoItemData>
              {T('Cap')} {format.currency(value?.cap) || emptyText}
            </RowInfoItemData>
          );

          if (Number(value?.duration)) {
            if (perDuration) {
              perDuration = T('shared.' + perDuration, {
                count: value?.duration,
              });
            }

            duration = value?.duration + ' ' + (perDuration || '');

            durationOutput = (
              <RowInfoItemData>
                {T('Duration')}
                {': '}
                {duration || runOfShow}
              </RowInfoItemData>
            );
          }
          hasAllowance = true;
        }

        return (
          <RowInfoItem key={'allowance-item-' + key}>
            {ICONS[key]}
            <span className="expandableTable-rowInfo-grouped">
              <RowInfoItemData important={true}>
                {amount || emptyText}
                {perOutput}
              </RowInfoItemData>
              {capOutput}
              {!isDurationFieldEnabled ? durationOutput : null}
            </span>
          </RowInfoItem>
        );
      });

    if (hasAllowance) {
      return (
        <Row rowExpanded={true}>
          <Cell colSpan={colSpan} expanded={true}>
            <RowHeader>Allowances / Reimbursements</RowHeader>
            <div className={classes.container}>{mappedAllowances}</div>
          </Cell>
        </Row>
      );
    } else {
      return <Row />;
    }
  };

  // pass in renderIcon prop to render the allowances icon used in the table
  // if not passed in it renders the allowances row when you click on an offer
  return <>{renderIconComponent ? renderIcon() : renderRow()}</>;
};

export default Allowances;
