const nonUnionV1 = termsV1 => {
  return {
    rate_per_day_studio: termsV1?.dailyRate || null,
    rate_per_week_studio:
      termsV1?.studioRatePerWeek || termsV1?.weeklyRate || null,
    guaranteed_days_studio: termsV1?.numberOfDaysPerWorkWeek || null,
    rate_per_hour_studio:
      termsV1?.studioRatePerHour || termsV1?.ratePerHour || null,
    overtime_rate: termsV1.overtimeRatePerHour || null,
    guaranteed_hours_studio: termsV1.studioGuaranteedHours || null,
  };
};

export default nonUnionV1;
