import React, { useState } from 'react';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

import classnames from 'classnames';
import CrewField from './CrewField';
import ConfirmButton from './ConfirmButton';
import ErrorIndicator from './ErrorIndicator';

const FieldsConfirm = ({
  onClose,
  classes,
  onNext,
  data,
  user,
  documentId,
}) => {
  const { fields, note } = data;
  const { firstName: userName } = user;

  const [error, setError] = useState(null);

  const handleError = err => {
    setError(err);
  };

  // back to previous screen - fields
  const hnadleBack = () => {
    onNext('note', { note });
  };

  return (
    <>
      <DialogContent data-test-id="confirm-fields-section">
        <p className={classes.confirmSubHeader}>
          Are you sure you want to reject the I-9 back to{' '}
          {userName.toUpperCase()} to make these corrections:
        </p>
        <div className={classes.listWrapper}>
          {fields &&
            fields.map(field => {
              return (
                field.checked && (
                  <CrewField
                    preview={true}
                    key={field.id}
                    data={field}
                    classes={classes}
                  />
                )
              );
            })}
        </div>
        <p className={classes.confirmNote}>
          Note to {userName.toUpperCase()}:
          <span data-test-id="preview-note">{note || ''}</span>
        </p>
      </DialogContent>
      {error && (
        <ErrorIndicator message="Oops! Something went wrong. Please try after some time or contact support" />
      )}
      <DialogActions>
        <Button
          onClick={() => hnadleBack()}
          className={classnames(classes.btn, classes.btnOutlined)}
          variant="outlined"
          color="primary"
        >
          Back
        </Button>
        <ConfirmButton
          data={data}
          classes={classes}
          onError={handleError}
          onClose={onClose}
          documentId={documentId}
        />
      </DialogActions>
    </>
  );
};

export default FieldsConfirm;
