import React, { Component } from 'react';
// import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import {
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import DocumentRank from './DocumentRank';
import CountPlaceholder from './CountPlaceholder';

import classNames from 'classnames';
import palette from 'common/shared/oldDocumentSignerUI/palette';

const styles = theme => ({
  heading: {
    flex: 1,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    paddingTop: 12,
  },
  activeSwitch: {
    cursor: 'pointer',
    width: 70,
  },
  switchControl: {
    textTransform: 'capitalize',
    flexFlow: 'row-reverse',
    margin: '0 8px',
    width: '100%',
  },
  switchRoot: {
    margin: '0 8px',
  },
  deleteContainer: {
    width: 70,
  },
  switchButton: {
    backgroundColor: `${palette.primary.main} !important`,
    color: `${palette.primary.main} !important`,
  },
});

class DocumentHeader extends Component {
  state = {
    rankEditMode: false,
  };

  onRankClick = ev => {
    this.setState({ rankEditMode: true });
    ev.stopPropagation();
    ev.preventDefault();
  };

  onRankSubmit = rank => {
    const { updateRank } = this.props;
    this.setState({ rankEditMode: false }, () => updateRank(rank));
  };

  toggleStatus = ev => {
    const { id, isActive, updateDoc } = this.props;
    const value = !isActive;
    const key = 'active';
    const update = true;
    updateDoc({ id, value, key, update });
    ev.stopPropagation();
    ev.preventDefault();
  };

  deleteDoc = ev => {
    const { id, deleteDoc: upstreamDeleteDoc } = this.props;
    upstreamDeleteDoc(id);
    ev.stopPropagation();
    ev.preventDefault();
  };

  render() {
    const {
      classes,
      id,
      isActive,
      isDeleting,
      isUpdatingRank,
      name,
      rank,
      counts,
    } = this.props;
    const { rankEditMode } = this.state;
    const {
      workflowsCount,
      studioPlusDirectoriesCount,
      autoAssignmentRulesCount,
    } = counts;

    return (
      <React.Fragment>
        <Typography className={classes.heading}>{name}</Typography>
        <CountPlaceholder studioPlus={studioPlusDirectoriesCount} />
        <CountPlaceholder rules={autoAssignmentRulesCount} />
        <CountPlaceholder workFlows={workflowsCount} />
        <DocumentRank
          rank={rank}
          editMode={rankEditMode}
          onRankClick={this.onRankClick}
          handleSubmit={this.onRankSubmit}
          isUpdatingRank={isUpdatingRank}
          id={id}
        />
        <FormGroup onClick={this.toggleStatus} className={classes.activeSwitch}>
          <FormControlLabel
            control={
              <Switch
                checked={isActive}
                classes={{
                  root: classes.switchRoot,
                  iconChecked: classes.switchButton,
                  bar: classNames({ [classes.switchButton]: isActive }),
                }}
                data-test-id={`DocumentHeader-switch-${id}`}
                color="secondary"
              />
            }
            label={isActive ? 'active' : 'inactive'}
            className={classes.switchControl}
          />
        </FormGroup>
        <div className={classes.deleteContainer}>
          <IconButton
            disabled={`${isDeleting}` === `${id}`}
            component="div"
            tabIndex="-1"
            aria-hidden="true"
            onClick={this.deleteDoc}
            data-test-id={`DocumentHeader-delete-${id}`}
          >
            {`${isDeleting}` === `${id}` ? (
              <CircularProgress size={24} />
            ) : (
              <DeleteIcon />
            )}
          </IconButton>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DocumentHeader);
