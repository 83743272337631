import func from '../function.js';

export default function(child, parent) {
  var checkFn = function(el) {
    if (func.isFunction(parent)) {
      return parent.call(null, el);
    } else {
      return parent === el;
    }
  };

  while (child) {
    if (checkFn(child)) {
      return true;
    }
    child = child.parentNode;
  }

  return false;
}
