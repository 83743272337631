import React from 'react';
import withApi from 'common/hoc/withApi';
import { Typography, Collapse } from '@mui/material';
import {
  InsertDriveFile as InsertDriveFileIcon,
  Visibility as VisibilityIcon,
  ChevronRight as MoreIcon,
  KeyboardArrowDown as LessIcon,
} from '@mui/icons-material';
import PreLoader from 'mobile/components/PreLoader';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { INPROGRESS, WAITING } from 'common/utilities/constants/documentStatus';
import useOfferDocuments from 'common/components/OfferDocumentsList/hooks/useOfferDocuments';

const documentName = {
  color: '#0000FF',
  fontSize: 14,
  fontWeight: 400,
  marginLeft: '20px',
};
const iconSize = {
  width: 24,
  height: 24,
};
const offerDocumentsStyles = makeStyles(theme => ({
  documentName: {
    ...documentName,
  },
  grayedDocumentName: {
    ...documentName,
    color: '#999999',
  },
  checkMarkIcon: {
    color: '#2ecc71',
    ...iconSize,
  },
  grayedCheckMark: {
    color: '#999999',
    ...iconSize,
  },
  eyeIcon: {
    color: '#aaaaaa',
    ...iconSize,
  },
  noDocs: {
    fontSize: '18px',
    color: '#999999',
    textAlign: 'center',
    paddingTop: '90px',
  },
  documentTitle: {
    color: '#646464',
    margin: '15px 0px !important',
  },
  parentDocumentActive: {
    backgroundColor: '#86A6FD',
    borderRadius: '2px',
  },
  parentDocument: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: '5px',
    paddingLeft: '10px',
  },
  pendingDoc: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    cursor: 'pointer',
    background: 'none',
    outline: 'none',
    border: 'none',
    padding: 0,
  },
  pendingDocName: {
    display: 'flex',
    marginRight: '20px',
    textAlign: 'left',
    width: '75%',
  },
  doc: {
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'auto',
    background: 'none',
    outline: 'none',
    border: 'none',
    padding: 0,
  },
  statusHeading: {
    color: 'black',
    marginBottom: '10px !important',
    display: 'flex',
  },
  statusHeadingPending: {
    color: 'black',
    fontWeight: '600',
    width: '75%',
  },
  statusHeadingApprover: {
    color: 'black',
    fontWeight: '600',
    width: '25%',
    textAlign: 'center',
  },
  blue: {
    color: '#0000B3',
  },
  approvedCount: {
    backgroundColor: '#C1F4CC',
    color: '#008006',
    padding: '1px 6px',
    borderRadius: '2px',
    fontSize: '12px',
    marginLeft: '2px',
  },
  previewIcon: {
    marginLeft: '5px',
    height: 20,
    cursor: 'pointer',
    paddingRight: '3px',
  },
  documentDivider: {
    margin: '10px 0px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  rejectedColor: {
    backgroundColor: '#F69493',
    color: '#C10004',
  },
  pendingApprovalCOlor: {
    backgroundColor: '#FFB262',
    color: '#B73C11',
  },
  supportingClolor: {
    backgroundColor: '#BECFFE',
    color: '#0000FF',
  },
  zeroCountColor: {
    backgroundColor: '#E0E0E0 !important',
    color: '#6F6F6F !important',
  },
  supParentDocument: {
    margin: '0px 5px',
    paddingLeft: '10px',
  },
  mainDocName: {
    fontWeight: '500',
  },
  supDocName: {
    marginLeft: '12px !important',
    color: '#c6c6c6',
  },
}));

const OfferDocuments = props => {
  const classes = offerDocumentsStyles();
  const {
    handleDocumentPreview,
    offerStatus: status,
    keepI9 = false,
    params: { offerId },
  } = props;

  const [open, setOpen] = React.useState('');

  const { data: offer, loading: isLoading } = useOfferDocuments(
    parseInt(offerId),
  );
  const { documentDetails = [], removedDocuments = [] } = offer || {};

  const documents = [...documentDetails, ...removedDocuments];

  if (isLoading) {
    return <PreLoader />;
  }

  const approvedDocuments = documents?.filter(
    doc =>
      doc?.status === 'approved' &&
      (status !== 'rejected' || keepI9) &&
      doc?.id,
  );
  const rejectedDocuments = documents?.filter(
    doc =>
      doc?.id &&
      (doc?.status === 'rejected' || (status === 'rejected' && !keepI9)),
  );
  const pendingDocuments = documents?.filter(
    doc =>
      doc?.status !== 'approved' &&
      doc?.status !== 'rejected' &&
      (status !== 'rejected' || keepI9) &&
      doc?.id,
  );

  const modifiedSupportingDocuments = documents.map(doc => {
    const { supportingDocuments, name } = doc;
    if (supportingDocuments?.length > 0) {
      return { name, supportingDocuments };
    } else {
      return null;
    }
  });

  const getMainDocumentId = supportingDocumentId => {
    const results = documents.find(obj =>
      obj.supportingDocuments.find(doc => doc.id === supportingDocumentId),
    );
    const { id: mainDocumentId } = results || {};
    return mainDocumentId;
  };

  const approvedDocumentsList = approvedDocuments?.map(doc => {
    const docClass = classNames([classes.parentDocument]);
    return (
      <div key={doc.id} className={docClass}>
        <button className={classes.doc}>
          <Typography type="body">{doc?.name}</Typography>
        </button>
        {(doc?.isViewable || doc?.isW9 || doc?.isExternal) && (
          <VisibilityIcon
            onClick={() => handleDocumentPreview(doc.id)}
            className={classes.previewIcon}
          />
        )}
      </div>
    );
  });

  const pendingDocumentsList = pendingDocuments?.map(doc => {
    const { supportingDocuments: supportDocs = [] } = doc;
    const docStatus = doc?.workflow;
    const approverLevel = docStatus?.find(
      approvalStep =>
        approvalStep?.status === INPROGRESS || approvalStep?.status === WAITING,
    );
    const docClass = classNames([classes.parentDocument]);
    const docNameClass = classNames({
      [classes.docName]: supportDocs?.length > 0,
      [classes.docNameSpace]: supportDocs?.length === 0,
    });
    return (
      <div key={doc.id}>
        <div className={docClass}>
          <button className={classes.pendingDoc}>
            <div className={classes.pendingDocName}>
              {supportDocs?.length > 0 && (
                <InsertDriveFileIcon className={classes.blue} />
              )}
              <Typography className={docNameClass} type="body">
                {doc?.name}
              </Typography>
              {(doc?.isViewable || doc?.isW9) && (
                <VisibilityIcon
                  onClick={() => handleDocumentPreview(doc.id)}
                  className={classes.previewIcon}
                />
              )}
            </div>
            <Typography className={classes.docLevelName} type="body">
              {approverLevel?.levelName}
            </Typography>
          </button>
        </div>
      </div>
    );
  });

  const rejectedDocumentsList = rejectedDocuments?.map(doc => {
    const { supportingDocuments: supportDocs = [] } = doc;
    const docClass = classNames([classes.parentDocument]);
    const docNameClass = classNames({
      [classes.docName]: supportDocs?.length > 0,
      [classes.docNameSpace]: supportDocs?.length === 0,
    });
    return (
      <div key={doc.id}>
        <div className={docClass}>
          <button className={classes.doc}>
            {supportDocs?.length > 0 && (
              <InsertDriveFileIcon className={classes.blue} />
            )}
            <Typography type="body" className={docNameClass}>
              {doc?.name}
            </Typography>
          </button>
          {(doc?.isViewable || doc?.isW9) && (
            <VisibilityIcon
              onClick={() => handleDocumentPreview(doc.id)}
              className={classes.previewIcon}
            />
          )}
        </div>
      </div>
    );
  });

  const supportingDocumentsList = modifiedSupportingDocuments
    .filter(doc => doc)
    .map(doc => {
      const docClass = classNames([classes.supParentDocument]);
      const { supportingDocuments, name } = doc;
      if (!supportingDocuments) {
        return null;
      }
      return (
        <div className={docClass}>
          <p className={classes.mainDocName}>{name}</p>
          {supportingDocuments.map(supportingDoc => {
            return (
              <>
                <Typography type="body" className={classes.supDocName}>
                  {supportingDoc?.name}
                </Typography>
                {(doc?.isViewable || doc?.isW9) && (
                  <VisibilityIcon
                    onClick={() =>
                      handleDocumentPreview(
                        getMainDocumentId(supportingDoc?.id),
                        supportingDoc?.id,
                      )
                    }
                    className={classes.previewIcon}
                    data-test-id={`"DocumentList-Offerdocuments-eyeIcon-"${supportingDoc?.id}`}
                  />
                )}
              </>
            );
          })}
        </div>
      );
    });

  // common collapse function for all the list
  const handleCollapse = type => {
    setOpen(prev => {
      if (prev === type) {
        return false;
      }
      return type;
    });
  };

  const renderApprovedList = () => {
    const listLength = approvedDocumentsList?.length;
    return (
      <div className={classes.documentDivider}>
        <Typography
          className={classes.statusHeading}
          fontWeight={600}
          fontSize={14}
          onClick={() => handleCollapse('approved')}
        >
          {listLength > 0 ? (
            open === 'approved' ? (
              <LessIcon className="expansion-icons" />
            ) : (
              <MoreIcon className="expansion-icons" />
            )
          ) : (
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          )}
          Approved/Completed{' '}
          <span
            className={classNames(
              classes.approvedCount,
              listLength === 0 && classes.zeroCountColor,
            )}
          >
            {' '}
            {listLength}
          </span>
        </Typography>
        <Collapse in={open === 'approved'} timeout="auto">
          {approvedDocumentsList}
        </Collapse>
      </div>
    );
  };

  const renderRejectList = () => {
    const listLength = rejectedDocumentsList?.length;
    return (
      <div className={classes.documentDivider}>
        <Typography
          className={classes.statusHeading}
          fontWeight={600}
          fontSize={14}
          onClick={() => handleCollapse('rejected')}
        >
          {listLength > 0 ? (
            open === 'rejected' ? (
              <LessIcon className="expansion-icons" />
            ) : (
              <MoreIcon className="expansion-icons" />
            )
          ) : (
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          )}
          Rejected{' '}
          <span
            className={classNames(
              classes.approvedCount,
              classes.rejectedColor,
              listLength === 0 && classes.zeroCountColor,
            )}
          >
            {' '}
            {listLength}
          </span>
        </Typography>
        <Collapse in={open === 'rejected'} timeout="auto">
          {rejectedDocumentsList}
        </Collapse>
      </div>
    );
  };

  const renderPendingApprovalList = () => {
    const listLength = pendingDocumentsList?.length;
    return (
      <div className={classes.documentDivider}>
        <Typography
          className={classes.statusHeading}
          fontWeight={600}
          fontSize={14}
          onClick={() => handleCollapse('pending')}
        >
          {listLength > 0 ? (
            open === 'pending' ? (
              <LessIcon />
            ) : (
              <MoreIcon />
            )
          ) : (
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          )}
          Pending{' '}
          <span
            className={classNames(
              classes.approvedCount,
              classes.pendingApprovalCOlor,
              listLength === 0 && classes.zeroCountColor,
            )}
          >
            {' '}
            {listLength}
          </span>
        </Typography>
        <Collapse in={open === 'pending'} timeout="auto">
          {pendingDocumentsList}
        </Collapse>
      </div>
    );
  };

  const renderSupportingList = () => {
    const listLength = supportingDocumentsList?.length;
    return (
      <div className={classes.documentDivider}>
        <Typography
          className={classes.statusHeading}
          fontWeight={600}
          fontSize={14}
          onClick={() => handleCollapse('supporting')}
        >
          {listLength > 0 ? (
            open === 'supporting' ? (
              <LessIcon />
            ) : (
              <MoreIcon />
            )
          ) : (
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          )}
          Supporting docs{' '}
          <span
            className={classNames(
              classes.approvedCount,
              classes.supportingClolor,
              listLength === 0 && classes.zeroCountColor,
            )}
          >
            {' '}
            {listLength}
          </span>
        </Typography>
        <Collapse in={open === 'supporting'} timeout="auto">
          {supportingDocumentsList}
        </Collapse>
      </div>
    );
  };

  return (
    <div>
      <Typography
        variant="h2"
        fontWeight={400}
        fontSize={18}
        className={classes.documentTitle}
      >
        Documents
      </Typography>
      {renderApprovedList()}
      {renderPendingApprovalList()}
      {renderRejectList()}
      {renderSupportingList()}
    </div>
  );
};

export default withApi(OfferDocuments);
