import gql from 'graphql-tag';

export default gql`
  query producers {
    producers {
      id
      name
      cncId
    }
  }
`;
