import isBlank from './isBlank';

export const isFormComplete = (formData, requiredFields) => {
  if (formData) {
    return requiredFields.every(field => !isBlank(formData[field]));
  }
  return true;
};

export default { isFormComplete };
