import React from 'react';
import { withStyles } from '@mui/styles';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import Preloader from 'common/oldJavascripts/components/Shared/Preloader';
import Text from 'common/oldJavascripts/components/Base/Text';
import Flexbox from 'flexbox-react';
import { Stepper, Step, createTheme, ThemeProvider } from '@mui/material';

const styles = {
  packetText: {
    padding: '15px 24px 0 24px',
  },
};

const PacketApprovalFlow = Relay.createClass({
  statics: {
    queries: {
      packetFlow: {
        info(params) {
          return {
            id: `/v2/offers/${params.offerId}/packets/${params.packetId}/flow`,
          };
        },
      },
    },
  },

  render() {
    const { classes } = this.props;
    let status = this.props.packetFlow.status;
    let packetName = this.props.packetFlow.data.packet_name;
    let { approvalLevel } = this.getData();
    const activeLevel = approvalLevel.indexOf(false);
    if (status === 'loading') {
      return <Preloader />;
    }
    if (status === 'failed') {
      return <FailureAlert />;
    }
    if (approvalLevel.length === 0) {
      return (
        <Text big={true} faded={true} bold={true} style={{ padding: '24px' }}>
          No Approval Flow - {packetName}
        </Text>
      );
    }
    return (
      <Flexbox flexDirection="column" width="100%">
        <div className={classes.packetText}>
          <Text blue={true} bold={true} style={{ fontSize: '120%' }}>
            {packetName}
          </Text>
        </div>
        <div>
          <ThemeProvider theme={this.generateTheme()}>
            <Stepper activeStep={activeLevel}>{this.generateSteps()}</Stepper>
          </ThemeProvider>
        </div>
      </Flexbox>
    );
  },

  getData() {
    const data = this.props.packetFlow.data.approvals || [];
    let approvalLevel = [];
    let approvers = [];
    const getStatus = option => option.status;
    const getApprover = option => option.approver;
    const checkForApproval = (approved, status) =>
      approved || status === 'Approved';
    const joinApproverNames = (label, approver) =>
      (label += label ? `\nOr\n${approver}` : approver);
    data.forEach(level => {
      let isApproved = level.map(getStatus).reduce(checkForApproval, false);
      if (isApproved) {
        const getApprover =
          level.filter(option => option.status === 'Approved')[0].approver ||
          '';
        approvers.push(getApprover);
      } else {
        const getApprovers = level
          .map(getApprover)
          .reduce(joinApproverNames, ``);
        approvers.push(getApprovers);
      }
      approvalLevel.push(isApproved);
      return;
    });
    return { approvalLevel, approvers };
  },

  generateSteps() {
    const { approvalLevel, approvers } = this.getData();
    return approvers.map((label, index) => {
      return <Step key={label} completed={approvalLevel[index]} />;
    });
  },

  generateTheme() {
    return createTheme({
      overrides: {
        MuiStepper: {
          root: {
            width: '100%',
          },
        },
        MuiStepLabel: {
          root: {
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
          },
        },
        MuiTypography: {
          body1: {
            fontWeight: 'bold !important',
          },
        },
        MuiStepIcon: {
          completed: {
            fill: '#99cc48',
          },
        },
        MuiStepPosition: {
          root: {
            fill: '#646464',
          },
          active: {
            fill: '#0000FF',
          },
        },
      },
    });
  },
});
export default withStyles(styles)(PacketApprovalFlow);
