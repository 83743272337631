const formatPhoneNumber = str => {
  const phoneNumberString = str.length > 10 ? str.substring(1) : str;
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

export default formatPhoneNumber;
