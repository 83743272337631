import React from 'react';
// import three files from src/studio/components/OfferPage/TermsOfEmployment/SAGTermsOfEmployment
import DailyTermsOfEmployment from './DailyTermsOfEmployment';
import WeeklyTermsOfEmployment from './WeeklyTermsOfEmployment';
import ContractTermsOfEmployment from './ContractTermsOfEmployment';

const SAGTermsOfEmployment = props => {
  const { termsOfEmploymentSettings = {} } = props;

  const {
    unionSagDaily,
    unionSagWeekly,
    unionSagContract,
  } = termsOfEmploymentSettings;

  if (unionSagDaily) {
    return <DailyTermsOfEmployment {...props} />;
  }
  if (unionSagWeekly) {
    return <WeeklyTermsOfEmployment {...props} />;
  }
  if (unionSagContract) {
    return <ContractTermsOfEmployment {...props} />;
  }
  return '';
};

export default SAGTermsOfEmployment;
