import React from 'react';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import LoanOutRestrictionTable from './LoanOutRestrictionTable';

const styles = theme => ({
  root: {
    height: '600px',
    width: '600px',
  },
});

const LoanOutRestriction = ({ open, onClose, classes }) => (
  <Dialog open={open} onClose={onClose} classes={{ paper: classes.root }}>
    <LoanOutRestrictionTable handleClose={onClose} />
  </Dialog>
);

export default withStyles(styles)(LoanOutRestriction);
