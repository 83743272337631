import React from 'react';
import { withStyles } from '@mui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import {
  Visibility as ViewIcon,
  CheckCircle as UploadedIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import SupportingDocumentUploadButton from './SupportingDocumentUploadButton';

const styles = theme => ({
  iconButton: {
    position: 'relative',
  },
  isRequired: {
    color: '#f28020',
  },
  card: {
    marginTop: 10,
  },
  file: {
    display: 'flex',
  },
  uploadedIconContainer: {
    padding: '12px 12px 12px 0',
    color: '#0000FF',
  },
  fileName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingTop: '10px',
  },
  supportingDocumentName: {
    marginBottom: 10,
  },
  associatedDocumentName: {
    position: 'relative',
    top: -9,
  },
});

const SupportingDocument = props => {
  const {
    doc,
    editHandler,
    uploadHandler,
    deleteHandler,
    showDocumentHandler,
    index,
    classes,
    isApproval,
  } = props;
  const {
    templateName: documentName,
    supportingDocumentTitles: supportingDocuments,
  } = doc;
  const {
    documentTitle: supportingDocumentName,
    supportingDocuments: uploadedSupportDocuments,
    required: isRequired,
  } = supportingDocuments[index];

  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent>
        <Typography variant="caption" color="textSecondary">
          Requested Document
        </Typography>
        <Typography
          variant="h5"
          className={classes.supportingDocumentName}
          data-test-id={`SupportingDocument-name-${doc.id}`}
        >
          {supportingDocumentName}
          {!isApproval && isRequired && (
            <span className={classes.isRequired}> &#42;</span>
          )}
        </Typography>

        <Typography variant="caption" color="textSecondary">
          Associated Document
        </Typography>
        <Typography
          variant="h6"
          gutterBottom
          className={classes.associatedDocumentName}
          data-test-id={`SupportingDocument-assocDocName-${doc.id}`}
        >
          {documentName}
          <IconButton
            className={classes.iconButton}
            onClick={() => showDocumentHandler(doc.id, false)}
          >
            <ViewIcon />
          </IconButton>
        </Typography>

        {uploadedSupportDocuments.map(({ id, name, canDelete }) => (
          <React.Fragment>
            <Divider />
            <div className={classes.file}>
              <div className={classes.uploadedIconContainer}>
                <UploadedIcon />
              </div>
              <Typography
                variant="h6"
                className={classes.fileName}
                data-test-id={`SupportingDocument-attachmentName-${id}`}
              >
                {name}
              </Typography>
              {canDelete && (
                <IconButton
                  className={classes.iconButton}
                  onClick={() => deleteHandler({ fileId: id })}
                  data-test-id={`SupportingDocument-deleteAttachment-${id}`}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </React.Fragment>
        ))}
      </CardContent>

      <CardActions>
        <SupportingDocumentUploadButton
          showButton={true}
          editHandler={editHandler}
          uploadHandler={uploadHandler}
          deleteHandler={deleteHandler}
          viewHandler={showDocumentHandler}
          uploadedSupportDocuments={uploadedSupportDocuments}
          associatedDocumentId={doc.id}
        />
      </CardActions>
    </Card>
  );
};

export default withStyles(styles)(SupportingDocument);
