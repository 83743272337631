import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import {
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { ListItemIcon, ListItemText } from '@mui/material';
import { FileCopy as ContentCopyIcon } from '@mui/icons-material';
import Feature from 'common/components/Feature';

import history from 'common/constants/config/history';

const styles = () => ({
  cancel: {
    padding: '8px 12px',
    background: '#FFFFFF',
    color: '#0000FF',
    border: '1px solid #0000FF',
    boxSizing: 'border-box',
    margin: '0px 14px',
    boxShadow:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
  },
  apply: {
    padding: '8px 20px',
    background: '#0000FF',
    color: '#F4F4F4',
    border: '1px solid #0000FF',
    marginRight: '10px',
    boxSizing: 'border-box',
    boxShadow:
      ' 0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
    '&:hover': {
      background: '#0000FF',
      color: '#FFF',
    },
    '&:disabled': {
      background: '#96A7A6',
      color: '#C6C6C6',
      border: '1px solid #96A7A6',
      boxShadow: ' 0px 0px 1px rgba(82, 82, 82, 0.4)',
    },
  },
});

const RowMenu = props => {
  const { offer, projectId, anchorEl, closeMenu, classes } = props;
  // State
  const [isCopyOfferModalOpen, setIsCopyOfferModalOpen] = useState(false);

  if (!offer) {
    return null;
  }

  const { id } = offer;

  // Open Copy Offer Modal
  const openCopyOfferModal = () => setIsCopyOfferModalOpen(true);

  // Close Copy Offer Modal
  const closeCopyOfferModal = () => setIsCopyOfferModalOpen(false);

  const initializeCopyOffer = () => {
    history.push(`/projects/${projectId}/offers/new?id=${id}&action=copy`);
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClick={closeMenu}>
        <Feature name="CopyOffer">
          <MenuItem onClick={openCopyOfferModal}>
            <ListItemIcon>
              <ContentCopyIcon />
            </ListItemIcon>
            <ListItemText primary="Copy Offer" />
          </MenuItem>
        </Feature>
      </Menu>
      <Dialog open={isCopyOfferModalOpen}>
        <DialogTitle>Copy Offer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to copy this offer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.cancel} onClick={closeCopyOfferModal}>
            No
          </Button>
          <Button
            className={classes.apply}
            autoFocus
            onClick={initializeCopyOffer}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(RowMenu);
