import React from 'react';

// MATERIAL
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0px',
  },
  loadMoreButton: {
    padding: '11px 16px',
    margin: '0 0 20px 0',
    background: '#FFFFFF !important',
    border: '1px solid #0000FF !important',
    color: '#0000FF',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
  },
  loadMoreMobileButton: {
    padding: '11px 16px',
    margin: '20px 0',
    background: '#FFFFFF',
    border: '1px solid #0000FF',
    color: '#0000FF',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  loadingIcon: {
    width: '20px',
    height: '20px',
    marginLeft: '10px',
    color: '#0000FF',
  },
}));

const FetchMoreApprovals = props => {
  const {
    loadMoreOffers,
    searchQuery,
    offersPageInfo,
    isMobile = false,
    networkStatus,
  } = props;

  const classes = useStyles();

  const loadApprovals = () => {
    const { endCursor } = offersPageInfo;

    loadMoreOffers({
      variables: {
        keyword: searchQuery?.length > 0 ? searchQuery : null,
        after: endCursor,
      },
      updateQuery: (prevResults, { fetchMoreResult }) => {
        const { project: fetchMoreApprovals = {} } = fetchMoreResult || {};
        const {
          approvals: { nodes: fetchMoreNodes = [] },
          approvals: { pageInfo: fetchMorePageInfo } = {},
        } = fetchMoreApprovals || {};

        if (!fetchMoreNodes.length) return prevResults;

        return {
          project: {
            ...fetchMoreApprovals,
            approvals: {
              nodes: [
                ...prevResults.project.approvals.nodes,
                ...fetchMoreNodes.filter(
                  fetchMoreApprovalsId =>
                    !prevResults.project.approvals.nodes.some(
                      prevResultsApprovalsId =>
                        prevResultsApprovalsId === fetchMoreApprovalsId,
                    ),
                ),
              ],
              pageInfo: { ...fetchMorePageInfo },
              __typename: prevResults.project.approvals.__typename,
            },
          },
        };
      },
    });
  };

  return (
    <div className={classes.buttonContainer}>
      <Button
        className={
          isMobile ? classes.loadMoreMobileButton : classes.loadMoreButton
        }
        data-test-id={`Projects-load-more-button`}
        onClick={loadApprovals}
      >
        Load More{' '}
        {networkStatus === 3 && (
          <CircularProgress size="sm" className={classes.loadingIcon} />
        )}
      </Button>
    </div>
  );
};

export default FetchMoreApprovals;
