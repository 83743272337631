import ajax from 'common/utilities/ajax.js';

const defaultCallback = response => Promise.resolve(response);

function Worker() {
  // All ajax.something return a Promise
  const baseUrl = '/workflow/templates';

  this.upload = (file, options, errorCallback, successCallback) => {
    const url = `${baseUrl}/create`;
    return ajax
      .post(url, { payload: { file } }, options)
      .then(successCallback)
      .catch(errorCallback);
  };

  this.get = templateId => {
    const url = `${baseUrl}/${templateId}`;
    return ajax.get(url);
  };

  this.edit = (data, options, errorCallback, successCallback) => {
    const { file, templateId } = data;
    const url = `${baseUrl}/${templateId}/edit`;
    const onSuccess = successCallback ? successCallback : defaultCallback;
    return ajax
      .put(url, { payload: file }, options)
      .then(onSuccess)
      .catch(errorCallback);
  };

  this.replace = function(data, options, errorCallback, successCallback) {
    const { file, templateId } = data;
    const url = `${baseUrl}/${templateId}/replace`;
    // Chained version to lock-unlock next step
    return ajax
      .put(url, { payload: { file } }, options)
      .then(successCallback)
      .catch(errorCallback);
  };
}

export default {
  createWorker() {
    return new Worker();
  },
};
