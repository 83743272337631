import React from 'react';
import ProjectTemplateAutoAssignmentRuleSelect from './ProjectTemplateAutoAssignmentRuleSelect';
import useStates from 'common/hooks/useStates';

const ProjectTemplateAutoAssignmentRuleStateSelect = props => {
  const {
    className,
    'data-test-id': dataTestId,
    label,
    onChange,
    stateIds,
    projectCountryId,
  } = props;
  const { data: states, loading } = useStates(projectCountryId);
  const stateOptions = states?.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
  return (
    <ProjectTemplateAutoAssignmentRuleSelect
      isLoading={loading}
      label={label}
      options={stateOptions || []}
      value={stateIds}
      onChange={stateIds =>
        onChange(states.filter(({ id }) => stateIds.includes(id)))
      }
      data-test-id={dataTestId}
      className={className}
      multiple={true}
    />
  );
};

export default ProjectTemplateAutoAssignmentRuleStateSelect;
