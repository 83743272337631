import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Typography from '@mui/material/Typography';
import formatOptionLabel from './formatOptionLabel';
import useDepartments from 'common/hooks/useDepartments';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import QUESTIONMARK_ICON from 'common/images/questionmark.svg';
import Tooltip from '@mui/material/Tooltip';
import useSubDepartments from 'studio/hooks/useSubDepartments';

const DepartmentSelect = props => {
  const {
    onChange: upstreamOnChange,
    formData: {
      department,
      occupation,
      union,
      subDepartment,
      workState,
      currency,
    } = {},
    error = '',
    selectStyles,
    classes = {},
    disabled,
    projectId,
  } = props;
  const { value: occupationCode } = occupation || {};
  const { value: unionCode } = union || {};
  const { id: subDepartmentId } = subDepartment || {};
  const { loading, data: departments = [] } = useDepartments(
    {
      projectId,
      occupation: occupationCode,
      union: unionCode,
      workState,
      currency,
    },
    { skip: !occupation || !union },
  );
  const [selectedSubDepartment, setSelectedSubDepartment] = useState({});

  // Format options for react-select
  const options = departments.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  // Get the formatted option corresponing to the current dept.
  const selectedOption =
    options.find(({ value }) => department === value) || null;

  const { masterDepartmentId } =
    departments.find(({ id }) => id === department) || {};

  const {
    loading: isSubDepartmentLoading = false,
    data: subDepartmentData = {},
  } = useSubDepartments({
    projectId: parseInt(projectId, 10),
    masterDepartmentId,
  });
  const { ecsProjectDepartments: subDepartments = [] } = subDepartmentData;

  const subDepartmentsOptions = selectedOption
    ? subDepartments.map(({ id, name }) => ({ value: id, label: name }))
    : [];
  const preSelectedSubDepartment = subDepartmentId
    ? subDepartments.find(o => o.id === subDepartmentId)
    : {};
  const preSelectedSubDepartmentOptions = preSelectedSubDepartment
    ? {
        value: preSelectedSubDepartment.id,
        label: preSelectedSubDepartment.name,
      }
    : {};

  // If the department is pre-selected, lock the select
  const { preSelected: isLocked } =
    departments.find(({ id }) => id === department) || {};

  const onChange = ({ value }) => upstreamOnChange({ department: value });

  useEffect(() => {
    if (department) return;
    const options = departments.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    if (options.length === 1) {
      onChange(options[0]);
    }
  }, [departments, department]); // eslint-disable-line react-hooks/exhaustive-deps
  // if we include upstreamOnChange in the dependency array, it will cause an infinite loop

  const onSubDepartmentChange = (selectedOption, actionInfo) => {
    const id = actionInfo.action === 'clear' ? null : selectedOption.value;
    const value = id ? { id: id, name: selectedOption.label } : null;

    setSelectedSubDepartment(id ? { ...selectedOption } : {});
    upstreamOnChange({ subDepartment: value });
  };

  const subDepartmentValue = selectedSubDepartment?.value
    ? selectedSubDepartment
    : preSelectedSubDepartmentOptions;

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1">
        Department
      </Typography>
      <div data-test-id="DepartmentSelect-select">
        <Select
          styles={selectStyles}
          onChange={onChange}
          value={selectedOption}
          options={options}
          className={classes.select}
          isLoading={loading}
          isDisabled={disabled || isLocked}
          formatOptionLabel={formatOptionLabel('DepartmentSelect')}
        />
      </div>
      {error && (
        <Typography
          color="error"
          variant="caption"
          className={classes.error}
          data-test-id="DepartmentSelect-error"
        >
          {error}
        </Typography>
      )}
      <div
        data-test-id="DepartmentSelect-assocSelectTitle"
        className={classes.root}
      >
        <label>
          Associated Hours+ Department{' '}
          <Tooltip
            disableInteractive
            title={
              'The Hours+ department is used to automatically invite crew ' +
              'member(s) to the same Hours+ project once their offer is ' +
              'approved in Start+. You can leave this field empty if you ' +
              'don’t know the right Hours+ department or if your project ' +
              'is not using Hours+'
            }
            placement="right"
            className={classes.tooltip}
          >
            <img
              src={QUESTIONMARK_ICON}
              alt="schedule tooltip"
              className={classes.questionMark}
            />
          </Tooltip>
        </label>
        <span data-test-id="DepartmentSelect-assocSelect">
          <Select
            styles={selectStyles}
            onChange={onSubDepartmentChange}
            value={subDepartmentValue}
            options={subDepartmentsOptions}
            className={classes.select}
            isLoading={isSubDepartmentLoading}
            formatOptionLabel={formatOptionLabel(
              'DepartmentSelect-assocSelect',
            )}
            isClearable
          />
        </span>
      </div>
    </div>
  );
};

export default withRouteHelpers(DepartmentSelect);
