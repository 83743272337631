import React from 'react';

import AddressInputs from './AddressInputs';
import Field from '../../../Base/Field';

import Header from '../../../Base/Header';
import Text from '../../../Base/Text';
import { withStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const styles = () => ({
  root: {
    width: '100%',
    minHeight: '170px',
    maxHeight: '676px',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '100px auto',
    border: '1px solid #e6e7ea',
    borderRadius: '5px',
    padding: '15px',
  },
  headerContainer: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '100px calc(100% - 100px)',
    gridTemplateRows: '100%',
    gridTemplateAreas: `
        "icon     header"`,
    borderBottom: '1px solid #e6e7ea',
    gridGap: '15px',
    gap: '15px',
  },
  icon: {
    gridArea: 'icon',
    height: '75%',
    width: 'auto',
    placeSelf: 'center',
  },
  header: {
    gridArea: 'header',
    height: '100%',
    width: '100%',
    display: 'grid',
    paddingLeft: '20px',
  },
  content: {
    height: '100%',
    width: '100%',
    margin: '0px',
  },
});

const PRIMARY_ADDRESS_TYPE = 'primary_address';

class AddressSection extends React.Component {
  constructor(props) {
    super(props);
    const { profileMutator = {}, type } = props;
    const { data = {} } = profileMutator;
    const { [type]: address = {} } = data;
    const { use_primary: usePrimaryAddress = false } = address || {};

    this.state = {
      usePrimaryAddress,
    };

    this._renderSameAsPrimaryField = this._renderSameAsPrimaryField.bind(this);
  }

  render() {
    const { usePrimaryAddress } = this.state;
    const { type, icon: Icon, required, subTitle, title, classes } = this.props;
    return (
      <div
        className={classes.root}
        data-test-id={`AddressSection-root-${type}`}
      >
        <div
          className={classes.headerContainer}
          data-test-id={`AddressSection-headerContainer-${type}`}
        >
          <Icon
            className={classes.icon}
            data-test-id={`AddressSection-addressIcon-${type}`}
          />
          <div className={classes.header}>
            <Header.Title data-test-id={`AddressSection-title-${type}`}>
              {title} {required && <Text className="field-required">*</Text>}
            </Header.Title>
            <Text data-test-id={`AddressSection-subTitle-${type}`}>
              {subTitle}
            </Text>
          </div>
        </div>
        {type !== PRIMARY_ADDRESS_TYPE && this._renderSameAsPrimaryField()}
        {!usePrimaryAddress && this._renderInputs()}
      </div>
    );
  }

  _renderErrors() {
    const errors = this.props.profileMutator.errors;
    if (errors && errors[this.props.type]) {
      return (
        <Field.Errors errors={[`${this.props.type}_required`]} key="errors" />
      );
    }
  }

  _renderInputs() {
    const { states, onChange, type, profileMutator = {}, classes } = this.props;
    const { data: profileData } = profileMutator;
    const { [type]: address } = profileData;
    return (
      <AddressInputs
        address={address}
        states={states}
        onChange={onChange}
        key="inputs"
        className={classes.content}
        type={type}
      />
    );
  }

  _renderSameAsPrimaryField() {
    const { type, onChange: upstreamOnChange, classes } = this.props;
    const { usePrimaryAddress } = this.state;
    const onChange = ({ target: { checked: value } }) => {
      upstreamOnChange({ use_primary: value });
      this.setState({ usePrimaryAddress: value });
    };
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={usePrimaryAddress}
            onChange={onChange}
            value="usePrimaryAddress"
            data-test-id={`AddressSection-usePrimaryCheckbox-${type}`}
            inputProps={{
              'data-test-id': `AddressSection-usePrimaryInput-${type}`,
              'data-checked': String(usePrimaryAddress),
            }}
          />
        }
        label="Use same as primary address"
        className={classes.content}
        data-test-id={`AddressSection-usePrimary-${type}`}
      />
    );
  }
}

export default withStyles(styles)(AddressSection);
