import React from 'react';
import {
  Button,
  Input,
  InputAdornment,
  Tooltip,
  IconButton,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close, Check } from '@mui/icons-material';
import classNames from 'classnames';

const styles = {
  root: {},
  changeAptBtn: {
    color: '#3f51b5',
  },
  inputAdornment: {
    display: 'flex',
  },
  adornmentButtons: {
    width: 36,
  },
  save: {
    color: '#0000FF',
  },
  cancel: {
    color: '#FF1744',
  },
};

const SuiteOverride = props => {
  const {
    saveAddressSuite,
    openSuiteOverride,
    value,
    onChange,
    onKeyDown,
    open,
    className,
    classes,
  } = props;
  if (!open)
    return (
      <div className={classNames(className, classes.root)}>
        <Button
          variant="contained"
          onClick={() => openSuiteOverride(true)}
          className={classes.changeAptBtn}
        >
          Change Apt / Suite
        </Button>
      </div>
    );
  return (
    <div className={classNames(className, classes.root)}>
      <Input
        value={value}
        onChange={e => onChange(e.target.value)}
        onKeyDown={onKeyDown}
        endAdornment={
          <InputAdornment className={classes.inputAdornment} position="end">
            <Tooltip disableInteractive title="Save and close" enterDelay={500}>
              <IconButton
                onClick={() => saveAddressSuite()}
                aria-label="Save and close"
                className={classNames(classes.adornmentButtons, classes.save)}
                color="primary"
              >
                <Check />
              </IconButton>
            </Tooltip>
            <Tooltip
              disableInteractive
              title="Cancel and close"
              enterDelay={500}
            >
              <IconButton
                onClick={() => openSuiteOverride(false)}
                aria-label="Cancel and close"
                className={classNames(classes.adornmentButtons, classes.cancel)}
                color="secondary"
              >
                <Close />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
      />
    </div>
  );
};

export default withStyles(styles)(SuiteOverride);
