import React from 'react';
export default ({ style, ...propsCatch }) => (
  <svg
    width="18"
    height="18"
    minwidth="18"
    minheight="18"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    {...propsCatch}
  >
    <g
      fill="none"
      fillRule="evenodd"
      transform={`translate(${style.width / 2 - 7.5 || 0})`}
    >
      <path d="M-2-2h24v24H-2z" />
      <path d="M-3-3h24v24H-3z" />
      <path
        d="M16 0H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm-2.41 5.29L9 9.87 4.41 5.29 3 6.7l6 6 6-6-1.41-1.41z"
        fill="#000"
      />
    </g>
  </svg>
);
