import gql from 'graphql-tag';

export default gql`
  query workSchedules(
    $projectId: Int!
    $startDate: String!
    $workState: String!
    $hireState: String!
    $workCity: String
    $hireCity: String
    $currency: String
    $union: String!
    $occupation: String!
    $season: Int
  ) {
    workSchedules(
      projectId: $projectId
      startDate: $startDate
      workState: $workState
      hireState: $hireState
      workCity: $workCity
      hireCity: $hireCity
      currency: $currency
      union: $union
      occupation: $occupation
      season: $season
    ) {
      code
      description
    }
  }
`;
