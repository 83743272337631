import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Typography, TextField, Dialog } from '@mui/material';
import { Close as CloseBtn } from '@mui/icons-material';

const styles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f7fcfc',
    padding: '20px',
    height: '100vh',
  },
  title: {
    color: '#646464',
    fontSize: 24,
    fontWeight: 400,
    margin: '10px 20px 0 20px',
  },
  formFields: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '60px',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '90%',
    background: '#F7FCFC',
  },
  input: {
    background: '#f7fcfc',
    '& input': {
      fontSize: 16,
    },
  },
  label: {
    color: '#0000FF',
  },
  closeBtn: {
    width: '30px',
    height: '30px',
    color: '#0000FF',
    marginLeft: '88vw',
  },
  btnContainer: {
    display: 'flex',
    marginTop: '30px',
    marginRight: '30px',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  cancelBtn: {
    backgroundColor: 'transparent !important',
    marginRight: '15px',
    color: '#0000FF',
    border: '1px solid #0000FF',
  },
  legalCopy: {
    color: '#646464',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '21px',
    margin: '10px 20px 0 20px',
  },
  viewMore: {
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 400,
    marginLeft: '20px',
    marginTop: '10px',
  },
  error: {
    color: 'red',
  },
  agreeBtn: {
    backgroundColor: '#0000FF',
    color: '#FFF',
    '&:focus': {
      backgroundColor: '#0000FF !important',
      color: '#FFF',
    },
    '&:hover': {
      backgroundColor: '#0000FF !important',
      color: '#FFF',
    },
  },
}));

const MobileAnySignature = props => {
  const classes = styles();
  const [viewMore, setViewMore] = useState(false);
  const [fieldContent, setFieldContent] = useState();
  const [fieldContentError, setFieldContentError] = useState();
  const {
    closeDialogHandler,
    actor,
    config = {},
    handleSubmit: upstreamHandleSubmit,
  } = props;
  const { field } = config;
  const { _derived: { isInitials = true, isValidated } = {} } = field || {};

  const handleFieldContent = value => {
    setFieldContent(value);
  };

  const handleSubmit = () => {
    if (!fieldContent) {
      setFieldContentError('Cannot be blank');
      return;
    }

    let trimmedFieldContent = `${fieldContent}`.trim();

    if (isValidated) {
      const expectedContent = isInitials ? actor.initials : actor.name;
      if (
        trimmedFieldContent.toLowerCase() !==
        expectedContent.trim().toLowerCase()
      ) {
        const errorMessage = isInitials
          ? `Please enter your initials as recorded in your profile: ${expectedContent}`
          : `Please enter your full legal name as recorded in your profile: ${expectedContent}`;
        setFieldContentError(errorMessage);
        return;
      }
    }
    setFieldContentError(null);
    upstreamHandleSubmit(fieldContent);
  };

  const handleViewMore = () => {
    setViewMore(!viewMore);
  };

  const showMoreTitle = viewMore ? 'Show less' : 'View More';

  return (
    <Dialog fullScreen open data-test-id="Any-Signature-Dialog">
      <div className={classes.root}>
        <CloseBtn onClick={closeDialogHandler} className={classes.closeBtn} />
        <Typography className={classes.title}>
          {isInitials ? 'Initials' : 'Signature'}
        </Typography>
        <Typography className={classes.legalCopy}>
          Only the person associated with these records is permitted to use this
          system through this account. Completion and submission of the online
          forms available through this system will create legally binding
          obligations associated with each form. Please read each form
          carefully. Execution of each form will be accomplished through
          clicking on the “I{' '}
          {viewMore && (
            <span>
              Agree” button provided at the bottom of each form. By clicking “I
              Agree” at the bottom of each form, you will be acknowledging that
              you have read the applicable form, understand the requirements for
              submission of the information, agree to submit complete and
              accurate information, and further agree to submit and sign these
              forms electronically through this system.
            </span>
          )}
        </Typography>
        <Typography className={classes.viewMore} onClick={handleViewMore}>
          {showMoreTitle}
        </Typography>
        <div className={classes.formFields}>
          <TextField
            id="legal-name"
            className={classes.textField}
            label={isInitials ? 'Initials' : 'Full Legal Name'}
            margin="normal"
            variant="filled"
            InputLabelProps={{
              className: classes.label,
            }}
            InputProps={{
              'data-test-id': 'MobileAnySignature-Dialog-legalName',
              className: classes.input,
            }}
            onChange={e => handleFieldContent(e.target.value)}
          />
          {fieldContentError && (
            <Typography className={classes.error}>
              {fieldContentError}
            </Typography>
          )}
        </div>
        <div className={classes.btnContainer}>
          <Button onClick={closeDialogHandler} className={classes.cancelBtn}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} className={classes.agreeBtn}>
            I Agree
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default MobileAnySignature;
