import React, { useState } from 'react';
import { Typography } from '@mui/material';
import OfferDocuments from './OfferDocuments';
import { makeStyles } from '@mui/styles';
import OfferDocumentsListModal from 'common/components/OfferDocumentsList/DocumentModal';

const documentListStyles = makeStyles(theme => ({
  documentTitle: {
    color: '#646464',
    fontSize: 24,
    fontWeight: 400,
    marginBottom: '30px',
    marginTop: '10px',
  },
}));

const DocumentList = ({ offer }) => {
  const [state, setState] = useState({
    documentId: '',
    isExternal: false,
    isW9: false,
    isDocumentPreviewerOpen: false,
  });
  const classes = documentListStyles();
  const optedOut = offer?.status === 'complete_on_paper';
  const { id: offerId, hasPackets: offerHasPackets, keepI9: keep_i9 } = offer;

  const handleDocumentPreviewer = (id, w9, external) => {
    setState({
      ...state,
      documentId: id,
      isExternal: external,
      isW9: w9,
      isDocumentPreviewerOpen: !state.isDocumentPreviewerOpen,
    });
  };

  const { documentId, isExternal, isW9, isDocumentPreviewerOpen } = state;

  return (
    <React.Fragment>
      <div
        className={classes.documentsContainer}
        data-test-id="DocumentList-container"
      >
        {optedOut && (
          <Typography data-test-id="OfferDocuments-optOutText">
            Employee has chosen to complete the offer on paper and cannot
            complete this offer in Start+.
          </Typography>
        )}
        {!optedOut && (
          <OfferDocuments
            params={{
              scope: `/v2/offers/${offerId}`,
              offerId: offerId,
              hasPackets: offerHasPackets,
            }}
            offerStatus={offer?.status}
            keepI9={keep_i9}
            handleDocumentPreview={handleDocumentPreviewer}
          />
        )}
      </div>
      {isDocumentPreviewerOpen && (
        <OfferDocumentsListModal
          onClose={handleDocumentPreviewer}
          documentId={documentId}
          offerId={offerId}
          isExternal={isExternal}
          isW9={isW9}
        />
      )}
    </React.Fragment>
  );
};

export default DocumentList;
