import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const QUERY_VIEW = gql`
  query ssnView($projectId: Int!, $profileId: Int!) {
    project(id: $projectId) {
      id
      users(profileId: $profileId) {
        id
        ssn {
          canView
        }
      }
    }
  }
`;

const QUERY_SSN = gql`
  query ssn($projectId: Int!, $profileId: Int!) {
    project(id: $projectId) {
      id
      users(profileId: $profileId) {
        id
        ssn {
          number
          canView
        }
      }
    }
  }
`;

const QUERY_KEY = gql`
  query key($projectId: Int!, $profileId: Int!) {
    project(id: $projectId) {
      id
      users(profileId: $profileId) {
        id
        ssn {
          key
          canView
        }
      }
    }
  }
`;

const useSSN = (projectId, profileId, iteration = null) => {
  const query =
    iteration > 0 ? (iteration > 1 ? QUERY_KEY : QUERY_SSN) : QUERY_VIEW;
  const { error, loading, data } = useQuery(query, {
    skip: !parseInt(profileId) || !parseInt(projectId),
    variables: {
      projectId: parseInt(projectId),
      profileId: parseInt(profileId),
    },
    notifyOnNetworkStatusChange: true,
  });

  const user = (data?.project?.users || [])[0];
  return { error, loading, data: user?.ssn };
};

export default useSSN;
