import React, { Component } from 'react';
import withApi from 'common/hoc/withApi';
import DepartmentRule from './Department/Rule';
import Field from 'common/oldJavascripts/components/Base/Field';
import Form from 'common/oldJavascripts/components/Base/Form';
import accountCodeRestrictions from 'common/oldJavascripts/utils/accountCodeRestrictions';
import AccountCodeMaskedInput from 'common/components/AccountCodeMaskedInput';
import Feature from 'common/components/Feature';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import QUESTIONMARK_ICON from 'common/images/questionmark.svg';
import Tooltip from '@mui/material/Tooltip';
import * as Queries from 'common/apollo/queries';
import { graphql } from 'react-apollo';

const styles = () => ({
  header: {
    marginTop: 10,
    marginBottom: 30,
  },
  questionMark: {
    position: 'relative',
    top: 5,
    left: 2,
  },
});

class DepartmentForm extends Component {
  static queries = {
    project: {
      info(_, related) {
        const params = related['/router/params'];
        return {
          id: `/projects/${params.projectId}`,
        };
      },
    },
    projectUsers: {
      info(params, related) {
        const routerParams = related['/router/params'];

        return {
          id: `/projects/${routerParams.projectId}/non-employees`,
        };
      },
    },
  };

  state = { error: null };

  componentWillReceiveProps(nextProps) {
    const { detailSubDataType: newDetailSubDataType } = nextProps;
    const { detailSubDataType: oldDetailSubDataType } = this.props;
    if (newDetailSubDataType !== oldDetailSubDataType) {
      this.validateExistingDepartmentCode(newDetailSubDataType);
    }
  }

  componentDidMount() {
    const { department, detailSubDataType } = this.props;
    const { unions, occupations } = this.handleDeptRuleUpdate();
    const { loanouts } = this.handleLoanoutRuleUpdate();
    department
      .bulkUpdate({
        occupations,
        unions,
        loan_out_occupations: loanouts,
      })
      .then(() => {
        this.validateExistingDepartmentCode(detailSubDataType);
      });
    // this.updateExistingDepartmentCode();
  }

  validateExistingDepartmentCode = newDetailSubDataType => {
    const { department } = this.props;

    const {
      data: { code },
    } = department;
    if (!code) return;
    const validatedDetailSubCode = code.replace(
      accountCodeRestrictions[newDetailSubDataType],
      '',
    );
    if (validatedDetailSubCode.length !== code.length) {
      this.setState({ error: newDetailSubDataType });
    }
  };

  handleDeptRuleUpdate = (props = this.props) => {
    const { departmentRules = [] } = props;
    if (departmentRules.length > 0) {
      let unions = {};
      let occupations = {};
      departmentRules.forEach(rule => {
        const { union, occupation_code: occupation } = rule;
        if (union) {
          unions[union.code] = union;
        }
        if (occupation) {
          const updatedOccupation = Object.assign({}, occupation);
          updatedOccupation.union_id = union.code;
          occupations[occupation.code] = updatedOccupation;
        }
      });
      return { unions, occupations };
    }
    return {};
  };

  handleLoanoutRuleUpdate = (props = this.props) => {
    const { loanoutRules = [] } = props;
    let loanouts = loanoutRules.reduce((result, rule) => {
      let { union, occupation_code: occupation } = rule;
      if (occupation) {
        result[occupation.code] = {
          code: occupation.code,
          description: occupation.description,
          union_id: union.code,
        };
      }
      return result;
    }, {});

    return { loanouts };
  };

  updateDept = obj => {
    const { department } = this.props;
    const {
      unions: deptUnions = {},
      occupations: deptOccupations = {},
    } = department;
    const { unions = {}, occupations = {} } = obj;
    const updateObj = {};
    if (Object.keys(deptUnions).length === 0 && Object.keys(unions) > 0) {
      updateObj['unions'] = unions;
    }
    if (
      Object.keys(deptOccupations).length === 0 &&
      Object.keys(occupations) > 0
    ) {
      updateObj['occupations'] = occupations;
    }
    department.bulkUpdate(updateObj);
  };

  // Validate account code type
  handleUpdateDetailSub = event => {
    const { department = {} } = this.props;
    department.update('code')(event.target?.value);
  };

  render() {
    const { classes, project = {}, department = {} } = this.props;
    const { data: { id, code: value } = {}, errors = {} } = department;
    const { data: projectData = {} } = project;
    const { account_mask: accountMask = '' } = projectData;

    const masterDepartmentID = department.data.master_department_id
      ? department.data.master_department_id.toString()
      : null;

    return (
      <div>
        <Typography variant="h5" className={classes.header}>
          ADDING NEW DEPARTMENT
        </Typography>
        <Form
          action={this.props.action}
          method={this.props.method}
          onSubmit={this.props.onSubmit}
        >
          <Feature name="masterDepartmentSetup">
            <label>
              Master Department{' '}
              <Tooltip
                disableInteractive
                title="This Master Department links to the Hours+ Master Department via the Enterprise Department Hub. Selecting a Master Department will help hiring managers choose the correct Associated Hours+ Department on the Create Offer screen. If a Master Department is missing, please reach out to the C&C Core Services team."
                placement="right"
                className={classes.tooltip}
              >
                <img
                  src={QUESTIONMARK_ICON}
                  alt="schedule tooltip"
                  className={classes.questionMark}
                />
              </Tooltip>
            </label>
            <Field
              type="autocomplete"
              options={this._getMasterDepartments()}
              value={masterDepartmentID}
              errors={errors.master_department_id}
              onChange={department.update('master_department_id')}
              data-test-id="Form-autocomplete-masterdept"
            />
          </Feature>

          <Field
            label="Department Name"
            type="text"
            name="name"
            id="name"
            value={department.data.name}
            errors={errors.name}
            required={true}
            onChange={department.update('name')}
            data-test-id="Form-departmentName"
          />

          <AccountCodeMaskedInput
            id={id}
            value={value}
            accountMask={accountMask}
            onChange={this.handleUpdateDetailSub}
            label="Default Detail/Sub"
            isLabelVisible={true}
          />
          <DepartmentRule
            occOnChange={this._saveRuleOccupations}
            loanoutOnChange={this._saveRuleLoanout}
            unionOnChange={this._saveRuleUnions}
            department={department.data}
          />
          {this.props.children}
        </Form>
      </div>
    );
  }

  _getMasterDepartments = () => {
    const { ecsMasterDepartments = [] } = this.props?.data;

    if (ecsMasterDepartments.length === 0) {
      return [];
    }

    return ecsMasterDepartments.map(dept => {
      return {
        value: dept.id,
        label: dept.name,
      };
    });
  };

  _saveRuleUnions = unions => {
    this.props.department.update('unions')(unions);

    // if (Object.keys(selectedUnionIds).length === 0) {
    //   this.props.onChange({});
    //   return;
    // }

    // const selectedUnions = {};
    // const unions = this.props.unions.data.items;
    // selectedUnionIds.forEach( unionCode =>{
    //   const unionObject = unions.find( union => (union.code === unionCode));

    //   selectedUnions[unionCode] = unionObject;
    // });
    // this.props.department.update("unions")(selectedUnions);
    //
    //
    // // Update payload occupation codes based on new union ids.
    // let occupationCodes = this.props.department.data.occupation_codes;
    // if (occupationCodes) {
    //   if (!unionIds) {
    //     this.props.department.update("occupation_codes")([]);
    //   } else {
    //     occupationCodes = occupationCodes
    //       .map(occ => unionIds.indexOf((occ["union_id"] + "")) > -1 ? occ : null)
    //       .filter(occ => occ != null);

    //     this.props.department.update("occupation_codes")(occupationCodes);
    //   }
    // }
  };

  _saveRuleOccupations = occupations => {
    const { department } = this.props;
    department.update('occupations')(occupations);
  };

  _saveRuleLoanout = occupations => {
    const { department } = this.props;
    department.update('loan_out_occupations')(occupations);
  };
}

const projectConfig = {
  options: ({ routerParams: { projectId } = {} }) => ({
    variables: { projectId: parseInt(projectId, 10) },
  }),
};

export default compose(
  withApi,
  graphql(Queries.GetMasterDepartments, projectConfig),
  withStyles(styles),
)(DepartmentForm);
