import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createOfferCurrency as currency } from 'common/oldJavascripts/utils/format';
import isNonUnionWorkScheduleA from 'common/utilities/isNonUnionWorkScheduleA';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';

const formatAmount = amount => {
  return !Number.isInteger(amount) ? currency(amount) : `$${amount}`;
};

const mixedEmployeeInfoStyles = makeStyles(theme => ({
  title: {
    color: '#999999',
    fontSize: '12px !important',
  },
  name: {
    fontSize: 24,
    fontWeight: 400,
    color: '#646464',
  },
  date: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 400,
  },
  email: {
    fontSize: 10,
    color: '#0000FF',
    fontWeight: 400,
    marginTop: '3px',
    letterSpacing: '0.3px',
  },
  spacing: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  amount: {
    color: '#0000FF',
    fontSize: '24px !important',
    marginRight: '10px !important',
  },
  description: {
    wordBreak: 'break-word',
  },
  flag: {
    display: 'block',
    margin: 'auto',
    width: 24,
    height: 24,
  },
  caption: {
    color: '#aaaaaa',
    fontSize: 10,
    fontWeight: 400,
    padding: 0,
    textAlign: 'center',
  },
}));

const MixedEmployeeTerms = ({ offer, countryCode }) => {
  const classes = mixedEmployeeInfoStyles();
  const { hourlyRate, weeklyRate, dailyRate } = getRate(offer);

  const {
    id: offerId = null,
    termsOfHire: {
      workSchedule: { code = '' },
      union: { isNonUnion = false } = {},
    },
    termsOfEmployment = {},
  } = offer;
  const workScheduleCode = code;
  const flags = useFeatureFlags();
  const isCanada = countryCode === 'CA';

  const {
    overtimeRatePerHour: overtimeRate,
    idleLocationSixthDayRatePerHour: sixthDayRate,
    idleLocationSeventhDayRatePerHour: seventhDayRate,
    studioGuaranteedHours: hoursPerDay,
  } = termsOfEmployment;

  const isNonUnionScheduleAFlagActive = flags.includes('nonUnionSchedules');
  const isNonUnionScheduleCFlagActive = flags.includes('nonUnionScheduleC');
  const isNonUnionScheduleBFlagActive = flags.includes('nonUnionScheduleB');

  const isNonUnionScheduleA =
    isNonUnion &&
    isNonUnionScheduleAFlagActive &&
    isNonUnionWorkScheduleA(workScheduleCode);
  const isNonUnionScheduleB =
    isNonUnion && isNonUnionScheduleBFlagActive && workScheduleCode === 'B';
  const isNonUnionScheduleC =
    isNonUnion &&
    isNonUnionScheduleCFlagActive &&
    ['C', 'E', 'CN'].includes(workScheduleCode);

  const payDayTitle = day => {
    if (isNonUnionScheduleA) {
      return (
        <>
          Apply Hours per <br /> Day to {day}
          th Day
        </>
      );
    }
    if (isNonUnionScheduleC) {
      return `Pay ${day}th day at`;
    }
    return `${day}th day pay`;
  };

  const weekendPayRate = payRate => {
    if (isNonUnionScheduleA) return payRate > 0 ? 'Yes' : 'No';
    return formatAmount(payRate);
  };

  const isHourlySchedule = isNonUnionScheduleA || isNonUnionScheduleB;
  const hoursTitle =
    isNonUnionScheduleA || isCanada ? 'Hours per Day' : 'Hours per Week';
  const hoursPerWeekTitle =
    isCanada && termsOfEmployment?.locationGuaranteedHours
      ? 'Hours Per Week'
      : null;
  const hoursPerWeek = isCanada
    ? termsOfEmployment?.locationGuaranteedHours
    : null;

  return (
    <>
      <Grid container className={classes.spacing}>
        {weeklyRate && (
          <Grid item xs={4}>
            <Typography
              variant="h6"
              className={classes.title}
              data-test-id={`EmployeeInfo-weeklyRateTitle-${offerId}`}
            >
              Weekly Rate
            </Typography>
            <Typography
              className={classes.amount}
              data-test-id={`EmployeeInfo-weeklyRate-${offerId}`}
            >
              {currency(weeklyRate)}
            </Typography>
          </Grid>
        )}
        {hourlyRate && (
          <Grid item xs={4}>
            <Typography
              variant="h6"
              className={classes.title}
              data-test-id={`EmployeeInfo-hourlyRateTitle-${offerId}`}
            >
              <UsaOnly>Hourly</UsaOnly>
              <CanadaOnly>Rate Per Hour</CanadaOnly>
            </Typography>
            <Typography
              className={classes.amount}
              data-test-id={`EmployeeInfo-hourlyRate-${offerId}`}
            >
              {currency(hourlyRate)}
            </Typography>
          </Grid>
        )}
        {dailyRate && (
          <Grid item xs={4}>
            <Typography
              variant="h6"
              className={classes.title}
              data-test-id={`EmployeeInfo-dailyRateTitle-${offerId}`}
            >
              Daily
            </Typography>
            <Typography
              className={classes.amount}
              data-test-id={`EmployeeInfo-dailyRateAmount-${offerId}`}
            >
              {currency(dailyRate)}
            </Typography>
          </Grid>
        )}
        {(isHourlySchedule || isCanada) && (
          <>
            {overtimeRate && (
              <Grid item xs={4}>
                <Typography
                  variant="h6"
                  className={classes.title}
                  data-test-id={`EmployeeInfo-overtimeRateTitle-${offerId}`}
                >
                  Overtime Rate
                </Typography>
                <Typography
                  className={classes.amount}
                  data-test-id={`EmployeeInfo-overtimeRateAmount-${offerId}`}
                >
                  {currency(overtimeRate)}
                </Typography>
              </Grid>
            )}
            {hoursPerDay && (
              <Grid item xs={4}>
                <Typography
                  variant="h6"
                  className={classes.title}
                  data-test-id={`EmployeeInfo-hoursPerDayTitle-${offerId}`}
                >
                  {hoursTitle}
                </Typography>
                <Typography
                  className={classes.amount}
                  data-test-id={`EmployeeInfo-hoursPerDay-${offerId}`}
                >
                  {hoursPerDay}
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Grid container className={classes.spacing}>
        {hoursPerWeek && (
          <CanadaOnly>
            <Grid item xs={4}>
              <Typography
                variant="h6"
                className={classes.title}
                data-test-id={`EmployeeInfo-hoursPerWeekTitle-${offerId}`}
              >
                {hoursPerWeekTitle}
              </Typography>
              <Typography
                className={classes.amount}
                data-test-id={`EmployeeInfo-hoursPerWeek-${offerId}`}
              >
                {hoursPerWeek}
              </Typography>
            </Grid>
          </CanadaOnly>
        )}
        {sixthDayRate && (
          <Grid item xs={4}>
            <Typography
              variant="h6"
              className={classes.title}
              data-test-id={`EmployeeInfo-sixthDayRateTitle-${offerId}`}
            >
              {payDayTitle('6')}
            </Typography>
            <Typography
              className={classes.amount}
              data-test-id={`EmployeeInfo-sixthDayRate-${offerId}`}
            >
              {weekendPayRate(sixthDayRate)}
            </Typography>
          </Grid>
        )}
        {seventhDayRate && (
          <Grid item xs={4}>
            <Typography
              variant="h6"
              className={classes.title}
              data-test-id={`EmployeeInfo-seventhDayRateTitle-${offerId}`}
            >
              {payDayTitle('7')}
            </Typography>
            <Typography
              className={classes.amount}
              data-test-id={`EmployeeInfo-seventhDayRate-${offerId}`}
            >
              {weekendPayRate(seventhDayRate)}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};
const getRate = offer => {
  const { termsOfEmployment = {} } = offer;
  const {
    studioRatePerWeek,
    studioRatePerHour,
    locationRatePerWeek,
    locationRatePerHour,
    dailyRate,
  } = termsOfEmployment;

  return {
    hourlyRate: studioRatePerHour || locationRatePerHour,
    dailyRate: dailyRate,
    weeklyRate: studioRatePerWeek || locationRatePerWeek,
  };
};
export default MixedEmployeeTerms;
