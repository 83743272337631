import gql from 'graphql-tag';

export default gql`
  query unions(
    $projectId: Int!
    $startDate: String!
    $workState: String!
    $hireState: String!
    $workCity: String
    $hireCity: String
    $season: Int
    $currency: String
  ) {
    unions(
      projectId: $projectId
      startDate: $startDate
      workState: $workState
      hireState: $hireState
      workCity: $workCity
      hireCity: $hireCity
      season: $season
      currency: $currency
    ) {
      code
      description
      isNonUnion
      preferred
      aboveTheLine
    }
  }
`;
