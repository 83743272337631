import { gql } from 'apollo-boost';
import { useQuery } from 'react-apollo';

const QUERY = gql`
  query projectTemplates($projectId: Int!, $offerIds: [Int!]) {
    project(id: $projectId) {
      id
      templates(first: 200, offerIds: $offerIds) {
        edges {
          node {
            id
            name
            images
            inputFieldGroups {
              id
              name
              description
              required
              fields {
                id
                name
                options
                type
              }
            }
          }
        }
      }
    }
  }
`;

const useTemplates = (projectId, offerIds) => {
  const { error, loading, data, errors } = useQuery(QUERY, {
    variables: {
      projectId: parseInt(projectId, 10),
      offerIds,
    },
    fetchPolicy: 'no-cache',
  });
  return {
    error,
    errors,
    loading,
    data: data?.project?.templates?.edges || [],
  };
};

export default useTemplates;
