import React from 'react';

// HoC
import { withStyles } from '@mui/styles';
// Component
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const styles = theme => ({
  root: {},
  error: {
    color: '#f44336',
  },
  label: {
    top: '-4px !important',
    left: '-4px !important',
  },
});

const DropdownInput = props => {
  const {
    fieldId,
    fieldName,
    value,
    error,
    fieldOptions = [],
    onChange,
    classes,
  } = props || {};
  return (
    <FormControl fullWidth classes={{ root: classes.root }} error={error}>
      <InputLabel
        shrink={value !== null && value !== undefined}
        htmlFor={fieldId}
        classes={{ root: classes.label }}
      >
        {fieldName}
      </InputLabel>
      <Select
        value={value || ''}
        onChange={({ target: { value } = {} } = {}) => onChange(value)}
        inputProps={{
          name: fieldName,
          id: fieldId,
        }}
        data-test-id={`DropdownInput-${fieldName}`}
      >
        {fieldOptions.map(option => (
          <MenuItem
            key={option}
            value={option}
            data-test-id={`DropdownInput-menuItem-${option}`}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText className={classes.error}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default withStyles(styles)(DropdownInput);
