import useFeatureFlags from 'common/hooks/useFeatureFlags';

const Feature = ({ children, fallback, name }) => {
  const activeFlags = useFeatureFlags();
  const isFlagActive = activeFlags.includes(name);
  if (isFlagActive) return children || null;
  return fallback || null;
};

export default Feature;
