import React from 'react';
import AccountLayout from './AccountLayout';
import Notifications from 'common/oldJavascripts/components/Pages/Account/Notification';

const NotificationsContainer = () => (
  <AccountLayout>
    <Notifications />
  </AccountLayout>
);

export default NotificationsContainer;
