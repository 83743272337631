import cloneDeep from 'lodash.clonedeep';
import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import { withStyles, ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import { AddCircleOutline as AddIcon } from '@mui/icons-material';
import * as Queries from 'common/apollo/queries';
import * as Mutations from 'common/apollo/mutations';
import ProjectTemplateWorkflow from './ProjectTemplateWorkflow';
import uniqueId from 'common/utilities/uniqueId';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import GraphQlMutationButton from 'common/components/GraphQlMutationButton';
import useSyncedState from 'common/hooks/useSyncedState';
import Loader from 'common/components/Loader';
import withApi from 'common/hoc/withApi';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';

const NONE_ROLE = { id: null, name: 'None' };

const theme = createTheme({
  palette: {
    primary: {
      main: '#0000FF',
    },
  },
});

const styles = theme => ({
  container: {
    width: '100%',
    background: 'white',
    borderTop: '1px solid #f2f5f7',
    padding: '22px 30px',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  rows: {
    marginTop: 30,
    width: '100%',
  },
  addWorkflowContainer: {
    width: '100%',
    textAlign: 'center',
    marginTop: 30,
  },
  addWorkflow: {
    width: 500,
    border: `1px solid #0000FF`,
    color: '#0000FF',
    '&:hover': {
      border: '1px solid #1A55FD',
    },
  },
  outlinedButton: {
    borderRadius: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: theme.spacing(1.7),
    textTransform: 'Capitalize',
    border: `1px solid #0000FF`,
    color: '#0000FF',
    '&:hover': {
      border: '1px solid #1A55FD',
    },
  },
  containedButton: {
    borderRadius: 0,
    fontSize: theme.spacing(1.7),
    textTransform: 'Capitalize',
    color: '#FFFFFF',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    background: '#0000FF',
    '&:hover': {
      background: '#1A55FD',
    },
    '&:active': {
      background: '#0000B3',
    },
  },
  cancel: {
    textDecoration: 'none',
    marginRight: theme.spacing(2),
  },
});

const newId = () => `new#${uniqueId()}`;

const ProjectTemplateWorkflows = ({
  classes,
  isTemplateI9,
  onSave,
  projectId,
  projectTemplateId,
  loading,
  templateName,
  templateRoles,
  templateRules,
  workflows: upstreamWorkflows,
}) => {
  const templateUrl = `/projects/${projectId}/project-templates#${projectTemplateId}`;
  const [workflows, setWorkflows] = useSyncedState(upstreamWorkflows, loading);

  if (loading || !workflows) return <Loader />;

  const addWorkflow = () =>
    setWorkflows(
      workflows.concat({
        id: newId(),
        rule: null,
        departments: [],
        levels: [],
      }),
    );

  const duplicateWorkflow = workflow => {
    const newWorkflow = cloneDeep(workflow);
    newWorkflow.id = newId();
    setWorkflows(workflows.concat(newWorkflow));
  };

  const updateWorkflow = workflow =>
    setWorkflows(workflows.map(w => (w.id === workflow.id ? workflow : w)));

  const deleteWorkflow = workflow =>
    setWorkflows(workflows.filter(({ id }) => id !== workflow.id));

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div>
            <Typography
              variant="h6"
              gutterBottom
              data-test-id="ProjectTemplateWorkflows-title"
            >
              <Link to={templateUrl}>{templateName}</Link> | Workflows
            </Typography>
            <Typography variant="body1">
              To add a workflow, select a condition, add a new level and choose
              the approvers for that level. Alternatively you can duplicate an
              existing workflow.
            </Typography>
          </div>
          <div>
            <Link to={templateUrl} className={classes.cancel}>
              <Button
                variant="outlined"
                data-test-id="ProjectTemplateWorkflows-cancel"
                className={classes.outlinedButton}
              >
                Cancel
              </Button>
            </Link>
            <GraphQlMutationButton
              variant="contained"
              onClick={() => onSave(workflows)}
              data-test-id="ProjectTemplateWorkflows-save"
              className={classes.containedButton}
            >
              Save
            </GraphQlMutationButton>
          </div>
        </div>
        <div className={classes.rows}>
          {workflows.map((workflow, index) => (
            <ProjectTemplateWorkflow
              workflow={workflow}
              templateRoles={templateRoles}
              templateRules={templateRules}
              index={index}
              isTemplateI9={isTemplateI9}
              key={workflow.id}
              onChange={updatedWorkflow => updateWorkflow(updatedWorkflow)}
              onDelete={() => deleteWorkflow(workflow)}
              onDuplicate={() => duplicateWorkflow(workflow)}
            />
          ))}
        </div>
        <div className={classes.addWorkflowContainer}>
          <Button
            variant="outlined"
            className={classes.addWorkflow}
            onClick={addWorkflow}
            data-test-id="ProjectTemplateWorkflows-add"
            startIcon={<AddIcon />}
          >
            Add Workflow
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
};

const convertNullRolesToEmpty = workflows =>
  workflows.map(workflow => ({
    ...workflow,
    levels: workflow.levels.map(level => ({
      ...level,
      role: level.role || NONE_ROLE,
    })),
  }));

const withWorkflows = graphql(Queries.projectTemplateWorkflows, {
  options: ({ projectTemplateId }) => ({
    variables: { id: projectTemplateId },
  }),
  props: ({
    data: {
      projectTemplate: {
        name,
        template: { roles, rules, specialTags = [] } = {},
        workflows,
      } = {},
      loading,
    } = {},
  }) => ({
    loading,
    templateName: name,
    templateRoles: [NONE_ROLE].concat(roles),
    templateRules: rules,
    workflows: convertNullRolesToEmpty(workflows || []),
    isTemplateI9: specialTags.includes('I-9'),
  }),
});

const withSaveWorkflows = graphql(Mutations.saveProjectTemplateWorkflows, {
  props: ({
    mutate,
    ownProps: {
      invalidateByPatterns,
      projectId,
      projectTemplateId,
      popSnackbarNotification,
      pushSnackbarNotification,
    },
  }) => ({
    onSave: workflows => {
      // Map Workflow objects to WorkflowInput objects
      workflows = workflows.map(
        ({
          id,
          departments,
          rule,
          overscaleConditionTriggered,
          levels,
          type,
        }) => ({
          // New workflows are submitted with null IDs.
          id: id.startsWith('new#') ? null : id,
          departmentIds: departments?.map(department =>
            parseInt(department.id),
          ),
          ruleId: rule && rule.id,
          type,
          overscaleConditionTriggered,
          levels: levels.map(({ name, role, users }) => ({
            name,
            roleId: role.id,
            userIds: users.filter(user => !!user).map(({ id }) => id),
          })),
        }),
      );
      return mutate({ variables: { projectTemplateId, workflows } })
        .then(() => {
          invalidateByPatterns([
            `/workflow/projects/${projectId}/project_templates_v2`,
            `/workflow/projects/${projectId}/project_templates`,
          ]);
          pushSnackbarNotification({
            message: 'Workflows saved successfully',
            variant: SnackbarVariants.SUCCESS,
          });
          pushSnackbarNotification({
            message: 'Workflows saved successfully',
            variant: SnackbarVariants.SUCCESS,
          });
        })
        .catch(err => {
          const { message } = err;
          popSnackbarNotification({
            message,
            popAllMatching: true,
            variant: SnackbarVariants.ERROR,
          });
          pushSnackbarNotification({
            message,
            variant: SnackbarVariants.ERROR,
          });
        });
    },
  }),
  options: ({ projectTemplateId }) => ({
    refetchQueries: [
      {
        query: Queries.projectTemplateWorkflows,
        variables: { id: projectTemplateId },
      },
    ],
  }),
});

export default compose(
  withSnackbarNotification,
  withStyles(styles),
  withRouteHelpers,
  withWorkflows,
  withApi,
  withSaveWorkflows,
)(ProjectTemplateWorkflows);
