import React from 'react';
import { withStyles } from '@mui/styles';
import DocumentField from '../../OfferPage/DocumentFields/DocumentField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import classNames from 'class-names';

const styles = theme => ({
  root: {
    padding: '10px 0px',
    boxShadow: 'none',
  },
  inputFieldContainer: {},
  header: {
    gridRow: 1,
    gridColumn: '1 / -2',
    color: '#000000',
  },
  completionStatus: {
    gridRow: 1,
    gridColumn: 3,
  },
  divider: {
    gridRow: 2,
    gridColumn: '1 / -1',
  },
  inputFieldRoot: {
    width: '100%',
    marginBottom: 20,
  },
  required: {
    color: '#ff0000',
    fontSize: '1.2rem',
  },
});

const DocumentFieldsGroup = props => {
  const { classes, formErrors, groupName, inputFields, isRequired, onChange } =
    props || {};
  const rootClass = classNames(classes.root);

  const getError = inputFieldId => {
    const { error } =
      formErrors.find(err => err.inputFieldId === inputFieldId) || {};
    return error;
  };

  return (
    <Paper className={rootClass}>
      <Typography variant="subtitle1" className={classes.header}>
        {groupName}
        <span className={classes.required}>{isRequired ? '*' : ''}</span>
      </Typography>
      <div className={classes.inputFieldContainer}>
        {inputFields.map(({ id, name, type, options, value }) => (
          <div key={id} className={classes.inputFieldRoot}>
            <DocumentField
              key={id}
              onChange={newValue => onChange(id, newValue)}
              value={value}
              error={getError(id)}
              fieldId={id}
              fieldName={name}
              fieldType={type}
              fieldOptions={options}
            />
          </div>
        ))}
      </div>
    </Paper>
  );
};

export default withStyles(styles)(DocumentFieldsGroup);
