import React from 'react';
import { withStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';

const styles = theme => ({
  fabProgress: {
    color: '#0000FF',
    position: 'absolute',
    zIndex: 1,
  },
});

const ProgressSpinner = props => {
  const { size, classes, top, left } = props;
  const dynamicStyles = {
    top,
    left,
  };
  return (
    <CircularProgress
      size={size}
      className={classes.fabProgress}
      style={dynamicStyles}
    />
  );
};

export default withStyles(styles)(ProgressSpinner);
