import React from 'react';

export default props => (
  <svg
    width="100"
    height="80"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M-2-4h64v64H-2z" />
      <path
        fill="white"
        fillRule="nonzero"
        d="M48 34.654H37.714V18.5H22.286v16.154H12L30 53.5z"
      />
      <text x="1" y="1" font-size="1" fill="white">
        Print Name
      </text>
    </g>
  </svg>
);
