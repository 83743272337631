import { useMutation } from '@apollo/react-hooks';
import * as Mutations from 'common/apollo/mutations';

const useCloneProject = () => {
  const [mutate, { loading, error }] = useMutation(Mutations.projectClone);
  const cloneProject = data => mutate({ variables: { ...data } });

  return [cloneProject, { loading, error }];
};

export default useCloneProject;
