import React, { useState, useEffect } from 'react';

// Components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/system';

// Utilities
import moment from 'moment';

const StyledButton = styled(Button)({
  color: '#000',
});

const useLogoutTimer = (logoutTime, logout) => {
  const [timeTillLogout, setLogoutTime] = useState(moment().to(logoutTime));

  useEffect(() => {
    const checkForLogout = () => {
      if (logoutTime.diff(moment()) < 10000) logout({ fromURI: 'href' });
    };
    const updateLogoutTime = () => {
      checkForLogout();
      setLogoutTime(moment().to(logoutTime));
    };
    const intervalTimer = setInterval(updateLogoutTime, 5000);
    return () => clearInterval(intervalTimer);
  }, [logoutTime, logout]);

  return timeTillLogout;
};

const SessionExpirationDialog = props => {
  const { time, logout, onClose } = props || {};

  const timeTillLogout = useLogoutTimer(time, logout);

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id="alert-dialog-title">Session Expiring Soon</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your session is about to expire, and you will be logged out{' '}
          {timeTillLogout}. If you would like to extend your session please
          click 'Extend Session' below.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={logout}>Logout</StyledButton>
        <Button onClick={onClose} color="primary" autoFocus>
          Extend Session
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionExpirationDialog;
