import React from 'react';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Table from 'common/oldJavascripts/components/Base/Table';
import Header from 'common/oldJavascripts/components/Base/Header';

import reimbursementTypes from 'common/oldJavascripts/utils/reimbursementTypes';
import ReimbursementRow from './ReimbursementRow';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

const Reimbursements = ({ data, onChange, errors }) => {
  const { reimbursements = {} } = data || {};
  const { reimbursements: reimbursementErrors = {} } = errors || {};
  const flags = useFeatureFlags();
  const isDurationFieldEnabled = flags?.includes('AllowanceDurationField');

  const reimbursementKey = type => {
    let key = type.replace(/_(\w)/g, m => m[1].toUpperCase());
    if (!key.match(/Allowance$/)) key = `${key}Allowance`;

    return key;
  };

  const reimbursementByType = type => reimbursements[reimbursementKey(type)];

  const handleChange = (type, field, value) => {
    const key = reimbursementKey(type);
    if (!reimbursements[key]) reimbursements[key] = {};
    reimbursements[key][field] = value;
    onChange('reimbursements', reimbursements);
  };

  const errorKey = type => {
    let key = type;
    if (!key.match(/_allowance$/)) key = `${key}_allowance`;

    return key;
  };

  return (
    <BoxItem>
      <Header>
        <Header.Title>
          <span data-test-id="ReimbursementsTable-title">
            Allowances / Reimbursements
          </span>
        </Header.Title>
      </Header>
      <Table
        grid={true}
        noWrap={true}
        overflowed={true}
        data-test-id="ReimbursementsTable-table"
      >
        <Table.Row header={true}>
          <Table.Cell />
          <Table.Cell align="center">Amount</Table.Cell>
          <Table.Cell align="center">Per</Table.Cell>
          <Table.Cell align="center">Cap</Table.Cell>
          {!isDurationFieldEnabled && (
            <Table.Cell align="center">Duration</Table.Cell>
          )}
        </Table.Row>
        {reimbursementTypes?.map(type => (
          <ReimbursementRow
            key={`reimbursement-row-${type}`}
            data={reimbursementByType(type)}
            type={type}
            onChange={handleChange}
            errors={reimbursementErrors[errorKey(type)]}
          />
        ))}
      </Table>
    </BoxItem>
  );
};

export default Reimbursements;
