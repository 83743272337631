import React from 'react';
import { withStyles } from '@mui/styles';

// MaterialUI Components
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// Utilities
import * as FieldTypes from 'common/utilities/constants/fieldTypes';
import sortByCoords from 'common/utilities/sortByCoords';

// Custom Components
import RadioItem from 'admin/components/RoleMapper/FieldDrawer/RadioItem';

const styles = {
  input: {
    width: '80%',
  },
};

const RuleAssignmentInput = props => {
  const {
    classes,
    fieldType,
    updateValue,
    options,
    value,
    radioGroupItems,
    textType,
  } = props;
  const input = (() => {
    switch (fieldType) {
      case FieldTypes.RDO:
        const radioMenuItems = sortByCoords(radioGroupItems, null, true).map(
          (radioButton, radioButtonIndex) => (
            <MenuItem
              value={radioButton.name}
              data-test-id={`RuleAssignmentInput-radioMenuItem-${radioButton.name}`}
              key={radioButton.name}
            >
              <RadioItem
                name={radioButton.radioValue}
                index={radioButtonIndex}
              />
            </MenuItem>
          ),
        );
        return (
          <Select
            value={value}
            className={classes.input}
            onChange={updateValue}
            data-test-id={`RuleAssignmentInput-valueSelect-${value}`}
            inputProps={{
              'data-test-id': `RuleAssignmentInput-valueInput-${value}`,
            }}
          >
            {radioMenuItems}
          </Select>
        );
      case FieldTypes.CHK:
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onChange={e =>
                  updateValue({
                    target: { value: e.target.checked },
                  })
                }
                data-test-id={`RuleAssignmentInput-valueCheckbox-${value}`}
                inputProps={{
                  'data-test-id': `RuleAssignmentInput-valueInput-${value}`,
                }}
              />
            }
            label="Checked"
          />
        );
      case FieldTypes.CMB:
        const dropdownMenuItems = options.map(option => (
          <MenuItem
            value={option}
            key={option}
            data-test-id={`RuleAssignmentInput-menuItem-${option}`}
          >
            {option}
          </MenuItem>
        ));
        return (
          <Select
            value={value}
            className={classes.input}
            onChange={updateValue}
            data-test-id="RuleAssignmentInput-dropdownSelect"
            inputProps={{
              'data-test-id': 'RuleAssignmentInput-dropdownSelectInput',
            }}
          >
            {dropdownMenuItems}
          </Select>
        );
      default:
        return (
          <TextField
            value={value}
            type={textType === 'number' ? 'number' : null}
            className={classes.input}
            onChange={updateValue}
            inputProps={{
              'data-test-id': `RuleAssignmentInput-value-${value}`,
            }}
          />
        );
    }
  })();
  return input;
};

export default withStyles(styles)(RuleAssignmentInput);
