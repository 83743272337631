const nonUnionHourly = termsV2 => {
  // CHECK
  const guaranteedHours6th = termsV2?.guaranteedHours6thDay
    ? termsV2?.rate
    : null;
  const guaranteedHours7th = termsV2?.guaranteedHours7thDay
    ? termsV2?.rate
    : null;

  return {
    rate_per_hour_studio: termsV2?.rate || null,
    overtime_rate: termsV2?.rateOvertime || null,
    pay_6th_day_rate: guaranteedHours6th,
    pay_7th_day_rate: guaranteedHours7th,
    guaranteed_hours_studio: termsV2?.guaranteedHours,
    guaranteedHoursFrequency: termsV2?.guaranteedHoursFrequency,
  };
};

export default nonUnionHourly;
