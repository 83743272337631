import React from 'react';

// Components
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MaskedInput from 'react-text-mask';

// Constants
import { ratePerHourMask } from '../../helpers/textFieldNumberMasks';

// Utilities
import formatCurrency from '../../helpers/formatCurrency';

const WeeklyRate = ({
  onChange: upstreamOnChange,
  formData: { weeklyRate, numberOfDaysPerWorkWeek = null } = {},
  classes = {},
}) => {
  const onChange = ({ target: { value } = {} } = {}) => {
    if (value === '' || value === '0') {
      upstreamOnChange({
        weeklyRate: null,
      });
      return;
    }
    upstreamOnChange({
      weeklyRate: value,
      dailyRate: null,
    });
    return;
  };

  const onBlur = ({ target: { value } = {} } = {}) => {
    if (value === '' || value === '0') return;

    const update = {
      weeklyRate: formatCurrency(value),
      dailyRate: null,
    };
    if (numberOfDaysPerWorkWeek === '' || numberOfDaysPerWorkWeek === '0') {
      update.numberOfDaysPerWorkWeek = '5';
    }
    upstreamOnChange(update);
    return;
  };

  return (
    <TableCell align="center" className={classes.root}>
      <MaskedInput
        mask={ratePerHourMask}
        value={weeklyRate || ''}
        onChange={onChange}
        onBlur={onBlur}
        render={(ref, props) => (
          <TextField
            inputRef={ref}
            {...props}
            variant="standard"
            className={classes.textField}
            InputProps={{
              className: classes.textFieldInputContainer,
              inputProps: {
                className: classes.textFieldInput,
                'data-test-id': 'WeeklyRate-input',
              },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        )}
      />
    </TableCell>
  );
};

export default WeeklyRate;
