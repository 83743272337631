import React, { useEffect, useState } from 'react';
import HireStateSelect from './HireStateSelect';
import HireCitySelect from './HireCitySelect';
import WorkStateSelect from './WorkStateSelect';
import WorkCitySelect from './WorkCitySelect';
import HiringStatusSelect from './HiringStatusSelect';
import UnionSelect from './UnionSelect';
import FringeSelect from './FringeSelect';
import OccupationSelect from './OccupationSelect';
import WorkScheduleSelect from './WorkScheduleSelect';
import DepartmentSelect from './DepartmentSelect';
import SeasonSelect from './SeasonSelect';
import CurrencySelect from './CurrencySelect';
import JobDescriptionInput from './JobDescriptionInput';
import EmploymentClassifcation from './EmploymentClassifcation';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import classNames from 'class-names';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';
import useProjectCurrencies from '../hooks/useProjectCurrencies';
import ScaleRatesSelect from './ScaleRatesSelect';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import SAGOccupationSelect from './Guild/OccupationSelect';
import SAGWorkScheduleSelect from './Guild/WorkScheduleSelect';
import useSAGGuilds from './Guild/hooks/useSAGGuilds';
import OffsiteWorkersSelect from './OffsiteWorkersSelect';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  header: {
    gridColumn: '1 / 3',
    gridRow: 1,
    marginBottom: 10,
  },
  hireState: {
    gridColumn: 1,
  },
  hireCity: {
    gridColumn: 1,
  },
  workState: {
    gridColumn: 1,
  },
  workCity: {
    gridColumn: 1,
  },
  employmentClassification: {
    gridColumn: 1,
    display: 'grid',
    width: '100%',
  },
  union: {
    gridColumn: 2,
    gridRow: 1,
  },
  rightItems: {
    width: '48%',
  },
  leftItems: {
    width: '48%',
  },
  itemSpacing: {
    marginTop: 10,
    marginBottom: 10,
  },
  workSchedule: {
    gridColumn: 2,
    gridRow: 3,
  },
  department: {
    gridColumn: 2,
    gridRow: 4,
  },
  season: {
    gridColumn: 1,
  },
  jobDescription: {
    gridColumn: 2,
    gridRow: 5,
  },
  hplusDept: {
    marginTop: 23,
  },
  selectContainer: {
    width: '100%',
  },
  error: {
    fontSize: '0.9rem',
    paddingLeft: '5px',
    display: 'inline-block',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  errorSelect: {
    borderColor: 'red',
  },
  selectLabel: {
    fontSize: '.9rem',
    padding: '5px',
  },
  description: {
    color: '#6D7278',
    fontSize: 12,
  },
});

const TermsOfHire = props => {
  const {
    offerDefaults: { enforceLoanOut, countryId, trackOffsiteWorkers },
    onChange,
    projectId,
    toggleLoanOutRestrictionModal,
    formData = {},
    formErrors = {},
    classes = {},
    isReviewOffer,
    disabled,
    canSelectScaleRates = null,
    isTermsSettingsLoading = false,
    contracts = [],
    fringeLoading = false,
    isNonUnion = false,
    fullyOffsiteWorker = false,
    isCopyOffer,
  } = props;

  const [unionCode, setUnionCode] = useState('');
  const {
    data: guildData = [],
    loading: guildLoading,
    error: { message: graphQlError = '' } = {},
  } = useSAGGuilds({
    unionCode: unionCode,
  });

  // const graphQlError = '';
  useEffect(() => {
    setUnionCode(formData?.union?.value);
  }, [formData]);

  const selectProps = key => ({
    disabled,
    onChange,
    formData,
    isReviewOffer,
    error: formErrors[key],
    classes: {
      root: classNames(classes.selectContainer, classes.itemSpacing),
      label: classes.selectLabel,
      select: classes.select,
      error: classes.error,
    },

    selectStyles: {
      control: (provided, state) => ({
        ...provided,
        '&:hover': {
          borderColor: '#0000FF',
        },
        borderColor: formErrors[key]
          ? 'red'
          : state.isSelected || state.isFocused
          ? '#0000FF'
          : 'hsl(0,0%,80%)',
      }),
      option: (provided, state) => ({
        ...provided,
        '&:hover': {
          borderColor: '#E2F1FF',
        },
        backgroundColor: state.isSelected
          ? '#0000FF'
          : state.isFocused
          ? '#D9E3FE'
          : state.backgroundColor,
      }),
    },
  });

  const { data: currencies } = useProjectCurrencies({ projectId });
  const { hiringStatus = null } = formData;
  const flags = useFeatureFlags();
  const isTrackOffsiteWorkersFlagOn = flags.includes('TrackOffsiteWorkers');
  const isCanadianNonUnionFringesOn = flags.includes('CanadianNonUnionFringes');
  const isSAGUnionFeatureOn = flags.includes('GuildManagement');
  const isOfferTrackOffsiteWorkerIncluded = formData?.trackOffsiteWorkers;

  const isSAGUnion = formData?.union?.value === 'SAG' && isSAGUnionFeatureOn;

  return (
    <div>
      <Typography className={classes.header} variant="h5">
        Terms of Hire
        <Typography variant="subtitle1" className={classes.description}>
          {isReviewOffer
            ? 'Review crew member and Union type'
            : 'Define crew member and Union type'}
        </Typography>
      </Typography>
      <div className={classes.root}>
        <div className={classes.leftItems}>
          {' '}
          <SeasonSelect
            {...selectProps('season')}
            isReviewOffer={isReviewOffer}
          />
          <HireStateSelect
            {...selectProps('hireState')}
            countryId={countryId}
          />
          <UsaOnly>
            <HireCitySelect
              {...selectProps('hireCity')}
              countryId={countryId}
            />
          </UsaOnly>
          <WorkStateSelect
            {...selectProps('workState')}
            countryId={countryId}
          />
          <UsaOnly>
            <WorkCitySelect
              {...selectProps('workCity')}
              countryId={countryId}
            />
            {!!hiringStatus && (
              <HiringStatusSelect {...selectProps('hiringStatus')} />
            )}
          </UsaOnly>
          <CanadaOnly>
            {currencies?.length > 1 && (
              <CurrencySelect
                {...selectProps('currency')}
                currencies={currencies}
              />
            )}
          </CanadaOnly>
          <EmploymentClassifcation
            {...selectProps('employmentClassification')}
            toggleModal={toggleLoanOutRestrictionModal}
            enforceLoanOut={enforceLoanOut}
          />
        </div>
        <div className={classes.rightItems}>
          <UnionSelect {...selectProps('union')} countryId={countryId} />
          {isSAGUnion ? (
            <>
              <SAGWorkScheduleSelect
                {...selectProps('workSchedule')}
                data={{
                  guilds: guildData,
                  loading: guildLoading,
                  error: graphQlError,
                }}
              />
              <SAGOccupationSelect
                {...selectProps('occupation')}
                data={guildData}
              />
            </>
          ) : (
            <>
              <OccupationSelect {...selectProps('occupation')} />
              <WorkScheduleSelect {...selectProps('workSchedule')} />
            </>
          )}

          {canSelectScaleRates && (
            <ScaleRatesSelect
              {...selectProps('payAtRollback')}
              isTermsSettingsLoading={isTermsSettingsLoading}
            />
          )}
          <CanadaOnly>
            {isNonUnion && isCanadianNonUnionFringesOn && (
              <FringeSelect
                {...selectProps('fringe')}
                contracts={contracts}
                fringeLoading={fringeLoading}
              />
            )}
          </CanadaOnly>
          <DepartmentSelect {...selectProps('department')} />
          <JobDescriptionInput {...selectProps('jobDescription')} />
          {((isOfferTrackOffsiteWorkerIncluded && !isCopyOffer) ||
            (isTrackOffsiteWorkersFlagOn && trackOffsiteWorkers)) && (
            <UsaOnly>
              <OffsiteWorkersSelect
                {...selectProps('fullyOffsiteWorker')}
                fullyOffsiteWorker={fullyOffsiteWorker}
              />
            </UsaOnly>
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(TermsOfHire);
