const validateFileType = (file, acceptTypeArr) => {
  let fileName = typeof file === 'string' ? file : file.name;
  const extensionIndex = fileName.lastIndexOf('.');
  const fileExtension =
    extensionIndex !== -1
      ? fileName.substr(extensionIndex + 1).toLowerCase()
      : '';
  return acceptTypeArr.includes(fileExtension);
};

export default validateFileType;
