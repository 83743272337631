import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const QUERY = gql`
  mutation removeDocument($documentId: String!, $note: String) {
    removeDocument(documentId: $documentId, note: $note)
  }
`;

const useRemoveDocument = () => {
  const [removeDocument, params] = useMutation(QUERY);
  return [removeDocument, params];
};

export default useRemoveDocument;
