import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createOfferCurrency as currency } from 'common/oldJavascripts/utils/format';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

const allowancesStyles = makeStyles(theme => ({
  documentTitle: {
    color: '#646464',
    fontSize: '24px !important',
    margin: '10px 0px !important',
  },
  allowanceTitle: {
    marginBottom: '10px',
  },
  allowanceItemTitle: {
    color: '#999999',
  },
  allowanceAmount: {
    marginTop: '10px',
    color: '#0000FF',
    fontSize: '24px',
  },
  perDay: {
    color: '#999999',
    fontSize: 14,
    fontWeight: 300,
    position: 'relative',
    bottom: '5px',
  },
  duration: {
    marginTop: '10px !important',
    color: '#999999',
  },
  durationInfo: {
    fontWeight: 500,
  },
  noDocs: {
    fontSize: '18px',
    color: '#999999',
    textAlign: 'center',
    paddingTop: '90px',
  },
  itemContainer: {
    '& div:nth-child(-n+2)': {
      borderBottom: '1px solid darkgrey',
    },
  },
  allowanceItemContainer: {
    paddingBottom: '15px',
    paddingTop: '10px',
    marginRight: '25px',
  },
}));

const isDecimalAmount = amount => {
  return !Number.isInteger(amount);
};

const Allowances = props => {
  const classes = allowancesStyles();
  const flags = useFeatureFlags();
  const isDurationFieldEnabled = flags?.includes('AllowanceDurationField');
  const { terms = {}, allowances = [] } = props;
  const isEmptyAllowance = allowances.every(({ key }) => !terms[key]?.amount);

  return (
    <div className={classes.documentsContainer}>
      <Typography
        variant="h2"
        className={[classes.documentTitle, classes.allowanceTitle].join(' ')}
        fontWeight={400}
      >
        Allowances
      </Typography>
      {isEmptyAllowance && (
        <Typography className={classes.noDocs}>No Allowances</Typography>
      )}
      {terms && (
        <Grid
          container
          justifyContent={'space-between'}
          className={classes.itemContainer}
        >
          {allowances.map(({ key, title }) => {
            const allowance = terms[key];
            if (!allowance?.amount) return null;
            const allowanceAmount = isDecimalAmount(allowance?.amount)
              ? currency(allowance?.amount)
              : `$${allowance?.amount}`;
            return (
              <Grid
                key={key}
                item
                xs={5}
                className={classes.allowanceItemContainer}
              >
                <Typography
                  fontWeight={500}
                  fontSize={14}
                  className={classes.allowanceItemTitle}
                >
                  {title}
                </Typography>
                <p className={classes.allowanceAmount}>
                  {allowanceAmount}
                  <span className={classes.perDay}>
                    {' '}
                    per {allowance?.perDay}
                  </span>
                </p>
                {!isDurationFieldEnabled && (
                  <Typography
                    fontWeight={300}
                    fontSize={12}
                    className={classes.duration}
                  >
                    Duration:
                    <span className={classes.durationInfo}>
                      {' '}
                      {allowance?.duration}
                    </span>
                  </Typography>
                )}
                <Typography className={classes.duration}>
                  Cap:{' '}
                  {allowance?.cap && (
                    <span className={classes.durationInfo}>
                      {' '}
                      ${allowance?.cap}
                    </span>
                  )}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export default Allowances;
