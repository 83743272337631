import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';
import Box from '../../Base/Box';
import Button from '../../Base/Button';
import FailureAlert from '../../Shared/FailureAlert';
import Form from '../../Base/Form';
import Header from '../../Base/Header';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Link from '../../Base/Link';
import NavBar from '../../Base/NavBar';
import NotificationsPicker from '../../Shared/Form/NotificationsPicker';
import PhoneVerificationModal from './PhoneVerificationModal';
import PhoneVerificationRow from './PhoneVerificationRow';
import Loader from 'common/components/Loader';
import Separator from '../../Base/Separator';
import status from '../../../utils/react/status';
import history from 'common/constants/config/history';

import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import { pushNotification } from 'common/store/actions/snackbarNotifications';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

class Notification extends Component {
  static mutators = {
    notificationPreferences: {
      info() {
        return {
          id: '/v2/notification-preferences',
        };
      },
      stale() {
        return ['/v2/notification-preferences'];
      },
    },
    phone: {
      info() {
        return {
          id: '/v2/phone',
        };
      },
      stale() {
        return ['/v2/phone'];
      },
    },
  };

  state = {
    formHasChanged: false,
    showVerificationModal: false,
  };

  render() {
    return (
      <LayoutContent>
        {this._renderPhoneModal()}
        {this._renderContent()}
      </LayoutContent>
    );
  }

  _getStatus = () => {
    const props = this.props;
    return status([props.notificationPreferences, props.phone]);
  };

  _goBack = () => {
    const { navigateToProfile } = this.props;
    if (navigateToProfile && typeof navigateToProfile === 'function') {
      navigateToProfile();
    } else {
      history.push('/account/profile');
    }
  };

  _hideVerificationModal = () => {
    this.setState({ showVerificationModal: false });
  };

  _isPhoneRequired = () => {
    const preferences = this.props.notificationPreferences.data.notifications;
    let isPhoneRowEnabled = false;

    preferences.forEach(option => {
      return Object.keys(option).forEach(key => {
        if (
          option[key]['delivery_type'] !== 'email' &&
          option[key]['enabled']
        ) {
          isPhoneRowEnabled = true;
        }
      });
    });

    return isPhoneRowEnabled;
  };

  _isSubmissible = () => {
    return (
      this.state.formHasChanged &&
      (!this._isPhoneRequired() || this.props.phone.data.is_phone_verified)
    );
  };

  _onFormChange = () => {
    this.setState({ formHasChanged: true });
  };

  _onSuccess = () => {
    this.props.pushNotification({
      message: 'Your notifications have been saved.',
      variant: SnackbarVariants.SUCCESS,
    });
    this.setState({ formHasChanged: false });
  };

  _renderContent = () => {
    const status = this._getStatus();

    switch (status) {
      case 'success': {
        return this._renderContentSuccess();
      }
      case 'loading': {
        return <Loader />;
      }
      default: {
        return this._renderContentFailed();
      }
    }
  };

  _renderContentFailed = () => {
    return (
      <Box minimal={true}>
        <BoxItem>
          <FailureAlert queryName="your email notifications" />
        </BoxItem>
      </Box>
    );
  };

  _renderContentSuccess = () => {
    return (
      <Box medium={true} overflow_initial={true}>
        <BoxItem>
          <Header>
            <Header.Title>
              <span data-test-id="Notification-title">Notifications</span>
            </Header.Title>
          </Header>
        </BoxItem>
        <Separator />
        {this._renderForm()}
      </Box>
    );
  };

  _renderForm = () => {
    const phoneRow = (
      <PhoneVerificationRow
        mutator={this.props.phone}
        verifyNumber={this._showVerificationModal}
        updateVerifiedStatus={this._updateVerificationStatus()}
      />
    );

    return (
      <Form action="edit" method="post" onSubmit={this._submit}>
        <BoxItem>
          <NotificationsPicker
            mutator={this.props.notificationPreferences}
            onPickerChange={this._onFormChange}
          />
          {this._isPhoneRequired() ? phoneRow : null}
        </BoxItem>
        <Separator />
        <BoxItem>{this._renderNavBar()}</BoxItem>
      </Form>
    );
  };

  _renderNavBar = () => {
    return (
      <NavBar>
        <NavBar.Item>
          <Button
            disabled={!this._isSubmissible()}
            data-test-id="Notification-save"
          >
            Save
          </Button>
        </NavBar.Item>
        <NavBar.Item>
          <Link
            onClick={this.props.history.goBack}
            data-test-id="Notification-cancel"
          >
            cancel
          </Link>
          {' or'}
        </NavBar.Item>
      </NavBar>
    );
  };

  _renderPhoneModal = () => {
    if (this.state.showVerificationModal) {
      return (
        <PhoneVerificationModal
          closeModal={this._hideVerificationModal}
          updateVerifiedStatus={this._updateVerificationStatus()}
        />
      );
    }
  };

  _showVerificationModal = () => {
    this.setState({
      showVerificationModal: true,
    });
  };

  _submit = () => {
    this.props.notificationPreferences.save('edit', {}, () =>
      this._onSuccess(),
    );
  };

  _updateVerificationStatus = () => {
    const phone = this.props.phone;
    return verified => {
      this._onFormChange();
      phone.update('is_phone_verified')(verified);
    };
  };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  pushNotification,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApi,
  withRouter,
)(Notification);
