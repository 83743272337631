import React from 'react';

const CopyToIcon = () => {
  return (
    <svg width="12" height="14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.278 1.273H7.723C7.467.535 6.794 0 6 0S4.533.535 4.277 1.273H1.722C1.05 1.273.5 1.845.5 2.545v10.182c0 .7.55 1.273 1.222 1.273h8.556c.672 0 1.222-.573 1.222-1.273V2.545c0-.7-.55-1.272-1.222-1.272zM6 1.273c.336 0 .611.286.611.636 0 .35-.275.636-.611.636a.626.626 0 0 1-.611-.636c0-.35.275-.636.611-.636zm4.278 11.454H1.722V2.545h1.222v1.91h6.112v-1.91h1.222v10.182z"
        fill="#000"
        fillRule="nonzero"
      />
    </svg>
  );
};
export default CopyToIcon;
