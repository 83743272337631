import React from 'react';

// Components
import Tooltip from '@mui/material/Tooltip';
import {
  Link as LinkIcon,
  LinkOff as LinkOffIcon,
  HelpOutline as HelpIcon,
} from '@mui/icons-material';

// HoC
import { withStyles } from '@mui/styles';

const styles = theme => ({
  root: {
    marginLeft: 8,
    display: 'flex',
  },
  linkButton: {
    height: '45px',
    width: '45px',
  },
  tooltipLinkIcon: {
    display: 'inline-block',
    position: 'relative',
    top: 3,
    height: 17,
  },
  label: {}, // Keep these here to make material-ui happy
  field: {}, // Keep these here to make material-ui happy
  icon: {
    placeSelf: 'end',
    marginRight: 20,
  },
});

const SendDateLinkHelp = props => {
  const { classes, formData: { isLinkActive } = {} } = props;

  return (
    <Tooltip
      disableInteractive
      title={
        isLinkActive ? (
          <p>
            Your project has been configured to send on start date by default.
            Click the <LinkIcon className={classes.tooltipLinkIcon} /> icon to
            override this setting.
          </p>
        ) : (
          <p>
            Your project has been configured to send on start date by default.{' '}
            <strong>You've overridden this setting.</strong> Click the{' '}
            <LinkOffIcon className={classes.tooltipLinkIcon} /> icon to revert
            to the default settings.
          </p>
        )
      }
      enterDelay={200}
      placement="right"
    >
      <div className={classes.root}>
        <HelpIcon className={classes.icon} />
      </div>
    </Tooltip>
  );
};

export default withStyles(styles)(SendDateLinkHelp);
