import React from 'react';
import { makeStyles } from '@mui/styles';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import usFlag from 'common/images/us-flag.png';
import canFlag from 'common/images/can-flag.png';
import useOfferCurrency from './useOfferCurrency';

const useStyles = makeStyles({
  flag: {
    display: 'block',
    margin: 'auto',
    width: 48,
    height: 48,
  },
  caption: {
    color: '#aaaaaa',
    fontSize: 12,
    fontWeight: 400,
    padding: 0,
    marginTop: 12,
    textAlign: 'center',
  },
});

const CurrencyIndicator = ({
  className,
  getRouterParam,
  countryCode,
  currencyClasses,
}) => {
  const offerId = getRouterParam('offerId');
  const { loading, data: currency } = useOfferCurrency({ offerId });
  const classes = useStyles();
  const flagClassName = currencyClasses?.flag
    ? currencyClasses?.flag
    : classes?.flag;
  const captionClassName = currencyClasses?.caption
    ? currencyClasses.caption
    : classes?.caption;

  if (loading) return null;

  const isCad = currency === 'CAD' || countryCode === 'CA';

  return (
    <div className={className} data-test-id="CurrencyIndicator-root">
      <img
        alt={`${isCad ? 'Canadian' : 'US'} Flag`}
        className={flagClassName}
        src={isCad ? canFlag : usFlag}
      />
      <div className={captionClassName}>{isCad ? 'CA' : 'US'} Currency</div>
    </div>
  );
};

export default withRouteHelpers(CurrencyIndicator);
