import React from 'react';
import SupportingDocument from './SupportingDocument';

const SupportingDocumentsList = props => {
  const {
    documents,
    offerId,
    uploadHandler,
    editHandler,
    deleteHandler,
    showDocumentHandler,
    // classes,
    isApproval,
  } = props;

  return (
    <div>
      {documents.map(doc => {
        const { supportingDocumentTitles, id: documentId } = doc;
        return supportingDocumentTitles.map((supportingDoc, index) => {
          const supportingDocumentId = supportingDoc.id;
          const apiData = {
            offerId,
            documentId,
            supportingDocumentId,
          };
          return (
            <SupportingDocument
              key={supportingDocumentId}
              doc={doc}
              editHandler={data => editHandler(data, apiData)}
              uploadHandler={formData => uploadHandler(formData, apiData)}
              deleteHandler={({ fileId }) =>
                deleteHandler({ fileId, supportingDocumentId, documentId })
              }
              showDocumentHandler={showDocumentHandler}
              index={index}
              isApproval={isApproval}
            />
          );
        });
      })}
    </div>
  );
};

export default SupportingDocumentsList;
