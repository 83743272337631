import React from 'react';
import domClasses from '../../../utils/dom/classes.js';

class ExpandableTable extends React.Component {
  static defaultProps = {
    noBody: false,
  };

  render() {
    let tableContainerClasses = null;
    let tableClasses = null;
    let content = null;

    if (this.props.noBody) {
      content = this.props.children;
    } else {
      content = <tbody>{this.props.children}</tbody>;
    }

    tableContainerClasses = domClasses.set(this.props.containerClassName, {
      expandableTable: true,
    });
    tableClasses = domClasses.set(this.props.className, {
      'expandableTable-body': true,
    });

    return (
      <div
        className={tableContainerClasses}
        data-test-id={this.props['data-test-id']}
      >
        <table className={tableClasses}>{content}</table>
      </div>
    );
  }
}

export default ExpandableTable;
