import React from 'react';

// Components
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// HoC
import { withStyles } from '@mui/styles';

const styles = theme => ({
  root: {
    alignContent: 'end',
    display: 'grid',
    gridTemplateRows: 'max-content max-content max-content',
  },
  header: {
    gridRow: 1,
  },
  button: {
    gridRow: 3,
    width: 182,
  },
  label: {}, // Keep these here to make material-ui happy
  error: {
    gridRow: 2,
    fontSize: '1rem',
  },
});

const AccountCodeModalButton = props => {
  const { onClick, formErrors = [], classes = {} } = props;
  const error = formErrors.length > 0;
  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.header}>
        Account Codes
      </Typography>
      {error && (
        <Typography
          color="error"
          variant="caption"
          className={classes.error}
          data-test-id="AccountCodeModalButton-error"
        >
          Please add a value for the required account codes
        </Typography>
      )}
      <Button
        variant="contained"
        size="large"
        color="primary"
        className={classes.button}
        onClick={onClick}
        data-test-id={'AccountCodeModalButton-button'}
      >
        Set Account Codes
      </Button>
    </div>
  );
};

export default withStyles(styles)(AccountCodeModalButton);
