import Alert from '../Base/Alert';
import ContactCustomerService from './ContactCustomerService';

import React from 'react';

class ErrorAlert extends React.Component {
  render() {
    const { children, className, contactCustomerService = true } = this.props;
    return (
      <Alert type="error" className={className}>
        {children}
        {contactCustomerService && <ContactCustomerService />}
      </Alert>
    );
  }
}

export default ErrorAlert;
