import React from 'react';

// HoC
import { withStyles } from '@mui/styles';
// Component
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';

const styles = theme => ({
  root: {},
  error: {
    color: `2px solid ${theme.palette.error.main}`,
  },
  inputLabel: {
    left: '-12px !important',
    top: '5px !important',
  },
});

const TextInput = props => {
  const { fieldId, fieldName, error, onChange, classes, value } = props || {};
  return (
    <FormControl fullWidth classes={{ root: classes.root }} error={error}>
      <InputLabel className={classes.inputLabel} htmlFor={fieldId}>
        {fieldName}
      </InputLabel>
      <Input
        name={fieldName}
        id={fieldId}
        onChange={({ target: { value } = {} } = {}) => onChange(value)}
        data-test-id={`TextInput-${fieldName}`}
        inputProps={{
          'data-test-id': `TextInput-input-${fieldName}`,
        }}
        value={value}
      />
      {error && (
        <FormHelperText className={classes.error}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default withStyles(styles)(TextInput);
