import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon } from '@mui/icons-material';

const styles = {
  active: {
    right: 0,
  },
  inActive: {
    right: '-100%',
  },
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    backgroundColor: 'white',
    zIndex: 100,
    width: '100%',
    transition: 'right .2s cubic-bezier(0.820, 0.085, 0.395, 0.895)',
    overflow: 'hidden',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '25px',
    zIndex: 100,
  },
};

const Sidebar = props => {
  const { open = false, children, classes, onClose } = props;
  return (
    <div
      className={classNames(classes.root, {
        [classes.active]: open,
        [classes.inActive]: !open,
      })}
    >
      {onClose && (
        <IconButton
          onClick={onClose}
          className={classes.closeButton}
          data-test-id="Sidebar-closeButton"
        >
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </div>
  );
};

export default withStyles(styles)(Sidebar);
