import React, { useEffect } from 'react';

// Components
import Select from 'react-select';
import Typography from '@mui/material/Typography';

// Utilities
import formatOptionLabel from '../formatOptionLabel';

const OccupationSelect = props => {
  const {
    onChange: upstreamOnChange,
    formData = {},
    data: guilds = [],
    error = '',
    selectStyles,
    classes = {},
    disabled,
  } = props;
  const { occupation, workSchedule } = formData || {};
  const { value: occupationValue } = occupation || {};

  const filteredGuilds = guilds.filter(
    guild => guild?.scheduleCode === workSchedule?.value,
  );

  const options = () => {
    // Format options for react-select
    return filteredGuilds.map(
      ({ occupationCode, occupationDescription, workerType }) => ({
        value: occupationCode,
        label: `${occupationCode} - ${occupationDescription}`,
        workerType,
      }),
    );
  };
  // We only store the code in our formData but we need to supply the formatted option
  // as the selected value
  const value =
    options().find(({ value }) => occupationValue === value) || null;

  const onChange = occupation => {
    const { workerType: selectedWorkerType } = occupation || {};
    delete occupation?.workerType;
    upstreamOnChange({
      occupation,
      workerType: selectedWorkerType,
    });
  };

  useEffect(() => {
    if (formData?.occupation && formData?.workerType) return;
    let options = filteredGuilds.map(
      ({ occupationCode, occupationDescription, workerType }) => ({
        value: occupationCode,
        label: `${occupationCode} - ${occupationDescription}`,
        workerType,
      }),
    );
    if (options.length === 1) {
      onChange(options[0]);
    }
  }, [guilds, formData]); // eslint-disable-line react-hooks/exhaustive-deps
  // if we include upstreamOnChange in the dependency array, it will cause an infinite loop

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1">
        Occupation
      </Typography>
      <div data-test-id="OccupationSelect-select">
        <Select
          styles={selectStyles}
          onChange={onChange}
          value={value}
          options={options()}
          className={classes.select}
          //   isLoading={loading}
          formatOptionLabel={formatOptionLabel('OccupationSelect')}
          isDisabled={disabled}
        />
      </div>
      {error && (
        <Typography
          color="error"
          variant="caption"
          className={classes.error}
          data-test-id="OccupationSelect-error"
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

export default OccupationSelect;
