import React from 'react';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import classNames from 'class-names';
import { withStyles } from '@mui/styles';

const styles = () => ({
  root: {
    color: '#0000FF',
  },
});

const AllowancesIcon = ({ className, classes }) => (
  <MoneyIcon className={classNames(classes.root, className)} />
);

export default withStyles(styles)(AllowancesIcon);
