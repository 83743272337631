import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Typography, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Transition from 'common/components/Transition';
import { CATEGORIES } from 'common/utilities/constants/myFileCategories';
import { compose } from 'redux';
import * as Mutations from 'common/apollo/mutations';
import { graphql } from 'react-apollo';
import {
  MYFILES_NEXT_BTN,
  REGULAR_STYLES,
} from 'common/components/DocumentUploader/Styles';
import { ChevronRight } from '@mui/icons-material';

const styles = theme => ({
  secondaryButton: {
    ...MYFILES_NEXT_BTN,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    backgroundColor: 'white',
    border: 'none',
    padding: 0,
    overflow: 'hidden',
    margin: '0 9px 40px 14px',
    boxShadow: 'inset -1px 1px -1px rgba(0, 0, 0, 0.8)',
    '& > *': {
      width: '100%',
    },
  },
  closeIcon: {
    top: theme.spacing(2),
    right: theme.spacing(2),
    position: 'absolute',
    cursor: 'pointer',
  },
  cancel: {
    marginRight: '1rem !important',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '30px',
    paddingBottom: '40px',
  },
  documentsContainer: {
    margin: '0 20px',
  },
  fileName: {
    ...REGULAR_STYLES,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  documentItem: {
    fontSize: '14px',
    textAlign: 'left',
  },
  disabledBtn: {
    ...MYFILES_NEXT_BTN,
    opacity: '0.4',
  },
  documentSectionTitle: {
    fontWeight: '700 !important',
    marginLeft: '10px !important',
    marginBottom: '15px !important',
  },
  mainTitle: {
    fontSize: '18px !important',
    ...REGULAR_STYLES,
  },
  subTitle: {
    marginTop: '20px !important',
    fontSize: '14px !important',
    ...REGULAR_STYLES,
  },
  dropDownIcon: {
    transform: 'rotate(90deg)',
    color: '#0000FF',
    position: 'relative',
    left: 0,
    top: 0,
  },
  documentInfo: {
    padding: '0 5px',
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '10px 14px',
    width: '92%',
  },
  filesContainer: {
    position: 'relative',
    left: 10,
  },
});
const DEFAULT_LABEL = 'Select My Files Location';

const UploadSuccessModal = props => {
  const { files, UploadMyFiles, isOpen, handleClose, classes } = props;
  const initialState = {
    selectedFiles: files,
    uploadError: [],
    fileSelected: false,
  };
  const [state, setState] = useState(initialState);
  const { selectedFiles, fileSelected } = state;

  const addUniqueIds = () => {
    let id = 0;
    let reformattedFiles = selectedFiles.map(obj => {
      obj['id'] = id++;
      return obj;
    });
    setState(state => {
      return { ...state, selectedFiles: reformattedFiles };
    });
  };

  useEffect(addUniqueIds);

  const calcFileSize = size => {
    const unit = ['B', 'kB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return `${(size / 1024 ** i).toFixed(2) * 1} ${unit[i]}`;
  };

  const handleChange = (ev, doc) => {
    const file = doc;
    setState(({ selectedFiles, ...rest }) => {
      let index = selectedFiles.findIndex(e => e.id === file.id);
      selectedFiles[index].category = ev.target.value;
      return {
        ...rest,
        selectedFiles,
        fileSelected: true,
      };
    });
  };

  const handleFileUpload = () => {
    selectedFiles.forEach(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onload = () => {
        const variables = {
          file: reader.result,
          name: file.fileName,
          category: file.category,
          contentType: file.file.type,
        };
        const mutate = UploadMyFiles;
        mutate({ variables }).catch(err =>
          setState(state => {
            return { ...state, uploadError: [{ [variables.name]: err }] };
          }),
        );
      };
    });
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle id="responsive-dialog-title" disableTypography>
        <Typography variant="h5" className={classes.mainTitle}>
          Upload successful!
        </Typography>
        <Typography variant="h6" className={classes.subTitle}>
          Would you like to copy these documents to your "My Files" section of
          "MyCast&Crew?"
        </Typography>
        <div className={classes.closeIcon}>
          <CloseIcon onClick={handleClose} />
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h5" className={classes.documentSectionTitle}>
          Documents
        </Typography>

        {selectedFiles.map(f => (
          <div className={classes.filesContainer}>
            <span className={classes.fileName}>{f.fileName}</span>
            <p>{calcFileSize(f.file.size)}</p>
            <Select
              value={f.category || DEFAULT_LABEL}
              onChange={ev => handleChange(ev, f)}
              IconComponent={() => (
                <ChevronRight classes={{ root: classes.dropDownIcon }} />
              )}
              variant="standard"
            >
              <MenuItem disabled value="Select My Files Location">
                <em>Select My Files Location</em>
              </MenuItem>
              {CATEGORIES.map(cat => (
                <MenuItem key={cat.key} value={cat.key}>
                  {cat.displayName}
                </MenuItem>
              ))}
            </Select>
          </div>
        ))}
      </DialogContent>
      <div className={classes.modalFooter}>
        <Button
          size="medium"
          variant="text"
          className={classes.cancel}
          onClick={handleClose}
          data-test-id="UploadSuccessModal-noThanks"
        >
          No Thanks
        </Button>
        <Button
          size="medium"
          variant="contained"
          data-test-id="MyFiles-select-button"
          disabled={!fileSelected}
          className={
            !fileSelected ? classes.disabledBtn : classes.secondaryButton
          }
          onClick={handleFileUpload}
        >
          Add to My Files
        </Button>
      </div>
    </Dialog>
  );
};

const withMyUploadMyFiles = graphql(Mutations.UploadMyFiles, {
  name: 'UploadMyFiles',
});

export default compose(
  withStyles(styles),
  withMyUploadMyFiles,
)(UploadSuccessModal);
