import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY_US = gql`
  query scaleRate(
    $projectId: Int!
    $startDate: String!
    $workState: String!
    $hireState: String!
    $workCity: String
    $hireCity: String
    $union: String!
    $occupation: String!
    $workSchedule: String!
    $season: Int
    $currency: String
  ) {
    scaleRate(
      projectId: $projectId
      startDate: $startDate
      workState: $workState
      hireState: $hireState
      workCity: $workCity
      hireCity: $hireCity
      union: $union
      occupation: $occupation
      workSchedule: $workSchedule
      season: $season
      currency: $currency
    ) {
      studioRatePerHour
      locationRatePerHour
      studioRatePerWeek
      locationRatePerWeek
      studioGuaranteedHours
      locationGuaranteedHours
      idleLocationSixthDayRatePerHour
      idleLocationSeventhDayRatePerHour
      overtimeRatePerHour
    }
  }
`;

const QUERY_CANADA = gql`
  query scaleRate(
    $projectId: Int!
    $startDate: String!
    $workState: String!
    $hireState: String!
    $workCity: String
    $hireCity: String
    $union: String!
    $occupation: String!
    $workSchedule: String!
    $season: Int
    $currency: String
  ) {
    scaleRate(
      projectId: $projectId
      startDate: $startDate
      workState: $workState
      hireState: $hireState
      workCity: $workCity
      hireCity: $hireCity
      union: $union
      occupation: $occupation
      workSchedule: $workSchedule
      season: $season
      currency: $currency
    ) {
      ratePerHour
      weeklyRate
      dailyRate
      overtimeRatePerHour
      guaranteedHoursDaily
      guaranteedHoursWeekly
    }
  }
`;

const QUERY_US_V2_WEEKLY = gql`
  query scaleRate(
    $projectId: Int!
    $startDate: String!
    $workState: String!
    $hireState: String!
    $workCity: String
    $hireCity: String
    $union: String!
    $occupation: String!
    $workSchedule: String!
    $season: Int
    $currency: String
    $rollback: Boolean
  ) {
    scaleRateV2(
      projectId: $projectId
      startDate: $startDate
      workState: $workState
      hireState: $hireState
      workCity: $workCity
      hireCity: $hireCity
      union: $union
      occupation: $occupation
      workSchedule: $workSchedule
      season: $season
      currency: $currency
      rollback: $rollback
    ) {
      rate
      ... on TermsOfEmploymentUnionHourly {
        rateDistant
        guaranteedHours
        guaranteedHoursDistant
        weeklyRate
        weeklyRateDistant
      }
    }
  }
`;

const QUERY_US_V2 = gql`
  query scaleRate(
    $projectId: Int!
    $startDate: String!
    $workState: String!
    $hireState: String!
    $workCity: String
    $hireCity: String
    $union: String!
    $occupation: String!
    $workSchedule: String!
    $season: Int
    $currency: String
    $rollback: Boolean
  ) {
    scaleRateV2(
      projectId: $projectId
      startDate: $startDate
      workState: $workState
      hireState: $hireState
      workCity: $workCity
      hireCity: $hireCity
      union: $union
      occupation: $occupation
      workSchedule: $workSchedule
      season: $season
      currency: $currency
      rollback: $rollback
    ) {
      rate
      ... on TermsOfEmploymentUnionHourly {
        rateDistant
        guaranteedHours
        guaranteedHoursDistant
      }
    }
  }
`;

const QUERY_US_V2_WEEKLY_ON_CALL = gql`
  query scaleRate(
    $projectId: Int!
    $startDate: String!
    $workState: String!
    $hireState: String!
    $workCity: String
    $hireCity: String
    $union: String!
    $occupation: String!
    $workSchedule: String!
    $season: Int
    $currency: String
    $rollback: Boolean
  ) {
    scaleRateV2(
      projectId: $projectId
      startDate: $startDate
      workState: $workState
      hireState: $hireState
      workCity: $workCity
      hireCity: $hireCity
      union: $union
      occupation: $occupation
      workSchedule: $workSchedule
      season: $season
      currency: $currency
      rollback: $rollback
    ) {
      rate
      ... on TermsOfEmploymentUnionWeekly {
        rateDistant
        guaranteedHours
        guaranteedHoursDistant
      }
    }
  }
`;

const useScaleRates = (variables, otherVariables) => {
  const {
    projectId,
    countryCode,
    startDate,
    workState,
    hireState,
    workCity,
    hireCity,
    currency,
    union,
    isNonUnion = false,
    occupation,
    workSchedule,
    isSAGUnion,
  } = variables;

  const {
    isUnionScheduleAV2,
    unionHourlyWeekly: isUnionWeeklyScheduleV2,
    isUnionWeeklyOnCall,
    isTermsSettingsLoading,
    payAtRollback,
  } = otherVariables;

  let QUERY;
  if (countryCode === 'CA') {
    QUERY = QUERY_CANADA;
  } else if (isUnionScheduleAV2) {
    QUERY = QUERY_US_V2;
  } else if (isUnionWeeklyScheduleV2) {
    QUERY = QUERY_US_V2_WEEKLY;
  } else if (isUnionWeeklyOnCall) {
    QUERY = QUERY_US_V2_WEEKLY_ON_CALL;
  } else {
    QUERY = QUERY_US;
  }

  const skip =
    !projectId ||
    !startDate ||
    !workState ||
    !hireState ||
    (countryCode === 'US' && !workCity) ||
    (countryCode === 'US' && !hireCity) ||
    !currency ||
    !union ||
    isNonUnion ||
    !occupation ||
    !workSchedule ||
    isTermsSettingsLoading ||
    isSAGUnion;

  const { loading, data } = useQuery(QUERY, {
    skip,
    variables: { ...variables, rollback: payAtRollback },
    notifyOnNetworkStatusChange: true,
  });

  let modifiedData = data?.scaleRate;
  if (
    countryCode === 'US' &&
    (isUnionWeeklyScheduleV2 || isUnionScheduleAV2 || isUnionWeeklyOnCall)
  ) {
    modifiedData = data?.scaleRateV2;
  }

  return {
    loading,
    data: !skip && data && { ...modifiedData },
  };
};

export default useScaleRates;
