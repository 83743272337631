import { useQuery } from '@apollo/react-hooks';
import { Queries } from 'common/apollo';

const useProjectSearch = (to, keyword, environment, nonRelated) => {
  const { loading, data, error } = useQuery(Queries.projectSearch, {
    skip: !to || !keyword,
    variables: {
      to: to,
      keyword: keyword,
      environment: environment,
      nonRelated,
    },
    fetchPolicy: 'no-cache',
  });

  return { loading, error, data: data && data.projectSearch };
};

export default useProjectSearch;
