import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { Error as FailureIcon } from '@mui/icons-material';

const styles = {
  dialogContent: {
    textAlign: 'center',
  },
  iconFail: {
    width: '100%',
    height: 200,
    color: '#ce7d7d',
  },
  titleH5: {
    display: 'inline',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
  error: {
    color: 'red',
  },
};

const Failure = props => {
  const { classes, message } = props;

  return (
    <div className={classes.dialogContent}>
      <Fragment data-test-id="ProjectCloneDialog-failure">
        <FailureIcon className={classes.iconFail} />
      </Fragment>
      <Typography variant="h5" className={classes.titleH5}>
        Something Went Wrong
      </Typography>
      <Typography variant="subtitle1">
        We were unable to clone the project.
      </Typography>
      {!!message && (
        <Typography className={classes.error}>Reason: {message}</Typography>
      )}
    </div>
  );
};

export default withStyles(styles)(Failure);
