import React from 'react';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

import classnames from 'classnames';

import useSendAdditionalDocuments from './hooks/useSendAdditionalDocuments';

import helperFunctions from './helpers';

const ConfirmButton = ({ classes, data, onError, onClose }) => {
  const { offers, documents, documentFields, note } = data;

  const [sendAdditinalDocuments, { loading }] = useSendAdditionalDocuments();

  const offerIds = offers.map(({ id }) => parseInt(id));

  const documentIds = helperFunctions.checkedDocumentsIds(documents);

  const sanitizedFields = documentFields.filter(({ documentId }) => {
    return documentIds.includes(documentId);
  });

  // make sure all values are strings
  const formattedDocumentFields = sanitizedFields
    .filter(({ value }) => value !== '')
    .map(({ id, value }) => ({
      id,
      value: `${value}`,
    }));

  const variables = {
    offerIds,
    documentsIds: documentIds.map(id => parseInt(id)),
    documentFields: formattedDocumentFields,
    note,
  };

  const handleSubmit = () => {
    sendAdditinalDocuments({ variables })
      .then(({ data }) => {
        const { sendAdditionalDocuments } = data;
        if (sendAdditionalDocuments) {
          onClose('sent');
        }
      })
      .catch(err => {
        onError(err.message);
      });
  };

  return (
    <Button
      disabled={loading}
      onClick={() => handleSubmit()}
      className={classnames(classes.btn, classes.btnContained)}
      data-test-id="submit-documents"
      variant="contained"
    >
      {loading ? (
        <>
          Sending... <CircularProgress size={20} />
        </>
      ) : (
        'Yes, Send Documents'
      )}
    </Button>
  );
};

export default ConfirmButton;
