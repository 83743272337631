import gql from 'graphql-tag';

export default gql`
  query adminUsers {
    adminUsers {
      id
      profile {
        email
        firstName
        middleName
        lastName
      }
      userPermissions {
        active
        permissions {
          id
        }
      }
    }
  }
`;
