import './shims';

// React
import React from 'react';
import ReactDOM from 'react-dom';

// NPM Providers
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Router } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { Provider as ReduxProvider } from 'react-redux';

// Custom Providers
import AuthenticationProvider from 'common/contextProviders/Authentication';
import FeatureFlagsProvider from 'common/components/FeatureFlagsProvider';
import { CookiesProvider } from 'react-cookie';

// Constants
import theme from 'common/shared/theme';
import apolloClient from 'common/apollo';

// Utilities
import store from 'common/store';
import history from 'common/constants/config/history';
import * as serviceWorker from './serviceWorker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

// Css
import './index.css';

// Component
import App from './App';

const render = Component => {
  ReactDOM.render(
    <ReduxProvider store={store}>
      <CookiesProvider>
        <Router history={history}>
          <ApolloProvider client={apolloClient}>
            <AuthenticationProvider>
              <FeatureFlagsProvider>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Component />
                  </LocalizationProvider>
                </ThemeProvider>
              </FeatureFlagsProvider>
            </AuthenticationProvider>
          </ApolloProvider>
        </Router>
      </CookiesProvider>
    </ReduxProvider>,
    document.getElementById('root'),
  );
};

// Turn off service worker
serviceWorker.unregister();

render(App);
