import React, { useEffect, useState } from 'react';
// HoC
import { connect } from 'react-redux';
// Utilities
import moment from 'moment';
import IdleTimer from 'react-idle-timer';
// Actions
import { logout } from 'common/store/actions/authenticate';
import { getSessionStatus } from 'common/store/actions/session';

// Components
import SessionExpirationDialog from 'common/components/SessionExpirationDialog';

const INTERVAL = 28 * 60 * 1000; // ms - request session status

const SessionManagementController = props => {
  const { getSessionStatus, logout } = props || {};
  const [displaySessionDialog, setDisplaySessionDialog] = useState(false);

  useEffect(() => {
    // Check if okta session is still valid every minute
    const sessionTrackerInterval = setInterval(getSessionStatus, INTERVAL);
    return () => clearInterval(sessionTrackerInterval);
  });

  const onIdle = () => setDisplaySessionDialog(true);
  const onClose = () => setDisplaySessionDialog(false);
  const fiveMinutesFromNow = () => moment().add(5, 'm');

  return (
    <React.Fragment>
      <IdleTimer element={document} onIdle={onIdle} timeout={1000 * 60 * 10} />
      {displaySessionDialog && (
        <SessionExpirationDialog
          time={fiveMinutesFromNow()}
          logout={logout}
          onClose={onClose}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  getSessionStatus,
  logout,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SessionManagementController);
