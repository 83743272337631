import gql from 'graphql-tag';

export default gql`
  mutation projectClone(
    $from: Int!
    $to: Int!
    $selection: ProjectCloneInput!
    $env: String!
  ) {
    projectClone(from: $from, to: $to, selection: $selection, environment: $env)
  }
`;
