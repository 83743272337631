import gql from 'graphql-tag';

export default gql`
  query permissions($admin: Boolean!) {
    permissions(admin: $admin) {
      id
      code
      description
      permissions {
        id
        code
        description
      }
      children {
        id
        code
        description
        permissions {
          id
          code
          description
        }
        children {
          id
          code
          description
          permissions {
            id
            code
            description
          }
          children {
            id
            code
            description
            permissions {
              id
              code
              description
            }
          }
        }
      }
    }
  }
`;
