import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import * as palette from 'common/shared/oldDocumentSignerUI/palette';

const styles = theme => ({
  active: {
    background: theme.palette.bottomDialogBackground,
    color: 'white',
    '&:hover': {
      background: theme.palette.bottomDialogBackground,
      color: 'white',
    },
  },
  tooltip: {
    fontSize: '0.9rem !important',
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const StyledButton = styled(Button)(({ theme }) => ({
  outline: 'none',
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  width: 60,
  height: 60,
  borderRadius: '0px',
  display: 'flex',
  color: '#848a8f',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: '500',
  '&.active': {
    background: theme.palette.bottomDialogBackground,
    color: 'white',
    '&:hover': {
      background: theme.palette.bottomDialogBackground,
      color: 'white',
    },
  },
  '&:hover': {
    background: palette.lightGrey,
    boxShadow: 'none',
  },
}));

const ToolbarButton = props => {
  const {
    title,
    disabled,
    classes,
    onClick,
    index,
    dataTestId,
    activeTab,
    icon,
    isEditingRoleName,
  } = props;
  const isActive = index === activeTab;
  const tooltipTitle = (() => {
    if (disabled)
      return 'Please complete field mapping before navigating to another tab.';
    if (isEditingRoleName)
      return 'Please complete editing the Role Name before navigating to another tab';
    return '';
  })();
  return (
    <Tooltip
      disableInteractive
      title={tooltipTitle}
      classes={{ tooltip: classes.tooltip }}
      data-test-id={`ToolbarButton-tooltip-${title}`}
    >
      <div>
        <StyledButton
          variant="contained"
          disabled={disabled || isEditingRoleName}
          className={classNames({ active: isActive })}
          data-test-id={dataTestId}
          onClick={() => onClick(index)}
          classes={{ label: classes.buttonContent }}
          disableRipple
        >
          {icon}
          {title}
        </StyledButton>
      </div>
    </Tooltip>
  );
};

export default withStyles(styles)(ToolbarButton);
