import React from 'react';
import PaginationPage from './PaginationPage';

class Pagination extends React.Component {
  render() {
    var buttons = [];
    var totalPages = this.props.totalPages;
    var page = this.props.page;
    var change = this.props.onChange;

    if (totalPages > 1) {
      if (page > 1) {
        buttons.push(
          <PaginationPage
            key="prev"
            bound={true}
            page={page - 1}
            onClick={change}
          >
            Previous
          </PaginationPage>,
        );
        if (page > 2) {
          buttons.push(<PaginationPage page={1} key={1} onClick={change} />);
        }
        if (page > 3) {
          buttons.push('...');
        }
        buttons.push(
          <PaginationPage page={page - 1} key={page - 1} onClick={change} />,
        );
      }
      buttons.push(
        <PaginationPage
          current={true}
          page={page}
          key={page}
          onClick={change}
        />,
      );
      if (page < totalPages) {
        if (page < totalPages) {
          buttons.push(
            <PaginationPage page={page + 1} key={page + 1} onClick={change} />,
          );
        }
        if (page + 1 < totalPages) {
          if (page + 2 < totalPages) {
            buttons.push('...');
          }
          buttons.push(
            <PaginationPage
              page={totalPages}
              key={totalPages}
              onClick={change}
            />,
          );
        }
        buttons.push(
          <PaginationPage
            bound={true}
            page={page + 1}
            key="next"
            onClick={change}
          >
            Next
          </PaginationPage>,
        );
      }
    }

    return (
      <nav className="pagination" data-test-id={this.props['data-test-id']}>
        <p className="pagination-text">
          Page {this.props.page} of {this.props.totalPages}
        </p>
        {buttons}
      </nav>
    );
  }
}

export default Pagination;
