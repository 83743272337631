import React, { Component } from 'react';
import BoxBody from 'common/oldJavascripts/components/Base/Box/BoxBody';
import BoxSection from 'common/oldJavascripts/components/Base/Box/BoxSection';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Data from 'common/oldJavascripts/components/Base/Data';
import Loader from 'common/components/Loader';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';

// HoC
import withApi from 'common/hoc/withApi';

class ImportedSettings extends Component {
  static queries = {
    project: {
      info(_, related) {
        const params = related['/router/params'];
        return {
          id: `/projects/${params.projectId}`,
        };
      },
    },

    settings: {
      info(_, related) {
        const params = related['/router/params'];
        return {
          id: `/v2/projects/${params.projectId}/settings`,
        };
      },
    },

    unions: {
      info(_, related) {
        const params = related['/router/params'];
        return {
          id: `/payroll/projects/${params.projectId}/unions`,
        };
      },
    },
  };

  renderItem = (label, value) => (
    <Data.Item>
      <Data.Label>{label}</Data.Label>
      <Data.Value right={true}>{value}</Data.Value>
    </Data.Item>
  );

  _renderUnions = (unions = []) =>
    unions.map((union = {}) => (
      <Data.Item key={`union-${union.code}`}>
        <Data.Value>{union.description}</Data.Value>
      </Data.Item>
    ));

  // TODO STAR-2088 Remove local storage saving of this info
  _updateLocalStorage() {
    const {
      project: { data: { producer_id, producer_name } = {} } = {},
    } = this.props;
    localStorage.setItem('producerId', producer_id);
    localStorage.setItem('producerName', producer_name);
  }

  isLoading = (...statuses) => statuses.some(status => status === 'loading');

  isFailed = (...statuses) => statuses.some(status => status === 'failed');

  render() {
    const {
      project: projectQuery = {},
      settings: settingsQuery = {},
      unions: unionsQuery = {},
    } = this.props;
    const { status: projectStatus, data: projectData = {} } =
      projectQuery || {};
    const { status: settingsStatus, data: settingsData = {} } =
      settingsQuery || {};
    const { status: unionsStatus, data: { items: unions = [] } = {} } =
      unionsQuery || {};
    const {
      production_company: company = {},
      media_type: mediaType = {},
      payroll = {},
      producer = {},
      production_type: productionType = {},
      shoot_location: shootLocation,
    } = settingsData || {};
    const {
      name: projectName,
      cnc_id: projectNumber,
      requires_state_w4: requiresStateW4 = false,
    } = projectData || {};
    const {
      name: prodCoName,
      cnc_id: prodCoNumber,
      federal_tax_id: federalTaxId,
    } = company || {};
    const { code: mediaCode, description: mediaDescription } = mediaType || {};
    const { name: producerName } = producer || {};
    const { description: productionTypeDescription } = productionType || {};
    const { database: payrollDatabase } = payroll || {};

    if (this.isLoading(projectStatus, settingsStatus, unionsStatus))
      return <Loader />;
    if (this.isFailed(projectStatus, settingsStatus, unionsStatus))
      return <FailureAlert />;

    this._updateLocalStorage();

    return (
      <BoxBody>
        <BoxItem>
          <BoxSection first={true}>
            <Data>
              <Data.Header>Project Information</Data.Header>
              {this.renderItem('Project Name', projectName)}
              {this.renderItem('Production Company Name', prodCoName)}
              {this.renderItem('Production Company Number', prodCoNumber)}
              {this.renderItem('Project Number', projectNumber)}
              {this.renderItem('Producer', producerName)}
              {this.renderItem(
                'Producer requires state W-4',
                String(requiresStateW4).toUpperCase(),
              )}
              {this.renderItem(
                'Production Company Federal Tax ID',
                federalTaxId,
              )}
            </Data>
          </BoxSection>
          <BoxSection>
            <Data>
              <Data.Header>Payroll Database: {payrollDatabase}</Data.Header>
              {this.renderItem('Production Type', productionTypeDescription)}
              {this.renderItem(
                'Media Type',
                `${mediaCode} - ${mediaDescription}`,
              )}
            </Data>
          </BoxSection>
        </BoxItem>
        <BoxItem>
          <BoxSection first={true}>
            <Data>
              <Data.Header>Unions</Data.Header>
              {this._renderUnions(unions)}
            </Data>
          </BoxSection>
        </BoxItem>
        <BoxItem>
          <BoxSection first={true}>
            <Data>
              <Data.Header>Comments</Data.Header>
              <Data.Item>
                <Data.Value>{shootLocation}</Data.Value>
              </Data.Item>
            </Data>
          </BoxSection>
        </BoxItem>
      </BoxBody>
    );
  }
}

export default withApi(ImportedSettings);
