import React from 'react';
import { makeStyles } from '@mui/styles';

import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import ajax from 'common/utilities/ajax';
import config from 'common/oldJavascripts/config.js';

import Button from 'common/oldJavascripts/components/Base/Button';
import Field from 'common/oldJavascripts/components/Base/Field';
import Link from 'common/oldJavascripts/components/Base/Link';

const useStyles = makeStyles({
  navBarActions: {
    display: 'grid',
    gridTemplateAreas: `
      '................... clearFilters'
      'downloadAsSinglePdf downloadAllButton'
    `,
    gridColumnGap: '5px',
    columnGap: '5px',
    gridRowGap: '10px',
    rowGap: '10px',
    justifyContent: 'flex-end',
  },
  downloadAsSinglePdf: {
    gridArea: 'downloadAsSinglePdf',
  },
  downloadAllButton: {
    gridArea: 'downloadAllButton',
  },
  clearFilters: {
    gridArea: 'clearFilters',
    justifySelf: 'end',
  },
  btn: {
    '&:active': {
      backgroundColor: '#000080',
    },
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
});

const FilterActions = props => {
  const { filters, onChange, projectId, ready = false } = props;
  const {
    fromDate = '',
    toDate = '',
    documentType = [],
    department = [],
    employee = '',
    createMerged = false,
  } = filters;
  const classes = useStyles();

  const downloadCheckboxVisible = (documentType || []).length > 0;

  const isEnabled =
    fromDate ||
    toDate ||
    employee ||
    documentType?.length ||
    department?.length;

  const downloadFilteredFiles = () => {
    const { pushNotification, popNotification } = props;

    pushNotification({
      message: 'Hold on a moment while we prepare the document for download.',
      variant: SnackbarVariants.INFO,
    });
    ajax
      .post(
        `/projects/${projectId}/offers-documents/files/download`,
        {
          payload: {
            from_date: fromDate && fromDate.format(config.DATE_FORMAT.DB),
            to_date: toDate && toDate.format(config.DATE_FORMAT.DB),
            document_type: documentType || [],
            department: department || [],
            employee_name: employee,
            create_merged: createMerged,
          },
        },
        { json: true },
      )
      .then(() => {
        popNotification();
        pushNotification({
          message:
            "Your file is being created. You'll receive an email when it's done.",
          variant: SnackbarVariants.SUCCESS,
        });
      })
      .catch(() => {
        popNotification();
        pushNotification({
          message:
            'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.',
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  const setFilters = newData => {
    onChange(filters => ({
      ...filters,
      ...newData,
    }));
  };

  const clearFilters = () => {
    setFilters({
      fromDate: null,
      toDate: null,
      documentType: null,
      department: null,
      employee: null,
    });
  };

  if (!ready) return null;

  return (
    <div className={classes.navBarActions}>
      <div
        className={classes.downloadAsSinglePdf}
        data-test-id="OffersDocuments-downloadSinglePDF"
      >
        {downloadCheckboxVisible && (
          <Field
            type="checkbox"
            name="create_merged"
            value={createMerged}
            onChange={val => setFilters({ createMerged: val })}
            label="Download as single PDF"
          />
        )}
      </div>
      <div className={classes.downloadAllButton}>
        <Button
          className={classes.btn}
          disabled={!isEnabled}
          onClick={downloadFilteredFiles}
        >
          Download All Documents
        </Button>
      </div>
      <div className={classes.clearFilters}>
        <Link onClick={clearFilters}>Clear filters</Link>
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  pushNotification,
  popNotification,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FilterActions,
);
