import React, { useState } from 'react';

// MUI
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';

// UTILS
import formatItems from 'common/utilities/formatItems';

// HOOKS
import useOfferFilters from 'studio/components/ManageOffers/hooks/useOfferFilters';
import useOfferDefaults from 'studio/components/OfferPage/hooks/useOfferDefaults';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
  chipText: {
    marginRight: '5px',
    maxWidth: 300,
  },
}));

const FiltersSelect = props => {
  const {
    upstreamFiltersChange,
    filterValues,
    name: filtersName,
    projectId,
  } = props;

  const classes = useStyles();

  const { data: filtersData = [], loading: loadingFilters } = useOfferFilters(
    filtersName,
    projectId,
  );
  const { data: offerDefaults = '' } = useOfferDefaults(projectId);

  const formmatedOptions = formatItems(filtersData);

  const [inputValue, setInputValue] = useState('');

  const filterUpdatedValues =
    filtersName === 'employmentClassification'
      ? offerDefaults?.countryCode === 'CA'
        ? formmatedOptions?.map(option => {
            if (option?.label === 'w2') {
              option.label = 'Individual';
              return option;
            }
            return option;
          })
        : formmatedOptions
      : formmatedOptions;

  const onFiltersChange = (event, values) => {
    upstreamFiltersChange(filtersName, values);
  };

  return (
    <div data-test-id={`filters-select-${filtersName}`}>
      <Autocomplete
        multiple
        id="manage-offer-filters"
        name={filtersName}
        value={filterValues}
        inputValue={inputValue}
        loading={loadingFilters}
        getOptionLabel={option => option.label}
        options={filterUpdatedValues?.sort((a, b) =>
          a.label.localeCompare(b.label),
        )}
        groupBy={option =>
          filtersName === 'employees' ? option.label[0].toUpperCase() : false
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        limitTags={2}
        disableCloseOnSelect
        onChange={onFiltersChange}
        renderTags={value => {
          // Limits displayed tags when dropdown is active
          const numTags = value.length;
          const limitTags = 2;

          return (
            <>
              {value.slice(0, limitTags).map((option, index) => (
                <Chip
                  className={classes.chipText}
                  key={index}
                  label={option.label}
                />
              ))}

              {numTags > limitTags && ` + ${numTags - limitTags}`}
            </>
          );
        }}
        renderOption={(props, option, { selected }) => (
          <div
            {...props}
            data-test-id={`filters-option-${option.label}`}
            key={option.id}
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </div>
        )}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            onBlur={() => setInputValue('')}
            onChange={e => setInputValue(e.target.value)}
            placeholder="Search or select"
          />
        )}
      />
    </div>
  );
};

export default FiltersSelect;
