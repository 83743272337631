import isFilledIn from '../../utilities/isFilledIn';

const fieldStatusClass = fieldProps => {
  const {
    classes,
    value,
    _derived: { error, isDisabled, isRequired },
  } = fieldProps;
  if (error) return classes.error;
  if (isRequired) {
    if (isFilledIn(value)) return classes.success;
    return classes.required;
  }
  if (isDisabled) return classes.readOnly;
  return classes.optional;
};

export default fieldStatusClass;
