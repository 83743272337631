import React, { useState, useCallback, useEffect } from 'react';

// HoC
import { compose } from 'redux';
import withApi from 'common/hoc/withApi';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import { withRouter } from 'react-router';
import { withStyles } from '@mui/styles';

// Components
import { ThemeProvider, Paper } from '@mui/material';
import { documentSignerTheme } from 'common/shared/oldDocumentSignerUI/theme';
import _ from 'lodash';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import Loader from 'common/components/Loader';
import RoleMapper from 'admin/components/RoleMapper';
import Ajax from 'common/utilities/ajax';
import { parseErrorMessages } from 'common/oldJavascripts/utils/errorMessageParser';

// Utilities
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';

const failureAlert = failureMessage => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <FailureAlert queryName={failureMessage} />
  </div>
);

const styles = theme => ({
  root: {
    height: 'calc(100% - 61px)',
    width: '100%',
    overflow: 'auto',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridAutoRows: '100%',
    gridTemplateAreas: `
    "content"
    `,
  },
  content: {
    backgroundColor: theme.palette.common.white,
    gridArea: 'content',
  },
});

const RoleMapperWrapper = props => {
  const {
    classes,
    routerParams: params = {},
    systemFields: systemFieldsQuery = {},
    fields: fieldsQuery = {},
    rules: rulesQuery = {},
    textTypes: textTypesQuery = {},
    template: templateQuery = {},
    mapFields,
    invalidateByPatterns,
    pushSnackbarNotification,
    location: { pathname } = {},
    responseBehavior: responseBehaviorQuery = {},
    triggerActions: triggerActionsQuery = {},
    badgeTypes: badgeTypesQuery = {},
  } = props;
  const { documentId } = params || {};
  const { data: badgeData = [] } = badgeTypesQuery || {};
  const { data: systemFields = [] } = systemFieldsQuery || {};
  const { data: textTypes = [] } = textTypesQuery || {};
  const { status: templateStatus, data: templateData = {} } =
    templateQuery || {};
  const { status: fieldsStatus } = fieldsQuery || {};
  const { status: responseBehaviorStatus, data: responseBehaviorOptions = [] } =
    responseBehaviorQuery || {};
  const { status: triggerActionsStatus, data: triggerActions = [] } =
    triggerActionsQuery || {};
  const { status: rulesStatus } = rulesQuery || {};
  const { status: badgeTypesStatus, data: badgeTypes = [] } =
    badgeTypesQuery || {};

  const [fields, setFields] = useState([]);
  const [rules, setRules] = useState([]);
  const [customInputs, setCustomInputs] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [template] = useState({}); // TODO: Do we need a state here?
  const [imageLoadFailed, setImageLoadFailed] = useState(false);
  const [saveDraftIsLoading, setSaveDraftIsLoading] = useState(false);
  const [templateLoadedTimes] = useState(0); // TODO: Do we need a state here?

  const saveCustomInputs = _.debounce((customInputs = []) => {
    const { documentId } = params;
    // remove client generated id's
    const formattedInputs = customInputs.map(input => {
      const updatedInput = { ...input };
      const updatedInputFields = [...updatedInput.inputFields]
        .map(inputField => {
          const updatedInputField = { ...inputField };
          if (`${updatedInputField.name}`.includes('__new-')) {
            delete updatedInputField.name;
          }
          return updatedInputField;
        })
        .filter(updated => !(!updated.name && !updated.active));
      if (`${updatedInput.id}`.includes('__new-')) {
        delete updatedInput.id;
      }
      updatedInput.inputFields = updatedInputFields;
      return updatedInput;
    });
    Ajax.patch(
      `/workflow/templates/${documentId}/custom_fields`,
      { payload: formattedInputs },
      {
        json: true,
      },
    ).then(data => {
      setCustomInputs(data);
      systemFieldsQuery.reload();
    });
  }, 800);

  const requestCustomInputs = useCallback(
    () =>
      Ajax.get(`/workflow/templates/${documentId}/custom_fields`).then(data =>
        setCustomInputs(data),
      ),
    [documentId],
  );

  // load custom inputs
  useEffect(() => {
    if (customInputs === null) requestCustomInputs();
  }, [customInputs, requestCustomInputs]);

  // Fields Hook
  useEffect(() => {
    const { status: fieldsStatus, data = [] } = fieldsQuery || {};
    // Reload if the previously requested fields are stale
    // if (fieldsStatus === 'success' && fields.length > 0) fieldsQuery.reload();

    // Store data in state so we can update, after save draft responses are received
    if (fieldsStatus === 'success' && fields.length === 0) {
      setFields(Array.isArray(data) ? data : []);
    }
  }, [fieldsQuery, fields]);

  // Rules Hook
  useEffect(() => {
    const { status: rulesStatus, data } = rulesQuery || {};
    // Reload if the previously requested rules are stale
    // if (rulesStatus === 'success' && rules.length > 0) rulesQuery.reload();

    // Store data in state so we can update, after save draft responses are received
    if (rulesStatus === 'success' && rules.length === 0) {
      setRules(Array.isArray(data) ? data : []);
    }
  }, [rulesQuery, rules]);

  const deleteCustomInput = inputId => {
    if (`${inputId}`.includes('__new-')) return;
    Ajax.delete(`/workflow/templates/${documentId}/custom_fields/${inputId}`)
      .then(() => requestCustomInputs())
      .then(() => systemFieldsQuery.reload());
  };

  const formatDocumentData = data => {
    const badgeTypes = Array.isArray(badgeData) ? badgeData : [];
    const { fieldGroups = [], openFields = [], rules = [], inputs = [] } = data;
    const formattedFieldGroups = fieldGroups.map(group => {
      const updatedGroup = { ...group };
      if (`${updatedGroup.id}`.includes('__new-')) {
        delete updatedGroup.id;
      }
      if (updatedGroup.delete) {
        updatedGroup.fields = [];
      }
      return updatedGroup;
    });

    const formattedRules = rules.map(rule => {
      let updatedRule = _.cloneDeep(rule);
      // delete the id if it's generated on the client side
      if (
        `${updatedRule.id}`.includes('__new-') ||
        badgeTypes.map(({ name }) => name).includes(`${updatedRule.id}`)
      ) {
        delete updatedRule.id;
      }

      updatedRule.fieldGroups = updatedRule.fieldGroups.map(fieldGroup => {
        const updatedFieldGroup = { ...fieldGroup };
        // delete the id if it's generated on the client side
        if (`${updatedFieldGroup.id}`.includes('__new-')) {
          delete updatedFieldGroup.id;
        }
        return updatedFieldGroup;
      });
      return updatedRule;
    });
    return {
      fieldGroups: formattedFieldGroups,
      openFields,
      rules: formattedRules,
      inputs,
    };
  };

  const saveDraft = data => {
    return new Promise(resolve => {
      setSaveDraftIsLoading(true);
      const { fieldGroups, openFields, rules } = formatDocumentData(data);
      mapFields.updateAndSave(
        {
          field_groups: fieldGroups,
          open_fields: openFields,
          rules,
          ready: false,
        },
        'edit',
        { json: true },
        ({ fields, rules }) => {
          setFields(fields);
          setRules(rules);
          setSaveDraftIsLoading(false);
          resolve();
        },
        () => {
          setSaveDraftIsLoading(false);
          resolve();
        },
      );
    });
  };

  const submit = data => {
    setIsSaving(true);
    const { fieldGroups, openFields, rules } = formatDocumentData(data);
    // save mapped fields
    mapFields.revert('ready').then(() => {
      mapFields.updateAndSave(
        {
          field_groups: fieldGroups,
          open_fields: openFields,
          rules,
        },
        'edit',
        { json: true },
        successHandler,
        errorHandler,
      );
    });
  };

  const successHandler = ({ rules, fields }) => {
    invalidateByPatterns([`/workflow/fields?template_id=${documentId}`]);
    setIsSaving(false);
    setSaveSuccess(true);
    setRules(rules);
    setFields(fields);

    pushSnackbarNotification({
      message: `Document saved successfully.`,
      variant: SnackbarVariants.SUCCESS,
      duration: 7000,
    });
  };

  const errorHandler = res => {
    const message = parseErrorMessages(res);
    setIsSaving(false);
    pushSnackbarNotification({
      message: `There was an error while saving: ${message}`,
      variant: SnackbarVariants.ERROR,
      duration: 7000,
    });
  };

  const _updateLocalStorage = () =>
    !_.isEmpty(template) && localStorage.setItem('templateId', template.id);

  const getDocumentImageUrls = ({ documentId, page }) => {
    const url = `/workflow/templates/${documentId}`;
    return Ajax.get(url)
      .then(data =>
        data.images.items
          .filter(img => !page || img.page === page)
          .map(({ url }) => url),
      )
      .catch(_err => setImageLoadFailed(true));
  };

  _updateLocalStorage();

  const loadingStatuses = [
    rulesStatus,
    fieldsStatus,
    templateStatus,
    responseBehaviorStatus,
    triggerActionsStatus,
    badgeTypesStatus,
  ];

  if (loadingStatuses.some(status => status === 'loading')) return <Loader />;

  if (imageLoadFailed) {
    return failureAlert('the document images');
  }

  if (fieldsStatus === 'failed' || templateStatus === 'failed') {
    return failureAlert('the document');
  }

  if (
    fieldsStatus === 'success' &&
    templateStatus === 'success' &&
    !templateData.images
  ) {
    return <Loader />;
  }

  if (fieldsStatus === 'success' && templateStatus === 'success') {
    const { fieldGroups, images, name: title } = templateData;
    const { items: docImages = [] } = images;
    if (docImages.length === 0) {
      return failureAlert('the document images');
    }

    const rolesProp = fieldGroups.map((fg, index) => ({
      name: fg.name,
      id: fg.id,
      fieldsMapped: 0,
      isDefault: fg.initial,
    }));
    const fieldsProp = Array.isArray(fields) ? fields : [];
    const documentProp = {
      id: templateData.id,
      name: templateData.name,
      pages: docImages.length,
      height: images.height,
      width: images.width,
      fieldsMapped: templateData.fieldsMapped,
      fieldGroups: templateData.fieldGroups,
      images: docImages,
      fields: fieldsProp,
    };
    const replacementStatus = pathname.includes('replacement');
    const systemFieldsProp = Array.isArray(systemFields) ? systemFields : [];
    const textTypesProp = Array.isArray(textTypes) ? textTypes : [];
    const responseBehaviorProp = Array.isArray(responseBehaviorOptions)
      ? responseBehaviorOptions
      : [];
    const triggerActionsProp = Array.isArray(triggerActions)
      ? triggerActions
      : [];
    const rulesProps = Array.isArray(rules) ? rules : [];
    const badgeProps = Array.isArray(badgeTypes) ? badgeTypes : [];
    return (
      <ThemeProvider theme={documentSignerTheme}>
        <div className={classes.root}>
          <Paper className={classes.content}>
            <RoleMapper
              title={title}
              roles={rolesProp}
              groups={rolesProp}
              doc={documentProp}
              documents={[documentProp]}
              submitGroupMappings={submit}
              saveDraft={saveDraft}
              textFieldOptions={textTypesProp}
              systemFieldOptions={systemFieldsProp}
              isSaving={isSaving}
              saveSuccess={saveSuccess}
              isReplacement={replacementStatus}
              replacement={replacementStatus}
              key={templateLoadedTimes}
              getDocumentImageUrls={getDocumentImageUrls}
              rules={rulesProps}
              saveCustomInputs={saveCustomInputs}
              inputs={customInputs}
              deleteCustomInput={deleteCustomInput}
              responseBehaviorOptions={responseBehaviorProp}
              triggerActions={triggerActionsProp}
              saveDraftIsLoading={saveDraftIsLoading}
              badgeTypes={badgeProps}
            />
          </Paper>
        </div>
      </ThemeProvider>
    );
  }

  return <div>no data</div>;
};

RoleMapperWrapper.queries = {
  me: {
    info() {
      return {
        id: '/me',
      };
    },
  },
  textTypes: {
    info() {
      return {
        id: '/workflow/fields/text_types',
      };
    },
  },
  systemFields: {
    info(_, related) {
      const { documentId } = related['/router/params'];
      return {
        id: `/workflow/templates/${documentId}/system-fields`,
      };
    },
  },
  template: {
    info(_, related) {
      const { documentId } = related['/router/params'];
      return {
        id: `/workflow/templates/${documentId}`,
        expires: true,
      };
    },
  },
  fields: {
    info(_, related) {
      const { documentId } = related['/router/params'];
      return {
        id: `/workflow/fields?template_id=${documentId}`,
      };
    },
  },
  rules: {
    info(_, related) {
      const { documentId } = related['/router/params'];
      return {
        id: `/workflow/templates/${documentId}/rules`,
      };
    },
  },
  responseBehavior: {
    info() {
      return {
        id: '/workflow/response-behavior-types',
      };
    },
  },
  triggerActions: {
    info() {
      return {
        id: '/workflow/trigger-action-types',
      };
    },
  },
  badgeTypes: {
    info() {
      return {
        id: '/workflow/badge-types',
      };
    },
  },
};
RoleMapperWrapper.mutators = {
  mapFields: {
    info(_, related) {
      const params = related['/router/params'];
      const documentId = params && params.documentId;
      return {
        id: `/workflow/templates/${documentId}/field_groups/map_fields`,
        create: true,
      };
    },
  },
};

export default compose(
  withApi,
  withRouter,
  withSnackbarNotification,
  withStyles(styles),
)(RoleMapperWrapper);
