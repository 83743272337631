import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Button from 'common/oldJavascripts/components/Base/Button';
import Dialog from '@mui/material/Dialog';
import AddProjectDocument from 'admin/components/AddProjectDocument';
import Notification from 'studio/components/OfferPage/OfferDrafts/Notification';

const styles = {
  dialogPaper: {
    padding: 10,
    borderRadius: 10,
  },
  closeButton: {
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
    textDecoration: 'underline',
    fontSize: '1rem',
  },
};

const AttachTemplateWrapper = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [message, setMessage] = useState('');
  const { projectId, classes, attachSuccessHandler } = props;
  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = message => {
    setIsModalOpen(false);
    if (message === 'added') {
      setSnackOpen(true);
      setMessage('Documents added successfully');
    }
  };

  return (
    <div>
      <Button onClick={openModal}>Add Documents</Button>
      {isModalOpen && (
        <Dialog
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          classes={{ paper: classes.dialogPaper }}
        >
          <AddProjectDocument
            projectId={projectId}
            attachSuccessHandler={attachSuccessHandler}
            onClose={message => handleClose(message)}
          />
        </Dialog>
      )}
      <Notification
        message={message}
        open={snackOpen}
        autoHide={2000}
        setOpen={setSnackOpen}
        severity={'success'}
      />
    </div>
  );
};

export default withStyles(styles)(AttachTemplateWrapper);
