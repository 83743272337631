import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';

const StyledLink = styled(Link)(({ active }) => ({
  fontSize: '14px',
  color: active ? '#000000' : '#667085',
  fontWeight: 600,
  height: '49px',
  textDecoration: 'none',
  textTransform: 'none',
  fontFamily: 'Inter',
  padding: '15px 15px',
  paddingBottom: '0px',
  background: 'none',
  display: '-webkit-inline-box',
  textAlign: 'center',
  borderBottom: active ? '2px solid #155EEF' : 'none',
}));

const SubNavItem = props => {
  const { location = {}, to, isActive, ...rest } = props;
  const { pathname } = location || {};
  var classes = null;
  if (to) {
    if (typeof isActive === 'undefined') {
      return (
        <StyledLink
          {...rest}
          to={`/${to}`}
          active={pathname.includes(to)}
          params={props.params}
          query={props.query}
        >
          {props.children}
        </StyledLink>
      );
    } else {
      return (
        <StyledLink
          {...rest}
          to={`/${to}`}
          active={isActive}
          params={props.params}
        >
          {props.children}
        </StyledLink>
      );
    }
  } else {
    return <span className={classes}>{props.children}</span>;
  }
};

export default withRouter(SubNavItem);
