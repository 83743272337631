import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { Chip, Input, FormControl, InputLabel } from '@mui/material';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  form: {
    flexDirection: 'column',
  },
  input: {
    padding: 0,
  },
  chipContainer: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '100%',
  },
  chipRoot: {
    fontSize: '0.75rem',
    height: 20,
    borderRadius: theme.spacing(1.5),
    margin: 2,
  },
  chipDeleteIcon: {
    width: theme.spacing(2),
  },
  chipLabel: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  errorLabel: {
    color: theme.palette.error.main,
  },
  label: {
    left: '-12px !important',
  },
});

class UploadedDocumentTags extends Component {
  state = { inputValue: '', error: null };

  handleDelete = data => () => {
    const { deleteHandler } = this.props;
    deleteHandler(data);
  };

  handleChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  handleKeyPress = event => {
    if (event.key === ',' || event.key === 'Enter') {
      event.preventDefault(); // Prevents handle change from being called
      const v = this.state.inputValue.trim();
      const { addHandler } = this.props;
      if (v.length >= 2 && v.length <= 50) {
        addHandler(v);
        this.setState({ inputValue: '', error: null });
      } else {
        this.setState({ error: 'Tag must be between 2 and 50 characters' });
      }
    }
  };

  render() {
    const { classes, tags, editable, form } = this.props;
    const { inputValue, error } = this.state;
    const chips = deleteable =>
      tags.map((tag, index) => (
        <Chip
          key={`${tag.name}-${index}`}
          label={tag.name}
          onDelete={deleteable ? this.handleDelete(tag) : null}
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
            label: classes.chipLabel,
          }}
          data-test-id={`DocumentTags-chip-${tag.name}`}
        />
      ));
    const flexDirection = classNames(classes.root, { [classes.form]: form });
    const labelClasses = classNames(classes.label, {
      [classes.errorLabel]: error,
    });
    return editable ? (
      <div className={flexDirection}>
        <FormControl>
          <InputLabel
            htmlFor="tag-input"
            error={error}
            className={labelClasses}
          >
            {error || `Input tags separated by a ',' or 'Enter'`}
          </InputLabel>
          <Input
            error={error}
            value={inputValue}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            margin="none"
            className={classes.input}
            inputProps={{
              'data-test-id': 'DocumentTags-input',
            }}
          />
        </FormControl>
        <div className={classes.chipContainer}>{chips(true)}</div>
      </div>
    ) : (
      <div className={flexDirection}>
        <div>{chips(false)}</div>
      </div>
    );
  }
}

export default withStyles(styles)(UploadedDocumentTags);
