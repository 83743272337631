import gql from 'graphql-tag';

export default gql`
  query myFiles($status: String, $category: String) {
    myFiles(status: $status, category: $category) {
      id
      name
      category
      contentType
      status
      active
      getUrl
      putUrl
      createdAt
    }
  }
`;
