import React, { useState } from 'react';
import { Paper, Button, CircularProgress, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/system';
import {
  Publish as PublishIcon,
  GetApp as GetAppIcon,
} from '@mui/icons-material';
import UploadModal from 'admin/components/GuildManagement/UploadModal';
import GuildTable from 'admin/components/GuildManagement/GuildTable';
import ajax from 'common/utilities/ajax';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import { compose } from 'redux';
import useGuildTerms from 'admin/components/GuildManagement/hooks/useGuildTerms';
import moment from 'moment';

const styles = {
  dialogPaper: {
    minWidth: 500,
    minHeight: 300,
  },
  closeButton: {
    height: 35,
    borderRadius: '2px',
    position: 'absolute',
    right: 8,
    top: 8,
  },
  dropzoneContainer: {
    border: '2px dashed',
    borderRadius: '3px',
    borderColor: 'grey',
    backgroundColor: '#ffffff',
    textAlign: 'center',
    height: 70,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    color: 'rgba(0, 0, 0, 0)',
    padding: '20px 10px',
    margin: '12px 0px',
    overflow: 'hidden',
  },
  browseLink: {
    color: '#0000FF',
    cursor: 'pointer',
  },
  fileDisplay: {
    backgroundColor: '#f4f4f4',
    padding: '10px',
    color: '#000000',
    minHeight: '100px',
  },
  btnsDivider: {
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
  },
  updatedBy: {
    marginLeft: 10,
  },
  paper: {
    padding: '1rem 2rem 2rem',
    minHeight: '80vh',
  },
  fileName: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#000000',
  },
  loader: {
    color: '#0000FF',
    marginLeft: 10,
  },
};

const StyledContainedButton = styled(Button)({
  textTransform: 'none',
  borderRadius: 0,
  marginRight: 16,
});

const StyledOutlinedButton = styled(Button)({
  borderRadius: 0,
  textTransform: 'none',
  marginRight: 16,
});

const GuildManagement = ({ classes, pushSnackbarNotification }) => {
  const [open, setOpen] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const {
    loading,
    error,
    data: guildItems,
    updatedBy = {},
    refetch,
  } = useGuildTerms();

  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadSuccess = isUploadSuccess => {
    if (isUploadSuccess) {
      refetch();
    }
    setOpen(false);
  };

  const csvFileDownloader = data => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'guild.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleDownload = () => {
    setDownloadLoading(true);
    ajax
      .get('/guild-terms')
      .then(res => {
        csvFileDownloader(res);
        pushSnackbarNotification({
          message: 'File downloaded successfully',
          variant: SnackbarVariants.SUCCESS,
        });
        refetch();
        setDownloadLoading(false);
      })
      .catch(err => {
        pushSnackbarNotification({
          message: 'There was an error while trying to download the file',
          variant: SnackbarVariants.ERROR,
        });
        setDownloadLoading(false);
      });
  };

  return (
    <Paper elevation={0} className={classes.paper}>
      <div className={classes.btnsDivider}>
        <StyledContainedButton
          color="primary"
          variant="contained"
          className={classes.btnContained}
          startIcon={<PublishIcon />}
          onClick={() => setOpen(true)}
          data-test-id="GuildManagement-uploadBtn"
        >
          Upload Guild Data
        </StyledContainedButton>
        <StyledOutlinedButton
          color="primary"
          variant="outlined"
          onClick={() => handleDownload()}
          disabled={downloadLoading}
          className={classes.btnOutlined}
          startIcon={<GetAppIcon />}
          data-test-id="Guild-Download-Btn"
        >
          Download Guild Data
        </StyledOutlinedButton>
        {!downloadLoading && updatedBy && (
          <Typography variant="body2" className={classes.updatedBy}>
            Last Updated on {moment(updatedBy?.timestamp).format('MM-DD-YYYY')}{' '}
            by {updatedBy?.fullName}
          </Typography>
        )}
        {downloadLoading && <CircularProgress className={classes.loader} />}
      </div>
      <UploadModal
        open={open}
        onClose={handleClose}
        onSuccess={info => handleUploadSuccess(info)}
        classes={classes}
      />
      <GuildTable data={guildItems} loading={loading} error={error} />
    </Paper>
  );
};

export default compose(
  withStyles(styles),
  withSnackbarNotification,
)(GuildManagement);
