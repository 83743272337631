import React, { useState, useEffect } from 'react';
import { Table, TableCell, TableRow } from '@mui/material';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import {
  ratePerHourMask,
  guaranteedHoursMask,
} from '../../helpers/textFieldNumberMasks';
import MaskedInput from 'react-text-mask';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import formatCurrency from 'studio/components/OfferPage/helpers/formatCurrency';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 40,
    marginBottom: 40,
  },
  terms: {
    marginTop: 10,
  },
  innerCell: {
    borderTop: 'none',
  },
  cellTitle: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  dollar: {
    width: '5%',
    fontSize: 14,
    marginTop: 7,
    color: '#696969',
    paddingLeft: 14,
    fontFamily: 'Roboto, Helvetica, Helvetica Neue, san-serif',
  },
  tableSpacing: {
    marginTop: 16,
    marginBottom: 20,
    border: '1px solid #0000FF',
  },
  topSpacing: {
    marginTop: 20,
  },
  hoursCopy: {
    marginTop: 7,
    paddingRight: 12,
    color: '#525252',
    fontSize: 16,
    fontWeight: 'normal',
  },
  errorIcon: {
    color: 'red',
    position: 'relative',
    top: 4,
    right: 8,
  },
  errorLabel: {
    color: 'red',
    fontSize: 14,
    textAlign: 'center',
  },
  warningIcon: {
    color: '#FFA800',
    position: 'relative',
    top: 4,
    right: 8,
  },
  warningLabel: {
    color: '#FFA800',
    fontSize: 14,
    textAlign: 'center',
    maxWidth: '65%',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hoursPerDay: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
  },
  label: {
    color: '#262626',
    fontSize: 16,
  },
  rightCellArea: {
    width: '60%',
    backgroundColor: '#FFFFFF',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: '5px !important',
    paddingBottom: '5px !important',
    paddingLeft: '8px !important',
  },
  fieldArea: {
    width: '40%',
    backgroundColor: '#FFFFFF!important',
  },
  noTop: {
    borderTop: 'none',
  },
  inputSpacing: {
    marginLeft: '21%',
    marginRight: '2% !important',
    width: '30%',
    '& input': {
      textAlign: 'center',
    },
  },
}));

const RateTableInputField = props => {
  const {
    rowTitle,
    rowPaymentType,
    currencySymbol,
    rowRate,
    rateKey,
    errorRateKey,
    formData,
    formErrors = {},
    isReadOnly,
    isTableHeader,
    handleHoursType,
    handleRateType,
    isWarning,
    warningMessage,
    onChange: upstreamOnChange,
    handleOvertimeRate,
  } = props;

  const classes = useStyles();
  const [rowValue, setRowValue] = useState(rowRate);

  useEffect(() => {
    setRowValue(rowRate);
  }, [rowRate]);

  const getTypedValue = (ev = {}, formKey) => {
    const { target: { value: rawValue } = {} } = ev;
    let value = rawValue;
    if (rawValue === '' || rawValue === '0') value = '';
    return value;
  };

  const onChange = (ev = {}) => {
    const typedValue = getTypedValue(ev);
    let value = typedValue > 0 ? typedValue : null;
    const decimalValue = value > 0 ? formatCurrency(value) : null;
    const { dailyRate, weeklyRate } = formData;
    handleRateType(rowPaymentType);
    const isNotPermittedWeeklyRate =
      rowPaymentType === '/week' && dailyRate > 0;
    const isNotPermittedDailyRate = rowPaymentType === '/day' && weeklyRate > 0;
    setRowValue(decimalValue);
    handleRateType(rowPaymentType);
    if (isNotPermittedWeeklyRate) {
      upstreamOnChange({
        dailyRate: null,
      });
    }
    if (isNotPermittedDailyRate) {
      upstreamOnChange({
        weeklyRate: null,
      });
    }

    if (rowPaymentType === '/hour') {
      handleOvertimeRate(value, rowTitle);
      return;
    }

    if (rowPaymentType === 'hours/day') {
      handleHoursType('daily');
      setRowValue(value);
    }
    if (rowPaymentType === 'hours/week') {
      handleHoursType('weekly');
      setRowValue(value);
    }
  };

  const onBlur = () => {
    upstreamOnChange({
      [rateKey]: rowValue,
    });
  };
  const errorMessage = formErrors[rateKey] || formErrors[errorRateKey];
  const inputMask =
    rateKey === 'guaranteedHours' ? guaranteedHoursMask : ratePerHourMask;
  return (
    <Table className={isTableHeader ? classes.terms : null}>
      <TableRow>
        <TableCell
          className={classNames(classes.cellTitle, {
            [classes.noTop]: !isTableHeader,
          })}
        >
          <div className={classes.errorContainer}>
            <Typography
              className={classes.hoursPerDay}
              data-test-id={`${rowTitle}-title`}
            >
              {rowTitle}
            </Typography>
            {isWarning && !errorMessage && (
              <Typography
                color="warning"
                variant="caption"
                className={classes.warningLabel}
                data-test-id={`${rowTitle}-RateTable-warning-message`}
              >
                <WarningIcon className={classes.warningIcon} />
                {warningMessage}
              </Typography>
            )}
            {errorMessage && (
              <Typography
                color="warning"
                variant="caption"
                className={classes.errorLabel}
                data-test-id={`${rowTitle}-RateTable-error-message`}
              >
                <WarningIcon className={classes.errorIcon} />
                {errorMessage}
              </Typography>
            )}
          </div>
        </TableCell>
        <TableCell
          className={classNames(classes.rightCellArea, {
            [classes.noTop]: !isTableHeader,
          })}
        >
          <div className={classes.itemContainer}>
            <Typography className={classes.dollar}>
              {currencySymbol && <span>$</span>}
            </Typography>
            <MaskedInput
              mask={inputMask}
              value={rowValue || ''}
              onChange={onChange}
              onBlur={onBlur}
              className={classes.inputSpacing}
              render={(ref, props) => (
                <TextField
                  inputRef={ref}
                  {...props}
                  variant="standard"
                  disabled={isReadOnly}
                  InputProps={{
                    inputProps: {
                      'data-test-id': `${rowTitle}-input`,
                    },
                  }}
                />
              )}
            />
            <Typography className={classes.hoursCopy}>
              {rowPaymentType && <span>{rowPaymentType}</span>}
            </Typography>
          </div>
        </TableCell>
      </TableRow>
    </Table>
  );
};

export default RateTableInputField;
