import { useMutation } from '@apollo/react-hooks';
import * as Mutations from 'common/apollo/mutations';
const useReplaceUser = () => {
  const [mutate, { loading, error }] = useMutation(
    Mutations.ReplaceNonEmployee,
  );
  const replaceOldUser = data => mutate({ variables: { ...data } });
  return [replaceOldUser, { loading, error }];
};

export default useReplaceUser;
