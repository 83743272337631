import React from 'react';
import { withStyles } from '@mui/styles';
import { Box, Button, Grid } from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopy';

import OfferStatus from 'common/oldJavascripts/components/Shared/OfferStatus';
import DealMemoErrors from './DealMemoErrors';
import dateTime from './dateTime';
import { styled } from '@mui/system';

const styles = {
  label: {
    fontWeight: 600,
  },
  payloadContainer: {
    position: 'relative',
  },
  payload: {
    whiteSpace: 'pre',
    overflow: 'auto',
    maxHeight: '300px',
    background: '#eee',
    fontFamily: 'monospace',
    padding: 12,
  },
  statusBadge: {
    display: 'inline-block',
    width: 'auto',
  },
};

const StyledButton = styled(Button)({
  background: '#eee',
  color: '#555',
  position: 'absolute',
  zIndex: 99,
  top: 18,
  right: 36,
  '&:hover, &:focus': {
    outline: 'none',
    backgroundColor: '#1A55FD',
    color: '#fff',
  },
  '&:active': {
    backgroundColor: '#000080',
    color: '#fff',
  },
});

const copyToClipboard = data => {
  if (!data) return false;

  const clip = navigator?.clipboard;
  if (!clip) return false;

  return clip.writeText(data);
};

const DealMemoPayload = ({ data, classes, onCopy }) => {
  const { id, status, dealMemo } = data;
  const { sent_on, payload, errors: dmErrors = [] } = dealMemo || {};

  const payloadString = JSON.stringify(payload, null, 4);

  const copy = () => {
    const p = copyToClipboard(payloadString);
    if (!p) return;

    p.then(() => onCopy(true)).catch(err => onCopy(false, err));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={2} className={classes.label}>
        Offer Id:
      </Grid>
      <Grid item xs={10} data-test-id="OfferDealMemo-offer-id">
        {id}
      </Grid>
      <Grid item xs={2} className={classes.label}>
        Offer status:
      </Grid>
      <Grid item xs={10} data-test-id="OfferDealMemo-offer-status">
        <OfferStatus status={status} className={classes.statusBadge} />
      </Grid>
      <Grid item xs={2} className={classes.label}>
        Sent On:
      </Grid>
      <Grid item xs={10} data-test-id="OfferDealMemo-offer-dealmemo-sent-on">
        {sent_on && dateTime(sent_on)}
        {!sent_on && '-'}
      </Grid>
      <Grid item xs={2} className={classes.label}>
        Payload:
      </Grid>
      <Grid
        item
        xs={10}
        className={classes.payloadContainer}
        data-test-id="OfferDealMemo-offer-dealmemo-payload"
      >
        {!payloadString && '-'}
        {payloadString && (
          <StyledButton
            component="a"
            onClick={copy}
            disabled={!payloadString}
            startIcon={<CopyIcon />}
            data-test-id="OfferDealMemo-offer-dealmemo-copy-btn"
          >
            copy
          </StyledButton>
        )}
        {payloadString && (
          <Box className={classes.payload}>{payloadString}</Box>
        )}
      </Grid>
      <Grid item xs={2} className={classes.label}>
        Errors:
      </Grid>
      <Grid item xs={10} data-test-id="OfferDealMemo-offer-dealmemo-errors">
        <DealMemoErrors errors={dmErrors} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(DealMemoPayload);
