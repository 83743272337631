import React from 'react';
import Select from 'react-select';
import Typography from '@mui/material/Typography';
import formatOptionLabel from './formatOptionLabel';
import useCities from 'common/hooks/useCities';

const WorkCitySelect = props => {
  const {
    onChange: upstreamOnChange,
    formData: { workCity, workStateId } = {},
    error = '',
    selectStyles,
    classes = {},
    disabled,
    countryId,
  } = props;

  const { loading, data: cities = [] } = useCities({
    countryId,
    stateId: workStateId,
  });

  if (!!workStateId && !loading && cities.length === 0) return null;

  // Format options for react-select
  const options = cities.map(({ code, name }) => ({
    value: code,
    label: name,
  }));

  // We only store the code in our formData but we need to supply the formatted option
  // as the selected value
  const value = options.find(({ value }) => workCity === value) || null;

  const onChange = ({ value }) => {
    upstreamOnChange({
      workCity: value,
      union: null,
      occupation: null,
      workSchedule: null,
    });
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1">
        Primary Work City
      </Typography>
      <div data-test-id="WorkCitySelect-select">
        <Select
          styles={selectStyles}
          className={classes.select}
          onChange={onChange}
          value={value}
          options={options}
          isLoading={loading}
          formatOptionLabel={formatOptionLabel('WorkCitySelect')}
          isDisabled={disabled}
        />
      </div>
      {error && (
        <Typography
          color="error"
          variant="caption"
          className={classes.error}
          data-test-id="WorkCitySelect-error"
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

export default WorkCitySelect;
