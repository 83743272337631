import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const QUERY = gql`
  mutation saveStateW4Document(
    $offerId: Int!
    $documentId: String!
    $name: String!
    $uploadedAt: String!
    $source: OfferExternalDocumentSource!
    $type: OfferExternalDocumentType!
  ) {
    saveOfferExternalDocument(
      offerId: $offerId
      documentId: $documentId
      name: $name
      uploadedAt: $uploadedAt
      source: $source
      type: $type
    )
  }
`;

const useSaveStateW4Document = () => {
  const [saveExternalDocument, params] = useMutation(QUERY);
  return [saveExternalDocument, params];
};

export default useSaveStateW4Document;
