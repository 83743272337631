import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const QUERY = gql`
  query offerDealMemo($offerId: Int!) {
    offer(id: $offerId) {
      id
      status
      dealMemo
    }
  }
`;

const useOfferDealMemo = offerId => {
  const { loading, error, data } = useQuery(QUERY, {
    skip: !offerId,
    variables: { offerId },
    fetchPolicy: 'no-cache',
  });

  return { loading, error, data: data?.offer };
};

export default useOfferDealMemo;
