import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Components
import AccountLayout from '../NotificationsContainer/AccountLayout';
import ProfileInformation from 'common/oldJavascripts/components/Pages/Account/Profile';
import ResetPassword from 'common/oldJavascripts/components/Pages/Account/Password';
import Notifications from 'common/oldJavascripts/components/Pages/Account/Notification';

const Profile = props => (
  <AccountLayout>
    <Switch>
      <Route exact path="/account/profile" component={ProfileInformation} />
      <Route exact path="/account/password" component={ResetPassword} />
      <Route exact path="/account/notifications" component={Notifications} />
    </Switch>
  </AccountLayout>
);

export default Profile;
