import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import TableCell from '@mui/material/TableCell';
import classNames from 'class-names';

const ClearAllowance = ({ classes, onChange, allowance }) => {
  const onClear = () => {
    if (!allowance) return;
    const emptyAllowance = {};
    for (const item in allowance) {
      emptyAllowance[item] = '';
    }
    onChange(emptyAllowance);
  };

  const showButton = allowance
    ? delete allowance.__typename &&
      Object.values(allowance).some(value => value)
    : false;
  return (
    <TableCell align="center" className={classNames(classes.clearCell)}>
      {showButton ? <ClearIcon onClick={() => onClear()} /> : null}
    </TableCell>
  );
};

export default ClearAllowance;
