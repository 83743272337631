import React from 'react';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';

const styles = {
  container: {
    width: 128,
    height: 30,
    marginTop: 12,
  },
  wrapper: {
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    fontSize: 12,
    fontWeight: 700,
  },
  blueBg: {
    backgroundColor: '#BECFFE',
    color: '#0000FF',
  },
  greyBg: {
    backgroundColor: '#E0E0E0',
    color: '#8D8D8D',
  },
};

const CountPlaceholder = ({ classes, rules, studioPlus, workFlows }) => {
  if (rules) {
    return (
      <div className={classes.container}>
        <span className={classnames(classes.wrapper, classes.blueBg)}>
          {rules}
        </span>
      </div>
    );
  }
  if (studioPlus) {
    return (
      <div className={classes.container}>
        <span className={classnames(classes.wrapper, classes.blueBg)}>
          {studioPlus}
        </span>
      </div>
    );
  }
  if (workFlows) {
    return (
      <div className={classes.container}>
        <span className={classnames(classes.wrapper, classes.blueBg)}>
          {workFlows}
        </span>
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <span className={classnames(classes.wrapper, classes.greyBg)}>0</span>
    </div>
  );
};

export default withStyles(styles)(CountPlaceholder);
