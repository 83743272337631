const reimbursementTypes = [
  'box_rental',
  'computer_rental',
  'car_allowance',
  'mobile_phone',
  'per_diem_allowance',
  'housing_allowance',
];

export default reimbursementTypes;
