import React from 'react';
import { MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Menu } from '@mui/material';
import { ListItemText } from '@mui/material';
import { Link as ReactLink } from 'react-router-dom';
import ajax from 'common/utilities/ajax';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import {
  Launch as LaunchIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Folder as FolderIcon,
} from '@mui/icons-material';
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    height: '3rem',
    width: '12rem',
    paddingLeft: '0.5rem',
    '&:hover': {
      background: '#f5f5f5',
    },
    '&:focus': {
      background: '#f5f5f5',
    },
    '&:active': {
      background: '#f5f5f5',
    },
  },
  icon: {
    margin: '0.7rem',
  },
});

const RowMenu = props => {
  const {
    offer,
    projectId,
    anchorEl,
    closeMenu,
    contextMenuAnchor,
    downloadOfferFiles,
  } = props;

  const classes = useStyles();
  if (!offer) {
    return null;
  }

  const { id } = offer;

  const downloadFinalDocument = () => {
    pushNotification({
      message:
        'Hold on a moment while we prepare the document for download. Your download should being momentarily.',
      variant: SnackbarVariants.INFO,
    });
    ajax
      .get(`/v2/offers/${id}/full-final-document`)
      .then(response => {
        const { path = '' } = response;
        if (!path)
          throw new Error(
            'No path received when requesting document for download.',
          );
        popNotification();
        pushNotification({
          message: 'Your download has started.',
          variant: SnackbarVariants.SUCCESS,
        });
        window.location.href = path;
      })
      .catch(err => {
        popNotification();
        pushNotification({
          message:
            'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.',
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorPosition={
        contextMenuAnchor
          ? { left: contextMenuAnchor.x, top: contextMenuAnchor.y }
          : undefined
      }
      anchorReference="anchorPosition"
      onClose={closeMenu}
    >
      <ReactLink
        to={`/projects/${projectId}/offer-documents/${id}/details`}
        className={classes.link}
      >
        <LaunchIcon className={classes.icon} />
        <ListItemText>Open File</ListItemText>
      </ReactLink>

      <MenuItem
        justifyContent="flex-start"
        onClick={downloadFinalDocument}
        className={classes.link}
      >
        <PictureAsPdfIcon className={classes.icon} />
        <ListItemText>Download PDF</ListItemText>
      </MenuItem>
      <MenuItem
        justifyContent="flex-start"
        onClick={downloadOfferFiles}
        className={classes.link}
      >
        <FolderIcon className={classes.icon} />
        <ListItemText>Download Zip</ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default RowMenu;
