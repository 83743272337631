import React from 'react';
import Feature from 'common/components/Feature';
import OldMobileDocumentSigner from './MobileDocumentSigner_OLD';
import NewMobileDocumentSigner from './MobileDocumentSigner_NEW';
import Alert from './RejectI9Alert';

const MobileDocumentSigner = props => (
  <Feature
    name="newMobileDocumentSigner"
    fallback={<OldMobileDocumentSigner {...props} />}
  >
    <NewMobileDocumentSigner {...props} />
    {props?.alertMessage && <Alert message={props?.alertMessage || ''} />}
  </Feature>
);

export default MobileDocumentSigner;
