import React from 'react';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import { DocumentSigner } from '@castandcrew/document-signer-react';
import UsaOnly from 'common/components/UsaOnly';
import Alert from './RejectI9Alert';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: ' 100%',
    gridTemplateRows: ' 100%',
    padding: ' 25px',
    boxSizing: ' border-box',
    width: ' 100%',
    height: ' calc(100% - 130px)',
    overflow: ' auto',
  },
});

const DesktopDocumentSigner = props => {
  const {
    actions,
    actor,
    classes,
    documents,
    getDocumentImageUrls,
    me,
    offer = {},
    templatesAcknowledgeNotes,
    templatesAcknowledgeTitle,
    throwFatalError,
    alertMessage = '',
  } = props;

  const flags = useFeatureFlags();

  return (
    <div className={classes.root}>
      <Paper>
        <UsaOnly>
          {isUsa => (
            <DocumentSigner
              documents={documents}
              actor={actor}
              getDocumentImageUrls={getDocumentImageUrls}
              throwFatalError={throwFatalError}
              actions={actions}
              offer={offer}
              templatesAcknowledgeNotes={templatesAcknowledgeNotes}
              templatesAcknowledgeTitle={templatesAcknowledgeTitle}
              me={me}
              isSaveSignatureEnabled
              showI9Note={isUsa}
              featureFlags={flags}
            />
          )}
        </UsaOnly>
        {alertMessage && <Alert message={alertMessage} />}
      </Paper>
    </div>
  );
};

export default withStyles(styles)(DesktopDocumentSigner);
