import { PUSH, POP, CLEAR } from './actionTypes';

export const DIALOG_NOTIFICATION = 'DIALOG_NOTIFICATION';

export const pushNotification = dialogObject => ({
  type: PUSH(DIALOG_NOTIFICATION),
  dialogObject,
});

export const popNotification = () => ({
  type: POP(DIALOG_NOTIFICATION),
});

export const clearNotification = () => ({
  type: CLEAR(DIALOG_NOTIFICATION),
});
