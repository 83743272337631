import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Paper, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Done as CheckIcon, Clear as ErrorIcon } from '@mui/icons-material';
import * as palette from 'common/shared/oldDocumentSignerUI/palette';
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';

import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Loader from 'common/components/Loader';

import useDownloadCrew from './hooks/useDownloadCrew';

const styles = {
  title: {
    textAlign: 'center',
  },
  returnText: {
    marginTop: '10px',
  },
  root: {
    width: '80%',
    margin: '0 auto',
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  success: {
    position: 'relative',
    top: '0.225em',
    color: palette.success2,
  },
  error: {
    position: 'relative',
    top: '0.225em',
    color: palette.error2,
  },
  loader: {
    height: '75px',
  },
};

const NOTIFICATION = {
  loading: {
    message:
      'Hold on a moment while we prepare the crew list for download. ' +
      'Your download should being momentarily.',
    variant: SnackbarVariants.INFO,
  },
  success: {
    message: 'Your download has started.',
    variant: SnackbarVariants.SUCCESS,
  },
};

const DownloadCrew = (props) => {
  const { match, classes, pushNotification, popNotification } = props;
  const { params: { fileId, projectId } = {} } = match || {};
  const { data: downloadUrl, loading: downloading, error: downloadError } = useDownloadCrew(
    projectId,
    fileId,
  );

  useEffect(() => {
    popNotification();
    if (downloading) {
      pushNotification(NOTIFICATION.loading);
    }
  }, [downloading, popNotification, pushNotification]);

  useEffect(() => {
    if (downloadUrl) {
      popNotification();
      pushNotification(NOTIFICATION.success);
      window.location.href = downloadUrl;
    }
  }, [downloadUrl, popNotification, pushNotification]);

  return (
    <LayoutContent>
      <Paper classes={{ root: classes.root }}>
        {downloading && !Boolean(downloadError) && (
          <Loader className={classes.loader} />
        )}
        {!Boolean(downloadError) && (
          <Typography className={classes.title} variant="h5">
            {downloading ? (
              'Your crew list will be downloaded shortly.'
            ) : (
              <React.Fragment>
                <CheckIcon className={classes.success} /> Your download has
                started.
              </React.Fragment>
            )}
          </Typography>
        )}
        {Boolean(downloadError) && (
          <Typography className={classes.title} variant="h5">
            <ErrorIcon className={classes.error} />
            {downloadError.message.toString().replace(/^graphql error:\s/i, '')}
          </Typography>
        )}
        {!downloading && (
          <Typography variant="h5" className={classes.returnText}>
            Click <Link to={`/projects/${projectId}/crew`}>here</Link> to return
            to your Crew.
          </Typography>
        )}
      </Paper>
    </LayoutContent>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  pushNotification,
  popNotification,
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(DownloadCrew);
