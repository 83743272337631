import React, { Component } from 'react';
import AllowancesRow from 'common/oldJavascripts/components/Shared/AllowancesRow';

import format from 'common/oldJavascripts/utils/format.js';
import OfferStatus from 'common/oldJavascripts/components/Shared/OfferStatus';
import RateCell from '../Offers/RateCell';

import Badges from 'common/components/Badges';
import Tooltip from '@mui/material/Tooltip';
// HoC
import withApi from 'common/hoc/withApi';
import { withStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { compose } from 'redux';

import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCellTitle from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCellTitle';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

const styles = () => ({
  overflowText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  badgesContainer: {
    marginTop: 6,
  },
});

class ArchivedOffersTableRow extends Component {
  static mutators = {
    project_offer: {
      info: function(params, related) {
        const routerParams = related['/router/params'];

        return {
          id: `/projects/${routerParams.projectId}/offers/${params.offer_id}`,
          create: true,
        };
      },
    },
  };
  static queries = {
    me: {
      info() {
        return {
          id: '/me',
        };
      },
    },
  };

  render() {
    const {
      offer = {},
      onToggleDetails,
      onToggleStatus,
      endDateAllowed,
      classes = {},
      openActionMenu,
      privileges,
    } = this.props;
    const canCopyOffer = privileges?.includes('can_create_offers');

    const { badges = [] } = offer;

    const renderAction = () => {
      return (
        <IconButton
          onClick={e => {
            e.stopPropagation();
            openActionMenu(e, offer);
          }}
        >
          <MoreVertIcon />
        </IconButton>
      );
    };

    return (
      <ExpandableTableRow
        onClick={() => onToggleDetails(offer.id)}
        expanded={this.props.detailsExpanded}
      >
        <ExpandableTableCell>
          <span data-test-id={`OffersTableRow-employee-${offer.id}`}>
            <ExpandableTableCellTitle>
              {format.fullName(
                offer.first_name,
                offer.middle_name,
                offer.last_name,
              )}
            </ExpandableTableCellTitle>
            <Tooltip disableInteractive title={offer.email} enterDelay={100}>
              <div className={classes.overflowText}>{offer.email}</div>
            </Tooltip>
            <div
              className={classes.badgesContainer}
              data-test-id={`OffersTableRow-badges-${offer.id}`}
            >
              <Badges badges={badges} id={offer.id} />
            </div>
          </span>
        </ExpandableTableCell>
        <ExpandableTableCell>
          <ExpandableTableCellTitle>
            <span data-test-id={`OffersTableRow-department-${offer.id}`}>
              {offer.department_name}
            </span>
          </ExpandableTableCellTitle>
        </ExpandableTableCell>
        <ExpandableTableCell>
          <span data-test-id={`OffersTableRow-jobTitle-${offer.id}`}>
            {offer.occupation_description}
          </span>
          <br />
          <span data-test-id={`OffersTableRow-accountCode-${offer.id}`}>
            {offer.account_code}
          </span>
        </ExpandableTableCell>
        <ExpandableTableCell>
          <span data-test-id={`OffersTableRow-union-${offer.id}`}>
            {offer.union_description}
          </span>
        </ExpandableTableCell>
        <ExpandableTableCell date={true}>
          <span data-test-id={`OffersTableRow-startDate-${offer.id}`}>
            {format.date(offer.start_date)}
          </span>
        </ExpandableTableCell>
        {endDateAllowed && (
          <ExpandableTableCell date={true}>
            <span data-test-id={`OffersTableRow-endDate-${offer.id}`}>
              {format.date(offer.end_date)}
            </span>
          </ExpandableTableCell>
        )}
        <RateCell offer={offer} />
        <ExpandableTableCell
          align="center"
          data-test-id={`OffersTableRow-allowances-${offer.id}`}
        >
          <AllowancesRow.Icon offer={offer} />
        </ExpandableTableCell>
        <ExpandableTableCell
          expanded={this.props.statusExpanded}
          onClick={() => onToggleStatus(offer.id)}
          data-test-id={`OffersTableRow-status-${offer.id}`}
        >
          <OfferStatus
            status={offer.status}
            startDate={offer.start_date}
            sendDate={offer.send_date}
          />
        </ExpandableTableCell>
        {canCopyOffer && (
          <ExpandableTableCell>{renderAction()}</ExpandableTableCell>
        )}
      </ExpandableTableRow>
    );
  }
}

export default compose(withApi, withStyles(styles))(ArchivedOffersTableRow);
