import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';

import AllowancesRow from 'common/oldJavascripts/components/Shared/AllowancesRow';
import format from 'common/oldJavascripts/utils/format.js';
import Button from 'common/oldJavascripts/components/Base/Button';
import Badges from 'common/components/Badges';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCellTitle from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCellTitle';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import RateCell from './Offers/RateCell';
import ajax from 'common/utilities/ajax.js';
import nonUnionTermsOfEmployment from 'studio/components/ManageOffers/utilities/nonUnionTermsOfEmployment/nonUnionTermsOfEmployment';

const styles = () => ({
  actionButtons: {
    padding: '16px 8px',
    overflow: 'unset',
  },
  approveButton: {
    height: 39,
    width: 75,
    borderRadius: '3px',
    background: '#00DF68',
    '&:hover': {
      background: '#04cf63',
    },
    '&:focus': {
      background: '#04cf63',
    },
    '&:active': {
      background: '#04cf63',
    },
  },
  processingButton: {
    background: '#6fe8a8',
    '&:hover': {
      background: '#6fe8a8',
    },
    '&:focused': {
      background: '#6fe8a8',
    },
    '&:active': {
      background: '#6fe8a8',
    },
  },
  overflowText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  badgesContainer: {
    marginTop: 6,
  },
});

const Row = props => {
  const {
    offer = {},
    rowExpanded,
    openSigner,
    handleReject,
    expanded = false,
    classes,
    projectId,
  } = props;

  const {
    id,
    allowances,
    accountCode,
    badges = [],
    endDate,
    i9Document: hasI9Document,
    jobTitle,
    projectUser: {
      profile: { email, firstName, lastName, middleName } = {},
    } = {},
    startDate,
    termsOfEmployment: terms = {},
    termsOfHire: {
      department: { name: departmentName } = {},
      union: { description: unionDescription, isNonUnion } = {},
    } = {},
    readyToSign,
  } = offer;

  const [isReadyToSign, setIsReadyToSign] = useState(() => readyToSign);

  useEffect(() => {
    // if readyToSign is false, we need to check if the offer is ready to sign
    const getLatestApprovalStatus = () => {
      if (!isReadyToSign && id && projectId) {
        ajax.get(`/v2/projects/${projectId}/approvals/${id}`).then(response => {
          if (response) {
            setIsReadyToSign(response?.ready_to_sign);
          }
        });
      }
    };

    const interval = setInterval(() => {
      getLatestApprovalStatus();
    }, 5000);
    return () => clearInterval(interval);
  }, [id, projectId, isReadyToSign]);

  const _allowances = {
    box_rental_amount: allowances?.boxRentalAllowance.amount,
    mobile_phone_amount: allowances?.mobilePhoneAllowance.amount,
    car_allowance_amount: allowances?.carAllowance.amount,
    per_diem_allowance_amount: allowances?.perDiemAllowance.amount,
    housing_allowance_amount: allowances?.housingAllowance.amount,
    computer_rental_amount: allowances?.computerRentalAllowance.amount,
  };

  const _getFullName = offer => {
    return format.fullName(offer.firstName, offer.middleName, offer.lastName);
  };

  const fullName = _getFullName({ firstName, lastName, middleName });

  const _renderEndDateCell = endDate => {
    const { endDateAllowed } = props;
    if (endDateAllowed) {
      return <ExpandableTableCell>{format.date(endDate)}</ExpandableTableCell>;
    }
  };

  const _toggleDetails = () => {
    const { onToggle, offer } = props;
    const { id } = offer;
    onToggle(id);
  };

  const isSAGUnion =
    offer?.termsOfEmploymentV2?.__typename === 'TermsOfEmploymentUnionSag';

  const rateCellInfo = isNonUnion
    ? nonUnionTermsOfEmployment(offer)
    : isSAGUnion
    ? {
        id,
        terms_of_employment: {
          sagRate: offer?.termsOfEmploymentV2?.rate,
        },
      }
    : {
        id,
        terms_of_employment: {
          rate_per_hour_studio: terms?.studioRatePerHour || terms?.ratePerHour,
          rate_per_hour_location: terms?.locationRatePerHour,
          rate_per_day_studio: terms?.dailyRate,
          rate_per_week_studio: terms?.studioRatePerWeek || terms?.weeklyRate,
          rate_per_week_location: terms?.locationRatePerWeek,
        },
        scaleRate: offer?.scaleRate,
        locationScaleRate: offer?.locationScaleRate,
        union_code: offer?.termsOfHire?.union?.code,
      };

  const approveButton = () => {
    if (isReadyToSign) {
      return (
        <Button
          action={true}
          className={classes.approveButton}
          onClick={() => openSigner(id, hasI9Document)}
          data-test-id={`ApprovalsRow-approveButton-${id}`}
        >
          Approve
        </Button>
      );
    }
    return (
      <Tooltip
        disableInteractive
        classes={{
          tooltip: 'offer-processing-tooltip',
        }}
        title="This offer is being prepared. It will be ready to approve shortly."
      >
        <div>
          <Button
            disabled
            action={true}
            className={classes.processingButton}
            data-test-id={`ApprovalsRow-processingButton-${id}`}
          >
            Processing...
          </Button>
        </div>
      </Tooltip>
    );
  };

  const rejectButton = () => {
    if (isReadyToSign) {
      return (
        <Button
          onClick={() => handleReject(id, hasI9Document)}
          secondary={true}
          data-test-id={`ApprovalsRow-rejectButton-${id}`}
        >
          Reject
        </Button>
      );
    }
    return (
      <Tooltip
        disableInteractive
        classes={{
          tooltip: 'offer-processing-tooltip',
        }}
        title="This offer is being prepared. It will be ready to approve shortly."
      >
        <div>
          <Button
            disabled
            secondary={true}
            data-test-id={`ApprovalsRow-processingButton-${id}`}
          >
            Processing...
          </Button>
        </div>
      </Tooltip>
    );
  };
  return (
    <ExpandableTableRow
      key={id}
      onClick={_toggleDetails}
      rowExpanded={rowExpanded}
      data-test-id={props['data-test-id']}
    >
      <ExpandableTableCell className="small-left-padding">
        <ExpandableTableCellTitle>{fullName}</ExpandableTableCellTitle>
        <Tooltip disableInteractive title={email} enterDelay={100}>
          <div className={classes.overflowText}>{email}</div>
        </Tooltip>
        <div
          className={classes.badgesContainer}
          data-test-id={`ApprovalsRow-badges-${id}`}
        >
          <Badges badges={badges} id={id} />
        </div>
      </ExpandableTableCell>
      <ExpandableTableCell>
        <ExpandableTableCellTitle>{departmentName}</ExpandableTableCellTitle>
      </ExpandableTableCell>
      <ExpandableTableCell>
        {jobTitle}
        <br />
        {accountCode}
      </ExpandableTableCell>
      <ExpandableTableCell>{unionDescription}</ExpandableTableCell>
      <ExpandableTableCell>{format.date(startDate)}</ExpandableTableCell>
      {_renderEndDateCell(endDate)}
      <RateCell offer={rateCellInfo} />
      <ExpandableTableCell align="center" expanded={expanded}>
        <AllowancesRow.Icon offer={_allowances} />
      </ExpandableTableCell>
      <ExpandableTableCell align="center" className={classes.actionButtons}>
        {approveButton()}
      </ExpandableTableCell>
      <ExpandableTableCell align="center" className={classes.actionButtons}>
        {rejectButton()}
      </ExpandableTableCell>
    </ExpandableTableRow>
  );
};

export default withStyles(styles)(Row);
