import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CheckIcon from 'common/images/CheckIcon.svg';
import CurrentStepIcon from 'common/images/CurrentStepIcon.svg';
import PendingIcon from 'common/images/PendingIcon.svg';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/system';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';

const progressStyles = {
  root: {
    background: 'transparent',
    padding: 0,
    marginTop: '1rem',
  },
  main: {
    top: '-100%',
    left: '47%',
    width: '60%',
    padding: 0,
  },
  lineRoot: {
    top: '6px',
    left: '-50%',
    right: 0,
    width: '100%',
    position: 'absolute',
  },
  connectorLine: {
    transition: 'none',
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: '#155EEF',
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: 'grey',
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: '#155EEF',
    },
  },
  iconRoot: {
    color: '#155EEF',
    fontSize: '12px',
    zIndex: 2,
  },
  alternativeLabel: {
    marginTop: '0 !important',
    color: '#000000',
    fontWeight: 700,
  },
  completedLabel: {
    color: '#000000 !important',
    fontWeight: '700 !important',
  },
  activeLabel: {
    color: '#155EEF !important',
    fontWeight: '700 !important',
  },
  iconDisabled: {
    color: '#FFFFFF',
    fontSize: '12px',
    zIndex: 2,
  },
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#155EEF',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#155EEF',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#155EEF',
  }),
}));

const QontoStepIcon = props => {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <img src={CheckIcon} alt="check icon" />
      ) : active ? (
        <img src={CurrentStepIcon} alt="in progress" />
      ) : (
        <img src={PendingIcon} alt="pending" />
      )}
    </QontoStepIconRoot>
  );
};

const OnBoardingProgress = props => {
  const { steps, currentStep, classes = {} } = props;
  const { alternativeLabel, completedLabel, activeLabel } = classes;

  return (
    <nav className={classes.main}>
      <Stepper
        className={classes.root}
        activeStep={currentStep}
        alternativeLabel
        connector={<QontoConnector />}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              classes={{
                alternativeLabel,
                active: activeLabel,
                completed: completedLabel,
              }}
            >
              {step.desc}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </nav>
  );
};

export default withStyles(progressStyles)(OnBoardingProgress);
