import React from 'react';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';

const styles = {
  couldntFindAddressContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  couldntFindAddress: {
    paddingBottom: '15px',
    maxWidth: 600,
  },
};
const CouldNotDetermineAddress = props => {
  const { renderAddressSuggestions, className, classes } = props;
  return (
    <div className={classNames(className, classes.couldntFindAddressContainer)}>
      <span className="text">
        Please verify your address on file by selecting a match from our address
        lookup below. If you don’t see a match, you can click the ‘X’ and start
        a new search. If you need help, contact support:
        plus.support@castandcrew.com or 818.860.7770.
      </span>
      <div className="field">{renderAddressSuggestions()}</div>
    </div>
  );
};

export default withStyles(styles)(CouldNotDetermineAddress);
