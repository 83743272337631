import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';

import withRouteHelpers from 'common/hoc/withRouteHelpers';
import config from 'common/oldJavascripts/config.js';

import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Box from 'common/oldJavascripts/components/Base/Box';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Header from 'common/oldJavascripts/components/Base/Header';
import QueryRefresher from 'common/components/QueryRefresher';
import Loader from 'common/components/Loader';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import { FETCH_MORE_NETWORK_STATUS } from 'common/constants/graphqlNetworkStatus/networkStatus';

import useProjectApprovedOffers from '../hooks/useProjectApprovedOffers';
import Filter from './Filter';
import Table from './Table';
import FetchMore from './FetchMore';

const useStyles = makeStyles({
  recordsText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.16px',
    color: '#8D8D8D',
    paddingBottom: '24px',
  },
  stickyLayout: {
    position: 'sticky',
    top: '-16px',
    zIndex: 2,
  },
});

const List = props => {
  const {
    routerParams: { projectId } = {},
    routerQuery: { sort, direction } = {},
  } = props;
  const [filters, setFilters] = useState({});

  const classes = useStyles();

  const offersVars = {
    ...filters,
    sort,
    direction,
    projectId: parseInt(projectId, 10),
    fromDate: filters.fromDate?.format(config.DATE_FORMAT.DB),
    toDate: filters.toDate?.format(config.DATE_FORMAT.DB),
  };

  const {
    data = {},
    loading: offersDocumentsLoading,
    error,
    refetch,
    networkStatus,
    fetchMore,
  } = useProjectApprovedOffers(offersVars);

  const { pageInfo = {}, nodes: offers = [] } = data || {};

  const { totalCount, hasNextPage: hasMoreOffers } = pageInfo || {};
  const loading =
    offersDocumentsLoading && networkStatus !== FETCH_MORE_NETWORK_STATUS;
  const hasOffers = totalCount > 0;
  const dataReady = !loading && !error;

  return (
    <LayoutContent>
      <Box>
        <div className={classes.stickyLayout}>
          <BoxItem>
            <Header>
              <Header.Title>Approved offers documents</Header.Title>
              <QueryRefresher reloadQuery={refetch} />
            </Header>
          </BoxItem>
          <BoxItem>
            <Filter
              projectId={parseInt(projectId, 10)}
              filters={filters}
              onChange={setFilters}
            />
          </BoxItem>
        </div>
        {loading && (
          <BoxItem>
            <Loader />
          </BoxItem>
        )}
        {error && (
          <BoxItem>
            <FailureAlert />
          </BoxItem>
        )}
        {dataReady && !hasOffers && <Blankslate>No offers found.</Blankslate>}
        {dataReady && hasOffers && (
          <Table offers={offers} projectId={projectId} />
        )}
      </Box>
      {dataReady && hasOffers && (
        <div className={classes.recordsText}>
          Showing {offers.length} of {totalCount}
        </div>
      )}
      {hasMoreOffers && dataReady && (
        <FetchMore {...{ networkStatus, fetchMore, pageInfo }} />
      )}
    </LayoutContent>
  );
};

export default withRouteHelpers(List);
