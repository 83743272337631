import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import T from 'common/oldJavascripts/utils/i18n';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Box from 'common/oldJavascripts/components/Base/Box';
import Button from 'common/oldJavascripts/components/Base/Button';
import Link from 'common/oldJavascripts/components/Base/Link';
import NavBar from 'common/oldJavascripts/components/Base/NavBar';
import Header from 'common/oldJavascripts/components/Base/Header';

import history from 'common/constants/config/history';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

export default Relay.createClass({
  statics: {
    mutators: {
      review: {
        info: function(params, related) {
          var routerParams = related['/router/params'];

          return {
            id:
              '/projects/' +
              routerParams.projectId +
              '/reviews/' +
              routerParams.reviewId,
          };
        },
      },
    },
  },

  render: function() {
    const { routerParams: { projectId } = {} } = this.props;

    return (
      <LayoutContent>
        <Box minimal={true}>
          <BoxItem>
            <Header center={true}>
              <Header.Badge icon="delete" label={T('project.reviews.delete')} />
              <Header.Title center={true}>
                {T('project.reviews.delete_confirmation')}
              </Header.Title>
            </Header>
            <Box>
              <NavBar>
                <NavBar.Item>
                  {' '}
                  <Button onClick={this._handleDelete}>
                    {T('form.actions.delete')}
                  </Button>
                </NavBar.Item>
                <NavBar.Item>
                  <Link to={`/projects/${projectId}/reviews`}>
                    {T('form.actions.cancel')}
                  </Link>{' '}
                  {T('form.actions.or')}
                </NavBar.Item>
              </NavBar>
            </Box>
          </BoxItem>
        </Box>
      </LayoutContent>
    );
  },

  _handleDelete: function() {
    const {
      review: reviewMutator = {},
      routerParams: { projectId } = {},
    } = this.props;
    reviewMutator.save('delete', {}, () => {
      history.push(`/projects/${projectId}/reviews`);
    });
  },
});
