import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';

const useStyles = makeStyles(theme => ({
  contentRoot: {
    display: 'grid',
    height: '100%',
    gridTemplateRows: '60px 60px 30px minmax(166px, max-content) 64px',
  },
  headerContainer: {
    backgroundColor: '#FFBF00',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headline: {
    textAlign: 'center',
    fontSize: 20,
    color: '#333',
    marginTop: 20,
  },
  header: {
    textAlign: 'center',
    color: '#FFF',
    fontWeight: 'normal',
  },
  subHeader: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    textAlign: 'center',
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: 12,
  },
  subText: {
    textAlign: 'center',
  },
  cancelButton: {
    color: '#888888 !important',
    backgroundColor: '#eaeded !important',
    '&:hover': {
      backgroundColor: '#b8bbbb !important',
    },
  },
}));

const ConfirmHoursModal = ({ onContinue, takeMeBackHandler } = {}) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.contentRoot}>
        <div className={classes.headerContainer}>
          <Typography variant="h4" className={classes.header}>
            Confirm Hours
          </Typography>
        </div>
        <Typography className={classes.headline}>
          Are you sure you want to leave the hours field(s) blank?
        </Typography>
        <Typography className={classes.subHeader}>
          C&C will pay the contractual minimum call. If you wish to pay more
          than contractual minimum call, you must provide those hours. Press yes
          to pay contractual minimum call or press no to go back and provide
          hours.
        </Typography>
      </div>
      <div className={classes.submitContainer}>
        <Button
          variant="contained"
          onClick={takeMeBackHandler}
          data-test-id="ConfirmHoursModal-cancelButton"
          className={classes.cancelButton}
        >
          NO, TAKE ME BACK
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onContinue}
          data-test-id="ConfirmHoursModal-button"
        >
          YES, CONTINUE
        </Button>
      </div>
    </div>
  );
};

export default ConfirmHoursModal;
