import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';

import withApi from 'common/hoc/withApi';

import BoxBody from 'common/oldJavascripts/components/Base/Box/BoxBody';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Loader from 'common/components/Loader';

import ProjectStatus from 'common/components/ProjectStatus';
import ProjectClone from 'common/components/ProjectClone';
import Feature from 'common/components/Feature';

import Fields from 'admin/oldJavascripts/components/Pages/Project/Setup/Documents/Fields';
import UsersAndDepartments from 'admin/oldJavascripts/components/Pages/Project/Setup/Documents/UsersAndDepartments';
import Details from './Details';

const updateEnforceMask = (value, props) => {
  const {
    projectEnforceMask: enforceMaskMutator,
    project: projectMutator,
  } = props;

  enforceMaskMutator.updateAndSave(
    { enforce_mask: value },
    '',
    { json: true },
    () => projectMutator.reload(),
  );
};

const ProjectSettings = props => {
  let {
    routerParams: { projectId } = {},
    customFields = {},
    nonEmployees = {},
    project = {},
  } = props;

  const [detailSubDataType, setDetailSubDataType] = useState(
    project?.data?.data_type,
  );

  if (
    project.status === 'loading' ||
    nonEmployees.status === 'loading' ||
    customFields.status === 'loading'
  ) {
    return <Loader />;
  }

  nonEmployees = nonEmployees.data;
  customFields = customFields.data;

  const {
    data: { enforce_loan_out = false, enforce_mask = false, account_mask } = {},
  } = project;

  return (
    <BoxBody>
      <BoxItem divider={true}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <ProjectStatus project={project.data} />
          </Grid>
          <Feature name="projectClone">
            <Grid item xs={6} align="right">
              <ProjectClone project={project.data} />
            </Grid>
          </Feature>
        </Grid>
      </BoxItem>
      <Fields
        updateDetailSubDataType={setDetailSubDataType}
        accountMask={account_mask}
        enforceMask={enforce_mask}
        updateEnforceMask={value => updateEnforceMask(value, props)}
      />
      <UsersAndDepartments
        nonEmployees={nonEmployees}
        detailSubDataType={detailSubDataType}
        projectId={projectId}
        enforceLoanOut={enforce_loan_out}
        enforceMask={enforce_mask}
        accountMask={account_mask}
      />
      <Details projectId={projectId} />
    </BoxBody>
  );
};

ProjectSettings.mutators = {
  projectEnforceMask: {
    info: function(_, related) {
      const routerParams = related['/router/params'];

      return {
        id: `/projects/${routerParams.projectId}/enforce-mask`,
        create: true,
      };
    },
  },
};

ProjectSettings.queries = {
  nonEmployees: {
    info: function(_, related) {
      var routerParams = related['/router/params'];

      return {
        id: '/projects/' + routerParams.projectId + '/non-employees',
      };
    },
  },

  customFields: {
    info: function(_, related) {
      var params = related['/router/params'];

      return {
        id: '/projects/' + params.projectId + '/custom-fields',
      };
    },
  },

  project: {
    info: function(_, related) {
      var params = related['/router/params'];

      return {
        id: '/projects/' + params.projectId,

        cursor: {
          with_privileges: true,
        },
      };
    },
  },
};

const mapStateToProps = () => ({});

export default compose(connect(mapStateToProps), withApi)(ProjectSettings);
