import React from 'react';

const formatOptionLabel = testLabel => ({ label }, { context }) =>
  context === 'value' ? (
    <div data-test-id={`${testLabel}-value`}>{label}</div>
  ) : (
    <div data-test-id={`${testLabel}-option-${label}`}>{label}</div>
  );

export default formatOptionLabel;
