import React from 'react';
import { withStyles } from '@mui/styles';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import OffersListTable from './OffersListTable';
import useOffersByStatus from './useOffersByStatus';
import Loader from 'common/components/Loader';

const styles = {
  loader: {
    backgroundColor: 'white',
    width: '100%',
    height: '200px',
    marginTop: 120,
  },
};

const OffersList = ({ classes }) => {
  const { data: offers = [], loading } = useOffersByStatus();
  const flags = useFeatureFlags();
  const globalNavFlag = flags.includes('GlobalNavbar');

  if (loading) return <Loader className={classes.loader} />;

  return (
    <LayoutContent globalNavFlag={globalNavFlag}>
      <OffersListTable
        columns={[
          'project.name',
          'termsOfHire.occupation.description',
          'sendDate',
        ]}
        offers={offers.filter(
          ({ status }) => status === 'sent' || status.startsWith('onboarding'),
        )}
        type="sent"
      />

      <OffersListTable
        columns={[
          'project.name',
          'termsOfHire.occupation.description',
          'sendDate',
        ]}
        offers={offers.filter(({ status }) => status === 'submitted')}
        type="submitted"
      />

      <OffersListTable
        columns={[
          'project.name',
          'termsOfHire.occupation.description',
          'sendDate',
          'approvedAt',
        ]}
        offers={offers.filter(({ status }) => status === 'approved')}
        type="approved"
      />

      <OffersListTable
        columns={[
          'project.name',
          'termsOfHire.occupation.description',
          'sendDate',
          'rejectedAt',
          'rejectionComments',
        ]}
        offers={offers.filter(({ status }) => status.includes('rejected'))}
        type="rejected"
      />

      <OffersListTable
        columns={[
          'project.name',
          'termsOfHire.occupation.description',
          'sendDate',
        ]}
        offers={offers.filter(
          ({ status }) =>
            status === 'opted-out' || status === 'complete_on_paper',
        )}
        type="optedOut"
      />
    </LayoutContent>
  );
};

export default withStyles(styles)(OffersList);
