import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    marginBottom: 5,
  },
  error: {
    marginLeft: 24,
  },
});

const LabeledCheckbox = props => {
  const { name, label, checked, onChange, error } = props;
  const classes = useStyles();

  return (
    <FormControl className={classes.root} error={error} fullWidth>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            checked={checked}
            onChange={e => onChange(e.target.checked)}
            data-test-id={`Field-checkboxInput-${name}`}
            color="secondary"
          />
        }
      />
      {error && (
        <FormHelperText className={classes.error}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default LabeledCheckbox;
