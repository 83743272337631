import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ProjectLayout from 'common/oldJavascripts/components/Pages/Layouts/Project';
import CreateOffer from 'studio/components/OfferPage/CreateOffer';
import EditOffer from 'studio/components/OfferPage/EditOffer';

// components for Pendo
import Feature from 'common/components/Feature';
import PendoController from 'common/components/PendoController';

const Offer = props => (
  <ProjectLayout hasSidebar>
    <Switch>
      <Route path="/projects/:projectId/offers/new" component={CreateOffer} />
      <Route
        path="/projects/:projectId/offers/:offerId/edit"
        render={({ match }) => <EditOffer key={match.params.offerId} />}
      />
    </Switch>
    <Feature name="pendoIntegration">
      <PendoController projectId={props?.match?.params?.projectId} />
    </Feature>
  </ProjectLayout>
);

export default Offer;
