import React, { useState, useEffect } from 'react';
import {
  Box,
  ClickAwayListener,
  FormControl,
  IconButton,
  InputAdornment,
  Paper,
  Popper,
  TextField,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import DeleteModal from './DeleteModal';
import { ArrowDropDown, ArrowDropUp, Close, Search } from '@mui/icons-material';
import Dropdown from './Dropdown';
import Notification from '../Notification';

const styles = {
  root: {
    position: 'absolute !important',
    zIndex: 99,
    top: 24,
    right: 24,
    minWidth: 200,
    '& .MuiAutocomplete-listbox': {
      padding: 0,
    },
  },
  dropdown: {
    width: '100%',
    boxShadow: '0px 0px 5px rgba(38, 38, 38, 0.7)',
  },
};

const Select = props => {
  const {
    draft,
    data: drafts,
    loading,
    error,
    classes,
    onChange,
    onDelete,
  } = props;
  const [listOpen, setListOpen] = useState(false);
  const [draftDelete, setDraftDelete] = useState(null);
  const [searchEl, setSearchEl] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedDraft, setSelectedDraft] = useState(draft);
  const [searchMode, setSearchMode] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSuccess, setSnackSuccess] = useState(null);
  const [snackMessage, setSnackMessage] = useState('');
  const [deleteError, setDeleteError] = useState(null);

  useEffect(() => {
    setSelectedDraft(draft);
  }, [draft]);

  const setListFocus = el => {
    setSearchEl(el);
    setListOpen(el ? true : false);
    setSearchMode(el ? true : false);
    setSearchKeyword('');
  };

  const clearSelection = () => {
    setSearchKeyword('');
    setSelectedDraft(null);
    onChange(null);
  };

  const toggleList = (e, isOpen) => {
    if (isOpen) {
      setListFocus(null);
    } else {
      const container = e.currentTarget.parentNode.parentNode;
      setListFocus(container.querySelector('input'));
    }
  };

  return (
    <FormControl className={classes.root}>
      <ClickAwayListener onClickAway={() => setListFocus(null)}>
        <Box>
          <TextField
            data-test-id="OfferDrafts-select"
            placeholder="Load offer"
            variant="standard"
            value={searchMode ? searchKeyword : selectedDraft?.title || ''}
            onFocus={e => setListFocus(e.currentTarget)}
            onChange={e => setSearchKeyword(e.target.value)}
            InputProps={{
              startAdornment: (
                <>
                  {listOpen && (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )}
                </>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <>
                    {listOpen && (
                      <IconButton onClick={e => toggleList(e, true)}>
                        <ArrowDropUp />
                      </IconButton>
                    )}
                    {!listOpen && !selectedDraft && (
                      <IconButton onClick={e => toggleList(e, false)}>
                        <ArrowDropDown />
                      </IconButton>
                    )}
                    {!listOpen && selectedDraft && (
                      <IconButton onClick={clearSelection} size="small">
                        <Close />
                      </IconButton>
                    )}
                  </>
                </InputAdornment>
              ),
            }}
            error={Boolean(error)}
            helperText={error?.message}
          />
          {!error && (
            <Popper
              anchorEl={searchEl}
              open={listOpen}
              className={classes.dropdown}
              disablePortal
            >
              <Paper>
                <Dropdown
                  keyword={searchKeyword}
                  data={drafts}
                  loading={loading}
                  onDelete={draft => setDraftDelete(draft)}
                  onChange={draft => {
                    setSearchMode(false);
                    setListOpen(false);
                    setSelectedDraft(draft);
                    onChange(draft);
                  }}
                />
              </Paper>
            </Popper>
          )}
          <DeleteModal
            draft={draftDelete}
            open={Boolean(draftDelete)}
            onClose={() => setDraftDelete(null)}
            deleteError={deleteError}
            onDelete={({ draft, isTemplate, success, error }) => {
              setDeleteError(error);
              if (success) {
                setDraftDelete(null);
                setSnackSuccess(true);
                setSnackMessage(
                  isTemplate ? 'Template deleted.' : 'Draft deleted.',
                );
                onDelete(draft);
                setSnackOpen(true);
              }
            }}
          />
          <Notification
            open={snackOpen}
            setOpen={setSnackOpen}
            severity={snackSuccess ? 'success' : 'error'}
            message={snackMessage}
          />
        </Box>
      </ClickAwayListener>
    </FormControl>
  );
};

export default withStyles(styles)(Select);
