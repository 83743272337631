import AllowancesRow from 'common/oldJavascripts/components/Shared/AllowancesRow';
import Badge from 'common/oldJavascripts/components/Base/Badge';
import Field from 'common/oldJavascripts/components/Base/Field';
import T from 'common/oldJavascripts/utils/i18n';
import Link from 'common/oldJavascripts/components/Base/Link';
import RateCell from '../Offers/RateCell';

import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import format from 'common/oldJavascripts/utils/format.js';
import OfferStatus from 'common/oldJavascripts/components/Shared/OfferStatus';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import ExpandableTableCellTitle from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCellTitle';

const Cell = ExpandableTableCell;
const CellTitle = ExpandableTableCellTitle;
const Row = ExpandableTableRow;

const styles = () => ({
  overflowText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

const ReviewRow = Relay.createClass({
  statics: {
    queries: {
      review: {
        info(params, related) {
          const routerParams = related['/router/params'];
          return {
            id: `/projects/${routerParams.projectId}/reviews/${params.id}`,
          };
        },
      },
    },
  },

  render() {
    const {
      review = {},
      checked,
      expanded,
      classes = {},
      'data-test-id': dataTestId,
    } = this.props;
    const { status: reviewStatus, data: offer = {} } = review || {};

    if (reviewStatus === 'loading') return null;
    const fullName = this._getFullName();

    return (
      <Row
        key={offer.id}
        onClick={this._toggleSelect}
        data-test-id={dataTestId}
      >
        <Cell>
          <Field
            hideLabel={true}
            inline={true}
            label={T('project.reviews.select_offer', { user: fullName })}
            onChange={this._handleSelect}
            type="checkbox"
            value={checked}
          />
        </Cell>
        <Cell>
          <CellTitle>{fullName}</CellTitle>
          <Tooltip disableInteractive title={offer.email} enterDelay={100}>
            <div className={classes.overflowText}>{offer.email}</div>
          </Tooltip>
        </Cell>
        <Cell>
          <CellTitle>{offer.department_name}</CellTitle>
        </Cell>
        <Cell>
          {offer.occupation_description}
          <br />
          {offer.account_code}
        </Cell>
        <Cell>{offer.union_description}</Cell>
        <Cell>{format.date(offer.start_date)}</Cell>
        {this._renderEndDateCell(offer)}
        <RateCell offer={offer} />
        <Cell expanded={expanded} align="center">
          <AllowancesRow.Icon offer={offer} onClick={this._toggleAllowances} />
        </Cell>
        <Cell>{this._renderStatusBadge()}</Cell>
        <Cell>{this._renderDeleteButton()}</Cell>
      </Row>
    );
  },

  _getFullName() {
    const {
      review: { data: { first_name, middle_name, last_name } = {} } = {},
    } = this.props;

    return format.fullName(first_name, middle_name, last_name);
  },

  _handleSelect: function(value) {
    const { onSelect, params: { id } = {} } = this.props;
    onSelect(id, value);
  },

  _renderDeleteButton() {
    const { routerParams = {}, review: reviewQuery = {} } = this.props;
    const { data: { id: reviewId } = {}, is_sent: reviewIsSent } =
      reviewQuery || {};
    const { projectId } = routerParams || {};
    if (reviewIsSent) return null;
    return (
      <Link
        icon={true}
        to={`/projects/${projectId}/reviews/${reviewId}/delete`}
      >
        <DeleteIcon />
      </Link>
    );
  },

  _renderEndDateCell(item) {
    if (this.props.endDateAllowed) {
      return <Cell>{format.date(item.end_date)}</Cell>;
    }
  },

  _renderStatusBadge() {
    if (this.props.review.data.status) {
      return <OfferStatus status={this.props.review.data.status} />;
    }

    if (this.props.review.data.reviewed_at) {
      return <Badge success={true}>Reviewed</Badge>;
    }

    return <Badge warning={true}>Not Reviewed</Badge>;
  },

  _toggleAllowance() {
    this.setState({
      allowanceExpanded: !this.state.allowanceExpanded,
    });
  },

  _toggleAllowances() {
    this.props.onAllowanceToggle(this.props.params.id);
  },

  _toggleSelect() {
    this._handleSelect(!this.props.checked);
  },
});

export default withStyles(styles)(ReviewRow);
