import { createTheme } from '@mui/material/styles';
import {
  WHITE,
  MAIN,
  DARK,
  LIGHT,
  LIGHT_CONTRAST_TEXT,
  GOLD,
  GOLD_DARK,
  GOLD_LIGHT,
  RED,
  RED_LIGHT,
} from './colors';

const defaultMuiTheme = createTheme();
const { spacing } = defaultMuiTheme;

const palette = {
  primary: {
    main: MAIN,
    dark: DARK,
    light: LIGHT,
    contrastText: WHITE,
    lightContrastText: LIGHT_CONTRAST_TEXT,
  },
  secondary: {
    main: GOLD,
    dark: GOLD_DARK,
    light: GOLD_LIGHT,
    contrastText: WHITE,
    lightContrastText: WHITE,
  },
  error: {
    main: RED,
    light: RED_LIGHT,
  },
};

const theme = createTheme({
  palette,
  typography: {
    fontFamily: ['"Helvetica Neue"', 'Arial', 'Roboto', 'sans-serif'],
    h1: {
      fontSize: 46,
    },
    h2: {
      fontSize: 35,
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 18,
    },
    h5: {
      fontSize: 16,
    },
    body1: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 14,
    },
    button: {
      fontSize: 14,
      textTransform: 'none',
    },
    caption: {
      fontSize: 10,
    },
  },
  shape: {
    borderRadius: 2,
  },
  props: {
    MuiButton: {
      color: 'primary',
      variant: 'contained',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: spacing(1, 2),
        borderRadius: '2px',
      },
      contained: {
        boxShadow: 'none',
      },
      outlined: {
        padding: `${spacing(1) - 1}px ${spacing(2) - 1}px`,
      },
      containedPrimary: {
        '&$disabled': {
          backgroundColor: palette.primary.light,
          color: palette.primary.lightContrastText,
        },
      },
      containedSecondary: {
        '&$disabled': {
          backgroundColor: palette.secondary.light,
          color: palette.secondary.lightContrastText,
        },
      },
      outlinedPrimary: {
        '&$disabled': {
          borderColor: palette.primary.light,
          color: palette.primary.light,
        },
      },
      outlinedSecondary: {
        '&$disabled': {
          borderColor: palette.secondary.light,
          color: palette.secondary.light,
        },
      },
    },
  },
});

export default theme;
