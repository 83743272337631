import React, { Fragment } from 'react';
import TOOLTIP_ICON from 'common/images/tooltip.svg';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createOfferCurrency as currency } from 'common/oldJavascripts/utils/format';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';
import UnionWeeklyEmployeeTermsMobile from './UnionWeeklyEmployeeTermsMobile';
import UnionWeeklyOnCallTermsMobile from './UnionWeeklyOnCallTermsMobile';
import TermsOfEmploymentSAG from './TermsOfEmploymentSAG';

const useStyles = makeStyles(theme => ({
  terms: {
    marginTop: '40px',
  },
  title: {
    color: '#646464',
    fontSize: '24px !important',
    marginBottom: '20px !important',
  },
  amount: {
    color: '#0000FF !important',
    fontSize: '30px !important',
    padding: '18px !important',
    textAlign: 'center !important',
    border: '0.1px solid #d3f0ee',
    [theme.breakpoints.up('md')]: {
      padding: 15,
    },
  },
  grey: {
    color: '#939393 !important',
  },
  tooltip: {
    marginLeft: '5px',
  },
  mobileTermsRateHeader: {
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    gridTemplateRows: '30% 70%',
    gridAutoFlow: 'row',
  },
  greenBox: {
    backgroundColor: '#E2F1FF',
    fontSize: 18,
    fontWeight: 400,
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  regularBox: {
    backgroundColor: '#E2F1FF',
    color: '#5b5b5b !important',
    fontSize: '14px !important',
    fontWeight: '700 !important',
    textAlign: 'center !important',
    padding: '15px !important',
    border: 'none',
  },
  distant: {
    color: '#5b5b5b',
    fontSize: '16px !important',
    fontWeight: '700 !important',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  distantContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const formatAmount = amount => {
  return !Number.isInteger(amount) ? currency(amount) : `$${amount}`;
};

const TermsOfEmploymentMobile = ({
  isNonUnion,
  offerData,
  isNonUnionScheduleA,
  isNonUnionScheduleC,
  isNonUnionScheduleD,
  isUnionScheduleA,
  isUnionWeeklySchedule,
  isUnionWeeklyOnCall,
  terms,
  isSAGUnion,
}) => {
  const classes = useStyles();
  const {
    hoursDaysTitle,
    hourlyLocationRate,
    hourlyStudioRate,
    dailyStudioRate,
    weeklyLocationRate,
    weeklyStudioRate,
    idlePayRate,
    sixthDayRate,
    sixthDayLocationRate,
    seventhDayLocationRate,
    seventhDayRate,
    studioDaysHours,
    locationHours,
    locationTitle,
    overtimeRate,
    daysPerWeekTitle,
    daysPerWeek,
  } = offerData;

  if (isSAGUnion) {
    return <TermsOfEmploymentSAG classes={classes} terms={terms} />;
  }

  if (isUnionWeeklySchedule) {
    return <UnionWeeklyEmployeeTermsMobile terms={terms} />;
  }

  if (isUnionWeeklyOnCall) {
    return <UnionWeeklyOnCallTermsMobile terms={terms} />;
  }

  const isStudioRate =
    dailyStudioRate || weeklyStudioRate || hourlyStudioRate || studioDaysHours;
  const isDistant = hourlyLocationRate || weeklyLocationRate || locationHours;
  const offerSeventhDayRate = formatAmount(seventhDayRate);

  const payDayTitle = day => {
    if (isUnionScheduleA) {
      return `Hours Apply to ${day}th`;
    }
    if (isNonUnionScheduleA) {
      return (
        <>
          Apply Hours per Day <br /> to {day}
          th Day
        </>
      );
    }
    if (isNonUnionScheduleC) {
      return `Pay ${day}th day at`;
    }
    return `${day}th day pay`;
  };

  const weekendPayRate = payRate => {
    if (isNonUnionScheduleA || isUnionScheduleA)
      return payRate > 0 ? 'Yes' : 'No';
    return formatAmount(payRate);
  };

  const showStudioDaysHours =
    !isNonUnionScheduleC && !isNonUnionScheduleD && !!studioDaysHours;

  return (
    <div className={classes.terms}>
      <Typography
        className={classes.title}
        data-test-id="TermsOfEmploymentMobile-title"
      >
        Terms of Employment
      </Typography>
      {isStudioRate && (
        <Fragment>
          <UsaOnly>
            <div>
              {!isNonUnion && (
                <div className={classes.greenBox}>
                  <Typography
                    className={classes.distant}
                    data-test-id="TermsOfEmploymentMobile-studioSection-title"
                  >
                    STUDIO{' '}
                    <Tooltip
                      disableInteractive
                      title="Studio refers to a local distance within the studio zone"
                      placement="top-start"
                      className={classes.tooltip}
                    >
                      <img src={TOOLTIP_ICON} alt="schedule tooltip" />
                    </Tooltip>
                  </Typography>
                </div>
              )}
            </div>
          </UsaOnly>
          {(hourlyStudioRate || dailyStudioRate) && (
            <Table>
              <TableHead>
                <TableRow>
                  {hourlyStudioRate && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-hourlyStudioRate-title"
                    >
                      <UsaOnly>Hourly Rate</UsaOnly>
                      <CanadaOnly>Rate Per Hour</CanadaOnly>
                    </TableCell>
                  )}
                  {dailyStudioRate && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-dailyStudioRate-title"
                    >
                      Daily Rate
                    </TableCell>
                  )}
                  {overtimeRate && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-overtimeTitle"
                    >
                      Overtime Rate
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {hourlyStudioRate && (
                    <TableCell
                      className={classes.amount}
                      data-test-id="TermsOfEmploymentMobile-hourlyStudioRate-amount"
                    >
                      {formatAmount(hourlyStudioRate)}
                    </TableCell>
                  )}
                  {dailyStudioRate && (
                    <TableCell
                      className={classes.amount}
                      data-test-id="TermsOfEmploymentMobile-dailyStudioRate-amount"
                    >
                      {formatAmount(dailyStudioRate)}
                    </TableCell>
                  )}

                  {overtimeRate && (
                    <TableCell
                      className={classes.amount}
                      data-test-id="TermsOfEmploymentMobile-overtime-amount"
                    >
                      {formatAmount(overtimeRate)}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          )}
          {(weeklyStudioRate || showStudioDaysHours) && (
            <Table className={classes.distantContainer}>
              <TableHead>
                <TableRow>
                  {weeklyStudioRate && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-weeklyStudioRate-title"
                    >
                      Weekly Rate
                    </TableCell>
                  )}
                  {showStudioDaysHours && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-hourDays-title"
                    >
                      <UsaOnly>
                        {isNonUnionScheduleA ? 'Hours Per Day' : hoursDaysTitle}
                      </UsaOnly>
                      <CanadaOnly>{hoursDaysTitle}</CanadaOnly>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {weeklyStudioRate && (
                    <TableCell
                      className={classes.amount}
                      data-test-id="TermsOfEmploymentMobile-weeklyStudioRate-amount"
                    >
                      {formatAmount(weeklyStudioRate)}
                    </TableCell>
                  )}
                  {showStudioDaysHours && (
                    <TableCell
                      className={[classes.amount, classes.grey].join(' ')}
                      data-test-id="TermsOfEmploymentMobile-studioDaysHours-amount"
                    >
                      {studioDaysHours}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          )}
          {daysPerWeek && (
            <CanadaOnly>
              <Table className={classes.distantContainer}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-canada-daysPerWeek-title"
                    >
                      {daysPerWeekTitle}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {daysPerWeek && (
                      <TableCell
                        className={[classes.amount, classes.grey].join(' ')}
                        data-test-id="TermsOfEmploymentMobile-canada-daysPerWeek"
                      >
                        {daysPerWeek}
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </CanadaOnly>
          )}
          <UsaOnly>
            <Table>
              <TableHead>
                <TableRow>
                  {(isUnionScheduleA || sixthDayRate) && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-6thDayRate-title"
                    >
                      {payDayTitle('6')}
                    </TableCell>
                  )}
                  {(isUnionScheduleA || seventhDayRate) && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-7thDayRate-title"
                    >
                      {payDayTitle('7')}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {(isUnionScheduleA || sixthDayRate) && (
                    <TableCell
                      className={`${classes.amount} ${classes.grey}`}
                      data-test-id="TermsOfEmploymentMobile-6thDayRate-amount"
                    >
                      {weekendPayRate(sixthDayRate)}
                    </TableCell>
                  )}
                  {(isUnionScheduleA || offerSeventhDayRate) && (
                    <TableCell
                      className={`${classes.amount} ${classes.grey}`}
                      data-test-id="TermsOfEmploymentMobile-7thDayRate-amount"
                    >
                      {weekendPayRate(offerSeventhDayRate)}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </UsaOnly>
        </Fragment>
      )}
      <UsaOnly>
        {' '}
        {isDistant && (
          <div className={classes.distantContainer}>
            <div>
              <div className={classes.greenBox}>
                <Typography
                  className={classes.distant}
                  data-test-id="TermsOfEmploymentMobile-distantSection-title"
                >
                  DISTANT{' '}
                  <Tooltip
                    disableInteractive
                    title="Distant refers to the location outside the studio zone."
                    placement="top-start"
                    className={classes.tooltip}
                  >
                    <img src={TOOLTIP_ICON} alt="schedule tooltip" />
                  </Tooltip>
                </Typography>
              </div>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  {hourlyLocationRate && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-hourlyRate-title"
                    >
                      Hourly Rate
                    </TableCell>
                  )}
                  {weeklyLocationRate && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-weeklyLocationRate-title"
                    >
                      Weekly Rate
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {hourlyLocationRate && (
                    <TableCell
                      className={classes.amount}
                      data-test-id="TermsOfEmploymentMobile-hourlyLocationRate-amount"
                    >
                      {formatAmount(hourlyLocationRate)}
                    </TableCell>
                  )}
                  {weeklyLocationRate && (
                    <TableCell
                      className={classes.amount}
                      data-test-id="TermsOfEmploymentMobile-weeklyLocationRate-amount"
                    >
                      {formatAmount(weeklyLocationRate)}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
            {!!locationHours && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-locationHours-title"
                    >
                      {locationTitle}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {locationHours && (
                      <TableCell
                        className={classes.amount}
                        data-test-id="TermsOfEmploymentMobile-locationHours-amount"
                      >
                        {locationHours}
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            )}
            <UsaOnly>
              <Table>
                <TableHead>
                  <TableRow>
                    {(isUnionScheduleA || sixthDayRate) && (
                      <TableCell
                        className={classes.regularBox}
                        data-test-id="TermsOfEmploymentMobile-6thDayRate-idle-title"
                      >
                        {payDayTitle('6')}
                      </TableCell>
                    )}
                    {(isUnionScheduleA || seventhDayRate) && (
                      <TableCell
                        className={classes.regularBox}
                        data-test-id="TermsOfEmploymentMobile-7thDayRate-idle-title"
                      >
                        {payDayTitle('7')}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {(isUnionScheduleA || sixthDayRate) && (
                      <TableCell
                        className={`${classes.amount} ${classes.grey}`}
                        data-test-id="TermsOfEmploymentMobile-6thDayRate-idle-amount"
                      >
                        {isUnionScheduleA
                          ? weekendPayRate(sixthDayLocationRate)
                          : formatAmount(sixthDayRate)}
                      </TableCell>
                    )}
                    {(isUnionScheduleA || offerSeventhDayRate) && (
                      <TableCell
                        className={`${classes.amount} ${classes.grey}`}
                        data-test-id="TermsOfEmploymentMobile-7thDayRate-idle-amount"
                      >
                        {isUnionScheduleA
                          ? weekendPayRate(seventhDayLocationRate)
                          : offerSeventhDayRate}
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </UsaOnly>
          </div>
        )}
        {idlePayRate && (
          <Table className={classes.distantContainer}>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.regularBox}
                  data-test-id="TermsOfEmploymentMobile-idleRate-title"
                >
                  Idle Days Pay
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  className={classes.amount}
                  data-test-id="TermsOfEmploymentMobile-idleRate-amount"
                >
                  {formatAmount(idlePayRate)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </UsaOnly>
    </div>
  );
};

export default TermsOfEmploymentMobile;
