import React from 'react';

// Components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

// Custom Components
import RatePerHour from './RatePerHour';
import OvertimeRate from '../shared/OvertimeRatePerHour';
import GuaranteedHours from '../shared/GuaranteedHours';

// Utitlities
import classNames from 'class-names';

const DefaultTermsOfEmploymentTable = ({
  classes = {},
  onChange: upstreamOnChange,
  formData = {},
  formErrors = {},
} = {}) => {
  const textFieldProps = key => ({
    onChange: upstreamOnChange,
    formData: formData,
    classes: {
      root: classNames(classes.cellWithTextFieldClass, {
        [classes.errorCell]: formErrors[key],
      }),
      textField: classes.textField,
      textFieldInputContainer: classes.textFieldInputContainer,
      textFieldInput: classes.textFieldInput,
    },
  });

  const guaranteedHoursProps = formKey => ({
    onChange: upstreamOnChange,
    value: formData[formKey],
    formKey,
    classes: {
      root: classes.cellWithTextFieldClass,
      textField: classes.textField,
      textFieldInputContainer: classes.textFieldInputContainer,
      textFieldInput: classes.textFieldInput,
    },
  });

  const rateLabelClass = classNames(classes.labelCellClass, {
    [classes.errorCell]: formErrors.rate,
  });

  const overtimeRateLabelClass = classNames(classes.labelCellClass, {
    [classes.errorCell]: formErrors.overtimeRate,
  });

  return (
    <Table className={classes.root}>
      <TableBody>
        <TableRow>
          <TableCell className={rateLabelClass}>
            Rate Per Hour
            {formErrors.rate && (
              <Typography
                color="error"
                variant="caption"
                className={classes.errorLabel}
              >
                {formErrors.rate}
              </Typography>
            )}
          </TableCell>
          <RatePerHour {...textFieldProps('ratePerHour')} />
        </TableRow>
        <TableRow>
          <TableCell className={overtimeRateLabelClass}>
            Overtime Rate
            {formErrors.overtimeRate && (
              <Typography
                color="error"
                variant="caption"
                className={classes.errorLabel}
              >
                {formErrors.overtimeRate}
              </Typography>
            )}
          </TableCell>
          <OvertimeRate {...textFieldProps('overtimeRatePerHour')} />
        </TableRow>
        <TableRow>
          <TableCell className={classes.labelCellClass}>
            Guaranteed Hours
          </TableCell>
          <GuaranteedHours {...guaranteedHoursProps('guaranteedHours')} />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default DefaultTermsOfEmploymentTable;
