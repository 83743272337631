import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import getSSOUrl from 'common/constants/config/ssoUrl';
import Loader from 'common/components/Loader/Loader';
import DocumentModal from 'common/oldJavascripts/components/Shared/DocumentModal';
import useStateW4Documents from './hooks/useStateW4Documents';
import useStateW4Download from './hooks/useStateW4Download';
import useStateW4Images from './hooks/useStateW4Images';
import ErrorIndicator from './ErrorIndicator';
import ValidDialogContent from './ValidDialogContent';
import InValidDialogContent from './InValidDialogContent';

const styles = {
  mobileView: {
    '@media (min-width: 320px) and (max-width: 480px)': {
      minWidth: '100% !important',
    },
  },
  closeButton: {
    '@media (min-width: 320px) and (max-width: 480px)': {
      right: '0 !important',
      top: '0 !important',
    },
    height: 35,
    borderRadius: '2px',
    position: 'absolute !important',
    right: '8px',
    top: '8px',
  },
  btn: {
    height: 35,
    borderRadius: '2px !important',
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: 14,
  },
  btnContained: {
    backgroundColor: '#0000FF !important',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#0000FF ',
    },
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  btnOutlined: {
    border: '1px solid #0000FF !important',
    color: '#0000FF',
    backgroundColor: '#fff !important',
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  contact: {
    color: '#0000FF',
    fontSize: 16,
    fontWeight: 500,
    '& a': {
      textDecoration: 'none',
      display: 'block',
    },
  },
  contentText: {
    margin: '20px 0px',
  },
  subHead: {
    fontWeight: '500 !important',
    fontSize: '14px',
  },
  itemText: {
    flexGrow: 0,
    marginRight: '25px',
    color: '#525252',
    '& span': {
      textDecoration: 'underline',
    },
  },
  listRoot: {
    paddingLeft: 0,
    color: '#525252',
  },
  dialogPaper: {
    minWidth: 500,
    minHeight: 300,
    justifyContent: 'center',
  },
  icon: {
    marginRight: '5px !important',
  },
  actionButtons: {
    '& .Mui-disabled': {
      color: '#9A9FAF',
      backgroundColor: '#fff',
      border: '1px solid #9A9FAF !important',
    },
    '& .MuiCircularProgress-root': {
      color: '#9A9FAF',
    },
  },
};

const StateW4Modal = props => {
  const MYCNC_W4S_URL = `${getSSOUrl()}/offer/w4s`;

  const { classes, onClose, onSubmit, offerData } = props;
  const { id: offerId, work_state = {}, worker_type: workerType } = offerData;

  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const {
    loading,
    data: stateW4Data = {},
    error: stateW4DocsError,
  } = useStateW4Documents(offerId);
  const {
    data: downloadedDocumentData = {},
    error: downloadError,
  } = useStateW4Download(offerId, 'mycnc', 'state_w4');
  const { data: imagesData = {}, error: imagesError } = useStateW4Images(
    offerId,
    'mycnc',
    'state_w4',
  );

  const { offerExternalDocuments: stateW4DocumentsData = [] } = stateW4Data;
  const {
    offerExternalDocuments: downloadedUrlData = [],
  } = downloadedDocumentData;
  const { offerExternalDocuments: imagesUrlData = [] } = imagesData;

  const { downloadUrl = '' } =
    downloadedUrlData.length > 0 && downloadedUrlData[0];
  const { images = '' } = imagesUrlData.length > 0 && imagesUrlData[0];
  const { status, name, uploadedAt, documentId } =
    stateW4DocumentsData.length > 0 && stateW4DocumentsData[0];

  const title =
    status === 'valid'
      ? 'Tax Withholding Form Exists'
      : 'Tax Withholding Form Incomplete/Expired';

  const inValidProps = {
    offerId,
    workState: work_state?.code,
    workerType,
    classes,
    MYCNC_W4S_URL,
  };

  const validProps = {
    offerId,
    workState: work_state?.code,
    workerType,
    classes,
    name,
    uploadedAt,
    documentId,
    MYCNC_W4S_URL,
    downloadUrl,
    downloadError,
  };

  const handleOnSubmit = data => {
    onSubmit(data);
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      open={true}
      style={{ zIndex: isDocumentModalOpen ? 0 : 1300 }}
      classes={{ paper: classes.dialogPaper }}
      data-test-id="Dialog-Title-Statew4"
      PaperProps={{ classes: { root: classes.mobileView } }}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <DialogTitle data-test-id="StateW4Modal-title">
            {title}
            <IconButton
              aria-label="close"
              onClick={onClose}
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          {status === 'valid' ? (
            <ValidDialogContent
              onSubmit={handleOnSubmit}
              onModalClick={() => setIsDocumentModalOpen(true)}
              {...validProps}
            />
          ) : (
            <InValidDialogContent {...inValidProps} />
          )}
        </>
      )}
      {stateW4DocsError || imagesError ? <ErrorIndicator /> : null}
      {isDocumentModalOpen && (
        <DocumentModal
          pages={images}
          onClose={() => setIsDocumentModalOpen(false)}
        />
      )}
    </Dialog>
  );
};

export default withStyles(styles)(StateW4Modal);
