import React from 'react';

export default ({ style, ...propsCatch }) => (
  <svg
    width="18"
    height="14"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    {...propsCatch}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M-3-5h24v24H-3z" />
      <path
        d="M0 12.13h18v1.25H0v-1.25zm16.136-.5h-.091c-.804-.039-1.444-.463-1.974-1.023-.822.444-1.773.56-2.65.135-.64-.328-1.041-.85-1.37-1.274-.073-.116-.146-.213-.22-.31-.2.368-.383.657-.383.657l-.092.155c-.383.54-1.023 1.486-2.138 1.216-.877-.212-1.407-.985-1.754-1.487a4.145 4.145 0 0 1-.292-.482c-.311.579-.713 1.216-1.353 1.602a2.6 2.6 0 0 1-2.065.31C.97 10.915.64 10.316.347 9.795L0 9.159l1.206-.753.347.637c.274.483.348.599.567.656.329.097.712.04 1.005-.154.457-.29.767-.869 1.115-1.602l.383-.811c.128-.27.402-.444.695-.406.292.02.53.251.603.541.146.54.384 1.081.694 1.544.293.444.585.83.914.908.2.058.384-.174.713-.657l.054-.096c.147-.213.731-1.37.841-1.66a.725.725 0 0 1 .548-.58.695.695 0 0 1 .823.58c.091.424.274.81.603 1.235.31.425.548.714.877.869.384.193.786.174 1.17.02-.183-.329-.366-.638-.494-.889a11.69 11.69 0 0 1-1.37-4.595c-.037-.482-.055-1.08.109-1.66.201-.714.658-1.255 1.224-1.506.622-.27 1.39-.173 2.01.27.44.31.805.773 1.134 1.41 1.005 1.988.986 4.44-.037 6.41a3.9 3.9 0 0 1-.548.81c.292.251.603.444.932.463.11 0 .2-.038.274-.096.018-.02.073-.116.11-.309l.127-.734L18 9.255l-.128.734c-.091.502-.256.869-.53 1.14-.329.327-.75.501-1.206.501zm-2.778-9.537a.626.626 0 0 0-.2.039c-.183.096-.348.309-.42.579-.11.347-.092.753-.056 1.1.11 1.39.53 2.761 1.188 3.997.11.193.238.444.402.714.091-.116.165-.232.22-.348.785-1.525.803-3.494.036-5.02-.22-.424-.439-.713-.676-.887a.812.812 0 0 0-.494-.174z"
        fill="#000"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
