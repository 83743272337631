import gql from 'graphql-tag';

export default gql`
  mutation updatePermissionPreset(
    $id: Int!
    $name: String!
    $permissions: [Int!]!
  ) {
    updatePermissionPreset(id: $id, name: $name, permissions: $permissions) {
      name
      id
    }
  }
`;
