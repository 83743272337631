import React from 'react';

import classNames from 'classnames';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CONFIG from 'common/oldJavascripts/config.js';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';

class DatePickerField extends React.Component {
  _pushSnackbar = message => {
    const { pushSnackbarNotification } = this.props;
    pushSnackbarNotification({
      message,
      variant: SnackbarVariants.WARNING,
      duration: 5000,
    });
  };

  _onChangeRaw = ev => {
    const { maxDate, minDate, onChange } = this.props;
    const dateDigits = ev.target.value.replace(/[^0-9.]/g, '');

    if (dateDigits.length !== 8) return;

    const enteredMoment = moment(dateDigits, 'MMDDYYYY');

    // Check for valid date
    if (!enteredMoment.isValid()) {
      ev.preventDefault();
      this._pushSnackbar('You tried to enter an invalid date.');
      return;
    }

    // Check mininum date
    if (minDate && enteredMoment.isBefore(minDate, 'day')) {
      ev.preventDefault();
      this._pushSnackbar('You tried to enter a date which was too early.');
      return;
    }

    // Check maximum date
    if (maxDate && enteredMoment.isAfter(maxDate, 'day')) {
      ev.preventDefault();
      this._pushSnackbar('You tried to enter a date which was too late.');
      return;
    }

    onChange(enteredMoment);
  };

  render = () => (
    <DatePicker
      {...this.props}
      className={classNames(this.props.className, 'datepicker')}
      disabledKeyboardNavigation
      onChangeRaw={this._onChangeRaw}
    />
  );
}

DatePickerField.defaultProps = {
  dateFormat: CONFIG.DATE_FORMAT.UI,
  placeholderText: CONFIG.DATE_FORMAT.UI,
  isClearable: true,
};

export default withSnackbarNotification(DatePickerField);
