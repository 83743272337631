import { useMutation } from '@apollo/react-hooks';
import * as Mutations from 'common/apollo/mutations';

const useAddRelatedProject = () => {
  const [mutate, { loading, error }] = useMutation(Mutations.AddRelatedProject);
  const addRelatedProject = data => mutate({ variables: { ...data } });

  return [addRelatedProject, { loading, error }];
};

export default useAddRelatedProject;
