import React from 'react';

import classNames from 'class-names';
import { withStyles } from '@mui/styles';
import WarningIcon from '@mui/icons-material/Warning';
import { List, ListItem, ListItemText } from '@mui/material';
const { fieldName } = require('../../utils/format.js');

const styles = {
  root: {
    borderRadius: 3,
    border: '1px solid #ea5a5a',
    color: '#ea5a5a',
    fontSize: 14,
    fontWeight: 'bold',
    padding: '1.2em',
    marginBottom: 10,
  },
  empty: {
    display: 'none',
  },
  header: {
    fontSize: 16,
    marginBottom: 12,
  },
  subheader: {
    color: 'black',
    marginBottom: '1.5em',
  },
  icon: {
    fontSize: '28',
  },
};

const OldValidationAlert = props => {
  const { errors, classes = {} } = props;
  const fields = Object.keys(errors || {});

  const rootClass = classNames(
    {
      [classes.empty]: fields.length === 0,
    },
    classes.root,
  );

  const getFieldName = (field, errorDescription = []) => {
    const { overrides: { [field]: override } = {} } = props;
    // Error descriptions are returned from the API as a string inside of an array
    // inside of another array
    const errorDetail =
      (Array.isArray(errorDescription) &&
        errorDescription[0] &&
        Array.isArray(errorDescription[0]) &&
        errorDescription[0][0]) ||
      '';
    // Display the error and accompanying details if any
    return override
      ? override
      : `${fieldName(field, {
          removeTrailingDigits: true,
        })} ${fieldName(errorDetail, { removeTrailingDigits: true })}`;
  };

  return (
    <div className={rootClass} data-test-id="ValidationAlert-root">
      {fields.length > 0 && (
        <div className={classes.header}>
          <WarningIcon className={classes.icon} />
          <span>Oops! You missed something.</span>
        </div>
      )}

      {fields.length > 0 && (
        <div className={classes.subheader}>
          Please complete the following required field
          {fields.length === 1 ? '' : 's'}:
        </div>
      )}

      <List>
        {fields.map(field => (
          <ListItem key={field}>
            <ListItemText primary={getFieldName(field, errors[field])} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default withStyles(styles)(OldValidationAlert);
