import React from 'react';
import { Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 10px',
    [theme.breakpoints.up('md')]: {
      padding: '0 27px',
    },
  },
  title: {
    color: '#646464 !important',
    fontSize: '24px !important',
    fontWeight: ' 400 !important',
  },
  reviewOfferCopy: {
    color: '#939393 !important',
    fontSize: '16px !important',
    fontWeight: 400,
    marginTop: '20px !important',
  },
}));

const StyledBegingButton = styled(Button)({
  width: '95%',
  height: 50,
  backgroundColor: '#0000FF !important',
  color: '#f5f5f5 !important',
  fontSize: '16px !important',
  fontWeight: 500,
  marginTop: '30px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#DDDDDD !important',
    color: '#0000FF !important',
  },
  '&:disabled': {
    backgroundColor: '#DDDDDD !important',
    color: '#AAAAAA !important',
  },
});

const StyledRejectTypography = styled(Typography)({
  color: '#0000FF',
  fontSize: 16,
  fontWeight: 400,
  letterSpacing: 0.5,
  textAlign: 'center',
  marginTop: '30px',
  cursor: 'pointer',
});

const ReviewOffer = props => {
  const { onContinueToNextStep, onReject, disabled = false } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Review Your Offer</Typography>
      <Typography className={classes.reviewOfferCopy}>
        Take a look at the details of your offer on this page and click the
        button below when you're ready to accept.
      </Typography>
      <StyledBegingButton
        variant="contained"
        onClick={onContinueToNextStep}
        data-test-id="ReviewOffer-begin"
        disabled={disabled}
      >
        Begin Start Packet Forms
      </StyledBegingButton>
      <StyledRejectTypography
        onClick={onReject}
        data-test-id="ReviewOffer-reject"
      >
        Reject offer & provide reason
      </StyledRejectTypography>
    </div>
  );
};

export default ReviewOffer;
