import React from 'react';
import { Route } from 'react-router-dom';

// Layouts
import BaseLayout from 'common/oldJavascripts/components/Pages/Layouts/Base';

// components for Pendo
import Feature from 'common/components/Feature';
import PendoController from 'common/components/PendoController';

// Components
import ChangeApp from 'common/oldJavascripts/components/Pages/Projects/ChangeApp';

export default props => {
  return (
    <BaseLayout>
      <Route exact component={ChangeApp} />
      <Feature name="pendoIntegration">
        <PendoController projectId={props?.match?.params?.projectId} />
      </Feature>
    </BaseLayout>
  );
};
