import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Box from 'common/oldJavascripts/components/Base/Box';
import Link from 'common/oldJavascripts/components/Base/Link';
import Header from 'common/oldJavascripts/components/Base/Header';
import NavBar from 'common/oldJavascripts/components/Base/NavBar';
import Form from 'common/oldJavascripts/components/Shared/Form';
import T from 'common/oldJavascripts/utils/i18n';

// Utilities
import history from 'common/constants/config/history';

import uniqueId from 'common/utilities/uniqueId';

import BoxHeader from 'common/oldJavascripts/components/Base/Box/BoxHeader';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

const Field = Form.Field;
const Button = Form.Button;

const getFileErrors = errors => {
  const fileErrors =
    errors && (errors.bulk_file || errors.name_error || errors.email);

  if (fileErrors) {
    if (Array.isArray(fileErrors)) {
      return fileErrors.map(code =>
        code === 'invalid_file_format' ? 'Not a valid file.' : code,
      );
    }
    return fileErrors === 'invalid_file_format'
      ? ['Not a valid file.']
      : [fileErrors];
  }
};

export default Relay.createClass({
  statics: {
    mutators: {
      crew: {
        info: function(_, related) {
          var params = related['/router/params'];

          return {
            id: '/projects/' + params.projectId + '/crew',
            create: true,
          };
        },
        stale(_, related) {
          const params = related['/router/params'];

          return [`/projects/${params.projectId}/crew`];
        },
      },
    },
  },

  getInitialState: function() {
    return {
      isValidFileType: true,
      isError: false,
      fieldInputKey: uniqueId(),
    };
  },

  render: function() {
    const { crew: crewMutator = {}, routerParams = {} } = this.props;
    const { isError, fieldInputKey } = this.state;
    const { projectId } = routerParams || {};
    return (
      <LayoutContent>
        <Box minimal={true}>
          <BoxHeader>
            <Header>
              <Header.Title primary={true}>
                {T('Bulk Upload Crew')}
              </Header.Title>
            </Header>
          </BoxHeader>
          <BoxItem>
            {T('In order to bulk upload Crew members, source file must be ')}
            {T('CSV (comma separated values). ')}
            {T('«First name», «Last name» and «Email» columns are mandatory.')}
            <Link
              to={`${process.env.PUBLIC_URL}/samples/crew.csv`}
              external={true}
            >
              {T('Sample')}
            </Link>
          </BoxItem>
          <BoxItem>
            <Form
              mutator={crewMutator}
              action="import"
              multipart={true}
              onSuccess={this._crewSubmitted}
              onError={this._crewError}
            >
              <Field
                mutator={crewMutator}
                key={fieldInputKey}
                name="bulk_file"
                type="file"
                label={T('project_crew.form.bulk_file')}
                accept=".csv"
                errors={isError && getFileErrors(crewMutator.errors)}
                hideLabel={true}
                onFileTypeChange={this._updateFileType}
              />
              <NavBar>
                <NavBar.Item>
                  <Button
                    mutator={crewMutator}
                    disabled={!this.state.isValidFileType}
                  >
                    {T('Upload')}
                  </Button>
                </NavBar.Item>
                <NavBar.Item>
                  <Link to={`/projects/${projectId}/crew`}>Cancel</Link>
                </NavBar.Item>
              </NavBar>
            </Form>
          </BoxItem>
        </Box>
      </LayoutContent>
    );
  },

  _updateFileType: function(validtype) {
    this.setState({ isValidFileType: validtype });
  },

  _crewSubmitted: function() {
    history.goBack();
  },
  _crewError: function() {
    // Update fieldInputKey on error to clear the file type input
    this.setState({ isError: true, fieldInputKey: uniqueId() });
  },
});
