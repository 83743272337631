import React from 'react';
import WeeklyInputField from './WeeklyInputField';
import WeeklyInputRadioRow from './WeeklyInputRadioRow';
import { Table, TableCell, TableRow } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const useStyles = makeStyles(theme => ({
  tableRoot: {
    marginTop: 10,
    width: '100%',
  },
  tableTitle: {
    marginTop: 30,
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 16,
    color: '#6d7278',
  },
  tableSpacing: {
    marginBottom: 16,
  },
  headerTitle: {
    width: '30%',
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: '14px !important',
    fontWeight: 'bold !important',
    border: '2px solid #BECFFE',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 8,
    textAlign: 'center',
  },
  warningLabel: {
    color: '#FFA800',
    fontSize: 14,
    textAlign: 'center',
    maxWidth: '65%',
    marginBottom: 10,
  },
  warningIcon: {
    color: '#FFA800',
    position: 'relative',
    top: 4,
    right: 8,
  },
  radioContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  radioGroupCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderBottom: 'none !important',
    paddingTop: '5px !important',
    paddingBottom: '2px !important',
  },
  radio: {
    '&$checked': {
      color: '#0000FF',
    },
  },
  checked: {
    color: '#0000FF',
    '&, & + $label': {
      color: '#0000FF !important',
      fontSize: 16,
    },
  },
  alignCopy: {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  scaleCopy: {
    fontWeight: 'bold',
    color: '#525252',
    fontSize: '14px',
  },
  label: {
    fontSize: '16px',
  },
}));

const theme = createTheme({
  overrides: {
    MuiInput: {
      input: {
        fontSize: 16,
        fontWeight: 'normal',
        textAlign: 'center',
        width: 196,
        top: 3,
        color: '#525252',
      },
      underline: {
        width: 196,
        color: '#696969',
        '&:before': {
          borderBottom: `1px solid #8D8D8D`,
        },
        '&$focused:not($disabled):before': {
          borderBottom: `2px solid #0000FF`,
        },
        '&:after': {
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          borderBottom: `2px solid #0000FF`,
        },
        '&$error:not($disabled):before': {
          borderBottom: `1px solid #FFBF00`,
        },
      },
      disabled: {
        '&:before': {
          borderBottom: `1px solid #C6C6C6`,
        },
      },
    },
    MuiFormGroup: {
      root: {
        flexWrap: 'no-wrap',
        flexDirection: 'row',
        justifyContent: 'center',
      },
    },
  },
});

const RateTable = props => {
  const classes = useStyles();
  const {
    formData = {},
    onChange: upstreamOnChange,
    scaleRates,
    contracts = [],
    isContractsLoading = false,
    isReadOnly = false,
  } = props;

  const {
    rate,
    rateDistant,
    weeklyRate,
    weeklyRateDistant,
    guaranteedHours,
    guaranteedHoursDistant,
    payIdleDaysDistant,
    payGoldAt,
    payGoldAtDistant,
    payAtScale,
    payAtScaleDistant,
    idleAtScaleDistant,
    goldAtScale,
    goldAtScaleDistant,
  } = formData;

  let isCNCSignatory,
    signatoryID = null;
  if (Array.isArray(contracts)) {
    const pensionContract = contracts.find(
      contract => contract.type === 'pension',
    );
    const pensionArr = pensionContract?.contracts || [];
    if (pensionArr.length > 0) {
      const contract = pensionArr.find(contract => contract.signatory);
      signatoryID = contract?.signatory?.id;
      isCNCSignatory = signatoryID === 'C';
    }
  }

  let isSignatoryAbsentMessage;
  if (!signatoryID && !isReadOnly && !isContractsLoading) {
    isSignatoryAbsentMessage =
      'Sorry, we’re not finding the scale rate. Please check with your C&C Coordinator.';
  }

  const onHandleIdleGoldUpdatePayRate = (rowKey, value, type, isScale) => {
    const isStudio = type === 'studio';
    const objKeyGold = isStudio ? 'payGoldAt' : 'payGoldAtDistant';
    const isDistant = type === 'distant';
    const isHourly = rowKey === 'payHourly';

    if (isHourly) {
      upstreamOnChange({
        [objKeyGold]: value,
      });
    }

    if (isHourly && isDistant) {
      upstreamOnChange({
        payIdleDaysDistant: value,
        guaranteedHoursFrequency: 'weekly',
      });
    }

    if (rowKey === 'payGold' && isStudio) {
      upstreamOnChange({
        [objKeyGold]: isScale ? value : rate,
      });
    }
    if (rowKey === 'payGold' && isDistant) {
      upstreamOnChange({
        [objKeyGold]: isScale ? value : rateDistant,
      });
    }
    if (rowKey === 'payIdle' && isDistant) {
      upstreamOnChange({
        payIdleDaysDistant: isScale ? value : rateDistant,
      });
    }
  };

  const payHourlyKeys = {
    rowTitle: 'Rate Per Hour',
    rowKey: 'payHourly',
    rowKeyStudio: 'rate',
    rowKeyDistant: 'rateDistant',
    rateStudio: rate,
    rateDistant,
    payrollScaleValStudio: scaleRates?.rate,
    payrollScaleValDistant: scaleRates?.rateDistant,
    isRadioRowDisabled: true,
  };

  const payWeeklyKeys = {
    rowTitle: 'Rate Per Week',
    rowKey: 'payWeekly',
    rowKeyStudio: 'weeklyRate',
    rowKeyDistant: 'weeklyRateDistant',
    rateStudio: weeklyRate,
    rateDistant: weeklyRateDistant,
    scaleKeyStudio: 'payAtScale',
    scaleKeyDistant: 'payAtScaleDistant',
    payrollScaleValStudio: scaleRates?.weeklyRate,
    payrollScaleValDistant: scaleRates?.weeklyRateDistant,
    isRadioRowDisabled: true,
  };

  const payIdleKeys = {
    rowTitle: 'Pay Idle Days at',
    rowKey: 'payIdle',
    rowKeyDistant: 'payIdleDaysDistant',
    rateDistant: payIdleDaysDistant || rateDistant,
    isStudioDisabled: true,
    scaleKeyDistant: 'idleAtScaleDistant',
    scaleDistant: idleAtScaleDistant,
    payrollScaleValStudio: scaleRates?.rate,
    payrollScaleValDistant: scaleRates?.rateDistant,
  };

  const payGoldKeys = {
    rowTitle: 'Pay Gold at Base Rate of',
    rowKey: 'payGold',
    rowKeyStudio: 'payGoldAt',
    rowKeyDistant: 'payGoldAtDistant',
    rateStudio: payGoldAt || rate,
    rateDistant: payGoldAtDistant || rateDistant,
    scaleKeyStudio: 'goldAtScale',
    scaleKeyDistant: 'goldAtScaleDistant',
    scaleStudio: goldAtScale,
    scaleDistant: goldAtScaleDistant,
    payrollScaleValStudio: scaleRates?.rate,
    payrollScaleValDistant: scaleRates?.rateDistant,
  };

  const CustomRadio = (
    <Radio
      classes={{
        root: classes.radio,
        checked: classes.checked,
      }}
    />
  );

  const payrollScaleValStudio = parseFloat(scaleRates?.rate);
  const payrollScaleValDistant = parseFloat(scaleRates?.rateDistant);
  const payrollWeeklyScaleValStudio = parseFloat(scaleRates?.weeklyRate);
  const payrollWeeklyScaleValDistant = parseFloat(
    scaleRates?.weeklyRateDistant,
  );
  const payrollGuaranteedHoursStudioScale = parseFloat(
    scaleRates?.guaranteedHours,
  );
  const payrollGuaranteedHoursDistantScale = parseFloat(
    scaleRates?.guaranteedHoursDistant,
  );

  const isWeeklyScaleStudio =
    payrollWeeklyScaleValStudio > 0 &&
    weeklyRate !== payrollWeeklyScaleValStudio;

  const isRateHourlyScaleStudio =
    payrollScaleValStudio > 0 && rate !== payrollScaleValStudio;
  const isGuaranteedHoursScaleStudio =
    scaleRates?.guaranteedHours > 0 &&
    guaranteedHours !== scaleRates?.guaranteedHours;

  const isWeeklyHourlyScaleStudio =
    isRateHourlyScaleStudio ||
    isWeeklyScaleStudio ||
    isGuaranteedHoursScaleStudio;

  const isScaleStudio = isWeeklyHourlyScaleStudio && payAtScale === undefined;

  const isRateHourlyScaleDistant =
    payrollScaleValDistant > 0 && rateDistant !== payrollScaleValDistant;
  const isWeeklyScaleDistant =
    payrollWeeklyScaleValDistant > 0 &&
    weeklyRateDistant !== payrollWeeklyScaleValDistant;
  const isGuaranteedHoursScaleDistant =
    scaleRates?.guaranteedHoursDistant > 0 &&
    guaranteedHoursDistant !== scaleRates?.guaranteedHoursDistant;

  const isWeeklyHourlyScaleDistant =
    isRateHourlyScaleDistant ||
    isWeeklyScaleDistant ||
    isGuaranteedHoursScaleDistant;

  const isScaleDistant =
    isWeeklyHourlyScaleDistant && payAtScaleDistant === undefined;
  const isScaleStudioBtn = isScaleStudio || payAtScale;
  const isScaleDistantBtn = isScaleDistant || payAtScaleDistant;

  const handleScaleChange = (ev, radioKey) => {
    const { target: { value: rawValue } = {} } = ev;
    const isStudioRadioKey = radioKey === 'studio';
    const isDistantRadioKey = radioKey === 'distant';

    const scaleTitle = isStudioRadioKey ? 'payAtScale' : 'payAtScaleDistant';
    const isScaleSelected = rawValue === 'yes';

    upstreamOnChange({
      [scaleTitle]: isScaleSelected,
    });

    if (isStudioRadioKey) {
      upstreamOnChange({
        goldAtScale: isScaleSelected,
        payAtScaleDistant: isScaleSelected,
        goldAtScaleDistant: isScaleSelected,
        idleAtScaleDistant: isScaleSelected,
      });
    }
    if (!isStudioRadioKey) {
      upstreamOnChange({
        goldAtScaleDistant: isScaleSelected,
        idleAtScaleDistant: isScaleSelected,
      });
    }

    if (isScaleSelected && isStudioRadioKey) {
      upstreamOnChange({
        rate: payrollScaleValStudio,
        weeklyRate: payrollWeeklyScaleValStudio,
        guaranteedHours: payrollGuaranteedHoursStudioScale,
        guaranteedHoursDistant: payrollGuaranteedHoursDistantScale,
        payGoldAt: payrollScaleValStudio,
        rateDistant: payrollScaleValDistant,
        weeklyRateDistant: payrollWeeklyScaleValDistant,
        payIdleDaysDistant: payrollScaleValDistant,
        payGoldAtDistant: payrollScaleValDistant,
      });
    }
    if (isScaleSelected && isDistantRadioKey) {
      upstreamOnChange({
        rateDistant: payrollScaleValDistant,
        weeklyRateDistant: payrollWeeklyScaleValDistant,
        guaranteedHoursDistant: payrollGuaranteedHoursDistantScale,
        payIdleDaysDistant: payrollScaleValDistant,
        payGoldAtDistant: payrollScaleValDistant,
      });
    }
    upstreamOnChange({
      isTermsOfEmploymentV2: true,
      tableErrors: {
        isRateBelowScale: false,
        isGuaranteedHoursBelowScale: false,
      },
    });
  };

  const isScaleRate = scaleRates?.rate || scaleRates?.rateDistant;

  return (
    <div className={classes.tableRoot}>
      <ThemeProvider theme={theme}>
        {isSignatoryAbsentMessage && (
          <Typography
            color="warning"
            variant="caption"
            className={classes.warningLabel}
            data-test-id="union-signatory-warning-message"
          >
            <WarningIcon className={classes.warningIcon} />
            {isSignatoryAbsentMessage}
          </Typography>
        )}
        <Table className={classes.tableSpacing}>
          <TableRow>
            <TableCell></TableCell>
            <TableCell className={classes.headerTitle}>
              Studio{' '}
              <div className={classes.radioContainer}>
                <TableCell className={classes.radioGroupCell}>
                  <RadioGroup
                    className={classes.radioBtns}
                    value={isScaleStudioBtn ? 'yes' : 'no'}
                    onChange={ev => handleScaleChange(ev, 'studio')}
                    row
                  >
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      value="yes"
                      control={CustomRadio}
                      label="Scale Rate"
                      disabled={isReadOnly || !payrollScaleValStudio}
                    />
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      value="no"
                      control={CustomRadio}
                      label="Negotiated Rate"
                      disabled={isReadOnly}
                    />
                  </RadioGroup>
                </TableCell>
              </div>
            </TableCell>
            <TableCell className={classes.headerTitle}>
              Distant{' '}
              <div className={classes.radioContainer}>
                <TableCell className={classes.radioGroupCell}>
                  <RadioGroup
                    className={classes.radioBtns}
                    value={isScaleDistantBtn ? 'yes' : 'no'}
                    onChange={ev => handleScaleChange(ev, 'distant')}
                    row
                  >
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      value="yes"
                      control={CustomRadio}
                      label="Scale Rate"
                      disabled={isReadOnly || !payrollScaleValDistant}
                    />
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      value="no"
                      control={CustomRadio}
                      label="Negotiated Rate"
                      disabled={isReadOnly}
                    />
                  </RadioGroup>
                </TableCell>
              </div>
            </TableCell>
          </TableRow>
          <WeeklyInputRadioRow
            {...props}
            {...payHourlyKeys}
            handleIdleGoldUpdatePayRate={onHandleIdleGoldUpdatePayRate}
            isCNCSignatory={isCNCSignatory}
          />
          <WeeklyInputField
            guaranteedHours={guaranteedHours}
            guaranteedHoursDistant={guaranteedHoursDistant}
            guaranteedHoursScaleStudio={scaleRates?.guaranteedHours}
            guaranteedHoursScaleDistant={scaleRates?.guaranteedHoursDistant}
            isCNCSignatory={isCNCSignatory}
            {...props}
          />
          <WeeklyInputRadioRow
            {...props}
            {...payWeeklyKeys}
            handleIdleGoldUpdatePayRate={onHandleIdleGoldUpdatePayRate}
            isCNCSignatory={isCNCSignatory}
          />
        </Table>
        <Table>
          <WeeklyInputRadioRow
            {...payIdleKeys}
            {...props}
            handleIdleGoldUpdatePayRate={onHandleIdleGoldUpdatePayRate}
            isCNCSignatory={isCNCSignatory}
          />
          <WeeklyInputRadioRow
            {...payGoldKeys}
            {...props}
            handleIdleGoldUpdatePayRate={onHandleIdleGoldUpdatePayRate}
            isCNCSignatory={isCNCSignatory}
          />
        </Table>
        {isScaleRate && (
          <div className={classes.alignCopy}>
            <Typography className={classes.scaleCopy}>
              Scale rate shown as of today's date
            </Typography>
            <Typography className={classes.scaleCopy}>
              Scale rate subject to change upon union negotiated increases.
            </Typography>
          </div>
        )}
      </ThemeProvider>
    </div>
  );
};
export default RateTable;
