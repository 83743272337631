import React from 'react';

import DocumentViewer from './DocumentViewer';
import Modal from '../Base/Modal';
import Preloader from 'common/oldJavascripts/components/Shared/Preloader';
import FailureAlert from './FailureAlert';
import { Button } from '@mui/material';

class DocumentModal extends React.Component {
  render() {
    const { pages, isLoading = false, error = false, onClose } = this.props;

    return (
      <Modal size={pages?.length ? 'maximal' : null}>
        {isLoading && <Preloader />}
        {error && (
          <>
            <FailureAlert queryName={error} />
            <Button onClick={onClose}>Close</Button>
          </>
        )}
        {pages && !isLoading && !error && (
          <DocumentViewer
            key="viewer"
            onClose={this.props.onClose}
            pages={pages}
          />
        )}
      </Modal>
    );
  }
}

export default DocumentModal;
