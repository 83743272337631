import React, { Component, cloneElement } from 'react';
import { Button, Dialog, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    margin: 'auto',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    marginTop: theme.spacing(1),
    position: 'relative',
    float: 'right',
  },
});

class DialogButton extends Component {
  state = {
    isDialogOpen: false,
  };

  openDialog = () => this.setState({ isDialogOpen: true });

  confirm = e => {
    const { onClick } = this.props;
    onClick();
    this.closeDialog(e);
  };

  closeDialog = e => {
    e.stopPropagation();
    this.setState({ isDialogOpen: false });
  };

  onClick = () => {
    const { dialogType, onClick } = this.props;
    if (dialogType === 'alert') {
      Promise.resolve(onClick()).then(this.openDialog);
      return;
    }
    if (dialogType === 'confirmation') {
      this.openDialog();
      return;
    }
    onClick();
  };

  render() {
    const {
      buttonClasses,
      children,
      classes,
      dialogBody,
      dialogCancelButton = 'Cancel',
      dialogConfirmButton = 'OK',
      dialogTitle,
      dialogType,
      dialogWidth = 500,
    } = this.props;

    const { isDialogOpen } = this.state;

    const innerProps = { ...this.props };
    delete innerProps.dialogBody;
    delete innerProps.dialogCancelButton;
    delete innerProps.dialogConfirmButton;
    delete innerProps.dialogTitle;
    delete innerProps.dialogType;
    delete innerProps.dialogWidth;
    delete innerProps.buttonClasses;
    innerProps.classes = buttonClasses;

    const title =
      dialogTitle || (dialogType === 'alert' ? 'Attention' : 'Please Confirm');
    const body =
      typeof dialogBody === 'string' ? (
        <Typography>{dialogBody}</Typography>
      ) : (
        dialogBody
      );

    const confirmAction =
      dialogType === 'alert' ? this.closeDialog : this.confirm;
    const confirmButton =
      typeof dialogConfirmButton === 'string' ? (
        <Button
          color="primary"
          variant="contained"
          onClick={confirmAction}
          className={classes.confirmButton}
          data-test-id="DialogButton-confirm"
        >
          {dialogConfirmButton}
        </Button>
      ) : (
        cloneElement(dialogConfirmButton, { onClick: confirmAction })
      );

    const cancelButton =
      typeof dialogCancelButton === 'string' ? (
        <Button
          onClick={this.closeDialog}
          color="secondary"
          variant="contained"
        >
          {dialogCancelButton}
        </Button>
      ) : (
        cloneElement(dialogCancelButton, { onClick: this.closeDialog })
      );

    return (
      <React.Fragment>
        <Button {...innerProps} onClick={this.onClick}>
          {children}
        </Button>
        <Dialog
          open={isDialogOpen}
          onClose={this.closeDialog}
          data-test-id="DialogButton-dialog"
        >
          <div className={classes.paper} style={{ width: dialogWidth }}>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            {body}
            <div className={classes.buttonContainer}>
              {confirmButton}
              {dialogType === 'confirmation' && cancelButton}
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DialogButton);
