import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query auditLogs($projectId: Int!) {
    auditLogs(projectId: $projectId) {
      activityData
      activityType
      createdAt
      id
      projectId
      userName
    }
  }
`;

const useAuditLogs = projectId => {
  const { error, loading, data } = useQuery(QUERY, {
    skip: !projectId,
    variables: { projectId: parseInt(projectId) },
    fetchPolicy: 'no-cache',
  });
  return { error, loading, data };
};

export default useAuditLogs;
