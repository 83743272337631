import { Link } from 'react-router-dom';

import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';

const styles = {
  root: {
    fontSize: '1em',
    borderRadius: '3px',
    lineHeight: '1.53846154',
    transition: 'background 0.3s ease-in-out 0s',
    background: '#0000FF',
    border: 'none',
    color: '#ffffff',
    cursor: 'pointer',
    display: 'inline-block',
    fontWeight: '700',
    minWidth: '120px',
    outline: 'none',
    padding: '8px 16px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    touchAction: 'manipulation',
    verticalAlign: 'middle',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: '#1A55FD',
    },
    '&:focus': {
      backgroundColor: '#0000B3',
    },
    '&:active': {
      boxShadow: '2px 2px 5px #000080',
    },
    '& .active': {
      boxShadow: '2px 2px 5px #000080',
    },
  },
  secondary: {
    color: '#a3a6aa',
    background: '#ffffff',
    border: '2px solid #a3a6aa',
    '&:hover': {
      border: '2px solid #b5b8bd',
      background: '#ffffff',
    },
    '&:focus': {
      border: '2px solid #b5b8bd',
      background: '#ffffff',
    },
    '&:active': {
      background: 'rgba(255, 255, 255, 0.8)',
    },
    '& .active': {
      background: 'rgba(255, 255, 255, 0.8)',
    },
  },
  tertiary: {
    fontSize: '12px',
    minWidth: '80px',
    padding: '4px 8px',
  },
  action: {
    boxShadow: 'none',
    borderRadius: '5px',
    background: '#0000FF',
    color: '#ffffff',
    '&:hover': {
      background: '#1A55FD',
    },
    '&:focus': {
      background: '#0000B3',
    },
    '&:active': {
      boxShadow: '2px 2px 5px #4b4b4b',
    },
    '& .active': {
      boxShadow: '2px 2px 5px #4b4b4b',
    },
  },
  danger: {
    boxShadow: 'none',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#ea5a5a',
    color: '#ffffff',
  },
  important: {
    padding: '10px 12px',
    margin: '10px 0',
    width: '100%',
  },
  disabled: {
    opacity: '0.25',
    cursor: 'default',
  },
  inline: {
    fontSize: '0.92307692em',
    lineHeight: '1.66666667',
    minWidth: 'initial',
    padding: '4px 10px',
  },
  marginLeft: {
    marginLeft: '25px',
  },
  left: {
    cssFloat: 'left',
  },
  preview: {
    borderRadius: '5px',
    backgroundColor: 'rgba(90, 175, 200, 0.2)',
    color: '#0000FF',
    display: 'flex',
    fontWeight: '500',
    overflow: 'hidden',
    padding: '0',
    '&:hover': {
      backgroundColor: '#1A55FD',
    },
    '& .icon': {
      alignItems: 'center',
      backgroundColor: '#1A55FD',
      display: 'flex',
      height: '40px',
      justifyContent: 'center',
      width: '50px',
    },
    '& .label': {
      alignItems: 'center',
      display: 'flex',
      height: '40px',
      padding: '10px',
    },
  },
  right: {
    cssFloat: 'right',
  },
  search: {
    height: '36px',
    marginTop: '27px',
    marginLeft: '20px',
  },
  wrap: {
    whiteSpace: 'normal',
  },
};

class Button extends React.Component {
  constructor(props) {
    super(props);
    this._onClick = this._onClick.bind(this);
  }

  render() {
    const {
      to,
      params,
      query,
      children,
      onMouseOver,
      onMouseOut,
      disabled,
      ...otherProps
    } = this.props;
    delete otherProps.onClick;
    delete otherProps.classes;
    // Delete props that are tied to classes
    delete otherProps.action;
    delete otherProps.accept;
    delete otherProps.danger;
    delete otherProps.disabled;
    delete otherProps.important;
    delete otherProps.inline;
    delete otherProps.alignment;
    delete otherProps.marginLeft;
    delete otherProps.preview;
    delete otherProps.searchButton;
    delete otherProps.cancel;
    delete otherProps.secondary;
    delete otherProps.tertiary;
    delete otherProps.wrap;
    delete otherProps.className;

    if (to) {
      return (
        <Link
          to={to}
          className={this._getClasses()}
          params={params}
          query={query}
          {...otherProps}
        >
          {children}
        </Link>
      );
    }
    return (
      <button
        className={this._getClasses()}
        onClick={this._onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        disabled={disabled}
        {...otherProps}
      >
        {children}
      </button>
    );
  }

  _getClasses() {
    const {
      classes,
      accept,
      action,
      cancel,
      danger,
      disabled,
      important,
      inline,
      alignment,
      marginLeft,
      preview,
      searchButton,
      secondary,
      tertiary,
      wrap,
      className,
    } = this.props;
    return classNames(
      classes.root,
      {
        [classes.action]: action || accept,
        [classes.danger]: danger,
        [classes.disabled]: disabled,
        [classes.important]: important,
        [classes.inline]: inline,
        [classes.left]: alignment === 'left',
        [classes.marginLeft]: marginLeft,
        [classes.preview]: preview,
        [classes.right]: alignment === 'right',
        [classes.search]: searchButton,
        [classes.secondary]: cancel || secondary,
        [classes.tertiary]: tertiary,
        [classes.wrap]: wrap,
      },
      className,
    );
  }

  _onClick(event) {
    if (this.props.disabled || this.props.onClick) {
      event.preventDefault();
      event.stopPropagation();

      if (!this.props.disabled && this.props.onClick) {
        this.props.onClick(event);
      }
    }
  }
}

export default withStyles(styles)(Button);
