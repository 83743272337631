import Addresses from 'common/oldJavascripts/components/Pages/UserProfile/Addresses/Index';
import Box from 'common/oldJavascripts/components/Base/Box';
import Emergency from 'common/oldJavascripts/components/Pages/UserProfile/Emergency';
import ErrorAlert from 'common/oldJavascripts/components/Shared/ErrorAlert';
import Form from 'common/oldJavascripts/components/Base/Form';
import General from 'common/oldJavascripts/components/Pages/UserProfile/General';
import Header from 'common/oldJavascripts/components/Base/Header';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import NavBar from 'common/oldJavascripts/components/Base/NavBar';
import Loader from 'common/components/Loader';
import React from 'react';
import ReactDOM from 'react-dom';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import Separator from 'common/oldJavascripts/components/Base/Separator';
import ValidationAlert from 'common/oldJavascripts/components/Base/ValidationAlert';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import VerificationModal from './VerificationModal';
import collectErrors from 'common/oldJavascripts/utils/react/collectErrors.js';
import NextButton from './NextButton';
import isBlank from 'common/oldJavascripts/utils/isBlank';
import history from 'common/constants/config/history';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

// const isW2 = offerData => offerData.require_loan_out === false;

const REQUIRED_FIELDS = [
  // List of functions to validate that they have filled out every required field
  // This will help our errors coming back from the backend
  // The validation is the check on whether they have worked on the field
  // The label shows up in the tool tip when we block the next button
  // Fields everyone fills out
  ({ data: { first_name: firstName = '' } = {} }) => ({
    validation: Boolean(firstName),
    label: 'First Name',
  }),
  ({ data: { last_name: lastName = '' } = {} }) => ({
    validation: Boolean(lastName),
    label: 'Last Name',
  }),
  ({ data: { email = {} } = '' }) => ({
    validation: Boolean(email),
    label: 'Email',
  }),
  ({ data: { birth_date: birthDate = '' } = {} }) => ({
    validation: Boolean(birthDate),
    label: 'Date Of Birth',
  }),
  ({ data: { ethnic_code: ethnicCode } = {} }) => ({
    validation: !!ethnicCode,
    label: 'Ethnic Code',
  }),
  ({ data: { gender } = {} }) => ({
    validation: !!gender,
    label: 'Gender',
  }),
  ({ data: { phone = {} } = '' }) => ({
    validation: Boolean(phone),
    label: 'Phone number',
  }),
  ({ data: { phone_type: phoneType = '' } = {} }) => ({
    validation: Boolean(phoneType),
    label: 'Phone Type',
  }),
  ({ data: { primary_address: primaryAddress = {} } = {} }) => ({
    // Check if there is more than 1 key in the primary address
    // We could always have the use_primary key even though this is the primary
    validation: Object.keys(primaryAddress).length > 1,
    label: 'Primary Address',
  }),
  ({ data: { w2_address: w2Address = {} } = {} }) => {
    const validation = () => {
      const { use_primary: usePrimary } = w2Address;
      // Check if there is more than 1 key in the  address
      // We should always have the use_primary key
      if (!usePrimary && Object.keys(w2Address).length === 1) return false;
      return true;
    };
    return {
      validation: validation(),
      label: 'W2 Address',
    };
  },
  ({ data: { ssn_mask: ssnMask = {} } = '' }) => ({
    validation: !!ssnMask && ssnMask.length >= 11,
    label: 'Social Security Number',
  }),
  (
    { data: { emergency_name: emergencyName = {} } = '' },
    { data: { require_emergency_contact: requireEmergencyContact = false } },
  ) => ({
    validation: !requireEmergencyContact || Boolean(emergencyName),
    label: 'Emergency Contact Name',
  }),
  (
    { data: { emergency_phone: emergencyPhone = {} } = '' },
    { data: { require_emergency_contact: requireEmergencyContact = false } },
  ) => ({
    validation: !requireEmergencyContact || Boolean(emergencyPhone),
    label: 'Emergency Contact Phone number',
  }),
  (
    { data: { emergency_phone_type: emergencyPhoneType = {} } = '' },
    { data: { require_emergency_contact: requireEmergencyContact = false } },
  ) => ({
    validation: !requireEmergencyContact || Boolean(emergencyPhoneType),
    label: 'Emergency Contact Phone Type',
  }),
  (
    { data: { emergency_relationship: emergencyRelationship = {} } = '' },
    { data: { require_emergency_contact: requireEmergencyContact = false } },
  ) => ({
    validation: !requireEmergencyContact || Boolean(emergencyRelationship),
    label: 'Emergency Contact Relationship',
  }),
  // Loan-out
  // ({ data: profileData }, { data: offerData }, { data: offerLoanOut }) => ({
  //   validation: isW2(offerData) ? true : Boolean(offerLoanOut['federal_tax_id']),
  //   label: 'Federal Tax Id',
  // }),
  // ({ data: profileData }, { data: offerData }, { data: offerLoanOut }) => ({
  //   validation: isW2(offerData) ? true : Boolean(offerLoanOut['corporation_name']),
  //   label: 'Corporation Name',
  // }),
  // ({ data: profileData }, { data: offerData }, { data: offerLoanOut }) => ({
  //   validation: isW2(offerData) ? true : Boolean(offerLoanOut['corporation_state']),
  //   label: 'Corporation State',
  // }),
  // ({ data: profileData }, { data: offerData }, { data: offerLoanOut }) => ({
  //   validation: isW2(offerData) ? true : Boolean(offerLoanOut['corporation_type']),
  //   label: 'Corporation Type',
  // }),
  // ({ data: profileData }, { data: offerData }, { data: offerLoanOut }) => ({
  //   validation: isW2(offerData) ? true : Boolean(offerLoanOut['corporation_address_line_1']),
  //   label: 'Corporation Address',
  // }),
  // ({ data: profileData }, { data: offerData }, { data: offerLoanOut }) => ({
  //   validation: isW2(offerData) ? true : Boolean(offerLoanOut['corporation_city']),
  //   label: 'Corporation City',
  // }),
  // ({ data: profileData }, { data: offerData }, { data: offerLoanOut }) => ({
  //   validation: isW2(offerData) ? true : Boolean(offerLoanOut['corporation_zip_code']),
  //   label: 'Corporation Zip Code',
  // }),
  // W2
  // ({ data: profileData }, { data: offerData }) => ({
  //   validation: !isW2(offerData) ? true : Boolean(profileData['w4_exempt']),
  //   label: 'W4 - Exempt Status',
  // }),
  // ({ data: profileData }, { data: offerData }) => ({
  //   validation: !isW2(offerData) ? true : Boolean(profileData['w4_marital_status']),
  //   label: 'W4 - Marital Status',
  // }),
  // ({ data: profileData }, { data: offerData }) => {
  //   // Conditional text field
  //   const label = 'W4 - Exemption verification text box';
  //   if (!isW2) return { validation: true, label };
  //   if (profileData['w4_exempt'] === 'non_exempt') return { validation: true, label };
  //   return {
  //     validation:
  //       String(profileData['w4_exempt_text'])
  //         .toLowerCase()
  //         .trim() === 'exempt',
  //     label,
  //   };
  // },
  // ({ data: profileData }, { data: offerData }) => {
  //   const label = 'W4 - Additional amount withheld';
  //   if (!isW2) return { validation: true, label };
  //   if (profileData['w4_exempt'] === 'exempt') return { validation: true, label };
  //   return { validation: Boolean(String(profileData['w4_additional_amount']).trim()), label };
  // },
  // ({ data: profileData }, { data: offerData }) => {
  //   // Conditional text field
  //   const label = 'W4 - Allowances Claimed';
  //   if (!isW2) return { validation: true, label };
  //   if (profileData['w4_exempt'] === 'exempt') return { validation: true, label };
  //   return { validation: Boolean(String(profileData['w4_allowances']).trim()), label };
  // },
];

const LOANOUT_REQUIRED_FIELDS = [
  'federal_tax_id',
  'corporation_name',
  'corporation_state',
  'corporation_type',
  'corporation_address_line_1',
  'corporation_city',
  'corporation_zip_code',
];

const VALIDATION_FIELD_NAME_OVERRIDES = {
  share: 'Percentage',
  type: 'Bank Account Type',
  account_number_mask: 'Account Number',
  routing_number_mask: 'Routing Number',
  ssn_mask: 'SSN',
  'onboarding_supporting-documents':
    'Oops! It looks like you may have arrived here from an outdated link. Please Select "My Offers" at the top of the screen, and select this offer again to get back to the right screen.',
  onboarding_documents:
    'Oops! It looks like you may have arrived here from an outdated link. Please Select "My Offers" at the top of the screen, and select this offer again to get back to the right screen.',
  onboarding_i9:
    'Oops! It looks like you may have arrived here from an outdated link. Please Select "My Offers" at the top of the screen, and select this offer again to get back to the right screen.',
  submitted: `Oops! It looks like you may have arrived here from an outdated link. Please Select "My Offers" at the top of the screen, navigate to the "Submitted for Approval" section and select this offer to view its details.`,
  complete_on_paper: `Oops! It looks like you have decided to opt out of digital onboarding for this offer to complete this offer on paper. Please select "My Offers", navigate to the "Complete on Paper" section and select this offer to view its details.`,
};

const promiseMutatorSave = (mutator, action) =>
  new Promise((resolve, reject) => {
    mutator.save(action, {}, resolve, reject);
  });

/* eslint react/prop-types: "off" */

export default Relay.createClass({
  statics: {
    queries: {
      states: {
        info: () => ({
          id: '/states',
        }),
      },
    },

    mutators: {
      offer: {
        info(_, related) {
          const params = related['/router/params'];
          return {
            id: `/v2/offers/${params.offerId}`,
          };
        },
      },
      offerLoanOut: {
        info(_, related) {
          const params = related['/router/params'];
          return {
            id: `/v2/offers/${params.offerId}/loan-out-corporation`,
          };
        },
      },
      profile: {
        info(_, related) {
          const params = related['/router/params'];
          return {
            id: `/v2/offers/${params.offerId}/profile`,
          };
        },
        stale: () => ['/account/profile', '/me'],
      },
    },
  },

  componentDidUpdate(prevProps, prevState) {
    // Check if the secondary addresses are missing
    // If they are then make them the default secondary address object
    const { profile = {} } = this.props;
    const { data = {} } = profile || {};
    if (!profile.update) return; // Dont continue if we havent loaded profile
    const { mailing_address = {}, w2_address = {} } = data || {};
    const addresses = {
      mailing_address: mailing_address,
      w2_address: w2_address,
    };
    Object.keys(addresses).forEach(addressType => {
      const { [addressType]: address = false } = addresses;
      if (!address || Object.keys(address).length === 0)
        return profile.update(addressType)({ use_primary: true });
    });
  },

  render() {
    const { showLoader } = this.state;
    const status = this._getStatus();
    if (status === 'loading' || showLoader) {
      return <Loader />;
    }
    if (status === 'failed') {
      return <FailureAlert />;
    }
    return this._renderSuccess();
  },

  _canSave() {
    const { profile = {}, offer = {}, offerLoanOut = {} } = this.props;
    return REQUIRED_FIELDS.reduce((accumulator, test) => {
      const { validation, label } = test(profile, offer, offerLoanOut);
      if (!validation) accumulator.push(label);
      return accumulator;
    }, []);
  },

  _getDisclaimerText() {
    if (this.props.profile.data.is_locked) {
      return (
        'If you need to change your ' +
        'Social Security Number or Date of Birth, please contact: ' +
        'plus.support@castandcrew.com or call 818.860.7770 for additional support. ' +
        'You can contact our employee help desk at any time if you need to ' +
        'update your profile: (818-860-7756) or employeehelpdesk@castandcrew.com'
      );
    }
    return (
      'Disclaimer: You will not be able to update your ' +
      'social security number, or date of birth after clicking "Next".'
    );
  },

  _getValidationErrors() {
    return collectErrors(this._getMutators().map(obj => obj.mutator));
  },

  _getMutators() {
    const props = this.props;
    // const customFieldsMutator = props.customFields;
    const profileMutator = props.profile;
    const offerMutator = props.offer;

    const mutators = [
      {
        action: 'status',
        mutator: offerMutator,
      },
    ];

    // if (offer.require_loan_out) {
    //   mutators.push({
    //     action: 'edit',
    //     mutator: offerLoanOutMutator,
    //   });
    // }

    // if (offer.max_allowed_bank_accounts > 0) {
    //   mutators.push({
    //     action: null,
    //     mutator: bankAccountsMutator,
    //   });
    // }

    // if (this._hasCustomFields()) {
    //   mutators.push({
    //     action: 'edit',
    //     mutator: customFieldsMutator,
    //   });
    // }

    mutators.push({
      action: 'edit',
      mutator: profileMutator,
    });

    return mutators;
  },

  getInitialState() {
    return {
      showVerificationModal: false,
      isServerError: false,
      showLoader: false,
    };
  },

  updateAddress(address, type) {
    const { profile: profileMutator = {} } = this.props;
    return profileMutator.update(type)(address);
  },

  _getStatus() {
    const profileMutator = this.props.profile;
    const status = profileMutator.status;
    // Display anyway if the fail was on edit.
    if (status === 'failed' && profileMutator.data) {
      return 'success';
    }
    return status;
  },

  _isEmergencyRequired() {
    return this.props.offer.data.require_emergency_contact;
  },

  _buildTooltipTitle() {
    const requiredFieldsMessages = [...this._canSave()];
    if (requiredFieldsMessages.length === 0) return [];

    const messagePrefix = (
      <li key="top">Please fill out the missing required fields:</li>
    );
    const formattedLabels = requiredFieldsMessages.map(label => (
      <li key={label}>{label}</li>
    ));
    return [messagePrefix, ...formattedLabels];
  },

  _generateFormErrorMessages() {
    const formData = this.props.offerLoanOut.data;
    const emptyFields = LOANOUT_REQUIRED_FIELDS.filter(field =>
      isBlank(formData[field]),
    );
    const errors = emptyFields.reduce((errorCollector, field) => {
      errorCollector[field] = ['blank'];
      return errorCollector;
    }, {});
    return errors;
  },

  _scrollToTop() {
    const top = ReactDOM.findDOMNode(this.top);
    this.forceUpdate(() => top.scrollIntoView());
  },

  _submitForm() {
    const {
      routerParams: params = {},
      profile: profileMutator = {},
      offer: offerMutator = {},
      invalidateByPatterns,
    } = this.props;

    this.setState({
      showVerificationModal: false,
      isServerError: false,
      showLoader: true,
    });
    promiseMutatorSave(profileMutator, 'edit')
      .then(() => offerMutator.update('status')('onboarding_details'))
      .then(() => promiseMutatorSave(offerMutator, 'status'))
      .then(() => {
        invalidateByPatterns([
          `/v2/offers/${params.offerId}/documents-details`,
        ]);
        history.push(`/onboarding/offers/${params.offerId}/details`);
      })
      .catch(({ status_code }) => {
        if (status_code && status_code !== 422) {
          this.setState({ isServerError: true, showLoader: false });
        } else {
          this.setState({ showLoader: false });
        }
      });
  },

  _renderSuccess() {
    const {
      states: statesQuery = {},
      profile: profileMutator = {},
    } = this.props;
    const { isServerError } = this.state;
    const { data: statesData = {} } = statesQuery;
    const { items: states = [] } = statesData;
    const submitAction = profileMutator.data.is_locked
      ? this._submitForm
      : this._showVerificationModal();
    const tooltipTitle = this._buildTooltipTitle();
    return (
      <LayoutContent ref={ref => (this.top = ref)}>
        {this._renderVerificationModal()}
        <Box>
          <BoxItem>
            <Header>
              <Header.Title>Profile Information</Header.Title>
            </Header>
          </BoxItem>

          <Separator />

          <Form action="" method="post" onSubmit={this._submitForm}>
            {!isServerError && this._renderValidationAlert()}
            {isServerError && (
              <ErrorAlert>
                There was an unknown error updating your profile.
              </ErrorAlert>
            )}

            <BoxItem>
              <p>{this._getDisclaimerText()}</p>
              <p>
                Note: Changes you make to your profile will only be seen on new
                offers.
              </p>
              <br />
              <p>Items marked with an asterisk are required fields.</p>

              <General profileMutator={profileMutator} />

              <Separator />

              <Addresses
                onChange={this.updateAddress}
                profileMutator={profileMutator}
                states={states}
              />

              <Separator />

              <Emergency
                profileMutator={profileMutator}
                required={this._isEmergencyRequired()}
              />
            </BoxItem>

            <Separator />

            <BoxItem>
              <NavBar important={true}>
                <NavBar.Item>
                  <span data-test-id="Profile-next">
                    <NextButton
                      disabled={this._canSave().length > 0}
                      onClick={submitAction}
                      title={tooltipTitle}
                    />
                  </span>
                </NavBar.Item>
              </NavBar>
            </BoxItem>
          </Form>
        </Box>
      </LayoutContent>
    );
  },

  _renderValidationAlert() {
    const errors = this._getValidationErrors();

    if (errors) {
      const { start_date = null } = errors;
      return (
        <BoxItem>
          {start_date ? (
            <ErrorAlert contactCustomerService={false}>
              This offer has expired. Please contact the hiring manager if you
              have any questions.
            </ErrorAlert>
          ) : (
            <ValidationAlert
              errors={errors}
              overrides={VALIDATION_FIELD_NAME_OVERRIDES}
            />
          )}
        </BoxItem>
      );
    }
  },

  _renderVerificationModal() {
    if (this.state.showVerificationModal) {
      return (
        <VerificationModal
          data={this.props.profile.data}
          onConfirm={this._submitForm}
          onReject={this._hideVerificationModal()}
        />
      );
    }
  },

  _hideVerificationModal() {
    return () => this.setState({ showVerificationModal: false });
  },

  _showVerificationModal() {
    return () => this.setState({ showVerificationModal: true });
  },
});
