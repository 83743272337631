import React from 'react';

// MATERIAL
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMoreButton: {
    padding: '11px 16px !important',
    margin: '0 0 20px 0',
    background: '#FFFFFF',
    border: '1px solid #0000FF',
    color: '#0000FF',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
  },
  loadMoreMobileButton: {
    padding: '11px 16px !important',
    margin: '20px 0',
    background: '#FFFFFF',
    border: '1px solid #0000FF',
    color: '#0000FF',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  loadingIcon: {
    width: '20px',
    height: '20px',
    marginLeft: '10px',
    color: '#0000FF',
  },
}));

const FetchMoreOffers = props => {
  const {
    loadMoreOffers,
    searchQuery,
    offersPageInfo,
    isMobile = false,
    networkStatus,
  } = props;

  const StyledButton = styled(Button)({
    padding: '11px 16px',
    margin: isMobile ? '20px 0' : '0 0 20px 0',
    background: '#FFFFFF',
    border: '1px solid #0000FF',
    color: '#0000FF',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  });

  const classes = useStyles();

  const loadOffers = () => {
    const { endCursor } = offersPageInfo;

    loadMoreOffers({
      variables: {
        keyword: searchQuery?.length > 0 ? searchQuery : null,
        after: endCursor,
      },
      updateQuery: (prevResults, { fetchMoreResult }) => {
        const { offers: fetchMoreOffers = {} } = fetchMoreResult || {};
        const { nodes: fetchMoreNodes = [], pageInfo: fetchMorePageInfo = {} } =
          fetchMoreOffers || {};

        if (!fetchMoreNodes.length) return prevResults;

        return {
          offers: {
            __typename: prevResults.offers.__typename,
            nodes: [
              ...prevResults.offers.nodes,
              ...fetchMoreNodes.filter(
                fetchMoreOffersId =>
                  !prevResults.offers.nodes.some(
                    prevResultsOffersId =>
                      prevResultsOffersId === fetchMoreOffersId,
                  ),
              ),
            ],
            pageInfo: { ...fetchMorePageInfo },
          },
        };
      },
    });
  };

  return (
    <div className={classes.buttonContainer}>
      <StyledButton
        className={
          isMobile ? classes.loadMoreMobileButton : classes.loadMoreButton
        }
        variant="outlined"
        data-test-id={`Projects-load-more-button`}
        onClick={loadOffers}
      >
        Load More{' '}
        {networkStatus === 3 && (
          <CircularProgress size="sm" className={classes.loadingIcon} />
        )}
      </StyledButton>
    </div>
  );
};

export default FetchMoreOffers;
