import {
  REQUEST,
  SUCCESS,
  FAILURE,
  PUSH,
  PULL,
  GET,
  POST,
  PUT,
  DELETE,
  INVALIDATE,
  REPLACE,
  UPDATE_PROGRESS,
} from 'common/store/actions/actionTypes';

import {
  RESOURCE,
  SUB_RESOURCE,
  RESOURCE_ITEM,
} from 'common/store/actions/resources';

export const initialState = {
  empty: { status: 'success', data: {} },
};

export default (state = initialState, action) => {
  const { type, payload = {} } = action;
  const { data, url } = payload || {};
  const setResource = fnOrObj => {
    const newResource =
      typeof fnOrObj === 'function' ? fnOrObj(state[url]) : fnOrObj;
    return { ...state, [url]: newResource };
  };

  const setSubResource = newResource => {
    const { id: inComingSubResourceId } = payload || {};
    const { [url]: { data: parentData = {} } = {} } = state;
    const { items: parentDataItems = [] } = parentData || {};

    // Replace existing resource in collection with new resource
    const newItems = parentDataItems.map(item =>
      item._id === inComingSubResourceId ? data : item,
    );

    const parentResource = () => ({
      ...state[url],
      data: {
        ...parentData,
        items: newItems,
      },
    });
    return {
      ...state,
      [inComingSubResourceId]: newResource,
      [url]: parentResource(),
    };
  };

  switch (type) {
    case REQUEST(GET(RESOURCE)):
    case REQUEST(PUT(RESOURCE)):
    case REQUEST(POST(RESOURCE)):
    case REQUEST(DELETE(RESOURCE)):
      return setResource(resource => ({ ...resource, status: 'loading' }));

    case SUCCESS(GET(RESOURCE)):
      return setResource({ status: 'success', stale: false, data });
    case SUCCESS(GET(SUB_RESOURCE)):
      return setSubResource({ status: 'success', stale: false, data });

    case SUCCESS(PUT(RESOURCE)):
    case SUCCESS(POST(RESOURCE)):
      return setResource(resource =>
        data ? { status: 'success', data } : { ...resource, status: 'success' },
      );

    case SUCCESS(DELETE(RESOURCE)):
      return setResource(undefined);

    case REPLACE(RESOURCE):
      return setResource(resource => ({
        ...resource,
        data: data,
        stale: false,
      }));

    case FAILURE(RESOURCE):
      return setResource(resource => ({
        ...resource,
        status: 'failed',
        errors: payload.errors,
      }));

    case UPDATE_PROGRESS(RESOURCE):
      return setResource(resource => ({
        ...resource,
        progress: Math.min(Math.max(payload.progress, resource.progress), 1),
      }));

    case PUSH(RESOURCE_ITEM):
      return setResource(resource => ({
        ...resource,
        data: {
          ...resource.data,
          items: resource.data.items.concat(payload.item),
        },
      }));

    case PULL(RESOURCE_ITEM):
      return setResource(resource => ({
        ...resource,
        data: {
          ...resource.data,
          items: resource.data.items.filter(
            ({ _id }) => _id !== payload.itemId,
          ),
        },
      }));

    case INVALIDATE(RESOURCE):
      return setResource(resource => ({ ...resource, stale: true }));

    default:
      return state;
  }
};
