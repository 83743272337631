import withApi from 'common/hoc/withApi';
import createReactClass from 'create-react-class';

export const createClass = component => {
  if (typeof component === 'object')
    return withApi(createReactClass(component));
  return withApi(component);
};

export default { createClass };
