import React, { useState } from 'react';

// MUI
import { makeStyles } from '@mui/styles';
import {
  ExpandMore as DownArrowIcon,
  ExpandLess as UpArrowIcon,
  UnfoldMore as SortIcon,
} from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  header: {
    background: '#e6e7ea',
    border: 'none',
    color: '#434f59',
    fontWeight: '700',
    textTransform: 'uppercase',
    lineHeight: '1.23076923',
    borderBottom: '1px solid #e4e4e4',
    padding: '16px 20px',
    verticalAlign: 'middle',
    position: 'relative',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
  },
  sortIcon: {
    display: 'inline-flex',
    height: 16,
  },
}));

const ExpandableTableHeaderGraphQL = props => {
  const classes = useStyles();
  const {
    currentDirection = null,
    setSorting,
    fieldName,
    children,
    headerClasses = '',
  } = props;

  const [fieldNameCheck, setFieldNameCheck] = useState('');
  const setSortingDirection = currentDirection === 'asc' ? 'desc' : 'asc';

  const setSortingDirectionByField = (direction, name) => {
    setSorting(direction, name);
    setFieldNameCheck(name);
  };

  const renderSortIcon = () => {
    if (fieldName) {
      if (fieldNameCheck === fieldName && currentDirection === 'asc') {
        return <UpArrowIcon className={classes.sortIcon} />;
      }

      if (fieldNameCheck === fieldName && currentDirection === 'desc') {
        return <DownArrowIcon className={classes.sortIcon} />;
      }

      if (fieldNameCheck !== fieldName) {
        return <SortIcon className={classes.sortIcon} />;
      }
    }
  };

  return (
    <>
      {currentDirection ? (
        <td
          className={`${classes.header} ${headerClasses}`}
          onClick={() =>
            setSortingDirectionByField(setSortingDirection, fieldName)
          }
        >
          {children} {renderSortIcon()}
        </td>
      ) : (
        <td className={`${classes.header} ${headerClasses}`}>
          {children} {renderSortIcon()}
        </td>
      )}
    </>
  );
};

export default ExpandableTableHeaderGraphQL;
