import React from 'react';
import Badge from 'common/oldJavascripts/components/Base/Badge';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import Button from 'common/oldJavascripts/components/Base/Button';
import ContactLink from 'common/oldJavascripts/components/Base/ContactLink';
import format from 'common/oldJavascripts/utils/format.js';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import ExpandableTable from 'common/oldJavascripts/components/Base/ExpandableTable';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCellTitle from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCellTitle';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import OfferI9Status from 'studio/components/OfferI9Status';

const styles = theme => ({
  table: {
    width: '100%',
    tableLayout: 'fixed',
  },
  employeeNameHeader: {
    width: '15%',
  },
  phoneHeader: {
    width: '13%',
  },
  departmentHeader: {
    width: '12%',
  },
  jobTitleHeader: {
    width: '13%',
  },
  unionHeader: {
    width: '15%',
  },
  startDateHeader: {
    width: '10%',
  },
  iNineHeader: {
    width: '10%',
    textAlign: 'center',
  },
  actionHeader: {
    width: '10%',
  },
  contact: {
    display: 'grid',
    gridTemplateColumns: '26px auto',
    gridTemplateRows: '100%',
    placeItems: 'center',
  },
  noHorizontalPaddin: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  overflowText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

const getFullName = item =>
  format.fullName(item.first_name, item.middle_name, item.last_name);

class Content extends React.Component {
  render() {
    const { classes, items: offersToVerify = [], endDateAllowed } = this.props;
    if (!offersToVerify.length) {
      return <Blankslate>No I-9 documents found</Blankslate>;
    }

    return (
      <ExpandableTable
        className={classes.table}
        data-test-id={`Content-headerRow`}
      >
        <ExpandableTableRow key="header">
          <ExpandableTableCell
            header={true}
            sort="last_name,first_name,middle_name"
            className={classes.employeeNameHeader}
          >
            Employee
          </ExpandableTableCell>
          <ExpandableTableCell header={true} className={classes.phoneHeader}>
            Phone
          </ExpandableTableCell>
          <ExpandableTableCell
            header={true}
            sort="department"
            className={classes.departmentHeader}
          >
            Department
          </ExpandableTableCell>
          <ExpandableTableCell
            header
            sort="occupation"
            className={classes.jobTitleHeader}
          >
            Job Title/
            <br />
            Acct Code
          </ExpandableTableCell>
          <ExpandableTableCell
            header={true}
            sort="union"
            className={classes.unionHeader}
          >
            Union
          </ExpandableTableCell>
          <ExpandableTableCell
            header={true}
            sort="start_date"
            className={classes.startDateHeader}
          >
            Start Date
          </ExpandableTableCell>
          {endDateAllowed && (
            <ExpandableTableCell
              header={true}
              sort="end_date"
              className={classes.startDateHeader}
            >
              End Date
            </ExpandableTableCell>
          )}
          <ExpandableTableCell header={true} className={classes.iNineHeader}>
            I-9 Status
          </ExpandableTableCell>
          <ExpandableTableCell header={true} className={classes.actionHeader}>
            Actions
          </ExpandableTableCell>
        </ExpandableTableRow>
        {this._renderList()}
      </ExpandableTable>
    );
  }

  _renderEndDateCell(item) {
    if (this.props.endDateAllowed) {
      return (
        <ExpandableTableCell>{format.date(item.end_date)}</ExpandableTableCell>
      );
    }
  }

  _renderOldOfferStatusCell(item) {
    return <ExpandableTableCell>{this._renderBadge(item)}</ExpandableTableCell>;
  }

  _renderBadge(item) {
    const days = format.daysAgo(item.start_date);
    if (days > this.props.pastDueLimit) {
      return (
        <Badge error={true}>
          {days}
          days past due
        </Badge>
      );
    }
    return '-';
  }

  _renderList() {
    const { items: offersToVerify = [], classes } = this.props;

    return offersToVerify.map((item, index) => (
      <ExpandableTableRow
        key={`item-${item.id || `index-${getFullName(item)}`}`}
        data-test-id={`Content-row-${item.id}`}
      >
        <ExpandableTableCell>
          <ExpandableTableCellTitle>
            {getFullName(item)}
          </ExpandableTableCellTitle>
          <Tooltip disableInteractive title={item.email} enterDelay={100}>
            <div className={classes.overflowText}>{item.email}</div>
          </Tooltip>
        </ExpandableTableCell>
        <ExpandableTableCell compact={true}>
          <ContactLink phone={item.phone} className={classes.contact} />
        </ExpandableTableCell>
        <ExpandableTableCell>{item.department_name}</ExpandableTableCell>
        <ExpandableTableCell>
          {item.occupation_description}
          <br />
          {item.account_code}
        </ExpandableTableCell>
        <ExpandableTableCell>{item.union_description}</ExpandableTableCell>
        <ExpandableTableCell>
          {format.date(item.start_date)}
        </ExpandableTableCell>
        {this._renderEndDateCell(item)}
        <ExpandableTableCell>
          <OfferI9Status props={{ ...item }} />
        </ExpandableTableCell>
        <ExpandableTableCell className={classes.noHorizontalPaddin}>
          {this._renderVerifyButton(item)}
        </ExpandableTableCell>
      </ExpandableTableRow>
    ));
  }

  _renderVerifyButton(item) {
    const { openSigner } = this.props;
    const { ready_to_sign = true } = item;
    if (this.props.displayControls) {
      if (ready_to_sign) {
        return (
          <Button
            tertiary={true}
            onClick={() => openSigner(item.id)}
            data-test-id={`Content-verifyButton-${item.id}`}
          >
            Verify
          </Button>
        );
      }
      return (
        <Tooltip
          disableInteractive
          classes={{
            tooltip: 'offer-processing-tooltip',
          }}
          title="This I-9 is being prepared. It will be ready to verify shortly."
        >
          <div>
            <Button
              disabled
              tertiary={true}
              onClick={() => openSigner(item.id)}
              data-test-id={`Content-verifyProcessing-${item.id}`}
            >
              Processing...
            </Button>
          </div>
        </Tooltip>
      );
    }
  }
}

export default withStyles(styles)(Content);
