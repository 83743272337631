/* Variable Test Utility:
 * This is a test to ensure that the query data that is present
 * is from the current query with the most up to data variables
 *
 * The test iterates through the variable keys from the apollo query prop and then
 * ensures that they match the keys from the formData which should be provided from
 * the current state of Offer Container (eg CreateOffer)
 * You can pass in a subbedKey in the event that the grapqhl variable does not match
 * the state variable from form data
 *
 */
const variableTest = (
  graphqlQueryVariables = {},
  formData = {},
  subbedKeys = {},
) => {
  const variablesKeys = Object.keys(graphqlQueryVariables);
  return variablesKeys.every(key => {
    let dataComparison =
      String(graphqlQueryVariables[key]) === String(formData[key]);
    let termsOfHireComparison =
      String(graphqlQueryVariables[key]) === String(formData[key]);
    // GraphQL is expecting 'YYYY-MM-DD' formatted dates so we get and
    // format the formData date object to compare
    // The formData dates are formatted as 'MMM Do, YYYY'
    if (key.toLowerCase().includes('date')) {
      dataComparison =
        String(graphqlQueryVariables[key]) ===
        (!!formData[`${key}Object`] &&
          String(formData[`${key}Object`].format('YYYY-MM-DD')));
    }
    if (key === 'union' || key === 'occupation' || key === 'workSchedule') {
      termsOfHireComparison =
        String(graphqlQueryVariables[key]) ===
        String((formData[key] || {}).value);
    }
    return (
      dataComparison ||
      key === 'project' ||
      key === 'projectId' ||
      termsOfHireComparison ||
      String(graphqlQueryVariables[key]) === String(formData[subbedKeys[key]])
    );
  });
};

export default variableTest;
