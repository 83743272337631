import React from 'react';

// HoC
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { withStyles } from '@mui/styles';

// Components
import List from '@mui/material/List';
import Loader from 'common/components/Loader';
import CrewMember from 'studio/components/OfferPage/CrewMembers/CrewMember';

const styles = _theme => ({
  root: {
    overflowY: 'auto',
  },
});

const CrewList = props => {
  const {
    data: { data: { crew: { edges = [] } = {} } = {}, loading, fetchMore } = {},
    isSelected,
    search,
    onChange,
    classes = {},
  } = props;

  const onScroll = ({ currentTarget }) => {
    if (loading) return;
    const { scrollTop, scrollHeight, clientHeight } = currentTarget || {};
    const numberOfUserInList = edges.length;
    const sizeOfListItem = scrollHeight / numberOfUserInList;
    if (clientHeight + scrollTop > scrollHeight - sizeOfListItem * 10) {
      const { cursor: after } = edges[edges.length - 1] || {};

      fetchMore({
        variables: {
          after,
          search,
        },
        // Update query runs twice during fetchMore so we
        // check the existing crew list for possible
        // duplicates when updating the query cache
        updateQuery: (prev, { fetchMoreResult }) => {
          const { project: fetchMoreProject = {} } = fetchMoreResult || {};
          const { crew: { edges: fetchMoreEdges = [] } = {} } =
            fetchMoreProject || {};
          if (!fetchMoreEdges.length) return prev;
          return {
            ...prev,
            project: {
              ...prev.project,
              crew: {
                ...prev.project.crew,
                edges: [
                  ...prev.project.crew.edges,
                  ...fetchMoreEdges.filter(
                    ({ node: { id: crewId } }) =>
                      !prev.project.crew.edges.some(
                        ({ node: { id: prevId } }) => prevId === crewId,
                      ),
                  ),
                ],
              },
            },
          };
        },
      });
    }
  };

  return (
    <List
      className={classes.root}
      onScroll={onScroll}
      data-test-id="CrewList-root"
    >
      {loading && <Loader />}
      {!loading &&
        edges.map(({ node = {} } = {}) => (
          <CrewMember
            key={node.id}
            {...node.profile}
            onClick={() => onChange(node)}
            selected={isSelected(node.id)}
          />
        ))}
    </List>
  );
};

export default compose(withRouter, withStyles(styles))(CrewList);
