import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import ListItem from 'admin/components/RoleMapper/ListItem';
import HighlightTriggerSourceButton from 'admin/components/RoleMapper/HighlightTriggerSourceButton';

const styles = () => ({
  bold: {
    fontWeight: 'bold',
  },
  name: {
    width: '100%',
  },
  tooltip: {
    fontSize: '0.9rem !important',
  },
  highlightedIcon: {
    color: '#fae047',
  },
  highlightedButton: {
    backgroundColor: '#8b8b8b',
  },
  highlightButton: {
    padding: 0,
    marginRight: '8px',
    height: '20px',
    width: '20px',
  },
  icon: {
    height: '20px',
  },
});

class WorkflowRuleListItem extends Component {
  state = {
    error: false,
    ruleName: this.props.rule.name,
  };

  handleInputChange = ({ target: { value } }) => {
    this.setState({ ruleName: value });
  };

  handleKeyPress = event => {
    const {
      updateRuleName,
      rule: { id },
      toggleEditRuleName,
    } = this.props;
    const { ruleName } = this.state;
    const { key } = event;
    if (key === 'Enter') {
      if (this.isValidRuleName()) {
        this.setState({ error: false });
        toggleEditRuleName(id);
        updateRuleName(id, ruleName);
      } else {
        this.setState({ error: true });
      }
    }
  };

  isValidRuleName = () => {
    const {
      rules,
      rule: { id },
    } = this.props;
    const { ruleName } = this.state;
    return (
      !rules.some(
        rule =>
          rule.name.toLowerCase() === ruleName.toLowerCase() && rule.id !== id,
      ) && !!ruleName.length
    );
  };

  handleFocus = name => {
    if (name.includes('New Conditional Workflow Rule')) {
      this.handleInputChange({ target: { value: '' } });
    }
  };

  handleEditConfirm = () => {
    const {
      updateRuleName,
      rule: { id },
      toggleEditRuleName,
    } = this.props;
    const { ruleName } = this.state;
    if (this.isValidRuleName()) {
      this.setState({ error: false });
      toggleEditRuleName(id);
      updateRuleName(id, ruleName);
    } else {
      this.setState({ error: true });
    }
  };

  renderGroupNameDisplay = () => {
    const {
      rule: { id, triggerFieldId },
      classes,
      isEditingRuleName,
      index,
      triggerSourceIdToHighlight,
      highlightTriggerSource,
    } = this.props;
    const { ruleName, error } = this.state;
    const highlightSourceButton = (
      <HighlightTriggerSourceButton
        triggerFieldId={triggerFieldId}
        triggerSourceIdToHighlight={triggerSourceIdToHighlight}
        enabledTooltipText="This conditional workflow rule has not been assigned a trigger source."
        disabledTooltipText="Highlight the workflow trigger source."
        listItemComponentName="WorkflowRuleListItem"
        highlightTriggerSource={highlightTriggerSource}
        index={index}
      />
    );

    if (isEditingRuleName !== id) {
      return (
        <React.Fragment>
          {highlightSourceButton}
          {ruleName}
        </React.Fragment>
      );
    }
    const hasError = error || ruleName === '';
    return (
      <TextField
        variant="standard"
        error={hasError}
        helperText={
          hasError && 'Empty or duplicate rule names are not allowed.'
        }
        FormHelperTextProps={{
          'data-test-id': `WorkflowRuleListItem-error-${index}`,
        }}
        className={classes.name}
        value={ruleName}
        onChange={this.handleInputChange}
        onKeyPress={this.handleKeyPress}
        onFocus={() => this.handleFocus(ruleName)}
        inputProps={{
          'data-test-id': `WorkflowRuleListItem-ruleNameInput-${index}`,
        }}
      />
    );
  };

  render() {
    const {
      classes,
      rule,
      deleteRule,
      openDeleteDialog,
      isEditingRuleName,
      toggleEditRuleName,
      index,
    } = this.props;
    const { ruleName } = this.state;
    const { name, id, isDefault } = rule;
    const deleteMessage = (
      <span>
        Are you sure you want to delete{' '}
        <b className={classes.bold}>{ruleName}</b>?
      </span>
    );

    const deleteCallback = () => deleteRule(id);
    const listProps = {
      title: this.renderGroupNameDisplay(),
      buttons: [
        {
          disabled:
            name === '' ||
            isDefault ||
            (isEditingRuleName && isEditingRuleName !== id),
          title: isEditingRuleName === id ? 'Done' : 'Edit',
          color: 'secondary',
          onClick:
            isEditingRuleName === id
              ? () => this.handleEditConfirm()
              : () => toggleEditRuleName(id),
          dataTestId: `WorkflowRuleListItem-editButton-${index}`,
        },
        {
          disabled:
            !!isEditingRuleName ||
            (isEditingRuleName && isEditingRuleName !== id),
          title: 'Delete',
          onClick: () => openDeleteDialog(deleteMessage, deleteCallback),
          dataTestId: `WorkflowRuleListItem-deleteButton-${index}`,
        },
      ],
      dataTestIds: {
        title: `WorkflowRuleListItem-ruleName-${index}`,
        root: `WorkflowRuleListItem-root-${index}`,
        subHeader: `WorkflowRuleListItem-subHeader-${index}`,
      },
      key: `WorkflowRuleListItem-${id}`,
      id: `WorkflowRuleListItem-${id}`,
    };
    return <ListItem {...listProps} />;
  }
}

export default withStyles(styles)(WorkflowRuleListItem);
