import React from 'react';

// MuiComponents
import { Paper, Tabs, Tab } from '@mui/material';

// HoC
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import history from '../../../common/constants/config/history';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

// Components;
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import LayoutHeader from 'common/oldJavascripts/components/Base/Layout/LayoutHeader';
import GlobalSecondaryNavBar from 'common/oldJavascripts/components/Pages/Layouts/GlobalNavbar/SecondaryNavbar';
import NavItemsContainer from 'common/oldJavascripts/components/Pages/Layouts/GlobalNavbar/NavItemsContainer';
import SubNavItem from 'common/oldJavascripts/components/Pages/Layouts/GlobalNavbar/SubNavItem';
import Box from 'common/oldJavascripts/components/Base/Box';
import tabs from './tabs';
import { styled } from '@mui/system';

const styles = {
  topMargin: {
    marginTop: 16,
  },
  tabs: {
    marginTop: 16,
    marginLeft: 15,
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#0000ff',
  },
  container: {
    padding: 16,
  },
};

const StyledNavItemsContainer = styled(NavItemsContainer)({
  position: 'relative',
  top: '9px',
});

const ToolsTab = props => {
  const {
    classes,
    tab,
    location: { pathname = '' },
  } = props;
  const tabIndex = tab || 0;

  const handleTabChange = (_event, value) => {
    const href = `/tools/${tabs[value].id}`;
    history.push(href);
  };
  const flags = useFeatureFlags();
  const globalNavFlag = flags.includes('GlobalNavbar');
  const activeTab = tabs[tabIndex] || {};
  const content = activeTab.component;

  const renderTabs = () => {
    return tabs.map(({ id, label, test_id }, index) => (
      <SubNavItem
        to={`tools/${id}`}
        isActive={pathname.includes(id) || tabIndex === index}
        data-test-id={`ToolsTab-${test_id}`}
      >
        {label}
      </SubNavItem>
    ));
  };

  if (globalNavFlag) {
    return (
      <>
        <LayoutHeader globalNav={true}>
          <GlobalSecondaryNavBar>
            <StyledNavItemsContainer>{renderTabs()}</StyledNavItemsContainer>
          </GlobalSecondaryNavBar>
        </LayoutHeader>
        <LayoutContent>
          <Paper elevation={0} className={classes.container}>
            <Box data-test-id={`ToolsTab-content-${activeTab.id}`}>
              {content}
            </Box>
          </Paper>
        </LayoutContent>
      </>
    );
  }

  return (
    <LayoutContent>
      <Box>
        <Paper elevation={0}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            value={tabIndex}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            {tabs.map(({ id, label, test_id }) => (
              <Tab
                key={`tools-tab-${id}`}
                label={label}
                data-test-id={`ToolsTab-${test_id}`}
              />
            ))}
          </Tabs>
        </Paper>

        <Paper elevation={0} className={classes.container}>
          <Box data-test-id={`ToolsTab-content-${activeTab.id}`}>{content}</Box>
        </Paper>
      </Box>
    </LayoutContent>
  );
};

export default compose(withRouter, withStyles(styles))(ToolsTab);
