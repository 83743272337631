const projectPrivilegesCheck = privilege => ({
  match = {},
  resources = {},
  getResource,
}) => {
  /*
  projectPrivilegesCheck checks only against privileges that are stored in /projects/:projectId query
  with the query param of with_privileges = true OR in /projects with query param of per_page=20 OR in /projects/:projectId without a query param when project data is stored as a sub-resource of the parent /projects resource
  We expect these to change and have a better backend system for permissions

  1. We pull the project id from the match prop which denotes which project we are on
  2. Check if we have the project query already requested
  3. If not then we request it and return loading
  4. If its loading then we return loading
  5. If its failed then we assume the user does not have the permission and return false
  6. If we have it we check if the privilege is in the array and return the answer
   */
  const { params: { projectId } = {} } = match || {};
  const projectRoute = `/projects/${projectId}?with_privileges=true`;
  const projectAsSubResourceRoute = `/projects/${projectId}`;
  const {
    [projectRoute]: projectQuery = {},
    [projectAsSubResourceRoute]: projectAsSubResourceQuery = {},
    '/projects?per_page=20': bulkProjectQuery = {},
  } = resources || {};
  const {
    status: bulkProjectQueryStatus,
    data: { items: bulkProjects = [] } = {},
  } = bulkProjectQuery || {};
  const {
    status: projectQueryStatus,
    data: { privileges: individualQueryPrivileges = [] } = {},
  } = projectQuery || {};
  const {
    data: { privileges: projectAsSubResourcePrivileges = [] } = {},
  } = projectAsSubResourceQuery;
  const requestPrivileges = () => {
    getResource({ url: `/projects/${projectId}?with_privileges=true` });
    return 'loading';
  };
  const getPrivileges = () => {
    // If we have privileges from the individual query just return those
    if (individualQueryPrivileges.length > 0) return individualQueryPrivileges;
    // If we have privileges from the project from a parent collection we return those privileges
    if (projectAsSubResourcePrivileges.length > 0)
      return projectAsSubResourcePrivileges;
    // If not then look through bulk projects and try to find the project in the bulk query
    const { privileges } =
      bulkProjects.find(({ id }) => String(id) === projectId) || {};
    // And return the privileges array from it
    return privileges || [];
  };
  // Neither request has been made, make the specific query we want
  if (bulkProjectQueryStatus === undefined && projectQueryStatus === undefined)
    return requestPrivileges();
  // One or both are loading so return loading
  if (projectQueryStatus === 'loading' || bulkProjectQueryStatus === 'loading')
    return 'loading';
  // Bulk request failed but the individual query has not been made then fire off the request
  // We dont do this the other way around because we do not know which page the project is on
  if (projectQueryStatus === undefined && bulkProjectQueryStatus === 'failed')
    return requestPrivileges();
  // At this point one of the arrays is successful OR the individual
  // query has failed which will fail the next if statement
  // Look through the array and see if the privilege is present
  if (getPrivileges().includes(privilege)) return true;
  return false;
};

export const can_create_offers = props =>
  projectPrivilegesCheck('can_create_offers')(props);

export const can_review_offers = props =>
  projectPrivilegesCheck('can_review_offers')(props);

export const can_approve_offers = props =>
  projectPrivilegesCheck('can_approve_offers')(props);

export const can_access_archives = props =>
  projectPrivilegesCheck('can_access_archives')(props);

export const can_be_i9_verifier = props =>
  projectPrivilegesCheck('can_be_i9_verifier')(props);

export const can_view_documents = props =>
  projectPrivilegesCheck('can_view_documents')(props);

export const can_access_crew_profile = props => {
  const { resources = {}, getResource } = props || {};
  const { '/account/profile': profileQuery = {} } = resources || {};
  const { status: profileQueryStatus, data: { is_employee: isEmployee } = {} } =
    profileQuery || {};

  const requestPrivileges = () => {
    getResource({ url: `/account/profile` });
    return 'loading';
  };

  // The request has not yet been made, make the specific query we want
  if (profileQueryStatus === undefined) return requestPrivileges();
  // Query is loading
  if (profileQueryStatus === 'loading' && isEmployee === undefined)
    return 'loading';
  // Query has failed, fail the permission request
  if (profileQueryStatus === 'failed' && isEmployee === undefined) return false;
  // The employee flag represents whether they are a crew member
  // This is not a concise key, but it is what we have
  return isEmployee;
};

export const can_access_non_crew_profile = props => {
  const { resources = {}, getResource } = props || {};
  const { '/account/profile': profileQuery = {} } = resources || {};
  const { status: profileQueryStatus, data: { is_employee: isEmployee } = {} } =
    profileQuery || {};

  const requestPrivileges = () => {
    getResource({ url: `/account/profile` });
    return 'loading';
  };
  // The request has not yet been made, make the specific query we want
  if (profileQueryStatus === undefined) return requestPrivileges();
  // Query is loading
  if (profileQueryStatus === 'loading' && isEmployee === undefined)
    return 'loading';
  // Query has failed, fail the permission request
  if (profileQueryStatus === 'failed' && isEmployee === undefined) return false;
  // The employee flag represents whether they are a crew member
  // This is not a concise key, but it is what we have
  return !isEmployee;
};
