import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import DocumentModal from 'common/oldJavascripts/components/Shared/DocumentModal';
import Link from 'common/oldJavascripts/components/Base/Link';
import FileIcon from '@mui/icons-material/InsertDriveFile';

const styles = {
  container: {
    flex: '1 1 auto',
    marginBottom: 5,
  },
  icon: {
    marginRight: '5px',
    verticalAlign: 'bottom',
  },
};

class SupportingDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {this._renderFilename()}
        {this._renderModal()}
      </div>
    );
  }

  _onFilenameClick(event) {
    event.preventDefault();
    this.setState({ showModal: true });
  }

  _renderFilename() {
    const { document: doc = {}, classes = {} } = this.props;
    const content = [<FileIcon className={classes.icon} />, doc.name];

    if (!this.props.allowPreview) {
      return content;
    }

    return (
      <Link onClick={event => this._onFilenameClick(event)} plain={true}>
        {content}
      </Link>
    );
  }

  _renderModal() {
    const pages = this.props.document.pages;
    if (pages && this.state.showModal) {
      return (
        <DocumentModal
          onClose={() => this.setState({ showModal: false })}
          pages={pages}
        />
      );
    }
  }
}

export default withStyles(styles)(SupportingDocument);
