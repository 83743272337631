import useMe from './useMe';
import useCliendAndProducer from 'common/hooks/useClientAndProducer';

const initPendo = userData => {
  const { authProviderId, email } = userData;
  window.pendo.initialize({
    visitor: { id: authProviderId, email },
    account: {},
  });
};

const getUsaInfo = (
  userData,
  clientName,
  clientId,
  producerId,
  producerName,
) => {
  const { authProviderId, email } = userData;
  const parentId = `${clientId[0]?.slice(0, 3)}|${clientId[1]}`;
  return {
    visitor: {
      id: authProviderId,
      email,
      current_parent_ID: parentId,
      current_account_ID: `${parentId}|${producerId}`,
      current_parent_name: clientName,
      current_account_name: `${clientName}|${producerName}`,
    },
    account: { id: producerId, name: producerName },
    parentAccount: { id: parentId, name: clientName },
  };
};

const getCanadaInfo = (
  userData,
  clientName,
  clientId,
  producerId,
  producerName,
) => {
  const { authProviderId, email } = userData;
  const parentId = `${clientId[0]?.slice(-5)}|${clientId[1]}`;
  return {
    visitor: {
      id: authProviderId,
      email,
      current_parent_ID: parentId,
      current_account_ID: producerId,
      current_parent_name: clientName,
      current_account_name: producerName,
    },
    account: { id: producerId, name: producerName },
    parentAccount: { id: parentId, name: clientName },
  };
};

const updatePendo = (userData, projectId, project) => {
  if (!projectId) return;

  const {
    country: { name: projectCountry } = {},
    client: { name: clientName, cncId: clientCncId } = {},
    productionCompany: {
      producer: { cncId: producerCncId, name: producerName } = {},
    } = {},
  } = project || {};
  const clientDetails = clientCncId?.split('|') || [];
  const producerDetails = producerCncId?.split('|') || [];
  if (!clientDetails.length || !producerDetails.length) {
    return;
  }
  const infoArgs = [
    userData,
    clientName,
    clientDetails,
    producerDetails[1],
    producerName,
  ];
  const info =
    projectCountry === 'Canada'
      ? getCanadaInfo(...infoArgs)
      : getUsaInfo(...infoArgs);
  window.pendo.updateOptions({
    ...info,
  });
};

const usePendo = ({ init = false, projectId = null }) => {
  const { data } = useMe();
  const { data: { project } = {} } = useCliendAndProducer(projectId) || {};
  if (!data) return;
  init ? initPendo(data) : updatePendo(data, projectId, project);
};

export default usePendo;
