import React, { useEffect, useState, useRef } from 'react';
import { withStyles, ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { IconButton, Typography } from '@mui/material';
import { AddCircle as AddIcon } from '@mui/icons-material';
import Dialog from 'common/components/Dialog';
import DocumentNotificationDialogDistributionList from './DocumentNotificationDialogDistributionList';

const EMPTY_DIST_LIST = {
  departments: [],
  recipients: [],
  isEnabled: true,
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#0000FF',
    },
  },
});

const styles = theme => ({
  paper: {
    width: 600,
    height: 600,
  },
  addBtnContainer: {
    width: '100%',
    textAlign: 'center',
  },
  addBtnIcon: {
    color: theme.palette.primary.main,
    width: 40,
    height: 40,
  },
  closeBtn: {
    width: 60,
    height: 40,
    borderRadius: 0,
    background: '#E0E0E0',
    color: 'black',
  },
  saveBtn: {
    width: 60,
    height: 40,
    borderRadius: 0,
    background: '#0000FF',
    color: 'white',
    '&:hover': {
      background: '#1A55FD',
    },
    '&:focus': {
      background: '#0000B3',
    },
    '&:active': {
      background: '#000080',
    },
  },
});

const DocumentNotificationDialog = props => {
  const {
    classes,
    distributionLists: upstreamDistLists = [],
    notificationName,
    onClose,
    onSave,
    open,
  } = props;
  const initialDistLists = upstreamDistLists.length
    ? upstreamDistLists
    : [EMPTY_DIST_LIST];

  const [distLists, setDistLists] = useState(initialDistLists);
  const [shouldScrollToEnd, setShouldScrollToEnd] = useState(false);
  const addBtnContainerRef = useRef();

  useEffect(() => {
    if (!shouldScrollToEnd) return;
    addBtnContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    setShouldScrollToEnd(false);
  }, [shouldScrollToEnd]);

  const addDistList = () => {
    setDistLists(distLists.concat(EMPTY_DIST_LIST));
    setShouldScrollToEnd(true);
  };

  const save = () => onSave(distLists).then(onClose);

  const distListsList = distLists.map((distList, index) => {
    const isMultiple = distLists.length > 1;

    const replaceDistList = newDistList => {
      const newDistLists = distLists.map((distList, i) =>
        i === index ? newDistList : distList,
      );
      setDistLists(newDistLists);
    };

    const deleteDistList = () =>
      setDistLists(distLists.filter((__, i) => i !== index));

    return (
      <span
        data-test-id={`DocumentNotificationDialog-distributionList-${index}`}
        key={index}
      >
        <DocumentNotificationDialogDistributionList
          distributionList={distList}
          onChange={replaceDistList}
          onDelete={isMultiple && deleteDistList}
          index={index}
        />
      </span>
    );
  });

  const body = (
    <div>
      <Typography variant="subtitle1">
        Configure recipient lists for these notifications here. Lists with
        associated departments will only be notified of offers for jobs in that
        department. Lists without a department will receive all notifications.
      </Typography>
      {distListsList}
      <div className={classes.addBtnContainer} ref={addBtnContainerRef}>
        <IconButton
          onClick={addDistList}
          data-test-id="DocumentNotificationDialog-addDistributionList"
        >
          <AddIcon className={classes.addBtnIcon} />
        </IconButton>
      </div>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        classes={{ paper: classes.paper }}
        open={open}
        title={notificationName}
        message={body}
        actions={[
          {
            text: 'Cancel',
            fn: onClose,
            className: classes.closeBtn,
            color: 'secondary',
          },
          { text: 'Save', fn: save, className: classes.saveBtn },
        ]}
        handleClose={onClose}
      />
    </ThemeProvider>
  );
};

export default withStyles(styles)(DocumentNotificationDialog);
