import React from 'react';
import Relay from '../../../utils/react/Relay.js';
import ajax from 'common/utilities/ajax';
import Preloader from '../Preloader';
import Modal from '../../Base/Modal';
import BoxParagraphItem from 'common/oldJavascripts/components/Base/Box/BoxParagraphItem';
import Link from '../../Base/Link';
import FailureAlert from '../FailureAlert';
import DocumentModal from '../DocumentModal';

const supportingDocumentUrl = ({ offerId, documentId, supportingDocumentId }) =>
  `/v2/offers/${offerId}/documents/${documentId}/supporting-documents/${supportingDocumentId}/images`;

const documentUrl = ({ offerId, documentId }) =>
  `/v2/offers/${offerId}/documents/${documentId}/images`;

const MAX_TRY = 10;
const TIMEOUT = 2000;

export default Relay.createClass({
  getInitialState() {
    return {
      items: [],
      isFailed: false,
    };
  },

  componentDidMount() {
    const { params } = this.props;
    const { isSupportingDocument } = params;
    const url = isSupportingDocument
      ? supportingDocumentUrl(params)
      : documentUrl(params);
    this.request(url, MAX_TRY);
  },

  request(url, count = 0) {
    const { isFailed } = this.state;
    if (isFailed) {
      return;
    }

    if (count < 0) {
      this.setState({ isFailed: true });
      return;
    }

    ajax
      .get(url)
      .then(response => {
        const { items } = response;

        if (Array.isArray(items) && items.length > 0) {
          this.setState({ items });
        } else {
          setTimeout(() => {
            this.request(url, count - 1);
          }, TIMEOUT);
        }
      })
      .catch(() => {
        this.setState({ isFailed: true });
      });
  },

  render() {
    const { items = [], isFailed = false } = this.state;
    const { onClose: closeFunc } = this.props;

    if (isFailed) {
      return (
        <Modal>
          <BoxParagraphItem align="right" minimal={true}>
            <Link onClick={closeFunc}>Close</Link>
          </BoxParagraphItem>
          <FailureAlert queryName="supporting document images" />
        </Modal>
      );
    }

    return items.length > 0 ? (
      <DocumentModal onClose={closeFunc} pages={items} />
    ) : (
      <Modal>
        <BoxParagraphItem align="right" minimal={true}>
          <Link onClick={closeFunc}>Close</Link>
        </BoxParagraphItem>
        <Preloader />
      </Modal>
    );
  },
});
