import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import List from '@mui/material/List';
import RoleListItem from 'admin/components/RoleMapper/RoleListItem';
import RuleList from 'admin/components/RoleMapper/RuleList';
import CustomInput from 'admin/components/RoleMapper/CustomInput';
import FieldDrawer from 'admin/components/RoleMapper/FieldDrawer';
import Sidebar from 'admin/components/RoleMapper/Sidebar';
import ConditionalRuleListItem from 'admin/components/RoleMapper/ConditionalRuleListItem';
import TriggerRuleListItem from 'admin/components/RoleMapper/TriggerRuleListItem';
import WorkflowRuleListItem from 'admin/components/RoleMapper/WorkflowRuleListItem';
import GroupToolbar from './GroupToolbar';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
    position: 'relative',
    background: 'white',
    borderLeft: '1px solid #d7d7d7',
  },
  list: {
    minHeight: 400,
  },
  text: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
  },
  hidden: {
    display: 'none',
    transition: 'display 0.2s cubic-bezier(0.23, 1, 0.32, 1)',
    transitionDelay: '0.2s',
  },
});

class GroupContainer extends Component {
  state = {
    menuAnchor: null,
    tabValue: 0,
    conditionalRulesIsOpen: true,
    triggerRulesIsOpen: true,
    workflowRulesIsOpen: true,
  };

  handleOpenMenu = ({ currentTarget }) =>
    this.setState({ menuAnchor: currentTarget });

  handleCloseMenu = () => this.setState({ menuAnchor: null });

  handleCreateConditionalRule = () => {
    const { createConditionalRule } = this.props;
    this.handleCloseMenu();
    createConditionalRule();
    this.setState({ conditionalRulesIsOpen: true });
  };

  handleCreateTriggerRule = () => {
    const { createTriggerRule } = this.props;
    this.handleCloseMenu();
    createTriggerRule();
    this.setState({ triggerRulesIsOpen: true });
  };

  handleCreateNewInput = () => {
    const { createNewInput } = this.props;
    this.handleCloseMenu();
    createNewInput();
  };

  handleCreateNewEmail = () => {
    const { createNewEmailNotification } = this.props;
    this.handleCloseMenu();
    createNewEmailNotification();
  };

  handleCreateNewWorkflowRule = () => {
    const { createNewWorkflowRule } = this.props;
    this.handleCloseMenu();
    this.setState({ workflowRulesIsOpen: true });
    createNewWorkflowRule();
  };

  handleTabChange = tabValue => this.setState({ tabValue });

  toggleList = ruleType => () => {
    this.setState(({ [`${ruleType}IsOpen`]: isOpen }) => ({
      [`${ruleType}IsOpen`]: !isOpen,
    }));
  };

  render() {
    const {
      classes,
      activeRoleId,
      setActiveRole,
      roles,
      inputs,
      onKeyPress,
      filterGroups,
      docId,
      addNewRole,
      deleteRole,
      rules,
      addFieldGroupToConditionalRule,
      toggleRuleMapping,
      activeRuleGroupId,
      deleteRule,
      deleteGroupFromRule,
      openDeleteDialog,
      openConfigureDialog,
      openEmailDialog,
      className,
      updateRoleName,
      addNewInputField,
      deleteInput,
      deleteInputField,
      openInputDialog,
      updateInput,
      fieldDrawerProps,
      sideBarProps,
      highlightTriggerSource,
      triggerSourceIdToHighlight,
      activeRuleId,
      isEditingRoleName,
      toggleEditRoleName,
      updateRuleName,
    } = this.props;
    const {
      menuAnchor,
      tabValue,
      conditionalRulesIsOpen,
      triggerRulesIsOpen,
      workflowRulesIsOpen,
    } = this.state;
    const { drawerOpen } = fieldDrawerProps;
    const roleList = roles.map(role => (
      <RoleListItem
        key={role.id}
        setActiveRole={setActiveRole}
        onKeyPress={onKeyPress}
        role={role}
        deleteRole={deleteRole}
        activeRoleId={activeRoleId}
        openDeleteDialog={openDeleteDialog}
        roles={roles}
        updateRoleName={updateRoleName}
        isEditingRoleName={isEditingRoleName}
        toggleEditRoleName={toggleEditRoleName}
      />
    ));
    const conditionalRulesList = rules
      .filter(r => r.ruleType === 'group')
      .map(rule => (
        <ConditionalRuleListItem
          addFieldGroupToConditionalRule={addFieldGroupToConditionalRule}
          key={rule.id}
          rule={rule}
          toggleRuleMapping={toggleRuleMapping}
          activeRuleGroupId={activeRuleGroupId}
          deleteRule={deleteRule}
          deleteGroupFromRule={deleteGroupFromRule}
          openDeleteDialog={openDeleteDialog}
          openConfigureDialog={openConfigureDialog}
        />
      ));
    const triggerRulesList = rules
      .filter(r => r.ruleType === 'trigger')
      .map((rule, index) => (
        <TriggerRuleListItem
          key={rule.id}
          rule={rule}
          index={index}
          toggleRuleMapping={toggleRuleMapping}
          activeRuleGroupId={activeRuleGroupId}
          deleteRule={deleteRule}
          openDeleteDialog={openDeleteDialog}
          openConfigureDialog={openConfigureDialog}
          highlightTriggerSource={highlightTriggerSource}
          triggerSourceIdToHighlight={triggerSourceIdToHighlight}
          activeRuleId={activeRuleId}
        />
      ));
    const workflowRuleList = rules
      .filter(r => r.ruleType === 'workflow')
      .map((rule, index) => (
        <WorkflowRuleListItem
          key={rule.id}
          index={index}
          rule={rule}
          rules={rules}
          activeRuleGroupId={activeRuleGroupId}
          deleteRule={deleteRule}
          openDeleteDialog={openDeleteDialog}
          openConfigureDialog={openConfigureDialog}
          highlightTriggerSource={highlightTriggerSource}
          triggerSourceIdToHighlight={triggerSourceIdToHighlight}
          activeRuleId={activeRuleId}
          toggleEditRuleName={toggleEditRoleName}
          isEditingRuleName={isEditingRoleName}
          updateRuleName={updateRuleName}
        />
      ));
    const inputList = inputs
      .filter(i => i.active)
      .map(input => (
        <CustomInput
          key={input.id}
          input={input}
          addNewInputField={addNewInputField}
          openDeleteDialog={openDeleteDialog}
          deleteInput={deleteInput}
          deleteInputField={deleteInputField}
          openInputDialog={openInputDialog}
          updateInput={updateInput}
        />
      ));
    const emailsList = rules
      .filter(r => r.ruleType === 'email')
      .map((email, index) => (
        <TriggerRuleListItem
          key={email.id}
          rule={email}
          index={index}
          toggleRuleMapping={toggleRuleMapping}
          openDeleteDialog={openDeleteDialog}
          deleteRule={deleteRule}
          activeRuleGroupId={activeRuleGroupId}
          openConfigureDialog={openEmailDialog}
          highlightTriggerSource={highlightTriggerSource}
          triggerSourceIdToHighlight={triggerSourceIdToHighlight}
          activeRuleId={activeRuleId}
          deleteConfirmationRequired={true}
          docId={docId}
        />
      ));
    const rulesList = (
      <React.Fragment>
        <RuleList
          onClick={this.toggleList('conditionalRules')}
          id="conditionalRules"
          title="Required Rules"
          isOpen={conditionalRulesIsOpen}
        >
          {conditionalRulesList}
        </RuleList>
        <RuleList
          onClick={this.toggleList('triggerRules')}
          id="triggerRules"
          title="Trigger Rules"
          isOpen={triggerRulesIsOpen}
        >
          {triggerRulesList}
        </RuleList>
        <RuleList
          onClick={this.toggleList('workflowRules')}
          id="workflowRules"
          title="Conditional Workflow Rules"
          isOpen={workflowRulesIsOpen}
        >
          {workflowRuleList}
        </RuleList>
      </React.Fragment>
    );
    return (
      <div className={classNames(classes.root, className)}>
        <GroupToolbar
          filterGroups={filterGroups}
          addNewRole={addNewRole}
          tabValue={tabValue}
          handleTabChange={this.handleTabChange}
          openMenu={this.handleOpenMenu}
          menuAnchor={menuAnchor}
          closeMenu={this.handleCloseMenu}
          createConditionalRule={this.handleCreateConditionalRule}
          createTriggerRule={this.handleCreateTriggerRule}
          createNewWorkflowRule={this.handleCreateNewWorkflowRule}
          activeRuleGroupId={activeRuleGroupId}
          activeRoleId={activeRoleId}
          currentTab={tabValue}
          createNewInput={this.handleCreateNewInput}
          createNewEmailNotification={this.handleCreateNewEmail}
          isEditingRoleName={isEditingRoleName}
        />
        <List
          className={classNames(classes.list, {
            [classes.hidden]: drawerOpen,
          })}
        >
          {tabValue === 0 && roleList}
          {tabValue === 1 && rulesList}
          {tabValue === 2 && inputList}
          {tabValue === 3 && emailsList}
        </List>
        <Sidebar {...sideBarProps}>
          {drawerOpen && <FieldDrawer {...fieldDrawerProps} />}
        </Sidebar>
      </div>
    );
  }
}

export default withStyles(styles)(GroupContainer);
