import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query validatePayrollData(
    $projectId: Int!
    $union: String!
    $occupation: String!
    $workSchedule: String!
    $scaleRate: TermsOfEmploymentInputV2!
    $startDate: String!
    $workState: String!
    $workCity: String
    $hireState: String
    $hireCity: String
    $endDate: String
    $season: Int
    $currency: String
    $rollback: Boolean
  ) {
    validatePayrollData(
      projectId: $projectId
      union: $union
      occupation: $occupation
      workSchedule: $workSchedule
      scaleRate: $scaleRate
      startDate: $startDate
      workState: $workState
      workCity: $workCity
      hireState: $hireState
      hireCity: $hireCity
      endDate: $endDate
      season: $season
      currency: $currency
      rollback: $rollback
    ) {
      occupation
      scaleRates
      union
      workSchedule
    }
  }
`;

const usePayrollValidation = variables => {
  const {
    projectId,
    union,
    occupation,
    workSchedule,
    scaleRate,
    startDate,
    workState,
  } = variables;

  const skipCondition =
    !projectId ||
    !union ||
    !occupation ||
    !workSchedule ||
    !scaleRate ||
    !startDate ||
    !workState;

  const { refetch, loading, data } = useQuery(QUERY, {
    skip: skipCondition,
    variables,
    fetchPolicy: 'no-cache',
  });
  return {
    refetch,
    loading,
    data: data?.validatePayrollData,
  };
};

export default usePayrollValidation;
