import React from 'react';

import ErrorAlert from './ErrorAlert';
import Text from '../Base/Text';

const FailureAlert = ({ queryName = '', className }) => (
  <ErrorAlert className={className}>
    <Text paragraph={true}>
      There was an error loading {queryName.trim() || 'data from the server'}.
      Please refresh your browser to try again.
    </Text>
    <Text paragraph={true}>
      If the error persists, please contact Customer Service.
    </Text>
  </ErrorAlert>
);

export default FailureAlert;
