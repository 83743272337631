import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
// Components
import DatePicker from 'react-datepicker';
import Typography from '@mui/material/Typography';

const EndDateSelect = props => {
  const {
    onChange: upstreamOnChange,
    formData: { startDateObject, endDateObject } = {},
    classes = {},
  } = props || {};

  const [minStartDate, setMinStartDate] = useState(null);

  useEffect(() => {
    const dateObj = cloneDeep(startDateObject);
    if (dateObj) setMinStartDate(dateObj.add(1, 'day'));
  }, [startDateObject]);

  const onChange = date => {
    const endDate = new Date(date);
    const startDate = new Date(startDateObject);
    if (endDate.getTime() <= startDate.getTime()) {
      upstreamOnChange({
        endDate: null,
        endDateObject: null,
      });
      return;
    }
    upstreamOnChange({
      endDate: (date && date.format('LL')) || null,
      endDateObject: date,
    });
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1">
        Anticipated End Date
      </Typography>
      <div data-test-id="EndDate-root">
        <DatePicker
          todayButton={'Today'}
          selected={endDateObject}
          onChange={onChange}
          className={classes.field}
          minDate={minStartDate}
          placeholderText={'MM-DD-YYYY'}
        />
      </div>
    </div>
  );
};
export default EndDateSelect;
