import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query offerDocuments($offerId: Int!) {
    offer(id: $offerId) {
      id
      status
      keepI9
      documentDetails {
        id
        name
        status
        isViewable
        unremovable
        workflow {
          id
          documentId
          requireAll
          levelName
          status
          approvers {
            id
            status
            fullName
          }
        }
        supportingDocuments {
          id
          name
          isViewable
        }
      }
      loanoutCorporation {
        w9 {
          id
          name
        }
      }
      externalDocuments {
        name
        documentId
        id
      }
      removedDocuments {
        id
        status
        documentId
        name
      }
    }
  }
`;

const useOfferDocuments = offerId => {
  const { error, loading, data, refetch } = useQuery(QUERY, {
    variables: { offerId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  return { error, loading, data: data?.offer, refetch };
};

export default useOfferDocuments;
