import React from 'react';

// Base Components
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import withRouteHelpers from 'common/hoc/withApi';
import { compose } from 'redux';

// Components

import OffersTable from './Archives/OffersTable';
// import ManualArchives from './Archives/ManualArchives';
import ManualArchives from 'studio/components/ManualArchives';

// HoC
import withPermissionProtection from 'common/hoc/withPermissionProtection';

const Archives = props => {
  const {
    routerParams: { projectId },
  } = props;
  return (
    <LayoutContent data-test-id="Archives-root">
      <ManualArchives projectId={projectId} />
      <OffersTable />
    </LayoutContent>
  );
};

export default compose(
  withPermissionProtection('can_access_archives'),
  withRouteHelpers,
)(Archives);
