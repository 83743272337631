export default mutators => {
  if (!Array.isArray(mutators)) {
    throw new Error(`Not an array: ${mutators}`);
  }

  return mutators
    .map(mutator => {
      if (Array.isArray(mutator.errors)) {
        const mutatorErrors = {};
        mutator.errors.forEach(error => {
          if (error) {
            Object.keys(error).map(id => (mutatorErrors[id] = error[id]));
          }
        });
        return mutatorErrors;
      }
      return mutator.errors;
    })
    .filter(Boolean)
    .reduce((errors, mutatorErrors) => {
      Object.keys(mutatorErrors).forEach(key => {
        const fieldErrors = mutatorErrors[key];
        if (fieldErrors) {
          if (!errors) {
            errors = {};
          }
          errors[key] = (errors[key] || []).concat(fieldErrors);
        }
      });
      return errors;
    }, null);
};
