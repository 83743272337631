import React from 'react';

import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { Collapse, IconButton as Button, Tooltip } from '@mui/material';
import Slider from 'react-slick';
import {
  Clear as CloseIcon,
  NavigateBeforeRounded,
  NavigateNextRounded,
  CheckCircle as CheckIcon,
  HighlightOff as ClearIcon,
} from '@mui/icons-material';
import classNames from 'classnames';
import {
  COMPLETE,
  INPROGRESS,
  REJECTED,
  WAITING,
} from 'common/utilities/constants/documentStatus';

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  stepHorizontal: {
    marginTop: `${theme.spacing(6)}`,
  },
  closeIcon: {
    float: 'right',
    cursor: 'pointer',
  },
  levelCard: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    borderRadius: '5px',
    margin: '10px',
    maxWidth: '97%',

    '&:hover': {
      borderColor: theme.palette.grey[600],
    },
  },
  level: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  levelHeading: {
    fontWeight: 550,
  },
  iconButtonRoot: {
    width: 40,
    height: 40,
    padding: 0,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  success: {
    color: '#00DF68',
  },
  successButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#0000B3',
      backgroundColor: '#E2F1FF',
      borderRadius: '25px',
    },
    '&:active': {
      color: '#000080',
      backgroundColor: '#D9E3FE',
      borderRadius: '25px',
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  pending: {
    color: theme.palette.grey[250],
  },
  leftPadding: {
    paddingLeft: theme.spacing(2),
  },
  levelName: {
    paddingLeft: theme.spacing(5),
    fontWeight: 550,
  },
  approvers: {
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  noApprovalLevels: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: '280px',
    overflow: 'auto',
    border: '0.5px dashed grey',
    minHeight: '170px',
    marginTop: '8px',
    marginRight: '10px',
  },
  supportingDocuments: {
    marginLeft: theme.spacing(16),
    marginTop: -theme.spacing(2.9),
  },
  documentListAllignment: {
    flexDirection: 'row',
    maxWidth: '800px',
    overflow: 'auto',
    marginLeft: theme.spacing(34.5),
    marginTop: '-24px',
  },
  supportingDocumentsViewableList: {
    fontSize: '0.9rem !important',
    color: '#0000FF !important',
    borderRadius: '0px !important',
    '&:hover': {
      color: '#0000FF !important',
    },
    '&:active': {
      border: '1.5px solid #0000FF !important',
    },
  },
  supportingDocumentsDisabledList: { fontSize: '0.9rem !important' },
});

const NextArrow = props => {
  const { className, onClick, classes } = props;
  return (
    <NavigateNextRounded
      className={`${className} ${classes.successButton}`}
      onClick={onClick}
    />
  );
};

const PrevArrow = props => {
  const { className, onClick, classes } = props;
  return (
    <NavigateBeforeRounded
      className={`${className} ${classes.successButton}`}
      onClick={onClick}
    />
  );
};

const DocumentApprovalStatus = props => {
  const {
    classes,
    data,
    open,
    closeDrawer,
    selectedDocument = {},
    previewDocument,
  } = props;
  const settings = {
    arrows: true,
    dots: true,
    draggable: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow classes={classes} />,
    prevArrow: <PrevArrow classes={classes} />,
  };

  const generateapproverIcon = step => {
    const { status, requireAll, levelName } = step;

    const statusClass = classNames(
      {
        [classes.success]: status === COMPLETE,
        [classes.error]: status === REJECTED,
        [classes.pending]: status === INPROGRESS || status === WAITING,
      },
      [classes.capitalize],
      [classes.leftPadding],
    );
    const requireAllClass = classNames(
      [classes.capitalize],
      [classes.leftPadding],
    );
    const tooltipTitleProp = (
      <div>
        <Typography className={classes.capitalize} color="inherit">
          Status:
        </Typography>
        <Typography className={statusClass}>{status}</Typography>
        <Typography color="inherit">Require:</Typography>
        <Typography color="inherit" className={requireAllClass}>
          {requireAll ? 'All Members' : 'One Member'}
        </Typography>
      </div>
    );

    return (
      <Tooltip disableInteractive title={tooltipTitleProp} placement="right">
        <Button
          disableRipple
          classes={{ root: classes.iconButtonRoot }}
          data-test-id={`offer-document-approval-step-${levelName}-${status}`}
        >
          {status === COMPLETE && <CheckIcon className={classes.success} />}
          {status === REJECTED && <ClearIcon className={classes.error} />}
          {(status === INPROGRESS || status === WAITING) && <ClearIcon />}
        </Button>
      </Tooltip>
    );
  };

  const approvalSteps = data?.map((step, i) => {
    const { status, levelName, approvers } = step;
    const levelHeadingClassName = classNames(
      {
        [classes.success]: status === COMPLETE,
        [classes.error]: status === REJECTED,
      },
      [classes.levelHeading],
    );

    const approversList = approvers?.map(approver => {
      const { status, fullName, id } = approver;
      const approverClass = classNames({
        [classes.success]: status === COMPLETE,
        [classes.error]: status === REJECTED,
      });

      return (
        <Typography
          className={approverClass}
          data-test-id={`offer-document-approver-text-${levelName}-${id}`}
        >
          {fullName}
        </Typography>
      );
    });

    return (
      <div
        className={classes.levelCard}
        data-test-id={`offer-document-approval-${levelName}`}
      >
        <div className={classes.level}>
          {generateapproverIcon(step)}
          <Typography type="h1" className={levelHeadingClassName}>
            Level {i + 1}
          </Typography>
        </div>
        <Typography type="h2" className={classes.levelName}>
          {levelName}
        </Typography>
        <div className={classes.approvers}>{approversList}</div>
      </div>
    );
  });

  const { supportingDocuments: supportDocs = [] } = selectedDocument;
  const supportingDocumentsList = supportDocs.map(supportDoc => (
    <Button
      data-test-id={`supporting-document-preview-${supportDoc.id}`}
      className={
        supportDoc?.isViewable
          ? classes.supportingDocumentsViewableList
          : classes.supportingDocumentsDisabledList
      }
      disabled={!supportDoc?.isViewable}
      onClick={() =>
        previewDocument([selectedDocument.id, supportDoc.id], false)
      }
    >
      {supportDoc.name}
    </Button>
  ));
  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <div className={classes.closeIcon}>
          <CloseIcon
            onClick={closeDrawer}
            data-test-id="close-button-document-approval-status"
          />
        </div>
        <Typography variant="h6">Approval Level</Typography>
        <div className={classes.supportingDocuments}>
          <Typography>Supporting Documents:</Typography>
        </div>
        {supportDocs.length > 0 && (
          <div className={classes.documentListAllignment}>
            {supportingDocumentsList}
          </div>
        )}
        {data?.length > 0 && (
          <Slider className={classes.stepHorizontal} {...settings}>
            {approvalSteps}
          </Slider>
        )}
        {data?.length === 0 && (
          <div className={classes.noApprovalLevels}>
            <Typography>No Approval levels</Typography>
          </div>
        )}
      </Collapse>
    </div>
  );
};

export default withStyles(styles)(DocumentApprovalStatus);
