import React, { useState } from 'react';
import {
  Button,
  Typography,
  TextareaAutosize,
  CircularProgress,
  IconButton,
  Dialog,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import useRemoveDocument from 'studio/components/ManageOffers/hooks/useRemoveDocument';
import { Close as CloseIcon } from '@mui/icons-material';
import useIsMobile from '../../hooks/useIsMobile';
import ErrorIndicator from 'studio/oldJavascripts/components/Pages/Project/RejectI9/ErrorIndicator';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    maxHeight: 600,
    minHeight: 400,
    minWidth: 500,
    padding: '6px 10px',
  },
  mobileRoot: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#f7fcfc',
    padding: '20px 50px',
  },
  header: {
    textAlign: 'center',
    color: '#262626',
    fontSize: '20px !important',
    fontWeight: '700 !important',
    marginTop: '14px !important',
  },
  mobileHeader: {
    color: '#0000FF',
    fontSize: '18px !important',
    fontWeight: 400,
  },
  description: {
    display: 'inline-block',
    color: '#646464',
    fontSize: '14px !important',
    marginBottom: '14px !important',
    marginTop: '8px !important',
    textAlign: 'center',
    maxWidth: '69%',
  },
  mobileDescription: {
    color: '#646464',
    fontSize: 14,
    marginBottom: '20px',
    marginTop: '20px',
  },
  noteLength: {
    color: '#8D8D8D',
    marginLeft: '35px',
  },

  closeBtn: {
    fontWeight: '500 !important',
    background: 'transparent !important',
    color: '#0000FF',
    border: '1px solid #0000FF',
    textTransform: 'none !important',
    minWidth: '91px',
    borderRadius: 2,
    marginRight: '20px !important',
  },
  btn: {
    height: 40,
    borderRadius: '2px',
    textTransform: 'capitalize',
    fontWeight: '700 !important',
    fontSize: '14px !important',
  },
  btnContained: {
    backgroundColor: '#0000FF',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1A55FD',
    },
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '48px',
  },
  noteTextArea: {
    minWidth: 400,
    background: '#F4F4F4',
    border: 'none',
    padding: 10,
    justifyContent: 'center',
    marginLeft: '35px',
  },
  mobileTextArea: {
    width: '100%',
    borderRadius: 2,
    border: '1 solid #b0bec5',
    backgroundColor: '#ffffff',
    padding: '10px',
    color: '#333',
    fontSize: 14,
  },
  closeIcon: {
    position: 'absolute !important',
    top: '10px',
    right: '10px',
  },
  mobileButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  headerContainer: {
    textAlign: 'center',
  },
}));

const RemoveDocumentModal = props => {
  const [removeDocument, { loading }] = useRemoveDocument();

  const { documentId, onClose } = props;
  const classes = useStyles();
  const [note, setNote] = useState('');
  const [error, setError] = useState(null);
  const isMobile = useIsMobile();

  const variables = {
    documentId: documentId,
    note: note,
  };

  const handleSubmit = () => {
    removeDocument({ variables })
      .then(({ data }) => {
        const { removeDocument } = data;
        if (removeDocument) {
          onClose(true);
        }
      })
      .catch(err => {
        setError(err);
      });
  };

  const handleClose = () => {
    onClose();
  };

  const handleNote = value => {
    if (value.length <= 500) {
      setNote(value);
    }
  };

  if (!documentId) return null;
  return (
    <Dialog
      open={true}
      fullScreen={isMobile}
      data-test-id={`remove-document-modal`}
    >
      <div className={isMobile ? classes.mobileRoot : classes.dialogRoot}>
        <div className={classes.headerContainer}>
          <Typography
            variant="h2"
            className={isMobile ? classes.mobileHeader : classes.header}
          >
            Remove Document
          </Typography>
          {!isMobile && (
            <IconButton
              onClick={handleClose}
              aria-label="close"
              className={classes.closeIcon}
            >
              <CloseIcon />
            </IconButton>
          )}
          <Typography
            className={
              isMobile ? classes.mobileDescription : classes.description
            }
          >
            Are you sure you want to remove this document? This Cannot be
            undone.
          </Typography>
        </div>
        <TextareaAutosize
          className={isMobile ? classes.mobileTextArea : classes.noteTextArea}
          aria-label="maximum height"
          minRows={8}
          placeholder="Please add a short explanation for removing this document"
          value={note}
          onChange={e => handleNote(e.target.value)}
          data-test-id={`remove-document-note`}
        />
        <p className={classes.noteLength}>{note?.length}/500</p>
        <div
          className={
            isMobile ? classes.mobileButtonContainer : classes.btnContainer
          }
        >
          <Button
            className={classes.btn + ' ' + classes.closeBtn}
            onClick={handleClose}
            data-test-id={`remove-document-modal-close`}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className={classes.btn + ' ' + classes.btnContained}
            disabled={note.length === 0 || loading}
            onClick={() => handleSubmit()}
            data-test-id={`remove-document-modal-submit`}
            color="primary"
            variant="contained"
          >
            {loading ? (
              <>
                Processing... <CircularProgress size={20} />
              </>
            ) : (
              'Yes, I am sure'
            )}
          </Button>
        </div>
      </div>
      {error && (
        <ErrorIndicator message="Oops! Something went wrong. Please try after some time or contact support" />
      )}
    </Dialog>
  );
};

export default RemoveDocumentModal;
