import React, { useState } from 'react';
import Flexbox from 'flexbox-react';
import { makeStyles } from '@mui/styles';

import DocumentModal from './DocumentModal';
import RemoveDocumentModal from './RemoveDocumentModal';
import Notification from 'studio/components/OfferPage/OfferDrafts/Notification';
import Documents from './Documents';

const useStyles = makeStyles({
  headerContainer: {
    flex: '1 1 auto',
    margin: '0 5px 10px',
    padding: '5px',
    background: '#ffffff',
  },
});

const OfferDocumentsList = props => {
  const {
    offerId,
    width = '100%',
    showHeader = false,
    expandedWorkflow,
    actionClickHandler,
    hirerCanSendDocuments,
    handleStatusChange,
  } = props;

  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [previewDocumentId, setPreviewDocumentId] = useState(null);
  const [removeDocumentId, setRemoveDocumentId] = useState(null);
  const [isW9, setIsW9] = useState(null);
  const [isExt, setIsExt] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [reloadDocuments, setReloadDocuments] = useState(false);

  const previewDocument = (id, w9, ext) => {
    if (!id) return null;

    setPreviewDocumentId(id);
    setIsW9(w9);
    setIsExt(ext);
    setModalOpen(true);
  };

  const removeDocument = id => {
    if (!id) return null;

    setRemoveDocumentId(id);
    setRemoveModalOpen(true);
  };

  const handleRemoveDocumentModalClose = value => {
    setRemoveModalOpen(false);
    setRemoveDocumentId(null);
    if (value) {
      setReloadDocuments(true);
      setSnackOpen(true);
      setMessage('Document removed successfully');
      setReloadDocuments(false);
    }
  };

  const handleSnackOpen = () => {
    setSnackOpen(false);
  };

  return (
    <Flexbox flexDirection="column" width={width}>
      {modalOpen && (
        <DocumentModal
          offerId={offerId}
          documentId={previewDocumentId}
          isW9={isW9}
          isExternal={isExt}
          onClose={() => setModalOpen(false)}
        />
      )}

      {removeModalOpen && (
        <RemoveDocumentModal
          offerId={offerId}
          documentId={removeDocumentId}
          onClose={value => handleRemoveDocumentModalClose(value)}
        />
      )}
      <Notification
        message={message}
        open={snackOpen}
        autoHide={2000}
        setOpen={handleSnackOpen}
        severity={'success'}
      />
      {showHeader && (
        <div className={classes.headerContainer}>Offer Documents</div>
      )}
      <Flexbox flexDirection="row" flexWrap="wrap">
        <Documents
          offerId={offerId}
          expandedWorkflow={expandedWorkflow}
          actionClickHandler={actionClickHandler}
          hirerCanSendDocuments={hirerCanSendDocuments}
          onPreviewDocument={previewDocument}
          removeDocument={removeDocument}
          handleStatusChange={handleStatusChange}
          reloadDocuments={reloadDocuments}
        />
      </Flexbox>
    </Flexbox>
  );
};

export default OfferDocumentsList;
