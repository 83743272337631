import React from 'react';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';

const styles = {
  root: {
    borderRadius: '3px',
    margin: '20px auto',
  },
  overflowHidden: { overflow: 'hidden' },
  overflowInitial: { overflow: 'initial' },
  minimal: { maxWidth: '500px', margin: 'auto' },
  full: { margin: '0 auto', minHeight: '300px' },
  login: {
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
  },
  medium: { maxWidth: '720px' },
  shadow: {
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
  },
  accentBottom: {
    position: 'relative',
    '&:after': {
      backgroundColor: [
        '#cccccc',
        '-o-linear-gradient(top, #cccccc, #d8d8d8)',
        'linear-gradient(top, #cccccc, #d8d8d8)',
      ],
      backgroundImage: [
        '-webkit-linear-gradient(top, #cccccc, #d8d8d8)',
        '-moz-linear-gradient(top, #cccccc, #d8d8d8)',
        '-ms-linear-gradient(top, #cccccc, #d8d8d8)',
      ],
      content: "''",
      position: 'absolute',
      bottom: '-7px',
      left: '0px',
      right: '0px',
      height: '7px',
    },
  },
  outline: { border: '1px solid #e6e7ea' },
  signer: { width: '100%', marginLeft: '25px', marginRight: '25px' },
};

const Box = props => {
  const {
    classes,
    children,
    overflow_hidden,
    overflow_initial,
    minimal,
    full,
    login,
    medium,
    noShadow,
    accentBottom,
    outline,
    signer,
    className,
    'data-test-id': dataTestId,
  } = props;
  const rootClass = classNames(classes.root, className, {
    [classes.overflowHidden]: overflow_hidden,
    [classes.overflowInitial]: overflow_initial,
    [classes.minimal]: minimal,
    [classes.full]: full,
    [classes.login]: login,
    [classes.medium]: medium,
    [classes.shadow]: !noShadow,
    [classes.accentBottom]: accentBottom,
    [classes.outline]: outline,
    [classes.signer]: signer,
  });
  return (
    <div className={rootClass} data-test-id={dataTestId}>
      {children}
    </div>
  );
};

export default withStyles(styles)(Box);
