import gql from 'graphql-tag';

export default gql`
  mutation replaceNonEmployee(
    $projectId: Int!
    $deletedUserId: Int!
    $replacementUserId: Int!
  ) {
    replaceNonEmployee(
      projectId: $projectId
      deletedUserId: $deletedUserId
      replacementUserId: $replacementUserId
    )
  }
`;
