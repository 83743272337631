import React, { Component } from 'react';
import Box from '../../Base/Box';
import Button from '../../Base/Button';
import NavBar from '../../Base/NavBar';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Text from '../../Base/Text';
import Loader from 'common/components/Loader';

import HomeIcon from 'common/icons/Home';
// HoC
import withApi from 'common/hoc/withApi';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

import BoxParagraphItem from 'common/oldJavascripts/components/Base/Box/BoxParagraphItem';

const ParagraphItem = BoxParagraphItem;

const styles = () => ({
  homeIconContainer: {
    display: 'grid',
    placeContent: 'center',
    background: 'white',
    padding: '25px',
  },
  homeIcon: {
    height: '300px',
  },
  buttons: {
    padding: 15,
  },
});

class ChangeApp extends Component {
  static mutators = {
    project: {
      info: function(_, related) {
        const params = related['/router/params'];
        return {
          id: '/projects/' + params.projectId,
        };
      },
    },
    me: {
      info: function(_, related) {
        return {
          id: `/me`,
        };
      },
    },
  };

  render() {
    const {
      project: projectQuery = {},
      me: meQuery = {},
      classes,
    } = this.props;
    const {
      data: { other_app_url: otherAppUrl, id: projectId } = {},
      status: projectQueryStatus,
    } = projectQuery || {};
    const { data: { is_admin: isAdmin } = {}, status: meQueryStatus } =
      meQuery || {};
    const projectIsLoading = projectQueryStatus === 'loading';
    const meIsLoading = meQueryStatus === 'loading';

    const onClick = () => {
      const redirect = `${otherAppUrl}${
        isAdmin ? `/projects/${projectId}` : ''
      }`;
      window.location.href = redirect;
    };

    // Cant determine messaging without me query
    if (meIsLoading) return <Loader />;
    return (
      <LayoutContent data-test-id="ChangeApp-root">
        <Box>
          <div className={classes.homeIconContainer}>
            <HomeIcon
              className={classes.homeIcon}
              data-test-id="ChangeApp-icon"
            />
          </div>
          <ParagraphItem
            align={'center'}
            title={true}
            data-test-id="ChangeApp-description1"
          >
            {!isAdmin && 'Looks like your Start+ project has moved.'}
            {isAdmin &&
              'This project has open offers in v1.0. You must complete or archive the offers in 1.0 before you can convert to 1.5'}
          </ParagraphItem>
          {!isAdmin && (
            <ParagraphItem
              align={'center'}
              data-test-id="ChangeApp-description2"
            >
              Click <Text bold={true}>"New Location"</Text> to go to the new
              project location.
            </ParagraphItem>
          )}
          <BoxItem>
            <NavBar>
              {projectIsLoading && <Loader />}
              {!projectIsLoading && (
                <React.Fragment>
                  <NavBar.Item tertiary={true} className={classes.buttons}>
                    <Button
                      secondary={true}
                      to="/"
                      data-test-id="ChangeApp-goHomeButton"
                    >
                      {isAdmin && 'Stay in 1.5'}
                      {!isAdmin && 'Go Back'}
                    </Button>
                  </NavBar.Item>
                  <NavBar.Item tertiary={true} className={classes.buttons}>
                    <Button
                      important={true}
                      onClick={onClick}
                      data-test-id="ChangeApp-goTo1.0Button"
                    >
                      {isAdmin && 'Go to 1.0 project'}
                      {!isAdmin && 'New Location'}
                    </Button>
                  </NavBar.Item>
                </React.Fragment>
              )}
            </NavBar>
          </BoxItem>
        </Box>
      </LayoutContent>
    );
  }
}

export default compose(withStyles(styles), withApi)(ChangeApp);
