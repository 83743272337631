import React, { Component } from 'react';
import withApi from 'common/hoc/withApi';
import MainHeader from '../../../Base/MainHeader';
import NavItem from './NavItem';
import DOMPurify from 'dompurify';
import HomeIcon from 'common/images/HomeIcon.svg';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

const StyledTyprography = styled(Typography)({
  marginLeft: '5px',
  marginTop: '3px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
});

const Navbar = styled('nav')({
  float: 'left',
  maxHeight: '61px',
  display: 'flex',
});

class MainNav extends Component {
  static queries = {
    me: {
      info() {
        return {
          id: '/me',
        };
      },
    },
  };

  render() {
    const sanitizeMe = this.props.me.data || {};
    const me = sanitizeMe;
    DOMPurify.sanitize(sanitizeMe);
    DOMPurify.sanitize(me);
    const {
      is_admin: isAdmin,
      is_update_password_required: isUpdatePasswordRequired,
      is_password_expired: isPasswordExpired,
      is_prodco_admin,
      is_super_admin: isSuperAdmin,
      can_access_projects: canAccessProjects,
      can_access_offers: canAccessOffers,
      producer_id: producerId,
      producer_name: producerName,
    } = me;
    const isProdcoAdmin = is_prodco_admin && isAdmin;
    const libraryLinkTo = `library${isProdcoAdmin ? '-fields' : ''}`;

    if (isProdcoAdmin) {
      localStorage.setItem('producerId', producerId);
      localStorage.setItem('producerName', producerName);
    }

    if (isUpdatePasswordRequired || isPasswordExpired) {
      return <MainHeader.Nav />;
    }

    return (
      <Navbar>
        {canAccessProjects && (
          <NavItem key="projects" to="projects" data-test-id="MainNav-projects">
            <img src={HomeIcon} alt="Home" />{' '}
            <StyledTyprography>Projects</StyledTyprography>
          </NavItem>
        )}
        {isAdmin && (
          <NavItem
            key="library"
            to={libraryLinkTo}
            data-test-id="MainNav-library"
          >
            Document Library
          </NavItem>
        )}
        {isSuperAdmin && (
          <NavItem
            key="global-permissions"
            to="global-permissions"
            data-test-id="MainNav-adminUsers"
          >
            Manage Users
          </NavItem>
        )}
        {canAccessOffers && (
          <NavItem key="offers" to="onboarding" data-test-id="MainNav-myOffers">
            <img src={HomeIcon} alt="Home" />{' '}
            <StyledTyprography>My Offers</StyledTyprography>
          </NavItem>
        )}
        {(isSuperAdmin || isAdmin) && (
          <NavItem key="tools" to="tools" data-test-id="MainNav-tools">
            Tools
          </NavItem>
        )}
      </Navbar>
    );
  }
}

export default withApi(MainNav);
