import React, { useState } from 'react';
import { Select } from '@mui/material';
import { Dialog, Grid, Typography, Button, MenuItem } from '@mui/material';
import { CloseSharp as CloseIcon } from '@mui/icons-material';

const ConditionSelectorModal = props => {
  const {
    modalOpen,
    directoryIndex,
    fieldId,
    value,
    templateFields,
    onClose,
    onChange,
    classes,
  } = props;

  const [selectedField, setSelectedField] = useState(fieldId);
  const [selectedValue, setSelectedValue] = useState(value);

  const changeSelectedField = event => {
    setSelectedField(event.target.value);
    setSelectedValue(null);
  };

  const changeSpecifiedValue = event => {
    setSelectedValue(event.target.value);
  };

  const noOptions = <MenuItem disabled> No Options </MenuItem>;

  const fieldOptions = () => {
    if (templateFields?.length === 0) return noOptions;
    return templateFields.map(({ displayName, id }) => (
      <MenuItem key={id} value={id}>
        {displayName}
      </MenuItem>
    ));
  };

  const fieldValues = () => {
    const field = templateFields.find(({ id }) => id === selectedField);
    if (!field) return noOptions;
    const { radioGroupItems = [] } = field;
    if (radioGroupItems?.length === 0) return noOptions;
    return radioGroupItems.map(({ name, value }) => (
      <MenuItem key={name} value={name}>
        {value}
      </MenuItem>
    ));
  };

  return (
    <Dialog
      open={modalOpen}
      maxWidth="xs"
      classes={{ paper: classes.dialogPaper }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">Studio+ Directory Condition</Typography>
        <CloseIcon className={classes.closeIcon} onClick={onClose} />
      </Grid>
      <Typography variant="body1" className={classes.modalText}>
        When the condition is met, the completed document will be sent to this
        destination folder.
      </Typography>
      <Grid container direction="row" alignItems="center">
        <Typography variant="h5">Document Field: </Typography>
        <Select
          variant="standard"
          value={selectedField}
          onChange={changeSelectedField}
          className={classes.select}
        >
          {fieldOptions()}
        </Select>
      </Grid>
      {selectedField && (
        <Grid container direction="row" alignItems="center">
          <Typography variant="h5">Has Specified Value: </Typography>
          <Select
            variant="standard"
            value={selectedValue}
            onChange={changeSpecifiedValue}
            className={classes.select}
          >
            {fieldValues()}
          </Select>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          variant="outlined"
          className={classes.cancelButton}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          className={classes.saveButton}
          disabled={!selectedField || !selectedValue}
          onClick={() => {
            onChange(directoryIndex, selectedField, selectedValue);
            onClose();
          }}
        >
          Save
        </Button>
      </Grid>
    </Dialog>
  );
};

export default ConditionSelectorModal;
