import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import OfferForm from './OfferForm';
import Loader from 'common/components/Loader';
import CrewMemberReviewerList from './CrewMemberReviewerList';
import { withStyles } from '@mui/styles';
import { graphql } from 'react-apollo';
import { compose } from 'redux';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import withApi from 'common/hoc/withApi';
import withPermissionProtection from 'common/hoc/withPermissionProtection';
import moment from 'moment';
import qs from 'qs';
import getNormalizedMutationPayload from './helpers/getNormalizedMutationPayload';
import classNames from 'classnames';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import { Mutations } from 'common/apollo';
import useOffers from './hooks/useOffers';
import useOfferDefaults from './hooks/useOfferDefaults';
import formatOfferData from './helpers/formatOfferData';

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'minmax(300px, 400px) 1fr',
    gridTemplateRows: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  crewMemberName: {
    marginTop: 4,
  },
  crewMemberContactInfo: {
    lineHeight: '1.2',
  },
  withCrewMemberSidebar: {
    gridTemplateAreas: `
        "crewMembers content"`,
  },
  withoutCrewMemberSidebar: {
    gridTemplateAreas: `
        "content content"`,
  },
  crewMembers: {
    gridArea: 'crewMembers',
    margin: '20px',
    padding: 10,
    marginRight: 0,
    boxSizing: 'content-box',
    position: 'sticky',
    top: 20,
  },
});

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss Z';

const EditOffer = props => {
  const {
    classes,
    invalidateByPatterns,
    mutate,
    popSnackbarNotification,
    pushSnackbarNotification,
    projectId,
    routerQuery: { action: routerQueryAction, ids: offerIdsString },
    routerParams: { offerId },
    history,
  } = props;
  const viewingVersionedOffer = routerQueryAction === 'version';
  const offerIds = `${offerIdsString}`.split(',');
  const [
    isReimbursementChangeAlertDisplayed,
    setIsReimbursementChangeAlertDisplayed,
  ] = useState(false);
  const [configurationLoaded, setConfigurationLoaded] = useState(false);
  const [formData, setFormData] = useState({});
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const { data: offerDefaults } = useOfferDefaults(projectId);
  const { data: offersData = [], loading } = useOffers(projectId, offerIds);
  const { nodes: offers = [] } = offersData;

  if (loading || !offers || !offerDefaults) return <Loader />;

  const reviewerQueue = offers.filter(
    ({ status }) => status === 'draft_review',
  );
  const reviewedOffers = offers.filter(
    ({ status }) => status !== 'draft_review',
  );
  const offerInReview = offers.find(({ id }) => id === offerId) || {};
  const { projectUser: { id: userId = '', profile = {} } = {} } = offerInReview;
  const { firstName = '', lastName = '', phone = '', email = '' } =
    profile || {};
  const { backdateLimit } = offerDefaults;
  const { offerDate: offerDateFormData = {} } = formData || {};
  const isStartDateBeforeBackDateLimit = (() => {
    if (backdateLimit == null) return false;
    const { startDateObject } = offerDateFormData;
    if (!startDateObject) return false;
    const daysSinceStartDate = moment().diff(startDateObject, 'days');
    return daysSinceStartDate > backdateLimit;
  })();

  const checkUpdatedDefaultReimbursements = () => {
    const {
      status: offerStatus,
      reimbursementsUpdated = false,
    } = offerInReview;
    if (
      offerStatus === 'success' &&
      reimbursementsUpdated &&
      !isReimbursementChangeAlertDisplayed
    ) {
      setIsReimbursementChangeAlertDisplayed(true);
      pushSnackbarNotification({
        message: "This project's default reimbursement settings have changed.",
        variant: SnackbarVariants.WARNING,
        duration: 6000,
      });
    }
  };

  const initializeOfferForm = () => {
    const formattedOfferData = formatOfferData(
      offerInReview,
      offerDefaults,
      false,
    );

    const { sendDate } = offerInReview;
    let sendDateObject = (sendDate && moment(sendDate, DATE_FORMAT)) || null;
    const sendDateIsInPast =
      sendDateObject && sendDateObject.isBefore(moment(), 'day');

    setFormData(formattedOfferData);

    if (sendDate && sendDateIsInPast) {
      pushSnackbarNotification({
        message:
          "The saved send date was in the past so it has been changed to today's date. Send date must be today or future date.",
        variant: SnackbarVariants.WARNING,
        duration: 6000,
      });
    }
  };

  // Function from Create Offer Form
  const isValidScaleRates = (scaleRates = {}) => {
    return Object.keys(scaleRates).some(k => {
      return parseInt(scaleRates[k]) > 0;
    });
  };

  // these parameters are coming from the OfferConfirmationModal
  const confirmOffer = (
    createOfferScaleRates = null,
    isUnionWeeklySchedule,
  ) => {
    const loadingMessage = 'Submitting Offer Now...';
    const { newScaleRates: scaleRates = {} } = formData;

    let modifiedScaleRates = isValidScaleRates(scaleRates) ? scaleRates : {};
    delete modifiedScaleRates?.__typename;
    delete modifiedScaleRates?.scaleRateHash;
    delete formData?.termsOfHire?.fringeContract?.totalFringePercentage;
    let toe = formData?.termsOfEmployment;
    delete toe?.guaranteedHoursDaily;
    delete toe?.guaranteedHoursWeekly;
    const isCanada = offerDefaults?.countryCode === 'CA';

    let modifiedFormData = {
      ...formData,
      termsOfEmployment: {
        ...toe,
        scaleRates: isCanada ? {} : modifiedScaleRates,
        isTermsOfEmploymentV2:
          isUnionWeeklySchedule ||
          formData?.termsOfEmployment?.isTermsOfEmploymentV2,
      },
    };
    const variables = getNormalizedMutationPayload(modifiedFormData);

    // Additional processing of mutation payload
    variables.id = parseInt(offerId, 10);
    variables.projectId = projectId;
    variables.crew = [parseInt(userId)];

    popSnackbarNotification();
    pushSnackbarNotification({
      message: loadingMessage,
      variant: SnackbarVariants.INFO,
    });
    mutate({
      variables,
    })
      .then(({ data, message = '' }) => {
        setSubmitInProgress(true);
        popSnackbarNotification({
          message: loadingMessage,
          popAllMatching: true,
        });
        if (message.length) {
          pushSnackbarNotification({
            message: `There was an error submitting the offer${
              !message ? '.' : `: ${message}.`
            }`,
            variant: SnackbarVariants.ERROR,
          });
          return;
        }
        pushSnackbarNotification({
          message: 'Offer successfully submitted',
          variant: SnackbarVariants.SUCCESS,
        });
        invalidateByPatterns([
          `projects/${projectId}/offers`,
          `projects/${projectId}/reviews`,
        ]);
        const currentOfferIndexInQueue = offerIds.indexOf(offerId);
        const nextOfferId = offerIds[currentOfferIndexInQueue + 1];
        // If there are remaining offers in the reviewer
        // queue, load in the next offer
        if (nextOfferId) {
          history.push({
            pathname: `/projects/${projectId}/offers/${nextOfferId}/edit`,
            search: qs.stringify({ ids: offerIds }),
          });
        } else {
          history.push(`/projects/${projectId}/offers`);
        }
      })
      .catch(err => {
        setSubmitInProgress(false);
        const { graphQLErrors, message } = err;
        graphQLErrors.forEach(console.warn);
        popSnackbarNotification({
          message: loadingMessage,
          popAllMatching: true,
        });
        pushSnackbarNotification({
          message: `There was an error submitting the offer${
            !message ? '.' : `: ${message}.`
          }`,
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  if (!configurationLoaded) {
    setConfigurationLoaded(true);
    checkUpdatedDefaultReimbursements();
    initializeOfferForm();
  }

  const workScheduleCode = offerInReview?.termsOfHire?.workSchedule?.code;

  return (
    <div
      className={classNames(classes.root, {
        [classes.withCrewMemberSidebar]: !viewingVersionedOffer,
        [classes.withoutCrewMemberSidebar]: viewingVersionedOffer,
      })}
    >
      {!viewingVersionedOffer && (
        <CrewMemberReviewerList
          className={classes.crewMembers}
          reviewerQueue={reviewerQueue}
          reviewedOffers={reviewedOffers}
          offerIdInReview={offerId}
        />
      )}
      <OfferForm
        headerTitle="Review Offer"
        headerContent={
          <React.Fragment>
            <Typography
              variant="h6"
              data-test-id="EditOffer-crewMember"
              className={classNames(
                classes.crewMemberName,
                classes.crewMemberContactInfo,
              )}
            >{`Crew Member: ${firstName} ${lastName}`}</Typography>
            {!!phone && (
              <Typography
                variant="h6"
                data-test-id="EditOffer-phoneNumber"
                className={classes.crewMemberContactInfo}
              >{`Phone: ${phone}`}</Typography>
            )}
            <Typography
              variant="h6"
              data-test-id="EditOffer-email"
              className={classes.crewMemberContactInfo}
            >
              {`Email: ${email}`}
            </Typography>
          </React.Fragment>
        }
        onSubmit={confirmOffer}
        submitInProgress={submitInProgress}
        formData={formData}
        isReviewOffer
        isTermsOfHireDisabled={isStartDateBeforeBackDateLimit}
        setFormData={setFormData}
        workScheduleCode={workScheduleCode}
      />
    </div>
  );
};

export default compose(
  withPermissionProtection(['can_review_offers', 'can_create_offers']),
  withSnackbarNotification,
  withApi,
  graphql(Mutations.SubmitOffer),
  withStyles(styles),
)(EditOffer);
