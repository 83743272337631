import React, { Component } from 'react';
import classNames from 'classnames';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import FieldsOverlay from './FieldsOverlay';
import ActionBar from './ActionBar';
import DocumentImage from './DocumentImage';
import MobileDocumentPageNavigation from 'mobile/components/DocumentSigner/DocumentSigner/MobileDocumentPageNavigation';

const styles = {
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'grid',
    marginTop: 60,
    gridTemplateRows: 'minMax(60px, 6.5%) 1fr',
    gridTemplateColumns: '100%',
    gridTemplateAreas: `
    "actionBar"
    "imageOverlay"`,
  },
  actionBar: {
    gridArea: 'actionBar',
  },
  contentContainer: {
    zIndex: 2,
    overflow: 'auto',
    background: '#eef3f6',
  },
  withSidebar: {
    display: 'grid',
    gridTemplateAreas: `
      "document sidebar"
    `,
    gridTemplateColumns: '70% 30%',
    gridTemplateRows: '100%',
  },
  documentImageContainer: {
    gridArea: 'document',
    overflow: 'auto',
    height: '100%',
    width: '100%',
  },
};

class DocumentViewer extends Component {
  state = {
    zoom: 1,
    page: 1,
    isLoading: true,
  };

  componentDidMount() {
    this.onNewDoc();
  }

  componentWillReceiveProps(nextProps) {
    const {
      currentDocument: { id: currId },
    } = this.props;
    const {
      currentDocument: { id: nextId },
    } = nextProps;
    if (currId !== nextId) this.onNewDoc();
  }

  setZoom = increment => {
    this.setState(({ zoom }) => ({ zoom: zoom + increment }));
  };

  getZoom = () => {
    // Calculate the zoom for the document which will have the document
    // at full width for the container
    const { currentDocument, withSidebar } = this.props;
    if (!currentDocument) return 1;

    const { width } = currentDocument;
    let containerWidth;
    if (withSidebar) {
      const { clientWidth } = this.__imageContainer;
      containerWidth = clientWidth;
    } else {
      const { clientWidth } = this.__docContainer;
      containerWidth = clientWidth;
    }
    return containerWidth / width;
  };

  resetZoom = () =>
    new Promise(resolve => {
      this.setState(() => ({ zoom: this.getZoom() }), resolve);
    });

  setPage = (increment, newPage) =>
    new Promise(resolve => {
      this.setState(
        ({ page }) => {
          if (newPage === page) {
            resolve();
            return;
          }
          return { isLoading: true, page: newPage || page + increment };
        },
        () => {
          this.resetZoom().then(() => setTimeout(resolve));
        },
      );
    });

  onCurrentImageLoaded = () => {
    this.scrollTo(0);
    this.setState({ isLoading: false });
  };

  scrollTo = top => (this.__imageContainer.scrollTop = top);

  setDocContainerRef = ref => {
    // Capture the dom ref so we can use it for scrolling
    this.__docContainer = ref;
  };

  setImageContainerRef = ref => {
    // Capture the dom ref for the image container to adjust the
    // zoom variable and width based on withSidebar prop
    this.__imageContainer = ref;
  };

  onNewDoc = () => {
    this.setState({ isLoading: true });
    this.setPage(null, 1);
  };

  render() {
    const { isLoading, page, zoom } = this.state;
    const {
      isMobile = false,
      classes: desktopClasses,
      mobileDocumentViewerStyles = {},
      currentDocument,
      getDocumentImageUrls,
      setCurrentDocument,
      actionButtons,
      prevDocId,
      nextDocId,
      renderField,
      throwFatalError,
      activeRoleId = null,
      activeRuleGroupId = null,
      className,
      actionBarProps = {},
      withSidebar = false,
      sideBar,
    } = this.props;
    const { actionButtons: additionalActionButtons } = actionBarProps || {};
    const classes = isMobile ? mobileDocumentViewerStyles : desktopClasses;
    const {
      images,
      _derived: { isComplete, isViewable } = {},
    } = currentDocument;
    const isFormComplete =
      (isComplete && images.length === 0) || isViewable === 0;

    return (
      <div className={classNames(classes.root, className)}>
        {isMobile && isFormComplete && (
          <Typography className={classes.formComplete}>
            Form is complete
          </Typography>
        )}
        {!isMobile && (
          <ActionBar
            {...actionBarProps}
            setPage={this.setPage}
            setZoom={this.setZoom}
            resetZoom={this.resetZoom}
            page={page}
            pageCount={currentDocument.pages}
            setCurrentDocument={setCurrentDocument}
            prevDocId={prevDocId}
            nextDocId={nextDocId}
            actionButtons={actionButtons || additionalActionButtons}
            withSidebar={withSidebar}
          />
        )}
        <div
          className={classNames(classes.contentContainer, {
            [classes.withSidebar]: withSidebar,
          })}
          ref={this.setDocContainerRef}
        >
          <div
            className={classes.documentImageContainer}
            ref={this.setImageContainerRef}
          >
            {isViewable !== 0 && (
              <DocumentImage
                doc={currentDocument}
                page={page}
                zoom={zoom}
                getDocumentImageUrls={getDocumentImageUrls}
                throwFatalError={throwFatalError}
                onLoad={this.onCurrentImageLoaded}
              />
            )}
            {!isLoading && (
              <FieldsOverlay
                currentDocument={currentDocument}
                page={page}
                zoom={zoom}
                renderField={renderField}
                activeRoleId={activeRoleId}
                activeRuleGroupId={activeRuleGroupId}
              />
            )}
            {isMobile && (
              <MobileDocumentPageNavigation
                setPage={this.setPage}
                page={page}
                pageCount={currentDocument.pages}
              />
            )}
          </div>
          {sideBar}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(DocumentViewer);
