import gql from 'graphql-tag';

export default gql`
  query project($id: Int!, $userRole: UserRole, $excludeEmployees: Boolean) {
    project(id: $id) {
      id
      users(
        active: true
        role: $userRole
        excludeEmployees: $excludeEmployees
      ) {
        id
        profile {
          firstName
          lastName
          middleName
          email
        }
        offers {
          id
          jobTitle
          status
        }
      }
    }
  }
`;
