import React, { useState, useEffect } from 'react';

// MATERIAL
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';

import { DatePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles(theme => ({
  datepicker: {
    minWidth: '49%',
  },
  label: {
    marginBottom: '5px',
    color: '#525252',
  },
  root: {
    '& .MuiFilledInput-root': {
      background: '#F4F4F4',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  input: {
    width: '100%',
  },
}));

const FiltersDatePicker = props => {
  const { upstreamFiltersDateChange, filterValues } = props;
  const formattedStartDate = filterValues['startDate'] || null;
  const formattedEndDate = filterValues['endDate'] || null;

  const [startDate, setStartDate] = useState(formattedStartDate);
  const [endDate, setEndDate] = useState(formattedEndDate);

  // Apparently @material-ui/pickers does not like null; have to set it to undefined if empty when setting a min date
  const hasMinDate = startDate || undefined;

  const onDateChange = (name, event) => {
    if (name === 'startDate') {
      setStartDate(event);
    } else {
      setEndDate(event);
    }

    upstreamFiltersDateChange(name, event);
  };

  // used to clear date values when reset filters is cleared
  useEffect(() => {
    if (Object.keys(filterValues)?.length === 0) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [filterValues]);

  const classes = useStyles();
  return (
    <>
      <InputLabel className={classes.label}>Start Date</InputLabel>
      <div className={classes.container}>
        <div className={classes.datepicker}>
          <InputLabel className={classes.label}>From:</InputLabel>
          <DatePicker
            autoOk
            clearable
            fullWidth
            format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            value={startDate}
            onChange={e => onDateChange('startDate', e)}
            className={classes.input}
          />
        </div>

        <div className={classes.datepicker}>
          <InputLabel className={classes.label}>To:</InputLabel>
          <DatePicker
            autoOk
            clearable
            size="small"
            fullWidth
            format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            value={endDate}
            minDate={hasMinDate}
            onChange={e => onDateChange('endDate', e)}
            className={classes.input}
          />
        </div>
      </div>
    </>
  );
};

export default FiltersDatePicker;
