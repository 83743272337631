import React from 'react';
import domClasses from '../../utils/dom/classes.js';
import {
  AccountCircle as AccountCircleIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { withStyles } from '@mui/styles';

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: '32px auto 32px',
    gridTemplateRows: '100%',
    gridTemplateAreas: `
    "avatar name downIcon"`,
    cursor: 'pointer',
    alignItems: 'center',
  },
  avatar: {
    gridArea: 'avatar',
    justifySelf: 'start',
  },
  name: {
    fontWeight: 700,
    gridArea: 'name',
  },
  downIcon: {
    gridArea: 'downIcon',
    justifySelf: 'end',
  },
};

const Dropdown = class extends React.Component {
  render() {
    const {
      title,
      children,
      header,
      hideAvatar,
      classes,
      'data-test-id': dataTestId,
    } = this.props;

    const rootClass = domClasses.set({
      dropdown: true,
      'dropdown--header': header,
    });

    const togglerClasses = domClasses.set({
      [classes.root]: true,
      'dropdown-toggler--header': header,
    });

    return (
      <div className={rootClass} data-test-id={dataTestId}>
        <div className={togglerClasses}>
          {!hideAvatar && <AccountCircleIcon className={classes.avatar} />}
          <span className={classes.name}>{title}</span>

          {header && <KeyboardArrowDownIcon className={classes.downIcon} />}
        </div>
        <div className="dropdown-content">{children}</div>
      </div>
    );
  }
};

Dropdown.Item = class extends React.Component {
  render() {
    var classes = null;

    classes = domClasses.set({
      'dropdown-item': true,
      'dropdown-item--destructive': this.props.destructive,
      'dropdown-item--container': this.props.container,
    });

    return (
      <div className={classes} onClick={this._click}>
        {this.props.children}
      </div>
    );
  }

  _click = event => {
    if (this.props.onClick) {
      event.preventDefault();
      this.props.onClick();
    }
  };
};

Dropdown.Title = class extends React.Component {
  render() {
    var classes = null;

    classes = domClasses.set({
      'dropdown-title': true,
      'dropdown-title--primary': this.props.primary,
      'dropdown-title--secondary': this.props.secondary,
    });

    return <div className={classes}>{this.props.children}</div>;
  }
};

export default withStyles(styles)(Dropdown);
