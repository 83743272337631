import collection from './collection';
import TRANSLATIONS from 'common/constants/en';

const translate = (...args) => {
  var lastArg = args[args.length - 1];
  var options =
    typeof lastArg === 'object' && lastArg !== null ? args.pop() : {};
  var keys = args.join('.').split('.');
  var translations = TRANSLATIONS[keys[0]];
  var result = null;

  options = options || {};

  if (options.hasOwnProperty('count')) {
    if (options.count === 0) {
      keys.push('zero');
    } else if (options.count === 1) {
      keys.push('one');
    } else {
      keys.push('other');
    }
  }

  result = collection.reduce(keys.slice(1), translations, function(trans, k) {
    if (trans) {
      return trans[k];
    }
  });

  if (!result) {
    return keys.join('.');
  }

  if (typeof result !== 'string') {
    throw new TypeError(
      'i18n key "' +
        keys.join('.') +
        '" is not a string, but of type "' +
        typeof result +
        '"',
    );
  }

  return result.replace(/%\{(\w+)\}/g, function(_, option) {
    return options[option];
  });
};

export default translate;
