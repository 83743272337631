import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Components

import BaseLayout from 'common/oldJavascripts/components/Pages/Layouts/Base';
import GlobalPermissionsIndex from 'admin/oldJavascripts/components/Pages/GlobalPermissions/index.js';

const GlobalPermissions = props => (
  <BaseLayout
    nonEmployee={true}
    data-test-id="GlobalPermissions-GlobalPermissionsLayout"
  >
    <Switch>
      <Route
        path="/global-permissions/permissions"
        render={() => <GlobalPermissionsIndex tab={1} />}
      />
      <Route
        path="/global-permissions/guild"
        render={() => <GlobalPermissionsIndex tab={2} />}
      />
      <Route path="*" component={GlobalPermissionsIndex} />
    </Switch>
  </BaseLayout>
);

export default GlobalPermissions;
