const sortListWithPage = (data, orderBy = 'name', direction = 'asc') => {
  const sortedData = [...data];
  if (direction === 'asc') {
    return sortedData.sort((a, b) => {
      const pageOrder = a['page'] <= b['page'];
      if (pageOrder && a[orderBy] < b[orderBy]) return -1;
      if (pageOrder && a[orderBy] > b[orderBy]) return 1;
      return 0;
    });
  }
  return sortedData.sort((a, b) => {
    const pageOrder = a['page'] <= b['page'];
    if (pageOrder && a[orderBy] < b[orderBy]) return 1;
    if (pageOrder && a[orderBy] > b[orderBy]) return -1;
    return 0;
  });
};

export default sortListWithPage;
