import React from 'react';
import { Button } from '@mui/material';

import Loader from 'common/components/Loader';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import Modal from 'common/oldJavascripts/components/Base/Modal';
import DocumentViewer from 'common/oldJavascripts/components/Shared/DocumentViewer';

import useOfferDocumentImages from './hooks/useOfferDocumentImages';

const DocumentModal = props => {
  const { offerId, documentId, isW9, isExternal, onClose } = props;

  // when we trigger modal for supporting-document
  // we need to know parent(document) id as well
  // therefore, we are passing documentId as an Array
  // Example:
  //   regular document: <DocumentModal documentId={123} />
  //   supporting document: <DocumentModal documentId={[123, 456]} />
  const isSupport = Array.isArray(documentId);
  const docId = isSupport ? documentId[0] : documentId;
  const suppDocId = isSupport ? documentId[1] : null;

  const {
    data: { w9, document, extDocs } = {},
    loading,
    error,
  } = useOfferDocumentImages(
    parseInt(offerId),
    docId,
    isW9,
    isExternal,
    isSupport,
  );

  if (!offerId || !docId) return null;

  let pages = document?.images;
  if (isW9) pages = w9?.images;
  if (isExternal) pages = extDocs?.images;
  if (isSupport) {
    const allSupportDocs = document?.supportingDocuments || [];
    const suppDoc = allSupportDocs.find(sd => sd.id === suppDocId);
    pages = suppDoc?.images;
  }

  return (
    <Modal size={pages?.length ? 'maximal' : null}>
      {loading && <Loader />}
      {error && (
        <div>
          <FailureAlert queryName="offerDocumentImages" />
          <Button onClick={onClose}>Close</Button>
        </div>
      )}
      {!loading && !error && (
        <DocumentViewer key="viewer" onClose={onClose} pages={pages} />
      )}
    </Modal>
  );
};

export default DocumentModal;
