import React from 'react';

// MUI
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

// Components
import Loader from 'common/components/Loader';

const useStyles = makeStyles(theme => ({
  contentRoot: {
    display: 'grid',
    height: '300px',
    justifyContent: 'center',
  },
}));

const PayrollValidationModal = ({ open = false } = {}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} fullWidth maxWidth={'sm'}>
      <div className={classes.contentRoot}>
        <Loader />
        <Typography>Validating Terms of Hire</Typography>
      </div>
    </Dialog>
  );
};

export default PayrollValidationModal;
