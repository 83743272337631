export default {
  isFunction(obj) {
    return Boolean(obj && obj.constructor && obj.call && obj.apply);
  },

  throttle(f, threshhold = 1) {
    let previous = null;
    let timeout = null;
    return () => {
      const now = Number(new Date());
      const args = arguments;
      const delay = previous && now < previous + threshhold ? threshhold : 0;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        previous = now;
        f.apply(null, args);
      }, delay);
    };
  },
};
