import React from 'react';

export default props => (
  <svg
    width={60}
    height={54}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M-2-4h64v64H-2z" />
      <path
        fill="white"
        fillRule="nonzero"
        d="M48 34.654H37.714V18.5H22.286v16.154H12L30 53.5z"
      />
      <path
        d="M5.326 6.803c0-.375-.132-.664-.395-.867-.264-.204-.74-.409-1.427-.616-.688-.207-1.234-.437-1.64-.691C1.085 4.14.696 3.504.696 2.719c0-.688.28-1.254.841-1.7.56-.445 1.288-.667 2.183-.667.593 0 1.123.109 1.588.328.464.218.83.53 1.095.934.266.405.399.853.399 1.345H5.326c0-.445-.14-.794-.419-1.046-.28-.252-.678-.378-1.198-.378-.484 0-.86.104-1.128.31-.268.208-.401.497-.401.868 0 .312.144.573.433.782.29.21.766.412 1.43.61.664.197 1.197.421 1.6.673.402.252.697.541.884.868.188.326.282.708.282 1.145 0 .711-.273 1.276-.818 1.696-.545.42-1.284.63-2.218.63a4.168 4.168 0 0 1-1.702-.343c-.517-.228-.92-.544-1.207-.946a2.358 2.358 0 0 1-.43-1.406h1.482c0 .484.16.86.48 1.125.32.266.78.398 1.377.398.516 0 .904-.104 1.164-.313.26-.21.39-.486.39-.83zM9.674 9H8.197V.469h1.477V9zm8.361-1.107c-.308.402-.736.707-1.283.914-.547.207-1.168.31-1.863.31-.715 0-1.348-.162-1.899-.486a3.24 3.24 0 0 1-1.274-1.392c-.299-.603-.454-1.307-.466-2.112v-.668c0-1.29.309-2.296.926-3.02C12.793.713 13.654.351 14.76.351c.949 0 1.703.234 2.261.703.56.468.895 1.144 1.008 2.027h-1.453c-.164-1.027-.76-1.541-1.787-1.541-.664 0-1.169.24-1.515.718-.345.478-.524 1.18-.536 2.106v.656c0 .922.195 1.638.583 2.148.389.51.929.765 1.62.765.758 0 1.297-.172 1.618-.516v-1.67H14.8V4.623h3.234v3.27zM26.432 9h-1.483l-3.803-6.053V9h-1.482V.469h1.482l3.815 6.076V.469h1.47V9zm11.513 0H36.47V5.209h-3.815V9h-1.482V.469h1.482v3.55h3.815V.47h1.476V9zm6.739-3.809H41.18v2.625h4.095V9h-5.578V.469h5.537v1.195H41.18V4.02h3.504V5.19zm4.933.522h-1.652V9h-1.483V.469h3c.985 0 1.745.22 2.28.662.535.441.802 1.08.802 1.916 0 .57-.137 1.048-.413 1.432-.275.385-.659.681-1.151.888l1.916 3.557V9h-1.588l-1.71-3.287zm-1.652-1.19h1.523c.5 0 .89-.126 1.172-.377.281-.252.422-.597.422-1.035 0-.457-.13-.81-.39-1.06s-.647-.38-1.163-.387h-1.564v2.86zm10.992.668h-3.504v2.625h4.096V9H53.97V.469h5.537v1.195h-4.055V4.02h3.504V5.19z"
        fill="white"
      />
    </g>
  </svg>
);
