import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Box from 'common/oldJavascripts/components/Base/Box';
import T from 'common/oldJavascripts/utils/i18n';
import { withRouter } from 'react-router';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';

import BoxParagraphItem from 'common/oldJavascripts/components/Base/Box/BoxParagraphItem';

var ParagraphItem = BoxParagraphItem;

const styles = theme => ({
  root: {},
  avatarContainer: {
    width: '100%',
    display: 'grid',
    backgroundColor: '#fff',
    height: '110px',
  },
});

const Profile = props => {
  const { match = {}, classes } = props;
  const { params = {} } = match || {};
  const { offerId } = params || {};

  return (
    <LayoutContent compact>
      <Box>
        <div className={classes.avatarContainer}>
          <AccountCircleIcon
            style={{
              placeSelf: 'center',
              height: '72px',
              width: 'auto',
              color: '#b3b9be',
            }}
          />
        </div>

        <ParagraphItem align="center" title={true}>
          {T('employee.onboarding.intro.profile.title')}
        </ParagraphItem>
        <ParagraphItem align="center">
          {T('Welcome!')}
          <br />
          {T('We just need to confirm a few details with you.')}
          <br />
          {T(
            "On the next screen we'll ask you some information that we'll need to finish your Start Packet.",
          )}
        </ParagraphItem>
        <ParagraphItem align="center">
          {T("We'll reuse this data to fill out the forms whenever possible.")}
        </ParagraphItem>
        <ParagraphItem align="center">{T("Let's get started!")}</ParagraphItem>
        <ParagraphItem align={'center'}>
          <RouterLink
            className="button"
            to={`/onboarding/offers/${offerId}/profile`}
          >
            {T('employee.onboarding.next_label')}
          </RouterLink>
        </ParagraphItem>
      </Box>
    </LayoutContent>
  );
};

export default compose(withRouter, withStyles(styles))(Profile);
