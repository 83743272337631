import React, { Component } from 'react';
import withApi from 'common/hoc/withApi';

import status from 'common/oldJavascripts/utils/react/status';
import Button from 'common/oldJavascripts/components/Base/Button';
import DepartmentForm from '../Form';
import NavBar from 'common/oldJavascripts/components/Base/NavBar';
import Loader from 'common/components/Loader';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import isEqual from 'lodash.isequal';

const styles = {
  submitButton: {
    color: '#FFFFFF',
    background: '#0000FF',
    textTransform: 'none',
    padding: '5px 20px',
    '&:hover': {
      background: '#1A55FD',
    },
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  cancelButton: {
    border: '1px solid #0000FF',
    color: '#0000FF',
    background: '#FFFFFF',
    padding: '5px 20px',
    textTransform: 'none',
    '&:hover': {
      background: '#FFFFFF',
    },
  },
};
class ItemForm extends Component {
  static queries = {
    project: {
      info(_, related) {
        const params = related['/router/params'];
        return {
          id: `/projects/${params.projectId}`,
        };
      },
    },
    departmentRules: {
      info(params, related) {
        const routerParams = related['/router/params'];

        return {
          id: `/v2/projects/${routerParams.projectId}/departments/${params.id}/department-rules`,
        };
      },
      stale(params, related) {
        const routerParams = related['/router/params'];

        return [
          `/v2/projects/${routerParams.projectId}/departments/${params.id}`,
        ];
      },
    },
  };

  dataBeforeEditsChecking = () => {
    const { department } = this.props;
    if (department.dataBeforeEdits) {
      department.dataBeforeEdits.code = department?.dataBeforeEdits?.code || '';
      department.dataBeforeEdits.id = department?.dataBeforeEdits?.id || '';
      department.dataBeforeEdits.loan_out_enabled =
        department?.dataBeforeEdits?.loan_out_enabled;
      department.dataBeforeEdits.loan_out_occupations =
        department?.dataBeforeEdits?.loan_out_occupations || {};
      department.dataBeforeEdits.locked = department?.dataBeforeEdits?.locked;
      department.dataBeforeEdits.master_department_id =
        department?.dataBeforeEdits?.master_department_id || '';
      department.dataBeforeEdits.max_allowed_bank_accounts =
        department?.dataBeforeEdits?.max_allowed_bank_accounts || 0;
      department.dataBeforeEdits.name = department?.dataBeforeEdits?.name || '';
      department.dataBeforeEdits.occupations =
        department?.dataBeforeEdits?.occupations;
      department.dataBeforeEdits.project_id =
        department?.dataBeforeEdits?.project_id || '';
      department.dataBeforeEdits.unions = department?.dataBeforeEdits?.unions;
    }
  };

  render() {
    const {
      detailSubDataType,
      enforceMask,
      accountMask,
      departmentRules = {},
      department = {},
      classes,
    } = this.props;
    const {
      data: {
        department_rules: departmentRulesProp = [],
        department_loan_out_rules: loanoutRulesProp = [],
      },
    } = departmentRules;

    // this is to check if dataBeforeEdits and data are same or not
    // to decide if update button should be disabled or not
    this.dataBeforeEditsChecking();

    const hasChanged = !isEqual(department.data, department.dataBeforeEdits);

    if (this._getStatus() === 'loading') {
      return <Loader />;
    }
    return (
      <DepartmentForm
        action={department.data._id + '/edit'}
        method="put"
        onSubmit={this._onSubmit}
        department={department}
        departmentRules={departmentRulesProp}
        loanoutRules={loanoutRulesProp}
        detailSubDataType={detailSubDataType}
        enforceMask={enforceMask}
        accountMask={accountMask}
      >
        <NavBar>
          <NavBar.Item>
            <Button
              data-test-id="ItemForm-updateButton"
              className={classes.submitButton}
              disabled={!hasChanged}
            >
              Update
            </Button>
          </NavBar.Item>
          <NavBar.Item>
            <span data-test-id="ItemForm-cancelButton">
              <Button className={classes.cancelButton} onClick={this._onCancel}>
                Cancel
              </Button>
            </span>
          </NavBar.Item>
        </NavBar>
      </DepartmentForm>
    );
  }

  _getStatus = () => {
    const props = this.props;
    return status([props.departmentRules]);
  };

  _onCancel = () => {
    this.props.onCancel();
  };

  _onSubmit = () => {
    const { onSubmit } = this.props;
    onSubmit();
  };
}

export default compose(withApi, withStyles(styles))(ItemForm);
