import React, { useEffect, useState } from 'react';

// HoC
import { withStyles } from '@mui/styles';
import { graphql } from 'react-apollo';
import { compose } from 'redux';

// push notification
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';

// GQL
import { Mutations } from 'common/apollo';

// MuiComponents
import { Button } from '@mui/material';

const styles = {
  button: {
    display: 'inline-flex',
    backgroundColor: '#0000FF',
    color: '#ffffff',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    '&:hover, &:focus': {
      outline: 'none',
      backgroundColor: '#1A55FD',
    },
    '&:active': {
      backgroundColor: '#000080',
    },
  },
};

const UserAccessButton = props => {
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    classes,
    producer,
    getLink,
    role,
    userStatus,
    pushSnackbarNotification,
    isReportAvailable,
  } = props;
  const getDownloadLink = () => {
    setIsDisabled(true);
    getLink({ producer, role, userStatus })
      .then(() => {
        pushSnackbarNotification({
          message: 'Report sent to your email successfully',
          variant: SnackbarVariants.SUCCESS,
        });
      })
      .catch(e => {
        const { graphQLErrors = [] } = e || {};
        const { message = '' } = graphQLErrors[0] || {};
        pushSnackbarNotification({
          message: `Failed to initialize Download report${
            message.length ? `: ${message}` : '.'
          }`,
          variant: SnackbarVariants.ERROR,
        });
      });
    setIsDisabled(false);
  };

  useEffect(() => {
    setIsDisabled(isReportAvailable);
  }, [isReportAvailable]);

  return (
    <Button
      onClick={getDownloadLink}
      className={classes.button}
      variant="contained"
      disabled={isDisabled}
      data-test-id="UserAccessButton-download"
    >
      Export to Excel
    </Button>
  );
};

const withUserAccessButton = graphql(Mutations.DownloadUserAccessReport, {
  props: ({ mutate }) => ({
    getLink: ({ producer, role, userStatus }) =>
      mutate({
        variables: {
          producerId: parseInt(producer),
          employeeRole: role.label,
          userStatus: userStatus.label,
        },
      }),
  }),
});

export default compose(
  withStyles(styles),
  withUserAccessButton,
  withSnackbarNotification,
)(UserAccessButton);
