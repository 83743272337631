import React from 'react';
import { makeStyles } from '@mui/styles';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 600,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function ErrorIndicator() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert severity="error">Oops! Something went wrong.</Alert>
    </div>
  );
}
