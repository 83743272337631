import gql from 'graphql-tag';

export default gql`
  mutation createPermissionPreset($name: String!, $permissions: [Int!]!) {
    createPermissionPreset(name: $name, permissions: $permissions) {
      name
      id
    }
  }
`;
