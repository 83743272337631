import React from 'react';
import { withStyles } from '@mui/styles';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
} from '@mui/material';
import { Done as SelectedIcon } from '@mui/icons-material';

const styles = theme => ({
  filterControl: {
    padding: 0,
    width: 300,
    height: 'auto',
    outline: 'none',
  },
});

const StatusMenu = props => {
  const { anchor, classes, onChange, onClose, statusFilter } = props;

  const flag = key => {
    const isSelected = statusFilter[key];
    return (
      <MenuItem
        value={key}
        selected={isSelected}
        onClick={() => onChange({ ...statusFilter, [key]: !isSelected })}
        data-test-id={`StatusMenu-toggle-${key}`}
      >
        {isSelected && (
          <ListItemIcon>
            <SelectedIcon />
          </ListItemIcon>
        )}
        {!isSelected && <span style={{ width: '40px' }} />}
        <ListItemText primary={key} />
      </MenuItem>
    );
  };

  return (
    <Menu
      id="role-menu-add-approver"
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={onClose}
      data-test-id="StatusMenu-root"
    >
      <ListItem
        className={classes.filterControl}
        button={false}
        disableGutters
      />
      <ListSubheader>Field Mapping</ListSubheader>
      {flag('mapped')}
      {flag('unmapped')}
      <ListSubheader>Visibility</ListSubheader>
      {flag('visible')}
      {flag('hidden')}
    </Menu>
  );
};

export default withStyles(styles)(StatusMenu);
