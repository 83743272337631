import React, { useEffect } from 'react';

// Components
import Select from 'react-select';
import Typography from '@mui/material/Typography';

// HoC
import { compose } from 'redux';
import { withStyles } from '@mui/styles';

// Utilities
import formatOptionLabel from '../formatOptionLabel';

const styles = theme => ({
  root: {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'max-content max-content 15px',
  },
  label: {
    gridColumn: 1,
    gridRow: 1,
  },
  payGuideLabel: {
    gridColumn: 2,
    gridRow: 1,
    placeSelf: 'end',
    padding: 5,
    gridAutoFlow: 'column',
    placeItems: 'center',
  },
  select: {
    gridColumn: '1 / -1',
    gridRow: 2,
  },
  icon: {
    height: '14px',
    width: 'auto',
  },
  error: {},
});

const WorkScheduleSelect = props => {
  const {
    onChange: upstreamOnChange,
    formData = {},
    data: { guilds = [], loading, error } = {},
    selectStyles,
    classes = {},
    disabled,
  } = props;
  const { workSchedule } = formData || {};
  const { value: workScheduleValue } = workSchedule || {};

  const options = () => {
    // Format options for react-select
    // Filter out duplicate work schedules
    const uniqueGuilds = guilds.filter(guild => {
      return (
        guilds.findIndex(
          guild2 => guild.scheduleCode === guild2.scheduleCode,
        ) === guilds.indexOf(guild)
      );
    });
    return uniqueGuilds.map(workSchedule => ({
      value: workSchedule?.scheduleCode,
      label: `${workSchedule?.scheduleCode} - ${workSchedule?.scheduleDescription}`,
      ...workSchedule,
    }));
  };
  // We only store the code in our formData but we need to supply the formatted option
  // as the selected value
  const value =
    options().find(({ value }) => workScheduleValue === value) || null;

  const onChange = workSchedule => {
    upstreamOnChange({
      workSchedule,
      occupation: null,
    });
  };

  useEffect(() => {
    if (formData?.workSchedule) return;
    let options = guilds.map(workSchedule => ({
      value: workSchedule?.scheduleCode,
      label: workSchedule?.scheduleDescription,
      ...workSchedule,
    }));
    if (options.length === 1) {
      onChange(options[0]);
    }
  }, [guilds, formData]); // eslint-disable-line react-hooks/exhaustive-deps
  // if we include upstreamOnChange in the dependency array, it will cause an infinite loop

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1">
        Work Schedule
      </Typography>
      <div data-test-id="WorkScheduleSelect-select" className={classes.select}>
        <Select
          styles={selectStyles}
          onChange={onChange}
          value={value}
          options={loading ? [] : options()}
          isLoading={loading}
          formatOptionLabel={formatOptionLabel('WorkScheduleSelect')}
          isDisabled={disabled}
        />
      </div>
      {error && (
        <Typography
          color="error"
          variant="caption"
          className={classes.error}
          data-test-id="WorkScheduleSelect-error"
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

export default compose(withStyles(styles))(WorkScheduleSelect);
