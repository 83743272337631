const truncateStringToFitLength = (length, str = '') => {
  if (str.length <= length) {
    return str;
  }

  const ellipsis = '...';
  const totalLength = length - ellipsis.length;
  const prefixLength = Math.ceil(totalLength / 2);
  const suffixLength = Math.floor(totalLength / 2);

  const prefix = str.substring(0, prefixLength);
  const suffix = str.substring(str.length - suffixLength);

  return prefix + ellipsis + suffix;
};

export default truncateStringToFitLength;
