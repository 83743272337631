export const CUSTOMER_SERVICE = {
  EMAIL: 'plus.support@castandcrew.com',
  PHONE: '+1 818 860-7770',
};

export const DATE_FORMAT = {
  DB: 'YYYY-MM-DD HH:mm:ss Z',
  UI: 'MM-DD-YYYY',
  UI_DATETIME: 'MM-DD-YYYY h:mm A',
};

export const DEFAULT_PER_PAGE = 20;

export default {
  CUSTOMER_SERVICE,
  DATE_FORMAT,
  DEFAULT_PER_PAGE,
};
