import React from 'react';
import config from 'common/oldJavascripts/config.js';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Box from 'common/oldJavascripts/components/Base/Box';
import Button from 'common/oldJavascripts/components/Base/Button';
import Header from 'common/oldJavascripts/components/Base/Header';
import Table from 'common/oldJavascripts/components/Base/Table';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import Pagination from 'common/oldJavascripts/components/Base/Pagination';
import Loader from 'common/components/Loader';
import UserPrivileges from 'common/oldJavascripts/utils/UserPrivileges.js';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

const { Row, Cell } = Table;

const InvitationRow = Relay.createClass({
  statics: {
    queries: {
      invitation: {
        info(params, related) {
          const routerParams = related['/router/params'];

          return {
            id: `/projects/${routerParams.projectId}/invitations/${params.id}`,
          };
        },
      },
    },
  },

  render() {
    const invitation = this.props.invitation.data;
    const privileges = new UserPrivileges(invitation.privileges);
    const { token: key } = invitation;

    return (
      <Row key={key}>
        <Cell rowHeader={true} doubleBorderBottom={true}>
          <span data-test-id={`Invitations-name-${key}`}>
            {invitation.first_name} {invitation.last_name}
          </span>
        </Cell>
        <Cell doubleBorderBottom={true}>
          <span data-test-id={`Invitations-phone-${key}`}>
            {invitation.phone}
          </span>
        </Cell>
        <Cell doubleBorderBottom={true}>
          <span data-test-id={`Invitations-email-${key}`}>
            {invitation.email}
          </span>
        </Cell>
        <Cell doubleBorderBottom={true}>
          <span data-test-id={`Invitations-hirer-${key}`}>
            {privileges.isHirer() ? 'true' : 'false'}
          </span>
        </Cell>
        <Cell doubleBorderBottom={true}>
          <span data-test-id={`Invitations-reviewer-${key}`}>
            {privileges.isReviewer() ? 'true' : 'false'}
          </span>
        </Cell>
        <Cell doubleBorderBottom={true}>
          <span data-test-id={`Invitations-approver-${key}`}>
            {privileges.isApprover() ? 'true' : 'false'}
          </span>
        </Cell>
        <Cell doubleBorderBottom={true}>
          <span data-test-id={`Invitations-verifier-${key}`}>
            {privileges.isVerifier() ? 'true' : 'false'}
          </span>
        </Cell>
        <Cell doubleBorderBottom={true}>
          <span data-test-id={`Invitations-archives-${key}`}>
            {privileges.isArchiver() ? 'true' : 'false'}
          </span>
        </Cell>
        <Cell doubleBorderBottom={true}>
          <span data-test-id={`Invitations-viewer-${key}`}>
            {privileges.isDocumentsViewer() ? 'true' : 'false'}
          </span>
        </Cell>
        <Cell doubleBorderBottom={true}>
          <span data-test-id={`Invitations-role-${key}`}>Non-employee</span>
        </Cell>
      </Row>
    );
  },
});

export default Relay.createClass({
  statics: {
    queries: {
      invitations: {
        info(_, related) {
          const params = related['/router/params'];
          const query = related['/router/query'];

          return {
            id: `/projects/${params.projectId}/invitations`,

            cursor: {
              page: query.page,
              per_page: query.per_page || config.DEFAULT_PER_PAGE,
            },
          };
        },
      },
    },
  },

  render() {
    const {
      routerParams: params,
      invitations: invitationsQuery = {},
    } = this.props;
    const { data: invitationsData = {}, status: invitationsStatus } =
      invitationsQuery || {};
    const { items: invitations = [] } = invitationsData || {};

    if (invitationsStatus === 'loading') return <Loader />;

    const rows = invitations.map(invitation => (
      <InvitationRow params={{ id: invitation.token }} key={invitation.token} />
    ));

    const content = () => (
      <Table fixed={false}>
        <Table.Header>
          <Cell header={true}>Name</Cell>
          <Cell header={true}>Phone</Cell>
          <Cell header={true} width={'320px'}>
            Email
          </Cell>
          <Cell header={true}>Hirer</Cell>
          <Cell header={true}>Reviewer</Cell>
          <Cell header={true}>Approver</Cell>
          <Cell header={true}>I-9 Verifier</Cell>
          <Cell header={true}>Archives</Cell>
          <Cell header={true}>Document Viewer</Cell>
          <Cell header={true}>Role</Cell>
        </Table.Header>
        {rows}
      </Table>
    );

    const pagination = () => (
      <Pagination
        totalPages={invitationsData.total_pages}
        page={invitationsData.page}
      />
    );

    return (
      <LayoutContent>
        <Box>
          <BoxItem>
            <Header>
              <Header.Title>
                <span data-test-id="Invitations-title">Invitations</span>
              </Header.Title>
              <Header.Nav>
                <Button
                  to={`/projects/${params.projectId}/invitations/new`}
                  params={{ projectId: params.projectId }}
                  data-test-id="Invitations-inviteButton"
                >
                  Invite/Add Non-production employee
                </Button>
              </Header.Nav>
            </Header>
          </BoxItem>
          {invitations.length > 0 && content()}
          {invitations.length === 0 && (
            <Blankslate>No invitations found.</Blankslate>
          )}
        </Box>
        {invitations.length > 0 && pagination()}
      </LayoutContent>
    );
  },
});
