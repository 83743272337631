import React from 'react';

// HOC
import { withStyles } from '@mui/styles';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLateOutlined';
import Tooltip from '@mui/material/Tooltip';

// Components

// Utilities
import classNames from 'classnames';

const styles = {
  badge: {
    display: 'inline-block',
    color: 'white',
    paddingLeft: 7,
    paddingRight: 7,
    height: 21,
    lineHeight: '21px',
    borderRadius: 5,
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    verticalAlign: 'middle',
    backgroundColor: 'grey',
    '& + span': {
      marginLeft: 6,
    },
  },
  COI: {
    backgroundColor: '#D0031B',
  },
  additional_documents: {
    color: '#FFBF00',
    backgroundColor: 'transparent !important',
  },
  i9_correction_requested: {
    color: '#FFBF00',
    backgroundColor: 'transparent !important',
  },
};

const noDisplayBadges = {
  additional_documents: 'additional_documents',
  i9_correction_requested: 'i9_correction_requested',
};

const Badges = props => {
  const { badges = [], id, classes } = props;

  return (
    <React.Fragment>
      {badges?.map(badge => (
        <span
          key={`Badges-${badge}-${id}`}
          className={classNames(classes.badge, classes[badge])}
        >
          <span data-test-id={`Badges-${badge}-${id}`}>
            {badge === noDisplayBadges[badge] ? '' : badge}
          </span>
          {badge === 'additional_documents' && (
            <Tooltip disableInteractive title="Additional Documents for Review">
              <DescriptionOutlinedIcon />
            </Tooltip>
          )}
          {badge === 'i9_correction_requested' && (
            <Tooltip disableInteractive title="I-9 Correction Requested">
              <AssignmentLateIcon />
            </Tooltip>
          )}
        </span>
      ))}
    </React.Fragment>
  );
};

export default withStyles(styles)(Badges);
