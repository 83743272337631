import { useQuery } from 'react-apollo';
import { gql } from 'graphql-tag';

const QUERY = gql(`
  query projectEmployees($projectId: Int!) {
    project(id: $projectId) {
      id
      approvedDocumentsEmployees {
        id
        lastName
        firstName
      }
    }
  }
`);

const useProjectEmployees = projectId => {
  const { loading, data, error } = useQuery(QUERY, {
    variables: { projectId },
  });

  return { loading, error, data: data?.project?.approvedDocumentsEmployees };
};

export default useProjectEmployees;
