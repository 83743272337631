import React from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import Loader from 'common/components/Loader';
import Box from 'common/oldJavascripts/components/Base/Box';
import BoxBody from 'common/oldJavascripts/components/Base/Box/BoxBody';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Table from 'common/oldJavascripts/components/Base/Table';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import T from 'common/oldJavascripts/utils/i18n';
import Header from 'common/oldJavascripts/components/Base/Header';

import useAuditLogs from 'admin/hooks/useAuditLogs';
import { compose } from 'redux';

import moment from 'moment';

const styles = {
  error: {
    color: 'red',
  },
};

const AuditLogs = props => {
  const { classes, match: { params: { projectId } = {} } = {} } = props;
  const {
    loading,
    error: { message: errorAuditLogs } = {},
    data: { auditLogs } = [],
  } = useAuditLogs(projectId);
  const hasLogs = auditLogs?.length > 0;

  const auditTableRow = auditLog => {
    const { id, activityType, activityData, createdAt, userName } = auditLog;
    const [createdAtStripped] = createdAt.split('+') || [];
    const message = T(
      'audit_logs.messages.' + activityType,
      JSON.parse(activityData),
    );
    const formattedCreatedAt = moment(
      createdAtStripped,
      'YYYY-MM-DD HH:mm:ss',
    ).format('MMM Do, YYYY, h:mmA');

    return (
      <Table.Row key={id}>
        <Table.Cell data-test-id={`AuditLogs-row-${id}`}>
          {formattedCreatedAt}
          {' - '}
          {userName} {message}
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <Box>
      <BoxBody>
        <BoxItem>
          <Header>
            <Header.Title>
              <span data-test-id="AuditLogs-title">Audit Log</span>
            </Header.Title>
          </Header>
        </BoxItem>
        {loading && (
          <BoxItem>
            <Loader />
          </BoxItem>
        )}
        {!loading && errorAuditLogs && (
          <Typography className={classes.error}>
            Failed loading manual archives: {errorAuditLogs}
          </Typography>
        )}
        {!loading && !errorAuditLogs && !hasLogs && (
          <BoxItem>
            <Blankslate>No audit logs found</Blankslate>
          </BoxItem>
        )}
        {!loading && !errorAuditLogs && hasLogs && (
          <Table data-test-id="RelatedProjects-table">
            <Table.Header>
              <Table.Cell header>Activity</Table.Cell>
            </Table.Header>
            {auditLogs?.map(auditTableRow)}
          </Table>
        )}
      </BoxBody>
    </Box>
  );
};

export default compose(withStyles(styles))(AuditLogs);
