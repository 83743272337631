import { REQUEST, SUCCESS, FAILURE } from 'common/store/actions/actionTypes';
import { SESSION_STATUS } from 'common/store/actions/session';

export const initialState = {
  sessionActive: false,
  sessionCheckInProgress: false,
  sessionCheckFailed: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(SESSION_STATUS):
      return {
        ...state,
        sessionCheckInProgress: true,
        sessionCheckFailed: false,
      };
    case SUCCESS(SESSION_STATUS):
      return {
        ...initialState,
        sessionActive: true,
      };
    case FAILURE(SESSION_STATUS):
      return {
        ...initialState,
        sessionCheckFailed: true,
      };
    default:
      return state;
  }
};
