import React from 'react';

import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { Button, CircularProgress } from '@mui/material';
import { FETCH_MORE_NETWORK_STATUS } from 'common/constants/graphqlNetworkStatus/networkStatus';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMoreButton: {},
  loadingIcon: {
    width: '20px',
    height: '20px',
    marginLeft: '10px',
    color: '#0000FF',
  },
});

const StyledButton = styled(Button)({
  padding: '11px 16px !important',
  margin: '0 0 20px 0',
  background: '#FFFFFF',
  border: '1px solid #0000FF',
  color: '#0000FF',
  boxSizing: 'border-box',
  boxShadow:
    '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
  borderRadius: '2px',
});

const FetchMore = props => {
  const { fetchMore, pageInfo, networkStatus } = props;
  const classes = useStyles();

  const loadOffers = () => {
    const { endCursor } = pageInfo;

    fetchMore({
      variables: { after: endCursor },
      updateQuery: (prevResults, { fetchMoreResult }) => {
        const { project: { approvedOffers: fetchMoreOffers = {} } = {} } =
          fetchMoreResult || {};
        const { nodes: fetchMoreNodes = [], pageInfo: fetchMorePageInfo = {} } =
          fetchMoreOffers || {};

        if (!fetchMoreNodes.length) return prevResults;

        const prevProject = prevResults.project;
        const prevOffers = prevProject.approvedOffers;
        return {
          project: {
            __typename: prevProject.__typename,
            id: prevProject.id,
            approvedOffers: {
              __typename: prevOffers.__typename,
              pageInfo: { ...fetchMorePageInfo },
              nodes: [
                ...prevOffers.nodes,
                ...fetchMoreNodes.filter(
                  fetchMoreOffersId =>
                    !prevOffers.nodes.some(
                      prevResultsOffersId =>
                        prevResultsOffersId === fetchMoreOffersId,
                    ),
                ),
              ],
            },
          },
        };
      },
    });
  };

  return (
    <div className={classes.buttonContainer}>
      <StyledButton
        className={classes.loadMoreButton}
        variant="outlined"
        data-test-id={`Projects-load-more-button`}
        onClick={loadOffers}
      >
        Load More{' '}
        {networkStatus === FETCH_MORE_NETWORK_STATUS && (
          <CircularProgress size="sm" className={classes.loadingIcon} />
        )}
      </StyledButton>
    </div>
  );
};

export default FetchMore;
