import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import {
  Typography,
  Paper,
  Button,
  CircularProgress,
  ThemeProvider,
} from '@mui/material';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import classNames from 'classnames';
import { documentSignerTheme } from 'common/shared/oldDocumentSignerUI/theme';
import DocumentSummary from './DocumentSummary';
import DocumentDropzone from './DocumentDropzone';
import UploadedDocument from 'common/components/UploadedDocument';
import { styled } from '@mui/system';

const styles = theme => ({
  root: {
    zIndex: '800 !important',
  },
  closeIcon: {
    top: theme.spacing(2),
    right: theme.spacing(2),
    position: 'absolute',
    cursor: 'pointer',
  },
  newDoc: {
    border: `1px dashed ${theme.palette.grey[400]}`,
    borderRadius: 3,
  },
  docContainer: {
    padding: `0px 0px ${theme.spacing(2)}px 0px`,
  },
  newDocContainer: {
    height: 178,
  },
  buttonContainer: {
    float: 'right',
    display: 'flex',
    flexDirection: 'row',
  },
  reviewButtonContainer: {
    position: 'relative',
  },
  buttonProgress: {
    color: '#0000FF',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: theme.spacing(-1.5),
    marginLeft: theme.spacing(-1.5),
  },
});

const StyledButton = styled(Button)({
  margin: '0 10px',
});

class DocumentReplacement extends Component {
  state = {
    showDropzone: true,
    newDocument: null,
  };

  uploadFile = file => {
    this.setState({
      newDocument: { file, fileName: file.name },
    });
    this.hideDropzone();
  };

  clearState = () => {
    this.setState({
      newDocument: null,
      showDropzone: true,
    });
  };

  receiveFileId = fileId => {
    this.setState(prevState => ({
      newDocument: {
        ...prevState.newDocument,
        fileId,
      },
    }));
  };

  reviewMapping = () => {
    this.props.reviewMappingHandler();
  };

  hideDropzone = () => {
    this.setState({ showDropzone: false });
  };

  closeHandler = () => {
    const { closeHandler } = this.props;
    closeHandler(this.clearState);
  };

  render() {
    const {
      classes,
      uploadHandler,
      editHandler,
      viewHandler,
      oldDocument,
      processing,
    } = this.props;
    const { showDropzone, newDocument } = this.state;
    const uploadSuccess = newDocument && newDocument.fileId;
    const newDocumentClass = classNames(
      [classes.docContainer],
      [classes.newDocContainer],
    );

    return (
      <ThemeProvider theme={documentSignerTheme}>
        <Dialog
          classes={{ root: classes.root }}
          open={this.props.open}
          onClose={this.handleClose}
          fullWidth
        >
          <DialogTitle disableTypography>
            <Typography variant="h6" data-test-id="DocumentReplacement-title">
              Replace Document
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Please upload PDF file to replace the existing one. Drag & Drop
              file below or add from computer file selector.
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Paper elevation={0} className={classes.docContainer}>
              <Typography variant="subtitle1" gutterBottom>
                Current Document
              </Typography>
              <DocumentSummary document={oldDocument} />
            </Paper>
            <Paper
              elevation={0}
              className={newDocumentClass}
              data-test-id="DocumentReplacement-document"
            >
              <Typography variant="subtitle1" gutterBottom>
                New Document
              </Typography>
              {showDropzone && (
                <DocumentDropzone
                  uploadHandler={this.uploadFile}
                  multiple={false}
                />
              )}
              {!showDropzone && (
                <UploadedDocument
                  uploadHandler={uploadHandler}
                  editHandler={editHandler}
                  deleteHandler={this.clearState}
                  document={newDocument}
                  onSuccessfulUpload={this.receiveFileId}
                  editable
                  processing={processing}
                  viewHandler={viewHandler}
                />
              )}
            </Paper>
            <Paper elevation={0} className={classes.buttonContainer}>
              <Button
                size="small"
                className={classes.secondaryButton}
                onClick={this.closeHandler}
                data-test-id="document-replace-cancel"
                color="secondary"
                variant="contained"
              >
                Cancel
              </Button>
              <div className={classes.reviewButtonContainer}>
                <StyledButton
                  variant="contained"
                  size="small"
                  onClick={this.reviewMapping}
                  disabled={!uploadSuccess || processing}
                  data-test-id="document-replace-review-mapping"
                  color="primary"
                >
                  Review Mappings
                </StyledButton>
                {processing && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Paper>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(DocumentReplacement);
