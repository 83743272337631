import React, { useState } from 'react';
import {
  Button,
  Typography,
  TextareaAutosize,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';

const addNotesStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#f7fcfc',
    padding: '20px 50px',
  },
  title: {
    color: '#0000FF',
    fontSize: '18px !important',
  },
  description: {
    color: '#646464',
    fontSize: '14px !important',
    margin: '20px 0px !important',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  closeBtn: {
    fontSize: '14px !important',
    fontWeight: '500 !important',
    textTransform: 'none !important',
    borderRadius: '2px !important',
  },
  submitBtn: {
    marginLeft: '20px !important',
    borderRadius: '2px !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
  },
  textArea: {
    width: '100%',
    borderRadius: 2,
    border: '1 solid #b0bec5',
    backgroundColor: '#ffffff',
    padding: '10px',
    color: '#333',
    fontSize: 14,
  },
  errorMessage: {
    color: 'red',
    fontSize: 14,
    marginTop: '20px',
    marginBottom: '20px',
  },
  radioRoot: {
    marginBottom: '14px',
  },
  selectText: {
    marginBottom: '4px',
    fontWeight: 700,
  },
  activeOption: {
    color: '#0000FF',
  },
  boldText: {
    fontWeight: 'bold',
  },
}));

const CustomRadio = withStyles({
  root: {
    '&$checked': {
      color: '#0000FF',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const AddNote = props => {
  const { onClose, closeAddNote, rejectOfferHandler, i9Document } = props;
  const classes = addNotesStyles();
  const [comment, setComment] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [keepI9, setKeepI9] = useState('saveI9');

  const sendNote = () => {
    if (comment.length > 0) {
      rejectOfferHandler(comment, keepI9 === 'saveI9' ? true : false);
      closeAddNote();
    } else {
      setErrorMessage('Please enter an explanation');
    }
  };

  const onI9RadioChange = event => {
    setKeepI9(event.target.value);
  };

  return (
    <div className={classes.root} data-test-id="RejectOffer-AddNote">
      <Typography className={classes.title}>Add Note</Typography>
      <Typography className={classes.description}>
        You have chosen to reject the selected offer. It is required that you
        give us a short explanation for this action.
      </Typography>
      {i9Document ? (
        <div className={classes.radioRoot}>
          <Typography paragraph={true} className={classes.selectText}>
            {'Select what you want to reject:'}
          </Typography>
          <RadioGroup value={keepI9} onChange={onI9RadioChange}>
            <FormControlLabel
              control={<CustomRadio />}
              label={
                <label
                  className={keepI9 === 'saveI9' ? classes.activeOption : ''}
                >
                  Offer <span className={classes.boldText}>EXCLUDING</span> I-9
                </label>
              }
              value={'saveI9'}
              classes={{ label: classes.label }}
            />
            <FormControlLabel
              control={<CustomRadio />}
              label={
                <label
                  className={keepI9 === 'removeI9' ? classes.activeOption : ''}
                >
                  Entire offer{' '}
                  <span className={classes.boldText}>INCLUDING</span> I-9
                </label>
              }
              value={'removeI9'}
              classes={{ label: classes.label }}
            />
          </RadioGroup>
        </div>
      ) : null}
      <div>
        <TextareaAutosize
          className={classes.textArea}
          minRows={9}
          onChange={ev => setComment(ev.target.value)}
          aria-label="note text area"
          placeholder='Provide an explanation for declining this offer. Example: "the allowance amount is over budget"'
          data-test-id="RejectOffer-TextAreaNote"
        />
      </div>
      <div className={classes.errorMessage}>{errorMessage}</div>
      <div className={classes.btnContainer}>
        <Button
          className={classes.closeBtn}
          color="primary"
          variant="outlined"
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          className={classes.submitBtn}
          onClick={() => sendNote()}
          data-test-id="RejectOffer-submitBtn"
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default AddNote;
