const enforceMaxDigits = (
  rawValue,
  preDecimalMaxDigits = 7,
  postDecimalMaxDigits = 4,
) => {
  let value = rawValue;
  if (typeof rawValue === 'number') value = String(rawValue);

  const [preDecimal = '', postDecimal = ''] = value.split('.');

  /*
  Test Pre Decimal
  - If the preDecimal length is greater than our max pre decimal digits then we
    convert the number to the highest allowed number without post decimal digits
   */
  if (preDecimal.length > preDecimalMaxDigits) {
    return '9'.repeat(preDecimalMaxDigits);
  }

  /*
  Test Post Decimal
  - If there is no post decimal, then just return the value submitted
    since the pre decimal checks already passed
  - If the Post Decimal length is too long then we take the supplied value and round it
   */
  if (postDecimal.length === 0) return value;
  if (postDecimal.length > postDecimalMaxDigits) {
    return Number.parseFloat(value).toFixed(postDecimalMaxDigits);
    // var factor = Math.pow(10, postDecimalMaxDigits);
    // const number = parseInt(value);
    // return Math.round(number * factor) / factor;
  }
  // All tests pass and no changes required
  return value;
};

export default enforceMaxDigits;
