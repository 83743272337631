export const validateName = function(value) {
  const cleanString = value.replace(/[^a-z\s.\-']/i, '');
  return cleanString;
};

export const cleanName = function(value) {
  if (value) {
    const cleanString = value.replace(/[^a-z\s.\-']/gi, '');
    return cleanString;
  }
  return '';
};

export default { validateName, cleanName };
