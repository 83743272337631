const uploadedDocumentActionEnum = {
  UPLOAD: 'UPLOAD',
  EDIT: 'EDIT',
  SAVE: 'SAVE',
  DELETE: 'DELETE',
  RETRY: 'RETRY',
  VIEW: 'VIEW',
};

export default uploadedDocumentActionEnum;
