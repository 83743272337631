import oktaAuth from 'common/utilities/oktaAuth';
import oktaAuthMyCnC from 'common/utilities/oktaAuthMyCnC';
import getScope from 'common/constants/config/scopes';

export default class Permissions {
  oktaAuthClient = oktaAuth();

  oktaAuthClientCnC = oktaAuthMyCnC();

  getAccessToken = () =>
    this.oktaAuthClient.token.getWithoutPrompt({
      responseType: 'token',
      scopes: [getScope()],
    });

  getAccessTokenMyCnC = () => {
    this.oktaAuthClientCnC.token
      .getWithoutPrompt({
        responseType: 'token',
        scopes: ['profile', 'openid', 'email'],
      })
      .then(token => {
        this.oktaAuthClientCnC.tokenManager.add('accessToken', token);
      });
  };
}
