import { useQuery } from '@apollo/react-hooks';
import { Queries } from 'common/apollo';

const useRelatedProjects = projectId => {
  const { loading, data, refetch, error } = useQuery(Queries.RelatedProjects, {
    variables: { projectId: parseInt(projectId, 10) },
    fetchPolicy: 'no-cache',
  });

  return { loading, refetch, error, data: data && data.project };
};

export default useRelatedProjects;
