import React, { useState } from 'react';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';

import history from 'common/constants/config/history';
import Loader from 'common/components/Loader/Loader';
import withRouteHelpers from 'common/hoc/withRouteHelpers';

import Snackbar from 'common/components/Snackbar/Snackbar';
import GraphQLErrors from 'common/components/GraphQLErrors';

import useOfferDealMemo from './hooks/useOfferDealMemo';
import DealMemoPayload from './DealMemoPayload';

const styles = {
  root: {
    padding: 18,
  },
  title: {
    paddingBottom: 18,
  },
  filterForm: {
    '& >*': {
      marginRight: 18,
    },
  },
  buttonSubmit: {
    backgroundColor: '#00F',
    color: '#fff',
    '&:hover, &:focus': {
      outline: 'none',
      backgroundColor: '#1A55FD',
    },
    '&:active': {
      backgroundColor: '#000080',
    },
  },
  dealMemo: {
    padding: 18,
    marginTop: 18,
  },
};

const OfferDealmemoPayload = ({ classes, routerParams }) => {
  const { id } = routerParams;
  const [offerId, setOfferId] = useState(parseInt(id) ?? null);
  const [copyResponse, setCopyResponse] = useState({});

  const { loading, error, data } = useOfferDealMemo(parseInt(id));

  const submit = e => {
    e.preventDefault();
    if (!offerId) return;

    history.push(`/tools/deal-memo/${offerId}`);
  };

  const onCopy = (success, error = null) => {
    setCopyResponse({
      success,
      open: true,
      message: success ? 'Deal-Memo Payload copied' : error.message,
    });
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Offer Deal-Memo API Payload
      </Typography>
      <Box component="form" onSubmit={submit}>
        <TextField
          variant="standard"
          placeholder="Offer Id"
          value={offerId || ''}
          className={classes.filterForm}
          InputProps={{ inputMode: 'numeric' }}
          onChange={({ target: { value } }) => setOfferId(parseInt(value))}
          data-test-id="OfferDealMemo-input-OfferId"
          required
        />
        <Button
          variant="contained"
          className={classes.buttonSubmit}
          type="submit"
          data-test-id="OfferDealMemo-button-submit"
        >
          Submit
        </Button>
      </Box>
      <Box className={classes.dealMemo}>
        {loading && <Loader message="Loading Deal-Memo data" />}
        {error && (
          <Box data-test-id="OfferDealMemo-dealMemoError">
            <GraphQLErrors error={error} detailed />
          </Box>
        )}
        {!loading && !error && data && (
          <DealMemoPayload data={data} onCopy={onCopy} />
        )}
      </Box>
      <Snackbar
        open={copyResponse?.open || false}
        message={copyResponse?.message || ''}
        variant={copyResponse?.success ? 'SUCCESS' : 'ERROR'}
        handleClose={() => setCopyResponse({ ...copyResponse, open: false })}
      />
    </Paper>
  );
};

export default compose(
  withStyles(styles),
  withRouteHelpers,
)(OfferDealmemoPayload);
