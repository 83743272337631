import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query offerHistory($id: Int!) {
    offer(id: $id) {
      id
      history {
        datetime
        description
      }
    }
  }
`;

const useOfferHistory = offerId => {
  const id = parseInt(offerId, 10);
  const { error, loading, data } = useQuery(QUERY, {
    variables: { id },
    skip: !id,
  });

  return { error, loading, data: data?.offer?.history };
};

export default useOfferHistory;
