import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Paper, Typography } from '@mui/material';
import Loader from 'common/components/Loader/Loader';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import { styled } from '@mui/system';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    padding: '12px 0px',
  },
  guildContainer: {
    marginBottom: 0,
  },
});

const StyledPaper = styled(Paper)({
  boxShadow: 'none',
  color: '#525252',
  background: '#f4f4f4',
  padding: '12px 8px',
  borderRadius: 0,
});

const GuildTable = ({ data = [], loading, error }) => {
  const classes = useStyles();

  if (loading) return <Loader />;

  if (error) return <FailureAlert queryName="Guild List" />;

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        <Grid item sm={1} md={1}>
          <Typography>Union Code</Typography>
        </Grid>
        <Grid item sm={2} md={1}>
          <Typography>Sch</Typography>
        </Grid>
        <Grid item sm={2} md={2}>
          <Typography>Sch Description</Typography>
        </Grid>
        <Grid item sm={1} md={1}>
          <Typography>Occ</Typography>
        </Grid>
        <Grid item sm={2} md={2}>
          <Typography>Occ Description</Typography>
        </Grid>
        <Grid item sm={2} md={1}>
          <Typography>Frequency</Typography>
        </Grid>
        <Grid item sm={1} md={1}>
          <Typography>Hours</Typography>
        </Grid>
        <Grid item sm={2} md={1}>
          <Typography>Days</Typography>
        </Grid>
        <Grid item sm={2} md={1}>
          <Typography>Per</Typography>
        </Grid>
        <Grid item sm={2} md={1}>
          <Typography>Worker Type</Typography>
        </Grid>
        {data &&
          data.map((row, id) => (
            <Grid
              container
              spacing={2}
              className={classes.guildContainer}
              key={id}
              data-test-id="GuildList-tableRow"
            >
              <Grid item sm={1} md={1}>
                <StyledPaper
                  className={classes.paper}
                  data-test-id={`Guild-Union-code-${row.unionCode}`}
                >
                  {row.unionCode}
                </StyledPaper>
              </Grid>
              <Grid item sm={1} md={1}>
                <StyledPaper
                  className={classes.paper}
                  data-test-id={`Guild-Sch-code-${row.scheduleCode}`}
                >
                  {row.scheduleCode}
                </StyledPaper>
              </Grid>
              <Grid item sm={2} md={2}>
                <StyledPaper
                  className={classes.paper}
                  data-test-id={`Guild-Sch-description-${row.scheduleDescription}`}
                >
                  {row.scheduleDescription}
                </StyledPaper>
              </Grid>
              <Grid item sm={1} md={1}>
                <StyledPaper
                  className={classes.paper}
                  data-test-id={`Guild-Occ-code-${row.occupationCode}`}
                >
                  {row.occupationCode}
                </StyledPaper>
              </Grid>
              <Grid item sm={2} md={2}>
                <StyledPaper
                  className={classes.paper}
                  data-test-id={`Guild-Occ-description-${row.occupationDescription}`}
                >
                  {row.occupationDescription}
                </StyledPaper>
              </Grid>
              <Grid item sm={1} md={1}>
                <StyledPaper
                  className={classes.paper}
                  data-test-id={`Guild-HoursFrequency-${row.guaranteedHoursFrequency}`}
                >
                  {row.guaranteedHoursFrequency}
                </StyledPaper>
              </Grid>
              <Grid item sm={1} md={1}>
                <StyledPaper
                  className={classes.paper}
                  data-test-id={`Guild-Hours-${row.guaranteedHours}`}
                >
                  {row.guaranteedHours}
                </StyledPaper>
              </Grid>
              <Grid item sm={1} md={1}>
                <StyledPaper
                  className={classes.paper}
                  data-test-id={`Guild-Days-${row.days}`}
                >
                  {row.days}
                </StyledPaper>
              </Grid>
              <Grid item sm={1} md={1}>
                <StyledPaper
                  className={classes.paper}
                  data-test-id={`Guild-Per-${row.per}`}
                >
                  {row.per}
                </StyledPaper>
              </Grid>
              <Grid item sm={1} md={1}>
                <StyledPaper
                  className={classes.paper}
                  data-test-id={`Guild-WorkerType-${row.workerType}`}
                >
                  {row.workerType}
                </StyledPaper>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default GuildTable;
