import { PUSH, POP, CLEAR } from 'common/store/actions/actionTypes';
import { DIALOG_NOTIFICATION } from 'common/store/actions/dialogNotifications';

export const initialState = {
  queue: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PUSH(DIALOG_NOTIFICATION):
      return {
        ...state,
        queue: [...state.queue, action.dialogObject],
      };
    case POP(DIALOG_NOTIFICATION):
      return {
        ...state,
        queue: [...state.queue].slice(1),
      };
    case CLEAR(DIALOG_NOTIFICATION):
      return {
        ...state,
        queue: [],
      };
    default:
      return state;
  }
};
