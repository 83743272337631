import React from 'react';
import { withStyles } from '@mui/styles';
import { Switch, Route } from 'react-router-dom';
import CncUiProvider from 'common/components/CnCUi/CncUiProvider';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import OffersList from 'onboarding/components/OffersList';
import OfferHelp from 'onboarding/oldJavascripts/components/Pages/Offer/Help';
import Details from 'onboarding/components/OfferDetails';
import SupportingDocuments from 'onboarding/components/SupportingDocuments';
import I9 from 'onboarding/oldJavascripts/components/Pages/Offer/Onboarding/I9';
import Sign from 'onboarding/oldJavascripts/components/Pages/Offer/Onboarding/Documents';
import MobileComplete from 'onboarding/components/MobileComplete';
import HamburgerMenu from 'mobile/components/Menu/HamburgerMenu';
import HamburgerMenuOld from 'mobile/components/Menu/HamburgerMenu_OLD';
import OnboardingStepper from './OnboardingStepper';
import OnboardingStepperOld from './OnboardingStepper_OLD';

// This should probably be added to cnc-ui
const styles = theme => ({
  '@global': {
    '[class*="MuiDialog-paper"]': {
      margin: 10,
    },
    '[class="modal-content"]': {
      maxWidth: 'unset',
      margin: 10,
    },
  },
});

const MobileRoutes = props => {
  const flags = useFeatureFlags();
  const globalNavDefault =
    process.env.REACT_APP_ENABLE_GLOBAL_NAV_BY_DEFAULT === 'true';
  const globalNavFlag = flags.includes('GlobalNavbar') || globalNavDefault;
  const HamburgerMenuComponent = globalNavFlag
    ? HamburgerMenu
    : HamburgerMenuOld;
  const OnboardingStepperComponent = globalNavFlag
    ? OnboardingStepper
    : OnboardingStepperOld;
  return (
    <CncUiProvider>
      <Switch>
        <Route path="/onboarding/offers/:offerId">
          <HamburgerMenuComponent nonEmployee={false}>
            <OnboardingStepperComponent />
          </HamburgerMenuComponent>
          <Switch>
            <Route
              exact
              path="/onboarding/offers/:offerId/details"
              component={Details}
            />
            <Route
              exact
              path="/onboarding/offers/:offerId/supporting-documents"
              component={SupportingDocuments}
            />
            <Route exact path="/onboarding/offers/:offerId/i9" component={I9} />
            <Route
              exact
              path="/onboarding/offers/:offerId/documents"
              component={Sign}
            />
            <Route
              exact
              path="/onboarding/offers/:offerId/complete"
              component={MobileComplete}
            />
            <Route
              exact
              path="/onboarding/offers/:offerId/submitted"
              component={Details}
            />
            <Route
              exact
              path="/onboarding/offers/:offerId/opted-out"
              component={Details}
            />
            <Route
              path="/onboarding/offers/:offerId/help"
              component={OfferHelp}
            />
          </Switch>
        </Route>
        <Route>
          <HamburgerMenuComponent />
          <OffersList />
        </Route>
      </Switch>
    </CncUiProvider>
  );
};

export default withStyles(styles)(MobileRoutes);
