import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import Button from 'common/oldJavascripts/components/Base/Button';
import DepartmentForm from './Form';
import List from 'common/oldJavascripts/components/Base/List';
import NavBar from 'common/oldJavascripts/components/Base/NavBar';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

// Hoc
import { withStyles } from '@mui/styles';

const styles = {
  typography: {
    marginLeft: 10,
    color: '#979ca5',
    fontWeight: 700,
  },
  iconButton: {
    padding: 0,
  },
  submitButton: {
    color: '#FFFFFF',
    background: '#0000FF',
    textTransform: 'none',
    fontSize: 13,
    '&:hover': {
      background: '#1A55FD',
    },
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  cancelButton: {
    border: '1px solid #0000FF',
    color: '#0000FF',
    background: '#FFFFFF',
    fontSize: 13,
    textTransform: 'none',
    '&:hover': {
      background: '#FFFFFF',
    },
  },
};

export default withStyles(styles)(
  Relay.createClass({
    statics: {
      queries: {
        project: {
          info(_, related) {
            const params = related['/router/params'];
            return {
              id: `/projects/${params.projectId}`,
            };
          },
        },
      },
      mutators: {
        department: {
          info(_, related) {
            const routerParams = related['/router/params'];

            return {
              id: `/v2/projects/${routerParams.projectId}/departments`,
              create: true,
              append: true,

              initial: {
                max_allowed_bank_accounts: 0,
              },
            };
          },

          state(_, related) {
            const routerParams = related['/router/params'];

            return [`/v2/projects/${routerParams.projectId}/departments`];
          },
        },
      },
    },

    getInitialState() {
      return {
        expanded: false,
      };
    },

    render() {
      const onClick = !this.state.expanded ? this._expandForm : null;
      return (
        <List.Item onClick={onClick} highlighted={true}>
          {this._renderContent()}
        </List.Item>
      );
    },

    _renderContent() {
      return this.state.expanded
        ? this._renderDepartmentForm()
        : this._renderAddButton();
    },

    _renderDepartmentForm() {
      const {
        detailSubDataType,
        enforceMask,
        accountMask,
        classes,
      } = this.props;
      const params = this.props.routerParams;
      const url = '/v2/projects/' + params.projectId + '/departments';
      const isButtonDisabled = !this.props.department?.data?.name;
      return (
        <List.ItemContent>
          <DepartmentForm
            action={url}
            method="post"
            onSubmit={this._createDepartment}
            department={this.props.department}
            detailSubDataType={detailSubDataType}
            enforceMask={enforceMask}
            accountMask={accountMask}
          >
            <NavBar>
              <NavBar.Item>
                <Button
                  className={classes.submitButton}
                  data-test-id="New-createButton"
                  disabled={isButtonDisabled}
                >
                  Create
                </Button>
              </NavBar.Item>
              <NavBar.Item>
                <Button
                  className={classes.cancelButton}
                  onClick={this._collapseForm}
                >
                  Cancel
                </Button>
              </NavBar.Item>
            </NavBar>
          </DepartmentForm>
        </List.ItemContent>
      );
    },

    _renderAddButton() {
      const { classes } = this.props;
      return (
        <List.ItemContent alignCenter={true} data-test-id="New-add">
          <IconButton className={classes.iconButton}>
            <AddIcon />
          </IconButton>
          <Typography className={classes.typography}>Add Department</Typography>
        </List.ItemContent>
      );
    },

    _expandForm() {
      this.setState({ expanded: true });
    },

    _collapseForm() {
      this.setState({ expanded: false });
      this.props.department.reset();
    },

    _createDepartment() {
      this.props.department.save('new', { excludeActionFromPath: true }, () =>
        this._collapseForm(),
      );
    },
  }),
);
