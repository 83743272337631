import { createTheme } from '@mui/material/styles';
// Custom Theme
import palette from './palette';
import typography from './typography';

const theme = createTheme({
  palette,
  typography,
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '16px',
      },
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1rem', // Adjust the font size as needed
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1rem', // Adjust the font size as needed
        },
      },
    },
  },
});

export default theme;
