import React from 'react';
import { styled } from '@mui/system';
import MuiSnackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import IconButton from '@mui/material/IconButton';
import amber from '@mui/material/colors/amber';

// Define styled components
const StyledSnackbarContent = styled(SnackbarContent, {
  shouldForwardProp: prop => prop !== 'variant',
})(({ theme, variant }) => ({
  backgroundColor: {
    SUCCESS: '#008006',
    ERROR: '#ED1C23',
    INFO: '#0000FF',
    WARNING: amber[700],
  }[variant],
  display: 'flex',
  alignItems: 'center',
  color: 'white',
}));

const StyledIcon = styled('span')({
  fontSize: 20,
  opacity: 0.9,
  marginRight: 8,
});

const StyledMessage = styled('span')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
});

const variantIcon = variant => {
  const icons = {
    SUCCESS: { Icon: CheckCircleIcon, iconId: 'Success' },
    WARNING: { Icon: WarningIcon, iconId: 'Warning' },
    ERROR: { Icon: ErrorIcon, iconId: 'Error' },
    INFO: { Icon: InfoIcon, iconId: 'Info' },
  };
  return icons[variant] || icons.INFO;
};

const Snackbar = ({
  open,
  message,
  onExited,
  handleClose,
  variant,
  duration = 3000,
  className,
}) => {
  const { Icon, iconId } = variantIcon(variant);
  return (
    <MuiSnackbar
      key={message}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      onExited={onExited}
      data-test-id="Snackbar-root"
    >
      <StyledSnackbarContent
        variant={variant}
        aria-describedby="message"
        message={
          <StyledMessage id="message" data-test-id="Snackbar-message">
            <StyledIcon as={Icon} data-test-id={`Snackbar-icon-${iconId}`} />
            {message}
          </StyledMessage>
        }
        action={
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            data-test-id="Snackbar-close"
          >
            <CloseIcon />
          </IconButton>
        }
        data-test-id={`Snackbar-content-${variant}`}
      />
    </MuiSnackbar>
  );
};

export default Snackbar;
