import React, { Fragment } from 'react';
import TOOLTIP_ICON from 'common/images/tooltip.svg';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import UsaOnly from 'common/components/UsaOnly';
import { createOfferCurrency as currency } from 'common/oldJavascripts/utils/format';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

const useStyles = makeStyles(theme => ({
  terms: {
    marginTop: '40px',
  },
  title: {
    color: '#646464',
    fontSize: '24px',
    fontWeight: 400,
    marginBottom: 20,
  },
  amount: {
    color: '#0000FF',
    fontSize: 30,
    fontWeight: 400,
    padding: 18,
    textAlign: 'center',
    border: '0.1px solid #d3f0ee',
    [theme.breakpoints.up('md')]: {
      padding: 15,
    },
  },
  grey: {
    color: '#939393',
  },
  scaleTip: {
    fontSize: 10,
    color: '#939393',
  },
  tooltip: {
    marginLeft: '5px',
  },
  mobileTermsRateHeader: {
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    gridTemplateRows: '30% 70%',
    gridAutoFlow: 'row',
  },
  greenBox: {
    backgroundColor: '#E2F1FF',
    fontSize: 18,
    fontWeight: 400,
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  regularBox: {
    backgroundColor: '#E2F1FF',
    color: '#5b5b5b',
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center',
    padding: 15,
    border: 'none',
  },
  distant: {
    color: '#5b5b5b',
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  distantContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const formatAmount = amount => {
  return !Number.isInteger(amount) ? currency(amount) : `$${amount}`;
};

const UnionWeeklyEmployeeTermsMobile = ({ terms = {} }) => {
  const classes = useStyles();
  const {
    guaranteed_hours_studio: guaranteedHours,
    guaranteed_hours_location: guaranteedHoursDistant,
    pay_at_scale: payAtScale,
    pay_at_scale_distant: payAtScaleDistant,
    rate_per_week_studio: weeklyRate,
    rate_per_week_location: weeklyRateDistant,
    pay_idle_days_distant_at_12th: payIdleDaysDistantAt12th,
    pay_idle_days_distant_at_scale: payIdleDaysDistantAtScale,
  } = terms;

  const isStudio = weeklyRate || guaranteedHours;
  const isDistant = weeklyRateDistant || guaranteedHoursDistant;

  const pay10th12thScaleValue = payIdleDaysDistantAtScale
    ? 'Scale Rate'
    : 'Negotiated Rate';
  const payAt10thOr12th = payIdleDaysDistantAt12th
    ? `1/12th of ${pay10th12thScaleValue}`
    : `1/10th of ${pay10th12thScaleValue}`;

  return (
    <div className={classes.terms}>
      <Typography
        className={classes.title}
        data-test-id="UnionWeeklyOnCallMobile-title"
      >
        Terms of Employment
      </Typography>
      {isStudio && (
        <Fragment>
          <div>
            <div className={classes.greenBox}>
              <Typography
                className={classes.distant}
                data-test-id="UnionWeeklyOnCallMobile-studioSection-title"
              >
                STUDIO{' '}
                <Tooltip
                  disableInteractive
                  title="Studio refers to a local distance within the studio zone"
                  placement="top-start"
                  className={classes.tooltip}
                >
                  <img src={TOOLTIP_ICON} alt="schedule tooltip" />
                </Tooltip>
              </Typography>
            </div>
          </div>
          <Table className={classes.distantContainer}>
            <TableHead>
              <TableRow>
                {weeklyRate && (
                  <TableCell
                    className={classes.regularBox}
                    data-test-id="UnionWeeklyOnCallMobile-weeklyStudioRate-title"
                  >
                    Weekly Rate
                  </TableCell>
                )}
                {guaranteedHours && (
                  <TableCell
                    className={classes.regularBox}
                    data-test-id="UnionWeeklyOnCallMobile-hourDays-title"
                  >
                    Hours Per Week
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {weeklyRate && (
                  <TableCell
                    className={classes.amount}
                    data-test-id="UnionWeeklyOnCallMobile-weeklyRate-amount"
                  >
                    {formatAmount(weeklyRate)}
                    {payAtScale && (
                      <Typography className={classes.scaleTip}>
                        *Scale Rate
                      </Typography>
                    )}
                  </TableCell>
                )}
                {guaranteedHours && (
                  <TableCell
                    className={classes.amount}
                    data-test-id="UnionWeeklyOnCallMobile-guaranteedHours-amount"
                  >
                    {guaranteedHours}
                    {payAtScale && (
                      <Typography className={classes.scaleTip}>
                        *Scale Rate
                      </Typography>
                    )}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </Fragment>
      )}
      {isDistant && (
        <div className={classes.distantContainer}>
          <div>
            <div className={classes.greenBox}>
              <Typography
                className={classes.distant}
                data-test-id="UnionWeeklyOnCallMobile-distantSection-title"
              >
                DISTANT{' '}
                <Tooltip
                  disableInteractive
                  title="Distant refers to the location outside the studio zone."
                  placement="top-start"
                  className={classes.tooltip}
                >
                  <img src={TOOLTIP_ICON} alt="schedule tooltip" />
                </Tooltip>
              </Typography>
            </div>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.regularBox}
                  data-test-id="UnionWeeklyOnCallMobile-hourlyRate-title"
                >
                  Weekly Rate
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {weeklyRateDistant && (
                  <TableCell
                    className={classes.amount}
                    data-test-id="UnionWeeklyOnCallMobile-rateDistant-amount"
                  >
                    {formatAmount(weeklyRateDistant)}
                    {payAtScaleDistant && (
                      <Typography className={classes.scaleTip}>
                        *Scale Rate
                      </Typography>
                    )}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
          {guaranteedHoursDistant && (
            <Table className={classes.distantContainer}>
              <TableHead>
                <TableRow>
                  {guaranteedHoursDistant && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="UnionWeeklyOnCallMobile-guaranteedHoursDistant-title"
                    >
                      Hours Per Week
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    className={classes.amount}
                    data-test-id="UnionWeeklyOnCallMobile-guaranteedHoursDistant-amount"
                  >
                    {guaranteedHoursDistant}
                    {payAtScaleDistant && (
                      <Typography className={classes.scaleTip}>
                        *Scale Rate
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </div>
      )}
      <UsaOnly>
        <Table className={classes.distantContainer}>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.regularBox}
                data-test-id="UnionWeeklyOnCallMobile-idleRate-title"
              >
                Idle Days Pay
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                className={classes.amount}
                data-test-id="UnionWeeklyOnCallMobile-idleRateDistant-amount"
              >
                {payAt10thOr12th}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </UsaOnly>
      <Typography className={classes.scaleTip}>
        *Scale rate shown is as of offer creation date. Scale rate is subject to
        change upon union negotiated increases.
      </Typography>
    </div>
  );
};

export default UnionWeeklyEmployeeTermsMobile;
