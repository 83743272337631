import { useQuery } from '@apollo/react-hooks';
import { GetCrewMembers } from 'common/apollo/queries';

const useCrewList = variables => {
  const { loading, refetch, fetchMore, data, error, networkStatus } = useQuery(
    GetCrewMembers,
    {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  );
  return {
    loading,
    networkStatus,
    refetch,
    fetchMore,
    data: data?.project,
    error,
  };
};

export default useCrewList;
