import React, { useState } from 'react';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import useSaveOfferDraft from '../hooks/useSaveOfferDraft';

import Modal from './Modal';
import Notification from '../Notification';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    display: 'inline-block',
    width: 'auto',
  },
};

const SaveButton = ({ draft, projectId, data, classes, onChange }) => {
  const { id: draftId, title: draftTitle, isTemplate } = draft || {};

  const [modalOpen, setModalOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(null);
  const [saveErrors, setSaveErrors] = useState({});
  const [saveOfferDraft, { loading }] = useSaveOfferDraft();

  const save = (title, type) => {
    const canSave = !loading && (draftId || title?.trim());
    if (!canSave) return;

    const crewClean = Object.keys(data?.crew || []).filter(
      id => (data?.crew || {})[id],
    );

    // offer templates start/send/end date should not be saved
    const offerDate = data?.offerDate || {};

    if (type === 'template') {
      offerDate['startDateObject'] = null;
      offerDate['startDate'] = '';
      offerDate['sendDateObject'] = null;
      offerDate['sendDate'] = '';
      offerDate['endDateObject'] = null;
      offerDate['endDate'] = '';
    }

    setMessage('');
    setSuccess(null);
    const variables = {
      projectId,
      id: draftId && !isTemplate ? draftId : null,
      isTemplate: type === 'template' ? true : false,
      title: draftTitle && !isTemplate ? draftTitle : title?.trim(),
      data: {
        ...data,
        offerDate,
        crew: type === 'draft' ? crewClean : [],
      },
    };

    saveOfferDraft({ variables })
      .then(_res => {
        setSuccess(true);
        setMessage('Saved.');
        setSnackOpen(true);
        setModalOpen(false);
        onChange();
      })
      .catch(err => {
        setSuccess(false);
        setMessage('Save failed.');
        setSnackOpen(true);
        loadSaveErrors(err);
      });
  };

  const loadSaveErrors = error => {
    const data = {};
    (error?.graphQLErrors || []).forEach(e => {
      const i = e.extensions?.data || {};
      Object.keys(i).forEach(k => {
        if (!data[k]) data[k] = [];
        data[k] = data[k].concat(i[k]);
      });
    });
    setSaveErrors(data);
  };

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        size="large"
        variant="outlined"
        color="primary"
        data-test-id="SaveOfferDraft-button"
        onClick={() => setModalOpen(true)}
      >
        {draftId && !isTemplate ? 'Update' : 'Save'}
      </Button>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        draft={draft}
        loading={loading}
        errors={saveErrors}
        onSave={save}
      />
      <Notification
        open={snackOpen}
        setOpen={setSnackOpen}
        severity={success ? 'success' : 'error'}
        message={message}
      />
    </div>
  );
};

export default withStyles(styles)(SaveButton);
