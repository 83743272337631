import { useQuery } from 'react-apollo';
import { Queries } from 'common/apollo';

const DEFAULT_VARS = {
  first: 20,
  after: '',
  search: null,
  withOffers: true,
  withoutOffers: true,
  sort: null,
  sortField: null,
};

const useCrewMembers = variables => {
  const res = useQuery(Queries.GetCrewMembers, {
    variables: {
      ...DEFAULT_VARS,
      ...variables,
      id: parseInt(variables?.id, 10),
    },
    fetchPolicy: 'network-only',
  });

  return { ...res, data: res.data?.project };
};

export default useCrewMembers;
