import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';

import Loader from 'common/components/Loader';
import Confirm from 'common/components/Confirm';
import BoxBody from 'common/oldJavascripts/components/Base/Box/BoxBody';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Table from 'common/oldJavascripts/components/Base/Table';
import Button from 'common/oldJavascripts/components/Base/Button';

import useRemoveRelatedProject from './hooks/useRemoveRelatedProject';
import SearchDialog from './Search/Dialog';
import countryFlag from 'common/utilities/renderCountryFlag.js';

const styles = {
  btnPrimary: {
    background: '#0000FF',
    '&:hover': {
      background: '#1A55FD',
    },
  },
  projectName: {
    color: '#45a7dc',
    fontWeight: 'bolder',
  },
  countryFlagImageSize: {
    width: '24px',
    height: '24px',
    marginLeft: '10px',
  },
};

const Relatives = props => {
  const {
    classes,
    projectId,
    relatives = [],
    refetch: refetchRelatedProjects,
  } = props;

  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteProject, setDeleteProject] = useState({});
  const [reload, setReload] = useState(false);

  const [removeRelatedProject, { loadingRemove }] = useRemoveRelatedProject();
  const hasRelatives = relatives.length > 0;

  if (loadingRemove || reload) {
    return <Loader />;
  }

  const tableRow = project => {
    const {
      id: projectId,
      name: projectName,
      cncId,
      country: projectCountry = {},
      productionCompany: { name: prodcoName } = {},
    } = project;

    const renderCountryFlag = countryFlag(projectCountry?.code);
    const confirmDelete = (projectId, e) => {
      e.stopPropagation();
      const project = relatives.find(p => p.id === projectId);
      setDeleteProject(project);
      setIsDeleteDialogOpen(true);
    };

    return (
      <Table.Row
        data-test-id={`RelatedProjects-row-${projectId}`}
        key={projectId}
        to={`/projects/${projectId}/imported-settings`}
      >
        <Table.Cell doubleBorderBottom>
          <img
            src={renderCountryFlag}
            alt="region flag"
            className={classes.countryFlagImageSize}
          />
        </Table.Cell>
        <Table.Cell doubleBorderBottom>{cncId}</Table.Cell>
        <Table.Cell doubleBorderBottom>{projectName}</Table.Cell>
        <Table.Cell doubleBorderBottom>{prodcoName}</Table.Cell>
        <Table.Cell doubleBorderBottom>
          <IconButton
            data-test-id={`RelatedProjects-row-${projectId}-btnRemove`}
            onClick={e => confirmDelete(projectId, e)}
          >
            <DeleteIcon />
          </IconButton>
        </Table.Cell>
      </Table.Row>
    );
  };

  const reloadRelatedProjects = () => {
    setReload(true);
    refetchRelatedProjects().then(() => setReload(false));
  };

  return (
    <BoxBody>
      <BoxItem>
        <Button
          data-test-id="RelatedProjects-btnAdd"
          className={classes.btnPrimary}
          onClick={() => setIsSearchDialogOpen(true)}
        >
          Add related project
        </Button>
      </BoxItem>
      <Table data-test-id="RelatedProjects-table">
        <Table.Header>
          <Table.Cell header>Payroll Region</Table.Cell>
          <Table.Cell header>CNC ID</Table.Cell>
          <Table.Cell header>Name</Table.Cell>
          <Table.Cell header>Production Company</Table.Cell>
          <Table.Cell width="84px" header />
        </Table.Header>
        {hasRelatives && relatives.map(tableRow)}
        {!hasRelatives && (
          <Table.Row>
            <Table.Cell colSpan={5} noBorder>
              No results.
            </Table.Cell>
          </Table.Row>
        )}
      </Table>
      <SearchDialog
        projectId={projectId}
        relatives={relatives}
        open={isSearchDialogOpen}
        onClose={() => setIsSearchDialogOpen(false)}
        onSubmit={reloadRelatedProjects}
      />
      <Confirm
        data-test-id="RelatedProjects-DialogRemove"
        open={isDeleteDialogOpen}
        title="Remove related project"
        question={
          <div data-test-id="RelatedProjects-ConfirmRemoveMessage">
            Do you want to remove relation to{' '}
            <span className={classes.projectName}>{deleteProject.name}</span>?
          </div>
        }
        successMessage={
          <Typography variant="subtitle1">
            Relation to{' '}
            <span className={classes.projectName}>{deleteProject.name}</span>{' '}
            has been removed.
          </Typography>
        }
        onClose={() => setIsDeleteDialogOpen(false)}
        onSubmit={() => {
          setIsDeleteDialogOpen(false);
          setReload(true);
          removeRelatedProject({
            projectId: parseInt(deleteProject.id),
          }).then(reloadRelatedProjects);
        }}
      />
    </BoxBody>
  );
};

export default withStyles(styles)(Relatives);
