import React, { useState, useEffect } from 'react';

// HoC
import { withStyles } from '@mui/styles';
import { graphql } from 'react-apollo';
import { compose } from 'redux';

// GQL
import { Queries } from 'common/apollo';

// Components
import { stableSort, getSorting } from 'common/utilities/stableSort';
import Loader from 'common/components/Loader';

// MuiComponents
import {
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
} from '@mui/material';

// Table Header Definitions
const rows = [
  { id: 'producer', label: 'Producer' },
  { id: 'productionCompany', label: 'Production Company' },
  { id: 'projectName', label: 'Project' },
  { id: 'employeeName', label: 'Employee Name' },
  { id: 'employeeEmail', label: 'Email Address' },
  { id: 'employeeRole', label: 'Role' },
  { id: 'userStatus', label: 'Active Status For User In Project' },
  { id: 'inviteDate', label: 'Invited Date' },
  { id: 'deletedDate', label: 'Deleted Date' },
  { id: 'lastActivity', label: "User's Last Activity" },
  { id: 'lastStartUserLogInDate', label: 'Last Start+ User Log In Date' },
  { id: 'permissions', label: 'Permissions' },
];

const styles = {
  dataCell: {
    padding: '8px 12px',
    maxWidth: 230,
    overflowWrap: 'break-word',
  },
  scroll: {
    overflowX: 'scroll',
  },
};

const UserAccessReport = props => {
  const { classes, userQuery = {}, page, isReportAvailable } = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('producer');
  const [rowsPerPage, setRowsPerPage] = useState(25);

  let { userAccessReport = [] } = userQuery;

  userAccessReport = userAccessReport.map(u => ({
    id: u.employeeId,
    producer: u.producerName,
    productionCompany: u.productionCompanyName,
    projectName: u.projectName,
    employeeName: u.employeeName,
    employeeEmail: u.employeeEmail,
    employeeRole: u.employeeRole,
    userStatus: u.activeStatusForUserInProject,
    inviteDate: u.inviteDate,
    deletedDate: u.deletedDate,
    lastActivity: u.userLastActivity || '',
    lastStartUserLogInDate: u.lastStartUserLogInDate || '',
    permissions: u.userPermissions,
  }));

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };
  const handleRequestSort = (event, property) => {
    if (orderBy === property && order === 'asc') {
      setOrder('desc');
    } else if (orderBy === property && order === 'desc') {
      setOrder('asc');
    } else {
      setOrderBy(property);
      setOrder('asc');
    }
  };
  const handleChangePage = (event, page) => props.updatePage(page);
  const handleChangeRowsPerPage = event => setRowsPerPage(event.target.value);
  const collectionToRender = () => {
    return stableSort(userAccessReport, getSorting(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  };
  const processedCollection = collectionToRender();

  useEffect(() => {
    if (userAccessReport.length === 0) {
      return isReportAvailable(true);
    } else {
      return isReportAvailable(false);
    }
  }, [userAccessReport, isReportAvailable]);

  return (
    <Paper elevation={0} className={classes.scroll}>
      {userQuery.loading && <Loader size={36} />}
      <Table data-test-id="UserAccessReport-root">
        <TableHead>
          <TableRow>
            {rows.map(row => (
              <TableCell
                key={row.id}
                sortDirection={orderBy === row.id ? order : false}
                className={classes.dataCell}
                data-test-id={`UserAccessReport-header-${row.id}`}
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={createSortHandler(row.id)}
                  data-test-id={`UserAccessReport-sort-${row.id}`}
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {processedCollection.map(employee => (
            <TableRow
              key={employee.id}
              data-test-id={`UserAccessReport-tableRow-${employee.id}`}
            >
              {Object.keys(employee).map(
                key =>
                  key !== 'id' && (
                    <TableCell
                      key={`${key}-${employee.id}`}
                      className={classes.dataCell}
                      data-test-id={`UserAccessReport-${key}-${employee.id}`}
                    >
                      <Typography variant="subtitle1">
                        {`${
                          Array.isArray(employee[key])
                            ? employee[key].map(elem => ` ${elem}`)
                            : employee[key]
                        }`}
                      </Typography>
                    </TableCell>
                  ),
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={userAccessReport.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          'data-test-id': 'UserAccessReport-pagination-select',
        }}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
          'data-test-id': 'UserAccessReport-pagination-previous',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
          'data-test-id': 'UserAccessReport-pagination-next',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        data-test-id="UserAccessReport-pagination-root"
      />
    </Paper>
  );
};

export default compose(
  graphql(Queries.GetUserAccessReport, {
    name: 'userQuery',
    options: props => ({
      variables: {
        producerId: parseInt(props?.reportObject?.producer),
        employeeRole: props?.reportObject?.role?.label,
        userStatus: props?.reportObject?.userStatus?.label,
      },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  withStyles(styles),
)(UserAccessReport);
