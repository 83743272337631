import React from 'react';
import { withStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DocumentFieldsGroup from './DocumentFieldsGroup';
import classNames from 'class-names';

const styles = theme => ({
  root: {},
  errorRoot: {
    borderLeft: `2px solid ${theme.palette.error.main}`,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  documentFieldGroupContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridAutoRows: 'max-content',
    height: 'max-content',
    gap: '15px',
  },
});

const DocumentFieldsExpansionPanel = props => {
  const {
    classes,
    formErrors,
    inputFieldGroups,
    isExpanded,
    onChange,
    onToggleExpansion,
    templateName,
  } = props;

  const requiredFieldCount = inputFieldGroups.filter(({ required }) => required)
    .length;
  const optionalFieldCount = inputFieldGroups.length - requiredFieldCount;
  const hasErrors = Object.keys(formErrors).length > 0;
  const rootClass = classNames(classes.root, {
    [classes.errorRoot]: hasErrors,
  });

  return (
    <Accordion
      classes={{ root: rootClass }}
      data-test-id={`DocumentFieldsExpansionPanel-${templateName}`}
      expanded={isExpanded}
      onChange={onToggleExpansion}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>{templateName}</Typography>
        <Typography className={classes.secondaryHeading}>
          {requiredFieldCount} - Required Fields / {optionalFieldCount} -
          Optional Fields
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.documentFieldGroupContainer}>
        {inputFieldGroups.map(inputFieldGroup => (
          <DocumentFieldsGroup
            onChange={onChange}
            inputFields={inputFieldGroup.fields}
            isRequired={inputFieldGroup.required}
            groupName={inputFieldGroup.name}
            formErrors={formErrors.filter(
              err => err.inputFieldGroupId === inputFieldGroup.id,
            )}
            key={inputFieldGroup.id}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default withStyles(styles)(DocumentFieldsExpansionPanel);
