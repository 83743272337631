import React, { useState } from 'react';
import T from 'common/oldJavascripts/utils/i18n';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import FreeFormFields from '../FreeFormFields/Index';
// Mui Components
import { Paper, Grid, Typography, Collapse } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  ChevronRight as MoreIcon,
  KeyboardArrowDown as LessIcon,
} from '@mui/icons-material';

// css classes
const useStyles = makeStyles({
  header: { fontWeight: 'bold', marginBottom: '0px !important' },
  collapse: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 10,
  },
});

const Fields = ({
  updateDetailSubDataType,
  enforceMask,
  accountMask,
  updateEnforceMask,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <BoxItem divider={true}>
      <Typography
        variant="h5"
        gutterBottom
        style={{ fontWeight: 'bold', paddingBottom: 20 }}
      >
        <span data-test-id="Fields-title">
          {T('project.documents.manage_fields.title')}
        </span>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper elevation={0}>
            <div className={classes.collapse} onClick={() => setOpen(!open)}>
              {open ? (
                <LessIcon className="expansion-icons" />
              ) : (
                <MoreIcon className="expansion-icons" />
              )}
              <Typography variant="h6" gutterBottom className={classes.header}>
                Account Code Fields
              </Typography>
            </div>
            <Collapse in={open} timeout="auto">
              <FreeFormFields
                updateDetailSubDataType={updateDetailSubDataType}
                enforceMask={enforceMask}
                accountMask={accountMask}
                updateEnforceMask={updateEnforceMask}
              />
            </Collapse>
          </Paper>
        </Grid>
      </Grid>
    </BoxItem>
  );
};

export default Fields;
