import React, { useState } from 'react';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import {
  Button,
  Typography,
  List,
  Checkbox,
  Input,
  Paper,
  Grid,
  ThemeProvider,
} from '@mui/material';
import { ListItem, ListItemText, InputAdornment } from '@mui/material';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import SingleDocIcon from 'common/icons/SingleDocIcon';
import ProgressSpinner from 'common/components/ProgressSpinner';
import Pagination from 'common/oldJavascripts/components/Base/Pagination';
import useTemplates from 'admin/hooks/useTemplates';
import useAddTemplates from 'admin/hooks/useAddTemplates';
import { documentSignerTheme } from 'common/shared/oldDocumentSignerUI/theme';

const styles = theme => ({
  dialogContainer: {
    maxWidth: 550,
    minHeight: 600,
  },
  addButton: {
    width: 85,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    background: '#0000FF',
    '&:hover': {
      background: '#0000B3',
    },
    '&:disabled': {
      background: '#9A9FAF',
    },
  },
  header: {
    paddingTop: theme.spacing(1),
    // width: 500,
  },
  dialogContent: {
    padding: theme.spacing(1),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    width: 500,
  },
  documentText: {
    '& p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '& h3': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  search: {
    cursor: 'pointer',
  },
  documentList: {
    maxHeight: 350,
    overflow: 'auto',
    padding: 0,
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
  },
  closeButton: {
    width: 40,
    position: 'absolute !important',
    top: theme.spacing(1.2),
    right: theme.spacing(1.2),
    color: theme.palette.grey[500] + '!important',
    boxShadow: 'none',
    background: 'transparent',
    '&:hover': {
      background: theme.palette.grey[200],
      boxShadow: 'none',
    },
  },
  listContainer: {
    background: theme.palette.grey[200],
    padding: theme.spacing(2) + '!important',
    marginTop: theme.spacing(1.2) + '!important',
    height: '40vh',
    overflowY: 'auto',
    width: 500,
  },
  listItem: {
    height: theme.spacing(8),
    marginBottom: theme.spacing(1) + '!important',
  },
  confirmAddButtonContainer: {
    position: 'relative',
    float: 'right',
  },
  footer: {
    width: '500px !important',
  },
});

const AddProjectDocument = props => {
  const { classes, onClose, projectId, attachSuccessHandler } = props;
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [searchvalue, setSearchValue] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [isSaveError, setIsSaveError] = useState(false);
  const [saveErrorMessage, setSaveErrorMessage] = useState('');
  const { error, loading, data } = useTemplates(projectId, search, page);
  const [addTemplates, { loading: isSaving }] = useAddTemplates();
  const {
    templatesList: {
      items: documents = [],
      totalPages,
      totalItems,
      page: currentPage,
    } = {},
  } = data || {};
  const addButtonClass = classnames([classes.addButton], {
    [classes.buttonSuccess]: true,
  });
  const updateSelectedDocuments = id => {
    const newSelectedDocuments = selectedDocuments.includes(id)
      ? selectedDocuments.filter(docId => docId !== id)
      : [...selectedDocuments, id];
    setSelectedDocuments(newSelectedDocuments);
  };

  const documentListItems = docs =>
    docs.map(doc => (
      <Paper key={doc.id}>
        <ListItem
          button
          onClick={() => updateSelectedDocuments(doc.id)}
          className={classes.listItem}
          data-test-id={`document-list-item-${doc.id}`}
        >
          <SingleDocIcon height={35} />
          <ListItemText primary={doc.name} className={classes.documentText} />
          <Checkbox
            checked={Boolean(selectedDocuments.includes(doc.id))}
            data-test-id={`document-list-checkbox-${doc.id}`}
            classes={{ checked: classes.checked }}
            color="secondary"
          />
        </ListItem>
      </Paper>
    ));

  const handleSearch = e => {
    if (e.key === 'Enter') {
      setPage(1);
      setSearch(e.target.value);
    }
  };

  const onAddDocuments = async selectedDocuments => {
    const templateIds = selectedDocuments.map(id => id.toString());
    const variables = {
      projectId: parseInt(projectId),
      templateIds,
    };
    try {
      const { data } = await addTemplates({ variables });
      const { addProjectTemplate } = data;
      if (addProjectTemplate) {
        onClose('added');
        attachSuccessHandler(selectedDocuments);
      }
    } catch (err) {
      setIsSaveError(true);
      setSaveErrorMessage(err.message);
    }
  };

  return (
    <ThemeProvider theme={documentSignerTheme}>
      {error && <FailureAlert message={error.message} />}
      {!error && (
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          className={classes.dialogContainer}
        >
          <Grid item className={classes.dialogContent}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className={classes.header}
            >
              <Grid item>
                <Typography
                  variant="h5"
                  data-test-id="add-document-title"
                  className={classes.header}
                >
                  Add Documents
                </Typography>
                <Input
                  id="filter-documents"
                  value={searchvalue}
                  onKeyDown={handleSearch}
                  onChange={e => setSearchValue(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start" className={classes.search}>
                      <SearchIcon
                        onClick={() => {
                          setPage(1);
                          setSearch(searchvalue);
                        }}
                      />
                    </InputAdornment>
                  }
                  placeholder="Filter Documents"
                  data-test-id="add-document-filter-input"
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => onClose('')}
                  className={classes.closeButton}
                  data-test-id="close-document-modal-button"
                >
                  <CloseIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <div>
                <ProgressSpinner />
              </div>
            ) : (
              <List className={classes.listContainer}>
                {documentListItems(documents)}
              </List>
            )}
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              padding="1rem"
              className={classes.footer}
            >
              <Grid item>
                <Pagination
                  page={currentPage}
                  totalPages={totalPages}
                  totalItems={totalItems}
                  onChange={setPage}
                />
              </Grid>
              <Grid item>
                <div className={classes.confirmAddButtonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={addButtonClass}
                    onClick={() => onAddDocuments(selectedDocuments)}
                    disabled={isSaving || selectedDocuments.length === 0}
                    data-test-id="add-document-button"
                  >
                    Add
                  </Button>
                  {isSaving && <ProgressSpinner size={24} top={6} left={32} />}
                </div>
              </Grid>
            </Grid>
          </Grid>
          {isSaveError && (
            <Grid item>
              <Typography color="error">{saveErrorMessage}</Typography>
            </Grid>
          )}
        </Grid>
      )}
    </ThemeProvider>
  );
};

export default withStyles(styles)(AddProjectDocument);
