import upperFirst from 'lodash.upperfirst';
import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import { withStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import * as Queries from 'common/apollo/queries';
import * as Mutations from 'common/apollo/mutations';
import GoLiveDialog from './GoLiveDialog';
import Loader from 'common/components/Loader';
import { styled } from '@mui/system';

const POLL_INTERVAL = 5000;

const statuses = {
  pre_live: 'Waiting to go live...',
  live_failed: 'Failed to go live',
};

const styles = {
  title: {
    display: 'inline',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
};

const StyledButton = styled(Button)({
  marginLeft: 50,
  position: 'relative',
  top: -3,
  borderRadius: '0px',
});

const ProjectStatus = props => {
  const {
    classes,
    status,
    startPollingStatus,
    stopPollingStatus,
    users,
    onGoLive,
    project,
  } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (!(status === 'test' || status === 'pre_live')) stopPollingStatus();
    return stopPollingStatus;
  });

  if (!(status && users)) return <Loader />;

  const friendlyStatus = statuses[status] || upperFirst(status);
  const canGoLive = status === 'test' || status === 'live_failed';

  // TODO Move this into container
  const goLive = userIdsForDeletion => {
    startPollingStatus(POLL_INTERVAL);
    return onGoLive(userIdsForDeletion);
  };

  return (
    <React.Fragment>
      <Typography
        className={classes.title}
        variant="h5"
        gutterBottom
        data-test-id="ProjectStatus-title"
      >
        Project Status: {friendlyStatus}
      </Typography>
      {canGoLive && (
        <StyledButton
          variant="contained"
          onClick={() => setIsDialogOpen(true)}
          data-test-id="ProjectStatus-goLive"
        >
          Go Live
        </StyledButton>
      )}
      <GoLiveDialog
        onClose={() => setIsDialogOpen(false)}
        onGoLive={goLive}
        open={isDialogOpen}
        project={project}
        status={status}
        users={users}
      />
    </React.Fragment>
  );
};

// Container

const withProjectStatus = graphql(Queries.projectStatus, {
  options: ({ project: { id } }) => ({ variables: { id } }),
  props: ({
    data: {
      startPolling: startPollingStatus,
      stopPolling: stopPollingStatus,
      project: { status } = {},
    },
  }) => ({
    status,
    startPollingStatus,
    stopPollingStatus,
  }),
});

const withProjectUsers = graphql(Queries.projectUsers, {
  options: ({ project: { id } }) => ({ variables: { id } }),
  props: ({ data: { project: { users } = {} } }) => ({ users }),
});

const withProjectGoLive = graphql(Mutations.projectGoLive, {
  props: ({
    mutate,
    ownProps: {
      project: { id },
    },
  }) => ({
    onGoLive: userIdsForDeletion => {
      const userIds = userIdsForDeletion.map(id => parseInt(id));
      return mutate({ variables: { id, userIds } });
    },
  }),
});

export default compose(
  withProjectStatus,
  withProjectUsers,
  withProjectGoLive,
  withStyles(styles),
)(ProjectStatus);
