import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@mui/styles';

const styles = {
  root: {
    height: '61px',
    maxHeight: '61px',
    minHeight: '61px',
    width: '100%',
  },
};

const LayoutHeader = props => {
  const { classes, children, globalNav } = props;
  const headerClass = classnames(classes.root, {
    [classes.globalNav]: globalNav,
  });
  return <div className={headerClass}>{children}</div>;
};

export default withStyles(styles)(LayoutHeader);
