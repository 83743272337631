import gql from 'graphql-tag';

export default gql`
  mutation updateProjectTemplate(
    $projectTemplateId: ID!
    $studioPlusDirectories: [StudioPlusDirectoriesInput!]
    $studioPlusIntegrationEnabled: Boolean!
  ) {
    updateProjectTemplate(
      projectTemplateId: $projectTemplateId
      studioPlusDirectories: $studioPlusDirectories
      studioPlusIntegrationEnabled: $studioPlusIntegrationEnabled
    )
  }
`;
