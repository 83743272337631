import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Typography, TextField, Dialog } from '@mui/material';
import UsaOnly from 'common/components/UsaOnly';
import classnames from 'classnames';

const signatureStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f7fcfc',
  },
  title: {
    color: '#0000FF',
    fontSize: '20px !important',
    marginBottom: '10px !important',
    paddingTop: '40px !important',
  },
  signatureSection: {
    boxShadow: '0 -3 7 rgba(0, 0, 0, 0.05), 0 8 7 rgba(0, 0, 0, 0.05)',
    backgroundColor: '#ffffff',
    padding: '20px',
  },
  yesBtn: {
    width: 247,
    height: '40px',
    borderRadius: '2px !important',
    margin: '15px 0px !important',
    '&:hover': {
      background: '#1A55FD',
    },
    '&:active': {
      background: '#0000B3',
    },
  },
  noBtn: {
    color: '#0000FF',
  },
  pleaseNote: {
    color: '#646464',
    fontSize: '14px !important',
    marginBottom: '10px !important',
    padding: '0 15px',
  },
  bold: {
    fontWeight: 500,
  },
  topSection: {
    margin: '0 20px 20px',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formFields: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  textField: {
    marginLeft: theme.spacing(1) + ' !important',
    marginRight: theme.spacing(1) + ' !important',
    width: '90%',
    background: '#F7FCFC',
  },
  legalCopy: {
    fontSize: '12px !important',
    backgroundColor: 'white',
    padding: '20px 10px',
    height: '112px',
    overflowY: 'auto',
    marginTop: '25px !important',
    position: 'relative',
  },
  messageText: {
    fontSize: '12px !important',
    marginLeft: '18px !important',
  },
  userGuideText: {
    color: 'rgba(0, 0, 0, 0.6) !important',
  },
  errorMessageText: {
    color: 'red',
  },
  subTitle: {
    color: '#646464',
    fontSize: 14,
    fontWeight: 400,
    marginTop: '10px',
  },
  input: {
    backgroundColor: '#f7fcfc !important',
    '& input': {
      fontSize: '16px',
    },
  },
  label: {
    color: '#0000FF !important',
  },
}));

const MobileSaveSignatureDialog = ({ actor, onSubmit, open }) => {
  const classes = signatureStyles();
  const [state, setState] = useState({
    fullName: '',
    initials: '',
    fullNameError: false,
    initialsError: false,
    isMatchingNameError: false,
    isMatchingInitialsError: false,
  });
  const {
    fullNameError,
    initialsError,
    fullName,
    initials,
    isMatchingNameError,
    isMatchingInitialsError,
  } = state;
  const { name: profileName, initials: profileInitials } = actor;

  const close = () => onSubmit(false);

  const confirm = () => {
    const hasMatchingNameErrors = verifyMatchingLegalNames();
    setState(state => ({
      ...state,
      fullNameError: fullName === '',
      initialsError: initials === '',
    }));
    if (fullName === '' || initials === '') return;
    if (hasMatchingNameErrors) return;
    onSubmit(true);
  };

  const verifyMatchingLegalNames = () => {
    const isNameError =
      fullName.toLowerCase().trim() !== profileName.toLowerCase().trim();
    const isInitialsError =
      initials.toLowerCase().trim() !== profileInitials.toLowerCase().trim();
    setState(state => ({
      ...state,
      isMatchingNameError: fullName === '' ? false : isNameError,
      isMatchingInitialsError: initials === '' ? false : isInitialsError,
    }));
    return isNameError || isInitialsError;
  };

  const handleLegalName = fullName => {
    setState(state => ({
      ...state,
      fullName,
    }));
  };

  const handleInitials = initials => {
    setState(state => ({
      ...state,
      initials,
    }));
  };

  const errorMessageClass = classnames(
    classes.errorMessageText,
    classes.messageText,
  );
  const messageTextClass = classnames(
    classes.userGuideText,
    classes.messageText,
  );

  return (
    <Dialog
      fullScreen
      open={open}
      data-test-id="MobileSaveSignatureDialog-root"
    >
      <div className={classes.root}>
        <div className={classes.topSection}>
          <Typography
            className={classes.title}
            data-test-id="MobileSaveSignatureDialog-title"
          >
            Saving your signature and initials for this session speeds up the
            submission process.
          </Typography>
        </div>
        <div className={classes.signatureSection}>
          <UsaOnly>
            <Typography className={classes.pleaseNote}>
              <span className={classes.bold}>PLEASE NOTE</span> To comply with
              federal I-9 regulations, employees must continue to type their
              signatures directly on the form.
            </Typography>
          </UsaOnly>
          <div className={classes.formFields}>
            <TextField
              id="legal-name"
              className={classes.textField}
              data-test-id="MobileSaveSignatureDialog-name"
              label="Full Legal Name"
              margin="normal"
              variant="filled"
              InputLabelProps={{
                className: classes.label,
              }}
              InputProps={{
                className: classes.input,
              }}
              onChange={e => handleLegalName(e.target.value)}
              error={fullNameError}
            />
            <span>
              {fullNameError && (
                <Typography
                  className={errorMessageClass}
                  data-test-id="MobileSaveSignatureDialog-nameMissing"
                >
                  Full Legal Name is required
                </Typography>
              )}
              {isMatchingNameError && (
                <Typography
                  className={errorMessageClass}
                  data-test-id="MobileSaveSignatureDialog-nameError"
                >
                  Please enter your full legal name as recorded in Start+:
                </Typography>
              )}
              <Typography
                className={messageTextClass}
                data-test-id="MobileSaveSignatureDialog-profileName"
              >
                {profileName}
              </Typography>
            </span>
            <TextField
              id="initials"
              className={classes.textField}
              data-test-id="MobileSaveSignatureDialog-initials"
              label="Initials"
              margin="normal"
              variant="filled"
              InputLabelProps={{
                className: classes.label,
              }}
              InputProps={{
                className: classes.input,
              }}
              onChange={e => handleInitials(e.target.value)}
              error={initialsError}
            />
            {initialsError && (
              <Typography
                className={errorMessageClass}
                data-test-id="MobileSaveSignatureDialog-initialsMissing"
              >
                Initials are required
              </Typography>
            )}
            {isMatchingInitialsError && (
              <Typography
                className={errorMessageClass}
                data-test-id="MobileSaveSignatureDialog-initialsError"
              >
                Please enter your initials as recorded in Start+:
              </Typography>
            )}
            <Typography
              className={messageTextClass}
              data-test-id="MobileSaveSignatureDialog-profileInitials"
            >
              {profileInitials}
            </Typography>
          </div>
          <div className={classes.btnContainer}>
            <Button
              className={classes.yesBtn}
              onClick={confirm}
              color="primary"
              variant="contained"
              data-test-id="MobileSaveSignatureDialog-save"
            >
              Yes, Save My Signature
            </Button>
            <Typography
              onClick={close}
              data-test-id="MobileSaveSignatureDialog-dontSave"
              className={classes.noBtn}
            >
              No, I'll manually sign my signature
            </Typography>
          </div>
          <Typography
            className={classes.legalCopy}
            data-test-id="MobileSaveSignatureDialog-legalCopy"
          >
            Only the person associated with these records is permitted to use
            this system through this account. Completion and submission of the
            online forms available through this system will create legally
            binding obligations associated with each form. Please read each form
            carefully. Execution of each form will be accomplished through
            clicking on the “Sign Here” button, where required on the form. By
            clicking “Sign Here”, you are acknowledging that you have read the
            applicable form, understand the requirements for submission of the
            information, agree that the information you are submitting is
            complete and accurate information, and that the form can be
            submitted and signed by you through this system.
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};
export default MobileSaveSignatureDialog;
