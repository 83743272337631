import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query offerExternalDocuments($offerId: Int!) {
    offer(id: $offerId) {
      id
      externalDocuments {
        id
        documentId
        name
        source
        type
      }
    }
  }
`;

const useOfferExternalDocuments = offerId => {
  const { error, loading, data } = useQuery(QUERY, {
    skip: !parseInt(offerId, 10),
    variables: { offerId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  return { error, loading, data: data?.offer?.externalDocuments };
};

export default useOfferExternalDocuments;
