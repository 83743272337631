import React, { Component } from 'react';

import { withStyles } from '@mui/styles';

import OfferDocumentsStatus from 'studio/components/OfferDocumentsStatus';
import DocumentApprovalStatus from 'studio/components/DocumentApprovalStatus';
import { ThemeProvider, Typography } from '@mui/material';

import { documentSignerTheme } from 'common/shared/oldDocumentSignerUI/theme';

const styles = () => ({
  root: {
    width: '100%',
  },
  groupedAckowledgement: {
    marginLeft: '70px',
    marginTop: '10px',
    fontWeight: 700,
  },
});

class OfferDocuments extends Component {
  static defaultProps = {
    documentStatus: [],
    offerId: '',
  };

  state = {
    approvalDrawerOpen: false,
  };

  handleCloseApprovalDrawer = () => {
    this.setState({ approvalDrawerOpen: false });
  };

  handleOpenApprovalDrawer = documentId => {
    const { requestDocumentStatus, offerId } = this.props;
    requestDocumentStatus(offerId, documentId);
    this.setState({ approvalDrawerOpen: true });
  };

  employeeAcknowledgementText = acknowledgeType => {
    switch (acknowledgeType) {
      case 'C':
        return 'Employee acknowledgement of receipt';
      case 'G':
        return 'Employee grouped acknowledgement of receipt';
      default:
        return '';
    }
  };

  render() {
    const {
      documents = [],
      selectedDocument = {},
      documentStatus = [],
      classes = {},
      status,
      keepI9 = false,
      previewDocument,
      removeDocument,
      offerStatus,
      hirerCanSendDocuments,
      approverCanRemoveDocuments,
      isDocsMoreThanOne,
    } = this.props;
    const { approvalDrawerOpen } = this.state;
    const {
      id: selectedDocumentId = null,
      acknowledge_type: acknowledgeType = null,
      crew_acknowledged: crewAcknowledged = false,
    } = selectedDocument;
    const showAcknowledgement = crewAcknowledged || acknowledgeType === 'G';

    return (
      <ThemeProvider theme={documentSignerTheme}>
        <div className={classes.root}>
          <OfferDocumentsStatus
            documents={documents}
            status={status}
            keepI9={keepI9}
            selectedDocumentId={selectedDocumentId}
            handleDocumentSelect={this.handleOpenApprovalDrawer}
            previewDocument={previewDocument}
            isDocsMoreThanOne={isDocsMoreThanOne}
            removeDocument={removeDocument}
            approverCanRemoveDocuments={approverCanRemoveDocuments}
            offerStatus={offerStatus}
            hirerCanSendDocuments={hirerCanSendDocuments}
          />
          {approvalDrawerOpen && (
            <DocumentApprovalStatus
              open
              closeDrawer={this.handleCloseApprovalDrawer}
              data={documentStatus}
              selectedDocument={selectedDocument}
              previewDocument={previewDocument}
              removeDocument={removeDocument}
            />
          )}
          {approvalDrawerOpen && showAcknowledgement && (
            <Typography
              className={classes.groupedAckowledgement}
              data-test-id={'OfferDocuments-groupedAckowledgement'}
              variant="h6"
            >
              {this.employeeAcknowledgementText(acknowledgeType)}
            </Typography>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(OfferDocuments);
