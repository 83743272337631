import React, { useEffect } from 'react';

import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import OfferDocuments from 'studio/components/OfferDocuments';
import Loader from 'common/components/Loader';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';

import useOfferDocuments from './hooks/useOfferDocuments';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

const Documents = props => {
  const {
    offerId,
    actionClickHandler,
    expandedWorkflow,
    onPreviewDocument,
    removeDocument,
    hirerCanSendDocuments,
    reloadDocuments = false,
    handleStatusChange,
  } = props;

  const { data: offer, loading, error, refetch } = useOfferDocuments(
    parseInt(offerId),
  );

  const featureFlags = useFeatureFlags();
  const isStatew4FlagActive = featureFlags.includes('StateW4');

  useEffect(() => {
    const docs = offer?.documentDetails || [];
    const isPendingDocsOne =
      docs.filter(doc => doc.status !== 'rejected' && doc.status !== 'approved')
        .length === 1;
    if (isPendingDocsOne) {
      handleStatusChange(isPendingDocsOne);
    }

    if (reloadDocuments) {
      refetch();
    }
  }, [reloadDocuments]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Loader />;
  if (error) return <FailureAlert queryName="offerDocuments" />;

  const {
    documentDetails: docs = [],
    removedDocuments = [],
    loanoutCorporation = {},
    externalDocuments = [],
    status,
    keepI9 = false,
  } = offer || {};
  const { w9 } = loanoutCorporation || {};

  let externalDocs = isStatew4FlagActive
    ? externalDocuments.map(doc => ({
        id: doc.documentId,
        status: status === 'rejected' ? 'rejected' : 'approved',
        isExternal: true,
        name: doc.name,
      }))
    : [];

  if (w9?.id) {
    w9['isW9'] = true;
    w9['status'] = status === 'rejected' ? 'rejected' : 'approved';
  }

  const isDocsMoreThanOne =
    docs.filter(doc => doc.status !== 'rejected').length > 1;
  const documents = [...docs, w9, ...externalDocs, ...removedDocuments];

  if ((docs || []).length === 0 && !w9?.id) {
    return <Blankslate>No Documents Defined</Blankslate>;
  }
  const selectedDocument = documents?.find(
    d => expandedWorkflow[offerId] === d.id,
  );
  const documentWorkflow = selectedDocument?.workflow || [];

  return (
    <OfferDocuments
      offerId={offerId}
      documents={documents}
      selectedDocument={selectedDocument}
      documentStatus={documentWorkflow}
      status={status}
      keepI9={keepI9}
      requestDocumentStatus={actionClickHandler}
      previewDocument={onPreviewDocument}
      isDocsMoreThanOne={isDocsMoreThanOne}
      removeDocument={removeDocument}
      offerStatus={offer?.status}
      hirerCanSendDocuments={hirerCanSendDocuments}
    />
  );
};

export default Documents;
