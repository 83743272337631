import React, { useState, useEffect } from 'react';

// HoC
import { withStyles } from '@mui/styles';

// Material UI
import { List } from '@mui/material';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ListItemText from '@mui/material/ListItemText';

// Material UI Icons
import {
  CheckCircle as CheckIcon,
  Error as ErrorIcon,
} from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Components
import DocumentSelectorField from './DocumentSelectorField';

// Utilities
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';
import * as palette from '../../shared/oldDocumentSignerUI/palette';

const styles = theme => ({
  emptyDocName: {
    color: palette.grey,
    fontStyle: 'italic',
  },
  statusText: {
    fontSize: 13,
    marginTop: 4,
  },
  secondaryItemText: {
    padding: 0,
  },
  incomplete: {
    color: palette.grey,
  },
  success: {
    color: palette.success2,
  },
  error: {
    color: theme.palette.error.main,
  },
  docName: {
    fontSize: '1.2rem',
  },
  i9Instructions: {
    background: '#e3f1f6',
    padding: 8,
    margin: '6px 6px 2px 6px',
  },
  i9Link: {
    '&:hover': {
      cursor: 'pointer',
    },
    color: '#0000FF',
    fontWeight: 'bold',
  },
  i9Text: {
    fontSize: 13,
  },
  documentDetails: {
    padding: 0,
  },
  documentSummary: {
    display: 'grid',
    gridTemplateColumns: '30px 1fr',
    columnGap: '12px',
    alignItems: 'center',
    wordBreak: 'break-all',
  },
  documentStatusMessage: {
    paddingLeft: 8,
  },
  summaryExpanded: {
    boxShadow: '0 2px 2px rgba(209, 209, 209, 1)',
    zIndex: 2,
    borderLeft: '4px solid #5aaaa9 !important',
  },
  summaryRoot: {
    borderLeft: '4px solid white',
  },
  documentWarning: {
    color: '#FF7800',
    marginTop: 4,
  },
  documentSuccess: {
    color: '#008006',
    marginTop: 4,
  },
  fieldList: {
    width: '100%',
    backgroundColor: '#f4f8f7',
  },
});

const DocumentSelectorPanel = props => {
  const {
    doc,
    isExpanded: upstreamIsExpanded,
    setCurrentDocument,
    classes,
    onSelectAcknowledgment,
    goToField,
  } = props;
  const {
    id,
    fields = [],
    categories = [],
    templateName,
    acknowledge_type,
    crew_acknowledged,
    _derived: { error: wholeDocError, isComplete, isViewable },
  } = doc;

  const [isExpanded, setIsExpanded] = useState(upstreamIsExpanded);
  useEffect(() => {
    setIsExpanded(upstreamIsExpanded);
  }, [upstreamIsExpanded]);

  const docNameClass = classNames(classes.docName, {
    [classes.emptyDocName]: !isViewable,
  });

  const acknowledgmentField = acknowledge_type === 'C' && {
    id: 'ACK',
    value: crew_acknowledged,
    _derived: { fieldType: 'ACK' },
  };

  const isFieldError = fields.some(({ _derived: { error } }) => error);

  const documentStatusIcon = (() => {
    if (wholeDocError || isFieldError)
      return (
        <ErrorIcon
          className={classes.error}
          data-test-id={`DocumentSelector-documentStatusIcon-${id}`}
        />
      );
    if (isComplete)
      return (
        <CheckIcon
          className={classes.success}
          data-test-id={`DocumentSelector-documentStatusIcon-${id}`}
        />
      );
    return (
      <CheckIcon
        className={classes.incomplete}
        data-test-id={`DocumentSelector-documentStatusIcon-${id}`}
      />
    );
  })();

  const filteredFields = fields.filter(
    ({ _derived: { error, isRequired } }) => error || isRequired,
  );

  const i9Instructions = categories.some(
    ({ name = '' }) =>
      name.toLowerCase().includes('i9') || name.toLowerCase().includes('i-9'),
  ) && (
    <div
      className={classes.i9Instructions}
      data-test-id="DocumentSelector-i9Instructions"
    >
      <Typography
        className={classes.i9Text}
        data-test-id="DocumentSelector-i9InstructionsText"
      >
        Need help? View{' '}
        <Link
          className={classes.i9Link}
          to={'/samples/i-9instr.pdf'}
          data-test-id="DocumentSelector-i9InstructionsLink"
          component={RouterLink}
          target="_blank"
        >
          I-9 Instructions
        </Link>{' '}
        on how to fill out this form.
      </Typography>
    </div>
  );

  const documentStatusMessage = (() => {
    let message = 'Form is not complete';
    if (wholeDocError) {
      if (wholeDocError.message) {
        message = wholeDocError.message;
      } else {
        message = 'There was an error saving this form';
      }
    }
    if (isComplete) message = 'Form is complete';
    if (isFieldError) message = 'This form was completed with errors';
    return (
      <Typography
        className={classNames(classes.documentStatusMessage, {
          [classes.documentWarning]: !isComplete || isFieldError,
          [classes.documentSuccess]: isComplete,
        })}
        data-test-id="DocumentSelector-statusMessage"
      >
        {message}
      </Typography>
    );
  })();

  return (
    <Accordion
      key={id}
      data-test-id={`DocumentSelector-document-${id}`}
      expanded={isExpanded}
    >
      <AccordionSummary
        onClick={() => {
          setCurrentDocument(id);
          setIsExpanded(!isExpanded);
        }}
        disabled={!isViewable}
        classes={{
          content: classes.documentSummary,
          root: classNames(classes.summaryRoot, {
            [classes.summaryExpanded]: isExpanded,
          }),
        }}
        expandIcon={<ExpandMoreIcon />}
        data-test-id={`DocumentSelectorPanel-panelSummary-${id}`}
      >
        {documentStatusIcon}
        <Typography
          className={docNameClass}
          data-test-id={`DocumentSelector-docName-${id}`}
        >
          {templateName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.documentDetails }}>
        <List component="div" disablePadding className={classes.fieldList}>
          <ListItemText
            secondary={
              <React.Fragment>
                {i9Instructions}
                {documentStatusMessage}
              </React.Fragment>
            }
            classes={{
              secondary: classes.statusText,
              root: classes.secondaryItemText,
            }}
            data-test-id={`DocumentSelector-documentStatusText-${id}`}
          />
          {acknowledgmentField && (
            <DocumentSelectorField
              field={acknowledgmentField}
              goToField={onSelectAcknowledgment}
            />
          )}
          {filteredFields.map(field => (
            <DocumentSelectorField
              field={field}
              goToField={() => goToField(field.id)}
              key={field.id}
            />
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default withStyles(styles)(DocumentSelectorPanel);
