import React, { useEffect } from 'react';
import HourlyRateTable from './NonUnionTermsOfEmploymentTable/HourlyRateTable';
import WeeklyRateTable from './NonUnionTermsOfEmploymentTable/WeeklyRateTable';
import DailyRateTable from './NonUnionTermsOfEmploymentTable/DailyRateTable';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
  tableRoot: {
    marginTop: 30,
    width: '100%',
  },
}));

const theme = createTheme({
  overrides: {
    MuiInput: {
      input: {
        fontSize: 16,
        fontWeight: 'normal',
        textAlign: 'center',
        width: 196,
        top: 3,
        color: '#525252',
      },
      underline: {
        width: 196,
        color: '#696969',
        '&:before': {
          borderBottom: `1px solid #8D8D8D`,
        },
        '&$focused:not($disabled):before': {
          borderBottom: `2px solid #0000FF`,
        },
        '&:after': {
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          borderBottom: `2px solid #0000FF`,
        },
        '&$error:not($disabled):before': {
          borderBottom: `1px solid #ED1C23`,
        },
      },
      disabled: {
        '&:before': {
          borderBottom: `1px solid #C6C6C6`,
        },
      },
    },
    MuiSelect: {
      root: {
        paddingRight: '0!important',
        '&:hover:not($disabled)': {
          backgroundColor: '#FFFFFF',
          color: '#525252',
        },
      },
      selected: {
        backgroundColor: '#D9E3FE',
      },
    },
    MuiMenuItem: {
      root: {
        color: '#525252',
        fontSize: 16,
        fontWeight: 400,
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        '&:hover:not($disabled)': {
          backgroundColor: '#e0e0e0',
        },
        '&$selected': {
          width: 196,
          height: 40,
          backgroundColor: '#D9E3FE',
          color: '#0000FF',
          fontSize: 16,
          fontWeight: 400,
          fontStyle: 'normal',
          letterSpacing: 'normal',
          lineHeight: 22,
          textAlign: 'left',
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
});

const NonUnionRateTable = (props = {}) => {
  const { onChange: upstreamOnChange, workScheduleCode } = props;
  const classes = useStyles();

  /**
   * Note: Whenever this component is rendered, we are using this key as an
   * indicator to the form object in edit offer
   * state as an indicator to ensure we are showing the right
   * data and to ensure the submit offer goes through.
   * A better solution should be added after the deprecation of termsOfEmploymentV2 key
   * from the backend as it is the primary source of this constraint
   */
  useEffect(() => {
    upstreamOnChange({
      isTermsOfEmploymentV2: true,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isScheduleA = ['A', 'OT', 'CR', 'FS', 'PO', 'XT', 'M'].includes(
    workScheduleCode,
  );
  const isScheduleB = ['B'].includes(workScheduleCode);
  const isScheduleC = ['C', 'E', 'CN'].includes(workScheduleCode);
  const isScheduleD = ['D'].includes(workScheduleCode);
  const isHourlySchedule = isScheduleA || isScheduleB;

  return (
    <div className={classes.tableRoot}>
      <ThemeProvider theme={theme}>
        {isHourlySchedule && <HourlyRateTable {...props} />}
        {isScheduleC && <WeeklyRateTable {...props} />}
        {isScheduleD && <DailyRateTable {...props} />}
      </ThemeProvider>
    </div>
  );
};

export default NonUnionRateTable;
