import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query states($countryId: Int!) {
    states(countryId: $countryId) {
      id
      name
      code
    }
  }
`;

const useStates = countryId => {
  const { loading, data } = useQuery(QUERY, {
    skip: !countryId,
    variables: { countryId: parseInt(countryId) },
  });
  return { loading, data: data && data.states };
};

export default useStates;
