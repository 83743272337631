import React from 'react';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';
import ExpandableTableRowInfo from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowInfo';
import ExpandableTableRowHeader from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowHeader';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import QUESTIONMARK_ICON from 'common/images/questionmark.svg';

const TEXT =
  'For California Pay Data Reporting, this employee ' +
  'is entirely remote, teleworking, or home-based, ' +
  'and has no expectation to regularly report in ' +
  'person to a physical establishment to perform, work duties.';

const useStyles = makeStyles(theme => ({
  optOutText: {
    marginLeft: '20px',
  },
  customOccupationDescriptionText: {
    marginLeft: '8px',
  },
  termsOfHireText: {
    marginLeft: '10px',
    marginBottom: '20px',
    display: 'flex',
  },
  termsOfHireSubHeader: {
    fontWeight: 600,
    marginRight: '5px',
  },
  noDeptText: {
    fontStyle: 'italic',
  },
  offerDocuments: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-10px',
  },
  docCount: {
    marginTop: '-7px',
  },
  termsOfHireTable: {
    display: 'Flex',
  },
  linkColor: {
    color: '#0000FF',
    textDecoration: 'underline',
  },
  tooltipIcon: {
    position: 'absolute',
    right: '-8px',
    top: '-10px',
  },
  offsiteContent: {
    position: 'relative',
    marginRight: '14px',
  },
  popper: { maxWidth: '380px !important' },
  termsOfHireTextUsaRightInfo: { width: '50%' },
  termsOfHireTextCanadaRightInfo: { width: '50%' },
  termsOfHireTextUsaLeftInfo: { width: '50%' },
  termsOfHireTextCanadaLeftInfo: { width: '50%' },
}));

const TermsOfHire = ({ offer }) => {
  const classes = useStyles();
  const {
    id,
    termsOfHire: {
      season,
      hireState,
      workState,
      hireCity,
      workCity,
      hiringStatus,
      employmentClassification,
      union,
      occupation,
      workSchedule,
      department,
      subDepartment,
      currency,
      fullyOffsiteWorker,
      trackOffsiteWorkers,
    } = {},
    customOccupationDescription = null,
  } = offer;
  const hireCityName = hireCity?.name;
  const hireStateName = hireState?.name;
  const workStateName = workState?.name;
  const workCityName = workCity?.name;
  const unionName = union?.description;
  const occupationName = occupation?.description;
  const workScheduleDescription = workSchedule?.description;
  const departmentName = department?.name;
  const isFullyOffsiteWorker = fullyOffsiteWorker ? 'Yes' : '';

  const formatEmploymentClassification =
    employmentClassification && employmentClassification === 'loanOut'
      ? 'Loan-out'
      : employmentClassification;

  const formatEmploymentClassificationCAN =
    employmentClassification && employmentClassification === 'loanOut'
      ? 'Loan-out'
      : 'Individual';

  return (
    <>
      <ExpandableTableRow key={`terms-of-hire-${id}`} rowExpanded={true}>
        <ExpandableTableCell colSpan={8} expanded={true}>
          <ExpandableTableRowHeader>Terms of Hire</ExpandableTableRowHeader>
          <UsaOnly>
            <div className={classes.termsOfHireTable}>
              <div className={classes.termsOfHireTextUsaLeftInfo}>
                <ExpandableTableRowInfo>
                  {!(season === null) && (
                    <div className={classes.termsOfHireText}>
                      <p className={classes.termsOfHireSubHeader}>Season:</p>
                      <p>{season?.label}</p>
                    </div>
                  )}
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Hire State:{''}
                    </p>
                    <p>{hireStateName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Hire City:{''}
                    </p>
                    <p>{hireCityName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Work State:{''}
                    </p>
                    <p>{workStateName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Primary Work City:{' '}
                    </p>
                    <p>{workCityName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      ACA Hiring Status:{' '}
                    </p>
                    <p>
                      {hiringStatus === 'full_time'
                        ? 'Full Time'
                        : hiringStatus}
                    </p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Employment Classification:{' '}
                    </p>
                    <p>{formatEmploymentClassification}</p>
                  </div>
                </ExpandableTableRowInfo>
              </div>
              <div className={classes.termsOfHireTextUsaRightInfo}>
                <ExpandableTableRowInfo>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>Union:</p>
                    <p>{unionName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>Occupation:</p>
                    <p>{occupationName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Work Schedule:{' '}
                    </p>
                    <p>{workScheduleDescription}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>Department:</p>
                    <p>{departmentName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Associated Hours+ Department:{' '}
                    </p>
                    <p className={`${subDepartment ? '' : classes.noDeptText}`}>
                      {subDepartment ? subDepartment?.name : 'Not Selected'}
                    </p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Job Description:
                    </p>
                    <p>{customOccupationDescription}</p>
                  </div>
                  {trackOffsiteWorkers && (
                    <div className={classes.termsOfHireText}>
                      <div className={classes.offsiteContent}>
                        <p className={classes.termsOfHireSubHeader}>
                          Fully Offsite Worker:
                        </p>
                        <Tooltip
                          interactive
                          classes={{ tooltip: classes.popper }}
                          title={
                            <div>
                              <Typography>
                                {TEXT}
                                <br></br>
                                <br></br> For more information:{' '}
                                <a
                                  href="https://calcivilrights.ca.gov/paydatareporting/faqs/"
                                  className={classes.linkColor}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  California Pay Data Reporting FAQ{' '}
                                </a>
                              </Typography>
                            </div>
                          }
                        >
                          <img
                            className={classes.tooltipIcon}
                            src={QUESTIONMARK_ICON}
                            alt="schedule tooltip"
                          />
                        </Tooltip>
                      </div>
                      <p>{isFullyOffsiteWorker}</p>
                    </div>
                  )}
                </ExpandableTableRowInfo>
              </div>
            </div>
          </UsaOnly>
          <CanadaOnly>
            <div className={classes.termsOfHireTable}>
              <div className={classes.termsOfHireTextCanadaLeftInfo}>
                <ExpandableTableRowInfo>
                  {!(season === null) && (
                    <div className={classes.termsOfHireText}>
                      <p className={classes.termsOfHireSubHeader}>Season:</p>
                      <p>{season?.label}</p>
                    </div>
                  )}
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Hire Province:{' '}
                    </p>
                    <p>{hireStateName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Work Province:{' '}
                    </p>
                    <p>{workStateName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>Currency: </p>
                    <p>{currency}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Employment Classification:{' '}
                    </p>
                    <p>{formatEmploymentClassificationCAN}</p>
                  </div>
                </ExpandableTableRowInfo>
              </div>
              <div className={classes.termsOfHireTextCanadaRightInfo}>
                <ExpandableTableRowInfo>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>Union:</p>
                    <p>{unionName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>Occupation:</p>
                    <p>{occupationName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Work Schedule:{' '}
                    </p>
                    <p>{workScheduleDescription}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>Department:</p>
                    <p>{departmentName}</p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Associated Hours+ Department:{' '}
                    </p>
                    <p className={`${subDepartment ? '' : classes.noDeptText}`}>
                      {subDepartment ? subDepartment?.name : 'Not Selected'}
                    </p>
                  </div>
                  <div className={classes.termsOfHireText}>
                    <p className={classes.termsOfHireSubHeader}>
                      Job Description:
                    </p>
                    <p>{customOccupationDescription}</p>
                  </div>
                </ExpandableTableRowInfo>
              </div>
            </div>
          </CanadaOnly>
        </ExpandableTableCell>
      </ExpandableTableRow>
    </>
  );
};

export default TermsOfHire;
