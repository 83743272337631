import React from 'react';
import { styled } from '@mui/system';
import MuiCheckbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import isFilledIn from '../../utilities/isFilledIn';
import * as palette from '../../shared/oldDocumentSignerUI/palette';

// Define styles directly using styled-components
const StyledCheckbox = styled(MuiCheckbox)(
  ({ x, y, height, width, status }) => ({
    position: 'absolute',
    top: y,
    left: x,
    height,
    width,
    '& .MuiSvgIcon-root': {
      height: height * 1.55,
      width: height * 1.55,
    },
    ...(status === 'error' && {
      backgroundColor: palette.transparentRed,
    }),
    ...(status === 'success' && {
      backgroundColor: palette.transparentGreen,
    }),
    ...(status === 'required' && {
      backgroundColor: palette.transparentYellow,
    }),
    ...(status === 'readOnly' && {
      color: palette.black,
    }),
    ...(status === 'optional' && {
      backgroundColor: palette.transparentBlue,
    }),
  }),
);

const determineFieldStatus = ({
  value,
  _derived: { error, isDisabled, isRequired },
}) => {
  if (error) return 'error';
  if (isRequired) {
    if (isFilledIn(value)) return 'success';
    return 'required';
  }
  if (isDisabled) return 'readOnly';
  return 'optional';
};

const Checkbox = props => {
  const {
    id,
    name,
    x,
    y,
    height,
    width,
    handleChange,
    value,
    setFieldRef,
    _derived: { isDisabled },
  } = props;

  const fieldStatus = determineFieldStatus(props);

  const checked = value === true || `${value}`.toLowerCase() === 'true';

  return (
    <StyledCheckbox
      id={name}
      inputRef={e => setFieldRef(e, y)}
      icon={<CheckBoxOutlineBlankIcon />}
      checkedIcon={<CheckBoxIcon />}
      color="primary"
      checked={checked}
      onChange={e => handleChange(e.target.checked)}
      data-test-id={`Checkbox-root-${id}`}
      disabled={isDisabled}
      inputProps={{
        'data-test-id': `Checkbox-input-${id}`,
        'data-checked': String(value),
      }}
      x={x}
      y={y}
      height={height}
      width={width}
      status={fieldStatus}
    />
  );
};

export default Checkbox;
