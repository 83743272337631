import React from 'react';
import { withStyles } from '@mui/styles';

const styles = _theme => {
  return {
    root: {
      fontSize: '0.95rem',
    },
  };
};

const StudioPlusFolderListError = props => {
  const { classes } = props;

  return (
    <span
      className={classes.root}
      severity="error"
      data-test-id="StudioPlusFolderSelector-error"
    >
      <span>No destination directory set</span>
    </span>
  );
};

export default withStyles(styles)(StudioPlusFolderListError);
