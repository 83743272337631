import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query archives($projectId: Int!) {
    archives(projectId: $projectId) {
      id
      firstName
      lastName
      position
      sentDate
      approvedDate
    }
  }
`;

const useManualArchives = projectId => {
  const { error, loading, data } = useQuery(QUERY, {
    skip: !projectId,
    variables: { projectId: parseInt(projectId) },
    fetchPolicy: 'no-cache',
  });
  return { error, loading, data: data?.archives };
};

export default useManualArchives;
