import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DesktopOnboardingRoutes from 'onboarding/components/Routes';
import MobileOnboardingRoutes from 'onboarding/components/MobileRoutes';
import Home from 'common/containers/Home';
import Library from 'admin/containers/Library';
import Profile from 'common/containers/Profile';
import Projects from 'common/containers/Projects';
import NonCrewProfile from 'common/containers/NonCrewProfile';
import NotificationsContainer from 'common/containers/NotificationsContainer';
import GlobalPermissions from 'admin/containers/GlobalPermissions';
import Tools from 'admin/containers/Tools';
import MobileRoutes from 'mobile/components/MobileRoutes';
import useIsMobile from 'common/hooks/useIsMobile';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import withApi from 'common/hoc/withApi';

const Routes = props => {
  const isMobile = useIsMobile();
  const flags = useFeatureFlags();
  const { me: data } = props;
  const {
    data: { is_admin: isAdmin, is_super_admin: isSuperAdmin },
  } = data;
  // Feature component is incompatible with Route so do this the hard way
  const isMobileOnboardingFlagActive = flags.includes('mobileOnboarding');

  return (
    <Switch>
      <Redirect from="/offers" to="/onboarding" />
      {isMobile && isMobileOnboardingFlagActive ? (
        <Route path="/onboarding" component={MobileOnboardingRoutes} />
      ) : (
        <Route path="/onboarding" component={DesktopOnboardingRoutes} />
      )}
      {isMobile && <MobileRoutes />}
      <Route path="/projects" component={Projects} />
      <Route path="/library" component={Library} />
      <Route
        path={[
          '/global-permissions',
          '/global-permissions/permissions',
          '/global-permissions/guild',
        ]}
        component={GlobalPermissions}
      />
      <Route path="/account" component={Profile} />
      <Route path="/profile" component={NonCrewProfile} />
      <Route path="/notifications" component={NotificationsContainer} />
      {(isAdmin || isSuperAdmin) && <Route path="/tools" component={Tools} />}
      <Route component={Home} />
    </Switch>
  );
};

Routes.queries = {
  me: {
    info() {
      return { id: '/me' };
    },
  },
};

export default withApi(Routes);
