import { all, select, put, takeEvery } from 'redux-saga/effects';
import ajax from 'common/utilities/ajax';
import * as Actions from 'common/store/actions/resources';
import { RESOURCE, SUB_RESOURCE } from 'common/store/actions/resources';
import {
  REQUEST,
  GET,
  POST,
  PUT,
  DELETE,
} from 'common/store/actions/actionTypes';

// Actions
import { logout as requestLogout } from 'common/store/actions/authenticate';

// **** HELPERS ****

const invalidateDependentUrls = dependentUrls =>
  dependentUrls.map(url => put(Actions.invalidateResource({ url })));

const selectCollectionContainingItem = itemUrl =>
  select(({ resources }) =>
    Object.keys(resources).find(key => {
      const res = resources[key];
      return (
        res &&
        res.data &&
        res.data.items &&
        res.data.items.find(item => item._id === itemUrl)
      );
    }),
  );

const storeCollectionItems = data => {
  const { collection, items } = data;
  if (!(collection && items)) return;
  return items.map(item => {
    const { _id } = item;
    if (!_id) {
      throw new Error(`Collection item does not have an '_id' property.`);
    }
    return put(Actions.getResourceSuccess({ url: _id, data: item }));
  });
};

const handleResourceError = function*(url, data, fail, isDelete = false) {
  const { status_code: statusCode } = data || {};
  if (statusCode === 401) {
    yield put(requestLogout());
  }
  let errors;
  if (!data.resource) errors = data;
  if (data.resource) {
    data = data || {};
    errors = (data.resource && data.resource.errors) || {};
  }
  // If a delete fails, the resource is still usable so we don't invalidate it.
  // Yes, this is conceptually wrong, but its necessary because we don't want a
  // failed delete to result in items being re-fetched, particularly if the item
  // came from a collection request with no equivalent single item endpoint.
  // NOTE The correct solution for this is to implement multiple statuses, one
  // for each request verb.
  if (!isDelete) yield put(Actions.failResource({ url, errors }));
  fail && fail(data.resource || data);
};

const appendActionToUrl = (url, action) => {
  if (!action) return url;
  const parts = url.split('?');
  parts[0] = `${parts[0]}/${action}`;
  return parts.join('?');
};

// **** WORKERS ****
const getResource = function*({ payload = {} } = {}) {
  const { url, succeed, fail } = payload || {};
  try {
    const result = yield ajax.get(url);
    // If resource is a collection, store each of its items individually.
    yield storeCollectionItems(result);

    yield put(Actions.getResourceSuccess({ url, data: result }));
    succeed && succeed(result);
  } catch (err) {
    // While we were trying to get the resource, it may have been loaded by
    // another request (e.g. by a get on its parent collection) in which case we
    // should swallow the error.
    const resource = yield select(state => state.resources[url]);
    if (resource && resource.status === 'success') return;

    yield* handleResourceError(url, err, fail);
  }
};

const getSubResource = function*({ payload = {} } = {}) {
  const { url, id, succeed, fail } = payload || {};
  try {
    // The url is the parent collection URL
    // The ID is the sub resource which can be requested individually from the api
    const result = yield ajax.get(id);
    // If resource is a collection, store each of its items individually.
    yield put(Actions.getSubResourceSuccess({ url, id, data: result }));

    if (typeof succeed === 'function') succeed(result);
  } catch (err) {
    // While we were trying to get the resource, it may have been loaded by
    // another request (e.g. by a get on its parent collection) in which case we
    // should swallow the error.
    const resource = yield select(state => state.resources[url]);
    if (resource && resource.status === 'success') return;

    yield* handleResourceError(url, err, fail);
  }
};
// NOTE:
// Start+ sometimes uses POST requests when it should really use a PUT (i.e.
// when making updates to an existing resource) so this code needs to handle
// POSTS that don't return a new entity.
const postResource = function*({ payload = {} } = {}) {
  const { url, action, data, isAppend, dependentUrls, options, succeed, fail } =
    payload || {};
  try {
    const postUrl = options.excludeActionFromPath
      ? url
      : appendActionToUrl(url, action);
    const result = yield ajax.post(postUrl, { payload: data }, options);
    // .progress(options.onProgress || (() => {}));

    // If resource has dependent urls, invalidate them.
    yield invalidateDependentUrls(dependentUrls);

    // This next bit is complicated so pay attention...
    const resultId = result && result._id;
    if (resultId) {
      // If returned object has an _id field (i.e. it's new), the _id is the
      // cache location at which to store it.
      // We must flag the operation as successful...
      yield put(Actions.postResourceSuccess({ url }));
      // ...store the newly created object in the cache...
      yield put(Actions.postResourceSuccess({ url: resultId, data: result }));
      // ...and append it to its parent collection (if required).
      if (isAppend) {
        yield put(Actions.pushResourceItem({ url, item: result }));
      }
    } else {
      // Returned object is NOT new (i.e. this request should really be a PUT)
      // so just write the response to the cache at the given url.
      yield put(Actions.postResourceSuccess({ url, data: result }));
    }

    succeed && succeed(result);
  } catch (err) {
    yield* handleResourceError(url, err, fail);
  }
};

const putResource = function*({ payload = {} } = {}) {
  const { url, data, dependentUrls, options, succeed, fail } = payload || {};
  try {
    const putUrl = options.excludeActionFromPath
      ? url
      : appendActionToUrl(url, 'edit');
    const result = yield ajax.put(putUrl, { payload: data }, options);
    // TODO: Figure out why this line breaks tests
    // .progress(options.onProgress || (() => {}));

    // If resource has dependent urls, invalidate them.
    yield invalidateDependentUrls(dependentUrls);

    yield put(Actions.putResourceSuccess({ url, data: result }));
    succeed && succeed(result);
  } catch (err) {
    yield* handleResourceError(url, err, fail);
  }
};

const deleteResource = function*({ payload = {} } = {}) {
  const { url, dependentUrls, options, succeed, fail } = payload || {};
  try {
    const deleteUrl = options.excludeActionFromPath
      ? url
      : appendActionToUrl(url, 'delete');
    const result = yield ajax.delete(deleteUrl);

    // If resource has dependent urls, invalidate them.
    yield invalidateDependentUrls(dependentUrls);

    // If this resource is present in a stored collection, pull it from the collection.
    const collectionUrl = yield selectCollectionContainingItem(url);
    if (collectionUrl) {
      yield put(Actions.pullResourceItem({ url: collectionUrl, itemId: url }));
    }

    yield put(Actions.deleteResourceSuccess({ url }));
    succeed && succeed(result);
  } catch (err) {
    yield* handleResourceError(url, err, fail, true);
  }
};

// **** WATCHERS ****

export default function* resourcesFlow() {
  yield all([
    takeEvery(REQUEST(GET(RESOURCE)), getResource),
    takeEvery(REQUEST(GET(SUB_RESOURCE)), getSubResource),
    takeEvery(REQUEST(POST(RESOURCE)), postResource),
    takeEvery(REQUEST(PUT(RESOURCE)), putResource),
    takeEvery(REQUEST(DELETE(RESOURCE)), deleteResource),
  ]);
}
