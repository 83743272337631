import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tooltip,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';

import ConditionalRuleConfiguration from './ConditionalRuleConfiguration';
import TriggerRuleConfiguration from './TriggerRuleConfiguration';

// Utilities
import isEqual from 'lodash.isequal';
import * as FieldTypes from 'common/utilities/constants/fieldTypes';
import DOMPurify from 'dompurify';

const styles = () => ({
  content: {
    display: 'grid',
    gridTemplateColumns: '49% 49%',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `
    "ruleName            helperMessage"
    "........            ............."
    "label               label"
    "........            ............."
    "inputs              inputs"
    `,
    gridColumnGap: '2%',
    gap: '5px',
    gridGap: '5px',
  },
  helperMessage: {
    gridArea: 'helperMessage',
  },
  tooltip: {
    fontSize: '1rem !important',
  },
});

const StyledButton = styled(Button)({
  backgroundColor: '#e0e0e0',
  marginRight: 16,
  color: 'black',
  '&:hover': {
    backgroundColor: '#d0d0d0',
  },
});

class RuleConfigurationDialog extends Component {
  state = {
    rule: DOMPurify.sanitize(this.props.rule) || {},
    errors: {},
    menuAnchor: null,
  };

  componentDidUpdate(prevProps, prevState) {
    const { rule: prevRule = {} } = prevProps;
    const { rule: upstreamRule = {} } = this.props;
    if (!isEqual(upstreamRule, prevRule)) {
      this.setState({ rule: upstreamRule });
    }
  }

  openSystemFieldMenu = ({ currentTarget }) =>
    this.setState({ menuAnchor: currentTarget });

  closeSystemFieldMenu = () => this.setState({ menuAnchor: null });

  // Update the rule name or description/helper message
  updateRuleDetails = key => ({ target: { value } }) => {
    this.setState(({ rule }) => ({
      rule: {
        ...rule,
        [key]: value,
      },
    }));
  };

  // Clear the field group name text field if it's focused and contains the default value
  handleFieldGroupInputFocus = (fieldGroupId, value) => {
    if (value.includes('New Field Group')) {
      this.handleFieldGroupNameChange(fieldGroupId, { target: { value: '' } });
    }
  };

  // Clear the text field if it's focused and contains the default value
  handleRuleNameFocus = name => {
    if (
      name.includes('New Required Rule') ||
      name.includes('New Trigger Rule')
    ) {
      this.updateRuleDetails('name')({ target: { value: '' } });
    }
  };

  // Update the field group name within a rule
  handleFieldGroupNameChange = (fieldGroupId, { target: { value } }) => {
    this.setState(({ rule }) => {
      const updatedFieldGroups = rule.fieldGroups.map(fieldGroup =>
        fieldGroup.id === fieldGroupId
          ? { ...fieldGroup, name: value }
          : fieldGroup,
      );
      return {
        rule: {
          ...rule,
          fieldGroups: updatedFieldGroups,
        },
      };
    });
  };

  updateResponseBehavior = ({ responseBehaviorType, value = null }, index) => {
    this.setState(({ rule }) => {
      const { responseBehavior } = rule;
      const updatedResponseBehavior = responseBehavior.map((behavior, i) =>
        i === index ? { responseBehaviorType, value } : behavior,
      );
      return {
        rule: {
          ...rule,
          responseBehavior: updatedResponseBehavior,
        },
      };
    });
  };

  addResponseBehavior = () => {
    this.setState(({ rule }) => ({
      rule: {
        ...rule,
        responseBehavior: [
          ...rule.responseBehavior,
          { responseBehaviorType: null, value: null },
        ],
      },
    }));
  };

  removeResponseBehavior = index => {
    this.setState(({ rule }) => ({
      rule: {
        ...rule,
        responseBehavior: [
          ...rule.responseBehavior.filter((_, idx) => idx !== index),
        ],
      },
    }));
  };

  updateSetValueResponse = (value, index) => {
    this.setState(({ rule }) => {
      const { responseBehavior } = rule;
      const updatedResponseBehavior = responseBehavior.map((behavior, i) =>
        i === index ? { ...behavior, value } : behavior,
      );
      return {
        rule: {
          ...rule,
          responseBehavior: updatedResponseBehavior,
        },
      };
    });
  };

  selectSystemField = index => systemField => {
    this.setState(({ rule }) => {
      const { responseBehavior } = rule;
      const updatedResponseBehavior = responseBehavior.map((behavior, i) =>
        i === index
          ? { ...behavior, value: systemField.name || null }
          : behavior,
      );
      return {
        rule: {
          ...rule,
          responseBehavior: updatedResponseBehavior,
        },
        menuAnchor: null,
      };
    });
  };

  handleConditionalRuleChange = (conditionalRuleId, index) =>
    this.setState(({ rule }) => ({
      rule: {
        ...rule,
        conditionalRuleIds: rule.conditionalRuleIds.map((id, idx) =>
          index === idx ? conditionalRuleId : id,
        ),
      },
    }));

  addConditionalRule = () =>
    this.setState(({ rule }) => ({
      rule: {
        ...rule,
        conditionalRuleIds: [...rule.conditionalRuleIds, ''],
      },
    }));

  removeConditionalRule = index =>
    this.setState(({ rule }) => ({
      rule: {
        ...rule,
        conditionalRuleIds: rule.conditionalRuleIds.filter(
          (_, idx) => idx !== index,
        ),
      },
    }));

  removeSystemFieldResponse = () =>
    this.setState(({ rule }) => ({
      rule: {
        ...rule,
        responseBehavior: rule.responseBehavior.filter(
          ({ responseBehaviorType }) => responseBehaviorType !== 'system',
        ),
      },
    }));

  // Checks for rule name and field group name uniqueness
  // and displays an error on the corresponding text field if
  // the name is a duplicate
  validateAndSave = () => {
    const { rules = [], fields = [], saveRule } = this.props;
    const { rule } = this.state;
    const errors = {};
    const ruleIndex = rules.findIndex(r => r.id === rule.id);
    // Check for rule name uniqueness
    if (
      rules.some(
        (r, idx) =>
          r.name.toLowerCase() === rule.name.toLowerCase() && ruleIndex !== idx,
      ) ||
      !rule.name.length
    ) {
      errors.ruleName = true;
    }
    // Check for field group name uniqueness in conditional rules
    if (rule.ruleType === 'group') {
      rule.fieldGroups.forEach((fieldGroup, index) => {
        if (
          rule.fieldGroups.some(
            (fg, idx) =>
              fg.name.toLowerCase() === fieldGroup.name.toLowerCase() &&
              index !== idx,
          ) ||
          !fieldGroup.name.length
        ) {
          errors[fieldGroup.id] = true;
        }
      });
    }
    this.setState({ errors });
    if (!Object.keys(errors).length) {
      const trimmedFieldGroups = rule.fieldGroups.map(fg => ({
        ...fg,
        name: fg.name.trim(),
      }));
      let trimmedRule = {
        ...rule,
        name: rule.name.trim(),
        description: (rule.description || '').trim(),
        fieldGroups: trimmedFieldGroups,
      };
      // Remove empty response behavior and conditional rule values from trigger rules before saving
      if (rule.ruleType === 'trigger') {
        const { conditionalRuleIds = [], fieldGroups = [] } = rule;
        const isSystemFieldRestricted = fieldGroups.some(
          ({ fieldIds = [] }) =>
            fieldIds.length > 1 ||
            (fieldIds.length === 1 &&
              !fieldIds.every(id => {
                const field =
                  fields.find(({ id: fieldId }) => fieldId === id) || {};
                const { fieldType } = field;
                return (
                  fieldType === FieldTypes.TXT || fieldType === FieldTypes.CMB
                );
              })),
        );
        const trimmedResponseBehaviorValues = rule.responseBehavior
          .filter(res => !!res.responseBehaviorType)
          .map(res => ({ ...res, value: (res.value || '').trim() }));
        const updatedResponseBehavior = isSystemFieldRestricted
          ? trimmedResponseBehaviorValues.filter(
              ({ responseBehaviorType }) => responseBehaviorType !== 'system',
            )
          : trimmedResponseBehaviorValues;
        trimmedRule = {
          ...trimmedRule,
          responseBehavior: updatedResponseBehavior,
          conditionalRuleIds: conditionalRuleIds.filter(ruleId => !!ruleId),
        };
      }
      this.setState({ rule: trimmedRule });
      saveRule(trimmedRule, 'configureDialogIsOpen');
    }
  };

  render() {
    const {
      isOpen,
      onClose,
      classes,
      isLoading,
      responseBehaviorOptions = [],
      rules = [],
      systemFieldOptions = [],
      fields = [],
      roles = [],
    } = this.props;
    const { rule, errors, menuAnchor } = this.state;
    const {
      fieldGroups = [],
      name,
      description,
      ruleType,
      responseBehavior = [],
      conditionalRuleIds = [],
    } = rule;
    const hasEmptyResponseBehavior =
      responseBehavior.length === 0 ||
      responseBehavior.filter(
        ({ responseBehaviorType }) => !!responseBehaviorType,
      ).length === 0;
    const hasEmptySystemField = responseBehavior.some(
      ({ responseBehaviorType, value = null }) =>
        responseBehaviorType === 'system' && !value,
    );
    const isTrigger = ruleType === 'trigger';
    const hasRulesAttached = !!conditionalRuleIds.filter(id => !!id).length;
    const isSaveButtonDisabled =
      isTrigger &&
      ((hasEmptyResponseBehavior && !hasRulesAttached) || hasEmptySystemField);
    const disabledTooltipMessage = (() => {
      if (isTrigger && hasEmptyResponseBehavior && !hasRulesAttached)
        return 'At least one response behavior or one linked Required Rule is required for Trigger Rules.';
      if (isTrigger && hasEmptySystemField)
        return 'A system field is required for the chosen response behavior.';
      return '';
    })();
    return (
      <Dialog open={isOpen} onClose={onClose} fullWidth>
        <DialogTitle>
          Configure {ruleType === 'trigger' ? 'Trigger ' : 'Required '}
          Rule
        </DialogTitle>
        <DialogContent classes={{ root: classes.content }}>
          <TextField
            variant="standard"
            label="Rule Name"
            value={name}
            onChange={this.updateRuleDetails('name')}
            onFocus={() => this.handleRuleNameFocus(name)}
            error={errors.ruleName}
            helperText={
              errors.ruleName && 'Duplicate or blank rule names not allowed.'
            }
            inputProps={{
              'data-test-id': `RuleConfigurationDialog-ruleName-${rule.id}`,
            }}
          />
          {ruleType === 'group' && (
            <TextField
              variant="standard"
              label="Helper Message"
              className={classes.helperMessage}
              value={description}
              onChange={this.updateRuleDetails('description')}
              inputProps={{
                'data-test-id': `RuleConfigurationDialog-helperMessage-${rule.id}`,
              }}
              multiline
            />
          )}
          {ruleType === 'group' && (
            <ConditionalRuleConfiguration
              fieldGroups={fieldGroups}
              onChange={this.handleFieldGroupNameChange}
              onFocus={this.handleFieldGroupInputFocus}
              errors={errors}
            />
          )}
          {ruleType === 'trigger' && (
            <TriggerRuleConfiguration
              rule={rule}
              rules={rules}
              fields={fields}
              roles={roles}
              responseBehaviorOptions={responseBehaviorOptions}
              onResponseBehaviorChange={this.updateResponseBehavior}
              addResponseBehavior={this.addResponseBehavior}
              onSetValueChange={this.updateSetValueResponse}
              removeResponseBehavior={this.removeResponseBehavior}
              handleConditionalRuleChange={this.handleConditionalRuleChange}
              addConditionalRule={this.addConditionalRule}
              removeConditionalRule={this.removeConditionalRule}
              systemFieldOptions={systemFieldOptions}
              selectSystemField={this.selectSystemField}
              openSystemFieldMenu={this.openSystemFieldMenu}
              closeSystemFieldMenu={this.closeSystemFieldMenu}
              removeSystemFieldResponse={this.removeSystemFieldResponse}
              menuAnchor={menuAnchor}
            />
          )}
        </DialogContent>
        <DialogActions>
          <StyledButton
            variant="contained"
            onClick={onClose}
            data-test-id={`RuleConfigurationDialog-cancelButton`}
            disabled={isLoading}
          >
            Cancel
          </StyledButton>
          <Tooltip
            disableInteractive
            classes={{ tooltip: classes.tooltip }}
            title={disabledTooltipMessage}
          >
            <div data-test-id="RuleConfigurationDialog-buttonContainer">
              <Button
                color="primary"
                variant="contained"
                onClick={() => this.validateAndSave()}
                data-test-id="RuleConfigurationDialog-saveButton"
                disabled={isSaveButtonDisabled || isLoading}
              >
                {isLoading ? <CircularProgress size={12} /> : 'Done'}
              </Button>
            </div>
          </Tooltip>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(RuleConfigurationDialog);
