import React from 'react';
import { withStyles } from '@mui/styles';
import { Fab, Typography } from '@mui/material';
import { CalendarToday as TimestampIcon } from '@mui/icons-material';
import classNames from 'classnames';
import sharedCss from '../../shared/oldDocumentSignerUI/sharedCss';
import fieldStatusClass from './fieldStatusClass';
import moment from 'moment';
import { styled } from '@mui/system';

const styles = {
  ...sharedCss.fields,
  root: {
    position: 'absolute',
    padding: 5,
  },
  buttonIcon: {
    height: 20,
  },
};

const StyledFab = styled(Fab)({
  position: 'absolute',
  right: 5,
  bottom: 5,
  height: 36,
  width: 36,
  '&:focus': {
    opacity: 1,
    boxShadow: '5px 5px 5px 0',
  },
});

const Timestamp = props => {
  const {
    classes = {},
    format,
    handleChange,
    height,
    id,
    value = '',
    width,
    x,
    y,
    setFieldRef,
    _derived: { isDisabled },
  } = props;
  const fieldStyle = {
    top: y,
    left: x,
    height,
    width,
    fontSize: height * 0.8,
  };
  const fieldClass = classNames(classes.root, fieldStatusClass(props));
  const safeFormat = (format || 'MM/DD/YYYY').toUpperCase();

  const setDate = () => handleChange(moment().format(safeFormat));

  return (
    <div
      className={fieldClass}
      style={fieldStyle}
      data-test-id={`Timestamp-root-${id}`}
      ref={el => {
        if (isDisabled) setFieldRef(el, y);
      }}
    >
      <Typography>{value}</Typography>
      {!isDisabled && (
        <StyledFab
          className={classes.setButton}
          onClick={setDate}
          data-test-id={`Timestamp-button-${id}`}
          ref={el => setFieldRef(el, y)}
        >
          <TimestampIcon className={classes.buttonIcon} />
        </StyledFab>
      )}
    </div>
  );
};

export default withStyles(styles)(Timestamp);
