import React, { useState, useEffect, useCallback } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import withApi from 'common/hoc/withApi';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import classnames from 'classnames';
import history from 'common/constants/config/history';
import getSSOUrl from 'common/constants/config/ssoUrl';
import ajax from 'common/utilities/ajax';
import { parseErrorMessages } from 'common/oldJavascripts/utils/errorMessageParser.js';
import moment from 'moment';
import PreLoader from 'mobile/components/PreLoader';
import TermsOfEmployment from './TermsOfEmployment';
import FringeDetails from './FringeDetails';
import Allowances from './Allowances';
import AdditionalDealTerms from './AdditionalDealTerms';
import DocumentsPanel from './DocumentsPanel';
import TermsOfHire from './TermsOfHire';
import HiringProfile from './HiringProfile';
import NeedAssistance from './NeedAssistance';
import ReviewOffer from './ReviewOffer';
import CurrencyIndicator from './CurrencyIndicator';
import BackButton from 'mobile/components/BackButton';
import Dialog from 'common/components/Dialog';
import RejectModal from 'onboarding/oldJavascripts/components/Pages/Offer/Onboarding/RejectModal';
import ScrollDestination from 'common/components/ScrollDestination';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';
import useProfileSynced from 'common/hooks/useProfileSynced';
import useOfferLoanoutCorporation from '../hooks/useOfferLoanoutCorporation';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import useOfferCountry from 'common/components/SpecifiedCountryOnly/useOfferCountry';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import StateW4Modal from './OfferStateW4/StateW4Modal';
import OfferStatus from 'common/oldJavascripts/components/Shared/OfferStatus';
import AdditionalDocumentsRejectModal from 'onboarding/oldJavascripts/components/Pages/Offer/Onboarding/AdditionalDocumentsRejectModal';

//import Pendocontroller
import PendoController from 'common/components/PendoController';

const INVALID_STATUS = [
  'archived',
  'inactive',
  'rescinded',
  'rejected',
  'rejected_employee',
];

const useStyles = makeStyles(theme => ({
  loaderText: {
    margin: '0 auto',
    padding: '40px 0',
    textAlign: 'center',
  },
  globalNavLoader: {
    marginTop: '120px',
  },
  section1: {
    position: 'relative', // So `position: absolute` works correctly inside
    gridArea: 'offersummary',
    width: '100%',
    minHeight: '1340px',
    borderRadius: '3px',
    backgroundColor: '#ffffff',
    padding: '40px 10px',
    [theme.breakpoints.up('md')]: {
      padding: '40px 25px',
      minWidth: 669,
      height: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 827,
      height: 'auto',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: 950,
      height: 'auto',
    },
  },
  section2: {
    gridArea: 'row1',
  },
  section3: {
    gridArea: 'row2',
  },
  section4: {
    gridArea: 'row3',
  },
  section5: {
    gridArea: 'row4',
  },
  gridContainer: {
    display: 'grid',
    margin: 0,
    gridTemplateAreas: `'row1''offersummary''row2''row3''row4''row5'`,
    gridGap: '20px',
    backgroundColor: '#f2f5f7',
    padding: '10px',
    [theme.breakpoints.up('md')]: {
      gridTemplateAreas: `
      'offersummary row1'
      'offersummary row2'
      'offersummary row3'
      'offersummary row4'
      'offersummary row5'`,
      margin: '30px 16px',
    },
    '& section:not(:first-child)': {
      borderRadius: '2px',
      backgroundColor: '#ffffff',
      padding: '30px 0',
      minWidth: 355,
      height: 'auto',
      [theme.breakpoints.up('lg')]: {
        minWidth: 404,
        height: 'auto',
      },
      [theme.breakpoints.up('xl')]: {
        minWidth: 550,
        height: 'auto',
      },
    },
  },
  globalNav: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '130px !important',
    },
  },
  globalNavSubmitted: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '70px !important',
    },
  },
  submittedGridContainer: {
    gridTemplateAreas: `'offersummary''row2''row3''row4''row5'`,
    [theme.breakpoints.up('md')]: {
      gridTemplateAreas: `
      'offersummary row2'
      'offersummary row3'
      'offersummary row4'
      'offersummary row5'`,
      margin: '30px 16px',
    },
  },
  title: {
    color: '#646464 !important',
    fontSize: '24px !important',
    fontWeight: 400,
  },
  offerStatus: {
    display: 'flex',
    padding: '25px',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  statusBadge: {
    display: 'inline-block',
    padding: '5px 10px',
    width: 'auto',
  },
  offerStatusTitle: {
    color: '#646464',
    fontSize: '24px',
    fontWeight: 400,
    paddingRight: '10px',
  },
  itemLabel: {
    color: '#aaaaaa !important',
    fontSize: '16px !important',
    fontWeight: 400,
  },
  itemTitle: {
    color: '#5b5b5b !important',
    fontSize: '22px !important',
    fontWeight: 400,
  },
  itemContainer: {
    display: 'grid',
    marginTop: '30px',
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr',
      marginTop: '30px',
    },
    '& div': {
      marginBottom: '24px',
    },
  },
  panel: {
    backgroundColor: '#E2F1FF',
    padding: '10px',
  },
  amount: {
    color: '#0000FF',
    fontSize: 30,
    fontWeight: 400,
    padding: '15px',
    textAlign: 'center',
    border: '2px solid #E2F1FF',
  },
  hide: {
    display: 'none',
  },
  backBtnContainer: {
    marginTop: 10,
    position: 'relative',
    top: '-35px',
    right: '10px',
  },
  currencyIndicator: {
    position: 'absolute',
    right: 25,
    top: 43,
  },
}));

// TODO Centralize onboarding navigation logic
const getNextStatus = (documentsQuery, offer) => {
  const { items: documents = [] } = documentsQuery.data;

  // if we directly open offer details page through email
  // navigte user to current status
  if (offer?.data?.status !== 'sent' && offer?.data?.status !== 'onboarding') {
    return offer?.data?.status;
  }

  const doesOfferIncludeSupportingDocuments = documents.some(
    doc => doc.supportingDocumentTitles?.length > 0,
  );
  if (doesOfferIncludeSupportingDocuments) {
    return 'onboarding_supporting-documents';
  }

  const doesOfferIncludeI9 = documents.some(
    doc => doc?.i9_document && doc?.start_plus_status === 'not_signed',
  );
  if (doesOfferIncludeI9) {
    return 'onboarding_i9';
  }

  return 'onboarding_documents';
};

const getNextUrl = (offerId, nextStatus) =>
  `/onboarding/offers/${offerId}/${nextStatus.split('_')[1]}`;

const getIsRegistrationComplete = () =>
  ajax.get('/registration').then(resp => !!resp?.registration_complete);

const OfferDetails = props => {
  const { documents, offer, hirer = {}, pushSnackbarNotification } = props;
  const offerData = offer?.data || {};
  const hirerData = hirer?.data;
  const crewProfileData = offerData;
  const {
    data: profileSyncData = {},
    refetch: checkProfile,
  } = useProfileSynced(offerData?.id);
  const { data: offerCountry } = useOfferCountry({ offerId: offerData?.id });
  const isCanada = offerCountry?.code === 'CA';
  const featureFlags = useFeatureFlags();
  const globalNavFlag = featureFlags.includes('GlobalNavbar');
  const isMycncLoanoutFlagActive = featureFlags.includes(
    'OfferSummaryMycncLoanoutCorporation',
  );
  const isCanadianNonUnionFringesOn = featureFlags.includes(
    'CanadianNonUnionFringes',
  );
  const isStatew4FlagActive = featureFlags.includes('StateW4');
  const isFringePercentagePresent =
    parseFloat(offerData?.terms_of_employment?.total_fringe_percentage) > 0;

  // TODO: Refactor this after moving Offer query to graphql
  //       offer.type becomes offer.termsOfHire.employmentClassification
  //       and value goes from :loan_out to :loanOut
  const isLoanoutOffer = offerData.type === 'loan_out';
  const isNonUnion = offerData?.union_is_non_union;
  const loanoutCorp = useOfferLoanoutCorporation(
    offerData.id,
    !(isLoanoutOffer && isMycncLoanoutFlagActive),
  );
  const {
    data: loanoutCorpData,
    loading: loanoutCorpLoading,
    error: loanoutCorpError,
  } = loanoutCorp;
  const { status: loanoutCorpStatus } = loanoutCorpData || {};

  const { isSynced: isProfileSynced = false } = profileSyncData;
  const [profileSyncCounter, setProfileSyncCounter] = useState(0);
  const [shouldProfileWait, setShouldProfileWait] = useState(!isProfileSynced);
  const isOfferSubmitted = offerData?.status === 'submitted';
  const isOfferApproved = offerData?.status === 'approved';
  const isOfferInvalid = INVALID_STATUS.includes(offerData?.status);
  const [stopReload, setStopReload] = useState(false);
  const [isOfferLoading, setIsOfferLoading] = useState(
    offer.status === 'loading' ||
      documents.status === 'loading' ||
      !profileSyncData ||
      loanoutCorpLoading,
  );
  const { items: documentsData = [] } = documents?.data;
  const hasDocuments = documentsData.length > 0;

  const handleSync = useCallback(() => {
    const reload = () => {
      checkProfile().then(() => setProfileSyncCounter(profileSyncCounter + 1));
    };

    if (isProfileSynced) {
      // profile is synced so we can hide the loading screen
      setShouldProfileWait(false);
    } else {
      // profile still not synced yet
      if (profileSyncCounter < 2) {
        // we haven't checked for 30 seconds yet so try again in 10 seconds'
        setTimeout(reload, 10000);
      } else {
        // we have been checking for 30 seconds and still don't have a synced profile
        // so let's hide the loading screen and show a modal for contacting support
        setIsRegistrationIncompleteModalOpen(true);
        setShouldProfileWait(false);
      }
    }
  }, [checkProfile, isProfileSynced, profileSyncCounter]);

  useEffect(() => {
    handleSync();
  }, [handleSync]);

  useEffect(() => {
    const reloadDocuments = async () => {
      setStopReload(true);
      await documents.reload();
    };
    if (isProfileSynced && !stopReload) {
      reloadDocuments();
    }
  });

  useEffect(() => {
    setIsOfferLoading(
      offer.status === 'loading' ||
        documents.status === 'loading' ||
        shouldProfileWait ||
        loanoutCorpLoading,
    );
  }, [offer.status, documents.status, shouldProfileWait, loanoutCorpLoading]);

  const classes = useStyles();
  const [
    isRegistrationIncompleteModalOpen,
    setIsRegistrationIncompleteModalOpen,
  ] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

  // statew4
  const [isStatew4DialogOpen, setIsStateW4DialogOpen] = useState(false);

  const handleStateW4NextStep = next => {
    if (next) {
      updateStatusAndSave();
    }
    setIsStateW4DialogOpen(false);
  };

  if (isOfferLoading) {
    const loaderClass = classnames(classes.loaderText, {
      [classes.globalNavLoader]: globalNavFlag,
    });
    return (
      <div>
        <div className={loaderClass}>
          Loading profile and offer details. <br />
          This could take up to a minute for new users.
        </div>
        <PreLoader />
      </div>
    );
  }

  if (loanoutCorpError) {
    return <FailureAlert queryName="Loanout Corporation" />;
  }

  const optedOut = offerData?.status === 'complete_on_paper';
  const isOldOffer = optedOut || isOfferSubmitted || isOfferApproved;
  const isReviewOffer = !optedOut && !isOfferSubmitted && !isOfferApproved;
  const isLoanoutWithoutVerifiedCorpStatus =
    isMycncLoanoutFlagActive &&
    isLoanoutOffer &&
    (loanoutCorpStatus === 'missing' ||
      loanoutCorpStatus === 'expired' ||
      !loanoutCorpStatus);
  const isCanadaLoanOut = isCanada && isLoanoutWithoutVerifiedCorpStatus;
  const isReviewOfferDisabled =
    isLoanoutWithoutVerifiedCorpStatus || isCanadaLoanOut;

  let gridContainerClass = classnames(classes.gridContainer, {
    [classes.submittedGridContainer]: !isReviewOffer,
    [classes.globalNav]: globalNavFlag && isReviewOffer,
    [classes.globalNavSubmitted]: globalNavFlag && !isReviewOffer,
  });

  const updateStatusAndSave = () => {
    const { id: offerId } = offerData;
    const nextStatus = getNextStatus(documents, offer);
    const nextUrl = getNextUrl(offerId, nextStatus);

    offer.updateAndSave(
      { status: nextStatus },
      'status',
      {},
      () => history.push(nextUrl),
      handleAcceptError,
    );
  };

  // check offer previous status to skip begin packet
  const offerStatus = offerData?.previous_status;
  const statusRegex = new RegExp(/^(approved|submitted)$/);
  const isApprovedOrSubmitted = statusRegex.test(offerStatus);

  const continueToNextStep = async () => {
    const statew4Status = offerData?.external_documents;
    const stateW4Check =
      isStatew4FlagActive && offerData?.type === 'w2' && !isCanada;
    if (isApprovedOrSubmitted) {
      // check offer previous statew4 status to enforce statew4
      if (!statew4Status && stateW4Check) {
        setIsStateW4DialogOpen(true);
        return;
      }
      return updateStatusAndSave();
    }

    const isRegistrationComplete = await getIsRegistrationComplete();
    if (!isRegistrationComplete) setIsRegistrationIncompleteModalOpen(true);
    if (stateW4Check) {
      setIsStateW4DialogOpen(true);
    } else {
      updateStatusAndSave();
    }
  };

  const handleAcceptError = async acceptError => {
    let registrationCheckError;
    try {
      const isRegistrationComplete = await getIsRegistrationComplete();
      if (!isRegistrationComplete) {
        setIsRegistrationIncompleteModalOpen(true);
        return;
      }
    } catch (err) {
      registrationCheckError = err;
    }

    pushSnackbarNotification({
      message: parseErrorMessages(registrationCheckError ?? acceptError),
      variant: SnackbarVariants.ERROR,
    });
  };

  const handleRejectError = err => {
    pushSnackbarNotification({
      message: parseErrorMessages(err),
      variant: SnackbarVariants.ERROR,
    });
    setIsRejectModalOpen(false);
  };

  const gotoRegistration = countryCode => {
    const offerId = offerData?.id;
    const workState = offerData?.work_state?.code;
    const dbCode = offerData?.cnc_db;
    const workerType = offerData?.worker_type;

    let canOffer = `startpluscanoffer?dbCode=${dbCode}&offerId=${offerId}`;
    let usOffer = `startplusoffer?dbCode=${dbCode}&offerId=${offerId}`;

    if (isStatew4FlagActive && offerData?.type === 'w2') {
      usOffer = usOffer + `&w4State=${workState}`;
    } else if (offerData?.type === 'loan_out') {
      usOffer = usOffer + `&type=loanout`;
      canOffer = canOffer + `&type=loanout`;
    }

    usOffer = usOffer + `&workerType=${workerType}`;
    canOffer = canOffer + `&workerType=${workerType}`;
    const offerDir = countryCode === 'CA' ? canOffer : usOffer;
    const registrationUrl = `${getSSOUrl()}/${offerDir}`;
    window.location = registrationUrl;
  };

  const canShowDocuments = isOfferSubmitted || optedOut || isOfferApproved;

  const getDialog = countryCode => (
    <Dialog
      open={isRegistrationIncompleteModalOpen}
      title="Registration Incomplete"
      message={
        <React.Fragment>
          <Typography gutterBottom>
            Sorry, but it looks like you haven't completed your registration.
            Please go to MyCast&Crew to finish registering before you accept
            your offer.
          </Typography>
          <Typography gutterBottom>
            If you need help, contact support:{' '}
          </Typography>
          <Typography>
            <a href="mailto:plus.support@castandcrew.com">
              Plus.Support@castandcrew.com
            </a>{' '}
          </Typography>
          <Typography>
            <a href="tel:818.860.7770">818.860.7770</a> (United States)
          </Typography>
          <Typography>
            <a href="tel:416.324.9748">416.324.9748</a> (Canada)
          </Typography>
        </React.Fragment>
      }
      actions={[
        {
          text: 'Complete Registration',
          fn: () => gotoRegistration(countryCode),
        },
      ]}
    />
  );

  return (
    <>
      <ScrollDestination isActive={isOldOffer} behavior="auto" block="start">
        <div className={gridContainerClass}>
          <section className={classes.section1}>
            <div className={classes.backBtnContainer}>
              <BackButton
                title="Back to Offers"
                data-test-id="OfferDetails-backBtn"
                url={`/onboarding/offers`}
                size="small"
              />
            </div>
            <CanadaOnly>
              <CurrencyIndicator className={classes.currencyIndicator} />
            </CanadaOnly>
            <Typography className={classes.title}>Offer Summary</Typography>
            <div className={classes.itemContainer}>
              <div>
                <Typography className={classes.itemLabel}>
                  Project Name
                </Typography>
                <Typography
                  className={classes.itemTitle}
                  data-test-id="OfferDetails-projectName"
                >
                  {offerData.project_name}
                </Typography>
              </div>
              <div>
                <Typography className={classes.itemLabel}>
                  Start Date on or About
                </Typography>
                <Typography className={classes.itemTitle}>
                  {moment(offerData.start_date).format('MMMM Do, YYYY')}
                </Typography>
              </div>
              <div>
                <Typography className={classes.itemLabel}>Job Title</Typography>
                <Typography className={classes.itemTitle}>
                  {offerData.occupation_description}
                </Typography>
              </div>
            </div>
            <TermsOfEmployment
              offerData={offerData}
              offerCountry={offerCountry}
            />
            <CanadaOnly>
              {isNonUnion &&
                isCanadianNonUnionFringesOn &&
                isFringePercentagePresent && (
                  <FringeDetails offerData={offerData} />
                )}
            </CanadaOnly>
            <Allowances offerData={offerData} />
            <AdditionalDealTerms offerData={offerData} hirer={hirerData} />
            {hasDocuments && canShowDocuments && (
              <DocumentsPanel
                documents={documentsData}
                offer={offerData}
                showLoanout={isLoanoutOffer}
                isCanada={isCanada}
                loanoutCorporation={loanoutCorp}
              />
            )}
          </section>
          {isOfferInvalid && (
            <section className={classes.section2}>
              <div className={classes.offerStatus}>
                <Typography className={classes.offerStatusTitle}>
                  Offer Status:
                </Typography>
                <OfferStatus
                  status={offerData?.status}
                  className={classes.statusBadge}
                />
              </div>
            </section>
          )}
          {isReviewOffer && !isOfferInvalid && (
            <section className={classes.section2}>
              <ReviewOffer
                onContinueToNextStep={continueToNextStep}
                onReject={() => setIsRejectModalOpen(true)}
                disabled={isReviewOfferDisabled}
              />
              {isStatew4DialogOpen && (
                <StateW4Modal
                  onClose={() => setIsStateW4DialogOpen(false)}
                  onSubmit={handleStateW4NextStep}
                  offerData={offerData}
                />
              )}
            </section>
          )}
          <section className={classes.section3}>
            <TermsOfHire offerData={offerData} />
          </section>
          <section className={classes.section4}>
            <HiringProfile
              offer={offerData}
              crewData={crewProfileData}
              loanoutCorp={loanoutCorpData}
              isLoanoutOffer={isLoanoutOffer}
              isCanada={isCanada}
              isMycncLoanoutFlagActive={isMycncLoanoutFlagActive}
            />
          </section>
          <section className={classes.section5}>
            <NeedAssistance hirer={hirerData} />
          </section>
          <UsaOnly>{getDialog('US')}</UsaOnly>
          <CanadaOnly>{getDialog('CA')}</CanadaOnly>
        </div>
        {isRejectModalOpen &&
          (isApprovedOrSubmitted ? (
            <AdditionalDocumentsRejectModal
              open={isRejectModalOpen}
              onClose={() => setIsRejectModalOpen(false)}
            />
          ) : (
            <RejectModal
              onClose={() => setIsRejectModalOpen(false)}
              onError={handleRejectError}
            />
          ))}
      </ScrollDestination>
      <PendoController projectId={offerData?.project_id} />
    </>
  );
};

OfferDetails.queries = {
  hirer: {
    info: function(_, related) {
      var params = related['/router/params'];
      return {
        id: '/v2/offers/' + params.offerId + '/hirer',
      };
    },
  },
  documents: {
    info: (_, related) => {
      const { offerId } = related['/router/params'];
      return {
        id: `/v2/offers/${offerId}/documents-details`,
      };
    },
  },
};

OfferDetails.mutators = {
  offer: {
    info: function(_, related) {
      var params = related['/router/params'];
      return {
        id: '/v2/offers/' + params.offerId,
      };
    },
  },
};

export default withSnackbarNotification(withApi(OfferDetails));
