import * as textFieldTypes from 'common/utilities/constants/textFieldTypes';
import { formatField } from './fields';

// Returns document meta data
export const getDocumentDetails = (fields, newFieldsPerPage) => {
  // Total number of fields on the document
  const totalFields = fields.length;
  // Total number of mapped fields on the document
  const mappedFields = fields.filter(({ roleId }) => roleId !== null).length;
  // Pages that have unassigned fields
  const unassignedPages = [];
  fields.forEach(({ roleId, page }) => {
    if (roleId === null && !unassignedPages.includes(page)) {
      unassignedPages.push(page);
    }
  });
  // Number of new fields on the document IF the document has been replaced
  const numberOfNewFields = Object.keys(newFieldsPerPage).reduce(
    (acc, key) => acc + newFieldsPerPage[key],
    0,
  );
  const totalFieldsMappedTooltipTitle =
    unassignedPages.length > 0
      ? `There are unassigned fields on page ${unassignedPages.join(', ')}`
      : '';
  const dialogReplacementMessage =
    numberOfNewFields === 0 ? `No unmapped fields` : `New unmapped fields`;
  return {
    totalFields,
    mappedFields,
    numberOfNewFields,
    totalFieldsMappedTooltipTitle,
    dialogReplacementMessage,
  };
};

// Returns a string indicating the number of new fields per page when a document has been replaced
export const formatReplacementDialogText = newFieldsPerPage => {
  const pageArray = Object.keys(newFieldsPerPage);
  if (pageArray.length >= 3) {
    const dialogText = pageArray.map(
      page => `${page} (${newFieldsPerPage[page]} fields)`,
    );
    const lastPage = dialogText.splice(dialogText.length - 1, 1);
    return 'pages '.concat(dialogText.join(', ').concat(`and ${lastPage[0]}.`));
  }
  if (pageArray.length === 2) {
    return 'pages '.concat(
      pageArray
        .map(page => `${page} (${newFieldsPerPage[page]} fields)`)
        .join(' and ')
        .concat('.'),
    );
  }
  return 'page '.concat(
    pageArray
      .map(page => `${page} (${newFieldsPerPage[page]} fields)`)
      .join('')
      .concat('.'),
  );
};

// Prep the document data for PUT request to API
export const formatDocumentData = ({ fields, roles }) => {
  const unassignedFields = [];
  const rolesToSubmit = roles.map(role => {
    const assignedFields = [];
    fields.forEach(field => {
      const { roleId, id: fieldId } = field;
      // Add field to assigned fields array
      if (roleId === role.id) {
        const assignedField = formatField(field);
        assignedFields.push(assignedField);
        return;
      }
      // add field to unassigned fields array
      if (
        roleId === null &&
        !unassignedFields.find(({ id }) => id === fieldId)
      ) {
        const unassignedField = formatField(field);
        unassignedFields.push(unassignedField);
      }
    });
    return {
      id: role.id,
      name: role.name,
      fields: assignedFields,
    };
  });
  return {
    fieldGroups: rolesToSubmit,
    openFields: unassignedFields,
  };
};

// Remove text fields that are not present in our config
export const filterTextFieldOptions = ({ value }) =>
  Object.keys(textFieldTypes).includes(value.toUpperCase());
