import gql from 'graphql-tag';

export default gql`
  query relatedProjects($projectId: Int!) {
    project(id: $projectId) {
      masterProject {
        id
        name
      }
      relatives {
        id
        name
        cncId
        country {
          code
          name
        }
        productionCompany {
          name
        }
      }
    }
  }
`;
