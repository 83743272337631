import React from 'react';

// HoC
import { withStyles } from '@mui/styles';
// Components
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

const styles = theme => ({
  root: {
    paddingLeft: 5,
  },
  checkBox: {
    padding: 4,
  },
  infoIcon: {
    height: '.9em',
    width: '.9em',
  },
});

const CrewMember = ({
  firstName,
  lastName,
  email,
  selected,
  onClick,
  classes = {},
}) => (
  <Tooltip disableInteractive title={email} enterDelay={500}>
    <ListItem
      key={`${firstName}-${lastName}`}
      role={undefined}
      button
      onClick={onClick}
      className={classes.root}
      data-test-id={`CrewMember-listItem-${firstName}-${lastName}`}
    >
      <Checkbox checked={selected} className={classes.checkBox} />
      <ListItemText primary={`${lastName}, ${firstName}`} />
    </ListItem>
  </Tooltip>
);

export default withStyles(styles)(CrewMember);
