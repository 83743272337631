import React from 'react';
export default ({ style, ...propsCatch }) => (
  <svg
    width="17"
    height="15"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    {...propsCatch}
  >
    <g
      fill="none"
      fillRule="evenodd"
      transform={`translate(${style.width / 2 - 7.5})`}
    >
      <path d="M21 20H-3V-4h24z" />
      <path
        d="M7.013 15H4.05V3.577L.512 4.674v-2.41L6.696.05h.317V15zm9.54 0H9.609v-1.504l3.2-3.363c.788-.898 1.182-1.611 1.182-2.14 0-.428-.093-.754-.28-.977-.187-.224-.458-.335-.814-.335-.35 0-.635.149-.854.447-.219.299-.328.671-.328 1.118h-2.31a3.269 3.269 0 0 1 1.729-2.905 3.777 3.777 0 0 1 1.818-.438c1.076 0 1.904.249 2.485.745.581.497.872 1.21.872 2.14 0 .392-.073.774-.22 1.145a5.021 5.021 0 0 1-.68 1.17c-.307.407-.803.953-1.486 1.636l-1.285 1.484h3.917V15z"
        fill="#000"
      />
    </g>
  </svg>
);
