import React, { useState } from 'react';

// HoC
import { withStyles } from '@mui/styles';

// MuiComponents
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

// Components
import CrewList from './CrewList';
import CrewSearch from './CrewSearch';
import SelectedCrewList from './SelectedCrewList';
import CrewFilter from './CrewFilter';
// utilities
import debounce from 'lodash.debounce';
import classNames from 'class-names';

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateRows:
      'minmax(50px, max-content) max-content minmax(40px, max-content) max-content 1fr',
    gridTemplateColumns: '1fr max-content max-content',
  },
  paperError: {
    boxShadow:
      '0px 1px 5px 0px rgb(251, 11, 11), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
  error: {
    fontSize: '0.9rem',
    gridRow: 2,
    placeSelf: 'center',
  },
  tab: {
    width: '50%',
    minWidth: '50%',
  },
  header: {
    gridRow: 1,
    gridColumn: 1,
    placeSelf: 'start',
    paddingLeft: 4,
    paddingTop: 8,
  },
  sortButton: {
    height: '40px',
    width: '40px',
    paddingTop: 8,
  },
  filterRoot: {
    gridRow: 1,
    gridColumn: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  tabs: {
    gridRow: 3,
    gridColumn: '1 / -1',
  },
  content: {
    gridRow: '4 / -1',
    gridColumn: '1 / -1',
  },
  sortIconLabel: {
    height: 16,
    width: 16,
  },
  searchContent: {
    gridRow: '5',
    gridColumn: '1 / -1',
    marginTop: 8,
  },
  selectedBadge: {
    padding: `0 ${theme.spacing(2)}px`,
  },
});

const updateCrewSearch = debounce((update, value) => update(value), 500);

const CrewMembers = props => {
  const {
    data: crewMembersData,
    formData = {},
    hasOfferBeenValidated,
    onChange,
    classes = {},
    onFilter = () => {},
  } = props;
  const isError =
    (hasOfferBeenValidated && Object.keys(formData).length === 0) ||
    (hasOfferBeenValidated &&
      Object.values(formData).every(crewMember => crewMember === undefined)); // check if all crew members are undefined (no crew members selected)

  const [tab, setTab] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [graphqlSearch, setGraphqlSearch] = useState('');
  const [filters, setFilters] = useState({
    withOffers: true,
    withoutOffers: true,
    sort: null,
    sortField: null,
  });
  const { sort } = filters || {};
  const selectedCrewMembers = Object.values(formData)
    .filter(crewMember => crewMember)
    .map(node => ({
      node,
    }));

  const searchOnChange = ({ target: { value } = {} } = {}) => {
    setSearchText(value);
    updateCrewSearch(value => {
      onFilter({ search: value });
      setGraphqlSearch(value);
    }, value);
  };
  const onChangeFilters = update => {
    onFilter(update);
    setFilters({ ...filters, ...update });
  };

  const onChangeTab = (_, value) => {
    setTab(value);
    setSearchText('');
  };

  const clearGraphqlSearch = () => setGraphqlSearch('');

  const sortCrewMembers = () => {
    const sortFilters = {
      sort: sort === 'asc' ? 'desc' : 'asc',
      sortField: 'name',
    };
    setFilters({
      ...filters,
      ...sortFilters,
    });
    onFilter(sortFilters);
  };

  const isSelected = id => formData[id] !== undefined;

  const rootClass = classNames(classes.root, {
    [classes.paperError]: isError,
  });

  return (
    <Paper className={rootClass}>
      <Typography className={classes.header} variant="h5">
        Select Crew Members
      </Typography>
      {tab === 0 && (
        <CrewFilter
          filters={filters}
          onChange={onChangeFilters}
          sortCrewMembers={sortCrewMembers}
          classes={{ root: classes.filterRoot, button: classes.sortButton }}
        />
      )}
      {isError && (
        <Typography color="error" variant="caption" className={classes.error}>
          Please select at least one crew member for this offer
        </Typography>
      )}
      <Tabs
        value={tab}
        onChange={onChangeTab}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
      >
        <Tab
          label="Crew"
          classes={{
            root: classes.tab,
          }}
          data-test-id="CrewMembers-crewTab"
        />
        <Tab
          label={
            <Badge
              className={classes.selectedBadge}
              color="primary"
              badgeContent={selectedCrewMembers.length}
            >
              Selected
            </Badge>
          }
          classes={{
            root: classes.tab,
          }}
          data-test-id="CrewMembers-selectedTab"
        />
      </Tabs>

      {tab === 0 && (
        <React.Fragment>
          <CrewSearch
            onChange={searchOnChange}
            value={searchText}
            classes={{ root: classes.search }}
          />
          <CrewList
            data={crewMembersData}
            search={graphqlSearch}
            filters={filters}
            onChange={onChange}
            isSelected={isSelected}
            classes={{ root: classes.searchContent }}
          />
        </React.Fragment>
      )}
      {tab === 1 && (
        <SelectedCrewList
          onChange={onChange}
          isSelected={isSelected}
          crew={selectedCrewMembers}
          graphqlSearch={graphqlSearch}
          clearGraphqlSearch={clearGraphqlSearch}
          classes={{ root: classes.content }}
        />
      )}
    </Paper>
  );
};

export default withStyles(styles)(CrewMembers);
