import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const QUERY = gql`
  mutation deleteOfferDraft($id: Int!) {
    deleteOfferDraft(id: $id)
  }
`;

const useDeleteOfferDraft = () => {
  const [deleteDraft, params] = useMutation(QUERY);
  return [deleteDraft, params];
};

export default useDeleteOfferDraft;
