import _ from 'lodash';

const humanize = str => {
  var spaced = null;
  var asArray = null;
  var asCapitalizedArray = null;

  spaced = _.lowerCase(str);
  asArray = _.words(spaced);
  asCapitalizedArray = _.map(asArray, _.capitalize);

  return _.join(asCapitalizedArray, ' ');
};

export default humanize;
