import React from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import DocumentFieldsGroup from './DocumentFieldsGroup';

const styles = theme => ({
  root: {},
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    paddingBottom: 4,
    color: '#000000',
    fontWeight: 700,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: '#525252',
  },
  documentFieldGroupContainer: {
    display: 'grid',
    gridAutoRows: 'max-content',
    height: 'max-content',
    gap: '15px',
  },
  textContainer: {
    marginBottom: 12,
  },
});

const DocumentFieldsExpansionPanel = props => {
  const {
    classes,
    formErrors,
    inputFieldGroups,
    onChange,
    templateName,
  } = props;

  return (
    <div
      classes={{ root: classes.root }}
      data-test-id={`DocumentFieldsExpansionPanel-${templateName}`}
    >
      <div className={classes.textContainer}>
        <Typography className={classes.heading}>{templateName}</Typography>
      </div>
      <div className={classes.documentFieldGroupContainer}>
        {inputFieldGroups.map(inputFieldGroup => (
          <DocumentFieldsGroup
            onChange={onChange}
            inputFields={inputFieldGroup.fields}
            isRequired={inputFieldGroup.required}
            groupName={inputFieldGroup.name}
            formErrors={formErrors.filter(
              err => err.inputFieldGroupId === inputFieldGroup.id,
            )}
            key={inputFieldGroup.id}
          />
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(DocumentFieldsExpansionPanel);
