// This feature calls the cncflags graphql API which is located at a different
// endpoint from the Start+ API. To enable this we create an alternative Apollo
// client in this component which we then inject into the react-appolo Query
// component. To prevent confusion all the code relating to this endpoint is
// kept in this file rather than mixing it with the main Apollo configuration.
// TODO Replace this with a federated GraphQL API:
// https://blog.apollographql.com/apollo-federation-f260cf525d21
import React from 'react';
import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { Query } from 'react-apollo';
import getFeatureFlagServer from 'common/constants/config/featureFlagServer';
import { FeatureFlagsContext } from 'common/context';

const APP_NAME = 'StartPlus';
const QUERY = gql`
  query flags($context: Context!) {
    flags(context: $context) {
      name
      value
    }
  }
`;

const uri = `${getFeatureFlagServer()}/graphql`;
const link = new HttpLink({ uri });
const cache = new InMemoryCache();
const client = new ApolloClient({ link, cache });
const variables = {
  context: { appName: APP_NAME, host: window.location.hostname },
};

const getActiveFlags = flags =>
  flags
    .filter(
      // true/false values are being stored as strings in the feature flag service
      flag =>
        (flag.value === 'true' || flag.value === true) &&
        flag.name.startsWith(`${APP_NAME}.`),
    )
    .map(({ name }) => name.split('.')[1]);

const FeatureFlagsProvider = ({ children }) => (
  <Query client={client} query={QUERY} variables={variables}>
    {({ loading, error, data }) => {
      const activeFlags = loading || error ? [] : getActiveFlags(data.flags);
      return (
        <FeatureFlagsContext.Provider value={activeFlags}>
          {children}
        </FeatureFlagsContext.Provider>
      );
    }}
  </Query>
);

export default FeatureFlagsProvider;
