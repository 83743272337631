import React from 'react';
import Select from 'react-select';
import Typography from '@mui/material/Typography';
import formatOptionLabel from './formatOptionLabel';
import useStates from 'common/hooks/useStates';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';

const HireStateSelect = props => {
  const {
    classes = {},
    disabled,
    error = '',
    formData: { hireState } = {},
    onChange: upstreamOnChange,
    selectStyles,
    countryId,
  } = props;

  const { loading, data: states = [] } = useStates(countryId);

  const options = loading
    ? []
    : states.map(({ name, code }) => ({
        value: code,
        label: name,
      }));

  const value = loading
    ? null
    : options.find(({ value }) => value === hireState);

  const onChange = ({ value }) => {
    const { id: hireStateId } = states.find(({ code }) => code === value) || {};
    upstreamOnChange({
      hireState: value,
      hireStateId,
      hireCity: null,
      occupation: null,
      union: null,
      workSchedule: null,
    });
  };

  return (
    <div className={classes.root}>
      <Typography
        className={classes.label}
        data-test-id="HireStateSelect-label"
        variant="body1"
      >
        Hire <UsaOnly>State</UsaOnly>
        <CanadaOnly>Province</CanadaOnly>
      </Typography>
      <div data-test-id="HireStateSelect-select">
        <Select
          styles={selectStyles}
          className={classes.select}
          onChange={onChange}
          value={value}
          options={options}
          isLoading={loading}
          formatOptionLabel={formatOptionLabel('HireStateSelect')}
          isDisabled={disabled}
        />
      </div>
      {error && (
        <Typography
          color="error"
          variant="caption"
          className={classes.error}
          data-test-id="HireStateSelect-error"
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

export default HireStateSelect;
