import gql from 'graphql-tag';

export default gql`
  mutation projectTemplateNotificationCreate(
    $id: Int!
    $ruleId: String!
    $distributionListsInput: [DistributionListInput!]!
  ) {
    projectTemplateNotificationCreate(
      id: $id
      ruleId: $ruleId
      distributionListsInput: $distributionListsInput
    )
  }
`;
