import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import Link from 'common/oldJavascripts/components/Base/Link';
import List from 'common/oldJavascripts/components/Base/List';
import NavBar from 'common/oldJavascripts/components/Base/NavBar';
import Form from 'common/oldJavascripts/components/Shared/Form';
import Loader from 'common/components/Loader';
import T from 'common/oldJavascripts/utils/i18n';
import collection from 'common/oldJavascripts/utils/collection.js';
import { Button as MatButton, Typography } from '@mui/material';
import isEqual from 'lodash.isequal';

// Mui Components
import { Edit as EditIcon } from '@mui/icons-material';
import { Grid, Paper, ListItemSecondaryAction } from '@mui/material';
import { withStyles } from '@mui/styles';
import Settings from './Settings';

const Field = Form.Field;
const Button = Form.Button;

const styles = {
  submitButton: {
    color: '#FFFFFF',
    background: '#0000FF',
    textTransform: 'none',
    '&:hover': {
      background: '#1A55FD',
    },
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  cancelButton: {
    border: '1px solid #0000FF',
    color: '#0000FF',
    background: '#FFFFFF',
    padding: '5px 20px',
    '&:hover': {
      background: '#FFFFFF',
    },
  },
  itemContent: {
    display: 'flex',
    justifyContent: 'space-evenly',
    float: 'none',
    fontWeight: 'normal',
    fontSize: 14,
  },
  itemContentBodyLabel: {
    width: '50%',
  },
  itemContentBodySetting: {
    width: '20%',
  },
  itemContentBodyValue: {
    width: '20%',
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-evenly',
    fontWeight: 'bold',
    padding: '6px 15px',
    backgroundColor: '#f2f2f3',
    '& > span': {
      color: '#979ca5',
      fontSize: 14,
      paddingLeft: 6,
      textDecoration: 'underline',
    },
  },
  field: {
    marginBottom: 0,
  },
  settingHeader: {
    fontSize: 14,
    fontWeight: '700 !important',
  },
};

const settings = setting => {
  if (setting['required']) {
    return 'Required';
  } else if (setting['allow_complete']) {
    return 'Available';
  } else {
    return 'N/A';
  }
};

const FreeFieldItem = Relay.createClass({
  statics: {
    mutators: {
      freeFormField: {
        info: function(params, related) {
          const routerParams = related['/router/params'];

          return {
            id:
              '/projects/' +
              routerParams.projectId +
              '/free-form-fields/' +
              params.id,
          };
        },
      },
    },
    queries: {
      project: {
        info: function(_, related) {
          const routerParams = related['/router/params'];

          return {
            id: '/projects/' + routerParams.projectId,
          };
        },
      },
    },
  },

  getInitialState: function() {
    return {
      expanded: false,
      defaultValue: this.props.freeFormField.data.default_value || '',
      error: [],
    };
  },

  handleDefaultValueChange(value) {
    const { freeFormField } = this.props;
    const isValid = new RegExp('^[a-zA-Z0-9]*$').test(value);
    if (isValid) {
      freeFormField.update('default_value')(value);
      this.setState({
        error: [],
      });
    } else {
      this.setState({
        error: [
          'This account code field is now restricted to alpha numeric characters',
        ],
      });
    }
  },

  handleOnChangeChip(value) {
    const { freeFormField } = this.props;
    if (value === 'Available') {
      freeFormField.update('allow_complete')(true);
      freeFormField.update('required')(false);
    } else if (value === 'Required') {
      freeFormField.update('allow_complete')(true);
      freeFormField.update('required')(true);
    } else if (value === 'N/A') {
      freeFormField.update('allow_complete')(false);
      freeFormField.update('required')(false);
    }
  },

  render: function() {
    const { freeFormField, classes } = this.props;

    let label = null;
    let defaultValue = null;
    let setting = null;
    let content = null;
    const { error } = this.state;

    const {
      data: { name: theName },
    } = freeFormField;

    const hasChanged = !isEqual(
      freeFormField?.dataBeforeEdits || {},
      freeFormField?.data || {},
    );

    const isDetailSubField = theName === 'detail/sub';

    // Check if code is for the insurance or ins
    // since we have to limit this fields character count to 2
    const isInsurance = theName === 'insurance' || theName === 'ins';

    // if its the insurance field, limit the max char length to 2; otherwise its 4
    const maxLength = isInsurance ? 2 : 4;

    if (this.state.expanded) {
      content = (
        <List.ItemContent>
          <Form
            action="edit"
            mutator={freeFormField}
            onSuccess={this._handleSuccess}
          >
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Paper elevation={0}>
                  <Field
                    mutator={freeFormField}
                    label={T('project.documents.free_form_fields.form.name')}
                    grouped={true}
                    type="text"
                    name="name"
                    readonly={true}
                    disabled={true}
                    className={classes.field}
                  />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={0}>
                  <Field
                    mutator={freeFormField}
                    label={T(
                      'project.documents.free_form_fields.form.description',
                    )}
                    grouped={true}
                    type="text"
                    name="description"
                    className={classes.field}
                  />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.settingHeader}>
                  Setting
                </Typography>
                <Settings
                  formField={freeFormField}
                  setValue={data => this.handleOnChangeChip(data)}
                />
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={0}>
                  {!isDetailSubField && (
                    <span
                      data-test-id={`FreeFormFields-defaultValue-${theName}`}
                    >
                      <Field
                        mutator={freeFormField}
                        label="Default Value"
                        id="default_value"
                        name="default_value"
                        type="text"
                        grouped
                        maxLength={maxLength}
                        inlineLabel
                        onChange={value => this.handleDefaultValueChange(value)}
                        errors={error}
                      />
                    </span>
                  )}
                </Paper>
              </Grid>
            </Grid>
            <NavBar>
              <NavBar.Item>
                <Button
                  mutator={freeFormField}
                  data-test-id={`FreeFormFields-update-${theName}`}
                  color="default"
                  className={classes.submitButton}
                  disabled={!hasChanged}
                >
                  Save
                </Button>
              </NavBar.Item>
              <NavBar.Item>
                <MatButton
                  className={classes.cancelButton}
                  onClick={this._collapseForm}
                >
                  Cancel
                </MatButton>{' '}
              </NavBar.Item>
            </NavBar>
          </Form>
        </List.ItemContent>
      );
    } else {
      label = freeFormField.data.name;
      defaultValue = freeFormField.data.default_value;
      setting = settings(freeFormField.data);
      if (freeFormField.data.description) {
        label += ' (' + freeFormField.data.description + ')';
      }
      content = (
        <List.ItemContent className={classes.itemContent}>
          <List.ItemContentBody className={classes.itemContentBodyLabel}>
            {label}
          </List.ItemContentBody>
          <List.ItemContentBody className={classes.itemContentBodySetting}>
            {setting}
          </List.ItemContentBody>
          <List.ItemContentBody className={classes.itemContentBodyValue}>
            {defaultValue || '--'}
          </List.ItemContentBody>
          <ListItemSecondaryAction>
            <Link
              onClick={this._expandForm}
              icon={true}
              data-test-id={`FreeFormFields-editButton-${theName}`}
            >
              <EditIcon data-test-id={`AccountCodeFields-edit-${label}`} />
            </Link>
          </ListItemSecondaryAction>
        </List.ItemContent>
      );
    }

    return <List.Item>{content}</List.Item>;
  },

  _expandForm: function() {
    this.setState({ expanded: true });
  },

  _collapseForm: function() {
    const { freeFormField } = this.props;
    const { defaultValue } = this.state;
    freeFormField.update('default_value')(`${defaultValue}`.toUpperCase());
    this.setState({ expanded: false });
  },

  _handleSuccess: function() {
    const { freeFormField, updateDetailSubDataType } = this.props;
    const {
      data: { default_value, name, data_type: dataType },
    } = freeFormField;
    if (name === 'detail/sub') {
      updateDetailSubDataType(dataType);
    }
    this.setState({ expanded: false, defaultValue: default_value });
  },
});

const FreeFied = withStyles(styles)(FreeFieldItem);

const FreeFormFields = Relay.createClass({
  statics: {
    queries: {
      freeFormFields: {
        info: function(_, related) {
          var params = related['/router/params'];

          return {
            id: '/projects/' + params.projectId + '/free-form-fields',
          };
        },
      },
    },
  },

  render: function() {
    const {
      freeFormFields,
      updateDetailSubDataType,
      updateEnforceMask,
      enforceMask,
      accountMask,
      classes,
    } = this.props;

    if (freeFormFields.status === 'loading') {
      return <Loader />;
    }

    return (
      <>
        <div className={classes.tableHeader}>
          <span className={classes.itemContentBodyLabel}>
            Name(Description)
          </span>
          <span className={classes.itemContentBodySetting}>Setting</span>
          <span className={classes.itemContentBodyValue}>Default Value</span>
          <span>{''}</span>
        </div>
        <List>
          {collection.map(freeFormFields.data.items, function(freeFormField) {
            return (
              <FreeFied
                key={freeFormField.id}
                params={{ id: freeFormField.id }}
                updateDetailSubDataType={updateDetailSubDataType}
                enforceMask={enforceMask}
                accountMask={accountMask}
                updateEnforceMask={updateEnforceMask}
              />
            );
          })}
        </List>
      </>
    );
  },
});

const FreeFormFieldsComponent = withStyles(styles)(FreeFormFields);

export default FreeFormFieldsComponent;
