import inverseFieldPositioning from './inverseFieldPositioning';
import adjustFieldPositioning from './adjustFieldPositioning';
import repositionFields from './repositionFields';
import resizeFields from './resizeFields';

const updateFieldLayout = (pageDimensions, field) => {
  const { originalDimensions, updatedDimensions } = pageDimensions;
  let newField = { ...field };
  // Inverse Coordinates
  // So that we can use css: top
  // The raw data we get sends points from the bottom of image
  // To the top of the field
  let inversedFieldCoordinates;
  if (newField.fieldType === 'RDO') {
    inversedFieldCoordinates = {
      radioGroupItems: [],
    };
    newField.radioGroupItems.forEach(radioField => {
      inversedFieldCoordinates.radioGroupItems.push({
        ...radioField,
        ...inverseFieldPositioning(originalDimensions, radioField),
      });
    });
  } else {
    inversedFieldCoordinates = inverseFieldPositioning(
      originalDimensions,
      newField,
    );
  }
  newField = { ...newField, ...inversedFieldCoordinates };

  // Reposition fields for page resizing
  // Raw data gives us a reference size to base this off of
  let repositionedFieldCoordinates;
  if (newField.fieldType === 'RDO') {
    repositionedFieldCoordinates = {
      radioGroupItems: [],
    };
    newField.radioGroupItems.forEach(radioField => {
      repositionedFieldCoordinates.radioGroupItems.push({
        ...radioField,
        ...repositionFields(pageDimensions, radioField),
      });
    });
  } else {
    repositionedFieldCoordinates = repositionFields(pageDimensions, newField);
  }
  newField = { ...newField, ...repositionedFieldCoordinates };

  // Adjust Coordinates
  // Raw data does not place fields in correct spot
  // We need to bump them a bit based on the page dimensions
  let adjustedFieldCoordinates;
  if (newField.fieldType === 'RDO') {
    adjustedFieldCoordinates = {
      radioGroupItems: [],
    };
    newField.radioGroupItems.forEach(radioField => {
      adjustedFieldCoordinates.radioGroupItems.push({
        ...radioField,
        ...adjustFieldPositioning(updatedDimensions, radioField),
      });
    });
  } else {
    adjustedFieldCoordinates = adjustFieldPositioning(
      updatedDimensions,
      newField,
    );
  }
  newField = { ...newField, ...adjustedFieldCoordinates };

  // Resize fields for page resizing
  // Raw data gives us a referense size to base this off of
  let resizedFieldSizes;
  if (newField.fieldType === 'RDO') {
    resizedFieldSizes = {
      radioGroupItems: [],
    };
    newField.radioGroupItems.forEach(radioField => {
      resizedFieldSizes.radioGroupItems.push({
        ...radioField,
        ...resizeFields(pageDimensions, radioField),
      });
    });
  } else {
    resizedFieldSizes = resizeFields(pageDimensions, newField);
  }
  newField = { ...newField, ...resizedFieldSizes };

  return newField;
};

export default updateFieldLayout;
