import React from 'react';

import { withStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { Button, Tooltip } from '@mui/material';
import {
  TextFields as TextFieldsIcon,
  Check as CheckIcon,
  DateRange as DateIcon,
  CalendarToday as TodaysDateIcon,
} from '@mui/icons-material';
import {
  SignatureIcon_mapper as SignatureIcon,
  InitialsSignatureIcon_mapper as InitialsSignatureIcon,
  Number as NumberIcon,
  RadioButton as RadioButtonIcon,
  Dropdown as DropdownIcon,
} from 'common/icons';
import PrintNameIcon from '@mui/icons-material/BorderColorTwoTone';

import RadioButtonFields from './RadioButtonFields';

// Utilities
import classNames from 'classnames';
import * as FieldTypes from 'common/utilities/constants/fieldTypes';
import * as palette from 'common/shared/oldDocumentSignerUI/palette';
import {
  isFieldDisabled,
  getButtonColor,
} from 'admin/components/RoleMapper/documentSetupHelpers/fields';

const styles = theme => ({
  radioButtonContainer: {
    position: 'absolute',
    width: 0,
    height: 0,
  },
  requiredField: {
    border: `2px solid ${palette.primary} !important`,
  },
  tooltip: {
    fontSize: '0.9rem !important',
  },
});

// Render the different text fields if the field type is TXT
// or render the other non-TXT field types
const renderDisplayIcon = (fieldType, textType, iconDynamicStyle) => {
  const formattedInput = fieldType ? fieldType.toUpperCase() : '';
  const formattedText = textType ? textType.toUpperCase() : '';
  const style = { margin: '0 auto' };
  if (formattedInput === 'TXT') {
    switch (formattedText) {
      case FieldTypes.SIGNATURE:
        return <SignatureIcon style={style} />;
      case FieldTypes.INITIAL_SIGNATURE:
        return <InitialsSignatureIcon style={style} />;
      case FieldTypes.DATE:
        return <DateIcon style={iconDynamicStyle} />;
      case FieldTypes.NUMBER:
        return <NumberIcon style={iconDynamicStyle} />;
      case FieldTypes.DATE_SIGNED:
        return <TodaysDateIcon style={iconDynamicStyle} />;
      case FieldTypes.PRINT_NAME:
        return <PrintNameIcon style={iconDynamicStyle} />;
      default:
        return <TextFieldsIcon style={iconDynamicStyle} />;
    }
  }
  switch (formattedInput) {
    case FieldTypes.CHK:
      return <CheckIcon style={iconDynamicStyle} />;
    case FieldTypes.RDO:
      return <RadioButtonIcon style={iconDynamicStyle} />;
    case FieldTypes.CMB:
      return <DropdownIcon style={iconDynamicStyle} />;
    default:
      return <TextFieldsIcon style={iconDynamicStyle} />;
  }
};

const StyledButton = styled(Button)({
  position: 'absolute',
  textAlign: 'left',
  justifyContent: 'flex-start',
  color: '#000000 !important',
  padding: '0.8px',
});

const FieldIconButton = props => {
  const {
    activeRuleGroupId,
    handleFieldRuleGroupChange,
    handleOpenDrawer,
    handleRoleAssignment,
    roleId,
    activeRoleId,
    displayName,
    activeRuleId,
    roles = [],
    rules = [],
    fields = [],
    id,
    activeRuleRoleId,
    classes,
    fieldType,
    textType,
    x,
    y,
    height,
    width,
    radioGroupItems = [],
    isRequired,
    highlightUnmappedFields,
    triggerSourceIdToHighlight,
    triggerRuleIdToHighlight,
    drawerFieldId,
    drawerOpen,
    page,
  } = props;
  const fieldDrawerOpened =
    drawerOpen && fieldType !== FieldTypes.RDO && id === drawerFieldId;
  const emails = rules.filter(({ ruleType }) => ruleType === 'email');
  const activeEmailId = (emails.find(({ id }) => activeRuleId === id) || {}).id;
  const isDisabled = isFieldDisabled({
    roles,
    rules,
    fields,
    activeRoleId,
    activeRuleGroupId,
    activeRuleId,
    roleId,
    id,
    activeEmailId,
  });
  const getButtonAction = () => {
    if (isDisabled) {
      return () => {};
    }
    if (activeRuleGroupId || activeEmailId) {
      return handleFieldRuleGroupChange;
    }
    if (activeRoleId === null) {
      return handleOpenDrawer;
    }
    return handleRoleAssignment;
  };
  const getTooltipMessage = () => {
    const mappedRole = roles.find(role => role.id === `${roleId}`) || {};
    const mappedRule =
      rules.find(({ fieldGroups = [] }) =>
        fieldGroups.some(({ fieldIds = [] }) => fieldIds.includes(id)),
      ) || {};

    const { fieldGroups = [], ruleType, name: ruleName = '' } = mappedRule;
    const { name: roleName = '' } = mappedRole;

    const mappedRuleGroup =
      fieldGroups.find(({ fieldIds = [] }) => fieldIds.includes(id)) || {};

    const { name: ruleGroupName = '', id: mappedRuleGroupId } = mappedRuleGroup;

    const isTriggerSource = rules.some(
      ({ triggerFieldId }) => triggerFieldId === id,
    );

    let tooltipMessage = '';
    // Update the tool tip message if the field is assigned to a role
    const isAssignedToDifferentRole =
      activeRoleId && roleId && roleId !== activeRoleId;
    if (isDisabled && isAssignedToDifferentRole) {
      tooltipMessage = displayName
        ? `${displayName} is already mapped to ${roleName}.`
        : `This field is already mapped to ${roleName}.`;
    }
    // Update the tool tip message if the user is mapping a rule and the field is already assigned to a different rule
    const isAssignedToDifferentRuleGroup =
      mappedRuleGroupId &&
      activeRuleGroupId &&
      activeRuleGroupId !== mappedRuleGroupId;
    if (isDisabled && isAssignedToDifferentRuleGroup) {
      if (ruleType === 'group') {
        tooltipMessage = `This field is already mapped to required rule: ${ruleName} in group: ${ruleGroupName}.`;
      } else {
        tooltipMessage = `This field is already mapped to trigger rule: ${ruleName}.`;
      }
    }
    // Update the tool tip message if the user is mapping a rule and the field is assigned to a role that differs from the role in the rule
    if (activeRuleRoleId !== roleId && activeRuleRoleId) {
      tooltipMessage =
        'This field is mapped to a role different from the other fields that belong to this rule';
    }
    // Update the tool tip message if the user is mapping a rule and the field has not yet been assigned to a role
    if (activeRuleGroupId && roleId === null) {
      tooltipMessage = 'This field is is not yet mapped to a role.';
    }
    // Display a tooltip notifying the user that the field is assigned as a trigger rule source
    if (isTriggerSource) {
      tooltipMessage = 'This field is a trigger rule source.';
    }
    return tooltipMessage;
  };
  const displayColor = getButtonColor({
    isDisabled,
    activeRoleId,
    roleId,
    highlightUnmappedFields,
    activeRuleGroupId,
    rules,
    triggerRuleIdToHighlight,
    fieldId: id,
    triggerSourceIdToHighlight,
    activeEmailId,
    emails,
    fieldDrawerOpened,
  });
  const fieldDynamicStyle = {
    top: y,
    left: x,
    height,
    width,
    minWidth: width,
    minHeight: height,
    padding: 0,
    ...displayColor,
  };
  const iconDynamicStyle = {
    height,
    width,
    minWidth: width,
    minHeight: height,
  };
  const buttonAction = getButtonAction();
  const displayIcon = renderDisplayIcon(fieldType, textType, iconDynamicStyle);
  const tooltipMessage = getTooltipMessage();
  const buttonClasses = classNames(
    {
      [classes.requiredField]: isRequired,
    },
    classes.button,
  );

  // render radio buttons
  if (fieldType === FieldTypes.RDO) {
    // Continue Color Index from previous pages
    // when radio items are splits between 2 or more pages.
    const contColorIdx = radioGroupItems.reduce(
      (total, radioItem) => total + (radioItem.page < page ? 1 : 0),
      0,
    );
    const radioProps = {
      radioItems: radioGroupItems.filter(radioItem => radioItem.page === page),
      displayIcon,
      displayColor,
      tooltipMessage,
      disabled: isDisabled,
      buttonAction,
      isRequired,
      displayName,
      id,
      drawerFieldId,
      drawerOpen,
      contColorIdx,
    };
    return <RadioButtonFields {...radioProps} />;
  }

  const tooltipTitle = isDisabled
    ? tooltipMessage
    : displayName || 'Not Available';

  return (
    <Tooltip
      disableInteractive
      title={tooltipTitle}
      placement="top"
      key={`FieldIconButton-tooltip-${id}`}
      classes={{ tooltip: classes.tooltip }}
    >
      <StyledButton
        disableRipple
        className={buttonClasses}
        style={fieldDynamicStyle}
        onClick={() => buttonAction(id)}
        data-test-id={`FieldIconButton-button-${id}`}
        data-tooltip-title={tooltipTitle}
      >
        {displayIcon}
      </StyledButton>
    </Tooltip>
  );
};

export default withStyles(styles)(FieldIconButton);
