import gql from 'graphql-tag';

export default gql`
  mutation createAdminUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $middleName: String
    $permissions: [Int!]!
  ) {
    createAdminUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      permissions: $permissions
    ) {
      id
    }
  }
`;
