import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { styled } from '@mui/system';

import useRescindAdditionalDocuments from './hooks/useRescindAdditionalDocuments';
import ErrorIndicator from '../SendAdditionalDocuments/ErrorIndicator';

const StyledConfirmButton = styled(Button)({
  padding: '8px 20px',
  marginRight: '10px',
  boxSizing: 'border-box',
  boxShadow:
    ' 0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
  borderRadius: '2px',
  '&:disabled': {
    boxShadow: ' 0px 0px 1px rgba(82, 82, 82, 0.4)',
  },
});

const StyledCancelButton = styled(Button)({
  padding: '8px 12px',
  boxSizing: 'border-box',
  margin: '0px 14px',
  boxShadow:
    '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
  borderRadius: '2px',
});

const AlertDialog = ({ open, alertClose, id, classes }) => {
  const [
    rescindAdditionalDocuments,
    { loading },
  ] = useRescindAdditionalDocuments();
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [open]);

  const handleRescind = () => {
    const variables = {
      offerId: parseInt(id),
    };

    rescindAdditionalDocuments({ variables })
      .then(({ data }) => {
        const { rescindAdditionalDocuments } = data;
        if (rescindAdditionalDocuments) alertClose(true);
      })
      .catch(err => {
        setError(true);
      });
  };

  return (
    <Dialog open={open}>
      <DialogTitle data-test-id={`rescind-offer-modal-title-${id}`}>
        Rescind Additional Documents
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to rescind the added documents?
        </DialogContentText>
        {error && (
          <ErrorIndicator message="Oops! Something went wrong. Please try after some time or contact support" />
        )}
      </DialogContent>
      <DialogActions>
        {error ? (
          <Button
            className={classes.apply}
            data-test-id={`copy-offer-no-${id}`}
            onClick={() => alertClose(false)}
          >
            Ok
          </Button>
        ) : (
          <>
            <StyledCancelButton
              color="primary"
              variant="outlined"
              data-test-id={`copy-offer-no-${id}`}
              onClick={() => alertClose(false)}
            >
              No
            </StyledCancelButton>
            <StyledConfirmButton
              color="primary"
              variant="contained"
              data-test-id={`copy-offer-yes-${id}`}
              autoFocus
              onClick={() => handleRescind()}
              disabled={loading}
            >
              Yes
            </StyledConfirmButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
