import React from 'react';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';

const styles = {
  root: {
    lineHeight: '1.23076923',
    background: '#ffffff',
    padding: '0px 40px 20px',
    position: 'relative',
  },
  title: {
    fontSize: '1.53846154em',
    paddingBottom: '10px',
    fontWeight: '700',
  },
  minimal: { padding: '0px' },
  compact: { padding: '0 0 10px 0' },
  alignLeft: { textAlign: 'left' },
  alignRight: { textAlign: 'right' },
  alignCenter: { textAlign: 'center' },
  marginTop: { paddingTop: 20 },
};

const BoxParagraphItem = props => {
  const {
    classes,
    align,
    title,
    minimal,
    compact,
    children,
    marginTop,
    'data-test-id': dataTestId,
  } = props;
  const rootClass = classNames(classes.root, {
    [classes.alignLeft]: align === 'left',
    [classes.alignCenter]: align === 'center',
    [classes.alignRight]: align === 'right',
    [classes.title]: title,
    [classes.minimal]: minimal,
    [classes.compact]: compact,
    [classes.marginTop]: marginTop,
  });
  return (
    <div className={rootClass} data-test-id={dataTestId}>
      {children}
    </div>
  );
};

export default withStyles(styles)(BoxParagraphItem);
