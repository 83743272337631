import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import getSSOUrl from 'common/constants/config/ssoUrl';
import formatPhoneNumber from 'common/utilities/formatPhoneNumber';
import LoanoutCorporation from './LoanoutCorporation';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 10px !important',
    [theme.breakpoints.up('md')]: {
      padding: '0 27px !important',
    },
  },
  title: {
    color: '#646464 !important',
    fontSize: '24px !important',
    fontWeight: 400,
  },
  subTitle: {
    fontSize: '16px !important',
    fontWeight: '600 !important',
    marginTop: '24px !important',
  },
  subTitleError: {
    color: '#ED1C23 !important',
    display: 'flex',
  },
  topCopy: {
    color: '#939393 !important',
    fontSize: '16px !important',
    fontWeight: 400,
  },
  editLink: {
    color: '#0000FF !important',
    fontSize: '16px !important',
    fontWeight: 400,
    textDecoration: 'none',
  },
  item: {
    marginTop: '16px !important',
  },
  itemName: {
    color: '#0000FF !important',
    fontSize: '14px !important',
    fontWeight: 400,
    marginBottom: '4px !important',
  },
  itemValue: {
    fontSize: 16,
    fontWeight: 400,
    wordWrap: 'break-word',
  },
  itemValueExpired: {
    color: '#C6C6C6 !important',
  },
  warningIcon: {
    color: '#ED1C23 !important',
    marginRight: 4,
  },
  subWarningIcon: {
    color: '#ED1C23 !important',
    marginRight: 4,
    marginTop: 2,
  },
  statusChip: {
    textTransform: 'capitalize',
    fontSize: 12,
    borderRadius: 8,
  },
  statusChipExpired: {
    backgroundColor: '#FFCACF !important',
    color: '#C10004 !important',
  },
  statusChipValid: {
    backgroundColor: '#C1F4CC !important',
    color: '#008006 !important',
  },
  statusChipPending: {
    backgroundColor: '#FFEBB2 !important',
    color: '#FFBF00 !important',
  },
  corpReverifyButton: {
    marginLeft: 4,
    color: '#0000FF !important',
    borderColor: '#0000FF !important',
    borderRAdius: 2,
  },
  corpAddButton: {
    backgroundColor: '#0000FF !important',
    marginTop: 16,
    color: '#FFFFFF !important',
    '&:hover': {
      backgroundColor: '#1A55FD !important',
    },
    '&:active': {
      backgroundColor: '#0000B3 !important',
    },
  },
}));
const profileUrl = `${getSSOUrl()}/profile`;

const HiringProfile = props => {
  const classes = useStyles();
  const {
    crewData = {},
    offer = {},
    loanoutCorp = {},
    isLoanoutOffer = false,
    isCanada = false,
    isMycncLoanoutFlagActive = false,
  } = props;

  const { id: offerId } = offer;
  const { first_name: firstName, last_name: lastName, email, phone } = crewData;
  const { status: loanoutCorpStatus } = loanoutCorp;

  const isLoanoutCorpStatusInvalid =
    loanoutCorpStatus === 'missing' || loanoutCorpStatus === 'expired';

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        {!isCanada && isLoanoutOffer && isLoanoutCorpStatusInvalid && (
          <WarningIcon
            data-test-id="HiringProfile-title-warning-icon"
            size="small"
            className={classes.warningIcon}
          />
        )}
        Your Hiring Profile
      </Typography>
      <Typography className={classes.subTitle}>Crew Member Profile</Typography>
      <Typography className={classes.topCopy}>
        Update your profile if anything is incorrect.
      </Typography>
      <Typography className={classes.editLink}>
        <a href={profileUrl} className={classes.editLink}>
          Edit Profile
        </a>
      </Typography>
      <Grid container className={classes.item}>
        <Grid item xs={4} className={classes.itemName}>
          Name
        </Grid>
        <Grid item xs={8} className={classes.itemValue}>
          {firstName} {lastName}
        </Grid>
        {phone && (
          <Grid item xs={4} className={classes.itemName}>
            Phone Number
          </Grid>
        )}
        {phone && (
          <Grid item xs={8} className={classes.itemValue}>
            {formatPhoneNumber(phone)}
          </Grid>
        )}
        {email && (
          <Grid item xs={4} className={classes.itemName}>
            Email Address
          </Grid>
        )}
        {email && (
          <Grid item xs={8} className={classes.itemValue}>
            {email}
          </Grid>
        )}
      </Grid>
      {isLoanoutOffer && isMycncLoanoutFlagActive && (
        <LoanoutCorporation
          classes={classes}
          corporation={loanoutCorp}
          offerId={offerId}
          isCanada={isCanada}
          offer={offer}
        />
      )}
    </div>
  );
};

export default HiringProfile;
