export default queries => {
  if (!Array.isArray(queries)) {
    throw new Error(`Not an array: ${queries}`);
  }
  const statuses = queries.filter(Boolean).map(query => query.status);
  if (!statuses.length) {
    throw new Error('No queries provided.');
  }
  if (statuses.every(status => status === 'success')) {
    return 'success';
  }
  if (statuses.indexOf('failed') >= 0) {
    return 'failed';
  }
  if (statuses.indexOf('loading') >= 0) {
    return 'loading';
  }
  throw new Error(`Invalid query status found: "${statuses.join('", "')}"`);
};
