import gql from 'graphql-tag';

export default gql`
  query permissionPresets {
    permissionPresets {
      name
      id
      updatedAt
      createdAt
      permissions {
        id
      }
    }
  }
`;
