import React, { Component } from 'react';

import { withStyles, ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Close } from '@mui/icons-material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
  },
});

const styles = theme => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '100%',
    padding: theme.spacing(2.2),
    background: theme.palette.bottomDialogBackground,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1.2),
    top: theme.spacing(1.2),
    width: 50,
    color: '#FFFFFF',
  },
  primaryButton: {
    color: '#0000FF',
    marginTop: theme.spacing(2),
    display: 'block',
  },
  textField: {
    width: '40%',
    marginTop: theme.spacing(2.2),
  },
  input: {
    color: '#FFFFFF',
  },
  disclaimer: {
    marginRight: theme.spacing(1.5),
    color: '#FFFFFF',
    maxHeight: 75,
    overflow: 'auto',
  },
  header: {
    color: '#FFFFFF',
    textTransform: 'capitalize',
  },
  errorMessage: {
    minHeight: '1em',
    color: theme.palette.error.main,
    fontSize: '16px',
  },
});

class SignatureTOSDialog extends Component {
  static defaultProps = {
    signatureTOS: `Only the person associated with these records is permitted to use this system through this account.  Completion and submission of the online forms available through this system will create legally binding obligations associated with each form. Please read each form carefully.  Execution of each form will be accomplished through clicking on the “I Agree” button provided at the bottom of each form.  By clicking “I Agree” at the bottom of each form, you will be acknowledging that you have read the applicable form, understand the requirements for submission of the information, agree to submit complete and accurate information, and further agree to submit and sign these forms electronically through this system.`,
  };

  state = { fieldContent: '', errorMessage: null, hasFocused: false };

  componentDidMount() {
    this.checkFocus(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // Only focus on the text if it was closed and we are opening it
    this.checkFocus(nextProps);
  }

  checkFocus = props => {
    if (!this.state.hasFocused && props.isOpen && this.textInput) {
      this.textInput.focus();
    }
  };

  handleChange = event => {
    this.setState({
      fieldContent: event.target.value,
    });
  };

  onKeyPress = ({ key }) => {
    if (key === 'Enter') {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const {
      actor,
      config: {
        field: {
          _derived: { isInitials, isValidated },
        },
      },
      handleSubmit: upstreamHandleSubmit,
    } = this.props;
    let { fieldContent } = this.state;
    fieldContent = `${fieldContent}`.trim();

    if (isValidated) {
      const expectedContent = isInitials ? actor.initials : actor.name;
      if (fieldContent.toLowerCase() !== expectedContent.trim().toLowerCase()) {
        const errorMessage = isInitials
          ? `Please enter your initials as recorded in your profile: ${expectedContent}`
          : `Please enter your full legal name as recorded in your profile: ${expectedContent}`;
        this.setState({ errorMessage });
        return;
      }
    }

    this.setState({ errorMessage: null, hasFocused: false });
    upstreamHandleSubmit(fieldContent);
  };

  handleClose = () => {
    const { handleClose } = this.props;

    this.setState({ hasFocused: false, fieldContent: '', errorMessage: null });
    handleClose();
  };

  render() {
    const { config = {}, classes, signatureTOS } = this.props;
    const { field, open } = config || {};
    const { fieldContent, errorMessage } = this.state;
    const { _derived: { isInitials } = {} } = field || {};

    return (
      <ThemeProvider theme={theme}>
        <Drawer open={open} anchor="bottom">
          <div className={classes.root}>
            <Typography
              variant="h5"
              gutterBottom
              className={classes.header}
              data-test-id="SignatureTOSDialog-title"
            >
              {isInitials ? 'INITIALS' : 'SIGNATURE'}
            </Typography>
            <Button
              className={classes.closeButton}
              onClick={this.handleClose}
              tabIndex="-1"
              data-test-id="SignatureTOSDialog-closeButton"
            >
              <Close />
            </Button>
            <Typography component="p" className={classes.disclaimer}>
              {signatureTOS}
            </Typography>
            <TextField
              autoFocus
              className={classes.textField}
              inputRef={ref => {
                this.textInput = ref;
              }}
              label={isInitials ? 'Initials' : 'Full Legal Name'}
              onChange={e => this.handleChange(e)}
              data-test-id="SignatureTOSDialog-textField"
              InputProps={{
                className: classes.input,
                inputProps: {
                  'data-test-id': 'SignatureTOSDialog-input',
                },
              }}
              onKeyPress={this.onKeyPress}
            />
            <Button
              className={classes.primaryButton}
              color="primary"
              variant="contained"
              onClick={this.handleSubmit}
              disabled={!`${fieldContent}`.trim()}
              data-test-id="SignatureTOSDialog-submitButton"
            >
              I Agree
            </Button>
            <Typography
              color="primary"
              type="body"
              className={classes.errorMessage}
              data-test-id="SignatureTOSDialog-errorMessage"
            >
              {errorMessage}
            </Typography>
          </div>
        </Drawer>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(SignatureTOSDialog);
