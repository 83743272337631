import React, { useEffect } from 'react';

// HoC
import { connect } from 'react-redux';
import { compose } from 'redux';

// Constants
import { Provider } from './authenticationContext';

// Actions
import { requestAccessToken } from 'common/store/actions/tokens';
import { getSessionStatus } from 'common/store/actions/session';

const AuthenticationProvider = props => {
  const {
    requestAccessToken,
    getSessionStatus,
    accessToken,
    accessTokenRequestInProgress,
    accessTokenRequestFailed,
    sessionActive,
    sessionCheckInProgress,
    sessionCheckFailed,
    children,
  } = props || {};

  useEffect(() => {
    if (
      !accessToken &&
      !accessTokenRequestInProgress &&
      !accessTokenRequestFailed
    ) {
      requestAccessToken();
    }
  }, [
    accessToken,
    accessTokenRequestInProgress,
    accessTokenRequestFailed,
    requestAccessToken,
  ]);

  useEffect(() => {
    if (!sessionActive && !sessionCheckInProgress && !sessionCheckFailed) {
      getSessionStatus();
    }
  }, [
    sessionActive,
    sessionCheckInProgress,
    sessionCheckFailed,
    getSessionStatus,
  ]);

  const value = {
    accessToken,
    sessionActive,
  };

  return <Provider value={value}>{children}</Provider>;
};

const mapStateToProps = ({ tokens, session }) => ({
  ...tokens,
  ...session,
});

const mapDispatchToProps = {
  requestAccessToken,
  getSessionStatus,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AuthenticationProvider,
);
