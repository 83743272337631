import DocumentModal from 'common/oldJavascripts/components/Shared/DocumentModal';

import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';

export default Relay.createClass({
  statics: {
    queries: {
      generateImageDocuments: {
        info(params) {
          return {
            cursor: {
              documents_ids: params.documentId,
            },
            id: `${params.scope}/download-image-documents`,
          };
        },
      },
    },
  },

  render() {
    return (
      <DocumentModal
        onClose={this.props.onClose}
        pages={this.props.generateImageDocuments.data.file_paths || []}
      />
    );
  },
});
