import React, { useState } from 'react';
import STARTPLUS_LOGO from 'common/images/start-white-logo-01.svg';
import Logo from './Logo';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { EmailOutlined, HelpOutlineOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { styled } from '@mui/system';
import { Button, Tooltip } from '@mui/material';
import ContactsModal from './ContactsModal';

const ActionsAndLogoContainer = styled('div')({
  display: 'flex',
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  minWidth: '140px',
  maxWidth: '180px',
});

const ActionsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignSelf: 'flex-start',
  alignItems: 'center',
  marginRight: '30px',
});

const Action = styled('div')(({ active }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '30px',
  width: '30px',
  borderRadius: '6px',
  marginRight: '10px',
  background: active ? '#1A55FD' : 'transparent',
  '&:hover': {
    background: '#1A55FD',
  },
}));

const ActionRight = styled('div')(({ active }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '30px',
  width: '30px',
  borderRadius: '6px',
  background: active ? '#1A55FD' : 'transparent',
  '&:hover': {
    background: '#1A55FD',
  },
}));

const StyledButton = styled(Button)({
  height: '30px',
  width: '30px',
  minWidth: '30px',
  color: 'white',
  borderRadius: '6px',
  marginRight: '10px',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#1A55FD',
  },
});

const ActionsAndLogo = props => {
  const {
    nonEmployee,
    employee,
    offerId,
    location: { pathname = '' },
  } = props;
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <ActionsAndLogoContainer>
        <ActionsContainer>
          {employee && (
            <>
              {offerId && (
                <Tooltip title="Contacts">
                  <StyledButton onClick={() => setModalOpen(true)}>
                    <EmailOutlined color="white" height="16px" />
                  </StyledButton>
                </Tooltip>
              )}
              <Tooltip title="Resource center">
                <ActionRight id="Resource-center">
                  <HelpOutlineOutlined />
                </ActionRight>
              </Tooltip>
            </>
          )}
          {nonEmployee && (
            <>
              <Tooltip title="Resource center">
                <Action id="Resource-center">
                  <HelpOutlineOutlined />
                </Action>
              </Tooltip>
              <Link to="/profile/notifications">
                <Tooltip title="Settings">
                  <ActionRight active={pathname.includes('notifications')}>
                    <SettingsOutlinedIcon height="16px" />
                  </ActionRight>
                </Tooltip>
              </Link>
            </>
          )}
        </ActionsContainer>
        <Logo src={STARTPLUS_LOGO} alt="Start+ Logo" />
      </ActionsAndLogoContainer>
      {modalOpen && (
        <ContactsModal
          open={modalOpen}
          offerId={offerId}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
};

export default withRouter(ActionsAndLogo);
