import React, { useState } from 'react';
import { Slide } from '@mui/material';
import withApi from 'common/hoc/withApi';
import Dialog from '@mui/material/Dialog';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { logout } from 'common/store/actions/authenticate';
import AddNote from 'mobile/components/Projects/AddNote';
import NoteSent from 'mobile/components/Projects/NoteSent';
import history from 'common/constants/config/history';

const Transition = props => {
  return <Slide direction="up" {...props} />;
};

const RejectOffer = props => {
  const {
    open,
    handleClose,
    projectId,
    selectedOfferId,
    logout,
    invalidateByPatterns,
    offerReject,
    i9Document,
  } = props;
  const [isNoteSent, setNoteSent] = useState(false);

  const rejectOfferConfirmation = (comment, keep_id) => {
    if (!selectedOfferId || !projectId) return;
    offerReject.updateAndSave(
      { ids: [selectedOfferId], comment, keep_id },
      'reject',
      { json: true },
      () => {
        invalidateByPatterns([
          `/projects/${projectId}/offers`,
          `/v2/projects/${projectId}/approvals`,
        ]);
      },
    );
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => handleClose}
      TransitionComponent={Transition}
      data-test-id="RejectOffer-Modal"
    >
      {!isNoteSent && (
        <AddNote
          onClose={handleClose}
          closeAddNote={() => setNoteSent(true)}
          i9Document={i9Document}
          rejectOfferHandler={rejectOfferConfirmation}
        />
      )}
      {isNoteSent && (
        <NoteSent
          closeHandler={handleClose}
          logoutHandler={logout}
          projectId={projectId}
        />
      )}
    </Dialog>
  );
};

RejectOffer.mutators = {
  offerReject: {
    info() {
      const { location: { state: { projectId = null } } = {} } = history;
      return {
        create: true,
        id: `/v2/projects/${projectId}/approvals`,
      };
    },
  },
};

const mapDispatchToProps = {
  logout,
};

export default compose(withApi, connect(null, mapDispatchToProps))(RejectOffer);
