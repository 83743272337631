import React, { useState } from 'react';

// CONSTANTS
import { FETCH_MORE_NETWORK_STATUS } from 'common/constants/graphqlNetworkStatus/networkStatus';

// HOC
import withApi from 'common/hoc/withApi';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withPermissionProtection from 'common/hoc/withPermissionProtection';
import withRouteHelpers from 'common/hoc/withApi';

// MUI
import { makeStyles } from '@mui/styles';

// COMPONENTS
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import Loader from 'common/components/Loader';
import Search from 'common/oldJavascripts/components/Shared/Search';
import CrewMembers from 'studio/components/ManageCrew/CrewMembers';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import FetchMoreCrewMembers from './FetchMoreCrewMembers';
import ExportCrewButton from './ExportCrewButton';

// LAYOUT COMPONENTS
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Box from 'common/oldJavascripts/components/Base/Box';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Button from 'common/oldJavascripts/components/Base/Button';
import Header from 'common/oldJavascripts/components/Base/Header';

// HOOKS
import useCrewList from 'studio/hooks/useCrewList';

// QUERY REFRESHER && AJAX
import QueryRefresher from 'common/components/QueryRefresher';
import ajax from 'common/utilities/ajax';

// ACTIONS
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';

// TABLE COMPONENTS
import ExpandableTableHeaderGraphQL from 'common/components/ExpandableTableHeaderGraphQL';
import ExpandableTable from 'common/oldJavascripts/components/Base/ExpandableTable';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';

const ETableRow = ExpandableTableRow;
const GraphQLTableHeader = ExpandableTableHeaderGraphQL;

const useStyles = makeStyles(theme => ({
  button: {
    margin: '5px',
  },
  loader: {
    backgroundColor: 'white',
    width: '100%',
    height: '200px',
  },
  crewMembersCount: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.16px',
    color: '#8D8D8D',
  },
}));

const CrewList = props => {
  const {
    projectId,
    me: meQuery = {},
    routerQuery: { q: searchTerm = '' },
    pushNotification,
    popNotification,
  } = props;

  const classes = useStyles();

  // HOOKS
  const [filters, setFilters] = useState({
    sort: 'asc',
    sortField: null,
  });

  const { sort, sortField } = filters;

  const {
    data: crewList = {},
    loading: isCrewListLoading,
    error: { message: graphQlError = '' } = {},
    fetchMore,
    refetch: reloadQuery,
    networkStatus,
  } =
    useCrewList({
      id: parseInt(projectId),
      first: 20,
      last: null,
      after: null,
      before: null,
      search: searchTerm,
      sort,
      sortField,
      withOffers: null,
      withoutOffers: null,
    }) || {};

  const {
    status: isMeLoading,
    data: { is_support_admin: isSupportAdmin },
  } = meQuery || {};

  // DESTRUCTING CREW LIST
  const { crew: { edges: crewMembers = [], pageInfo = {} } = {} } = crewList;

  const hasNextPage = pageInfo?.hasNextPage;

  const isLoading =
    (isCrewListLoading && networkStatus !== FETCH_MORE_NETWORK_STATUS) ||
    isMeLoading === 'loading';

  // setting filtering
  const setSorting = (direction, fieldName) => {
    setFilters({
      sort: direction,
      sortField: fieldName,
    });
  };

  const exportEmergency = () => {
    pushNotification({
      message: 'Requesting the emergency contact list for download.',
      variant: SnackbarVariants.INFO,
    });
    ajax
      .get(`/v2/projects/${projectId}/export-crew-emergency-contacts`)
      .then(data => {
        const { path, errors = false } = data;
        if (errors) {
          pushNotification({
            message: 'Emergency contacts data not found.',
            variant: SnackbarVariants.INFO,
          });
          return;
        }
        if (path) {
          popNotification();
          pushNotification({
            message: 'Downloading emergency contacts list now.',
            variant: SnackbarVariants.SUCCESS,
          });
          window.location.href = path;
        } else {
          throw new Error(
            `No path received for export emergency contacts for project: ${projectId}`,
          );
        }
      })
      .catch(e => {
        popNotification();
        pushNotification({
          message: `There was an issue downloading the emergency contacts list.
              Please try again or contact customer support if the issue persists.`,
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  const renderHeader = () => {
    return (
      <BoxItem>
        <Header>
          <Header.Title>Crew</Header.Title>
          <QueryRefresher reloadQuery={reloadQuery} />
          <Header.Nav>
            <Search />
            {renderHeaderNav()}
          </Header.Nav>
        </Header>
      </BoxItem>
    );
  };

  const renderHeaderNav = () => {
    if (!isSupportAdmin) {
      return (
        <React.Fragment>
          <ExportCrewButton projectId={projectId} />
          <Button className={classes.button} onClick={exportEmergency}>
            Export Emergency Contacts
          </Button>
          <Button className={classes.button} to="crew/new">
            Add crew member
          </Button>
          <Button className={classes.button} to="crew/import">
            Bulk upload crew
          </Button>
        </React.Fragment>
      );
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loader className={classes.loader} />;
    }

    if (graphQlError) {
      return <FailureAlert />;
    }

    if (crewMembers.length > 0) {
      return renderCrewMembers();
    }

    return <Blankslate>No crew members</Blankslate>;
  };

  const renderCrewMembers = () => {
    return (
      <ExpandableTable>
        <ETableRow>
          <GraphQLTableHeader
            setSorting={setSorting}
            currentDirection={sort}
            fieldName={'name'}
          >
            Employee
          </GraphQLTableHeader>
          <GraphQLTableHeader
            setSorting={setSorting}
            currentDirection={sort}
            fieldName={'email'}
          >
            Email
          </GraphQLTableHeader>
          <GraphQLTableHeader
            setSorting={setSorting}
            currentDirection={sort}
            fieldName={'has_account'}
          >
            Has Account
          </GraphQLTableHeader>
          <GraphQLTableHeader
            setSorting={setSorting}
            currentDirection={sort}
            fieldName={'offers'}
          >
            Offers
          </GraphQLTableHeader>
          <GraphQLTableHeader />
        </ETableRow>

        {crewMembers?.map(user => (
          <CrewMembers
            key={user.node.profile.id}
            params={{ id: user.node.profile.id }}
            refetchCrewList={reloadQuery}
            isSupportAdmin={isSupportAdmin}
            userData={user.node}
          />
        ))}
      </ExpandableTable>
    );
  };

  return (
    <LayoutContent extraPadding>
      <Box>
        {renderHeader()}
        {renderContent()}
      </Box>
      {!isLoading && (
        <span className={classes.crewMembersCount}>
          showing {crewMembers.length || 0} of {pageInfo?.totalCount} crew
          members
        </span>
      )}
      {hasNextPage && !isLoading && (
        <FetchMoreCrewMembers
          searchTerm={searchTerm}
          crewPageInfo={pageInfo}
          fetchMore={fetchMore}
          networkStatus={networkStatus}
        />
      )}
    </LayoutContent>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  pushNotification,
  popNotification,
};

CrewList.queries = {
  me: {
    info: () => {
      return { id: '/me' };
    },
  },
};

export default compose(
  withRouteHelpers,
  withPermissionProtection('can_create_offers'),
  withApi,
  withSnackbarNotification,
  connect(mapStateToProps, mapDispatchToProps),
)(CrewList);
