import FailureAlert from '../../Shared/FailureAlert';
import Field from '../../Base/Field';
import Flexbox from 'flexbox-react';
import Header from '../../Base/Header';
import Preloader from '../../Shared/Preloader';

import React from 'react';
import Relay from '../../../utils/react/Relay.js';
import Select from '../../Base/Select';
import { padNumber } from '../../../utils/format.js';

const DELIVERY_OPTIONS = [
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'SMS',
    value: 'sms',
  },
  {
    label: 'Both',
    value: 'both',
  },
];

const NOTIFICATION_OPTIONS = {
  email_digest: 'Daily digest',
  offers_related: 'Offer updates',
  approval_notification: 'Approval Notification',
};

const TIME_OPTIONS = (() => {
  const LENGTH = 24;
  const options = new Array(LENGTH);
  for (let i = 0; i < LENGTH; ++i) {
    options[i] = {
      label: `${padNumber(i % 12 || 12)}:00 ${i < 12 ? 'A' : 'P'}M`,
      value: `${padNumber(i)}:00`,
    };
  }
  return options;
})();

const flatten = option => {
  return Object.assign(
    {},
    ...(function _flatten(optionData) {
      return [].concat(
        ...Object.keys(optionData).map(key =>
          typeof optionData[key] === 'object' && optionData[key]
            ? _flatten(optionData[key])
            : { [key]: optionData[key] },
        ),
        Object.keys(optionData).map(key => ({ type: key })),
      );
    })(option),
  );
};

const header = () => {
  return (
    <Flexbox flexDirection="row" width="100%" height="40px">
      <Flexbox margin="1em" width="50%">
        <Header quaternary={true} style={{ fontWeight: 'bold' }}>
          Notification
        </Header>
      </Flexbox>
      <Flexbox margin="1em" width="25%">
        <Header quaternary={true} style={{ fontWeight: 'bold' }}>
          Delivery Time
        </Header>
      </Flexbox>
      <Flexbox margin="1em" width="25%">
        <Header quaternary={true} style={{ fontWeight: 'bold' }}>
          Delivery Type
        </Header>
      </Flexbox>
    </Flexbox>
  );
};

export default Relay.createClass({
  getDefaultProps() {
    return {
      onPickerChange: () => null,
    };
  },

  render() {
    const status = this.props.mutator.status;

    if (status === 'loading') {
      return <Preloader />;
    }

    if (status === 'failed') {
      return (
        <Flexbox flexWrap="wrap">
          <FailureAlert />
        </Flexbox>
      );
    }

    return (
      <Flexbox flexWrap="wrap">
        {header()}
        {this._renderOpts()}
      </Flexbox>
    );
  },

  _renderOpts() {
    const options = this.props.mutator.data.notifications;

    return options.map(option => {
      const preference = flatten(option);
      const label = NOTIFICATION_OPTIONS[preference.type];

      return (
        <Flexbox flexDirection="row" width="100%">
          <Flexbox flexDirection="column" margin=".25em 1em" width="50%">
            <span data-test-id={`NotificationsPicker-enabled-${label}`}>
              <Field
                label={label}
                onChange={this._update(preference.type, 'enabled')}
                name="enabled"
                type="checkbox"
                value={preference.enabled}
              />
            </span>
          </Flexbox>
          <Flexbox flexDirection="column" margin=".25em 1em" width="25%">
            <span data-test-id={`NotificationsPicker-deliveryTime-${label}`}>
              {this._renderTimeDropdown(preference)}
            </span>
          </Flexbox>
          <Flexbox flexDirection="column" margin=".25em 1em" width="25%">
            <span data-test-id={`NotificationsPicker-deliveryType-${label}`}>
              {this._renderTypeDropdown(preference)}
            </span>
          </Flexbox>
        </Flexbox>
      );
    });
  },

  _renderTimeDropdown(preference) {
    if (preference.type !== 'email_digest') return null;
    const disabledRow = !preference.enabled;
    return (
      <Select
        clearable={false}
        disabled={disabledRow}
        onChange={this._update(preference.type, 'schedule_time')}
        options={TIME_OPTIONS}
        value={preference.schedule_time}
      />
    );
  },

  _renderTypeDropdown(preference) {
    if (preference.type === 'offers_related') {
      return this._renderOfferTypeDropdown(preference);
    } else if (preference.type === 'approval_notification') {
      return this._renderApprovalTypeDropdown(preference);
    } else if (preference.type === 'email_digest') {
      return this._renderEmailTypeDropdown(preference);
    }
  },

  _renderEmailTypeDropdown(preference) {
    const delivery_type = 'email';
    return (
      <Select
        clearable={false}
        disabled={true}
        onChange={this._update(preference.type, 'delivery_type')}
        options={DELIVERY_OPTIONS}
        value={delivery_type}
      />
    );
  },

  _renderOfferTypeDropdown(preference) {
    const isDisabled = !preference.enabled || this.props.adminEdition;
    return (
      <Select
        clearable={false}
        disabled={isDisabled}
        onChange={this._update(preference.type, 'delivery_type')}
        options={DELIVERY_OPTIONS}
        value={preference.delivery_type}
      />
    );
  },

  _renderApprovalTypeDropdown(preference) {
    const delivery_type = 'email';

    return (
      <Select
        clearable={false}
        disabled={true}
        onChange={this._update(preference.type, 'delivery_type')}
        options={DELIVERY_OPTIONS}
        value={delivery_type}
      />
    );
  },

  _update(option, property) {
    return value => {
      const notifications = this.props.mutator.data.notifications;

      const preferences = notifications.map(notification => {
        if (notification[option] && notification[option][property] !== value) {
          this.props.onPickerChange();
          notification[option][property] = value;
        }
        return notification;
      });

      return this.props.mutator.update('notifications')(preferences);
    };
  },
});
