import gql from 'graphql-tag';
// AccountCodeInput should not be required
export default gql`
  mutation submitOffer(
    $id: Int
    $projectId: Int!
    $crew: [Int!]!
    $offerDate: OfferDateInput!
    $termsOfHire: TermsOfHireInput!
    $termsOfEmployment: TermsOfEmploymentInput
    $termsOfEmploymentV2: TermsOfEmploymentInputV2
    $allowances: AllowancesInput!
    $accountCodes: [AccountCodeInput!]!
    $dealNote: DealNoteInput
    $documents: [String!]!
    $documentFields: [OfferDocumentFieldInput!]!
    $draftId: Int
  ) {
    submitOffer(
      id: $id
      projectId: $projectId
      crew: $crew
      offerDate: $offerDate
      termsOfHire: $termsOfHire
      termsOfEmployment: $termsOfEmployment
      termsOfEmploymentV2: $termsOfEmploymentV2
      allowances: $allowances
      accountCodes: $accountCodes
      dealNote: $dealNote
      documents: $documents
      documentFields: $documentFields
      draftId: $draftId
    )
  }
`;
