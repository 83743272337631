import React from 'react';

import { withStyles } from '@mui/styles';
import { Typography, Grid, Chip } from '@mui/material';
import SingleDocIcon from 'common/icons/SingleDocIcon';
import * as palette from 'common/shared/oldDocumentSignerUI/palette';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    border: `1px solid ${palette.lightGrey}`,
    borderRadius: 3,
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  boldFont: {
    fontWeight: 'bold',
  },
  categoryContainer: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
  },
  categoryChip: {
    ...theme.typography.caption,
    margin: theme.spacing(1) / 4,
    height: 'initial',
  },
});

const DocumentSummary = props => {
  const { document, classes } = props;

  const chips = tags =>
    tags.map(tag => (
      <Chip
        className={classes.categoryChip}
        key={`${tag.name}-${tag.id}`}
        label={tag.name}
      />
    ));

  return (
    <div className={classes.container}>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={1} className={classes.iconContainer}>
          <SingleDocIcon height={40} />
        </Grid>
        <Grid item xs={11}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className={classes.boldFont}
          >
            {document.name}
          </Typography>
          <div className={classes.categoryContainer}>
            Tags: {chips(document.categories)}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(DocumentSummary);
