import React from 'react';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import Flexbox from 'flexbox-react';
import Label from './Separator/Label';

const styles = {
  root: {
    zoom: '1',
    borderBottom: '1px solid #e6e7ea',
    '&:before': { content: '" "', display: 'table' },
    '&:after': { content: '" "', display: 'table', clear: 'both' },
  },
  labeled: {
    overflow: 'hidden',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    border: 'none',
  },
  secondary: { borderStyle: 'dashed' },
  vertical: {
    borderBottom: 'none',
    borderRight: '1px solid #e6e7ea',
  },
  separator: { flex: '1' },
  imageContainer: {
    padding: 20,
  },
  image: {
    height: 50,
    width: 'auto',
  },
};

const Separator = props => {
  const { classes, image, children, secondary, vertical } = props;
  const rootClass = classNames(classes.root, {
    [classes.labeled]: !!children,
    [classes.secondary]: secondary,
    [classes.vertical]: vertical,
  });
  if (image) {
    return (
      <Flexbox alignItems="center">
        <div className={classes.separator} />
        <div className={classes.imageContainer}>
          <img alt="separator" className={classes.image} src={image} />
        </div>
        <div className={classes.separator} />
      </Flexbox>
    );
  }
  return <div className={rootClass}>{children}</div>;
};

Separator.Label = Label;

export default withStyles(styles)(Separator);
