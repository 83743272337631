import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import DocumentModal from 'common/oldJavascripts/components/Shared/DocumentModal';

const Document = ({ data, classes, onCheckBoxClick, preview = false }) => {
  const { name, images, id, checked } = data;

  const [documentModalOpen, setDocumentModalOpen] = useState(false);

  const handleCheckBoxClick = () => {
    if (preview) return;
    onCheckBoxClick(data);
  };

  return (
    <ListItem
      key={id}
      button={!preview}
      onClick={() => handleCheckBoxClick()}
      classes={{
        gutters: classes.listItemGutters,
        container: classes.multiList,
      }}
      data-test-id={`send-document-list-item-${id}`}
    >
      {!preview && (
        <ListItemIcon
          classes={{
            root: classes.listItemCheckbox,
          }}
        >
          <Checkbox
            edge="start"
            checked={checked}
            data-test-id={`send-document-list-checkbox-${id}`}
            classes={{ root: classes.checkboxRoot }}
          />
        </ListItemIcon>
      )}
      <ListItemText
        className={checked && !preview ? classes.listText : ''}
        data-test-id={`document-list-item-${id}`}
        primary={name}
        classes={{ root: classes.itemTextRoot }}
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" onClick={() => setDocumentModalOpen(true)}>
          <VisibilityIcon />
        </IconButton>
      </ListItemSecondaryAction>
      {documentModalOpen && (
        <DocumentModal
          style={{ zIndex: 99999 }}
          pages={images}
          isLoading={false}
          onClose={() => setDocumentModalOpen(false)}
        />
      )}
    </ListItem>
  );
};

export default Document;
