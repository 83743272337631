import gql from 'graphql-tag';

export default gql`
  query projectSearch(
    $to: Int!
    $keyword: String!
    $environment: String
    $nonRelated: Boolean!
  ) {
    projectSearch(
      to: $to
      keyword: $keyword
      environment: $environment
      nonRelated: $nonRelated
    ) {
      id
      name
      cncId
      country {
        code
      }
    }
  }
`;
