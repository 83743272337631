import React from 'react';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';

const styles = {
  root: {
    fontSize: '0.92307692em',
    lineHeight: '1.5',
  },
  important: {
    fontWeight: '700',
    marginTop: '10px',
  },
};

const RowInfoItemData = props => {
  const { classes, children, important } = props;
  const rootClass = classNames(classes.root, {
    [classes.important]: important,
  });
  return <div className={rootClass}>{children}</div>;
};

export default withStyles(styles)(RowInfoItemData);
