import React from 'react';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';

const styles = {
  root: { marginTop: '1px' },
  tab: { background: '#ffffff', padding: '0 30px 30px' },
};

const BoxBody = props => {
  const { classes, tab, children } = props;
  const rootClass = classNames(classes.root, {
    [classes.tab]: tab,
  });
  return <div className={rootClass}>{children}</div>;
};

export default withStyles(styles)(BoxBody);
