import React, { PureComponent } from 'react';
import withApi from 'common/hoc/withApi';
import Loader from 'common/components/Loader';
import ajax from 'common/utilities/ajax';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import SupportingDocumentsList from './SupportingDocumentsList';
import SupportingDocumentPreview from 'common/oldJavascripts/components/Shared/SupportingDocumentForm/SupportingDocumentPreview';
import { parseErrorMessages } from 'common/oldJavascripts/utils/errorMessageParser';

class SupportingDocumentsListContainer extends PureComponent {
  static queries = {
    documents: {
      info: function(params, related) {
        const { offerId } = related['/router/params'];
        return { id: `/v2/offers/${offerId}/documents-details` };
      },
    },
  };

  constructor(props) {
    super(props);
    const docProps = (() => {
      const { documents = {} } = props;
      const { data = {} } = documents;
      const { items = [] } = data;
      return {
        documents: items,
        docsMapper: {},
        isSupportingDocument: true,
      };
    })();
    this.state = docProps;
  }

  getDocsFromProps = (props = this.props) => {
    const { documents = {} } = props;
    const { data = {} } = documents;
    const { items = [] } = data;
    return {
      documents: items,
      docsMapper: {},
      isSupportingDocument: true,
    };
  };

  componentWillReceiveProps(newProps) {
    const { documents: newDocuments = {} } = newProps;
    const { documents: oldDocuments = {} } = this.props;
    const { data: newData = {} } = newDocuments;
    const { data: oldData = {} } = oldDocuments;
    const { items: newItems = [] } = newData;
    const { items: oldItems = [] } = oldData;
    if (newItems.length === oldItems.length) return;
    this.setState(this.getDocsFromProps(newProps), this.propUpdateCallback);
  }

  componentDidMount() {
    this.enableNextCheck();
    this.skipStepCheck();
  }

  skipStepCheck = () => {
    const { skipIfEmpty, documents: documentsQuery } = this.props;
    if (!skipIfEmpty || documentsQuery.status !== 'success') return;
    const { documents } = this.state;
    const supportDocsFound = documents.find(
      ({ supportingDocumentTitles }) => supportingDocumentTitles.length,
    );
    if (supportDocsFound) return;
    return skipIfEmpty();
  };

  propUpdateCallback = () => {
    this.enableNextCheck();
    this.skipStepCheck();
  };

  addNewSupportingDocumentToState = (documentId, supportingDocument) => {
    const {
      supportingDocumentTitleId: supportingDocumentId,
    } = supportingDocument;
    this.setState(({ documents }) => {
      // Copy Docs Array
      const newDocuments = [...documents];
      // Get index of doc we are updating
      const docIndex = newDocuments.findIndex(doc => doc.id === documentId);
      // Get copy of support doc titles array of our doc
      const newSupportingDocumentTitles = [
        ...newDocuments[docIndex].supportingDocumentTitles,
      ];
      // Get index of support doc we are updating
      const supportingDocumentTitlesIndex = newSupportingDocumentTitles.findIndex(
        doc => doc.id === supportingDocumentId,
      );
      // Get copy of uploaded docs and add our supporting document
      const newUploadedSupportDocs = [
        ...newSupportingDocumentTitles[supportingDocumentTitlesIndex]
          .supportingDocuments,
        supportingDocument,
      ];
      // Update our support doc titles with our new uploaded doc array
      newSupportingDocumentTitles[
        supportingDocumentTitlesIndex
      ].supportingDocuments = newUploadedSupportDocs;
      // Update  Doc array with our new supporting doc titles array
      newDocuments[
        docIndex
      ].supportingDocumentTitles = newSupportingDocumentTitles;

      return {
        documents: newDocuments,
      };
    }, this.enableNextCheck);
  };

  removeSupportingDocumentToState = data => {
    const { fileId, supportingDocumentId, documentId } = data;
    this.setState(({ documents }) => {
      // Copy Docs Array
      const newDocuments = [...documents];
      // Get index of doc we are updating
      const docIndex = newDocuments.findIndex(doc => doc.id === documentId);
      // Get copy of support doc titles array of our doc
      const newSupportingDocumentTitles = [
        ...newDocuments[docIndex].supportingDocumentTitles,
      ];
      // Get index of support doc we are updating
      const supportingDocumentTitlesIndex = newSupportingDocumentTitles.findIndex(
        doc => doc.id === supportingDocumentId,
      );
      // Get copy of uploaded docs
      const newUploadedSupportDocs = [
        ...newSupportingDocumentTitles[supportingDocumentTitlesIndex]
          .supportingDocuments,
      ];
      // Get index of uploaded doc we are removing
      const uploadedSupportDocIndex = newUploadedSupportDocs.findIndex(
        doc => doc.id === fileId,
      );
      // Create array without the doc we deleted
      newUploadedSupportDocs.splice(uploadedSupportDocIndex, 1);
      // Update our support doc titles with our new uploaded doc array
      newSupportingDocumentTitles[
        supportingDocumentTitlesIndex
      ].supportingDocuments = newUploadedSupportDocs;
      // Update  Doc array with our new supporting doc titles array
      newDocuments[
        docIndex
      ].supportingDocumentTitles = newSupportingDocumentTitles;

      return {
        documents: newDocuments,
      };
    }, this.enableNextCheck);
  };

  uploadHandler = (formData, data) => {
    const { offerId: routerParamsOfferId } = this.props.routerParams || {};
    const { offerId: paramsOfferId } = this.props.params || {};
    const { documentId, supportingDocumentId } = data;
    const { my_file_id = null } = formData;
    const isMultiPart = my_file_id ? false : true;
    const fileData = my_file_id
      ? { my_file_id }
      : { file: formData.get('file') };
    return new Promise((resolve, reject) => {
      ajax
        .post(
          `/v2/offers/${routerParamsOfferId ||
            paramsOfferId}/documents/${documentId}/supporting-document-titles/${supportingDocumentId}/supporting-documents`,
          { payload: fileData },
          { multipart: isMultiPart },
        )
        .then(res => {
          const supportDoc = res.data[0];
          this.addNewSupportingDocumentToState(documentId, supportDoc);

          let newMap = Object.assign({}, this.state.docsMapper);
          newMap[supportDoc.id] = documentId;
          this.setState({ docsMapper: newMap });

          resolve(res);
        })
        .catch(e =>
          reject({
            message: parseErrorMessages(e),
          }),
        );
    });
  };

  deleteHandler = ({ fileId, supportingDocumentId, documentId }) => {
    const { offerId: routerParamsOfferId } = this.props.routerParams || {};
    const { offerId: paramsOfferId } = this.props.params || {};
    return new Promise((resolve, reject) => {
      ajax
        .delete(
          `/v2/offers/${routerParamsOfferId ||
            paramsOfferId}/documents/${documentId}/supporting-document-titles/${supportingDocumentId}/supporting-documents/${fileId}`,
        )
        .then(res => {
          this.removeSupportingDocumentToState({
            fileId,
            supportingDocumentId,
            documentId,
          });
          resolve(res);
        })
        .catch(e =>
          reject({
            message: parseErrorMessages(e),
          }),
        );
    });
  };

  viewDocument = (id, isSupportingDocument = true) => {
    const findSuppDoc = doc => {
      const { supportingDocumentTitles } = doc;
      return supportingDocumentTitles.find(supDocTitle => {
        const { supportingDocuments } = supDocTitle;
        return supportingDocuments.find(supDoc => supDoc.id === id);
      });
    };
    const findSuppDocId = () => {
      const { documents } = this.state;
      const chosenDoc = documents.find(doc => findSuppDoc(doc));
      return chosenDoc.id;
    };
    const viewDocumentId = isSupportingDocument ? findSuppDocId() : id;
    const viewSupportDocumentId = isSupportingDocument ? id : null;
    const filteredDocs = {
      isSupportingDocument,
      viewDocumentId,
      viewSupportDocumentId,
    };
    this.setState(filteredDocs);
  };

  closeDocumentViewer = () => {
    this.setState({
      viewSupportDocumentId: null,
      viewDocumentId: null,
      isSupportingDocument: true,
    });
  };

  enableNextCheck = () => {
    const { documents } = this.state;
    const { onChangeIsNextEnabled, documents: documentsQuery } = this.props;
    // If the query has not come back yet we dont know if it should be enabled
    if (documentsQuery.status !== 'success') return false;
    // Iterate through the document list
    for (let i = 0; i < documents.length; i += 1) {
      const doc = documents[i];
      // Pull out the suppoorting Document Request list
      const { supportingDocumentTitles = [] } = doc;
      // Iterate through the supporting Document Request list
      for (let j = 0; j < supportingDocumentTitles.length; j += 1) {
        const supportingDoc = supportingDocumentTitles[j];
        // Pull out whether its required or not
        const { required = false, supportingDocuments } = supportingDoc;
        // If its not required then go look at the next supporting document request
        if (!required) continue;
        // If we already filled the requirement then go look at the next supporting document request
        if (supportingDocuments.length > 0) continue;
        // If we have not filled the req then the next button should be disabled]
        return onChangeIsNextEnabled(false);
      }
    }
    // Fall through is it should be enabled
    return onChangeIsNextEnabled(true);
  };

  render() {
    const {
      routerParams = {},
      params = {},
      documents: documentsQuery,
    } = this.props;
    const { offerId: routerParamsOfferId } = routerParams || {};
    const { offerId: paramsOfferId, type } = params || {};
    const isApproval = type === 'approver' || type === 'verifier';
    const {
      documents,
      isSupportingDocument,
      viewDocumentId,
      viewSupportDocumentId,
    } = this.state;
    if (documentsQuery.status === 'loading') return <Loader />;
    if (documentsQuery.status === 'failed')
      return <FailureAlert queryName="the Offer Document Details" />;
    return (
      <div data-test-id="SupportDocumentForm-root">
        <SupportingDocumentsList
          offerId={routerParamsOfferId || paramsOfferId}
          documents={documents}
          uploadHandler={this.uploadHandler}
          editHandler={() => {}} // No edits are possible currently
          deleteHandler={this.deleteHandler}
          showDocumentHandler={this.viewDocument}
          isApproval={isApproval}
        />
        {(viewSupportDocumentId || viewDocumentId) && (
          <SupportingDocumentPreview
            params={{
              offerId: routerParamsOfferId || paramsOfferId,
              supportingDocumentId: viewSupportDocumentId,
              documentId: viewDocumentId,
              isSupportingDocument,
            }}
            onClose={this.closeDocumentViewer}
          />
        )}
      </div>
    );
  }
}

export default withApi(SupportingDocumentsListContainer);
