export default {
  pageHeader: {
    fontSize: '1.5125rem',
    fontWeight: 500,
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    lineHeight: '1.9em',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  appName: {
    fontSize: '1.1125rem',
    fontWeight: 300,
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    lineHeight: '1.5667em',
    color: 'rgba(0, 0, 0, 0.87)',
  },
};
