import React from 'react';
import { Link } from 'react-router-dom';
import domClasses from '../../utils/dom/classes.js';
import { withRouter } from 'react-router';
import qs from 'qs';
import DOMPurify from 'dompurify';

class PaginationPage extends React.Component {
  render() {
    const { location = {} } = this.props;
    const { search, pathname } = DOMPurify.sanitize(location) || {};
    const query = { ...qs.parse(search, { ignoreQueryPrefix: true }) };
    var classes = null;
    var content = null;

    classes = domClasses.set({
      'pagination-page': true,
      'pagination-page--bound': this.props.bound,
      'pagination-page--current': this.props.current,
    });

    if (this.props.current) {
      return (
        <span className={classes} data-test-id="PaginationPage-root-current">
          {this.props.page}
        </span>
      );
    } else {
      if (this.props.page > 1) {
        query.page = DOMPurify.sanitize(this.props.page);
      } else {
        delete query.page;
      }

      if (this.props.bound) {
        content = this.props.children;
      } else {
        content = this.props.page;
      }

      return (
        <span data-test-id={`PaginationPage-root-${content}`}>
          {this.props.onClick ? (
            <span onClick={this._click} className={classes} query={query}>
              {content}
            </span>
          ) : (
            <Link
              to={{
                pathname,
                search: qs.stringify(query, { addQueryPrefix: true }),
              }}
              className={classes}
            >
              {content}
            </Link>
          )}
        </span>
      );
    }
  }

  _click = event => {
    event.preventDefault();

    this.props.onClick(this.props.page);
  };
}

export default withRouter(PaginationPage);
