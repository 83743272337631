import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Typography, Button, Grid } from '@mui/material';
import { Check as CheckIcon, Search as SearchIcon } from '@mui/icons-material';

import useProjectCloneSettings from './hooks/useProjectCloneSettings';

import Loader from 'common/components/Loader';
import CloneDialog from './Dialog';
import { useMutation } from '@apollo/react-hooks';
import { revertProjectClone } from 'common/apollo/mutations';
import { styled } from '@mui/system';

const styles = {
  gridCell: {
    lineHeight: '38px',
  },
  titleH5: {
    display: 'inline',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
  iconCheck: {
    color: '#0000B3',
    marginTop: '4px',
  },
};

const StyledButton = styled(Button)({
  borderRadius: '0px',
  padding: '9px 10px',
  textTransform: 'none',
  fontSize: '1rem',
});

const ProjectClone = props => {
  const { classes, project } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [revertClone] = useMutation(revertProjectClone, {
    variables: { projectId: project.id },
  });

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const doRevert = () => {
    revertClone()
      .then(success => {
        setIsError(false);
        setErrorMessage(null);
        window.location.reload();
      })
      .catch(error => {
        setIsError(true);
        setErrorMessage(error.message);
      });
  };

  const {
    loading,
    data: { cloned, environments: cloneEnvironments = [] } = {},
  } = useProjectCloneSettings(project && project.id);

  if (cloned === undefined || !project || loading) return <Loader />;

  const colWidth = cloned ? 1 : 3;
  const validEnvironments = cloneEnvironments.length > 0;

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={6} className={classes.gridCell}>
          {validEnvironments && (
            <Typography
              className={classes.titleH5}
              variant="h5"
              gutterBottom
              data-test-id="ProjectClone-clonedTitle"
            >
              Project clone:
            </Typography>
          )}
        </Grid>
        <Grid item xs={colWidth} className={classes.gridCell} align="center">
          {!cloned && validEnvironments && (
            <StyledButton
              data-test-id="ProjectClone-goClone"
              color="primary"
              variant="contained"
              onClick={() => setIsDialogOpen(true)}
              startIcon={<SearchIcon />}
            >
              Search
            </StyledButton>
          )}
          {cloned && (
            <CheckIcon
              data-test-id="ProjectClone-alreadyCloned"
              fontSize="large"
              className={classes.iconCheck}
            />
          )}
        </Grid>
        <Grid item s={colWidth} className={classes.gridCell} align="left">
          {cloned && (
            <StyledButton
              color="primary"
              data-test-id="ProjectClone-goClone"
              onClick={doRevert}
              variant="contained"
            >
              Revert Clone
            </StyledButton>
          )}
          {isError && (
            <Grid>
              <Typography
                color="error"
                variant="caption"
                data-test-id="ProjectClone-error"
              >
                {errorMessage}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <CloneDialog
        project={project}
        cloned={cloned}
        cloneEnvironments={cloneEnvironments}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </React.Fragment>
  );
};

export default withStyles(styles)(ProjectClone);
