import React from 'react';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import ProjectTemplateAutoAssignmentRuleSelect from './ProjectTemplateAutoAssignmentRuleSelect';
import useUnions from './hooks/useUnions';

const ProjectTemplateAutoAssignmentRuleUnionSelect = props => {
  const {
    className,
    'data-test-id': dataTestId,
    onChange,
    projectId,
    unionCodes,
  } = props;
  const { data: unions, loading } = useUnions({ projectId });
  const unionOptions = unions?.map(({ code, description }) => ({
    label: description,
    value: code,
  }));
  return (
    <ProjectTemplateAutoAssignmentRuleSelect
      label="Unions"
      isLoading={loading}
      options={unionOptions}
      value={unionCodes}
      onChange={unionCodes =>
        onChange(unions.filter(({ code }) => unionCodes.includes(code)))
      }
      data-test-id={dataTestId}
      className={className}
      multiple={true}
    />
  );
};

export default withRouteHelpers(ProjectTemplateAutoAssignmentRuleUnionSelect);
