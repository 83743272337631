import React from 'react';
import { withStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';

const styles = {
  tootltip: {
    backgroundColor: '#494949 !important',
  },
  tootltipArrow: {
    color: '#494949 !important',
  },
};

const TitleTooltip = ({ title, children, classes }) => {
  return (
    <Tooltip
      title={title}
      arrow
      classes={{
        tooltipPlacementBottom: classes.tootltip,
        arrow: classes.tootltipArrow,
      }}
    >
      {children}
    </Tooltip>
  );
};

export default withStyles(styles)(TitleTooltip);
