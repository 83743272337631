import gql from 'graphql-tag';

// NOTE This only works up to 10 levels of nesting! Indefinitely recursive
// queries are not possible with GraphQL, see:
// https://github.com/graphql/graphql-spec/issues/91
// There may be a way to express this more elegantly, if so please feel free to
// rewrite this code.
export default gql`
  query projectStudioPlusDirectories($projectId: Int!) {
    projectStudioPlusDirectories(projectId: $projectId) {
      id
      name
      children {
        id
        name
        children {
          id
          name
          children {
            id
            name
            children {
              id
              name
              children {
                id
                name
                children {
                  id
                  name
                  children {
                    id
                    name
                    children {
                      id
                      name
                      children {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
