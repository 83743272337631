import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query offerCountry($id: Int!) {
    offer(id: $id) {
      id
      project {
        id
        country {
          id
          code
        }
      }
    }
  }
`;

const useOfferCountry = ({ offerId }) => {
  const { loading, data } = useQuery(QUERY, {
    skip: !offerId,
    variables: { id: parseInt(offerId) },
  });
  return { loading, data: data?.offer.project.country };
};

export default useOfferCountry;
