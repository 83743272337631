import React from 'react';
import { withStyles } from '@mui/styles';
import classNames from 'class-names';

const styles = {
  root: {
    zoom: '1',
    display: [
      '-webkit-box',
      '-moz-box',
      'box',
      '-webkit-flex',
      '-moz-flex',
      '-ms-flex',
      'flex',
    ],
    WebkitBoxOrient: 'horizontal',
    MozBoxOrient: 'horizontal',
    msBoxOrient: 'horizontal',
    boxOrient: 'horizontal',
    WebkitFlexDirection: 'row',
    MozFlexDirection: 'row',
    msFlexDirection: 'row',
    flexDirection: 'row',
    '& :before': {
      content: '" "',
      display: 'table',
    },
    '& :after': {
      content: '" "',
      display: 'table',
      clear: 'both',
    },
  },
  grouped: {
    WebkitBoxFlex: '1',
    MozBoxFlex: '1',
    msBoxFlex: '1',
    boxFlex: '1',
    WebkitFlex: '1',
    MozFlex: '1',
    msFlex: '1',
    flex: '1',
    margin: '0 0 0 4%',
    '& :first-child': { marginLeft: '0' },
  },
  vertical: {
    display: [
      '-webkit-box',
      '-moz-box',
      'box',
      '-webkit-flex',
      '-moz-flex',
      '-ms-flex',
      'flex',
    ],
    WebkitBoxOrient: 'inherit',
    MozBoxOrient: 'inherit',
    msBoxOrient: 'inherit',
    boxOrient: 'inherit',
    WebkitFlexDirection: 'column',
    MozFlexDirection: 'column',
    msFlexDirection: 'column',
    flexDirection: 'column',
  },
};

class Form extends React.Component {
  render() {
    var encType = null;

    if (this.props.multipart) {
      encType = 'multipart/form-data';
    }
    return (
      <form
        onSubmit={this._submit}
        encType={encType}
        action={this.props.action}
        method={this.props.method}
        autoComplete="off"
      >
        {this.props.children}
      </form>
    );
  }

  _submit = event => {
    if (this.props.onSubmit) {
      event.preventDefault();
      this.props.onSubmit();
      return false;
    }
  };
}

export const Fieldset = withStyles(styles)(props => {
  const { classes, children, grouped, vertical } = props;
  const rootClass = classNames(classes.root, {
    [classes.grouped]: grouped,
    [classes.vertical]: vertical,
  });
  return <div className={rootClass}>{children}</div>;
});

Form.Fieldset = Fieldset;

export default Form;
