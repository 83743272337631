const resizeFields = (pageDimensions, field) => {
  const { originalDimensions, updatedDimensions } = pageDimensions;
  const { height: originalHeight, width: originalWidth } = originalDimensions;
  const { height: updatedHeight, width: updatedWidth } = updatedDimensions;
  const newField = {};

  newField.width = (updatedWidth / originalWidth) * field.width;
  newField.height = (updatedHeight / originalHeight) * field.height;

  return newField;
};

export default resizeFields;
