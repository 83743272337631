import React, { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { ratePerHourMask } from '../../helpers/textFieldNumberMasks';
import MaskedInput from 'react-text-mask';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import formatCurrency from 'studio/components/OfferPage/helpers/formatCurrency';

const ERROR_MESSAGE_CNC = 'Rate is below scale. Unable to create offer';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 40,
    marginBottom: 40,
  },
  terms: {
    marginTop: 10,
  },
  innerCell: {
    borderTop: 'none',
  },
  cellTitle: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  radioContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  radioGroupCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderBottom: 'none !important',
    paddingTop: '5px !important',
    paddingBottom: '2px !important',
  },
  dollar: {
    width: '10%',
    fontSize: 14,
    marginTop: 7,
    color: '#696969',
    paddingLeft: 14,
    fontFamily: 'Roboto, Helvetica, Helvetica Neue, san-serif',
  },
  tableSpacing: {
    marginTop: 16,
    marginBottom: 20,
    border: '1px solid #0000FF',
  },
  topSpacing: {
    marginTop: 20,
  },
  hoursCopy: {
    marginTop: 7,
    paddingRight: 12,
    color: '#525252',
    fontSize: 16,
    fontWeight: 'normal',
  },
  errorIcon: {
    color: 'red',
    position: 'relative',
    top: 4,
    right: 8,
  },
  errorLabel: {
    color: 'red',
    fontSize: 14,
    textAlign: 'center',
  },
  warningIcon: {
    color: '#FFA800',
    position: 'relative',
    top: 4,
    right: 8,
  },
  warningLabel: {
    color: '#FFA800',
    fontSize: 14,
    textAlign: 'center',
    maxWidth: '65%',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hoursPerDay: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
  },
  rightCellArea: {
    width: '30%',
    backgroundColor: '#FFFFFF',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  fieldArea: {
    width: '40%',
    backgroundColor: '#FFFFFF!important',
  },
  noTop: {
    borderTop: 'none',
  },
  inputSpacing: {
    marginLeft: '5%',
    marginRight: '10%',
    '& input': {
      textAlign: 'center',
    },
  },
  radio: {
    '&$checked': {
      color: '#0000FF',
    },
  },
  checked: {
    color: '#0000FF',
    '&, & + $label': {
      color: '#0000FF !important',
      fontSize: 16,
    },
  },
  label: {
    color: '#262626',
    fontSize: 16,
  },
  greyedOutArea: {
    width: '30%',
    backgroundColor: '#F4F4F4',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  errorUnderline: {
    width: 196,
    borderBottom: '1px solid red',
  },
}));

const WeeklyInputRadioRow = props => {
  const classes = useStyles();
  const {
    rowTitle,
    rowKey,
    rowKeyStudio,
    rowKeyDistant,
    scaleKeyStudio,
    scaleKeyDistant,
    rateStudio,
    rateDistant,
    isStudioDisabled,
    scaleStudio,
    scaleDistant,
    onChange: upstreamOnChange,
    handleIdleGoldUpdatePayRate,
    scaleRates,
    formData = {},
    formErrors = {},
    isCNCSignatory,
    isReadOnly,
    isRadioRowDisabled,
  } = props;

  const formattedStudioRate = rateStudio
    ? formatCurrency(rateStudio, true)
    : null;
  const formattedDistantRate = rateDistant
    ? formatCurrency(rateDistant, true)
    : null;

  const [studioRate, setStudioRate] = useState(formattedStudioRate);
  const [distantRate, setDistantRate] = useState(formattedDistantRate);
  let payrollScaleValStudio, payrollScaleValDistant;

  const isPayHourly = rowKey === 'payHourly';
  const isPayWeekly = rowKey === 'payWeekly';
  const isPayGold = rowKey === 'payGold';
  const isPayIdle = rowKey === 'payIdle';

  if (isPayWeekly) {
    payrollScaleValStudio = parseFloat(scaleRates?.weeklyRate);
    payrollScaleValDistant = parseFloat(scaleRates?.weeklyRateDistant);
  } else if (isPayHourly) {
    payrollScaleValStudio = parseFloat(scaleRates?.rate);
    payrollScaleValDistant = parseFloat(scaleRates?.rateDistant);
  } else if (isPayGold || isPayIdle) {
    payrollScaleValStudio = parseFloat(scaleRates?.rate);
    payrollScaleValDistant = parseFloat(scaleRates?.rateDistant);
  }

  const isScaleStudio =
    parseFloat(studioRate) === payrollScaleValStudio &&
    payrollScaleValStudio > 0 &&
    scaleStudio === undefined;

  const isScaleDistant =
    parseFloat(distantRate) === payrollScaleValDistant &&
    payrollScaleValDistant > 0 &&
    scaleDistant === undefined;

  const isScaleStudioDisabled = isScaleStudio && !isPayHourly && !isPayWeekly;
  const isScaleDistantDisabled = isScaleDistant && !isPayHourly && !isPayWeekly;
  const isNegotiatedNonHourlyStudio = !isScaleStudio && isPayGold;
  const isDisabledPayGoldDistant = !isScaleDistant && isPayGold;
  const isDisabledPayIdleDistant = !isScaleDistant && isPayIdle;

  const isNegotiatedNonHourlyDistant =
    isDisabledPayIdleDistant || isDisabledPayGoldDistant;

  useEffect(() => {
    setStudioRate(formattedStudioRate);
    setDistantRate(formattedDistantRate);
  }, [formattedDistantRate, formattedStudioRate]);

  const CustomRadio = (
    <Radio
      classes={{
        root: classes.radio,
        checked: classes.checked,
      }}
    />
  );

  const handleAutoScaleUpdate = (key, value, type) => {
    if (type === 'studio') {
      const studioValue =
        payrollScaleValStudio > value ? false : payrollScaleValStudio > value;
      upstreamOnChange({
        payAtScale: studioValue,
        goldAtScale: studioValue,
        payAtScaleDistant: studioValue, // distant should be updated
        idleAtScaleDistant: studioValue,
        goldAtScaleDistant: studioValue,
      });
      if (scaleKeyStudio) {
        upstreamOnChange({
          [scaleKeyStudio]: studioValue,
        });
      }
    } else {
      const distantValue =
        payrollScaleValDistant > value ? false : payrollScaleValDistant > value;
      upstreamOnChange({
        payAtScaleDistant: distantValue,
        idleAtScaleDistant: distantValue,
        goldAtScaleDistant: distantValue,
      });
      if (scaleKeyDistant) {
        upstreamOnChange({
          [scaleKeyDistant]: distantValue,
        });
      }
    }
  };

  const getTypedValue = (ev = {}, formKey) => {
    const { target: { value: rawValue } = {} } = ev;
    let value = rawValue;
    if (rawValue === '' || rawValue === '0') value = '';
    return value;
  };

  const onChangeStudio = ev => {
    const typedValue = getTypedValue(ev);
    let value = typedValue > 0 ? formatCurrency(typedValue) : null;
    setStudioRate(typedValue);
    handleRateUpdate(value, typedValue, 'studio');
  };

  const onChangeDistant = ev => {
    const typedValue = getTypedValue(ev);
    let value = typedValue > 0 ? formatCurrency(typedValue) : null;
    setDistantRate(typedValue);
    handleRateUpdate(value, typedValue, 'distant');
  };

  const handleRateUpdate = (value, typedValue, type) => {
    handleIdleGoldUpdatePayRate(rowKey, value, type);
    handleAutoScaleUpdate(rowKey, typedValue, type);
  };

  const isBelowScaleStudio =
    studioRate < payrollScaleValStudio && studioRate > 0;
  const isBelowScaleDistant =
    distantRate < payrollScaleValDistant && distantRate > 0;
  const isRateBelowScale = isBelowScaleStudio || isBelowScaleDistant;
  const isPayGoldOrIdle = isPayGold || isPayIdle;
  const isScaleStudioBtn = isScaleStudio || scaleStudio;
  const isScaleDistantBtn = isScaleDistant || scaleDistant;

  const handleScaleChange = (ev = {}, radioKey) => {
    const { target: { value: rawValue } = {} } = ev;
    const isStudioRadioKey = radioKey === 'studio';
    const isDistantRadioKey = radioKey === 'distant';

    const scaleTitle = isStudioRadioKey ? scaleKeyStudio : scaleKeyDistant;
    const inputKey = isStudioRadioKey ? rowKeyStudio : rowKeyDistant;
    const isScaleSelected = rawValue === 'yes';

    upstreamOnChange({
      [scaleTitle]: isScaleSelected,
    });

    const payrollScaleValue = isStudioRadioKey
      ? payrollScaleValStudio
      : payrollScaleValDistant;
    const isNegotiatedInputStudio =
      studioRate > payrollScaleValStudio || studioRate < payrollScaleValStudio;
    const isNegotiatedInputDistant =
      distantRate > payrollScaleValDistant ||
      distantRate < payrollScaleValDistant;

    if (
      isScaleSelected &&
      isNegotiatedInputStudio &&
      isStudioRadioKey &&
      isPayHourly
    ) {
      upstreamOnChange({
        [rowKeyStudio]: payrollScaleValue,
        payGoldAt: payrollScaleValue,
      });
    }

    if (
      isScaleSelected &&
      isNegotiatedInputDistant &&
      isDistantRadioKey &&
      isPayHourly
    ) {
      upstreamOnChange({
        [rowKeyDistant]: payrollScaleValue,
        payGoldAtDistant: payrollScaleValue,
        payIdleDaysDistant: payrollScaleValue,
      });
    }

    if (rowKey === 'payHourly') {
      const payGoldTitle = isStudioRadioKey
        ? 'goldAtScale'
        : 'goldAtScaleDistant';
      upstreamOnChange({
        [payGoldTitle]: isScaleSelected,
      });
      if (isDistantRadioKey) {
        upstreamOnChange({
          idleAtScaleDistant: isScaleSelected,
        });
      }
    }
    if (isPayGold && isStudioRadioKey) {
      handleIdleGoldUpdatePayRate(
        rowKey,
        payrollScaleValStudio,
        'studio',
        isScaleSelected,
      );
    }
    if ((isPayGold && isDistantRadioKey) || (isPayIdle && isDistantRadioKey)) {
      handleIdleGoldUpdatePayRate(
        rowKey,
        payrollScaleValDistant,
        'distant',
        isScaleSelected,
      );
    }

    if (isPayGoldOrIdle && isScaleSelected) {
      const scaleInputValue = isStudioRadioKey
        ? payrollScaleValStudio
        : payrollScaleValDistant;

      upstreamOnChange({
        [inputKey]: scaleInputValue,
      });
    }

    if (!isScaleSelected && isPayGoldOrIdle) {
      upstreamOnChange({
        [inputKey]: isStudioRadioKey
          ? formData?.rateStudio
          : formData?.rateDistant,
      });
    }
  };

  const isBelowScaleErrorCNC = isCNCSignatory && isRateBelowScale;
  const isBelowScaleWarningClient = !isCNCSignatory && isRateBelowScale;
  const isStudioWarning = !isCNCSignatory && isBelowScaleStudio;
  const isDistantWarning = !isCNCSignatory && isBelowScaleDistant;
  const isWarning =
    (rowKey !== 'payIdle' && isBelowScaleStudio) || isBelowScaleDistant;
  const warningMessageClient = isBelowScaleWarningClient
    ? 'Rate is below scale'
    : null;
  const warningMessage = warningMessageClient;
  const isBelowScaleError = isBelowScaleErrorCNC && isPayHourly;
  const isHourlyRateError =
    (formErrors?.rate || formErrors?.rateDistant) && isPayHourly;
  const isWeeklyRateError =
    (formErrors?.weeklyRate || formErrors?.weeklyRateDistant) && isPayWeekly;
  const hourlyRateErrorMessage = isHourlyRateError ? formErrors?.rate : null;
  const weeklyRateErrorMessage = isWeeklyRateError
    ? formErrors?.weeklyRate
    : null;
  const isErrorMessage =
    !isPayIdle && (isBelowScaleError || isHourlyRateError || isWeeklyRateError);
  const cncErrorMessage = isBelowScaleError ? ERROR_MESSAGE_CNC : null;
  const errorMessage =
    hourlyRateErrorMessage || cncErrorMessage || weeklyRateErrorMessage;
  const isScalePayGoldIdle = isPayGoldOrIdle && scaleStudio;
  const isDistantScalePayGoldIdle = isPayGoldOrIdle && scaleDistant;

  const onBlurStudio = ev => {
    let blurValue;
    const typedValue = getTypedValue(ev);
    if (studioRate) {
      blurValue = studioRate;
      let dataChange = {
        [rowKeyStudio]: blurValue,
        [rowKeyDistant]: blurValue,
      };
      if (rowKey === 'payHourly') {
        dataChange = {
          ...dataChange,
          weeklyRate: null,
          weeklyRateDistant: null,
        };
      }
      upstreamOnChange(dataChange);
      handleRateUpdate(blurValue, formatCurrency(blurValue, true), 'distant');
    }
    if ((!studioRate && distantRate) || (!typedValue && distantRate)) {
      blurValue = distantRate;
      // copy to studio
      upstreamOnChange({
        [rowKeyStudio]: blurValue,
        weeklyRate: null,
      });
      handleRateUpdate(blurValue, formatCurrency(blurValue, true), 'studio');
    }

    if (studioRate && !distantRate) {
      blurValue = studioRate;
      // copy to distant
      upstreamOnChange({
        [rowKeyDistant]: blurValue,
        payAtScaleDistant: true,
        weeklyRateDistant: null,
      });
      handleRateUpdate(blurValue, formatCurrency(blurValue, true), 'distant');
    }
    setStudioRate(blurValue);
    upstreamOnChange({
      tableErrors: {
        isRateBelowScale: isBelowScaleError,
      },
    });
  };

  const onBlurDistant = ev => {
    let blurValue;
    const typedValue = getTypedValue(ev);

    if (distantRate) {
      blurValue = distantRate;
      let dataChange = {
        [rowKeyDistant]: blurValue,
      };
      if (rowKey === 'payHourly') {
        dataChange = {
          ...dataChange,
          weeklyRateDistant: null,
        };
      }
      upstreamOnChange(dataChange);
    }

    if (distantRate && !studioRate) {
      blurValue = distantRate;
      // copy to studio
      upstreamOnChange({
        [rowKeyStudio]: blurValue,
        weeklyRate: null,
      });
      handleRateUpdate(blurValue, formatCurrency(blurValue, true), 'studio');
    }

    if ((!typedValue && studioRate) || (!distantRate && studioRate)) {
      blurValue = studioRate;
      upstreamOnChange({
        [rowKeyDistant]: blurValue,
        weeklyRateDistant: null,
      });
      handleRateUpdate(blurValue, formatCurrency(blurValue, true), 'distant');
    }
    setDistantRate(blurValue);
    upstreamOnChange({
      tableErrors: {
        isRateBelowScale: isBelowScaleError,
      },
    });
  };

  return (
    <>
      <TableRow>
        <TableCell className={classes.cellTitle}>
          <div className={classes.errorContainer}>
            <Typography
              className={classes.hoursPerDay}
              data-test-id={`inputradiorow-rowTitle-${rowKey}`}
            >
              {rowTitle}
            </Typography>
            {isWarning && (
              <Typography
                color="warning"
                variant="caption"
                className={classes.warningLabel}
                data-test-id={`inputRadioRow-warning-message-${rowKey}`}
              >
                <WarningIcon className={classes.warningIcon} />
                {warningMessage}
              </Typography>
            )}
            {isErrorMessage && !studioRate && (
              <Typography
                color="warning"
                variant="caption"
                className={classes.errorLabel}
                data-test-id={`InputRadioRow-error-message-${rowKey}`}
              >
                <WarningIcon className={classes.errorIcon} />
                {errorMessage}
              </Typography>
            )}
          </div>
        </TableCell>
        <TableCell
          className={
            isStudioDisabled ? classes.greyedOutArea : classes.rightCellArea
          }
        >
          {!isStudioDisabled && (
            <>
              {' '}
              <div className={classes.itemContainer}>
                <Typography className={classes.dollar}>$</Typography>
                <MaskedInput
                  mask={ratePerHourMask}
                  className={classes.inputSpacing}
                  value={studioRate || ''}
                  onChange={onChangeStudio}
                  onBlur={onBlurStudio}
                  error={isStudioWarning}
                  render={(ref, props) => (
                    <TextField
                      inputRef={ref}
                      {...props}
                      variant="standard"
                      disabled={
                        isReadOnly ||
                        isScaleStudioDisabled ||
                        isNegotiatedNonHourlyStudio
                      }
                      InputProps={{
                        className:
                          errorMessage && !studioRate
                            ? classes.errorUnderline
                            : '',
                        inputProps: {
                          'data-test-id': `inputradiorow-studioinput-${rowKey}`,
                        },
                      }}
                    />
                  )}
                />
                <Typography className={classes.hoursCopy}>
                  <span>{isPayWeekly ? '/week' : '/hour'}</span>
                </Typography>
              </div>
              {!isRadioRowDisabled && (
                <div className={classes.radioContainer}>
                  <TableCell className={classes.radioGroupCell}>
                    <RadioGroup
                      className={classes.radioBtns}
                      value={isScaleStudioBtn ? 'yes' : 'no'}
                      onChange={ev => handleScaleChange(ev, 'studio')}
                      row
                    >
                      <FormControlLabel
                        classes={{ label: classes.label }}
                        value="yes"
                        control={CustomRadio}
                        label="Scale Rate"
                        data-test-id={`inputradiorow-studioscale-radio-yes-${rowKey}`}
                        disabled={
                          isReadOnly ||
                          !payrollScaleValStudio ||
                          isScalePayGoldIdle
                        }
                      />
                      <FormControlLabel
                        classes={{ label: classes.label }}
                        value="no"
                        control={CustomRadio}
                        label="Negotiated Rate"
                        data-test-id={`inputradiorow-studionegotiated-radio-no-${rowKey}`}
                        disabled={
                          isReadOnly ||
                          !payrollScaleValStudio ||
                          isScalePayGoldIdle
                        }
                      />
                    </RadioGroup>
                  </TableCell>
                </div>
              )}
            </>
          )}
        </TableCell>
        <TableCell className={classes.rightCellArea}>
          <div className={classes.itemContainer}>
            <Typography className={classes.dollar}>$</Typography>
            <MaskedInput
              mask={ratePerHourMask}
              className={classes.inputSpacing}
              value={distantRate || ''}
              onChange={onChangeDistant}
              onBlur={onBlurDistant}
              error={isDistantWarning}
              render={(ref, props) => (
                <TextField
                  inputRef={ref}
                  {...props}
                  variant="standard"
                  disabled={
                    isReadOnly ||
                    isScaleDistantDisabled ||
                    isNegotiatedNonHourlyDistant
                  }
                  InputProps={{
                    className:
                      errorMessage && !distantRate
                        ? classes.errorUnderline
                        : '',
                    inputProps: {
                      'data-test-id': `inputradiorow-distantinput-${rowKey}`,
                    },
                  }}
                />
              )}
            />
            <Typography className={classes.hoursCopy}>
              <span>{isPayWeekly ? '/week' : '/hour'}</span>
            </Typography>
          </div>
          {!isRadioRowDisabled && (
            <div className={classes.radioContainer}>
              <TableCell className={classes.radioGroupCell}>
                <RadioGroup
                  className={classes.radioBtns}
                  value={isScaleDistantBtn ? 'yes' : 'no'}
                  onChange={ev => handleScaleChange(ev, 'distant')}
                  row
                >
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="yes"
                    control={CustomRadio}
                    label="Scale Rate"
                    data-test-id={`inputradiorow-distantscale-radio-yes-${rowKey}`}
                    disabled={
                      isReadOnly ||
                      !payrollScaleValDistant ||
                      isDistantScalePayGoldIdle
                    }
                  />
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="no"
                    control={CustomRadio}
                    label="Negotiated Rate"
                    data-test-id={`inputradiorow-studionegotiated-radio-no-${rowKey}`}
                    disabled={
                      isReadOnly ||
                      !payrollScaleValDistant ||
                      isDistantScalePayGoldIdle
                    }
                  />
                </RadioGroup>
              </TableCell>
            </div>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default WeeklyInputRadioRow;
