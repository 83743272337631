import React from 'react';

// MATERIAL
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';

// ICONS
import CloseIcon from '@mui/icons-material/Close';

// COMPONENTS
import FiltersSelect from './FiltersSelect';
import FiltersDatePicker from './FiltersDatePicker';

// HELPER
import UsaOnly from 'common/components/UsaOnly';

const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  label: {
    marginBottom: '5px',
    color: '#525252',
  },
  boldText: {
    color: '#262626',
    fontWeight: '500',
  },
  selectContainer: {
    margin: '0 auto',
  },
  select: {
    width: '90%',
    margin: '0 auto 2em auto',
  },
  modalFooter: {
    display: 'flex',
    padding: '15px',
    justifyContent: 'flex-end',
    width: '100%',
  },
  cancel: {
    padding: '8px 12px',
    background: '#FFFFFF',
    color: '#0000FF',
    border: '1px solid #0000FF',
    boxSizing: 'border-box',
    margin: '0px 14px',
    boxShadow:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
  },
  apply: {
    padding: '8px 20px',
    background: '#0000FF',
    color: '#F4F4F4',
    border: '1px solid #0000FF',
    marginRight: '10px',
    boxSizing: 'border-box',
    boxShadow:
      ' 0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
    '&:hover': {
      background: '#1A55FD',
      color: '#FFF',
    },
    '&:disabled': {
      background: '#9A9FAF',
      color: '#C6C6C6',
      border: '1px solid #96A7A6',
      boxShadow: ' 0px 0px 1px rgba(82, 82, 82, 0.4)',
    },
  },
}));

const CancelAndResetButton = styled(Button)({
  padding: '8px 12px',
  background: '#FFFFFF',
  color: '#0000FF',
  border: '1px solid #0000FF',
  boxSizing: 'border-box',
  margin: '0px 14px',
  boxShadow:
    '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
  borderRadius: '2px',
});

const ApplyButton = styled(Button)({
  padding: '8px 20px',
  background: '#0000FF',
  color: '#F4F4F4',
  border: '1px solid #0000FF',
  marginRight: '10px',
  boxSizing: 'border-box',
  boxShadow:
    ' 0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
  borderRadius: '2px',
  '&:hover': {
    background: '#1A55FD',
    color: '#FFF',
  },
  '&:disabled': {
    background: '#9A9FAF',
    color: '#C6C6C6',
    border: '1px solid #96A7A6',
    boxShadow: ' 0px 0px 1px rgba(82, 82, 82, 0.4)',
  },
});

const FiltersModal = ({
  isOpen,
  handleToggle,
  projectId,
  filterValues = [],
  updateFiltersSelection: upstreamFiltersChange,
  updateFiltersDateSelection: upstreamFiltersDateChange,
  submitAllFilters,
  clearFilters,
  setFilterValues,
}) => {
  const classes = useStyles();

  const handleToggleAndSubmit = () => {
    handleToggle();
    submitAllFilters();
  };

  const handleToggleAndClear = () => {
    setFilterValues({});
    clearFilters();
  };

  return (
    <Dialog
      open={isOpen}
      scroll={'paper'}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleToggle();
        }
      }}
      fullWidth
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <MuiDialogTitle>
        <div className={classes.titleContainer}>
          <Typography
            variant="h5"
            className={classes.boldText}
            id="modal-title"
          >
            Filter your selection
          </Typography>
          <CloseIcon onClick={handleToggle} />
        </div>

        <Typography id="modal-description">
          Use at least one filter below to customize your filter selections
        </Typography>
      </MuiDialogTitle>

      <div>
        <MuiDialogActions>
          <CancelAndResetButton
            variant="outlined"
            className={classes.cancel}
            onClick={handleToggle}
          >
            Cancel
          </CancelAndResetButton>

          <CancelAndResetButton
            onClick={handleToggleAndClear}
            variant="outlined"
            className={classes.cancel}
            data-test-id={'filters-reset-button'}
          >
            Reset Filters
          </CancelAndResetButton>

          <ApplyButton
            variant="outlined"
            onClick={handleToggleAndSubmit}
            className={classes.apply}
            data-test-id={'filters-submit-button'}
          >
            Apply
          </ApplyButton>
        </MuiDialogActions>
      </div>

      <MuiDialogContent>
        <div className={classes.selectsContainer}>
          <div className={classes.select}>
            <FiltersDatePicker
              upstreamFiltersDateChange={upstreamFiltersDateChange}
              filterValues={filterValues}
            />
          </div>
        </div>

        <div className={classes.selectsContainer}>
          <div className={classes.select} key={`employee-${projectId}`}>
            <InputLabel className={classes.label}>Employee</InputLabel>
            <FiltersSelect
              upstreamFiltersChange={upstreamFiltersChange}
              projectId={projectId}
              filterValues={filterValues['employees'] || []}
              name={'employees'}
            />
          </div>

          <div className={classes.select} key={`emp-class-${projectId}`}>
            <InputLabel className={classes.label}>
              Employment Classification
            </InputLabel>
            <FiltersSelect
              upstreamFiltersChange={upstreamFiltersChange}
              projectId={projectId}
              filterValues={filterValues['employmentClassification'] || []}
              name={'employmentClassification'}
            />
          </div>

          <div className={classes.select} key={`derpartment-${projectId}`}>
            <InputLabel className={classes.label}>Department</InputLabel>
            <FiltersSelect
              upstreamFiltersChange={upstreamFiltersChange}
              projectId={projectId}
              filterValues={filterValues['departments'] || []}
              name={'departments'}
            />
          </div>

          <div className={classes.select} key={`offer-status-${projectId}`}>
            <InputLabel className={classes.label}>Offer Status</InputLabel>
            <FiltersSelect
              upstreamFiltersChange={upstreamFiltersChange}
              projectId={projectId}
              filterValues={filterValues['status'] || []}
              name={'status'}
            />
          </div>

          <div className={classes.select} key={`job-acct-${projectId}`}>
            <InputLabel className={classes.label}>Job Title</InputLabel>
            <FiltersSelect
              upstreamFiltersChange={upstreamFiltersChange}
              projectId={projectId}
              filterValues={filterValues['jobs'] || []}
              name={'jobs'}
            />
          </div>

          <UsaOnly>
            <div className={classes.select} key={`i9-status-${projectId}`}>
              <InputLabel className={classes.label}>I-9 Status</InputLabel>
              <FiltersSelect
                upstreamFiltersChange={upstreamFiltersChange}
                projectId={projectId}
                filterValues={filterValues['i9Status'] || []}
                name={'i9Status'}
              />
            </div>
          </UsaOnly>

          <div className={classes.select} key={`unions-${projectId}`}>
            <InputLabel className={classes.label}>Union</InputLabel>
            <FiltersSelect
              upstreamFiltersChange={upstreamFiltersChange}
              projectId={projectId}
              filterValues={filterValues['unions'] || []}
              name={'unions'}
            />
          </div>

          <div
            className={classes.select}
            key={`additional-documents-${projectId}`}
          >
            <InputLabel className={classes.label}>
              Additional Documents Pending
            </InputLabel>
            <FiltersSelect
              upstreamFiltersChange={upstreamFiltersChange}
              projectId={projectId}
              filterValues={filterValues['additionalDocuments'] || []}
              name={'additionalDocuments'}
            />
          </div>
        </div>
      </MuiDialogContent>
    </Dialog>
  );
};

export default FiltersModal;
