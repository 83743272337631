import React from 'react';
import { makeStyles, ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { FormControl, Checkbox, FormControlLabel } from '@mui/material';

const theme = createTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#FFA800',
        },
      },
    },
  },
});

const useStyles = makeStyles(theme => ({
  checkboxControl: {
    width: '258px',
  },
}));

const ProjectTemplateAutoAssignmentRuleAllRequiredCheckbox = props => {
  const { onCheckboxChange, value = false } = props;

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <FormControl
        className={classes.checkboxControl}
        data-test-id={'all-required-auto-assignment-rules'}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              onChange={({ target: { checked } = {} } = {}) =>
                onCheckboxChange(checked)
              }
              color="secondary"
            />
          }
          label="All Required"
        />
      </FormControl>
    </ThemeProvider>
  );
};

export default ProjectTemplateAutoAssignmentRuleAllRequiredCheckbox;
