import React from 'react';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import { QueryBuilder, Alarm, Warning, CheckCircle } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import moment from 'moment';

const styles = {
  blue: {
    color: '#0000FF',
  },
  orange: {
    color: '#FFBF00',
  },
  warning: {
    color: '#ED1C23',
  },
  success: {
    color: '#00DF68',
  },
  icon: {
    display: 'block',
    margin: '0 auto',
  },
  label: {
    margin: '0 auto',
    textAlign: 'center',
    fontWeight: '700 !important',
    fontSize: '12px !important',
  },
  align: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const getINineStatusInfo = props => {
  const {
    props: {
      start_date: startDate = null,
      status,
      i9_document: i9Document = true,
      keepI9 = false,
      keep_i9 = false,
      i9_verified: i9Verified = false,
      i9_created_date: i9CreatedDate = null,
      i9Correction,
    },
    classes,
  } = props;
  const { success, blue, orange, warning } = classes || {};

  const isStatusVisible =
    status === 'sent' ||
    status === 'submitted' ||
    status.includes('onboarding') ||
    ((keepI9 || keep_i9) && status !== 'approved');
  if (!isStatusVisible) return null;

  if (!i9Document) {
    return {
      label: 'No I9',
      style: success,
    };
  }

  //Difference in number of days
  const consideringDate = i9CreatedDate < startDate ? startDate : i9CreatedDate;
  const days = moment()
    .startOf('day')
    .diff(consideringDate, 'days', false);

  if (i9Verified) {
    return {
      icon: CheckCircle,
      label: 'VERIFIED',
      style: success,
    };
  } else if (i9Correction) {
    return {
      icon: '',
      label: 'PENDING EMPLOYEE',
      style: orange,
    };
  } else if (days < -1) {
    return {
      icon: QueryBuilder,
      label: 'WAITING',
      style: blue,
    };
  } else if (days === -1) {
    return {
      icon: Alarm,
      label: '1 DAY BEFORE START',
      style: orange,
    };
  } else if (days >= 0 && days < 3) {
    let dayCount = 3 - days;
    return {
      icon: Alarm,
      label: `${dayCount}-DAY${dayCount > 1 ? 'S' : ''} LEFT`,
      style: orange,
    };
  } else if (days === 3) {
    return {
      icon: Warning,
      label: 'DUE TODAY',
      style: warning,
    };
  } else if (days >= 4) {
    let daysPast = days - 3;
    return {
      label: `${daysPast}-DAY${daysPast === 1 ? '' : 'S'} PAST DUE`,
      icon: Warning,
      style: warning,
    };
  } else {
    return null;
  }
};

const OfferI9Status = props => {
  const {
    props: { id },
    classes,
  } = props;
  const { label, style, icon: Icon } = getINineStatusInfo(props) || {};

  if (!label) return null;

  return (
    <div className={classes.align}>
      {Icon && (
        <Icon
          data-test-id="Shared-StatusIcon-icon"
          className={classNames(style, classes.icon)}
        />
      )}
      <Typography
        className={`${classes.label} ${style}`}
        variant="caption"
        data-test-id={`Shared-INineReviewStatus-label-${id}`}
      >
        {label}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(OfferI9Status);
