import React from 'react';
import Relay from '../../../utils/react/Relay.js';
import Box from '../../Base/Box';
import Button from '../../Base/Button';
import Form from '../../Base/Form';
import Header from '../../Base/Header';
import T from '../../../utils/i18n';
import AlertsMixin from '../../mixins/Alerts.js';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Link from '../../Base/Link';
import NavBar from '../../Base/NavBar';
import Separator from '../../Base/Separator';
import history from 'common/constants/config/history';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

export default Relay.createClass({
  mixins: [AlertsMixin],

  getInitialState: function() {
    return { btnDisable: false };
  },

  statics: {
    mutators: {
      password: {
        info() {
          return {
            id: '/account/password',
            create: true,
          };
        },
        stale() {
          return ['/me'];
        },
      },
    },
    queries: {
      me: {
        info() {
          return {
            id: '/me',
          };
        },
      },
    },
  },

  render() {
    return (
      <LayoutContent>
        <Box minimal={true} overflow_initial={true}>
          <BoxItem compact={true}>
            <Header>
              <Header.Title>{T('account.password.title')}</Header.Title>
            </Header>
          </BoxItem>
          <Separator />

          <Form
            action="/account/password"
            method="put"
            onSubmit={this._submitForm}
          >
            <BoxItem>
              To reset your password click 'Submit' and you'll receive an email
              from noreply@castandcrew.com. The email will contain a secure link
              to reset your password.
              <NavBar>
                <NavBar.Item>
                  <Button>{T('form.actions.submit')}</Button>
                </NavBar.Item>
                {this._renderCancelLink()}
              </NavBar>
            </BoxItem>
          </Form>
        </Box>
      </LayoutContent>
    );
  },

  _renderCancelLink() {
    if (
      !this.props.me.data.is_update_password_required &&
      !this.props.me.data.is_password_expired
    ) {
      return (
        <NavBar.Item>
          <Link onClick={this._cancel}>{T('form.actions.cancel')}</Link>{' '}
          {T('form.actions.or')}
        </NavBar.Item>
      );
    }
  },

  _cancel() {
    history.push('/account/profile');
  },
  _submitForm() {
    var self = this;
    var passwordMutator = self.props.password;

    passwordMutator.save('edit', { json: true }, function() {
      history.push('/account/profile');
      self.alertSuccess(
        "We've sent you an email with a link to reset your password.",
      );
    });
  },
});
