import { gql } from 'apollo-boost';
import { useQuery } from 'react-apollo';

const QUERY = gql`
  query crewGroupFields($documentId: String!) {
    crewMemberGroupFieldValues(documentId: $documentId) {
      id
      fieldId
      fieldGroupId
      name
      displayName
    }
  }
`;

const useCrewGroupFileds = documentId => {
  const { error, loading, data, errors } = useQuery(QUERY, {
    variables: {
      documentId: documentId,
    },
    fetchPolicy: 'no-cache',
    skip: !documentId,
  });
  return {
    error,
    errors,
    loading,
    data: data?.crewMemberGroupFieldValues || [],
  };
};

export default useCrewGroupFileds;
