import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

const useStyles = makeStyles(theme => ({
  dialogActions: {
    flexDirection: 'column',
  },
  documents: {
    display: 'inline',
    fontSize: '0.8em',
    fontWeight: 'bold',
    '& li': {
      float: 'left',
      display: 'inline',
      paddingRight: '10px',
    },
    '& li:before': {
      content: '"• "',
    },
  },
  cancelButton: {
    marginLeft: '0 !important',
    background: '#0000FF !important',
    color: '#FFFFFF !important',
    '&:hover': {
      background: '#0000FF !important',
    },
  },
  cancelText: {
    textTransform: 'none',
    fontStyle: 'italic',
    textDecoration: 'underline',
  },
  confirmButton: {
    background: '#536881',
    color: theme.palette.primary.contrastText,
  },
  confirmText: {
    textTransform: 'none',
  },
}));

const MobileGroupedAcknowledgmentDialog = ({
  documentNames,
  notes,
  onClose,
  onConfirm,
  open,
  title,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      open={open}
      data-test-id="MobileGroupedAcknowledgmentDialog-root"
    >
      <DialogTitle onClose={onClose}>{title}</DialogTitle>

      <DialogContent>
        <Typography>{notes}</Typography>
        <ul className={classes.documents}>
          {documentNames.map(name => (
            <li key={name}>{name}</li>
          ))}
        </ul>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.confirmButton}
          color="primary"
          fullWidth
          onClick={() => {
            onClose();
            onConfirm();
          }}
          variant="contained"
        >
          <span className={classes.confirmText}>
            I acknowledge receipt of the aforementioned documents
          </span>
        </Button>
        <br />
        <Button className={classes.cancelButton} fullWidth onClick={onClose}>
          <span className={classes.cancelText}>
            On second thought, let me review those again...
          </span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MobileGroupedAcknowledgmentDialog;
