import React from 'react';
import Chip from './Chip';

const Settings = ({ setValue, formField }) => {
  const { allow_complete, required } = formField?.data || {};

  const availableValue = allow_complete && !required;
  const requiredValue = required;
  const noValue = !allow_complete && !required;

  return (
    <div>
      <Chip value={noValue} label={'N/A'} setValue={() => setValue('N/A')} />
      <Chip
        value={availableValue}
        label={'Available'}
        setValue={() => setValue('Available')}
      />
      <Chip
        value={requiredValue}
        label={'Required'}
        setValue={() => setValue('Required')}
      />
    </div>
  );
};

export default Settings;
