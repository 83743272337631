import React from 'react';
import { Dialog, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MOVE from 'mobile/images/move.png';
import PINCH from 'mobile/images/pinch.png';
import TAP from 'mobile/images/tap.png';
import SWIPE from 'mobile/images/swipe.png';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '40px',
    padding: '0 40px',
  },
  paper: {
    backgroundColor: 'transparent !important',
  },
  title: {
    color: '#FFFFFF',
    fontSize: '36px',
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: '40px',
  },
  gotItBtn: {
    width: '255px',
    boxShadow: 'inset 0 -2 0 #cccccc',
    borderRadius: 4,
    backgroundColor: '#ffffff !important',
    color: '#646464 !important',
    fontSize: '18px !important',
    fontWeight: '500 !important',
    '&:hover': {
      backgroundColor: '#1A55FD',
      color: '#FFFFFF',
    },
  },
  instruction: {
    color: '#ffffff',
    fontSize: '18px',
    fontWeight: 400,
    marginTop: '8px',
  },
  itemRow: {
    marginBottom: '40px',
    padding: '0 10px 0 6px',
  },
  btnContainer: {
    textAlign: 'center',
    marginTop: '60px',
  },
  tap: {
    marginLeft: '15px',
  },
}));

const FormTutorialOverlay = ({ overlayTutorialHandler }) => {
  const classes = useStyles();
  return (
    <Dialog
      fullScreen
      open
      classes={{ paper: classes.paper }}
      BackdropProps={{ style: { backgroundColor: '#000000', opacity: 0.85 } }}
      data-test-id="FormTutorialOverlay-Dialog"
    >
      <div className={classes.root}>
        <Typography className={classes.title}>Navigating Forms</Typography>
        <Grid container className={classes.itemRow}>
          <Grid item xs={3}>
            <img src={MOVE} alt="move form with hand" />
          </Grid>
          <Grid item xs={9}>
            <Typography className={classes.instruction}>
              Navigate by moving the form around your screen
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.itemRow}>
          <Grid item xs={3}>
            <img src={PINCH} alt="pinch form to zoom" />
          </Grid>
          <Grid item xs={9}>
            <Typography className={classes.instruction}>
              Pinch to zoom
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.itemRow}>
          <Grid item xs={3}>
            <img className={classes.tap} src={TAP} alt="tap form" />
          </Grid>
          <Grid item xs={9}>
            <Typography className={classes.instruction}>
              Tap to type or select
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.itemRow}>
          <Grid item xs={3}>
            <img className={classes.tap} src={SWIPE} alt="SWIPE form" />
          </Grid>
          <Grid item xs={9}>
            <Typography className={classes.instruction}>
              Swipe to view other pages in document
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.btnContainer}>
          <Button
            className={classes.gotItBtn}
            data-test-id="FormTutorialOverlay-gotItBtn"
            onClick={overlayTutorialHandler}
          >
            Got it!
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default FormTutorialOverlay;
