import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import debounce from 'lodash.debounce';

import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import ProjectList from './ProjectList';

const styles = {
  input: {
    width: '100%',
    color: '#0000FF',
  },
  root: {
    marginTop: '5px !important',
  },
};

const updateProjectSearch = debounce((update, value) => update(value), 500);

const ProjectSearch = props => {
  const {
    classes,
    project: { id: to },
    onSelect = () => {},
    search = '',
    environments = [],
    noEnvironmentSelection = false,
    nonRelated = false,
    showOnlyCA = false,
  } = props;

  const defaultEnv = noEnvironmentSelection ? '' : environments[0].id;

  const [keyword, setKeyword] = useState(search);
  const [inputVal, setInputVal] = useState(search);
  const [environment, setEnvironment] = useState(defaultEnv);

  const onChange = ({ target: { value } = {} } = {}) => {
    setInputVal(value);
    updateProjectSearch(setKeyword, value);
  };

  const environmentOptions = environments.map(env => (
    <MenuItem value={env.id}>{env.label}</MenuItem>
  ));

  return (
    <>
      <Grid container spacing={2} classes={{ root: classes.root }}>
        <Grid item xs={noEnvironmentSelection ? 12 : 9}>
          <TextField
            data-test-id="ProjectSearch-input"
            id="project-search"
            className={classes.input}
            label="Search for a project&hellip;"
            variant="outlined"
            value={inputVal}
            color="primary"
            onChange={onChange}
            autoFocus
          />
        </Grid>
        {!noEnvironmentSelection && (
          <Grid item xs={3}>
            <FormControl>
              <InputLabel id="project-clone-environment">
                Environment:
              </InputLabel>
              <Select
                labelId="project-clone-environment"
                value={environment}
                onChange={({ target: { value } }) => setEnvironment(value)}
                disabled={environments.length < 2}
                variant="outlined"
              >
                {environmentOptions}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <ProjectList
            data-test-id="ProjectSearch-list"
            to={to}
            keyword={keyword}
            environment={environment}
            onSelect={onSelect}
            nonRelated={nonRelated}
            showOnlyCA={showOnlyCA}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default compose(withStyles(styles))(ProjectSearch);
