import React from 'react';
import { withStyles } from '@mui/styles';
import { SnackbarProvider } from 'notistack';
import SnackbarCloseButton from './SnackbarCloseButton';
import * as colors from './theme/colors';

const styles = theme => ({
  success: {
    backgroundColor: colors.GREEN,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    backgroundColor: colors.ORANGE,
  },
  info: {
    backgroundColor: colors.BLUE,
  },
});

const CncSnackbarProvider = ({ classes, children }) => (
  <SnackbarProvider
    action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    autoHideDuration={5000}
    classes={{
      variantSuccess: classes.success,
      variantError: classes.error,
      variantWarning: classes.warning,
      variantInfo: classes.info,
    }}
  >
    {children}
  </SnackbarProvider>
);

export default withStyles(styles)(CncSnackbarProvider);
