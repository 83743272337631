const createPlaceholder = (apiMask, wildCard = '.') => {
  if (!apiMask) return '';

  const placeHolder = `${apiMask}`.split('').map(maskDigit => {
    if (maskDigit === wildCard) return 'X';
    return maskDigit;
  });
  return placeHolder.join('');
};

export default createPlaceholder;
