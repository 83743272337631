import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const QUERY = gql`
  query downloadOfferExternalDocument($offerId: Int!, $documentId: Int!) {
    offer(id: $offerId) {
      externalDocuments(id: $documentId) {
        id
        name
        downloadUrl
      }
    }
  }
`;

const useDownloadOfferExternalDocument = (offerId, documentId) => {
  const { error, loading, data } = useQuery(QUERY, {
    skip: !parseInt(offerId, 10) || !parseInt(documentId, 10),
    variables: { documentId, offerId },
    fetchPolicy: 'no-cache',
  });

  const doc = (data?.offer?.externalDocuments || [])[0];
  return { error, loading, data: doc };
};

export default useDownloadOfferExternalDocument;
