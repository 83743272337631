import { useQuery } from 'react-apollo';
import { gql } from 'graphql-tag';

const QUERY = gql(`
  query offerDownloadW9Document($id: Int!) {
    offer(id: $id) {
      loanoutCorporation {
        w9 {
          id
          downloadUrl
        }
      }
    }
  }
`);

const useDownloadW9Document = (offerId, skip) => {
  const { loading, data, error } = useQuery(QUERY, {
    skip: !parseInt(offerId, 10) || skip,
    variables: { id: parseInt(offerId, 10) },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  return { loading, error, data: data?.offer?.loanoutCorporation?.w9 };
};

export default useDownloadW9Document;
