import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay';
import DocumentReplacement from 'admin/components/DocumentReplacement';
import Worker from './DocumentWorker';
import TemplateDocumentModal from 'common/oldJavascripts/components/Shared/TemplateDocumentModal';
import { parseErrorMessages } from 'common/oldJavascripts/utils/errorMessageParser';

export default Relay.createClass({
  getInitialState() {
    return {
      uploading: false,
      processing: false,
      intervalId: null,
      error: null,
      viewModalOpen: false,
    };
  },

  fetchStatus(id) {
    const worker = Worker.createWorker();
    worker
      .get(id)
      .then(response => {
        // Do other stuff with response... if needed.
        // Setting both uploading and processing as false should unblock the
        // button in the form to continue on the mapping review.
        if (response.status === 'processed') {
          clearInterval(this.state.intervalId);
          this.setState({ processing: false, intervalId: null });
        }
      })
      .catch(err => {
        this.setState({ error: err });
      });
  },

  onUploadSuccess(updatedData) {
    const { id } = updatedData;
    // Here we start a process to start requesting the data,
    // to do a req every x sec until status === 'processed'
    const intervalId = setInterval(() => {
      this.fetchStatus(id);
    }, 2000);
    this.setState({ uploading: false, processing: true, intervalId });
    return Promise.resolve(updatedData);
  },

  errorHandler(response) {
    const messages = parseErrorMessages(response);
    return Promise.reject({ message: messages });
  },

  replaceHandler(formData) {
    const worker = Worker.createWorker();
    const {
      oldDocument: { id },
    } = this.props;

    const data = {
      templateId: id,
      file: formData.get('file'),
    };
    // We block the button because its uploading
    this.setState({ uploading: true });
    // We are sending a promise (worker.replace is a promise)
    // with a `.then()` already attached, which would be the onUploadSuccess
    return worker
      .replace(
        data,
        { multipart: true },
        this.errorHandler,
        this.onUploadSuccess,
      )
      .finally(() => this.setState({ uploading: false }));
  },

  onEditSuccess(response) {
    return Promise.resolve(response);
  },

  editHandler(data) {
    const worker = Worker.createWorker();
    const {
      oldDocument: { id },
    } = this.props;
    const update = {
      templateId: id,
      file: {
        categories: data.tags,
        name: data.name,
      },
    };
    return worker.edit(
      update,
      { json: true },
      this.errorHandler,
      this.onEditSuccess,
    );
  },

  openViewerHandler() {
    this.setState({ viewModalOpen: true });
  },

  closeViewerHandler() {
    this.setState({ viewModalOpen: false });
  },

  renderViewer() {
    const { viewModalOpen } = this.state;
    const {
      oldDocument: { id },
    } = this.props;
    if (viewModalOpen) {
      return (
        <TemplateDocumentModal
          params={{
            adminTemplate: true,
            path: `/workflow/templates/${id}/images`,
          }}
          onClose={this.closeViewerHandler}
        />
      );
    }
  },

  render() {
    const { oldDocument, open, closeHandler, mappingHandler } = this.props;
    const { uploading, processing } = this.state;
    return (
      <div>
        {this.renderViewer()}
        <DocumentReplacement
          open={open}
          oldDocument={oldDocument}
          uploadHandler={this.replaceHandler}
          editHandler={this.editHandler}
          deleteHandler={() => {}} // No deletes are currently supported
          viewHandler={this.openViewerHandler}
          reviewMappingHandler={() => mappingHandler(oldDocument.id)}
          closeHandler={closeHandler}
          processing={uploading || processing}
          deleteable={false}
        />
      </div>
    );
  },
});
