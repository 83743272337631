import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import AccountCodeMaskTooltip from './AccountCodeMaskTooltip';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const styles = makeStyles(theme => ({
  container: {
    marginBottom: 20,
  },
  label: {
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  maskInput: {
    borderRadius: '3px',
    border: '1px solid #e6e7ea',
    margin: 0,
    padding: '10px 3px 10px 2px',
    textAlign: 'center',
    width: '100%',
    lineHeight: '14px',
  },
  validationError: {
    color: '#FFCC00',
  },
  normalInputContainer: {
    marginBottom: '15px',
  },
  maskInputError: {
    color: 'red',
  },
  extraMaskInput: {
    marginTop: 11,
  },
}));

const createMaskValidation = accountMask => {
  if (!accountMask) return null;
  const mixed = /[A-Za-z0-9_.]/;
  const digitsOnly = /\d/;
  const lettersOnly = /[A-Za-z]/;
  const lettersAndDigitsOnly = /[A-Za-z0-9]/;

  return Array.from(accountMask).map(maskChar => {
    if (maskChar === '.') {
      return mixed;
    } else if (maskChar === '-') {
      return '-';
    } else if (maskChar === '#') {
      return digitsOnly;
    } else if (maskChar === 'A') {
      return lettersOnly;
    } else if (maskChar === 'X') {
      return lettersAndDigitsOnly;
    }
    return mixed;
  });
};

const AccountCodeMaskedInput = props => {
  const {
    id: testID,
    accountMask,
    value,
    label,
    onChange,
    code = '',
    lineItemKey = '',
    canEditAccountCodes = true,
    hasContainerClass = true,
    customMaskInputClass = false,
    isRequired = false,
    disabled = false,
    isLabelVisible = false,
  } = props;
  const classes = styles();
  const [maskedValue, setMaskedValue] = useState(value);
  const [maskInputError, setMaskInputError] = useState(false);
  const [isMaskRequired, setIsMaskRequired] = useState(false);
  const isDisabled = !canEditAccountCodes || !accountMask || disabled;
  const testDepartmentID = testID || 'none';
  const handleMaskedInput = ev => {
    const { value: maskInput = '' } = ev.target;

    if (accountMask.length === maskInput.length) {
      setMaskInputError(false);
      setMaskedValue(maskInput);
      setIsMaskRequired(false);
    } else {
      if (isRequired) {
        setIsMaskRequired(true);
        return false;
      }
      setMaskInputError(true);
      setMaskedValue(maskInput);
    }
    onChange(ev);
  };

  const extraMaskInput = customMaskInputClass ? classes.extraMaskInput : null;

  if (!accountMask) {
    return (
      <div className={classes.normalInputContainer}>
        {isLabelVisible && <label>Default Detail/Sub</label>}
        <input
          value={value}
          disabled={!canEditAccountCodes || disabled}
          className={classes.maskInput}
          maxLength={26}
          onChange={onChange}
        />
      </div>
    );
  }

  const maskValidationRegex = createMaskValidation(accountMask);

  return (
    <div className={hasContainerClass ? classes.container : null}>
      {label && <label>{label}</label>}
      <MaskedInput
        id="code"
        name="code"
        data-test-id={`AccountCodeMaskedInput-${testDepartmentID}${code}${lineItemKey}`}
        className={[classes.maskInput, extraMaskInput].join(' ')}
        mask={maskValidationRegex}
        guide={false}
        showMask
        onChange={ev => handleMaskedInput(ev)}
        value={maskedValue}
        disabled={isDisabled}
      />
      {!isDisabled && <AccountCodeMaskTooltip accountMask={accountMask} />}
      {maskInputError && (
        <Typography
          className={classes.validationError}
          data-test-id={`AccountCodeMasked-detailSub-error-${testDepartmentID}`}
        >
          The account code you entered is incomplete.
        </Typography>
      )}
      {isMaskRequired && (
        <Typography className={classes.maskInputError}>
          account code is required.
        </Typography>
      )}
    </div>
  );
};

export default AccountCodeMaskedInput;
