import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

const TITLE_MIN = 3;
const TITLE_MAX = 40;

const styles = {
  separator: {
    display: 'flex',
    flexGrow: 1,
  },
  strong: {
    fontWeight: 'bolder',
  },
  chip: {
    background: '#0000ff !important',
    color: '#ffffff !important',
    fontWeight: 400,
    fontSize: '14px !important',
    letterSpacing: '0.16px',
    lineHeight: '18px',
    marginTop: '6px !important',
    marginRight: '12px !important',
    '&:hover': {
      background: '#0000ff',
    },
    '& .MuiChip-icon': { color: '#ffffff !important' },
  },
  icon: { background: '#ffffff !important' },
  selectedChip: {
    background: 'linear-gradient(180deg, #1A55FD 0%, #000080 100%) !important',
    border: '1px solid #BECFFE !important',
    '&:hover': {
      background:
        'linear-gradient(180deg, #1A55FD 0%, #000080 100%) !important',
    },
  },
};

const Modal = props => {
  const { open, setOpen, classes, draft, loading, errors, onSave } = props;
  const { id: draftId, title: draftTitle, isTemplate } = draft || {};

  const [title, setTitle] = useState('');
  const titleIsValid = title?.length >= TITLE_MIN && title?.length <= TITLE_MAX;
  const canSave = !loading && (titleIsValid || draftId);
  const [type, setType] = useState('draft');

  const flags = useFeatureFlags();
  const isOfferTemplatesFlagOn = flags.includes('OfferTemplates');
  useEffect(() => {
    const offerType = isTemplate ? 'template' : 'draft';
    setType(offerType);
  }, [isTemplate]);

  useEffect(() => {
    setTitle('');
  }, [open]);

  const handleType = type => {
    setType(type);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      data-test-id="SaveOfferDraft-dialog"
      fullWidth
    >
      <DialogTitle>Save Offer</DialogTitle>
      <DialogContent>
        {draftId && !isTemplate && (
          <div>
            <p>The draft was modified.</p>
            <p>
              Would you like to update{' '}
              <span className={classes.strong}>{draftTitle}</span>?
            </p>
          </div>
        )}
        {(!draftId || isTemplate) && (
          <TextField
            variant="standard"
            label={`Title (${TITLE_MIN}-${TITLE_MAX} chars)`}
            value={title}
            onChange={e => setTitle(e.target.value)}
            onKeyUp={e => e.key === 'Enter' && onSave(title)}
            disabled={loading}
            error={!!errors['title']}
            helperText={errors['title']?.join(' ')}
            data-test-id="SaveOfferDraft-title-input"
            inputProps={{
              minlength: TITLE_MIN,
              maxlength: TITLE_MAX,
            }}
            fullWidth
            autoFocus
          />
        )}
        {!draftId && isOfferTemplatesFlagOn && (
          <span>
            <Chip
              icon={
                type === 'draft' ? <DoneIcon classes={classes.icon} /> : null
              }
              label="Draft"
              clickable
              className={classnames(
                classes.chip,
                type === 'draft' ? classes.selectedChip : '',
              )}
              onClick={() => handleType('draft')}
            />
            <Chip
              icon={
                type === 'template' ? <DoneIcon classes={classes.icon} /> : null
              }
              label="Template"
              clickable
              className={classnames(
                classes.chip,
                type === 'template' ? classes.selectedChip : '',
              )}
              onClick={() => handleType('template')}
            />
          </span>
        )}
      </DialogContent>
      <DialogActions>
        <div className={classes.separator} />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setOpen(false)}
          disabled={loading}
          data-test-id="SaveOfferDraft-button-cancel"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSave(title, type)}
          disabled={!canSave}
          startIcon={loading && <CircularProgress size={16} color="primary" />}
          data-test-id="SaveOfferDraft-button-save"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(Modal);
