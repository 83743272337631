import React from 'react';
import domClasses from '../../utils/dom/classes.js';

class Modal extends React.Component {
  render() {
    return (
      <div className="modal-overlay" onClick={this.props.onClick}>
        <div
          className={this._getClasses()}
          data-test-id={this.props['data-test-id']}
        >
          {this.props.children}
        </div>
      </div>
    );
  }

  _getClassSize() {
    return this.props.size ? `modal-content--size-${this.props.size}` : null;
  }

  _getClasses() {
    return domClasses.set(
      {
        'modal-content': true,
        'modal-content--noPadding': this.props.noPadding,
      },
      this._getClassSize(),
    );
  }
}

export default Modal;
