import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Button, ThemeProvider } from '@mui/material';
import { LibraryAdd as LibraryAddIcon } from '@mui/icons-material';
import DocumentUploaderModal from './DocumentUploaderModal';
import { documentSignerTheme } from 'common/shared/oldDocumentSignerUI/theme';

const styles = theme => ({
  button: {
    marginLeft: theme.spacing(1),
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
    color: '#FFFFFF',
    height: 15,
    width: 15,
  },
});

const DocumentUploader = props => {
  const [open, setOpen] = useState(false);
  const { uploadHandler, editHandler, deleteHandler, classes } = props;

  return (
    <ThemeProvider theme={documentSignerTheme}>
      <div>
        <Button
          onClick={() => setOpen(true)}
          data-test-id="upload-document-button"
          className={classes.button}
          color="primary"
          variant="contained"
        >
          <LibraryAddIcon className={classes.buttonIcon} />
          Upload Documents
        </Button>
        {open && (
          <DocumentUploaderModal
            open={open}
            uploadHandler={uploadHandler}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
            editableFiles
            fileTypes="application/pdf"
            closeModalHandler={() => setOpen(false)}
          />
        )}
      </div>
    </ThemeProvider>
  );
};

export default withStyles(styles)(DocumentUploader);
