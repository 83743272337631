import React from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

import dateTime from './dateTime';

const styles = {
  header: {
    background: '#ccc',
    color: '#fff',
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
  row: {
    borderBottom: '1px solid #ccc',
  },
};

const DealMemoErrors = props => {
  const { classes, errors = [] } = props;
  const hasErrors = (errors || []).length > 0;

  if (!hasErrors) return '-';

  return (
    <Grid container spacing={2} data-test-id="OfferDealMemo-ErrorsTable">
      <Grid item xs={3} className={classes.header}>
        Failed At
      </Grid>
      <Grid item xs={9} className={classes.header}>
        Error Message
      </Grid>
      {errors.map(({ failed_at, error }, i) => (
        <React.Fragment key={`deal-memo-errors-${i}`}>
          <Grid item xs={3} className={classes.row}>
            {dateTime(failed_at)}
          </Grid>
          <Grid item xs={9} className={classes.row}>
            {error}
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default withStyles(styles)(DealMemoErrors);
