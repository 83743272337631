import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import AllowancesRow from 'common/oldJavascripts/components/Shared/AllowancesRow';

import OfferStatus from 'common/oldJavascripts/components/Shared/OfferStatus';
import RateCell from './Offers/RateCell';
import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import format from 'common/oldJavascripts/utils/format.js';
import ToolTipBox from 'common/oldJavascripts/components/Shared/ToolTipBox';
import Badges from 'common/components/Badges';

import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCellTitle from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCellTitle';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import nonUnionTermsOfEmployment from 'studio/components/ManageOffers/utilities/nonUnionTermsOfEmployment/nonUnionTermsOfEmployment';

const styles = () => ({
  levelName: {
    textAlign: 'center',
    fontSize: '1em',
    fontWeight: 600,
    marginTop: 8,
  },
  offerStatusCell: {
    overflow: 'unset',
  },
  badgesContainer: {
    marginTop: 6,
  },
});

const PendingLevel = ({ classes, levelName = '' }) => (
  <Typography className={classes.levelName}>{levelName}</Typography>
);
const StyledPendingLevel = withStyles(styles)(PendingLevel);

export default withStyles(styles)(
  Relay.createClass({
    render() {
      const {
        approval: offer = {},
        classes = {},
        'data-test-id': dataTestId,
      } = this.props;
      const {
        projectUser: {
          profile: { email, firstName, lastName, middleName } = {},
        } = {},
        termsOfEmployment: terms = {},
        termsOfHire: {
          department: { name: departmentName } = {},
          union: { description: unionDescription, isNonUnion } = {},
        } = {},
        jobTitle,
        id,
        accountCode,
        startDate,
        endDate,
        status,
        sendDate,
        pending_levels: pendingLevels = [],
        badges = [],
        allowances,
      } = offer;
      const { name: pendingLevelName = '' } = pendingLevels[0] || {};
      let pendingDocumentNames = [];
      if (pendingLevels.length) {
        const pendingLevelMinPosition = pendingLevels.reduce((prev, curr) =>
          prev.position < curr.position ? prev : curr,
        ).position;
        pendingDocumentNames = pendingLevels
          .filter(level => level.position === pendingLevelMinPosition)
          .map(lvl => lvl.document_name);
      }
      const documentNameTooltipContents = pendingDocumentNames.map(name => (
        <Typography>{name}</Typography>
      ));

      const _allowances = {
        box_rental_amount: allowances?.boxRentalAllowance.amount,
        mobile_phone_amount: allowances?.mobilePhoneAllowance.amount,
        car_allowance_amount: allowances?.carAllowance.amount,
        per_diem_allowance_amount: allowances?.perDiemAllowance.amount,
        housing_allowance_amount: allowances?.housingAllowance.amount,
        computer_rental_amount: allowances?.computerRentalAllowance.amount,
      };

      const fullName = format.fullName(firstName, middleName, lastName);

      const isSAGUnion =
        offer?.termsOfEmploymentV2?.__typename === 'TermsOfEmploymentUnionSag';

      const rateCellInfo = isNonUnion
        ? nonUnionTermsOfEmployment(offer)
        : isSAGUnion
        ? {
            id,
            terms_of_employment: {
              sagRate: offer?.termsOfEmploymentV2?.rate,
            },
          }
        : {
            id,
            terms_of_employment: {
              rate_per_hour_studio:
                terms?.studioRatePerHour || terms?.ratePerHour,
              rate_per_hour_location: terms?.locationRatePerHour,
              rate_per_day_studio: terms?.dailyRate,
              rate_per_week_studio:
                terms?.studioRatePerWeek || terms?.weeklyRate,
              rate_per_week_location: terms?.locationRatePerWeek,
            },
            scaleRate: offer?.scaleRate,
            locationScaleRate: offer?.locationScaleRate,
            union_code: offer?.termsOfHire?.union?.code,
          };

      return (
        <ExpandableTableRow
          key={id}
          onClick={this._toggleDetails}
          data-test-id={dataTestId}
        >
          <ExpandableTableCell>
            <ExpandableTableCellTitle>{fullName}</ExpandableTableCellTitle>
            {email}
            <div
              className={classes.badgesContainer}
              data-test-id={`ProcessedRow-badges-${offer.id}`}
            >
              <Badges badges={badges} id={offer.id} />
            </div>
          </ExpandableTableCell>
          <ExpandableTableCell>
            <ExpandableTableCellTitle>
              {departmentName}
            </ExpandableTableCellTitle>
          </ExpandableTableCell>
          <ExpandableTableCell>
            {jobTitle}
            <br />
            {accountCode}
          </ExpandableTableCell>
          <ExpandableTableCell>{unionDescription}</ExpandableTableCell>
          <ExpandableTableCell>{format.date(startDate)}</ExpandableTableCell>
          {this._renderEndDateCell(endDate)}
          <RateCell offer={rateCellInfo} />
          <ExpandableTableCell expanded={this.props.expanded} align="center">
            <AllowancesRow.Icon offer={_allowances} />
          </ExpandableTableCell>
          <ExpandableTableCell
            align="center"
            className={classes.offerStatusCell}
          >
            <OfferStatus
              status={status}
              startDate={startDate}
              sendDate={sendDate}
            />
            <ToolTipBox
              disableInteractive
              align="top"
              tooltipNode={documentNameTooltipContents}
              bold={true}
            >
              <StyledPendingLevel levelName={pendingLevelName} />
            </ToolTipBox>
          </ExpandableTableCell>
        </ExpandableTableRow>
      );
    },

    _renderEndDateCell(endDate) {
      if (this.props.endDateAllowed) {
        return (
          <ExpandableTableCell>{format.date(endDate)}</ExpandableTableCell>
        );
      }
    },

    _toggleDetails() {
      this.props.onToggle(this.props.params.id);
    },
  }),
);
