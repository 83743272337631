import { useQuery } from 'react-apollo';
import { gql } from 'graphql-tag';

const QUERY = gql(`
  query offerDocumentsDetails($id: Int!) {
    offer(id: $id) {
      id
      projectUser {
        id
        profile {
          id
          fullName
        }
      }
      documentDetails {
        id
        name
        canDownload
        documentId
      }
      loanoutCorporation {
        w9 {
          id
          name
        }
      }
      externalDocuments {
        id
        name
      }
    }
  }
`);

const useOfferDocumentsDetails = offerId => {
  const { loading, data, error } = useQuery(QUERY, {
    skip: !parseInt(offerId, 10),
    variables: { id: parseInt(offerId, 10) },
    notifyOnNetworkStatusChange: true,
  });

  return { loading, error, data: data?.offer };
};

export default useOfferDocumentsDetails;
