import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';
import merge from 'lodash.merge';
import classNames from 'classnames';

const RETRY_LIMIT = 3;

const styles = () => ({
  root: {
    margin: 'auto',
  },
  loadingContainer: {
    zIndex: 3,
    height: '100%',
    width: '100%',
    display: 'grid',
    placeContent: 'center',
  },
  loader: {
    color: 'black',
  },
});

class DocumentImage extends Component {
  state = {
    currentImageUrl: null,
    imageCache: {},
  };

  componentDidMount() {
    this.getCurrentDocumentImages();
  }

  componentDidUpdate(prevProps) {
    const { doc: { id: prevDocId } = {} } = prevProps;
    const { doc: { id: currDocId } = {} } = this.props;
    const docHasChanged = currDocId !== prevDocId;
    if (docHasChanged) {
      this.getCurrentDocumentImages();
      return;
    }

    const { page: prevPage } = prevProps;
    const { page: currPage } = this.props;
    const pageHasChanged = currPage !== prevPage;
    if (pageHasChanged) this.getCurrentImage();
  }

  preloadImageFromUrl = url =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onerror = reject;
      img.onload = () => resolve(url);
      img.src = url;
      // Attach image object to component instance to stop it getting garbage collected
      this[`___${url}`] = img;
    });

  loadUpstreamImage = (documentId, page, retry = 0) => {
    const { getDocumentImageUrls, throwFatalError } = this.props;
    return getDocumentImageUrls({ documentId, page })
      .then(([url]) => this.preloadImageFromUrl(url))
      .catch(err => {
        if (retry > RETRY_LIMIT) {
          throwFatalError('Unable to load Document Images', err);
          return;
        }
        return this.loadUpstreamImage(documentId, page, retry + 1);
      });
  };

  getPageImage = page => {
    const {
      doc: { id: docId },
    } = this.props;
    const { imageCache } = this.state;

    // Get image url from cache if there...
    const docImageCache = imageCache[docId];
    if (docImageCache) {
      const cachedPromiseOfUrl = docImageCache[page];
      if (cachedPromiseOfUrl) return cachedPromiseOfUrl;
    }

    // ...otherwise load image from upstream
    const promiseOfUrl = this.loadUpstreamImage(docId, page);
    this.setState(({ imageCache }) => {
      const newImageCache = merge({}, imageCache, {
        [docId]: { [page]: promiseOfUrl },
      });
      return { imageCache: newImageCache };
    });
    return promiseOfUrl;
  };

  getCurrentImage = () => {
    this.setState({ currentImageUrl: null });
    const { doc, onLoad, page } = this.props;
    if (!doc) return;

    return this.getPageImage(page).then(url =>
      this.setState({ currentImageUrl: url }, onLoad),
    );
  };

  getRestOfDocImages = () => {
    const { doc, page } = this.props;
    const pages = doc.images
      .filter(img => img.page !== page)
      .map(({ page }) => ({
        page,
        isRequired: doc.fields.some(
          field => field.isRequired && field.page === page,
        ),
      }));

    // Fetch pages with required fields before pages without
    const priorityPromises = pages
      .filter(({ isRequired }) => isRequired)
      .map(({ page }) => this.getPageImage(page));

    Promise.all(priorityPromises).then(() =>
      pages
        .filter(({ isRequired }) => !isRequired)
        .forEach(({ page }) => this.getPageImage(page)),
    );
  };

  getCurrentDocumentImages = () =>
    this.getCurrentImage().then(this.getRestOfDocImages);

  // For some reason the preloaded images are expiring, we're not sure why.
  // So if the img complains of a broken link, reload images from source.
  reloadCurrentDocumentImages = () => {
    const {
      doc: { id: docId },
    } = this.props;
    this.setState(
      ({ imageCache }) => ({
        imageCache: { ...imageCache, [docId]: undefined },
      }),
      this.getCurrentDocumentImages,
    );
  };

  render() {
    const { classes, doc, zoom, className } = this.props;
    const { currentImageUrl } = this.state;
    let { height, width, templateName } = doc || {};
    height *= zoom;
    width *= zoom;

    if (!currentImageUrl) {
      return (
        <div
          className={classes.loadingContainer}
          data-test-id="DocumentImage-loading"
        >
          <CircularProgress classes={{ root: classes.loader }} />
        </div>
      );
    }

    return (
      <div
        className={classNames(classes.root, className)}
        style={{ height, width }}
      >
        <img
          draggable={false}
          src={currentImageUrl}
          alt={templateName}
          style={{ height, width }}
          data-test-id="DocumentImage-img"
          onError={this.reloadCurrentDocumentImages}
        />
      </div>
    );
  }
}

export default withStyles(styles)(DocumentImage);
