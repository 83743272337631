import React from 'react';
import { withStyles } from '@mui/styles';
import classNames from 'class-names';
import humanize from '../../utils/humanize';
import FormErrors from './FormErrors';
import FormLabel from './FormLabel';
import Text from './Text';
import DOMPurify from 'dompurify';

const styles = {
  root: { zoom: '1', marginBottom: '20px' },
  compact: { marginBottom: '0' },
  disabled: { cursor: 'not-allowed' },
  grouped: {
    WebkitBoxFlex: '1',
    MozBoxFlex: '1',
    msBoxFlex: '1',
    boxFlex: '1',
    WebkitFlex: '1',
    MozFlex: '1',
    msFlex: '1',
    flex: '1',
    margin: '0 0 20px 4%',
    '& :first-child': { marginLeft: '0' },
  },
  inline: { display: 'inline-block', margin: '0' },
  invalid: { marginBottom: '0' },
};

// requires props to be passed down for conditional styles
class FormField extends React.Component {
  render() {
    const {
      classes,
      compact,
      disabled,
      grouped,
      inline,
      className,
      'data-test-id': dataTestId,
    } = this.props;
    const rootClass = classNames(
      classes.root,
      {
        [classes.compact]: compact,
        [classes.disabled]: disabled,
        [classes.grouped]: grouped,
        [classes.inline]: inline,
        [classes.invalid]: this._isInvalid(),
      },
      className,
    );
    return (
      <div className={rootClass} data-test-id={dataTestId}>
        {this._renderLabel()}
        {this._renderField()}
        {this._renderHelpText()}
        {this._renderErrors()}
      </div>
    );
  }

  _getInput() {
    return React.Children.only(this.props.children);
  }

  _getLabelText() {
    if (typeof this.props.label === 'string') {
      return this.props.label;
    }
    return humanize(this._getInput().props.name);
  }

  _isInvalid() {
    const errors = this.props.errors;
    return errors && errors.length > 0;
  }

  _renderErrors() {
    if (this._isInvalid()) {
      return (
        <FormErrors
          errors={this.props.errors}
          data-test-id={`FormErrors-${this._getInput().props.id}`}
        />
      );
    }
  }

  _renderField() {
    const input = this._getInput();
    return React.cloneElement(input, {
      disabled: this.props.disabled,
      errors: this.props.errors,
    });
  }

  _renderHelpText() {
    const helpText = DOMPurify.sanitize(this.props.helpText);
    if (helpText) {
      return (
        <Text comment={true} paragraph={true}>
          {helpText}
        </Text>
      );
    }
  }

  _renderLabel() {
    const { classes = {} } = this.props;
    // We accept a prop of classes from upstream
    // Of which we pull the label key and apply it here
    // This class is not defined in our styles
    if (this.props.label !== false) {
      return (
        <FormLabel
          htmlFor={this._getInput().props.id}
          inline={this.props.inlineLabel}
          required={this.props.required}
          text={this._getLabelText()}
          className={classes.label}
        />
      );
    }
  }
}

FormField.defaultProps = {
  label: true,
};

export default withStyles(styles)(FormField);
