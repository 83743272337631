import React from 'react';
import { withStyles } from '@mui/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import Checkbox from 'common/components/Checkbox';

const styles = {
  section: {
    marginTop: 12,
  },
  sectionLabel: {
    fontSize: 16,
    fontWeight: 600,
  },
};

const ItemSection = props => {
  const {
    classes,
    title,
    items = [],
    selection = {},
    onChange = () => {},
  } = props;

  const isChecked = Object.entries(selection)
    .filter(e => items.includes(e[0]))
    .every(e => e[1]);

  const setChecked = checked => onChange(items, checked);

  const checkbox = (
    <Checkbox
      name={`section-check-${title}`}
      checked={isChecked}
      onChange={({ target: { checked } }) => setChecked(checked)}
    />
  );

  const label = (
    <Typography className={classes.sectionLabel} variant="h5">
      {title}
    </Typography>
  );

  return (
    <FormControlLabel
      className={classes.section}
      label={label}
      control={checkbox}
    />
  );
};

export default withStyles(styles)(ItemSection);
