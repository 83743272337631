import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query offerFilters($filterType: String!, $projectId: Int!) {
    offerListFilters(filterType: $filterType, projectId: $projectId) {
      id
      label
    }
  }
`;

const useOfferFilters = (filterType, projectId) => {
  const { error, loading, data } = useQuery(QUERY, {
    variables: {
      filterType,
      projectId: parseInt(projectId, 10),
    },
    fetchPolicy: 'no-cache',
  });
  return { error, loading, data: data?.offerListFilters };
};

export default useOfferFilters;
