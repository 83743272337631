import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import withRouteHelpers from '../../../../../common/hoc/withRouteHelpers';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { withStyles } from '@mui/styles';

const styles = theme => ({
  label: {
    gridRow: 1,
    gridColumn: 1,
    fontSize: '15px',
  },
  fieldSet: {
    gridRow: 2,
    gridColumn: '1 / -1',
    marginTop: '-7px',
  },
  radioGroup: {
    display: 'grid',
    gridAutoFlow: 'column',
  },
  formControl: {
    marginLeft: '0',
  },
  error: {
    display: 'block',
    marginBottom: '10px',
    fontSize: '12px',
  },
  description: {
    fontSize: 16,
    marginLeft: '24%',
    marginBottom: 10,
  },
});

const getContract = (contracts, negotiableValue) => {
  if (Array.isArray(contracts)) {
    const htgContract = contracts.find(contract => contract.type === 'htg');
    if (htgContract) {
      return (
        htgContract.contracts.find(
          contract => contract.negotiableContract === negotiableValue,
        ) || {}
      );
    }
  }
  return {};
};

const NegotiatedContractTerms = props => {
  const {
    onChange: upstreamOnChange,
    negotiatedContract = {},
    contracts = [],
    formErrors = {},
    classes = {},
  } = props;
  const error = formErrors.negotiatedContractId;
  const { isNegotiated } = negotiatedContract;
  const [contractDescription, setContractDescription] = useState();

  const contract = getContract(contracts, true);

  const handleContractSelection = e => {
    const negotiableValue = e.target.value === 'true';
    const contract = getContract(contracts, negotiableValue);
    setContractDescription(contract?.description);
    upstreamOnChange({
      negotiatedContract: {
        contractId: contract?.code,
        isNegotiated: negotiableValue,
      },
    });
  };

  if (!contract?.code) {
    return null;
  }
  const isCastedNegotiatedVal = String(isNegotiated);

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1">
        Do you wish to use the specialty contract terms for this offer?
        <FormControl component="fieldset" classes={{ root: classes.fieldSet }}>
          <RadioGroup
            aria-label="Negotiate Rate"
            name="negotiableContract"
            className={classes.radioGroup}
            value={isCastedNegotiatedVal}
            onChange={e => handleContractSelection(e)}
          >
            <FormControlLabel
              value={'true'}
              control={
                <Radio
                  inputProps={{
                    'data-test-id': 'negotiableContract-radio-Yes',
                  }}
                />
              }
              label="Yes"
              classes={{ root: classes.formControl }}
            />
            <FormControlLabel
              value={'false'}
              control={
                <Radio
                  inputProps={{
                    'data-test-id': 'negotiableContract-radio-No',
                  }}
                />
              }
              label="No"
              classes={{ root: classes.formControl }}
            />
          </RadioGroup>
        </FormControl>
        {contractDescription && (
          <Typography className={classes.description}>
            Contract: {contractDescription}{' '}
          </Typography>
        )}
        {error && (
          <Typography color="error" variant="caption" className={classes.error}>
            {error}
          </Typography>
        )}
      </Typography>
    </div>
  );
};

export default withRouteHelpers(withStyles(styles)(NegotiatedContractTerms));
