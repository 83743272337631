import React from 'react';
import Loader from '../Base/Loader';

export default () => <Loader />;

// export default class extends React.Component {
//   render() {
//     return <Loader />;
//   }
// }
