import React, { useState, Suspense } from 'react';
import { compose } from 'redux';

//Components
import RemoveDocumentModal from 'common/components/OfferDocumentsList/RemoveDocumentModal';
import DocumentInteractionController from 'studio/oldJavascripts/components/Shared/DocumentInteractionController';
import AdditionalDocumentsRejectModal from '../AdditionalDocumentsRejectionModal.js';
import OfferRejectModal from 'common/oldJavascripts/components/Shared/OfferRejectModal';
import Loader from 'common/components/Loader';
import Notification from 'studio/components/OfferPage/OfferDrafts/Notification';
// API
import ajax from 'common/utilities/ajax.js';
// Graphql
import useOffers from 'studio/components/OfferPage/hooks/useOffers';
// HOC
import withApi from 'common/hoc/withApi';
import withPermissionProtection from 'common/hoc/withPermissionProtection';
// Utilities
import processDocumentRequests from 'common/utilities/processDocumentRequests';
import history from 'common/constants/config/history';

const REJECT_MODAL_CONTENT = {
  closeLink: 'Close',
  note_label: 'Note',
  note_placeholder:
    'Please add a short explanation for declining the approval of the offer.' +
    ' For example, "the allowance amount is over budget."',
  paragraph:
    'You have chosen to decline the selected offer.' +
    ' It is required that you give us a short explanation for this action.',
  submitBtn: 'Submit',
  title: 'Add a Note',
};

const DocumentSigning = props => {
  const { routerParams, project: projectQuery = {} } = props;
  const { projectId, offerId } = routerParams;

  const { data: offerData = [], loading: isOfferLoading } = useOffers(
    projectId,
    [parseInt(offerId)],
  );
  const { data: projectData = {} } = projectQuery || {};
  const { nodes: offers = [] } = offerData;
  const offerInfo = offers.length > 0 ? offers[0] : [];
  const {
    projectUser: { profile: { firstName, lastName } = {} } = {},
    startDate,
  } = offerInfo || {};

  const modifiedOfferInfo = {
    ...offerInfo,
    first_name: firstName,
    last_name: lastName,
    start_date: startDate,
  };
  const [
    documentsSuccessfullySubmitted,
    setDocumentsSuccessfullySubmitted,
  ] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [removeDocumentModalOpen, setRemoveDocumentModalOpen] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [reloadDocumentDetails, setReloadDocumentDetails] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedOfferHasI9, setSelectedOfferHasI9] = useState(false);
  const [offerSelectedToReject, setOfferSelectedToReject] = useState(null);

  const { privileges } = projectData || {};
  const canApproveOffers = privileges?.includes('can_approve_offers');
  const rejectStatus = props?.offerReject?.status;

  const handleSave = (docs, isAutosave) => {
    // Sometimes autosave is triggered after the offer is closed in which case
    // we should just ignore it.
    if (isAutosave && !offerId) return;

    const documentRequests = Object.keys(docs)
      .filter(d => docs[d].ready && !documentsSuccessfullySubmitted.includes(d))
      .map(d => {
        const fields = Object.keys(docs[d].fields).map(fieldId => ({
          id: docs[d].fields[fieldId].id,
          value: docs[d].fields[fieldId].value,
        }));
        const { crew_acknowledged, id } = docs[d];
        const postUrl = `/v2/offers/${offerId}/documents/${id}/save-draft`;
        const promise = ajax.post(
          postUrl,
          { fields, crew_acknowledged },
          { json: true },
        );
        return { promise, documentId: id };
      });

    // If this is an autosave don't reject errors - we don't want to pop up
    // mysterious error messages while the user is typing.
    return processDocumentRequests(documentRequests, !isAutosave);
  };

  const handleOfferAccept = (docs, offerId, documents) => {
    const documentRequests = Object.keys(docs)
      .filter(d => !documentsSuccessfullySubmitted.includes(d))
      .map(documentId => {
        const fields = Object.keys(docs[documentId].fields).map(fieldId => ({
          id: docs[documentId].fields[fieldId].id,
          value: docs[documentId].fields[fieldId].value,
        }));
        const postUrl = `/v2/offers/${offerId}/documents/${documentId}`;
        const promise = ajax
          .post(postUrl, { fields }, { json: true })
          .then(() =>
            setDocumentsSuccessfullySubmitted(documentsSuccessfullySubmitted =>
              documentsSuccessfullySubmitted.concat(documentId),
            ),
          );
        return { promise, documentId };
      });

    return processDocumentRequests(documentRequests).then(() => {
      const {
        invalidateByPatterns,
        routerParams: { projectId },
      } = props;
      invalidateByPatterns([`/projects/${projectId}/offers`]);
      history.push({
        pathname: `/projects/${projectId}/approvals`,
      });
    });
  };

  const _onRejectConfirm = (comment, keepI9 = false) => {
    const {
      offerReject = {},
      routerParams: { projectId } = {},
      invalidateByPatterns,
    } = props;
    if (offerSelectedToReject === null) return;

    offerReject.updateAndSave(
      {
        ids: [offerSelectedToReject],
        comment: comment,
        keep_i9: keepI9,
      },
      'reject',
      { json: true },
      () => {
        invalidateByPatterns([`/projects/${projectId}/offers`]);
        history.push({
          pathname: `/projects/${projectId}/approvals`,
        });
        _onRejectModalClose();
        setSelectedOfferHasI9(false);
        setOfferSelectedToReject(null);
      },
    );
  };

  const _onRejectModalClose = () => {
    setShowRejectModal(false);
  };

  const handleOfferReject = (offerId, hasI9) => {
    setShowRejectModal(true);
    setOfferSelectedToReject(offerId);
    setSelectedOfferHasI9(hasI9);
  };

  const handleRemoveDocument = documentId => {
    setRemoveDocumentModalOpen(true);
    setDocumentId(documentId);
  };

  const handleRemoveDocumentModal = value => {
    setRemoveDocumentModalOpen(false);

    if (value) {
      setReloadDocumentDetails(true);
      setReloadDocumentDetails(false);
      setSnackOpen(true);
      setMessage('Document removed successfully');
    }
  };

  const handleEndDocumentInteraction = () => {
    history.push({
      pathname: `/projects/${projectId}/approvals`,
    });
  };

  const handleSnackOpen = () => {
    setSnackOpen(false);
  };

  return (
    <Suspense fallback={<Loader />}>
      {removeDocumentModalOpen && (
        <RemoveDocumentModal
          onClose={value => handleRemoveDocumentModal(value)}
          documentId={documentId}
        />
      )}
      {isOfferLoading ? (
        <Loader />
      ) : (
        <DocumentInteractionController
          buttonText={{
            accept: 'Approve Offer',
            reject: 'Reject Offer',
          }}
          errors={[]}
          offer={modifiedOfferInfo}
          offerId={offerId}
          type="approver"
          handleOfferAccept={handleOfferAccept}
          canApproveOffers={canApproveOffers}
          reloadDocumentDetails={reloadDocumentDetails}
          handleOfferReject={() =>
            handleOfferReject(offerId, offerInfo?.i9Document)
          }
          handleRemoveDocument={documentId => handleRemoveDocument(documentId)}
          handleEndDocumentInteraction={handleEndDocumentInteraction}
          handleSave={handleSave}
        />
      )}
      {offerInfo?.previousStatus === 'approved' ? (
        <AdditionalDocumentsRejectModal
          onClose={_onRejectModalClose}
          open={showRejectModal}
        />
      ) : (
        <OfferRejectModal
          content={REJECT_MODAL_CONTENT}
          onClose={_onRejectModalClose}
          onSubmit={_onRejectConfirm}
          fromApprovals={true}
          isRejectProcessing={rejectStatus}
          hasI9={selectedOfferHasI9}
          visible={showRejectModal}
          data-test-id="Approvals-offerRejectModal"
        />
      )}
      <Notification
        message={message}
        open={snackOpen}
        autoHide={2000}
        setOpen={handleSnackOpen}
        severity={'success'}
      />
    </Suspense>
  );
};

DocumentSigning.queries = {
  project: {
    info: function(_, related) {
      const params = related['/router/params'];

      return {
        id: '/projects/' + params.projectId,
      };
    },
  },
};

DocumentSigning.mutators = {
  offerReject: {
    info(params, related) {
      const routerParams = related['/router/params'];
      return {
        create: true,
        id: `/v2/projects/${routerParams.projectId}/approvals`,
      };
    },
  },
};

export default compose(
  withPermissionProtection('can_approve_offers'),
  withApi,
)(DocumentSigning);
