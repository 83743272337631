import React, { useState, useEffect } from 'react';

import { List, DialogContent } from '@mui/material';
import Document from './Document';
import ActionButtons from './ActionButtons';

import helperFunctions from './helpers';

// templates
const Documents = ({ onClose, templates, classes, onNext, data }) => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (data?.documents && data?.documents.length > 0) {
      setDocuments(data?.documents);
      return;
    }
    const modifiedTemplates = templates.map(({ node }) => ({
      ...node,
      checked: false,
    }));
    setDocuments(modifiedTemplates);
    return;
  }, [data, templates]);

  const handleCheckBoxClick = document => {
    const index = documents.findIndex(doc => doc.id === document.id);
    if (index > -1) {
      const newDocuments = [...documents];
      newDocuments[index].checked = !newDocuments[index].checked;
      setDocuments(newDocuments);
    }
  };

  // next screen - custom questions
  const handleNext = () => {
    const selectedDocuments = documents.filter(({ checked }) => checked); // no checked documents - no next step
    if (!selectedDocuments) return;
    const hasQuestions = helperFunctions.questionsCheck(documents); // selected documents have no questions - next step is note
    let step = 'questions';
    if (!hasQuestions) step = 'note';
    onNext(step, { documents });
  };

  return (
    <>
      <DialogContent data-test-id="documents-section">
        <List
          classes={{ root: classes.listRoot }}
          data-test-id="send-documents-list"
        >
          {documents &&
            documents.map(node => (
              <Document
                key={node.id}
                data={node}
                classes={classes}
                onCheckBoxClick={handleCheckBoxClick}
              />
            ))}
        </List>
      </DialogContent>
      <ActionButtons
        btnText1="Cancel"
        disabled={!documents.find(doc => doc.checked)}
        onBack={() => onClose()}
        onNext={handleNext}
        classes={classes}
      />
    </>
  );
};

export default Documents;
