import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query offerDocumentImages($offerId: Int!, $documentId: String!) {
    offer(id: $offerId) {
      id
      documentDetails(documentId: $documentId) {
        id
        images {
          page
          url
          width
          height
        }
      }
    }
  }
`;

const W9_QUERY = gql`
  query offerW9DocumentImages($offerId: Int!) {
    offer(id: $offerId) {
      id
      loanoutCorporation {
        w9 {
          id
          images {
            page
            url
            width
            height
          }
        }
      }
    }
  }
`;

const EXTERNAL_QUERY = gql`
  query offerExternalDocumentImages($offerId: Int!) {
    offer(id: $offerId) {
      id
      externalDocuments {
        id
        documentId
        images {
          page
          url
          width
          height
        }
      }
    }
  }
`;

const SUPPORT_QUERY = gql`
  query offerSupportingDocumentImages($offerId: Int!, $documentId: String!) {
    offer(id: $offerId) {
      id
      documentDetails(documentId: $documentId) {
        id
        supportingDocuments {
          id
          name
          userId
          images(documentId: $documentId) {
            page
            url
            width
            height
          }
        }
      }
    }
  }
`;

const useOfferDocumentImages = (
  offerId,
  documentId,
  isW9,
  isExternal,
  isSupport,
) => {
  let query = QUERY;
  if (isW9) query = W9_QUERY;
  if (isSupport) query = SUPPORT_QUERY;
  if (isExternal) query = EXTERNAL_QUERY;

  const { error, loading, data } = useQuery(query, {
    skip: !documentId,
    variables: { offerId, documentId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const w9 = data?.offer?.loanoutCorporation?.w9;
  const document = data?.offer?.documentDetails || [];
  const extDocs = data?.offer?.externalDocuments || [];

  return {
    error,
    loading,
    data: {
      w9,
      extDocs: extDocs ? extDocs.find(d => d.documentId === documentId) : [],
      document: document.find(d => d.id === documentId),
    },
  };
};

export default useOfferDocumentImages;
