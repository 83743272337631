import React from 'react';

// COMPONENTS
import PacketApprovalFlow from 'studio/oldJavascripts/components/Shared/Packets/PacketApprovalFlow';

// LAYOUT COMPONENTS
import ExpandableTableRowHeader from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowHeader';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import ExpandableTableRowInfo from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowInfo';

const ExpandedPacketFlow = props => {
  const {
    offer: { id, hasPackets },
    packetId,
  } = props;

  return (
    hasPackets && (
      <ExpandableTableRow
        key={`packet-flow-${id}-${packetId}`}
        rowExpanded={true}
      >
        <ExpandableTableCell colSpan={10} expanded={true}>
          <ExpandableTableRowHeader>
            Packet Approval Flow
          </ExpandableTableRowHeader>
          <ExpandableTableRowInfo>
            <PacketApprovalFlow params={{ offerId: id, packetId }} />
          </ExpandableTableRowInfo>
        </ExpandableTableCell>
      </ExpandableTableRow>
    )
  );
};

export default ExpandedPacketFlow;
