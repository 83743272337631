import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query contactDetails($offerId: Int!) {
    offer(id: $offerId) {
      id
      project {
        name
      }
      hirer {
        fullName
        email
        phone
      }
    }
  }
`;

const useContactDetails = offerId => {
  const { loading, data } = useQuery(QUERY, {
    variables: { offerId: parseInt(offerId) },
    skip: !offerId,
    fetchPolicy: 'no-cache',
  });

  return { loading, data: data?.offer || {} };
};

export default useContactDetails;
