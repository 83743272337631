import store from 'common/store';
import { logout } from 'common/store/actions/authenticate';

// This function logs a user out
// If possible please use react-redux to dispatch the logout from the react component
// Or or dispatch it properly from within the saga

const { dispatch } = store;

export default () => dispatch(logout());
