export const parseErrorMessages = (item, errors) => {
  let messages = errors || [];
  if (Array.isArray(item)) {
    item.forEach(i => {
      if (typeof i === 'object') {
        parseErrorMessages(i, messages);
      }
      if (typeof i === 'string' && !messages.includes(i)) {
        messages.push(i);
      }
    });
  }
  if (typeof item === 'object') {
    const keys = Object.keys(item);
    keys.forEach(key => {
      if (item[key]) {
        parseErrorMessages(item[key], messages);
      }
    });
  }
  if (typeof item === 'string' && !messages.includes(item)) {
    messages.push(item);
  }
  return messages;
};
