import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import domClasses from '../../utils/dom/classes.js';
import DOMPurify from 'dompurify';

class Link extends React.Component {
  constructor(props) {
    super(props);
    this._onClick = this._onClick.bind(this);
  }

  render() {
    if (this.props.onClick) {
      return this._renderHandledLink();
    }
    if (this.props.external) {
      return this._renderExternalLink();
    }
    return this._renderRouterLink();
  }

  _getClasses() {
    return domClasses.set(DOMPurify.sanitize(this.props.className), {
      link: true,
      'link--blue': DOMPurify.sanitize(this.props.blue),
      'link--bold': DOMPurify.sanitize(this.props.bold),
      'link--icon': DOMPurify.sanitize(this.props.icon),
      'link--left': this.props.alignment === 'left',
      'link--plain': DOMPurify.sanitize(this.props.plain),
      'link--right': this.props.alignment === 'right',
      'link--disabled': DOMPurify.sanitize(this.props.disabled),
      'link--large': DOMPurify.sanitize(this.props.large),
      'link--marginleft': DOMPurify.sanitize(this.props.marginLeft),
    });
  }

  _onClick(event) {
    event.preventDefault();
    this.props.onClick(event); // TODO: Remove this, non-Base components
    // shouldn't handle these events
  }

  _renderExternalLink() {
    return (
      <a
        className={this._getClasses()}
        href={DOMPurify.sanitize(this.props.to)}
        target={this.props.newWindow ? '_blank' : ''}
        title={DOMPurify.sanitize(this.props.title)}
        data-test-id={this.props['data-test-id']}
      >
        {DOMPurify.sanitize(this.props.children)}
      </a>
    );
  }

  _renderHandledLink() {
    return (
      <span
        className={this._getClasses()}
        onClick={this._onClick}
        title={DOMPurify.sanitize(this.props.title)}
        data-test-id={this.props['data-test-id']}
      >
        {this.props.children}
      </span>
    );
  }

  _renderRouterLink() {
    const tabIndex = this.props.preventFocus ? -1 : null;
    return (
      <RouterLink
        className={this._getClasses()}
        params={DOMPurify.sanitize(this.props.params)}
        query={DOMPurify.sanitize(this.props.query)}
        tabIndex={tabIndex}
        title={DOMPurify.sanitize(this.props.title)}
        to={this.props.to}
        data-test-id={this.props['data-test-id']}
      >
        {this.props.children}
      </RouterLink>
    );
  }
}

export default Link;
