import React from 'react';
import T from '../../utils/i18n';
import Box from '../Base/Box';
import Button from '../Base/Button';
import Field from '../Base/Field';
import Header from '../Base/Header';
import Link from '../Base/Link';
import Modal from '../Base/Modal';
import Text from '../Base/Text';
import Loader from 'common/components/Loader';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

import BoxParagraphItem from 'common/oldJavascripts/components/Base/Box/BoxParagraphItem';

// MATERIAL
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import { RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { withStyles } from '@mui/styles';
import DOMPurify from 'dompurify';

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '20px 1fr',
  },
  noteLabel: {
    fontWeight: 'bold',
  },
  charCountLabel: {
    justifySelf: 'end',
    gridColumn: '2',
  },
  radio: {
    color: 'default',
    '&$checked': {
      color: '#0000FF',
    },
  },
  activeOption: {
    color: '#0000FF',
  },
  boldText: {
    fontWeight: 'bold',
  },
  loader: {
    height: '18px',
  },
});

class RejectModal extends React.Component {
  state = {
    rejectNote: '',
    keepI9: 'true',
  };

  static getDerivedStateFromProps(props, state) {
    if (!props.visible) {
      return {
        rejectNote: '',
        keepI9: 'saveI9',
      };
    }
    return state;
  }

  render() {
    const { rejectNote, keepI9 } = this.state;
    const maxLength = '500';
    const classes = this.props.classes;
    const {
      fromApprovals = false,
      hasI9 = false,
      isRejectProcessing,
    } = this.props;
    const isSubmitting = isRejectProcessing === 'loading';

    var sanitizeContent = this.props.content || {};
    var content = sanitizeContent;
    DOMPurify.sanitize(sanitizeContent);
    DOMPurify.sanitize(content);
    var title = content.title;
    var note_paragraph = content.paragraph;
    var note_label = content.note_label;
    var note_placeholder = content.note_placeholder;
    var closeLink = content.closeLink;
    var submitBtn = content.submitBtn;

    if (!this.props.visible) {
      return null;
    }

    return (
      <Modal minimal={true} data-test-id={this.props['data-test-id']}>
        <Box minimal={true} noShadow={true}>
          <BoxItem>
            <Header>
              <Header.Title compact={true} center={true}>
                {T(title)}
              </Header.Title>
            </Header>
          </BoxItem>
          <BoxItem minimal={true}>
            <Text paragraph={true}>{T(note_paragraph)}</Text>
          </BoxItem>
          {fromApprovals && hasI9 ? (
            <BoxItem minimal={true}>
              <Text paragraph={true}>{'Select what you want to reject:'}</Text>
              <RadioGroup
                className={classes.root}
                value={keepI9}
                onChange={this.onI9RadioChange}
                row
              >
                <FormControlLabel
                  control={<Radio className={classes.radio} />}
                  label={
                    <label
                      className={
                        keepI9 === 'saveI9' ? classes.activeOption : ''
                      }
                    >
                      Offer <span className={classes.boldText}>EXCLUDING</span>{' '}
                      I-9
                    </label>
                  }
                  value={'saveI9'}
                  classes={{ label: classes.label }}
                />
                <FormControlLabel
                  control={<Radio className={classes.radio} />}
                  label={
                    <label
                      className={
                        keepI9 === 'removeI9' ? classes.activeOption : ''
                      }
                    >
                      Entire offer{' '}
                      <span className={classes.boldText}>INCLUDING</span> I-9
                    </label>
                  }
                  value={'removeI9'}
                  classes={{ label: classes.label }}
                />
              </RadioGroup>
            </BoxItem>
          ) : null}
          <BoxItem>
            <div className={classes.root}>
              <InputLabel className={classes.noteLabel}>
                {T(note_label)}
              </InputLabel>
              <Typography className={classes.charCountLabel}>
                {rejectNote.length} / {maxLength}
              </Typography>
            </div>
            <Field
              name="OfferReject"
              type="textarea"
              numRows={6}
              maxLength={maxLength}
              placeholder={T(note_placeholder)}
              onChange={this._onNoteChange}
              data-test-id={`OfferRejectModal-textArea`}
            />
          </BoxItem>
          <BoxParagraphItem align="center" minimal={true}>
            <Link
              onClick={this.onClose}
              data-test-id={`OfferRejectModal-closeButton`}
            >
              {T(closeLink)}
            </Link>
            <Button
              align="right"
              marginLeft={true}
              disabled={!this.isValid() || isSubmitting}
              onClick={this.onSubmit}
              data-test-id={`OfferRejectModal-submitButton`}
            >
              {isSubmitting ? (
                <Loader color="white" size={18} className={classes.loader} />
              ) : (
                submitBtn
              )}
            </Button>
          </BoxParagraphItem>
        </Box>
      </Modal>
    );
  }

  onI9RadioChange = event => {
    this.setState({ keepI9: event.target.value });
  };

  _onNoteChange = value => {
    this.setState({ rejectNote: value });
  };

  isValid = () => {
    const { rejectNote } = this.state;
    return Boolean(rejectNote);
  };

  onSubmit = () => {
    const { rejectNote, keepI9 } = this.state;
    const { fromApprovals = false, hasI9 = false } = this.props;
    const saveI9 =
      fromApprovals && hasI9 ? (keepI9 === 'saveI9' ? true : false) : false;

    if (this.props.onSubmit) {
      this.props.onSubmit(rejectNote, saveI9);
    }
  };

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };
}

export default withStyles(styles)(RejectModal);
