import React from 'react';
import { TableCell, TableRow, Chip } from '@mui/material';
import { withStyles } from '@mui/styles';
import DoneIcon from '@mui/icons-material/Done';
import classnames from 'classnames';

const styles = {
  headerTitle: {
    width: '70%',
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 8,
    textAlign: 'left',
  },
  warningLabel: {
    color: '#FFA800',
    fontSize: 14,
    textAlign: 'center',
    maxWidth: '65%',
    marginBottom: 10,
  },
  warningIcon: {
    color: '#FFA800',
    position: 'relative',
    top: 4,
    right: 8,
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  rightCellArea: {
    width: '30%',
    backgroundColor: '#FFFFFF',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  chip: {
    backgroundColor: '#0000ff !important',
    color: '#ffffff !important',
    fontWeight: 400,
    fontSize: 12,
    letterSpacing: '0.16px',
    lineHeight: '18px',
    marginTop: '6px !important',
    marginRight: '12px !important',
    '&:hover': {
      background: '#0000ff',
    },
    '& .MuiChip-icon': { color: '#ffffff !important' },
  },
  icon: { background: '#ffffff !important' },
  selectedChip: {
    background: 'linear-gradient(180deg, #1A55FD 0%, #000080 100%)',
    border: '1px solid #BECFFE',
    '&:hover': {
      background: 'linear-gradient(180deg, #1A55FD 0%, #000080 100%)',
    },
  },
};

const AgentFeeSelect = props => {
  const { title, value, setValue, classes, disabled } = props;

  return (
    <TableRow>
      <TableCell className={classes.headerTitle}>{title}</TableCell>
      <TableCell className={classes.rightCellArea}>
        <div className={classes.itemContainer}>
          <Chip
            icon={value === 'N/A' ? <DoneIcon classes={classes.icon} /> : null}
            label="N/A"
            clickable
            className={classnames(
              classes.chip,
              value === 'N/A' ? classes.selectedChip : '',
            )}
            onClick={() => setValue('N/A')}
            disabled={disabled}
            data-test-id="agent-fee-na"
          />
          <Chip
            icon={value === '10%' ? <DoneIcon classes={classes.icon} /> : null}
            label="10%"
            clickable
            className={classnames(
              classes.chip,
              value === '10%' ? classes.selectedChip : '',
            )}
            onClick={() => setValue('10%')}
            disabled={disabled}
            data-test-id="agent-fee-10"
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default withStyles(styles)(AgentFeeSelect);
