import gql from 'graphql-tag';

export default gql`
  query states {
    states {
      id
      name
      code
    }
  }
`;
