import React, { useState } from 'react';
import qs from 'qs';
import history from 'common/constants/config/history';
import Allowances from 'studio/components/ManageOffers/Allowances/Allowances';
import FetchMoreApprovals from './FetchMoreApprovals/index.js';
import ApprovalsHeader from './Header.js';
import ApprovalRow from './Row.js';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import Box from 'common/oldJavascripts/components/Base/Box';
import CustomFieldsRow from 'common/oldJavascripts/components/Shared/CustomFieldsRow';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import Flexbox from 'flexbox-react';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import OfferPacketsTable from 'studio/oldJavascripts/components/Shared/OfferPacketsTable';
import OfferRejectModal from 'common/oldJavascripts/components/Shared/OfferRejectModal';
import Loader from 'common/components/Loader';
import ProcessedRow from './ProcessedRow.js';
import RateScaleRow from 'common/oldJavascripts/components/Shared/RateScaleRow';
import Tabs from 'common/oldJavascripts/components/Base/Tabs';
import AdditionalDealTerms from 'common/oldJavascripts/components/Pages/Project/Offers/AdditionalDealTerms.js';
import OfferFreeFormFields from 'studio/oldJavascripts/components/Pages/Project/Offers/OfferFreeFormFields';
import TypographyLink from './TypographyLink.js';
import AdditionalDocumentsRejectModal from './AdditionalDocumentsRejectionModal.js';
// MAP to constuct nonUnionTermsOfEmployment and unionTermsOfEmployment for RateScaleRow
import nonUnionTermsOfEmployment from 'studio/components/ManageOffers/utilities/nonUnionTermsOfEmployment/nonUnionTermsOfEmployment';
import unionTermsOfEmployment from 'studio/components/ManageOffers/utilities/unionTermsOfEmployment/unionTermsOfEmployment';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

// HoC
import withApi from 'common/hoc/withApi';
import { withStyles } from '@mui/styles';
import withPermissionProtection from 'common/hoc/withPermissionProtection';
import { compose } from 'redux';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import ExpandableTable from 'common/oldJavascripts/components/Base/ExpandableTable';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableRowInfo from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowInfo';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import TermsOfHire from './TermsOfHireExpandedView.js';

// GQL
import useApprovals from './hooks/useApprovals.js';
import accountCodesMap from 'studio/components/ManageOffers/utilities/accountCodesMap';

const EMPTY_CONTENT = <Blankslate>No offers</Blankslate>;

const REJECT_MODAL_CONTENT = {
  closeLink: 'Close',
  note_label: 'Note',
  note_placeholder:
    'Please add a short explanation for declining the approval of the offer.' +
    ' For example, "the allowance amount is over budget."',
  paragraph:
    'You have chosen to decline the selected offer.' +
    ' It is required that you give us a short explanation for this action.',
  submitBtn: 'Submit',
  title: 'Add a Note',
};

const styles = () => ({
  table: {
    width: '100%',
    tableLayout: 'fixed',
  },
  employeeNameHeader: {
    width: '15%',
  },
  departmentHeader: {
    width: '10%',
  },
  jobTitleHeader: {
    width: '10%',
  },
  unionHeader: {
    width: '9%',
  },
  startDateHeader: {
    width: '9%',
  },
  rateHeader: {
    width: '10%',
  },
  allowancesHeader: {
    width: '11%',
  },
  actionButtonHeaders: {
    width: '12%',
  },
  statusHeader: {
    width: '12%',
  },
  loader: {
    backgroundColor: 'white',
    width: '100%',
    height: '200px',
  },
  recordsText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.16px',
    color: '#8D8D8D',
  },
});

// change to functional component
const Approvals = props => {
  const [expandedApprovals, setExpandedApprovals] = useState([]);
  const [hasAllExpanded, setHasAllExpanded] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [expandedWorkflow, setExpandedWorkflow] = useState({});
  const [signingOfferId, setSigningOfferId] = useState(null);
  const [isFreeFormFieldsShown, setIsFreeFormFieldsShown] = useState(null);
  const [numberOfColumns, setNumberOfColumns] = useState(null);
  const [selectedOfferHasI9, setSelectedOfferHasI9] = useState(false);
  const [offerSelectedToReject, setOfferSelectedToReject] = useState(null);

  const { classes, routerParams, routerQuery } = props;
  const { projectId } = routerParams;

  const approvalsVariables = {
    id: parseInt(projectId),
    keyword: routerQuery?.q,
    sort: routerQuery?.sort,
    processed: routerQuery?.status === 'processed',
    direction: routerQuery?.direction,
    after: null,
    offerIds: [],
  };

  const {
    data: {
      approvals: { nodes: approvals = [] } = {},
      approvals: { pageInfo } = [],
      country: { name: projectCountry = '' } = {},
      offerDefaults: { enforceMask: projectEnforceMask } = {},
      accountMask: projectAccountMask,
      allowEndDate: projectAllowEndDate,
      status: { approvals: { status: approvalsStatus } = {} } = {},
    } = {},
    loading: offersLoading,
    refetch: reloadQuery,
    networkStatus,
    fetchMore,
  } = useApprovals(approvalsVariables);

  const isLoading = offersLoading && networkStatus !== 3;

  const selectedOffer =
    approvals && approvals.find(({ id }) => id === signingOfferId);
  const rejectStatus = props?.offerReject?.status;
  const previousStatus = selectedOffer?.previousStatus;

  const OfferFreeFormFieldsProjectData = {
    country: projectCountry,
    enforce_mask: projectEnforceMask,
    account_mask: projectAccountMask,
  };

  const _isProcessed = () => {
    const { routerQuery: query = {} } = props;
    const { status } = query || {};
    return status === 'processed';
  };

  const isProcessed = _isProcessed();

  const _renderContent = () => {
    if (approvalsStatus === 'failed') {
      return _renderContentFailed();
    }
    return isProcessed ? _renderListProcessed() : _renderListPending();
  };

  const _renderListPending = () => {
    const { me: { data: { is_support_admin } = {} } = {} } = props;
    return (
      <Box full={true}>
        <BoxItem>
          <ApprovalsHeader
            isProcessed={is_support_admin || false}
            numOfApprovals={approvals.length}
            reloadQuery={reloadQuery}
          />
        </BoxItem>
        <BoxItem minimal={true}>
          <Flexbox marginBottom="5px" width="122px">
            {_renderExpand()}
          </Flexbox>
        </BoxItem>
        {_renderListPendingContent()}
      </Box>
    );
  };

  const _renderListPendingContent = () => {
    if (isLoading) return <Loader className={classes.loader} />;
    if (approvals.length === 0) {
      return EMPTY_CONTENT;
    }
    return (
      <ExpandableTable className={classes.table}>
        <ExpandableTableRow>{_renderCommonHeaders()}</ExpandableTableRow>
        {approvals.map(_renderApproval)}
      </ExpandableTable>
    );
  };

  const _renderListProcessed = () => {
    return (
      <Box full={true}>
        <BoxItem>
          <ApprovalsHeader isProcessed={true} reloadQuery={reloadQuery} />
        </BoxItem>
        {_renderListProcessedContent()}
      </Box>
    );
  };

  const _renderListProcessedContent = () => {
    if (isLoading) return <Loader className={classes.loader} />;
    if (approvals.length === 0) {
      return EMPTY_CONTENT;
    }
    return (
      <ExpandableTable className={classes.table}>
        <ExpandableTableRow data-test-id="Approved-row-header">
          {_renderCommonHeaders(true)}
        </ExpandableTableRow>
        {approvals.map(_renderProcessedApproval)}
      </ExpandableTable>
    );
  };

  const _showFreeFormFieldsByOffer = offer => {
    setIsFreeFormFieldsShown(offer.offerId);
  };

  const _renderFreeFormFieldsLink = approval => {
    return (
      <ExpandableTableRow
        key={`custom-free-form-fields-${approval.id}`}
        rowExpanded
      >
        <ExpandableTableCell colSpan={numberOfColumns} expanded>
          <TypographyLink
            onClick={() => _showFreeFormFieldsByOffer({ offerId: approval.id })}
            title={'Set Account Codes'}
          />
        </ExpandableTableCell>
      </ExpandableTableRow>
    );
  };

  const _renderContentFailed = () => {
    return (
      <Box full={true}>
        <FailureAlert />
      </Box>
    );
  };

  const handleEndDocumentInteraction = state => {
    if (state) {
      reloadQuery();
    }
    setExpandedApprovals([]);
  };

  const handleOfferReject = (offerId, hasI9) => {
    setShowRejectModal(true);
    setOfferSelectedToReject(offerId);
    setSigningOfferId(offerId);
    setSelectedOfferHasI9(hasI9);
    handleEndDocumentInteraction();
  };

  const openSigner = offerId => {
    const { location } = props;
    const { pathname } = location || {};
    history.push({
      pathname: `${pathname}/${offerId}/document-signing`,
    });
  };

  const _renderApproval = approval => {
    return [_renderApprovalRow(approval)].concat(_renderExpandedRows(approval));
  };

  const _renderApprovalRow = (approval = {}) => {
    const { id } = approval;
    return (
      <ApprovalRow
        offer={approval}
        projectId={projectId}
        endDateAllowed={projectAllowEndDate}
        key={id}
        onToggle={_toggleApprovalExpand}
        rowExpanded={_isExpanded(id)}
        openSigner={openSigner}
        handleReject={handleOfferReject}
        data-test-id={`Approvals-row-${approval.id}`}
      />
    );
  };

  const _toggleApprovalExpand = id => {
    const updatedExpandedApprovals = [...expandedApprovals];
    const index = updatedExpandedApprovals.indexOf(id);
    if (index >= 0) {
      updatedExpandedApprovals.splice(index, 1);
    } else {
      updatedExpandedApprovals.push(id);
    }
    const hasAllExpanded =
      updatedExpandedApprovals.length &&
      updatedExpandedApprovals.length === approvals.length;
    setHasAllExpanded(hasAllExpanded);
    setExpandedApprovals(updatedExpandedApprovals);
  };

  const _isExpanded = id => {
    return expandedApprovals.indexOf(id) >= 0;
  };

  const _renderProcessedApproval = approval => {
    return [_renderProcessedApprovalRow(approval)].concat(
      _renderExpandedRows(approval),
    );
  };

  const _renderProcessedApprovalRow = approval => {
    return (
      <ProcessedRow
        approval={approval}
        key={approval.id}
        params={{ id: approval.id, type: 'approver' }}
        endDateAllowed={projectAllowEndDate}
        onToggle={_toggleApprovalExpand}
        data-test-id={`Approved-row-${approval.id}`}
      />
    );
  };

  const _renderTermsOfHire = approval => {
    return <TermsOfHire offer={approval} />;
  };

  const _renderExpandedRows = approval => {
    if (!_isExpanded(approval.id)) {
      return [];
    }

    const _renderRateScaleRow = approval => {
      const {
        termsOfHire: { union: { isNonUnion } = {} },
      } = approval;
      const TermsOfEmploymentMap = isNonUnion
        ? nonUnionTermsOfEmployment(approval)
        : unionTermsOfEmployment(approval);
      return (
        <RateScaleRow
          colSpan={numberOfColumns}
          key={`rate-${approval.id}`}
          offer={TermsOfEmploymentMap}
        />
      );
    };

    const _renderCustomFieldsRow = approval => {
      const scope = `/v2/offers/${approval.id}`;
      return <CustomFieldsRow colSpan={numberOfColumns} params={{ scope }} />;
    };

    const _renderAllowancesRow = approval => {
      return (
        <Allowances
          colSpan={numberOfColumns}
          rowExpanded={true}
          key={`allowance-${approval.id}`}
          offer={approval}
        />
      );
    };

    const _handleExpandWorkflow = (offerId, documentId) => {
      setExpandedWorkflow({
        [offerId]: documentId,
      });
    };

    const _renderOfferPacketsRow = approval => {
      const { routerParams: params = {} } = props;
      const { projectId } = params;
      const scope = `/v2/projects/${projectId}/approvals/${approval.id}`;
      return (
        <ExpandableTableRow
          key={`packet-documents-${approval.id}`}
          rowExpanded={true}
        >
          <ExpandableTableCell colSpan={numberOfColumns} expanded={true}>
            <ExpandableTableRowInfo>
              <OfferPacketsTable
                hidePermanent={true}
                params={{
                  scope,
                  offerId: approval.id,
                  hasPackets: approval.has_packets,
                }}
                offerStatus={approval?.status}
                expandedWorkflow={expandedWorkflow}
                handleEndDocumentInteraction={handleEndDocumentInteraction}
                actionClickHandler={_handleExpandWorkflow}
                showHeader={false}
                width="100%"
              />
            </ExpandableTableRowInfo>
          </ExpandableTableCell>
        </ExpandableTableRow>
      );
    };

    const _renderNotes = approval => {
      const oldNote = approval?.old_notes?.map(note => {
        return {
          notes: note?.notes,
          notesUpdatedBy: note?.notes_updated_by,
          notesUpdatedAt: note?.notes_updated_at,
        };
      });
      if (approval.notes) {
        return (
          <ExpandableTableRow
            key={`approval-notes-${approval.id}`}
            rowExpanded={true}
          >
            <ExpandableTableCell colSpan={numberOfColumns} expanded={true}>
              <AdditionalDealTerms
                title="Additional Deal Terms"
                notes={approval.notes || ''}
                oldNotes={[...(oldNote || [])].reverse()}
                headerStyles={{
                  color: '#0000FF',
                  marginLeft: '8px',
                  textTransform: 'uppercase',
                  fontSize: '0.9rem',
                }}
                currentDate={approval.sent_at}
              />
            </ExpandableTableCell>
          </ExpandableTableRow>
        );
      }
      return null;
    };

    return [
      _renderFreeFormFieldsLink(approval),
      _renderTermsOfHire(approval),
      _renderRateScaleRow(approval),
      _renderCustomFieldsRow(approval),
      _renderAllowancesRow(approval),
      _renderOfferPacketsRow(approval),
      _renderNotes(approval),
    ];
  };

  const _onRejectModalClose = () => {
    setShowRejectModal(false);
  };

  const _onRejectConfirm = (comment, keepI9 = false) => {
    const {
      offerReject = {},
      routerParams: { projectId } = {},
      invalidateByPatterns,
    } = props;
    if (offerSelectedToReject === null) return;

    offerReject.updateAndSave(
      {
        ids: [offerSelectedToReject],
        comment: comment,
        keep_i9: keepI9,
      },
      'reject',
      { json: true },
      () => {
        invalidateByPatterns([`/projects/${projectId}/offers`]);
        _onRejectModalClose();
        setSelectedOfferHasI9(false);
        setOfferSelectedToReject(null);
        reloadQuery();
      },
    );
  };

  const _toggleExpandAll = () => {
    const expandedApprovals = !hasAllExpanded
      ? approvals.map(approval => approval.id)
      : [];
    setExpandedApprovals(expandedApprovals);
    setHasAllExpanded(hasAllExpanded => !hasAllExpanded);
  };

  const _renderExpand = () => {
    return (
      <FormControlLabel
        control={
          <Switch
            checked={hasAllExpanded}
            onChange={_toggleExpandAll}
            color="primary"
          />
        }
        label="Expand All"
      />
    );
  };

  const _renderMoreOffers = () => {
    if (pageInfo?.hasNextPage && !isLoading) {
      return (
        <FetchMoreApprovals
          loadMoreOffers={fetchMore}
          searchQuery={routerQuery?.q || ''}
          offersPageInfo={pageInfo}
          networkStatus={networkStatus}
        />
      );
    }
  };

  const _selectTab = index => {
    const { location } = props;
    const { pathname } = location || {};
    history.push({
      pathname,
      search: qs.stringify({
        status: index,
      }),
    });
  };

  const _hideFreeFormFieldsByOffer = offerId => {
    setIsFreeFormFieldsShown(null);
  };

  const _renderCommonHeaders = (isProcessed = false) => {
    let headers = [
      <ExpandableTableCell
        className={classes.employeeNameHeader}
        key="employee"
        header
        sort="last_name,first_name,middle_name"
      >
        Employee
      </ExpandableTableCell>,
      <ExpandableTableCell
        className={classes.departmentHeader}
        header
        key="department"
        sort="department"
      >
        Department
      </ExpandableTableCell>,
      <ExpandableTableCell
        className={classes.jobTitleHeader}
        header
        key="occupation"
        sort="occupation"
      >
        Job Title/
        <br />
        Acct Code
      </ExpandableTableCell>,
      <ExpandableTableCell
        className={classes.unionHeader}
        header
        key="union"
        sort="union"
      >
        Union
      </ExpandableTableCell>,
      <ExpandableTableCell
        className={classes.startDateHeader}
        key="startDate"
        header
        sort="start_date"
      >
        Start Date
      </ExpandableTableCell>,
      projectAllowEndDate ? (
        <ExpandableTableCell
          className={classes.startDateHeader}
          header={true}
          key="endDate"
          sort="end_date"
        >
          End Date
        </ExpandableTableCell>
      ) : null,
      <ExpandableTableCell
        className={classes.rateHeader}
        header
        key="rate"
        sort="rate_per_hour_studio,rate_per_hour_location,rate_per_day_studio,rate_per_week_studio,rate_per_week_location"
      >
        Rate/ Scale
      </ExpandableTableCell>,
      <ExpandableTableCell
        className={classes.allowancesHeader}
        align="center"
        key="allowances"
        header
      >
        Allowances/ Reimbursements
      </ExpandableTableCell>,
      isProcessed ? (
        <ExpandableTableCell
          className={classes.statusHeader}
          header
          key="status"
          sort="status_description"
          align="center"
        >
          Status
        </ExpandableTableCell>
      ) : null,
      isProcessed ? null : (
        <ExpandableTableCell
          className={classes.actionButtonHeaders}
          align="center"
          key="approvals"
          header
        >
          Approval
        </ExpandableTableCell>
      ),
      isProcessed ? null : (
        <ExpandableTableCell
          className={classes.actionButtonHeaders}
          align="center"
          key="reject"
          header
        >
          Reject
        </ExpandableTableCell>
      ),
    ];
    const presentHeaders = headers.filter(header => !!header);
    if (
      !numberOfColumns ||
      (numberOfColumns && numberOfColumns !== presentHeaders.length)
    ) {
      setNumberOfColumns(presentHeaders.length);
    }
    return headers;
  };

  return (
    <>
      <LayoutContent>
        <div>
          {previousStatus === 'approved' ? (
            <AdditionalDocumentsRejectModal
              onClose={_onRejectModalClose}
              open={showRejectModal}
            />
          ) : (
            <OfferRejectModal
              content={REJECT_MODAL_CONTENT}
              onClose={_onRejectModalClose}
              onSubmit={_onRejectConfirm}
              fromApprovals={true}
              isRejectProcessing={rejectStatus}
              hasI9={selectedOfferHasI9}
              visible={showRejectModal}
              data-test-id="Approvals-offerRejectModal"
            />
          )}
          <LayoutContent>
            <div>
              <Tabs block={true}>
                <Tabs.Item
                  index="pending"
                  to="pending"
                  isActive={!isProcessed}
                  onClick={_selectTab}
                  isDefault={true}
                >
                  Ready for Approval
                </Tabs.Item>
                <Tabs.Item
                  index="processed"
                  to="processed"
                  isActive={isProcessed}
                  onClick={_selectTab}
                >
                  My Approval History
                </Tabs.Item>
              </Tabs>
              {_renderContent()}
              {!isLoading && (
                <span className={classes.recordsText}>
                  Showing {approvals.length || 0} of {pageInfo?.totalCount}{' '}
                  offers
                </span>
              )}
              {_renderMoreOffers()}
            </div>
          </LayoutContent>
        </div>

        <Box>
          {isFreeFormFieldsShown && (
            <OfferFreeFormFields
              offer={accountCodesMap(approvals, isFreeFormFieldsShown)}
              offerId={parseInt(isFreeFormFieldsShown)}
              projectId={projectId}
              project={OfferFreeFormFieldsProjectData}
              onClose={_hideFreeFormFieldsByOffer}
            />
          )}
        </Box>
      </LayoutContent>
    </>
  );
};

Approvals.queries = {
  me: {
    info() {
      return { id: '/me' };
    },
  },
};

Approvals.mutators = {
  offerReject: {
    info(params, related) {
      const routerParams = related['/router/params'];
      return {
        create: true,
        id: `/v2/projects/${routerParams.projectId}/approvals`,
      };
    },
  },
};

export default compose(
  withPermissionProtection('can_approve_offers'),
  withStyles(styles),
  withApi,
)(Approvals);
