class UserRole {
  constructor(role) {
    this.role = role;
  }

  isEmployee() {
    return this.role === 'employee';
  }
}

export default UserRole;
