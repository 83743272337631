import withRouteHelpers from 'common/hoc/withRouteHelpers';
import useProjectCountry from './useProjectCountry';
import useOfferCountry from './useOfferCountry';

const SpecifiedCountryOnly = ({
  children,
  countryCode,
  getRouterParam,
  projectId,
}) => {
  const offerId = getRouterParam('offerId');
  const { data: projectCountry } = useProjectCountry({ projectId });
  const { data: offerCountry } = useOfferCountry({ offerId });

  if (!countryCode) return children || null;

  const country = offerCountry ?? projectCountry;
  const isSpecifiedCountry = country?.code === countryCode;

  if (typeof children === 'function') return children(isSpecifiedCountry);
  if (!isSpecifiedCountry) return null;
  return children || null;
};

export default withRouteHelpers(SpecifiedCountryOnly);
