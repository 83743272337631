import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import DocumentList from './DocumentList';
import EmptyMessage from 'studio/components/OfferPage/EmptyMessage';
import Typography from '@mui/material/Typography';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Loader from 'common/components/Loader';
import Viewer from '@castandcrew/react-viewer';

const styles = theme => ({
  root: {
    display: 'grid',
    height: 'max-content',
    width: '100%',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'max-content 15px 400px',
    gap: '0px 25px',
  },
  header: {
    gridRow: 1,
    gridColumn: 1,
  },
  error: {
    gridRow: 2,
    gridColumn: 1,
    fontSize: '1rem',
  },
  fillOutFormMessage: {
    gridRow: 3,
    placeSelf: 'center',
    gridColumn: '1 / -1',
  },
  preferredDocumentList: {
    gridRow: 3,
    gridColumn: 1,
  },
  nonPrerredDocumentList: {
    gridRow: 3,
    gridColumn: 2,
  },
  loader: {
    gridRow: 3,
    gridColumn: '1 / -1',
  },
  description: {
    color: '#6D7278',
    fontSize: 12,
  },
});

const Documents = props => {
  const {
    formData,
    templates,
    formErrors: error = '',
    onChange,
    classes,
    keepI9,
    i9DocumentTemplateId,
  } = props;
  const {
    workState,
    hireState,
    union,
    occupation,
    workSchedule,
    department,
    employmentClassification,
    documents: selectedDocuments = [],
  } = formData;
  const isTermsOfHireFilledIn =
    workState &&
    hireState &&
    union &&
    occupation &&
    workSchedule &&
    department &&
    employmentClassification;
  const recommendedTemplates = templates.filter(
    ({ preSelected, id }) =>
      preSelected || (keepI9 && id === i9DocumentTemplateId),
  );
  const availableTemplates = templates.filter(
    ({ preSelected, id }) =>
      !preSelected && !(keepI9 && id === i9DocumentTemplateId),
  );
  const isLoading = false;

  const [viewerImages, setViewerImages] = useState([]);

  const onViewClick = selectedId => {
    const selectedTemplate = templates.find(({ id }) => id === selectedId);
    const { images = [], name } = selectedTemplate;
    const newViewerImages = images.map(url => ({
      src: url,
      alt: name,
    }));
    setViewerImages(newViewerImages);
  };

  const closeViewer = () => setViewerImages([]);

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.header}>
        Documents
        <Typography variant="subtitle1" className={classes.description}>
          A collection of documents tied to your project
        </Typography>
        {error && (
          <Typography color="error" variant="caption" className={classes.error}>
            {error}
          </Typography>
        )}
      </Typography>
      {!isTermsOfHireFilledIn && (
        <EmptyMessage
          text={'To see documents, fill out the Terms Of Hire Section'}
          icon={InsertDriveFileIcon}
          classes={{ root: classes.fillOutFormMessage }}
        />
      )}
      {isLoading && <Loader className={classes.loader} />}
      {isTermsOfHireFilledIn && (
        <React.Fragment>
          <DocumentList
            documents={recommendedTemplates}
            selectedDocuments={selectedDocuments}
            onViewClick={onViewClick}
            onChange={onChange}
            keepI9={keepI9}
            i9DocumentTemplateId={i9DocumentTemplateId}
            header="Recommended Documents"
            classes={{ root: classes.preferredDocumentList }}
            data-test-id="Documents-recommended"
          />
          <DocumentList
            documents={availableTemplates}
            selectedDocuments={selectedDocuments}
            onViewClick={onViewClick}
            onChange={onChange}
            header="Available Documents"
            classes={{ root: classes.nonPrerredDocumentList }}
            data-test-id="Documents-available"
          />
        </React.Fragment>
      )}
      <Viewer
        visible={viewerImages.length}
        images={viewerImages}
        onClose={closeViewer}
        rotatable={false}
        attribute={false}
        scalable={false}
      />
    </div>
  );
};

export default withStyles(styles)(Documents);
