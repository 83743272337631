import React from 'react';
import { withStyles } from '@mui/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from '@mui/material';

const styles = () => ({
  cancel: {
    padding: '8px 12px',
    background: '#FFFFFF',
    color: '#0000FF',
    border: '1px solid #0000FF',
    boxSizing: 'border-box',
    margin: '0px 14px',
    boxShadow:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
  },
  apply: {
    padding: '8px 20px',
    background: '#0000FF',
    color: '#F4F4F4',
    border: '1px solid #0000FF',
    marginRight: '10px',
    boxSizing: 'border-box',
    boxShadow:
      ' 0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
    '&:hover': {
      background: '#0000FF',
      color: '#FFF',
    },
    '&:disabled': {
      background: '#96A7A6',
      color: '#C6C6C6',
      border: '1px solid #96A7A6',
      boxShadow: ' 0px 0px 1px rgba(82, 82, 82, 0.4)',
    },
  },
});

const AdditionalDocumentsRejectModal = ({ onClose, open, classes }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Reject Additional Documents</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You have already accepted the offer. Please reach out to the Hiring
          Manager if you wish to not complete these additional documents that
          were added to the offer.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.apply}
          data-test-id={`additional-docs-reject-modal-button`}
          autoFocus
          onClick={onClose}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(AdditionalDocumentsRejectModal);
