import { date } from '../../utils/format.js';

import React from 'react';
import Relay from '../../utils/react/Relay.js';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableRowHeader from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowHeader';
import ExpandableTableRowInfo from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowInfo';
import ExpandableTableRowInfoItem from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowInfoItem';
import ExpandableTableRowInfoItemData from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowInfoItemData';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

const renderFieldRows = fields =>
  fields.map(field => (
    <ExpandableTableRowInfoItem
      icon="fa-file-text"
      key={`custom-field-${field.id}`}
      small={true}
    >
      <span className="expandableTable-rowInfo-grouped">
        <ExpandableTableRowInfoItemData important={true}>
          {field.label}
        </ExpandableTableRowInfoItemData>
        {renderFieldValue(field) || '—'}
      </span>
    </ExpandableTableRowInfoItem>
  ));

const renderFieldValue = field => {
  switch (field.type) {
    case 'checkboxgroup': {
      return (field.options || []).map(option => option.label).join(', ');
    }
    case 'date': {
      return date(field.value);
    }
    default: {
      return String(field.value);
    }
  }
};

export default Relay.createClass({
  statics: {
    queries: {
      fields: {
        info(params) {
          return {
            id: `${params.scope}/additional-approval-info`,
          };
        },
      },
    },
  },

  render() {
    if (this.props.fields.status === 'loading') {
      return <ExpandableTableRow />;
    }
    const { fields: { data = {} } = {} } = this.props;
    const { items: fields = [] } = data || {};

    if (fields.length) {
      return (
        <ExpandableTableRow rowExpanded={true}>
          <ExpandableTableCell colSpan={this.props.colSpan} expanded={true}>
            <ExpandableTableRowHeader>
              Additional approval information
            </ExpandableTableRowHeader>
            <ExpandableTableRowInfo wrap={true}>
              {renderFieldRows(fields)}
            </ExpandableTableRowInfo>
          </ExpandableTableCell>
        </ExpandableTableRow>
      );
    }

    return <ExpandableTableRow />;
  },
});
