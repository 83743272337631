import React from 'react';

// Components
import TableCell from '@mui/material/TableCell';
import Select from 'react-select';

// Utilities
import classNames from 'class-names';

const selectStyles = {
  control: (styles, state) => ({
    ...styles,
    '&:hover': {
      borderColor: '#0000FF',
    },
    borderColor:
      state.isActive || state.isFocused ? '#0000FF' : styles.borderColor,
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected
      ? '#0000FF'
      : state.isFocused
      ? '#E2F1FF'
      : state.backgroundColor,
  }),
};

const PerField = ({
  id,
  onChange: upstreamOnChange,
  value = '',
  updateKey,
  classes = {},
}) => {
  const options = () => [
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week' },
  ];

  // We only store the code in our formData but we need to supply the formatted option
  // as the selected value
  const selectValue = options().find(({ value: val }) => value === val) || null;

  const onChange = ({ value }) =>
    upstreamOnChange({
      [updateKey]: value,
    });

  return (
    <TableCell
      align="center"
      className={classNames(classes.root, classes.selectContainer)}
    >
      <div data-test-id={`PerField-select-${id}`}>
        <Select
          styles={selectStyles}
          onChange={onChange}
          value={selectValue}
          options={options()}
          className={classes.select}
        />
      </div>
    </TableCell>
  );
};

export default PerField;
