import debounce from 'lodash.debounce';
import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Toolbar as MuiToolbar, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import StatusMenu from './StatusMenu';
import FilterListIcon from '@mui/icons-material/FilterList';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import listParams from './listParams';

const SEARCH_DEBOUNCE = 1000;

const styles = theme => ({
  toolbar: {
    display: 'block !important',
  },
  toolbarItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
});

// Using debounce inside the render function constructs a new closure every time
// which is *not* what we want, we want a common debounce loop for every call.
const updateSearchInUrl = debounce(
  (amendRouterQuery, search) => amendRouterQuery({ search }),
  SEARCH_DEBOUNCE,
);

const Toolbar = props => {
  const { classes, routerQuery, amendRouterQuery } = props;
  const { mapped, unmapped, visible, hidden, q: searchFromUrl } = listParams(
    routerQuery,
  );
  const statusFilter = { mapped, unmapped, visible, hidden };

  const [statusMenuAnchor, setStatusMenuAnchor] = useState(null);
  const [enteredSearch, setEnteredSearch] = useState(searchFromUrl);

  return (
    <MuiToolbar className={classes.toolbar}>
      <div className={classes.toolbarItem}>
        <TextField
          variant="standard"
          value={enteredSearch}
          placeholder="Filter Documents..."
          onChange={({ target: { value } }) => {
            setEnteredSearch(value);
            updateSearchInUrl(amendRouterQuery, value);
          }}
          data-test-id="Toolbar-textFilter"
        />
        <IconButton
          title="Filter List"
          onClick={ev => setStatusMenuAnchor(ev.currentTarget)}
          data-test-id="Toolbar-statusFilter"
        >
          <FilterListIcon />
        </IconButton>
        <StatusMenu
          onClose={() => setStatusMenuAnchor(null)}
          onChange={statusFilter =>
            amendRouterQuery({ ...statusFilter, page: 1 })
          }
          statusFilter={statusFilter}
          anchor={statusMenuAnchor}
        />
      </div>
    </MuiToolbar>
  );
};

export default withStyles(styles)(withRouteHelpers(Toolbar));
