import React, { useState } from 'react';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import { withStyles } from '@mui/styles';
import {
  Button,
  Paper,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import * as Mutations from 'common/apollo/mutations';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import validateEmail from 'common/utilities/validateEmail';
import isFilledIn from 'common/utilities/isFilledIn';

const styles = {
  root: {
    display: 'grid',
    width: '100%',
    height: 'calc(100% - 120px)',
    gridTemplateColumns: '100%',
    gridTemplateRows: '25px calc(100% - 25px)',
    // placeItems: 'center',
    justifyItems: 'center',
    gridTemplateAreas: `
    "....."
    "paper"`,
  },
  paper: {
    gridArea: 'paper',
    display: 'grid',
    height: '700px',
    width: '460px',
    gridTemplateColumns: '15% 35% 35% 15%',
    gridTemplateRows: '5% 15% 50% 20% 10%',
    gridTemplateAreas: `
    ". ...... ...... ."
    ". header header ."
    ". form   form   ."
    ". cancel submit ."
    ". ...... ...... ."`,
    minHeight: 510,
  },
  header: {
    gridArea: 'header',
    placeSelf: 'center',
  },
  form: {
    gridArea: 'form',
    display: 'grid',
  },
  cancel: {
    gridArea: 'cancel',
    height: '48px',
    width: '141px',
    placeSelf: 'center',
  },
  submitWrapper: {
    gridArea: 'submit',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
    gridTemplateAreas: `
    "submitButton"`,
    height: '48px',
    width: '141px',
    placeSelf: 'center',
  },
  underline: {
    '&:before': {
      borderBottomColor: '#2E1EFF',
    },
    '&:after': {
      borderBottomColor: '#0000FF',
    },
  },
  errorUnderline: {
    '&:before': {
      borderBottomColor: '#f44336',
    },
    '&:after': {
      borderBottomColor: '#f44336',
    },
  },
  errorLabel: {
    color: '#f44336',
  },
  submitButton: {
    gridArea: 'submitButton',
    color: 'white',
    backgroundColor: '#0000FF',
    '&:hover': {
      backgroundColor: '#4D7bFD',
    },
  },
  submitSuccess: {
    color: 'white',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  submitFailure: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  submitNormalState: {
    color: 'white',
    backgroundColor: '#0000FF',
    '&:hover': {
      backgroundColor: '#0000FF',
    },
  },
  submitDisabled: {
    backgroundColor: '#9A9FAF !important',
    color: 'white !important',
  },
  submitProgress: {
    color: green[500],
    gridArea: 'submitButton',
  },
};

const MergeAccounts = ({ classes, onSubmit, pushSnackbarNotification }) => {
  const [email1, setEmail1] = useState('');
  const [email2, setEmail2] = useState('');
  const [reason, setReason] = useState('');

  const canSubmit =
    validateEmail(email1) &&
    validateEmail(email2) &&
    isFilledIn(reason) &&
    reason.length >= 10;

  const submit = () => {
    pushSnackbarNotification({
      message: 'Merge accounts operation in progress',
      variant: SnackbarVariants.INFO,
    });
    onSubmit({ email1, email2, reason })
      .then(() => {
        pushSnackbarNotification({
          message: 'Merge accounts operation complete',
          variant: SnackbarVariants.SUCCESS,
        });
        setEmail1('');
        setEmail2('');
        setReason('');
      })
      .catch(e => {
        const { graphQLErrors = [] } = e || {};
        const { message = '' } = graphQLErrors[0] || {};
        pushSnackbarNotification({
          message: `Merge accounts operation failed${
            message.length ? `: ${message}` : '.'
          }`,
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  const despace = s => s.replace(/ /g, '');

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography
          className={classes.header}
          variant="h4"
          data-test-id="MergeAccounts-title"
        >
          Merge Accounts
        </Typography>
        <form className={classes.form} noValidate autoComplete="off">
          <TextField
            variant="standard"
            label="Old or Current Email"
            margin="normal"
            required
            data-test-id="MergeAccounts-account1Email"
            value={email1}
            onChange={ev => setEmail1(despace(ev.target.value))}
          />
          <TextField
            variant="standard"
            label="New or Desired Email"
            margin="normal"
            required
            data-test-id="MergeAccounts-account2Email"
            value={email2}
            onChange={ev => setEmail2(despace(ev.target.value))}
          />
          <TextareaAutosize
            minRows={10}
            onChange={ev => setReason(ev.target.value.trim())}
            aria-label="merge accounts reason text area"
            placeholder="Provide an explanation for merging these accounts. Add Salesforce ticket also."
            data-test-id="MergeAccounts-reason"
          />
        </form>
        <div className={classes.submitWrapper}>
          <Button
            className={classes.submitButton}
            onClick={submit}
            variant="contained"
            disabled={!canSubmit}
            classes={{
              disabled: classes.submitDisabled,
            }}
            data-test-id="MergeAccounts-submit"
          >
            Submit
          </Button>
        </div>
      </Paper>
    </div>
  );
};

const withMergeAccount = graphql(Mutations.changeEmail, {
  props: ({ mutate }) => ({
    onSubmit: ({ email1, email2, reason }) =>
      mutate({
        variables: {
          currentEmail: email1,
          newEmail: email2,
          reason: reason,
        },
      }),
  }),
});

export default compose(
  withSnackbarNotification,
  withMergeAccount,
  withStyles(styles),
)(MergeAccounts);
