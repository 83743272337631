import React from 'react';
import { withStyles } from '@mui/styles';

const styles = () => ({
  cls1: {
    fill: '#0000FF',
  },
  cls2: {
    fill: 'none',
    stroke: '#0000FF',
    strokeLinecap: 'round',
    strokeWidth: '2px',
    strokeLinejoin: 'round',
  },
  cls3: {
    fill: 'none',
    stroke: '#0000FF',
    strokeLinecap: 'round',
    strokeWidth: '2px',
    strokeMiterlimit: '10',
  },
});

const W2 = ({ classes, ...propsCatch }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 33 43"
    {...propsCatch}
  >
    <title>w2</title>
    <path
      className={classes.cls1}
      d="M578.41,4940.63a0.8,0.8,0,0,1,0-.24,0.62,0.62,0,0,1,.64-0.61,0.63,0.63,0,0,1,.61.45l1.13,3.49,1.14-3.59a0.81,0.81,0,0,1,.63-0.63h0.1a0.8,0.8,0,0,1,.63.63l1.14,3.52,1.13-3.45a0.63,0.63,0,0,1,1.22.18,0.89,0.89,0,0,1,0,.25l-1.59,4.41a0.64,0.64,0,0,1-.65.45h-0.13a0.62,0.62,0,0,1-.64-0.45l-1.12-3.23-1.12,3.21a0.63,0.63,0,0,1-.64.47h-0.13A0.64,0.64,0,0,1,580,4945Z"
      transform="translate(-568.72 -4925.49)"
    />
    <path
      className={classes.cls1}
      d="M587.68,4944.3l1.62-1.33a1.86,1.86,0,0,0,1-1.37,0.7,0.7,0,0,0-.76-0.74,1.2,1.2,0,0,0-.93.5,0.57,0.57,0,0,1-.42.18,0.57,0.57,0,0,1-.57-0.57,0.62,0.62,0,0,1,.15-0.39,2.24,2.24,0,0,1,1.85-.84,1.75,1.75,0,0,1,2,1.76v0c0,1-.49,1.45-1.5,2.23l-0.93.74h2a0.51,0.51,0,0,1,.54.5,0.5,0.5,0,0,1-.54.5H588a0.59,0.59,0,0,1-.68-0.58A0.76,0.76,0,0,1,587.68,4944.3Z"
      transform="translate(-568.72 -4925.49)"
    />
    <polygon
      className={classes.cls2}
      points="32 42 1 42 1 1 21.17 1 32 11.77 32 42"
    />
    <polyline className={classes.cls2} points="21 1 21 12 32 12" />
    <line className={classes.cls3} x1="9" y1="24" x2="25" y2="24" />
    <line className={classes.cls3} x1="9" y1="29.42" x2="25" y2="29.42" />
    <line className={classes.cls3} x1="9" y1="35" x2="25" y2="35" />
  </svg>
);

export default withStyles(styles)(W2);
