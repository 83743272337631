export const styles = {
  dialogPaper: {
    maxHeight: '600px !important',
    minHeight: 400,
    minWidth: 500,
    padding: 10,
  },
  closeButton: {
    height: 35,
    borderRadius: '2px !important',
    position: 'absolute !important',
    right: 8,
    top: 17,
    color: '#262626 !important',
  },
  btn: {
    height: 35,
    borderRadius: '2px',
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: 14,
  },
  btnContained: {
    backgroundColor: '#0000FF',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#0000FF',
    },
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  btnOutlined: {
    border: '1px solid #0000FF',
    color: '#0000FF',
    backgroundColor: '#fff',
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  listItemCheckbox: {
    minWidth: '10px !important',
  },
  listText: {
    color: '#0000FF !important',
  },
  listItemGutters: {
    paddingLeft: '0px !important',
  },
  dialogRoot: {
    zIndex: '1000 !important',
  },
  loaderContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  required: { color: '#FF0000' },
  noteLength: { color: '#8D8D8D' },
  noteTextArea: {
    minWidth: 400,
    background: '#F4F4F4',
    border: 'none',
    padding: 10,
  },
  confirmSubHeader: {
    fontWeight: 700,
    marginBottom: 16,
  },
  multiList: {
    listStyle: 'none',
  },
  confirmUsersList: {
    marginTop: 40,
  },
  questionsSubHeader: {
    fontSize: 12,
  },
  confirmNote: {
    marginTop: 40,
    fontWeight: 700,
    overflowWrap: 'anywhere',
    '& span': {
      fontWeight: 300,
      display: 'block',
      paddingTop: 2,
      marginLeft: 10,
    },
  },
  noQuestionsText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center',
    color: '#525252',
  },
  listRoot: {
    marginRight: 24,
    marginLeft: 13,
  },
  itemTextRoot: {
    color: '#525252',
    fontSize: 14,
    margin: '0px 0px 0px 10px',
    display: 'inline list-item',
  },
  checkboxRoot: {
    color: '#6f6f6f',
    padding: 5,
  },
  dialgActionsRoot: {
    padding: '12px 8px',
    justifyContent: 'center',
    marginTop: 4,
  },
  dialogTitleRoot: {
    textAlign: 'center',
    '& h2': {
      color: '#262626',
      fontSize: 20,
      fontWeight: 700,
    },
  },
  input: {
    marginLeft: 4,
    flex: 1,
    width: '78%',
  },
  iconButton: {
    padding: 10,
    cursor: 'auto',
  },
  inputContainer: {
    borderBottom: '2px solid #ccc',
    background: '#fff',
  },
  allText: {
    paddingLeft: 12,
    marginTop: 12,
    paddingBottom: 4,
  },
  listWrapper: {
    marginLeft: 10,
  },
  subHeader: {
    marginLeft: 26,
    marginTop: -14,
    marginBottom: 20,
    textAlign: 'center',
  },
  fieldsWrapper: {
    boxShadow: '0px 0px 5px 0px #262626B2',
    background: '#F4F4F4',
    padding: 8,
  },
  inputWrapper: {
    padding: 8,
    background: '#F4F4F4',
    boxShadow: '0px -1px 5px -1px #262626B2',
    borderBottom: '1px solid #505050',
  },
};
