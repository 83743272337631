import React, { PureComponent, Fragment } from 'react';

import { withStyles } from '@mui/styles';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Grid, Typography, Button, IconButton } from '@mui/material';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from '@mui/icons-material';
import Document from 'common/components/UploadedDocument';
import humanizeFileTypes from 'common/utilities/humanizeFileTypes';
import * as palette from 'common/shared/oldDocumentSignerUI/palette';
import Uploader from './Uploader';
import Transition from 'common/components/Transition';
import SingleDocIcon from 'common/icons/SingleDocIcon';
import { MYFILES_NEXT_BTN, MYFILES_CANCEL_BTN } from './Styles';

const styles = theme => ({
  container: {
    padding: '10px',
    width: '93% !important',
    marginLeft: '20px',
    marginRight: '20px',
    border: '1px solid #333',
    marginBottom: '10px',
  },
  title: {
    fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
    color: '#616161',
    marginBottom: '14px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    backgroundColor: 'white',
    borderTop: '1px solid',
    borderColor: palette.white,
    padding: 0,
    marginBottom: theme.spacing(6),
    boxShadow: 'inset -1px 1px -1px rgba(0, 0, 0, 0.8)',
    '& > *': {
      width: '100%',
    },
  },
  closeIcon: {
    top: theme.spacing(2),
    right: theme.spacing(2),
    position: 'absolute',
    cursor: 'pointer',
  },
  secondaryButton: {
    ...MYFILES_NEXT_BTN,
  },
  cancel: {
    ...MYFILES_CANCEL_BTN,
    padding: '6px 15px !important',
  },
  disabledBtn: {
    ...MYFILES_NEXT_BTN,
    opacity: '0.4',
  },
  instructions: {
    color: '#616161',
    fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '30px',
    paddingBottom: '40px',
  },
  fileName: {
    color: '#212121',
    fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
    fontSize: 14,
  },
  deleteIcon: {
    cursor: 'pointer',
    color: '#9e9e9e',
  },
  eyeIcon: {
    color: '#0000FF',
    height: '16px',
    width: '16px',
    position: 'relative',
    bottom: '0px',
    '&:hover': {
      color: '#rgba(0, 0, 0, 0.04) !important',
    },
  },
  eyeIconCircle: {
    padding: '12px !important',
  },
  rowContainer: {
    minHeight: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  viewCopy: {
    fontFamily: 'Roboto, Helvetica, Helvetica Neue, san-serif',
    fontSize: '10px',
    color: '#9e9e9e',
    textAlign: 'center',
  },
});

class DocumentUploaderModal extends PureComponent {
  state = {
    files: [],
    isDocumentModalOpen: this.props.open,
    selectedMyFiles: [],
  };

  componentWillReceiveProps(nextProps) {
    const {
      uploadedSupportDocuments = [],
      recentlySelectedMyFiles = [],
    } = nextProps;
    if (
      recentlySelectedMyFiles.length > 0 &&
      uploadedSupportDocuments.length > 0
    ) {
      const myFiles = recentlySelectedMyFiles.reduce(
        (arr, file) =>
          arr.concat(
            uploadedSupportDocuments.filter(({ name }) => name === file.name),
          ),
        [],
      );
      this.setState({ selectedMyFiles: myFiles });
    }
  }

  getDefaultInstructions = () => {
    const { fileTypes = '' } = this.props;
    const hFileTypes = humanizeFileTypes(fileTypes);
    return `Please upload ${hFileTypes} files only, other types of files will not
      be accepted. Drag & Drop files below, or choose files from file selector.`;
  };

  handleClose = () => {
    const { closeModalHandler } = this.props;
    closeModalHandler();
  };

  handleUpload = files => {
    const { maxFiles } = this.props;
    const { files: currentFiles } = this.state;
    let filesToProcess;
    // This set of conditionals are to forbid the upload of more files
    // than allowed
    if (currentFiles.length === maxFiles) return;
    if (maxFiles && files.length + currentFiles.length >= maxFiles) {
      const maxAllowed = maxFiles - currentFiles.length;
      filesToProcess = files.slice(0, maxAllowed);
    } else {
      filesToProcess = files;
    }
    const fs = filesToProcess.map(f => ({
      fileId: null,
      file: f,
      fileName: f.name,
    }));
    this.setState({
      files: [...this.state.files, ...fs],
    });
  };

  getDeleteHandler = file => {
    const { deleteHandler: upstreamDeleteHandler } = this.props;
    if (!upstreamDeleteHandler) return;
    return ({ fileId }) =>
      upstreamDeleteHandler({ fileId }).then(() =>
        this.setState(({ files }) => ({
          files: files.filter(f => f.file !== file),
        })),
      );
  };

  deleteMyFile = file => {
    const { deleteHandler } = this.props;
    const uploadedFile = {
      ...file,
      fileId: file.id,
    };
    deleteHandler(uploadedFile).then(() =>
      this.setState(({ selectedMyFiles }) => ({
        selectedMyFiles:
          selectedMyFiles.length > 0
            ? selectedMyFiles.filter(f => f !== file)
            : selectedMyFiles,
      })),
    );
  };

  selectFromMyFiles = () => {
    const { closeModalHandler, myFilesModalHandler } = this.props;
    closeModalHandler();
    myFilesModalHandler();
  };

  handleNext = () => {
    const { closeModalHandler, successModalHandler } = this.props;
    const { files } = this.state;
    closeModalHandler();
    successModalHandler(files);
  };

  cancelEverything = () => {
    const {
      closeModalHandler,
      deleteHandler,
      uploadedSupportDocuments,
    } = this.props;
    const { selectedMyFiles, files } = this.state;
    // delete normal uploaded files
    let uploadedFromComputer = [];
    files.forEach(item => {
      let temp = uploadedSupportDocuments.filter(
        arrItem => arrItem.name === item.fileName,
      );
      uploadedFromComputer.push(...temp);
    });

    if (selectedMyFiles.length > 0) {
      selectedMyFiles.forEach(({ id, canDelete }) =>
        canDelete ? deleteHandler({ fileId: id }) : null,
      );
    }
    if (uploadedFromComputer.length > 0) {
      uploadedFromComputer.forEach(({ id, canDelete }) =>
        canDelete ? deleteHandler({ fileId: id }) : null,
      );
    }
    closeModalHandler();
  };

  render() {
    const {
      title,
      instructions = this.getDefaultInstructions(),
      classes,
      editableFiles,
      minFiles,
      maxFiles,
      uploadHandler,
      editHandler,
      fileTypes,
      viewHandler,
      isMyFiles,
    } = this.props;
    const { isDocumentModalOpen, files, selectedMyFiles } = this.state;

    const optionalProps = viewHandler ? { viewHandler } : {};

    const filesDiv = files.map(f => (
      <Document
        key={`${f.fileName}-${f.file.size}`}
        document={f}
        editable={editableFiles}
        editHandler={editHandler}
        uploadHandler={uploadHandler}
        deleteHandler={this.getDeleteHandler(f.file)}
        closeModalHandler={this.handleClose}
        {...optionalProps}
      />
    ));

    const myFilesDiv = selectedMyFiles.map(f => (
      <div className={classes.container}>
        <Grid container>
          <Grid item xs={1} className={classes.iconContainer}>
            <SingleDocIcon height={35} />
          </Grid>
          <Grid item xs={9}>
            <p className={classes.fileName}>{f.name}</p>
          </Grid>
          <Grid item xs={1}>
            <div>
              <IconButton
                className={classes.eyeIconCircle}
                onClick={() => viewHandler(f.id)}
              >
                <RemoveRedEyeIcon
                  className={classes.eyeIcon}
                  data-test-id="DocumentUploaderModal-Document-view"
                />
              </IconButton>
              <p className={classes.viewCopy}>View</p>
            </div>
          </Grid>
          <Grid item xs={1}>
            <div>
              <IconButton
                onClick={() => this.deleteMyFile(f)}
                className={classes.eyeIconCircle}
              >
                <DeleteIcon className={classes.eyeIcon} />
              </IconButton>
              <p className={classes.viewCopy}>Delete</p>
            </div>
          </Grid>
        </Grid>
      </div>
    ));

    const multiFile = maxFiles !== 1;
    const maxFilesMessage =
      maxFiles && maxFiles > 1 ? `Maximum ${maxFiles} files allowed.` : '';
    const multiFilesMessage = `You can upload multiple files at a time. ${maxFilesMessage}`;
    const showNextBtn = !!files;
    const showAddDocumentBtn = selectedMyFiles.length > 0 && files.length === 0;
    const isNextBtnDisabled = files.length === 0;
    return (
      <Fragment>
        <Dialog
          open={isDocumentModalOpen}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          TransitionComponent={Transition}
          classes={{
            root: classes.root,
          }}
          style={{ zIndex: 1000 }}
        >
          <DialogTitle id="responsive-dialog-title" disableTypography>
            <Typography
              variant="h6"
              sx={{ fontSize: '18px', marginBottom: '14px' }}
              className={classes.title}
            >
              {title}
            </Typography>
            <Typography
              variant="caption"
              sx={{ fontSize: '14px' }}
              className={classes.instructions}
            >
              {instructions} {multiFile ? multiFilesMessage : ''}
            </Typography>
            <div className={classes.closeIcon}>
              <CloseIcon
                onClick={this.handleClose}
                data-test-id="DocumentUploaderModal-close"
              />
            </div>
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            <React.Fragment>
              {myFilesDiv}
              <Uploader
                onUpload={this.handleUpload}
                maxFiles={maxFiles}
                minFiles={minFiles}
                accept={fileTypes}
                handleMyFiles={this.selectFromMyFiles}
                isMyFiles={isMyFiles}
              >
                {filesDiv}
              </Uploader>
            </React.Fragment>
          </DialogContent>
          {isMyFiles && (
            <div className={classes.modalFooter}>
              <Button
                size="medium"
                variant="text"
                className={classes.cancel}
                onClick={this.cancelEverything}
              >
                Cancel
              </Button>
              {showNextBtn && !showAddDocumentBtn && (
                <Button
                  size="medium"
                  variant="contained"
                  className={
                    isNextBtnDisabled
                      ? classes.disabledBtn
                      : classes.secondaryButton
                  }
                  data-test-id="documentuploader-modal-upload-button"
                  onClick={() => this.handleNext()}
                  disabled={isNextBtnDisabled}
                >
                  Next
                </Button>
              )}
              {showAddDocumentBtn && (
                <Button
                  size="medium"
                  variant="contained"
                  className={classes.secondaryButton}
                  data-test-id="documentuploader-add-document-button"
                  onClick={this.handleClose}
                >
                  Add Document
                </Button>
              )}
            </div>
          )}
        </Dialog>
      </Fragment>
    );
  }
}

DocumentUploaderModal.defaultProps = {
  editableFiles: false,
  minFiles: 1,
  title: 'Upload Documents',
};

export default withStyles(styles)(DocumentUploaderModal);
