import gql from 'graphql-tag';

export default gql`
  mutation changeEmail(
    $currentEmail: String!
    $newEmail: String!
    $reason: String!
  ) {
    changeEmail(
      currentEmail: $currentEmail
      newEmail: $newEmail
      reason: $reason
    )
  }
`;
