import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { withStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';

const styles = theme => ({
  browse: {
    width: 150,
    margin: 'auto !important',
    color: '#fff !important',
    backgroundColor: '#0000ff !important',
    '&:hover': {
      backgroundColor: '#1A55FD !important',
    },
  },
  filesLink: {
    color: '#0000FF',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      borderBottom: '4px solid #0000FF',
      paddingBottom: '5px',
    },
  },
  myFiles: {
    textAlign: 'center',
    marginTop: 20,
    zIndex: 5,
    color: '#333',
  },
});

class Uploader extends Component {
  static defaultProps = {
    accept: 'application/pdf',
  };

  state = {
    dropzoneActive: false,
  };

  onDragEnter = () => {
    this.setState({
      dropzoneActive: true,
    });
  };

  onDragLeave = () => {
    this.setState({
      dropzoneActive: false,
    });
  };

  onDrop = files => {
    const { onUpload } = this.props;
    this.setState({
      dropzoneActive: false,
    });
    onUpload(files);
  };

  onClick = reference => {
    reference.open();
  };

  render() {
    const { classes, accept = '', handleMyFiles } = this.props;
    let dropzoneRef;
    return (
      <React.Fragment>
        <Dropzone
          className={classes.dropzone}
          accept={accept}
          onDrop={this.onDrop}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          disableClick
          ref={node => {
            dropzoneRef = node;
          }}
          multiple={true}
          style={{ display: 'none' }}
          data-test-id="Uploader-dropzone"
        />
        <Button
          onClick={() => this.onClick(dropzoneRef)}
          className={classes.browse}
        >
          Browse for file
        </Button>
        <Typography className={classes.myFiles}>
          Select from{' '}
          <span onClick={handleMyFiles} className={classes.filesLink}>
            My Files
          </span>
          ?
        </Typography>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Uploader);
