import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query profileSynced($offerId: Int!) {
    me {
      id
      isSynced(offerId: $offerId)
    }
  }
`;

const useProfileSynced = offerId => {
  const { error, loading, data, refetch } = useQuery(QUERY, {
    variables: { offerId },
    skip: !offerId,
  });
  return { error, loading, data: data?.me, refetch };
};

export default useProfileSynced;
