import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query subDepartments($projectId: Int!, $masterDepartmentId: Int) {
    ecsProjectDepartments(
      projectId: $projectId
      masterDepartmentId: $masterDepartmentId
    ) {
      id
      name
    }
  }
`;

const useSubDepartments = variables => {
  const { projectId, masterDepartmentId } = variables;
  const skip = !projectId || !masterDepartmentId;
  const { loading, data } = useQuery(QUERY, {
    skip,
    variables,
    notifyOnNetworkStatusChange: true,
  });
  return {
    loading,
    data: !skip && data,
  };
};

export default useSubDepartments;
