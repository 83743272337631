import React from 'react';
import withApi from 'common/hoc/withApi';

const I9_NAMES = ['i9', 'I9', 'I-9', 'i-9'];

const getDoesOfferIncludeSupportingDocuments = documents =>
  documents.some(doc => doc.supportingDocumentTitles?.length > 0);

const getDoesOfferIncludeI9 = documents =>
  documents.some(doc =>
    doc.categories?.some(({ name = '' }) => I9_NAMES.includes(name)),
  );

const defaultSteps = [
  { name: 'details', label: 'Offer Details' },
  {
    name: 'supporting-documents',
    label: 'Supporting Documents',
    condition: getDoesOfferIncludeSupportingDocuments,
  },
  { name: 'i9', label: 'I-9', condition: getDoesOfferIncludeI9 },
  { name: 'documents', label: 'Forms' },
  { name: 'complete', label: 'Submission' },
];

const withOnboardingStatus = InnerComponent => {
  const Wrapper = props => {
    const {
      documents: documentsQuery,
      routerParams: { offerId },
      routerPath,
    } = props;
    const documents = documentsQuery.data?.items;

    const currentStepName = routerPath.split('/').slice(-1)[0];
    const steps = documents
      ? defaultSteps
          .filter(({ condition }) => !condition || condition(documents))
          .map(step => ({
            ...step,
            status: `onboarding_${step.name}`,
            url: `/onboarding/offers/${offerId}/${step.name}`,
          }))
      : [];
    const currentStepIndex = steps.findIndex(
      ({ name }) => name === currentStepName,
    );
    const nextStep = steps[currentStepIndex + 1];

    return (
      <InnerComponent
        {...props}
        currentOnboardingStepIndex={currentStepIndex}
        nextOnboardingStep={nextStep}
        onboardingSteps={steps}
      />
    );
  };

  Wrapper.queries = {
    documents: {
      info: (_, related) => {
        const { offerId } = related['/router/params'];
        return {
          id: offerId ? `/v2/offers/${offerId}/documents-details` : null,
        };
      },
    },
  };

  return withApi(Wrapper);
};

export default withOnboardingStatus;
