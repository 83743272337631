import React, { useEffect, useRef, useState } from 'react';

const ScrollDestination = props => {
  const {
    behavior = 'auto',
    block = 'start',
    children,
    inline = 'nearest',
    isActive,
  } = props;

  const ref = useRef(null);
  const [isActivated, setIsActivated] = useState(false);
  useEffect(() => {
    if (isActive) setIsActivated(true);
  }, [isActive]);

  if (isActivated) {
    setIsActivated(false);
    ref.current.scrollIntoView({ behavior, block, inline });
  }

  return <div ref={ref}>{children}</div>;
};

export default ScrollDestination;
