import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { Typography, Collapse, IconButton } from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import ListItem from 'admin/components/RoleMapper/ListItem';
import classNames from 'classnames';
import CustomInputField from './CustomInputField';
import DOMPurify from 'dompurify';

const styles = theme => ({
  root: {
    marginTop: 5,
    display: 'block',
  },
  inputDescription: {
    gridArea: 'inputDescription',
    display: 'flex',
    overflowWrap: 'anywhere',
    paddingTop: 4,
    paddingLeft: 8,
  },
  inputFieldsContainer: {
    gridArea: 'inputs',
    width: '100%',
    padding: 5,
    boxSizing: 'border-box',
  },
  iconButton: {
    width: '24px !important',
    height: '24px !important',
    padding: '0px',
  },
  bold: {
    fontWeight: 'bold',
  },
  withDescription: {
    width: '100%',
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridTemplateColumns: '100%',
    gridTemplateAreas: `
      "inputName"
      "inputDescription"
    `,
    alignItems: 'center',
  },
  withoutDescription: {
    width: '100%',
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridTemplateColumns: '100%',
    gridTemplateAreas: `
      "inputName "
    `,
    alignItems: 'center',
  },
  inputName: {
    gridArea: 'inputName',
    display: 'flex',
    overflowWrap: 'anywhere',
  },
  icon: {
    gridArea: 'icon',
    marginRight: 4,
  },
  inputField: {
    float: 'right',
    marginRight: 8,
    marginTop: -18,
  },
});

class CustomInput extends Component {
  state = {
    listOpen: false,
    editingInputFields: [],
    input: DOMPurify.sanitize(this.props.input) || {},
    inputFields: this.props.input.inputFields || [],
  };

  static getDerivedStateFromProps(props, state) {
    return {
      input: props.input || {},
      inputFields: props.input.inputFields || [],
    };
  }

  handleOpenList = () => this.setState({ listOpen: true });

  handleCloseList = () => this.setState({ listOpen: false });

  renderInputFields = () => {
    const { deleteInputField, openDeleteDialog, updateInput } = this.props;
    const { input, inputFields } = this.state;
    const customInputFieldProps = {
      deleteInputField,
      openDeleteDialog,
      input,
      updateInput,
    };
    return inputFields
      .filter(input => input.active)
      .map(inputField => (
        <CustomInputField
          {...customInputFieldProps}
          inputField={inputField}
          key={inputField.name}
        />
      ));
  };

  handleAddNewInputField = inputId => {
    const { addNewInputField } = this.props;
    this.setState({ listOpen: true }, () => addNewInputField(inputId));
  };

  render() {
    const {
      classes,
      openDeleteDialog,
      deleteInput,
      openInputDialog,
    } = this.props;
    const { inputFields, input, listOpen } = this.state;
    if (!input.active) return null;
    const activeInputFields = inputFields.filter(field => field.active);
    const inputDeleteMessage = (
      <span>
        There are <b className={classes.bold}>{activeInputFields.length}</b>{' '}
        inputs assigned to this custom input, are you sure you want to delete{' '}
        <b className={classes.bold}>{input.displayName}</b>?
      </span>
    );
    const inputDeleteCallback = () => {
      deleteInput(input.id);
    };

    const inputTitle = (
      <div
        className={classNames({
          [classes.withDescription]: !!input.description,
          [classes.withoutDescription]: !input.description,
        })}
      >
        <div className={classes.inputName}>
          {!!inputFields.length && (
            <IconButton
              onClick={listOpen ? this.handleCloseList : this.handleOpenList}
              classes={{ root: classes.iconButton }}
              data-test-id={`CustomInput-expandIconButton-${input.id}`}
              className={classes.icon}
            >
              {listOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
          {input.displayName}
        </div>
        <div className={classes.inputDescription}>
          {input.description && (
            <Typography
              variant="body1"
              data-test-id={`CustomInput-inputDescription-${input.id}`}
            >
              {input.description}
            </Typography>
          )}
        </div>
      </div>
    );

    const subTitle = (
      <div className={classes.inputField}>
        {activeInputFields.length} inputs
      </div>
    );

    const listProps = {
      title: inputTitle,
      subHeader: subTitle,
      buttons: [
        {
          title: 'Add Input',
          color: 'primary',
          onClick: () => this.handleAddNewInputField(input.id),
          dataTestId: `CustomInput-addInputButton-${input.id}`,
        },
        {
          title: 'Configure',
          color: 'secondary',
          onClick: () => openInputDialog(input.id),
          dataTestId: `CustomInput-configureButton-${input.id}`,
        },
        {
          title: 'Delete',
          onClick: () =>
            openDeleteDialog(inputDeleteMessage, inputDeleteCallback),
          dataTestId: `CustomInput-deleteButton-${input.id}`,
        },
      ],
      dataTestIds: {
        title: `CustomInput-displayName-${input.id}`,
        root: `CustomInput-root-${input.id}`,
        subHeader: `CustomInput-fieldCount-${input.id}`,
      },
      key: `CustomInput-${input.id}`,
      className: classes.root,
    };

    return (
      <ListItem {...listProps}>
        <Collapse
          in={listOpen}
          unmountOnExit
          classes={{ container: classes.inputFieldsContainer }}
          data-test-id={`CustomInput-collapse-${input.id}`}
        >
          {this.renderInputFields()}
        </Collapse>
      </ListItem>
    );
  }
}

export default withStyles(styles)(CustomInput);
