import Header from 'common/oldJavascripts/components/Base/Header';

import React from 'react';
import Search from 'common/oldJavascripts/components/Shared/Search';
import Text from 'common/oldJavascripts/components/Base/Text';
import QueryRefresher from 'common/components/QueryRefresher';

import { withStyles } from '@mui/styles';

const styles = () => ({
  headerContainer: {
    float: 'left',
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
  },
});

class ApprovalsHeader extends React.Component {
  render() {
    return this.props.isProcessed
      ? this._renderProcessed()
      : this._renderUnprocessed();
  }

  _hasSelectedApprovals() {
    return this.props.numOfSelectedApprovals > 0;
  }

  _renderProcessed() {
    const { reloadQuery } = this.props;
    return (
      <Header>
        <Header.Title>Approved Offers</Header.Title>
        <QueryRefresher reloadQuery={() => reloadQuery()} />
        <Header.Nav>
          <Search />
        </Header.Nav>
      </Header>
    );
  }

  _renderUnprocessed() {
    const { reloadQuery, classes = {} } = this.props;
    return (
      <Header>
        <div className={classes.headerContainer}>
          <Header.Title>Let's review and approve these offers!</Header.Title>
          <Text italic={true} small={true}>
            {'Use the buttons on the right to approve or reject an offer'}
          </Text>
        </div>
        <QueryRefresher reloadQuery={() => reloadQuery()} />
        <Header.Nav>
          <Search />
        </Header.Nav>
      </Header>
    );
  }
}

export default withStyles(styles)(ApprovalsHeader);
