import React from 'react';
import { withStyles } from '@mui/styles';

import ErrorAlert from './ErrorAlert';

const styles = {
  list: {
    listStyle: 'disc',
    padding: '4px 16px',

    '& >li': {
      marginBottom: '4px',
    },
  },
};

const extractMessages = (errors, detailed) => {
  const ret = errors.map(({ message, extensions: { data } = {} }) => {
    if (!detailed) return message;

    return Object.values(data || {}).flat();
  });

  return ret.flat();
};

const GraphQLErrors = ({ error, classes, detailed = false }) => {
  const { graphQLErrors: errors = [] } = error || {};

  const messages = extractMessages(errors, detailed);

  return (
    <ErrorAlert title="GraphQL Error">
      <ul className={classes.list}>
        {messages.map((message, i) => (
          <li key={`graphql-error-${i}`}>{message}</li>
        ))}
      </ul>
    </ErrorAlert>
  );
};

export default withStyles(styles)(GraphQLErrors);
