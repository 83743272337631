import React from 'react';
import Flexbox from 'flexbox-react';
import { withStyles } from '@mui/styles';
import Link from 'common/oldJavascripts/components/Base/Link';
import Modal from './PacketDocumentModal';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import SupportingDocument from './SupportingDocument';
import UserPrivileges from 'common/oldJavascripts/utils/UserPrivileges.js';
import {
  InsertDriveFile as FileIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

const styles = {
  fileNameContainer: {
    flex: '1 1 auto',
    marginBottom: 5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    marginRight: '5px',
    verticalAlign: 'bottom',
  },
};

export default withStyles(styles)(
  Relay.createClass({
    getInitialState() {
      return {
        showModal: false,
        privileges: new UserPrivileges(this.props.user.privileges),
      };
    },

    render() {
      const { classes } = this.props;
      return (
        <Flexbox
          flexDirection="column"
          marginLeft="15px"
          style={{ fontWeight: 'normal' }}
        >
          <div className={classes.fileNameContainer}>
            {this._renderFilename()}
          </div>
          {this._renderSupportingDocuments()}
          {this._renderModal()}
        </Flexbox>
      );
    },

    _isSignatureRequired() {
      return (
        this.props.document.signature_required &&
        (!this.state.privileges.isHirer() ||
          (this.props.isApproval && this.state.privileges.isApprover()))
      );
    },

    _onPreviewClick(event) {
      event.preventDefault();
      if (this.props.onDocumentView) {
        this.props.onDocumentView(this.props.document.id);
      } else {
        this.setState({ showModal: true });
      }
    },

    _renderFilename() {
      const name = this.props.document.name;
      if (this.props.allowPreview) {
        return (
          <Link onClick={this._onPreviewClick} plain={true} title={name}>
            {this._renderIcon()}
            {name}
          </Link>
        );
      }
      return (
        <span title={name}>
          {this._renderIcon()}
          {name}
        </span>
      );
    },

    _renderIcon() {
      const { classes = {} } = this.props;
      const isSignatureRequired = this._isSignatureRequired();
      return isSignatureRequired ? (
        <EditIcon className={classes.icon} />
      ) : (
        <FileIcon className={classes.icon} />
      );
    },

    _renderModal() {
      if (this.state.showModal) {
        return (
          <Modal
            onClose={() => this.setState({ showModal: false })}
            params={this.props.params}
          />
        );
      }
    },

    _renderSupportingDocuments() {
      const doc = this.props.document;
      const privileges = this.state.privileges;

      if (
        !doc.supporting_documents ||
        doc.supporting_documents.length <= 0 ||
        (privileges.isHirer() && !privileges.isApprover())
      ) {
        return null;
      }

      return (
        <Flexbox flexDirection="column" marginLeft="15px">
          {doc.supporting_documents.map(supportingDocument => (
            <SupportingDocument
              allowPreview={this.props.allowPreview}
              document={supportingDocument}
              key={`packet-supporting-document-${supportingDocument.id}`}
            />
          ))}
        </Flexbox>
      );
    },
  }),
);
