import React from 'react';
import Flexbox from 'flexbox-react';
import { withStyles } from '@mui/styles';
import Packet from './Packet';
import Preloader from 'common/oldJavascripts/components/Shared/Preloader';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import status from 'common/oldJavascripts/utils/react/status.js';
import UserRole from 'common/oldJavascripts/utils/UserRole.js';
import OfferDocumentsListModal from 'common/components/OfferDocumentsList/DocumentModal';
import OfferDocuments from 'studio/components/OfferDocuments';
import RemoveDocumentModal from 'common/components/OfferDocumentsList/RemoveDocumentModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import classNames from 'classnames';
import ExpandableTableRowHeader from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowHeader';
import { compose } from 'redux';
import withApi from 'common/hoc/withApi';
import useOfferDocuments from 'common/components/OfferDocumentsList/hooks/useOfferDocuments';

const styles = {
  headerContainer: {
    flex: '1 1 auto',
    margin: '0 5px 10px',
    padding: '5px',
    background: '#ffffff',
  },
  packetIcon: {
    margin: '0 0 0 20px',
  },
  packetContainer: {
    flex: '0 0 auto',
    margin: '20px 10px',
  },
  offerDocuments: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-10px',
  },
  docCount: {
    marginTop: '-7px',
  },
  packetsContainer: {
    flex: '0 0 auto',
    margin: '20px 10px',
    maxWidth: 400,
  },
  approved: {
    color: '#99cc48',
  },
  notApproved: {
    color: '#99cc48',
  },
  icon: {
    marginTop: '20px',
    cursor: 'pointer',
  },
};

const OfferPacketsTable = ({
  params,
  classes,
  showHeader,
  packets,
  user,
  offerStatus,
  actionClickHandler,
  expandedWorkflow,
  handleEndDocumentInteraction,
  hidePermanent,
  allowPreview,
  colorStyle,
  width,
  showAction,
}) => {
  const { hasPackets, offerId, scope } = params;
  const { data: offer, loading, refetch } = useOfferDocuments(
    parseInt(offerId),
  );

  const { documentDetails = [], removedDocuments = [] } = offer || {};

  const [state, setState] = React.useState({
    modalIsOpen: false,
    documentId: null,
    isW9: false,
    isExternal: false,
    removeDocumentModalOpen: false,
    removeDocumentId: null,
  });

  const {
    modalIsOpen,
    documentId,
    isW9,
    isExternal,
    removeDocumentModalOpen,
    removeDocumentId,
  } = state;

  const getStatus = () => {
    return status([packets, user]);
  };

  const handleCloseModal = () => {
    setState({
      ...state,
      modalIsOpen: false,
    });
  };

  const getPackets = () => {
    const {
      data: { items = [] },
    } = packets;
    // apply hide permanent to offers documents
    if (hidePermanent) {
      return items.filter(packet => !packet.is_permanent);
    }
    return items;
  };

  const renderIcon = (documents, offerId, packetId) => {
    //need all possible approval_statuses from BE team
    const getStatus = doc => doc.approval_status;
    const countApprovals = (sum, status) =>
      status === 'approved' ? sum + 1 : sum;
    const approved =
      documents.length === documents.map(getStatus).reduce(countApprovals, 0);

    if (approved) {
      return (
        <CheckCircleIcon
          className={classNames(classes.icon, {
            [classes.approved]: approved,
          })}
        />
      );
    }
    return (
      <CheckCircleIcon
        className={classNames(classes.icon, {
          [classes.notApproved]: !approved,
        })}
        onClick={() => actionClickHandler(offerId, packetId)}
      />
    );
  };

  const removeDocument = id => {
    const removeDocumentId = id;
    setState({
      ...state,
      removeDocumentModalOpen: true,
      removeDocumentId: removeDocumentId,
    });
  };

  const handleRemoveDocumentModalClose = value => {
    setState({
      ...state,
      removeDocumentModalOpen: false,
      RemoveDocumentId: null,
    });
    const isPendingDocOne =
      documentDetails?.filter(
        doc => doc.status !== 'rejected' && doc.status !== 'approved',
      ).length === 1;

    if (isPendingDocOne) {
      handleEndDocumentInteraction(isPendingDocOne);
    }
    if (value) refetch();
  };

  const renderHeader = () => {
    if (showHeader) {
      var signatureNote = null;

      return (
        <div className={classes.headerContainer}>
          {'Offer Documents'}
          {signatureNote}
        </div>
      );
    }
  };

  const handlePreviewDocument = (id, w9, external) => {
    setState({
      ...state,
      documentId: id,
      modalIsOpen: true,
      isW9: w9,
      isExternal: external,
    });
  };

  const renderPackets = () => {
    const status = getStatus();
    const approvals = scope.split('/').indexOf('approvals') !== -1;

    if (status === 'loading') {
      return <Preloader />;
    }
    if (status === 'failed') {
      return <FailureAlert />;
    }

    const { data: userData } = user;
    const packets = getPackets();
    const isEmployee = new UserRole(userData.role).isEmployee();

    if (packets.length === 0) {
      return <Blankslate>No Packets Defined</Blankslate>;
    }

    if (showAction) {
      return packets
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map(packet => (
          <Flexbox>
            <div
              key={`packet-${packet.id}-icon`}
              className={classes.packetIcon}
            >
              {renderIcon(packet.documents, packet.offer_id, packet.id)}
            </div>
            <div
              className={classes.packetContainer}
              key={`packet-${packet.id}-desc`}
            >
              <Packet
                allowPreview={(allowPreview && approvals) || isEmployee}
                colorStyle={colorStyle}
                isApproval={approvals}
                packet={packet}
                params={params}
                user={userData}
              />
            </div>
          </Flexbox>
        ));
    }

    //sort the documents and pass the sorted list down to the OfferDocuments component
    return packets
      .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
      .map(packet => (
        <div key={`packet-${packet.id}`} className={classes.packetsContainer}>
          <Packet
            allowPreview={(allowPreview && approvals) || isEmployee}
            colorStyle={colorStyle}
            isApproval={approvals}
            packet={packet}
            params={params}
            user={user}
          />
        </div>
      ));
  };

  const renderOfferDocuments = () => {
    if (loading) {
      return <Preloader />;
    }
    const { offerId } = params;
    const isDocsMoreThanOne =
      documentDetails.filter(
        doc => !doc.isExternal && !doc.isW9 && doc.status !== 'rejected',
      ).length > 1;
    const approverCanRemoveDocuments = user.data.privileges?.includes(
      'can_approve_offers',
    );
    const offerDocs = [...documentDetails, ...removedDocuments];
    if (offerDocs.length === 0) {
      return <Blankslate>No Documents Defined</Blankslate>;
    }
    const documentCount = offerDocs.length;
    const selectedDocument =
      offerDocs.find(doc => expandedWorkflow[offerId] === doc.id) || {};
    const documentWorkflow = selectedDocument.workflow || [];
    const offerDocumentsProps = {
      documents: offerDocs,
      selectedDocument,
      documentStatus: documentWorkflow,
      offerId,
      offerStatus,
      requestDocumentStatus: actionClickHandler,
      previewDocument: handlePreviewDocument,
      approverCanRemoveDocuments,
      removeDocument: removeDocument,
      isDocsMoreThanOne: isDocsMoreThanOne,
    };
    return (
      <>
        <div className={classes.offerDocuments}>
          <ExpandableTableRowHeader>Offer Documents </ExpandableTableRowHeader>
          <span className={classes.docCount}>
            {documentCount} total documents
          </span>
        </div>
        <OfferDocuments {...offerDocumentsProps} />
      </>
    );
  };

  return (
    <Flexbox flexDirection="column" width={width}>
      {modalIsOpen && (
        <OfferDocumentsListModal
          onClose={handleCloseModal}
          documentId={documentId}
          offerId={offerId}
          isExternal={isExternal}
          isW9={isW9}
        />
      )}
      {removeDocumentModalOpen && (
        <RemoveDocumentModal
          offerId={offerId}
          documentId={removeDocumentId}
          onClose={d => handleRemoveDocumentModalClose(d)}
        />
      )}

      {renderHeader()}
      <Flexbox flexDirection="row" flexWrap="wrap">
        {hasPackets ? renderPackets() : renderOfferDocuments()}
      </Flexbox>
    </Flexbox>
  );
};

OfferPacketsTable.queries = {
  packets: {
    info(params) {
      const { hasPackets, scope } = params;
      if (hasPackets) {
        return {
          id: `${scope}/packets`,
        };
      }
      return {
        id: `empty`,
      };
    },
  },
  user: {
    info(params, related) {
      const routerParams = related['/router/params'];
      const projectId = routerParams.projectId;
      const scope = projectId ? 'projects' : 'offers';

      return {
        id: `/v2/${scope}/${projectId ? projectId : params.offerId}/user`,
      };
    },
  },
};

export default compose(withStyles(styles), withApi)(OfferPacketsTable);
