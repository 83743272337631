import { combineReducers } from 'redux';

// Reducers
// Please Sort Alphabetically by variable name
import dialogNotifications from './dialogNotifications';
import resources from './resources';
import session from './session';
import snackbarNotifications from './snackbarNotifications';
import tokens from './tokens';

export default combineReducers({
  // Please sort Alphabetically
  dialogNotifications,
  resources,
  session,
  snackbarNotifications,
  tokens,
});
