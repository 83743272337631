import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { withStyles } from '@mui/styles';
import ContactCustomerService from './ContactCustomerService';

const styles = {
  root: {
    width: 'fit-content',
    color: '#ea5a5a',
    background: '#fbdedf',
    border: '1px solid #f8c1c1',
  },
};

const ErrorAlert = props => {
  const {
    classes,
    children,
    contactCustomerService = true,
    title = null,
  } = props;

  return (
    <Alert severity="error" className={classes.root}>
      {title && <AlertTitle>{title}</AlertTitle>}
      <p>{children}</p>
      {contactCustomerService && <ContactCustomerService />}
    </Alert>
  );
};

export default withStyles(styles)(ErrorAlert);
