import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import link from './config/link';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import schema from 'schema.json';

const filteredSchema = {
  ...schema,
  __schema: {
    ...schema.__schema,
    types: schema.__schema.types.filter(type => type.possibleTypes !== null),
  },
};

// Allow apollo-client to understand fragments on interfaces.
// See https://www.apollographql.com/docs/react/data/fragments/#fragments-on-unions-and-interfaces
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: filteredSchema,
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({ fragmentMatcher }),
});

export default client;
