import React, { useState } from 'react';
import { Button } from '@mui/material';
import SupportingDocumentUploaderModal from './SupportingDocumentUploaderModal';
import { CloudUpload as UploadIcon } from '@mui/icons-material';
import MyFilesModal from 'common/components/DocumentUploader/MyFiles';
import UploadSuccessModal from './UploadSuccessModal';

const INSTRUCTIONS =
  'Please upload PDF, JPG or PNG files only, other types of files will not be accepted. Choose files from file selector, or from your My Files storage.';

const SupportingDocumentUploadButton = props => {
  const initialState = {
    open: false,
    isSuccessModalOpen: false,
    isMyFilesOpen: false,
    files: [],
    recentlySelectedMyFiles: [],
  };
  const [state, setState] = useState(initialState);
  const {
    associatedDocumentId,
    uploadHandler,
    editHandler,
    deleteHandler,
    viewHandler,
    deleteAllHandler,
    uploadedSupportDocuments,
  } = props;

  const handleDocumentUploaderModal = (options = null) => {
    if (options === 'start') {
      clearFileState();
    }
    setState(state => ({ ...state, open: !state.open }));
  };

  const handleMyFilesClose = (recentlySelectedMyFiles = []) => {
    setState(state => ({
      ...state,
      recentlySelectedMyFiles,
    }));
    handleMyFilesModal();
    handleDocumentUploaderModal();
  };

  const handleSuccessModal = (files = []) => {
    setState(state => ({
      ...state,
      isSuccessModalOpen: !state.isSuccessModalOpen,
      files,
    }));
  };

  const handleMyFilesModal = () => {
    setState(state => ({
      ...state,
      isMyFilesOpen: !state.isMyFilesOpen,
    }));
  };

  const clearFileState = () => {
    setState(state => ({
      ...state,
      recentlySelectedMyFiles: [],
      files: [],
    }));
  };

  const {
    open,
    isMyFilesOpen,
    isSuccessModalOpen,
    files,
    recentlySelectedMyFiles,
  } = state;

  return (
    <React.Fragment>
      <Button
        onClick={() => handleDocumentUploaderModal('start')}
        startIcon={<UploadIcon />}
        color="primary"
        data-test-id={`SupportingDocumentUploadButton-upload-${associatedDocumentId}`}
      >
        Upload
      </Button>
      {open && (
        <SupportingDocumentUploaderModal
          open={open}
          uploadHandler={uploadHandler}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
          deleteAllHandler={deleteAllHandler}
          viewHandler={viewHandler}
          closeModalHandler={handleDocumentUploaderModal}
          uploadedSupportDocuments={uploadedSupportDocuments}
          myFilesModalHandler={handleMyFilesModal}
          successModalHandler={handleSuccessModal}
          recentlySelectedMyFiles={recentlySelectedMyFiles}
          title="Upload Supporting Documents"
          instructions={INSTRUCTIONS}
          fileTypes="image/jpeg, image/png, application/pdf"
        />
      )}
      {isMyFilesOpen && (
        <MyFilesModal
          isOpen={isMyFilesOpen}
          handleClose={handleMyFilesClose}
          uploadHandler={uploadHandler}
        />
      )}
      {isSuccessModalOpen && (
        <UploadSuccessModal
          isOpen={isSuccessModalOpen}
          handleClose={handleSuccessModal}
          files={files}
        />
      )}
    </React.Fragment>
  );
};

export default SupportingDocumentUploadButton;
