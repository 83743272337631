import React, { Component } from 'react';
import { connect } from 'react-redux';
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import Badge from '../../Base/Badge';
import BoxItem from '../../Base/Box/BoxItem';
import BoxParagraphItem from '../../Base/Box/BoxParagraphItem';
import Button from '../../Base/Button';
import FailureAlert from '../../Shared/FailureAlert';
import Field from '../../Base/Field';
import Form from '../../Base/Form';
import Header from '../../Base/Header';
import Icon from '../../Base/Icon';
import Link from '../../Base/Link';
import Modal from '../../Base/Modal';
import Preloader from '../../Shared/Preloader';
import Separator from '../../Base/Separator';

import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import { pushNotification } from 'common/store/actions/snackbarNotifications';
import DOMPurify from 'dompurify';

const CODE_MAXCHAR = 6;

const styles = {
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    padding: '20px',
    width: '100%',
    justifyContent: 'space-around',
  },
};

class PhoneVerificationModal extends Component {
  static mutators = {
    verification: {
      info() {
        return {
          create: true,
          id: '/v2/phone/verify',
          initial: {
            code: '',
          },
        };
      },
    },
  };

  state = {
    codeSent: false,
  };

  shouldComponentUpdate() {
    return this.state.codeSent;
  }

  render() {
    const status = this.props.verification.status;

    switch (status) {
      case 'success': {
        return this._renderSuccess();
      }
      case 'loading': {
        return this._renderLoading();
      }
      default: {
        return this._renderFailed();
      }
    }
  }

  _renderErrors = () => {
    const errors = this.props.verification.errors;

    return Object.keys(errors).map(key => {
      return errors[key].map(description => {
        return (
          <Badge className="badge--row" error={true}>
            {key}: {description}
          </Badge>
        );
      });
    });
  };

  _renderForm = () => {
    const { classes } = this.props;
    const verificationData = this.props.verification.data;
    return (
      <Modal>
        <Header>
          <Header.Title center={true} primary={true}>
            <span data-test-id="PhoneVerificationModal-title">
              Enter 6 digit validation code
            </span>
          </Header.Title>
        </Header>
        {this._renderErrors()}
        <Form action="" method="put" onSubmit={this._submitCode}>
          <BoxItem>
            <span data-test-id="PhoneVerificationModal-input">
              <Field
                autoFocus={true}
                type="code"
                onChange={this._updateCode()}
                value={verificationData.code}
              />
            </span>
            <BoxParagraphItem align="center" compact={true}>
              <Link blue={true} onClick={this._sendCode}>
                Resend verification code
              </Link>
            </BoxParagraphItem>
          </BoxItem>
          <Separator />
          <BoxItem nopadding={true}>
            <div className={classes.buttonContainer}>
              <Button
                cancel={true}
                onClick={this.props.closeModal}
                data-test-id="PhoneVerificationModal-cancel"
              >
                Cancel
              </Button>
              {this._renderSubmitButton()}
            </div>
          </BoxItem>
        </Form>
      </Modal>
    );
  };

  _renderFailed = () => {
    if (this.props.verification.errors) {
      return this._renderForm();
    }

    return (
      <Modal>
        <Icon
          name="fa-times"
          className="icon--close-modal"
          onClick={DOMPurify.sanitize(this.props.closeModal)}
        />

        <BoxItem>
          <FailureAlert />
        </BoxItem>
      </Modal>
    );
  };

  _renderLoading = () => {
    return (
      <Modal>
        <Preloader />
      </Modal>
    );
  };

  _renderSuccess = () => {
    if (!this.state.codeSent) this._sendCode();
    return this._renderForm();
  };

  _renderSubmitButton = () => {
    const code = this.props.verification.data.code;
    const disabled = !code || code.length < 6;

    return (
      <Button disabled={disabled} data-test-id="PhoneVerificationModal-verify">
        Verify
      </Button>
    );
  };

  _sendCode = () => {
    this.props.verification.save('', {}, () => {
      this.setState({ codeSent: true });
    });
  };

  _submitCode = () => {
    this.props.verification.save('edit', {}, () => {
      this.props.updateVerifiedStatus(true);
      this.props.pushNotification({
        message: 'Your phone number has been verified.',
        variant: SnackbarVariants.SUCCESS,
      });
      this.props.closeModal();
    });
  };

  _updateCode = () => {
    const verification = this.props.verification;

    return value => {
      const code = value.substring(0, CODE_MAXCHAR);
      verification.update('code')(code);
    };
  };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  pushNotification,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withApi,
)(PhoneVerificationModal);
