import React, { Component } from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Box from 'common/oldJavascripts/components/Base/Box';
import Button from 'common/oldJavascripts/components/Base/Button';
import Header from 'common/oldJavascripts/components/Base/Header';
import List from 'common/oldJavascripts/components/Base/List';
import Loader from 'common/components/Loader';
import NavBar from 'common/oldJavascripts/components/Base/NavBar';
import T from 'common/oldJavascripts/utils/i18n';
import collection from 'common/oldJavascripts/utils/collection.js';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import DocumentIcon from 'common/icons/SingleDocIcon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import moment from 'moment';

// HoC
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';

import ajax from 'common/utilities/ajax';
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';

import BoxHeader from 'common/oldJavascripts/components/Base/Box/BoxHeader';

import BoxSection from 'common/oldJavascripts/components/Base/Box/BoxSection';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

const styles = () => ({
  noPadding: {
    padding: 0,
  },
});

class ArchivesFileItemClass extends Component {
  static queries = {
    file: {
      info: function(params, related) {
        var routerParams = related['/router/params'];

        return {
          id: [
            '/projects',
            routerParams.projectId,
            'archives',
            routerParams.archiveId,
            'files',
            params.id,
          ].join('/'),
        };
      },
    },
  };
  download = () => {
    const {
      routerParams = {},
      file: fileQuery = {},
      pushNotification,
      popNotification,
    } = this.props;
    const { projectId, archiveId } = routerParams || {};
    const { data: file = {} } = fileQuery || {};
    const { id: fileId } = file || {};
    const loadingMessage =
      'Hold on a moment while we prepare the document for download. Your download should being momentarily.';
    pushNotification({
      message: loadingMessage,
      variant: SnackbarVariants.INFO,
    });
    ajax
      .get(
        `/projects/${projectId}/archives/${archiveId}/files/${fileId}/download`,
      )
      .then(response => {
        const { path = '' } = response;
        if (!path)
          throw new Error(
            'No path received when requesting document for download.',
          );
        popNotification({ message: loadingMessage, popAllMatching: true });
        pushNotification({
          message: 'Your download has started.',
          variant: SnackbarVariants.SUCCESS,
        });
        window.location.href = path;
      })
      .catch(err => {
        popNotification({ message: loadingMessage, popAllMatching: true });
        pushNotification({
          message:
            'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.',
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  render() {
    const { file: fileQuery = {}, classes = {} } = this.props;
    const { data: file = {} } = fileQuery || {};

    if (fileQuery.status === 'loading') {
      return <Loader />;
    }

    return (
      <List.Item>
        <List.ItemContent>
          <List.ItemContentBody title={file.filename}>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '27px auto',
                gridTemplateRows: '100%,',
              }}
            >
              <DocumentIcon style={{ height: '1.5em', width: 'auto' }} />
              <div
                data-test-id={`ArchivedOfferDetails-documentName-${file.filename}`}
              >
                {file.filename}
              </div>
            </div>
          </List.ItemContentBody>
          <List.ItemNav>
            <IconButton
              onClick={this.download}
              className={classes.noPadding}
              data-test-id={`ArchivedOfferDetails-downloadDocument-${file.filename}`}
            >
              <DownloadIcon />
            </IconButton>
          </List.ItemNav>
        </List.ItemContent>
      </List.Item>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  pushNotification,
  popNotification,
};

const ArchiveFileItem = compose(
  withStyles(styles),
  withApi,
  connect(mapStateToProps, mapDispatchToProps),
)(ArchivesFileItemClass);

var ArchiveFiles = Relay.createClass({
  statics: {
    queries: {
      archive_files: {
        info: function(_, related) {
          var params = related['/router/params'];

          return {
            id: [
              '/projects',
              params.projectId,
              'archives',
              params.archiveId,
              'files',
            ].join('/'),
          };
        },
      },
    },
  },

  render: function() {
    var archiveFilesQuery = this.props.archive_files;
    var archiveFiles = null;
    var files = null;

    if (archiveFilesQuery.status === 'loading') {
      return <Loader />;
    }

    archiveFiles = archiveFilesQuery.data.items;

    files = collection.map(archiveFiles, function(file) {
      return <ArchiveFileItem params={{ id: file.id }} key={file.id} />;
    });

    return <List>{files}</List>;
  },
});

export default Relay.createClass({
  statics: {
    queries: {
      archive: {
        info: function(_, related) {
          var params = related['/router/params'];

          return {
            id:
              '/projects/' + params.projectId + '/archives/' + params.archiveId,
          };
        },
      },
    },
  },

  render: function() {
    const { routerParams = {}, archive: archiveQuery = {} } = this.props;
    const { data: archive = {} } = archiveQuery || {};
    const { projectId } = routerParams;
    const {
      first_name: firstName = '',
      last_name: lastName = '',
      position = '',
      sent_date: sentDate,
      approved_date: approvedDate,
    } = archive || {};
    if (archiveQuery.status === 'loading') {
      return <Loader />;
    }
    const formatDate = date =>
      moment(date, 'YYYY-MM-DD').format('MMM Do, YYYY');
    const recipientName = `${firstName} ${lastName}`;
    return (
      <LayoutContent>
        <Box>
          <BoxHeader>
            <Header>
              <Typography
                variant="h4"
                data-test-id="ArchivedOfferDetails-recipientName"
              >
                {recipientName}
              </Typography>
              <Typography
                variant="h6"
                data-test-id="ArchivedOfferDetails-position"
              >
                POSITION: {position.toUpperCase()}
              </Typography>
            </Header>
          </BoxHeader>
          <BoxItem>
            <BoxItem minimal={true}>
              <Header>
                <Typography
                  variant="h4"
                  style={{ fontWeight: 500 }}
                  data-test-id="ArchivedOfferDetails-informationHeader"
                >
                  Archived Offer Information
                </Typography>
              </Header>
            </BoxItem>

            <BoxItem minimal={true}>
              <BoxSection first={true}>
                <Typography
                  variant="h6"
                  data-test-id="ArchivedOfferDetails-sentDateHeader"
                >
                  Sent Date
                </Typography>
                <Typography
                  variant="h5"
                  data-test-id="ArchivedOfferDetails-sentDate"
                >
                  {formatDate(sentDate)}
                </Typography>
              </BoxSection>
              <BoxSection>
                <Typography
                  variant="h6"
                  data-test-id="ArchivedOfferDetails-approvedDateHeader"
                >
                  Approved Date
                </Typography>
                <Typography
                  variant="h5"
                  data-test-id="ArchivedOfferDetails-approvedDate"
                >
                  {formatDate(approvedDate)}
                </Typography>
              </BoxSection>
            </BoxItem>
          </BoxItem>

          <BoxItem>
            <BoxItem minimal={true}>
              <Header>
                <Typography
                  variant="h4"
                  style={{ fontWeight: 500 }}
                  data-test-id="ArchivedOfferDetails-documentsHeader"
                >
                  Documents uploaded by Hiring Manager
                </Typography>
              </Header>
              <ArchiveFiles />
            </BoxItem>
            <NavBar>
              <NavBar.Item>
                <Button
                  to={`/projects/${projectId}/archives`}
                  data-test-id="ArchivedOfferDetails-back"
                >
                  {T('archives.details.back')}
                </Button>
              </NavBar.Item>
            </NavBar>
          </BoxItem>
        </Box>
      </LayoutContent>
    );
  },
});
