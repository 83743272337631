import React from 'react';
import { withStyles } from '@mui/styles';
import classNames from 'class-names';
var Help = null;

const styles = {
  root: {
    WebkitBorderRadius: '10px',
    borderRadius: '10px',
    background: '#8cc4e0',
    bottom: '3px',
    color: 'white',
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '8px',
    marginLeft: '5px',
    position: 'relative',
    textAlign: 'center',
    width: '15px',
    height: '15px',
    lineHeight: '12px',
  },
  active: {
    '&:hover': { background: '#0000FF' },
  },
  tooltipIcon: { verticalAlign: 'middle' },
  tooltipMessage: {
    display: 'none',
  },
  tooltipMessageActive: {
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid #0000FF',
    bottom: 'calc(0px - 5px)',
    content: '" "',
    display: 'block',
    fontSize: '0',
    left: '27%',
    lineHeight: '0',
    position: 'absolute',
    width: '0',
    '&:after': {
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      borderTop: '5px solid #0000FF',
      bottom: 'calc(0px - 5px)',
      content: '" "',
      display: 'block',
      fontSize: '0',
      left: '27%',
      lineHeight: '0',
      position: 'absolute',
      width: '0',
    },
  },
};

Help = class extends React.Component {
  static defaultProps = {
    delay: 200,
  };

  state = {
    showHelpText: false,
  };

  handleMouseOver = () => {
    this._showHelpText();
  };

  handleMouseOut = () => {
    setTimeout(this._hideHelpText, this.props.delay);
  };

  _showHelpText = () => {
    this.setState({ showHelpText: true });
  };

  _hideHelpText = () => {
    if (!this._isHover()) {
      this.setState({ showHelpText: false });
    }
  };

  _isHover = () => {
    return (
      this.refs.help.querySelector('.tooltip-message:hover') ||
      this.refs.help.querySelector('.tooltip-icon:hover')
    );
  };

  render() {
    const { classes } = this.props;
    const { showHelpText } = this.state;
    const root = classNames(classes.root, {
      [classes.active]: showHelpText,
    });
    const tooltipMessageClass = classNames(classes.tooltipMessage, {
      [classes.tooltipMessageActive]: showHelpText,
    });
    return (
      <span
        className={root}
        ref="help"
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
      >
        <span className={classes.tooltipIcon}>?</span>
        <span
          className={tooltipMessageClass}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
        >
          {this.props.children ? this.props.children : this.props.text}
        </span>
      </span>
    );
  }
};

export default withStyles(styles)(Help);
