import React, { Component } from 'react';

import { withStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import ListItem from 'admin/components/RoleMapper/ListItem';
import DOMPurify from 'dompurify';

const styles = () => ({
  bold: {
    fontWeight: 'bold',
  },
  name: {
    width: '100%',
  },
});

class RoleListItem extends Component {
  state = {
    error: false,
    roleName: DOMPurify.sanitize(this.props.role.name),
  };

  handleInputChange = ({ target: { value } }) => {
    this.setState({ roleName: value });
  };

  handleKeyPress = event => {
    const {
      updateRoleName,
      role: { id },
      toggleEditRoleName,
    } = this.props;
    const { roleName } = this.state;
    const { key } = event;
    if (key === 'Enter') {
      if (this.isValidGroupName()) {
        this.setState({ error: false });
        toggleEditRoleName(id);
        updateRoleName(id, roleName);
      } else {
        this.setState({ error: true });
      }
    }
  };

  isValidGroupName = () => {
    const {
      roles,
      role: { id },
    } = this.props;
    const { roleName } = this.state;
    return (
      !roles.some(
        role =>
          role.name.toLowerCase() === roleName.toLowerCase() && role.id !== id,
      ) && !!roleName.length
    );
  };

  handleFocus = name => {
    if (name.includes('New Group')) {
      this.handleInputChange({ target: { value: '' } });
    }
  };

  handleEditConfirm = () => {
    const {
      updateRoleName,
      role: { id },
      toggleEditRoleName,
    } = this.props;
    const { roleName } = this.state;
    if (this.isValidGroupName()) {
      this.setState({ error: false });
      toggleEditRoleName(id);
      updateRoleName(id, roleName);
    } else {
      this.setState({ error: true });
    }
  };

  renderGroupNameDisplay = () => {
    const {
      role: { id },
      classes,
      isEditingRoleName,
    } = this.props;
    const { roleName, error } = this.state;

    if (isEditingRoleName !== id) {
      return roleName;
    }
    return (
      <TextField
        variant="standard"
        error={error || roleName === ''}
        className={classes.name}
        value={roleName}
        onChange={this.handleInputChange}
        onKeyDown={this.handleKeyPress}
        onFocus={() => this.handleFocus(roleName)}
        inputProps={{
          'data-test-id': `RoleListItem-roleNameInput-${id}`,
        }}
      />
    );
  };

  render() {
    const {
      classes,
      setActiveRole,
      role,
      deleteRole,
      activeRoleId,
      openDeleteDialog,
      isEditingRoleName,
      toggleEditRoleName,
    } = this.props;
    const { roleName } = this.state;
    const { name, id, fieldsMapped, isDefault } = role;

    const deleteMessage = (
      <span>
        There are <b className={classes.bold}>{fieldsMapped}</b> fields mapped
        to this field group, are you sure you want to delete{' '}
        <b className={classes.bold}>{roleName}</b>?
      </span>
    );

    const deleteCallback = () => deleteRole(id);
    const listProps = {
      title: this.renderGroupNameDisplay(),
      subHeader: `${fieldsMapped} Fields`,
      buttons: [
        {
          disabled:
            (id !== activeRoleId && activeRoleId !== null) ||
            !!isEditingRoleName ||
            (isEditingRoleName && isEditingRoleName !== id),
          title: activeRoleId === id ? 'Done' : 'Map',
          color: 'primary',
          onClick: () => setActiveRole(id),
          dataTestId: `RoleListItem-mapButton-${id}`,
        },
        {
          disabled:
            !!activeRoleId ||
            name === '' ||
            isDefault ||
            (isEditingRoleName && isEditingRoleName !== id),
          title: isEditingRoleName === id ? 'Done' : 'Edit',
          color: 'secondary',
          onClick:
            isEditingRoleName === id
              ? () => this.handleEditConfirm()
              : () => toggleEditRoleName(id),
          dataTestId: `RoleListItem-editButton-${id}`,
        },
        {
          disabled:
            (id !== activeRoleId && activeRoleId !== null) ||
            isDefault ||
            !!activeRoleId ||
            !!isEditingRoleName ||
            (isEditingRoleName && isEditingRoleName !== id),
          title: 'Delete',
          onClick: () => openDeleteDialog(deleteMessage, deleteCallback),
          dataTestId: `RoleListItem-deleteButton-${id}`,
        },
      ],
      dataTestIds: {
        title: `RoleListItem-roleName-${id}`,
        root: `RoleListItem-root-${role.id}`,
        subHeader: `RoleListItem-subHeader-${id}`,
      },
      key: `RoleListItem-${role.id}`,
    };
    return <ListItem {...listProps} />;
  }
}

export default withStyles(styles)(RoleListItem);
