import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const QUERY = gql`
  mutation saveOfferDraft(
    $id: Int
    $projectId: Int!
    $title: String!
    $data: JsonInput!
    $isTemplate: Boolean!
  ) {
    saveOfferDraft(
      id: $id
      projectId: $projectId
      title: $title
      data: $data
      isTemplate: $isTemplate
    )
  }
`;

const useSaveOfferDraft = () => {
  const [saveDraft, params] = useMutation(QUERY);
  return [saveDraft, params];
};

export default useSaveOfferDraft;
