import React from 'react';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import FormField from 'common/oldJavascripts/components/Base/FormField';
import Select from 'common/oldJavascripts/components/Base/Select';

const UnionOccupationSelect = props => {
  if (props.hasFailed) {
    return <FailureAlert queryName="Occupations" />;
  }
  const changeOccupations = selectedOccupationIds => {
    if (selectedOccupationIds?.length === 0) {
      props.onChange({});
      return;
    }
    const selectedOccupations = {};
    const { occupations } = props;
    selectedOccupationIds.forEach(occupation => {
      const occupationCode = occupation.split('_')[0];
      const unionCode = occupation.split('_')[1];
      const occupationObject = occupations.find(
        occ => occ.code === occupationCode && occ.union_id === unionCode,
      );
      if (occupationCode === '') {
        return;
      }
      selectedOccupations[occupationCode] = occupationObject;
    });
    props.onChange(selectedOccupations);
  };

  const getOccupations = () => {
    const { selectedUnions, occupations } = props;
    const selectedUnionCodes = Object.keys(selectedUnions);
    if (
      occupations.length > 0 &&
      selectedUnionCodes.length > 0 &&
      selectedUnionCodes[0] !== ''
    ) {
      return occupations
        .filter(occ => selectedUnions[occ.union_id])
        .map(occupation => ({
          label:
            `${occupation.code} - ${occupation.description}` +
            ' - ' +
            (selectedUnions[occupation.union_id]
              ? selectedUnions[occupation.union_id].description
              : ''),
          // union and occupation are in many-to-many relation. To get unique relation
          // in drop down options, option value must be occ id plus union id.
          value:
            occupation.code +
            '_' +
            (selectedUnions[occupation.union_id]
              ? selectedUnions[occupation.union_id].code
              : ''),
        }));
    }
    return [];
  };

  const { label, isLoading, selectedOccupations } = props;
  const occupationValues = Object.keys(selectedOccupations).map(
    key =>
      `${key}_${
        selectedOccupations[key] ? selectedOccupations[key].union_id : ''
      }`,
  );
  return (
    <FormField label={label}>
      <Select
        id={props.id}
        isInvalid={false}
        multiple={true}
        isLoading={isLoading}
        onChange={changeOccupations}
        options={getOccupations()}
        value={occupationValues}
        closeMenuOnSelect={false}
      />
    </FormField>
  );
};

export default UnionOccupationSelect;
