import React from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import classnames from 'classnames';
const ActionButtons = ({
  classes,
  btnText1 = 'Back',
  btnText2 = 'Next',
  onBack,
  onNext,
  disabled = false,
}) => {
  return (
    <DialogActions classes={{ root: classes.dialgActionsRoot }}>
      <Button
        data-test-id="reject-i9-back-btn"
        onClick={() => onBack()}
        className={classnames(classes.btn, classes.btnOutlined)}
        variant="outlined"
        color="primary"
      >
        {btnText1}
      </Button>
      <Button
        data-test-id="reject-i9-next-btn"
        disabled={disabled}
        onClick={() => onNext()}
        className={classnames(classes.btn, classes.btnContained)}
        variant="contained"
        color="primary"
      >
        {btnText2}
      </Button>
    </DialogActions>
  );
};

export default ActionButtons;
