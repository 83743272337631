import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const QUERY = gql`
  mutation rejectI9(
    $documentId: String!
    $excludedFields: [String!]
    $rejectionNotes: String
  ) {
    rejectI9(
      documentId: $documentId
      excludedFields: $excludedFields
      rejectionNotes: $rejectionNotes
    )
  }
`;

const useRejectI9Fields = () => {
  const [rejectI9, params] = useMutation(QUERY);
  return [rejectI9, params];
};

export default useRejectI9Fields;
