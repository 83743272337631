import React from 'react';

import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import {
  InsertDriveFile as InsertDriveFileIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { Divider } from '@mui/material';
import classNames from 'classnames';
import {
  INPROGRESS,
  WAITING,
  NOTSIGNED,
} from 'common/utilities/constants/documentStatus';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/RemoveCircleSharp';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

const OFFER_STATUSES = ['approved', 'rejected', 'archived', 'rescinded'];

const styles = theme => {
  return {
    root: {
      display: 'flex',
      width: '100%',
    },
    approvedListAllignment: {
      display: 'flex',
      width: '28%',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    pendingListAllignment: {
      display: 'flex',
      width: '44%',
      flexDirection: 'column',
      marginLeft: theme.spacing(1),
    },
    rejectedListAllignment: {
      display: 'flex',
      width: '28%',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingLeft: '8px',
    },
    documentListAllignment: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '5px',
      maxHeight: '280px',
      overflow: 'auto',
    },
    noDocuments: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      maxHeight: '280px',
      overflow: 'auto',
      border: '0.5px dashed grey',
      minHeight: '170px',
      marginTop: '8px',
      marginRight: '10px',
    },
    parentDocument: {
      display: 'flex',
      alignItems: 'flex-start',
      margin: '5px 0px',
      marginRight: '5px',
    },
    doc: {
      display: 'flex',
      alignItems: 'flex-start',
      cursor: 'pointer',
      background: 'none',
      outline: 'none',
      border: 'none',
      padding: 0,
    },
    parentDocumentActive: {
      backgroundColor: '#86A6FD',
      borderRadius: '2px',
    },
    pendingDoc: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      cursor: 'pointer',
      background: 'none',
      outline: 'none',
      border: 'none',
      padding: 0,
    },
    pendingDocName: {
      display: 'flex',
      marginRight: '20px',
      textAlign: 'left',
      width: '75%',
    },
    docName: {
      paddingLeft: theme.spacing(0.2),
      textAlign: 'left',
    },
    docNameSpace: {
      paddingLeft: theme.spacing(2.3),
      textAlign: 'left',
    },
    docLevelName: {
      marginRight: '10px',
      width: '20%',
    },
    documentIcon: {
      height: theme.spacing(3),
      marginRight: theme.spacing(0.5),
    },
    previewIcon: {
      marginLeft: '5px',
      height: 20,
      cursor: 'pointer',
      paddingRight: '3px',
    },
    blue: {
      color: '#0000B3',
    },
    white: {
      color: '#fafafa',
    },
    red: {
      color: theme.palette.error.main,
    },
    approvedCount: {
      backgroundColor: '#C1F4CC',
      color: '#008006',
      padding: '2px 8px',
      borderRadius: '2px',
    },
    pendingCount: {
      backgroundColor: '#FFEBB2',
      color: '#FF6D00',
      padding: '2px 8px',
      borderRadius: '2px',
    },
    rejectedCount: {
      backgroundColor: '#fab9b9',
      color: '#f56464',
      padding: '2px 8px',
      borderRadius: '2px',
      marginLeft: '5px',
    },
    statusHeading: {
      color: 'black',
      fontWeight: '600',
    },
    statusHeadingPending: {
      color: 'black',
      fontWeight: '600',
      width: '75%',
    },
    statusHeadingApprover: {
      color: 'black',
      fontWeight: '600',
      width: '25%',
      textAlign: 'center',
    },
    removeIconButton: {
      padding: '0px 1px 1px 1px !important',
    },
  };
};

const OfferDocumentsStatus = props => {
  const {
    documents,
    handleDocumentSelect,
    classes,
    status,
    keepI9 = false,
    previewDocument,
    removeDocument,
    selectedDocumentId,
    hirerCanSendDocuments,
    approverCanRemoveDocuments,
    offerStatus,
    isDocsMoreThanOne,
  } = props;
  documents.sort((a, b) => (a.name > b.name ? 1 : -1));
  const flags = useFeatureFlags();
  const isRemoveDocumentFlagActive = flags?.includes('RemoveDocument');
  const approvedDocuments = documents?.filter(
    doc =>
      doc?.status === 'approved' &&
      (status !== 'rejected' || keepI9) &&
      doc?.id,
  );
  const rejectedDocuments = documents?.filter(
    doc =>
      doc?.id &&
      (doc?.status === 'rejected' || (status === 'rejected' && !keepI9)),
  );
  const canRemoveDocuments =
    isRemoveDocumentFlagActive &&
    isDocsMoreThanOne &&
    (hirerCanSendDocuments || approverCanRemoveDocuments) &&
    !OFFER_STATUSES.includes(offerStatus);
  const pendingDocuments = documents?.filter(
    doc =>
      doc?.status !== 'approved' &&
      doc?.status !== 'rejected' &&
      (status !== 'rejected' || keepI9) &&
      doc?.id,
  );
  const approvedDocumentsList = approvedDocuments?.map(doc => {
    const { supportingDocuments: supportDocs = [] } = doc;
    const docClass = classNames(
      {
        [classes.parentDocumentActive]: selectedDocumentId === doc?.id,
      },
      [classes.parentDocument],
    );
    const docNameClass = classNames({
      [classes.docName]: supportDocs?.length > 0,
      [classes.docNameSpace]: supportDocs?.length === 0,
    });
    const fileIconClass = classNames({
      [classes.blue]: selectedDocumentId !== doc?.id,
      [classes.white]: selectedDocumentId === doc?.id,
    });
    return (
      <div key={doc.id}>
        <div className={docClass}>
          <button
            className={classes.doc}
            onClick={() => handleDocumentSelect(doc.id)}
            data-test-id={`offer-document-status-${doc.id}`}
          >
            {supportDocs?.length > 0 && (
              <InsertDriveFileIcon className={fileIconClass} />
            )}
            <Typography type="body" className={docNameClass}>
              {doc?.name}
            </Typography>
          </button>
          {(doc?.isViewable || doc?.isW9 || doc?.isExternal) && (
            <VisibilityIcon
              onClick={() =>
                previewDocument(doc.id, doc?.isW9, doc?.isExternal)
              }
              className={classes.previewIcon}
              data-test-id={`offer-document-preview-${doc.id}`}
            />
          )}
          {canRemoveDocuments &&
            !doc.unremovable &&
            !doc.isExternal &&
            !doc.isW9 && (
              <IconButton
                onClick={() => removeDocument(doc.id)}
                data-test-id={`offer-document-remove-${doc.id}`}
                className={classes.removeIconButton}
              >
                <RemoveIcon className={classes.red} />
              </IconButton>
            )}
        </div>
      </div>
    );
  });

  const pendingDocumentsList = pendingDocuments?.map(doc => {
    const { supportingDocuments: supportDocs = [] } = doc;
    const docStatus = doc?.workflow;
    const employeeStatus = doc?.status === NOTSIGNED;
    const approverLevel = docStatus?.find(
      approvalStep =>
        approvalStep?.status === INPROGRESS || approvalStep?.status === WAITING,
    );
    const docClass = classNames(
      {
        [classes.parentDocumentActive]: selectedDocumentId === doc?.id,
      },
      [classes.parentDocument],
    );
    const docNameClass = classNames({
      [classes.docName]: supportDocs?.length > 0,
      [classes.docNameSpace]: supportDocs?.length === 0,
    });
    const fileIconClass = classNames({
      [classes.blue]: selectedDocumentId !== doc?.id,
      [classes.white]: selectedDocumentId === doc?.id,
    });
    return (
      <div key={doc.id}>
        <div className={docClass}>
          <button
            className={classes.pendingDoc}
            onClick={() => handleDocumentSelect(doc.id)}
            data-test-id={`offer-document-status-${doc.id}`}
          >
            <div className={classes.pendingDocName}>
              {supportDocs?.length > 0 && (
                <InsertDriveFileIcon className={fileIconClass} />
              )}
              <Typography className={docNameClass} type="body">
                {doc?.name}
              </Typography>
              {(doc?.isViewable || doc?.isW9) && (
                <VisibilityIcon
                  onClick={() =>
                    previewDocument(doc.id, doc?.isW9, doc?.isExternal)
                  }
                  className={classes.previewIcon}
                  data-test-id={`offer-document-preview-${doc.id}`}
                />
              )}
              {canRemoveDocuments &&
                !doc.unremovable &&
                !doc.isExternal &&
                !doc.isW9 && (
                  <IconButton
                    onClick={() => removeDocument(doc.id)}
                    data-test-id={`offer-document-remove-${doc.id}`}
                    className={classes.removeIconButton}
                  >
                    <RemoveIcon className={classes.red} />
                  </IconButton>
                )}
            </div>
            <Typography className={classes.docLevelName} type="body">
              {employeeStatus ? 'Employee' : approverLevel?.levelName}
            </Typography>
          </button>
        </div>
      </div>
    );
  });
  const rejectedDocumentsList = rejectedDocuments?.map(doc => {
    const { supportingDocuments: supportDocs = [] } = doc;
    const docClass = classNames(
      {
        [classes.parentDocumentActive]: selectedDocumentId === doc?.id,
      },
      [classes.parentDocument],
    );
    const docNameClass = classNames({
      [classes.docName]: supportDocs?.length > 0,
      [classes.docNameSpace]: supportDocs?.length === 0,
    });
    const fileIconClass = classNames({
      [classes.blue]: selectedDocumentId !== doc?.id,
      [classes.white]: selectedDocumentId === doc?.id,
    });
    return (
      <div key={doc.id}>
        <div className={docClass}>
          <button
            className={classes.doc}
            onClick={() => handleDocumentSelect(doc.id)}
            data-test-id={`offer-document-status-${doc.id}`}
          >
            {supportDocs?.length > 0 && (
              <InsertDriveFileIcon className={fileIconClass} />
            )}
            <Typography type="body" className={docNameClass}>
              {doc?.name}
            </Typography>
          </button>
          {(doc?.isViewable || doc?.isW9) && (
            <VisibilityIcon
              onClick={() =>
                previewDocument(doc.id, doc?.isW9, doc?.isExternal)
              }
              className={classes.previewIcon}
              data-test-id={`offer-document-preview-${doc.id}`}
            />
          )}
        </div>
      </div>
    );
  });

  return (
    <div className={classes.root}>
      <div className={classes.rejectedListAllignment}>
        <Typography className={classes.statusHeading}>
          Rejected/Removed
          <span className={classes.rejectedCount}>
            {' '}
            {rejectedDocuments?.length}
          </span>
        </Typography>
        {rejectedDocumentsList.length > 0 ? (
          <div className={classes.documentListAllignment}>
            {rejectedDocumentsList}
          </div>
        ) : (
          <div className={classes.noDocuments}>No Rejected documents</div>
        )}
      </div>
      <Divider orientation="vertical" flexItem />
      <div className={classes.pendingListAllignment}>
        <div className={classes.pendingDoc}>
          <Typography className={classes.statusHeadingPending}>
            Pending{' '}
            <span className={classes.pendingCount}>
              {pendingDocuments?.length}
            </span>
          </Typography>
          <Typography className={classes.statusHeadingApprover}>
            {' '}
            Current Approver{' '}
          </Typography>
        </div>
        {pendingDocumentsList.length > 0 ? (
          <div className={classes.documentListAllignment}>
            {pendingDocumentsList}
          </div>
        ) : (
          <div className={classes.noDocuments}>No pending documents</div>
        )}
      </div>
      <Divider orientation="vertical" flexItem />
      <div className={classes.approvedListAllignment}>
        <Typography className={classes.statusHeading}>
          Approved/Completed{' '}
          <span className={classes.approvedCount}>
            {' '}
            {approvedDocumentsList?.length}
          </span>
        </Typography>
        {approvedDocumentsList.length > 0 ? (
          <div className={classes.documentListAllignment}>
            {approvedDocumentsList}
          </div>
        ) : (
          <div className={classes.noDocuments}>No Approved documents</div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(OfferDocumentsStatus);
