import React from 'react';

// Components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Custom Components
import IdlePayHelpIcon from '../IdlePayHelpIcon';
import StudioRatePerHour from './StudioRatePerHour';
import LocationRatePerHour from './LocationRatePerHour';
import StudioRatePerWeek from './StudioRatePerWeek';
import LocationRatePerWeek from './LocationRatePerWeek';
import LocationIdlePaySixthDay from './LocationIdlePaySixthDay';
import LocationIdlePaySeventhDay from './LocationIdlePaySeventhDay';
// Shared
import GuaranteedHours from '../shared/GuaranteedHours';
import OvertimeRatePerHour from '../shared/OvertimeRatePerHour';

// Utilities
import classNames from 'class-names';

const UnionTermsOfEmploymentTable = ({
  classes = {},
  onChange: upstreamOnChange,
  formData = {},
  formErrors = {},
} = {}) => {
  const textFieldProps = key => ({
    onChange: upstreamOnChange,
    formData: formData,
    error: formErrors[key],
    classes: {
      root: classNames(classes.cellWithTextFieldClass, {
        [classes.errorCell]: formErrors[key],
      }),
      textField: classes.textField,
      textFieldInputContainer: classes.textFieldInputContainer,
      textFieldInput: classes.textFieldInput,
    },
  });

  const guaranteedHoursProps = formKey => ({
    onChange: upstreamOnChange,
    value: formData[formKey],
    formKey,
    classes: {
      root: classes.cellWithTextFieldClass,
      textField: classes.textField,
      textFieldInputContainer: classes.textFieldInputContainer,
      textFieldInput: classes.textFieldInput,
    },
  });

  const rateLabelClass = classNames(classes.labelCellClass, {
    [classes.errorCell]: formErrors.rate,
  });

  const overtimeRateLabelClass = classNames(classes.labelCellClass, {
    [classes.errorCell]: formErrors.overtimeRate,
  });

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell />
          <TableCell align="center" className={classes.headerCell}>
            Studio
          </TableCell>
          <TableCell align="center" className={classes.headerCell}>
            Distant
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell colSpan={2} className={rateLabelClass}>
            Rate Per Hour
            {formErrors.rate && (
              <Typography
                color="error"
                variant="caption"
                className={classes.errorLabel}
              >
                {formErrors.rate}
              </Typography>
            )}
          </TableCell>
          <StudioRatePerHour {...textFieldProps('studioRatePerHour')} />
          <LocationRatePerHour {...textFieldProps('locationRatePerHour')} />
        </TableRow>
        <TableRow>
          <TableCell colSpan={2} className={rateLabelClass}>
            Rate Per Week
            {formErrors.rate && (
              <Typography
                color="error"
                variant="caption"
                className={classes.errorLabel}
              >
                {formErrors.rate}
              </Typography>
            )}
          </TableCell>
          <StudioRatePerWeek {...textFieldProps('studioRatePerWeek')} />
          <LocationRatePerWeek {...textFieldProps('locationRatePerWeek')} />
        </TableRow>
        <TableRow>
          <TableCell colSpan={2} className={classes.labelCellClass}>
            Guaranteed Hours
          </TableCell>
          <GuaranteedHours {...guaranteedHoursProps('studioGuaranteedHours')} />
          <GuaranteedHours
            {...guaranteedHoursProps('locationGuaranteedHours')}
          />
        </TableRow>
        <TableRow>
          <TableCell rowSpan={2} className={classes.labelCellClass}>
            <div className={classes.idlePayLabel}>
              Idle Pay <IdlePayHelpIcon />
            </div>
          </TableCell>
          <TableCell className={classes.labelCellClass}>
            Pay 6th day at
          </TableCell>
          <Tooltip
            disableInteractive
            enterDelay={250}
            title="Idle pay is only available for distant pay."
          >
            <TableCell align="center" className={classes.labelCellClass} />
          </Tooltip>
          <LocationIdlePaySixthDay
            {...textFieldProps('locationIdlePaySixthDay')}
          />
        </TableRow>
        <TableRow>
          <TableCell className={classes.labelCellClass}>
            Pay 7th day at
          </TableCell>
          <Tooltip
            disableInteractive
            enterDelay={250}
            title="Idle pay is only available for distant pay."
          >
            <TableCell align="center" className={classes.labelCellClass} />
          </Tooltip>
          <LocationIdlePaySeventhDay
            {...textFieldProps('locationIdlePaySeventhDay')}
          />
        </TableRow>
        <TableRow>
          <TableCell colSpan={2} className={overtimeRateLabelClass}>
            Overtime Rate
            {formErrors.overtimeRate && (
              <Typography
                color="error"
                variant="caption"
                className={classes.errorLabel}
              >
                {formErrors.overtimeRate}
              </Typography>
            )}
          </TableCell>
          <OvertimeRatePerHour {...textFieldProps('overtimeRatePerHour')} />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default UnionTermsOfEmploymentTable;
