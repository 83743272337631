import gql from 'graphql-tag';

export default gql`
  query departments($projectId: Int!) {
    departments(projectId: $projectId) {
      id
      name
    }
  }
`;
