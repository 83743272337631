import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Components
import BaseLayout from 'common/oldJavascripts/components/Pages/Layouts/Base';
import ToolsTab from 'admin/containers/Tools/ToolsTab';

const GlobalPermissions = props => (
  <BaseLayout nonEmployee={true}>
    <Switch>
      <Route path="/tools/merge-accounts" render={() => <ToolsTab tab={0} />} />
      <Route
        path="/tools/user-access-report"
        render={() => <ToolsTab tab={1} />}
      />
      <Route path="/tools/user-projects" render={() => <ToolsTab tab={2} />} />
      <Route path="/tools/deal-memo/:id?" render={() => <ToolsTab tab={3} />} />
      <Route path="*" component={ToolsTab} />
    </Switch>
  </BaseLayout>
);

export default GlobalPermissions;
