const sortListBy = (data, orderBy = 'name', direction = 'asc') => {
  const sortedData = [...data];
  if (direction === 'asc') {
    return sortedData.sort((a, b) => {
      if (a[orderBy] < b[orderBy]) return -1;
      if (a[orderBy] > b[orderBy]) return 1;
      return 0;
    });
  }
  return sortedData.sort((a, b) => {
    if (a[orderBy] < b[orderBy]) return 1;
    if (a[orderBy] > b[orderBy]) return -1;
    return 0;
  });
};

export default sortListBy;
