import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

// Define your GraphQL mutation
const QUERY = gql`
  mutation addCrewMember(
    $firstName: String!
    $lastName: String!
    $email: String!
    $managerProfileId: String!
    $projectId: String!
  ) {
    addCrewMember(
      firstName: $firstName
      lastName: $lastName
      email: $email
      managerProfileId: $managerProfileId
      projectId: $projectId
    )
  }
`;

// Mutation hook for adding a new crew member
const useAddCrewMember = () => {
  const [addCrewMember, params] = useMutation(QUERY);
  return [addCrewMember, params];
};
export default useAddCrewMember;
