import React from 'react';
import domClasses from '../../utils/dom/classes.js';

class ToolTipBox extends React.Component {
  render() {
    var classes = null;
    classes = domClasses.set({
      tooltip: true,
      'tooltip--top': this.props.align === 'top',
      'tooltip--right': this.props.align === 'right',
      'tooltip--bottom': this.props.align === 'bottom',
      'tooltip--left': this.props.align === 'left',
      'tooltip--bold': this.props.bold,
    });

    return (
      <div className="tooltip-wrapper">
        {this.props.children}
        <div className={classes}>{this.props.tooltipNode}</div>
      </div>
    );
  }
}

ToolTipBox.defaultProps = {
  align: 'bottom',
};

export default ToolTipBox;
