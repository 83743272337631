const repositionFields = (pageDimensions, field) => {
  const { originalDimensions, updatedDimensions } = pageDimensions;
  const { height: originalHeight, width: originalWidth } = originalDimensions;
  const { height: updatedHeight, width: updatedWidth } = updatedDimensions;
  const newField = {};

  newField.x = (updatedWidth / originalWidth) * field.x;
  newField.y = (updatedHeight / originalHeight) * field.y;

  return newField;
};

export default repositionFields;
