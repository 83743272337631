import React from 'react';

import MergeAccounts from 'admin/components/MergeAccounts';
import UserAccessPage from 'admin/components/UserAccessReport/UserAccess';
import UserProjectsList from 'admin/components/UserProjectList';
import OfferDealmemoPayload from 'admin/components/OfferDealmemoPayload';

const tabs = [
  {
    id: 'merge-accounts',
    label: 'Merge Accounts',
    test_id: 'mergeAccountsTab',
    component: <MergeAccounts />,
  },
  {
    id: 'user-access-report',
    label: 'User Access Report',
    test_id: 'userAccessReportTab',
    component: <UserAccessPage />,
  },
  {
    id: 'user-projects',
    label: 'Projects by User',
    test_id: 'userProjectsList',
    component: <UserProjectsList />,
  },
  {
    id: 'deal-memo',
    label: 'Offer Deal-Memo Payload',
    test_id: 'OfferDealmemoPayload',
    component: <OfferDealmemoPayload />,
  },
];

export default tabs;
