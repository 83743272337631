import React from 'react';
import { withStyles } from '@mui/styles';

const styles = () => ({
  cls1: {
    fill: '#b1b8be',
  },
  cls2: {
    fill: 'none',
    stroke: '#fff',
    strokeLinecap: 'round',
    strokeWidth: '2.34px',
    strokeLinejoin: 'round',
  },
  cls3: {
    fill: 'none',
    stroke: '#fff',
    strokeLinecap: 'round',
    strokeWidth: '2.34px',
    strokeMiterlimit: 10,
  },
  cls4: {
    fill: '#fff',
  },
});

const i9Logo = ({ classes, ...propsCatch }) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 84.48 84.48"
    {...propsCatch}
  >
    <title>i-9-logo</title>
    <circle className={classes.cls1} cx="42.24" cy="42.24" r="42.24" />
    <polygon
      className={classes.cls2}
      points="60.36 65.4 24.12 65.4 24.12 17.47 47.7 17.47 60.36 30.05 60.36 65.4"
    />
    <polyline
      className={classes.cls2}
      points="47.5 17.47 47.5 30.33 60.36 30.33"
    />
    <line className={classes.cls3} x1="33.47" y1="50.2" x2="52.18" y2="50.2" />
    <line
      className={classes.cls3}
      x1="33.47"
      y1="56.05"
      x2="52.18"
      y2="56.05"
    />
    <path
      className={classes.cls4}
      d="M-270.36,1117.63v8.08h-1.06v-8.08h1.06Z"
      transform="translate(309.05 -1082.56)"
    />
    <path
      className={classes.cls4}
      d="M-266.12,1122.09v0.77h-3v-0.77h3Z"
      transform="translate(309.05 -1082.56)"
    />
    <path
      className={classes.cls4}
      d="M-264.61,1125a4.42,4.42,0,0,0,.85,0,2.86,2.86,0,0,0,1.63-.76,3.42,3.42,0,0,0,1-2.06h0a2.29,2.29,0,0,1-1.85.83,2.26,2.26,0,0,1-2.29-2.39,2.67,2.67,0,0,1,2.66-2.77c1.6,0,2.58,1.28,2.58,3.3a4.92,4.92,0,0,1-1.34,3.65,3.9,3.9,0,0,1-2.27,1,4.93,4.93,0,0,1-1,.06V1125Zm0.38-4.47a1.5,1.5,0,0,0,1.5,1.66,1.74,1.74,0,0,0,1.54-.82,0.74,0.74,0,0,0,.1-0.4c0-1.33-.49-2.35-1.61-2.35A1.68,1.68,0,0,0-264.22,1120.49Z"
      transform="translate(309.05 -1082.56)"
    />
  </svg>
);

export default withStyles(styles)(i9Logo);
