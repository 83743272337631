import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const QUERY = gql`
  mutation rescindAdditionalDocuments($offerId: Int!) {
    rescindAdditionalDocuments(offerId: $offerId)
  }
`;

const useRescindAdditionalDocuments = () => {
  const [rescindAdditionalDocuments, params] = useMutation(QUERY);
  return [rescindAdditionalDocuments, params];
};

export default useRescindAdditionalDocuments;
