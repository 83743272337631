import { useQuery } from 'react-apollo';
import { gql } from 'graphql-tag';

const QUERY = gql(`
  query approvedOffers(
    $projectId: Int!
    $after: String
    $fromDate: String
    $toDate: String
    $documentType: [String!]
    $department: [String!]
    $employee: String
    $sort: String
    $direction: String
  ) {
    project(id: $projectId) {
      id
      approvedOffers(
        first: 20
        after: $after
        sort: $sort
        direction: $direction
        fromDate: $fromDate
        toDate: $toDate
        documentTypes: $documentType
        departments: $department
        employee: $employee
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
          totalCount
        }
        nodes {
          id
          accountCode
          startDate
          approvedAt
          projectUser {
            id
            profile {
              id
              firstName
              middleName
              lastName
            }
          }
          termsOfHire {
            department {
              id
              name
            }
            occupation {
              code
              description
            }

          }
        }
      }
    }
  }
`);

const useProjectApprovedOffers = variables => {
  const { projectId } = variables;
  const res = useQuery(QUERY, {
    skip: !projectId,
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const { loading, data, error, refetch, networkStatus, fetchMore } = res;

  return {
    loading,
    error,
    refetch,
    networkStatus,
    fetchMore,
    data: data?.project?.approvedOffers,
  };
};

export default useProjectApprovedOffers;
