class UserPrivileges {
  constructor(privileges) {
    this.privileges = (privileges || []).slice(0);
  }

  isHirer() {
    return this.privileges.indexOf('can_create_offers') !== -1;
  }

  isReviewer() {
    return (
      this.privileges.indexOf('can_be_reviewer') !== -1 ||
      this.privileges.indexOf('can_review_offers') !== -1
    );
  }

  isVerifier() {
    return (
      this.privileges.indexOf('can_be_i9_verifier') !== -1 ||
      this.privileges.indexOf('can_i9_verify_offers') !== -1
    );
  }

  isApprover() {
    return this.privileges.indexOf('can_approve_offers') !== -1;
  }

  isArchiver() {
    return this.privileges.indexOf('can_access_archives') !== -1;
  }

  isDocumentsViewer() {
    return this.privileges.indexOf('can_view_documents') !== -1;
  }

  isSuperHirer() {
    return (
      this.isHirer() && this.privileges.indexOf('can_hirer_view_all') !== -1
    );
  }

  canViewAccountCodes() {
    return this.privileges.includes('can_view_offer_account_codes');
  }

  canEditAccountCodes() {
    return this.privileges.includes('can_edit_offer_account_codes');
  }
}

export default UserPrivileges;
