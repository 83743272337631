import EditEmployee from './EditEmployee';
import EditNonEmployee from './EditNonEmployee';
import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';

export default Relay.createClass({
  statics: {
    mutators: {
      user: {
        info(_, related) {
          const params = related['/router/params'];
          return {
            id: `/projects/${params.projectId}/users/${params.userId}`,
          };
        },
      },
    },
  },

  render() {
    const user = this.props.user.data;
    if (user && user.role === 'employee') {
      return <EditEmployee />;
    }
    return <EditNonEmployee />;
  },
});
