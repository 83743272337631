import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import Dropzone from 'react-dropzone';
import Loader from 'common/components/Loader/Loader';
import ajax from 'common/utilities/ajax';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import { compose } from 'redux';

const UploadModal = ({
  onClose,
  open,
  classes,
  pushSnackbarNotification,
  onSuccess,
}) => {
  const [dropzoneActive, setDropzoneActive] = useState(false);
  const [dropzoneRef, setDropzoneRef] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    ajax
      .post('/guild-terms', { payload: { file } }, { multipart: true })
      .then(res => {
        onSuccess(true);
        pushSnackbarNotification({
          message: 'File uploaded successfully',
          variant: SnackbarVariants.SUCCESS,
        });
        setLoading(false);
      })
      .catch(() => {
        onSuccess(false);
        pushSnackbarNotification({
          message: `There was an error while trying to upload the file`,
          variant: SnackbarVariants.ERROR,
        });
        setLoading(false);
      });
  };

  const uploadHandler = file => {
    setFile(file);
  };

  const onDrop = files => {
    setDropzoneActive(false);
    if (files.length > 0) {
      uploadHandler(files[0]);
    }
  };

  const onDragEnter = () => {
    setDropzoneActive(true);
  };

  const onDragLeave = () => {
    setDropzoneActive(false);
  };

  const handleClose = () => {
    onClose();
    setFile(null);
  };

  useEffect(() => {
    return () => {
      setFile(null);
    };
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      classes={{ paper: classes.dialogPaper }}
      data-test-id="GuildManagement-uploadModal"
    >
      <DialogTitle data-test-id="StateW4Modal-title">
        Upload Guild Data
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">Only CSV</Typography>
        {loading ? (
          <Loader />
        ) : (
          <Dropzone
            className={classes.dropzoneContainer}
            data-test-id="Guild-Uploader-dropzone"
            accept={'text/csv'}
            multiple={false}
            onDrop={onDrop}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            disableClick
            ref={node => {
              setDropzoneRef(node);
              // dropzoneRef = node;
            }}
            rejectClassName={classes.error}
          >
            <div>
              {dropzoneActive && (
                <Typography className={classes.overlayStyle}>
                  Drag and drop file here or click to browse the file
                </Typography>
              )}

              <Typography color="textSecondary">
                <span>
                  Drag and drop file here or click to{' '}
                  <span
                    onClick={() => {
                      dropzoneRef.open();
                    }}
                    className={classes.browseLink}
                  >
                    browse{' '}
                  </span>
                  for file
                </span>
              </Typography>
            </div>
          </Dropzone>
        )}
        <Typography variant="body1">Selected Files</Typography>
        <div className={classes.fileDisplay}>
          {file?.name && (
            <Typography variant="body1" className={classes.fileName}>
              {file?.name || ''}
            </Typography>
          )}
        </div>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <Button
          className={classes.btnOutlined}
          disabled={loading}
          onClick={() => handleClose()}
        >
          Cancel
        </Button>
        <Button
          className={classes.btnContained}
          onClick={() => handleSubmit()}
          disabled={!file?.name || loading}
          data-test-id="Guild-upload-submit"
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default compose(withSnackbarNotification)(UploadModal);
